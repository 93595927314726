import { environment } from "src/environments/environment";

export enum SURGERY_REPORT_CALL {
  // UPDATE_REPORT_TEXT_BY_ID = 'UPDATE_REPORT_TEXT_BY_ID',
  UPDATE_REPORT_DESCRIPTION_BY_ID = 'UPDATE_REPORT_DESCRIPTION_BY_ID',
  UPDATE_REPORT_OBJ_BY_ID = 'UPDATE_REPORT_OBJ_BY_ID',
}

export function apiSugeryRequest(prefix: String, call:SURGERY_REPORT_CALL, params:string = '') {
  switch(call){
    // case SURGERY_REPORT_CALL.UPDATE_REPORT_TEXT_BY_ID:
    // return `${environment.endpoints.apiBasePathV2}templates/surgeryItems/update-report-text/${params}`;
    case SURGERY_REPORT_CALL.UPDATE_REPORT_DESCRIPTION_BY_ID:
    return `${environment.endpoints.apiBasePathV2}${prefix}/surgeryItems/update-report-description/${params}`;
    case SURGERY_REPORT_CALL.UPDATE_REPORT_OBJ_BY_ID:
    return `${environment.endpoints.apiBasePathV2}${prefix}/surgeryItems/update-report-obj/${params}`;
  }
}
