import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';
import { AlertI, AlertActionI } from '../core/models/alertM';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private resultSubject = new Subject<AlertActionI.BUTTON_ACTION_TYPE>();
  private subject = new Subject<any>();
  private keepAfterRouteChange = false;

  private templateItemPositonWantEditTemplateSubject = new Subject<string>();

  constructor(private router:Router) {
    this.router.events.subscribe( event => {
      if(event instanceof NavigationStart){
        if(this.keepAfterRouteChange){
          this.keepAfterRouteChange = false;
        } else {
          this.clear();
          this.clearResult();
          this.clearWantEditPublicTemplate();
        }
      }
    });
  }


  getAlert(): Observable<any> {
    return this.subject.asObservable();
  }

  getAlertResultAction(): Observable<AlertActionI.BUTTON_ACTION_TYPE> {
    return this.resultSubject.asObservable();
  }
  emitAlertResultAction(action:AlertActionI.BUTTON_ACTION_TYPE){
    console.log('EMITTED RESULT ', action)
    this.resultSubject.next(action)
  }
  

  templateItemPositonWantEditTemplateAlert() : Observable<string> {
    return this.templateItemPositonWantEditTemplateSubject.asObservable();
  }
  templateItemPositonWantEditTemplate(templateId:string){
    this.templateItemPositonWantEditTemplateSubject.next(templateId);
  }

  deleteTemplateAlert(){
    let alertM:AlertI = {
      title:"Vorlage löschen",
      bodyMessage:"Sind Sie sicher, dass Sie diese Vorlage löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
      actions: [
        {
          title:'Fortfahren', color: AlertActionI.BUTTON_COLOR_TYPE.WARN, actionType:AlertActionI.BUTTON_ACTION_TYPE.RESUME
        },
        {
          title:'Abbrechen', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType:AlertActionI.BUTTON_ACTION_TYPE.CANCEL
        }
      ]
    };
    this.keepAfterRouteChange = false;
    this.subject.next({type:'alert', model: alertM})
  }
  
  cantEditPublicTemplateAlert(){
    let alertM:AlertI = {
      title:"Achtung", 
      bodyMessage:"Diese Vorlage ist eine DoxFox Vorlage. Bitte erst *Zu meinen Vorlagen hinzufügen*  auswählen",
      actions: [
        {
          title:'Template zu *Meinen Vorlagen kopieren*', color: AlertActionI.BUTTON_COLOR_TYPE.ACCENT, actionType:AlertActionI.BUTTON_ACTION_TYPE.RESUME
        },
        {
          title:'Abbrechen', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType:AlertActionI.BUTTON_ACTION_TYPE.CANCEL
        }
      ]
      
    };
    this.keepAfterRouteChange = false;
    this.subject.next({type:'alert', model: alertM})
  }

  deleteMaterialItemAlert(){
    let alertM:AlertI = {
      title:"Material löschen", bodyMessage:"Wollen Sie wirklich diesen Eintrag löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
      actions: [
        {
          title:'Löschen', color: AlertActionI.BUTTON_COLOR_TYPE.WARN, actionType:AlertActionI.BUTTON_ACTION_TYPE.RESUME
        },
        {
          title:'Abbrechen', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType:AlertActionI.BUTTON_ACTION_TYPE.CANCEL
        }
      ]
    };
    this.keepAfterRouteChange = false;
    this.subject.next({type:'alert', model: alertM})
  }
  removeGOAE_ExclusionNumberAlert(){
    let alertM:AlertI = {
      title:"ACHTUNG", bodyMessage:"Wollen sie diese Ausschlussziffer wirklich entfernen?",
      actions: [
        {
          title:'Fortfahren', color: AlertActionI.BUTTON_COLOR_TYPE.WARN, actionType:AlertActionI.BUTTON_ACTION_TYPE.RESUME
        },
        {
          title:'Abbrechen', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType:AlertActionI.BUTTON_ACTION_TYPE.CANCEL
        }
      ]
    };
    this.keepAfterRouteChange = false;
    this.subject.next({type:'alert', model: alertM})
  }
  clearResult(){
    this.resultSubject.next();
  }
  clear(){
    this.subject.next();
  }
  clearWantEditPublicTemplate(){
    this.templateItemPositonWantEditTemplateSubject.next();
  }
}
