<mat-card class="mat-card-no-shadow p0" style="height:100%!important;">
  <div class="container">

    <div class="row justify-content-between pl16 pr16 " style="padding-top: 13px!important;">
      <div class="col-12 ">
        <div class="row">  <span class="mat-caption">Minderung gemäß § 6a, Abs. 1, GOÄ</span></div>
      </div>
      <div class="col-12 pl0 pr0 " style="padding-top: 0px!important;">
        <mat-form-field appearance="outline" class="textfield100 select-form-field">
          <mat-select (selectionChange)='changed($event)' [(value)]="selectedReduction" [disabled]='!tc.isTemplateEditable'>
            <mat-option *ngFor="let reduction of reductions" [value]="reduction.value">
              {{reduction.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- <div class="d-flex flex-column"  style="padding-top: 7px!important;">
      <div class="p-0">
        <span class="mat-caption">Minderung gemäß § 6a, Abs. 1, GOÄ</span>
      </div>
      <div class="p-0">
        <mat-form-field appearance="outline" class="textfield100 select-form-field">
          <mat-select (selectionChange)='changed($event)' [(value)]="selectedReduction" [disabled]='isDisabled'>
            <mat-option *ngFor="let reduction of reductions" [value]="reduction.value">
              {{reduction.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div> -->
  </div>
</mat-card>
