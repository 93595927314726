<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  
  <mat-icon  class='justification_arrow_icon' style="font-size: 20px!important;" (click)="close()">close</mat-icon>
</mat-toolbar>
<div class="container" style="height: calc(100% - 88px)">

<div class="d-flex" style="height: 100%;">
  <div class="p-2 w50p">
    <div class="d-flex flex-column">
      <div class="p-2">
        <mat-label class='mat-caption'>Fachbereich</mat-label>
        <input class="formControl mat-body" [(ngModel)]="_areaOfExpertise.name" [class.formControlError]="_areaOfExpertise.name == '' ">
      </div>
      <div class="p-2">
        <mat-label class='mat-caption'>Abkürzung <span style="color: rgba(0,0,0,0.5); font-style: italic;">{{_areaOfExpertise.short.length}} von 8</span></mat-label>
        <input class="formControl mat-body" [class.formControlError]="_areaOfExpertise.short == '' " [(ngModel)]="_areaOfExpertise.short" maxlength="8">
      </div>
    </div>
  </div>
  <div class="p-2 w50p" style="height: 100%; overflow:hidden;">
    <div class="d-flex flex-column" style="height: 100%;">
      <div class="p-0">
        <mat-toolbar class="overlay-toolbar">
          <span class="toolbar-spacer"></span>
          <app-df-icon-button *ngIf="treatmentFilterGroups.length < 3" title="Filter Gruppe hinzufügen" icon="add" (clicked)="addFilterGroup()"></app-df-icon-button>  
          <app-df-icon-button title="Filter hinzufügen" icon="add" (clicked)="addTreatmentFiler()"></app-df-icon-button>  
        </mat-toolbar>
      </div>
      <div class="p-0" style="height: 100%; " *ngIf="!treatmentFilerDetail">
        <mat-tab-group style="height: 100%;  overflow-x:hidden;" animationDuration="0ms" [selectedIndex]="selectedTab" (selectedIndexChange)="selectedTabChanged($event)">

          <mat-tab *ngFor="let tab of treatmentFilterGroups" [label]="tab">
            <div class="d-flex flex-column" style="height: 100%;">
              
              
              <div class="p-0 m_t15" *ngIf="selectedTab == 0">
                <div class="container">
                  <mat-chip-list  #chipList multiple="true" >
                    <div *ngFor="let region of treatmentFilter1">
                      <mat-chip #chip="matChip"  class="filter-chip" (click)="editChip(region)"
                        [class.filterChipActiveBlue]="region.selected" [selected]='true'>
                        <mat-icon class="filter-chip-icon" *ngIf="region.selected">check</mat-icon>
                        <mat-icon class="filter-chip-icon" *ngIf="!region.selected">add</mat-icon>
                        {{region.filter}}
                      </mat-chip>
                    </div>
                  </mat-chip-list>
                </div>
              </div>
              <div class="p-0 m_t15" *ngIf="selectedTab == 1">
                <div class="container">
                  <mat-chip-list  #chipList multiple="true" >
                    <div *ngFor="let region of treatmentFilter2">
                      <mat-chip #chip="matChip"  class="filter-chip" (click)="editChip(region)"
                        [class.filterChipActiveBlue]="region.selected" [selected]='true'>
                        <mat-icon class="filter-chip-icon" *ngIf="region.selected">check</mat-icon>
                        <mat-icon class="filter-chip-icon" *ngIf="!region.selected">add</mat-icon>
                        {{region.filter}}
                      </mat-chip>
                    </div>
                  </mat-chip-list>
                </div>
              </div>
              <div class="p-0 m_t15" *ngIf="selectedTab == 2">
                <div class="container">
                  <mat-chip-list  #chipList multiple="true" >
                    <div *ngFor="let region of treatmentFilter3">
                      <mat-chip #chip="matChip"  class="filter-chip" (click)="editChip(region)"
                        [class.filterChipActiveBlue]="region.selected" [selected]='true'>
                        <mat-icon class="filter-chip-icon" *ngIf="region.selected">check</mat-icon>
                        <mat-icon class="filter-chip-icon" *ngIf="!region.selected">add</mat-icon>
                        {{region.filter}}
                      </mat-chip>
                    </div>
                  </mat-chip-list>
                </div>
              </div>
  
            </div>
          </mat-tab>
  
         
  
        </mat-tab-group>
      </div>
      <div class="p-0" style="height: 100%; " *ngIf="treatmentFilerDetail">
        <div class="d-flex flex-column">
          
        <div class="p-0 m_t15">
          <mat-label class='mat-caption'>Filter Titel</mat-label>
          <input class="formControl mat-body" [(ngModel)]="selectedFilter.filter">
        </div>
        <div class="p-0 m_t15">
          <div class="d-flex">
            <div class="p-0 mr2 ml-autp">
              <app-df-icon-button title="Abbrechen" icon="cancel" (clicked)="clearAddingFillter()"></app-df-icon-button>  
            </div>
            <div class="p-0 mr2" *ngIf="selectedFilter.id == -1">
              <app-df-icon-button color="primary" title="Löschen" icon="delete" (clicked)="deleteTreatmentFilter()"></app-df-icon-button>  
            </div>
            <div class="p-0">
              <app-df-icon-button color="primary" title="Speichern" icon="save" (clicked)="saveCurrenFilter()"></app-df-icon-button>  
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <!-- <div class="container-fluid"> -->

    <!-- </div> -->
  <!-- <div class="d-flex flex-column">
    <div class="p-0">
      <mat-toolbar class="overlay-toolbar">
        <mat-label class='mat-caption'>Filter Gruppe hinzufügen</mat-label>
        <span class="toolbar-spacer"></span>
        <app-df-icon-button *ngIf="treatmentFilterAll.length > 0" title="Filter Gruppe" icon="add" (clicked)="toggleRegionDetail(true)"></app-df-icon-button>  
      </mat-toolbar>
    </div>
    <div class="p-0">
      <mat-toolbar class="overlay-toolbar">
        <mat-label class='mat-caption'>Filter 1</mat-label>
        <span class="toolbar-spacer"></span>
        <app-df-icon-button *ngIf="treatmentFilterAll.length > 0" title="Filter" icon="add" (clicked)="toggleRegionDetail(true)"></app-df-icon-button>  
      </mat-toolbar>
    </div>
    <div class="p-0 m_t15" *ngIf="treatmentFilter1.length > 0">
      <mat-chip-list  #chipList multiple="true" >
        <div *ngFor="let region of treatmentFilter1">
          <mat-chip #chip="matChip"  class="filter-chip"
            [class.filterChipActiveBlue]="region.selected" [selected]='true'>
            <mat-icon class="filter-chip-icon" *ngIf="region.selected">check</mat-icon>
            <mat-icon class="filter-chip-icon" *ngIf="!region.selected">add</mat-icon>
            {{region.filter}}
          </mat-chip>
        </div>
  
      </mat-chip-list>
    </div>



    <div class="p-0 m_t15" *ngIf="isRegionDetail">
      <mat-label class='mat-caption'>Filter Titel</mat-label>
      <input class="formControl mat-body" [(ngModel)]="currentRegionTitle">
    </div>
    <div class="p-0" *ngIf="isRegionDetail">
      <mat-toolbar class="overlay-toolbar p0" style="background-color: rgba(0,0,0,0)!important;">
        
        
        <span class="toolbar-spacer"></span>
        
        <app-df-icon-button *ngIf="isRegionDetail" class="mr10"
        title="Abbrechen"
        icon="cancel"
        (clicked)="toggleRegionDetail(false)">
        </app-df-icon-button>  
        <app-df-icon-button *ngIf="isRegionDetail"
        title="Speichern"
        icon="save"
        (clicked)="saveCurrentRegion()">
        </app-df-icon-button>  
      </mat-toolbar>
    </div>
  </div> -->
    
  </div>
</div>

</div>
<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <app-df-icon-button title="Speichern" icon="save" (clicked)="save()" [disabled]="saveBtnDisabled" ></app-df-icon-button>  
</mat-toolbar>