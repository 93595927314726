import { Component, OnInit, Input } from '@angular/core';
import { FooterService } from 'src/app/services/footer.service';

@Component({
  selector: 'template-footer',
  templateUrl: './template-footer.component.html',
  styleUrls: ['./template-footer.component.scss', '../../../../pages/templates-details.scss'],
  viewProviders:[FooterService]
})
export class TemplateFooterComponent implements OnInit {

  // @Input() template:TemplateM;

  _summaryWriter:string = ''
  _currentSummary:number
  _newSummary:number = 0
  _sumTimeout:any
  isSaving = false;
  hasItemChanged = false;
  intermediate
  countTo = 0
  countFrom = 10
  reductionCopy =''

  constructor(public fs:FooterService) { 

  }

  ngOnInit(): void {

    this.fs.summeryChangedEmitter.subscribe(
      incomingSummary => {
        this._checkSum(incomingSummary)
      }
    )
    setTimeout(()=>{
      this.initSummary()
    },2000)
  }

  private _checkSum(newS:number){
    let _incomingNew = Math.round((newS + Number.EPSILON) * 100) / 100;
    if(!this._currentSummary){
      this._currentSummary = _incomingNew;
      this.countFrom = Math.round(((this._currentSummary /2) + Number.EPSILON) *100) / 100;
      this.countTo = Math.round((_incomingNew + Number.EPSILON) *100) / 100;
    } else {

      this.countFrom = Math.round((this._currentSummary + Number.EPSILON) *100) / 100;
      this.countTo = Math.round((_incomingNew + Number.EPSILON) *100) / 100;
    }
    if (this.countTo != this._currentSummary) {
    }
    
  }
  
  hasReduction(){
    if(this.fs.reduction == null) return false;
    switch (this.fs.reduction){
      case 25:
        this.reductionCopy = 'Chefarzt (-25%)';
        break;
      case 15:
        this.reductionCopy = 'Belegarzt (-15%)';
        break;
      case 0:
        this.reductionCopy = '';
        break;
    }
    return this.fs.reduction == null || this.fs.reduction == 0 ? false : true;
  }

  
  initSummary(){

    // // console.log('get current summary')
    // if(this.template == null) return '0';
    // var _sum:number = 0.00;
    // for(var i = 0; i < this.template.sections.length; i ++){
    //   var __section:SectionM = this.template.sections[i];
    //   for (var j = 0; j < __section.positions.length; j++) {
    //     var __position:PositionM = __section.positions[j];
    //     if(__position.activated && __position.goa_paragraph != null){
          
    //       if(__position.goa_paragraph.amount_euro && __position.goa_paragraph.amount_euro != 0){
    //         let _itemAmount:number = ((__position.progression_factor * __position.goa_paragraph.amount_euro) * __position.quantity);
    //         // console.log('_itemAmount ', _itemAmount)
    //         let _itemSum = Math.round((_itemAmount + Number.EPSILON) *100) / 100;
    //         // console.log('_itemSum ', _itemSum)
    //         _sum += _itemSum
    //         _sum =  Math.round((_sum + Number.EPSILON) *100) / 100;
    //         // console.log('_sum ', _sum)
    //       }
          
    //     }      
    //   }
    //   if(__section.materialItems != null && __section.materialItems.length > 0){
    //     for(var k = 0; k < __section.materialItems.length; k ++){
    //       let matItem = __section.materialItems[k]
    //       let _itemAmount:number = matItem.quantity * Number(matItem.preis)
    //       let _itemSum = Math.round((_itemAmount + Number.EPSILON) *100) / 100;
    //       _sum += _itemSum
    //       _sum =  Math.round((_sum + Number.EPSILON) *100) / 100;
    //     }
    //   }
    // }
    


    // if(this.template.reduction != null && this.template.reduction != 0){
    //   let _reduction =  this.template.reduction;
    //   let _tempSum = _sum * (_reduction / 100);
    //   _sum -= _tempSum;
    //   _sum =  Math.round((_sum + Number.EPSILON) *100) / 100;
    // }
    // this._currentSummary = _sum;
    // this.countFrom = Math.round(((this._currentSummary /2) + Number.EPSILON) *100) / 100;
    // this.countTo = Math.round((_sum + Number.EPSILON) *100) / 100;
  }

  summary(){

    // console.log('get current summary')
    // if(this.template == null) return '0';
    // var _sum:number = 0.00;
    // for(var i = 0; i < this.template.sections.length; i ++){
    //   var __section:SectionM = this.template.sections[i];
    //   for (var j = 0; j < __section.positions.length; j++) {
    //     var __position:PositionM = __section.positions[j];
    //     if(__position.activated && __position.goa_paragraph != null){
          
    //       if(__position.goa_paragraph.amount_euro && __position.goa_paragraph.amount_euro != 0){
    //         let _itemAmount:number = ((__position.progression_factor * __position.goa_paragraph.amount_euro) * __position.quantity);
    //         // console.log('_itemAmount ', _itemAmount)
    //         let _itemSum = Math.round((_itemAmount + Number.EPSILON) *100) / 100;
    //         // console.log('_itemSum ', _itemSum)
    //         _sum += _itemSum
    //         _sum =  Math.round((_sum + Number.EPSILON) *100) / 100;
    //         // console.log('_sum ', _sum)
    //       }
          
    //     }      
    //   }
    //   if(__section.materialItems != null && __section.materialItems.length > 0){
    //     for(var k = 0; k < __section.materialItems.length; k ++){
    //       let matItem = __section.materialItems[k]
    //       let _itemAmount:number = matItem.quantity * Number(matItem.preis)
    //       let _itemSum = Math.round((_itemAmount + Number.EPSILON) *100) / 100;
    //       _sum += _itemSum
    //       _sum =  Math.round((_sum + Number.EPSILON) *100) / 100;
    //     }
    //   }
    // }

    // if(this.template.reduction != null && this.template.reduction != 0){
    //   let _reduction =  this.template.reduction;
    //   let _tempSum = _sum * (_reduction / 100);
    //   _sum -= _tempSum;
    //   _sum =  Math.round((_sum + Number.EPSILON) *100) / 100;
    // }

    // this.countFrom = Math.round((this._currentSummary + Number.EPSILON) *100) / 100;
    // this.countTo = Math.round((_sum + Number.EPSILON) *100) / 100;

     
  }

  getError() : number{
    var _errorCount = 0;

    // if(this.template == null) return _errorCount;
    
    // for(var i = 0; i < this.template.sections.length; i ++){
    //   var __section:SectionM = this.template.sections[i];
    //   for (var j = 0; j < __section.positions.length; j++) {
    //     var __position:PositionM = __section.positions[j];
    //     if(__position.activated && !__position.indented && __position.hasError === true) {      
    //       _errorCount ++;
    //     }      
    //   }
    // }
    return _errorCount;
  }

  onCountoEnd() {
    
    this._currentSummary = this.countTo;
    this.fs.changeSum(this._currentSummary)
  }
 
}
