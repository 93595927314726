import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertI, AlertActionI } from 'src/app/core/models/alertM';

@Component({
  selector: 'app-alert-overlay',
  templateUrl: './alert-overlay.component.html',
  styleUrls: ['./alert-overlay.component.scss']
})
export class AlertOverlayComponent implements OnInit {

  title = ""
  constructor(public dialogRef: MatDialogRef<AlertOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertI) {
      this.title = data.title
    }

  ngOnInit(): void {
  }

  // cancel(){
  //   let result: AlertResultI = {
  //     canceled: true
  //   }
  //   this.dialogRef.close(result)
  // }
  // ready(){
  //   let result: AlertResultI = {
  //     canceled: false
  //   }
  //   this.dialogRef.close(result)
  // }
  tapped(action:AlertActionI.BUTTON_ACTION_TYPE){
    console.log(' TAPPED ', action)
     this.dialogRef.close(action)
  }

  colorForActionColorType(type :AlertActionI.BUTTON_COLOR_TYPE):string{
    switch(type) {
      case AlertActionI.BUTTON_COLOR_TYPE.BASIC:
        return 'basic';
      case AlertActionI.BUTTON_COLOR_TYPE.PRIMARY:
          return 'primary';
      case AlertActionI.BUTTON_COLOR_TYPE.ACCENT:
          return 'accent';
      case AlertActionI.BUTTON_COLOR_TYPE.WARN:
          return 'warn';
    }
  }

}
