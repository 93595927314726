import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JustificationTextItem, PositionFlatI} from 'src/app/core/models/sectionPositionM';
import {  MatTabGroup } from '@angular/material/tabs';
import { GoaJustificationEditI, GoaJustificationI } from 'src/app/core/models/goaJustificationI';
import { TemplateControllerService } from 'src/app/services/template-controller.service';

@Component({
  selector: 'app-position-jusification-overlay',
  templateUrl: './position-jusification-overlay.component.html',
  styleUrls: ['./position-jusification-overlay.component.scss']
})
export class PositionJusificationOverlayComponent implements OnInit {

  @ViewChild('tabbar') tabbar:MatTabGroup
  selectedIndex:number=0;
  editable = true;
  newFactor = false;
  newFactorValue = 6.5
  buttonEnabled = false
  goaJustifications:GoaJustificationI[]
  editData:GoaJustificationEditI
  position:PositionFlatI
  _currentFactor = 0
  text:string = ''
  currentGoaeJustification:GoaJustificationI
  constructor(
    private tc:TemplateControllerService,
    public dialogRef: MatDialogRef<PositionJusificationOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GoaJustificationEditI
    ) {
      this.goaJustifications = data.justifications
     this.editData = data
     this.position = data.positionModel
 
  }
  
  ngOnInit(): void {
    this._currentFactor = this.editData.positionModel.progression_factor

    this.selectedIndex = this.editData.selectedIndex
   
   setTimeout(()=>{
    this.initCurrentItem(this.editData.addNewItem)
   },100)
    // if(this.data.addNewProgressionFactor === true){
     
    // } else {
    //   if(this.data.positionModel.justification_items != null) {
    //     for(const item of this.data.positionModel.justification_items) {
    //       if(this.data.positionModel.progression_factor === item.progression_factor){
    //         this.justification = item;
    //         break
    //       }
    //     }
    //   } else if(this.data.positionModel.justification_items == null){
    //     this.justification.text_items.push({selected:true, text:''})
    //   }
    //   setTimeout(()=>{
    //     this.selectedIndex = this.data.selectedIndex
    //   },500)
    //   this.justification.progression_factor =  this.data.positionModel.progression_factor
    // }
    
  }
  // justification_items:JustificationTextItem[];
  initCurrentItem(addNew:boolean){
    const _currentFactorItem = this.editData.factorItems.find(item => item.factor == this._currentFactor);
    console.log(_currentFactorItem)
    var _txt = ''

    if(addNew){
      this.currentGoaeJustification = {
        id: -1,
        position_id: this.position.position_id,
        section_id: this.position.section_id, 
       // is_custom_factor: _currentFactorItem == null  || _currentFactorItem.isPublic == null || !_currentFactorItem.isPublic ? true : false,
        //progression_factor: this._currentFactor,
        text: '', 
        description: '',
        //progression_factor_id:  _currentFactorItem == null ||  _currentFactorItem.id == null ? null : _currentFactorItem.id,
      }
      this.goaJustifications.push(this.currentGoaeJustification)
      this.selectedIndex = this.goaJustifications.length - 1
    } else {
      if(this.goaJustifications != null && this.goaJustifications[this.selectedIndex]){
        
        
        this.currentGoaeJustification = {
          section_id: this.position.section_id,
          text: this.goaJustifications[this.selectedIndex].text,
          //progression_factor: this._currentFactor,
          //progression_factor_id:  _currentFactorItem.id,
          id: addNew ? -1 : this.goaJustifications[this.selectedIndex].id,
          //is_custom_factor: _currentFactorItem.isPublic ? false : true
        }
      } else {
  
        this.currentGoaeJustification = {
          section_id: this.position.section_id,
          text: _txt,
          //progression_factor: this._currentFactor,
          //progression_factor_id: _currentFactorItem.id,
          id: -1,
          //is_custom_factor: _currentFactorItem.isPublic ? false : true
        }
      }
    }
    
  }
  addItem(){
    let jI:JustificationTextItem = {
      selected: false,
      text:''
   }
   this.initCurrentItem(true)
  //  this.openJustificationOverlay(true);
 /*   this.justification.text_items.push(jI)
   this.selectedIndex = this.justification.text_items.length-1; */
  }
  removeItem(){
   /*  this.justification.text_items.splice(this.selectedIndex,1);
    setTimeout(()=>{
      this.selectedIndex --
    },10) */
  }
  selectJustification(item:JustificationTextItem, index:number){
    // console.log('item.selected ', item.selected)
   /*  if(item.selected){
      for (var i = 0; i < this.justification.text_items.length; i++) {
        if(i != index){
          this.justification.text_items[i].selected = false;
        }        
      }
    } */
  }
  get goaJustificationsCount():number {
    return this.goaJustifications.length//.filter(item => item.progression_factor == this._currentFactor).length
  }
  get copy():string {
    if(this.currentGoaeJustification && this.currentGoaeJustification.text){
      return this.currentGoaeJustification.text
    }
  /*   if(this.justification.text_items[this.selectedIndex] == null) { return ""}
    return this.justification.text_items[this.selectedIndex].text */
    return ''
  }
  async save(){
    // if(this.currentGoaeJustification.id > 0){
    //   this.tc.updateGoaJustification(this.currentGoaeJustification.id, this.currentGoaeJustification.text)
    // } else {
    //   const _result = await this.tc.addGoaJustification(this.position.position_id, this.currentGoaeJustification)
    //   this.currentGoaeJustification.id = _result['insertId']
    // }
    // this.dialogRef.close(undefined)
  }
  justificatio_changed(e){
    this.currentGoaeJustification.text = e.target.value
    console.log(this.currentGoaeJustification.text)
    // console.log(this.justification.text_items)
    //this.justification.text_items[this.selectedIndex].text = e.target.value;
  }
  newFactor_changed(e){

    
    var _newInput:string = e.target.value;
    _newInput = _newInput.replace(',', '.');
    // if(this.newFactorValue == null || this.newFactorValue == '' ) {
    //   this.justification.selected_progression_factor = parseFloat(_newInput);
    //   this.justification.progression_factor = parseFloat(_newInput);
    //   this.justification.text_items = [{selected:true, text:''}]
    // }
    

    // console.log(_newInput);
    // var _fact = 100;
    // console.log(_fact * parseFloat(_newInput));
  /*   this.newFactorValue = parseFloat(_newInput);
    this.justification.progression_factor = parseFloat(_newInput); */
  }
  close(){
    this.dialogRef.close(undefined);
  }
  nextItem(){
    if(this.selectedIndex < this.goaJustifications.length -1){
      this.selectedIndex ++
    this.initCurrentItem(false)
    }
    
   /*  if(!this.editable){
      return;
    } */
   /*  if(this.selectedIndex < this.justification.text_items.length -1){
      this.selectedIndex ++
    } */
  }
  prevItem(){
 /*    if(!this.editable){
      return;
    } */
    if(this.selectedIndex > 0){
      this.selectedIndex --
    }
    this.initCurrentItem(false)
  }
  
}
