import { CdkDragDrop, copyArrayItem } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { TemplateFlatI } from 'src/app/core/models/api/TemplateFlatI';
import { SPLIT_SCREEN_CHANGED, SPLIT_SCREEN_VIEW_STATE } from 'src/app/core/models/enums';
import { SectionM } from 'src/app/core/models/sectionM';
import { PositionFlatI, SECTION_DROPLIST } from 'src/app/core/models/sectionPositionM';
import { TemplateApiService } from 'src/app/services/template-api.service';
import { UiService } from 'src/app/services/ui.service';
import * as _ from 'lodash';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { TEMPLATES_CALL } from 'src/app/services/enums/templateS.callEnum';

@Component({
  selector: 'app-template-copy-splitscreen',
  templateUrl: './template-copy-splitscreen.component.html',
  styleUrls: ['./template-copy-splitscreen.component.scss']
})
export class TemplateCopySplitscreenComponent implements OnInit, OnDestroy {

  @Input() originTemplateID:number
  @Input() origin_sections:SectionM[]
  @Input() elementHeight:number
  @ViewChild('splitScreenView') splitScreenView:ElementRef
  private _templateSortName:number = 0;
  private _templateSortTime:number = 0;
  private sortNameArr:string[] = ['nameAsc', 'nameDesc', ''];
  private sortDateArr:string[] = ['','dateAsc', 'dateDesc'];
  private _templateSplitScreenToggleSubscription:Subscription;
  templates:TemplateFlatI[] = []

  get templateNameSort():string {
    return this.sortNameArr[this._templateSortName];
  }
  get templateTimeSort():string {
    return this.sortDateArr[this._templateSortTime];
  }
  _selectedTemplate:TemplateFlatI

  filteredTemplates:TemplateFlatI[] = []
  showAll = false
  sections:SectionM[] = []

  destinationIds:string[] = []

  destinationDropList:SECTION_DROPLIST[] = []

  panelStates:boolean[] = []

  currentDestinationExpansionPanelIndex = -1
  currenrRemoteExpansionPanelIndex = -1

  constructor(private api:TemplateApiService, public ui:UiService, private tc:TemplateControllerService) { }

  get containerHeight():string{

    return this.ui.splitScreenState == SPLIT_SCREEN_VIEW_STATE.TEMPLATE_SELECTED ? (this.elementHeight + 50).toString() : this.elementHeight.toString()
  }
  ngOnInit(): void {
    this.loadTemplates();
    for(var i = 0; i < this.origin_sections.length; i ++){
      this.destinationIds.push('section_index_' + this.origin_sections[i].order_index.toString())
      this.destinationDropList.push({
        order_index: i,
        section: this.origin_sections[i],
        itemId: 'section_index_' + this.origin_sections[i].order_index.toString(),
        positions:[]
      })
      // id="{{ '_section_index_' + oSection.index }}"
    }
    this._templateSplitScreenToggleSubscription = this.ui.templateSplitScreenViewChanged.subscribe(
      _state => {
        this.toggleSplitScreen(_state)
      }
    )

    setTimeout(()=>{
      this.showAll = true

    },800)
  }
  toggleSplitScreen(values:SPLIT_SCREEN_CHANGED){
    if(values.oldValue == SPLIT_SCREEN_VIEW_STATE.TEMPLATE_SELECTED && values.newValue == SPLIT_SCREEN_VIEW_STATE.TEMPLATE_LIST){
      this._selectedTemplate = null
    } else if(values.newValue != SPLIT_SCREEN_VIEW_STATE.TEMPLATE_SELECTED ) {
      this.ui.splitScreenTemplateName = ''
    }
  }
  async loadTemplates(){
    
    console.log("loadTemplates");
    this.templates = await this.api.apiGet(TEMPLATES_CALL.TEMPLATE_OVERVIEW_OWN) as TemplateFlatI[]
    console.log(this.templates);
    this.filteredTemplates = this.templates
    this.joinTemplates()
  }

  closedRemotePanelIndex(index){
    if(this.currenrRemoteExpansionPanelIndex === index){
      this.currenrRemoteExpansionPanelIndex = -1;
    }
   }

  openedRemotePanelIndex(index){
    this.currenrRemoteExpansionPanelIndex = index
  }

  closedDestinationPanelIndex(index){
    if(this.currentDestinationExpansionPanelIndex === index){
      this.currentDestinationExpansionPanelIndex = -1;
    }
  }
  applyFilter(filterValue: string) {
    if(filterValue.length == 0) {
      // this.filteredTemplates = this.templates
      this.filteredTemplates = this.templates.filter((item) => item.template_id != this.originTemplateID)
      return
    }
    this.filteredTemplates = this.templates.filter(item => item.name.toLowerCase().includes(filterValue.toLowerCase()));
    /* this.filteredTemplates.filter(item => item.name.startsWith(filterValue)); */
  }
  onDestinationDragStarted(e){
     this.currentDestinationExpansionPanelIndex = -1
     this.currenrRemoteExpansionPanelIndex = -1
      console.log(e)
    }
    openedDestinationPanelIndex(index){
      this.currentDestinationExpansionPanelIndex = index
     }
  onPositionSelected(e){
    console.log(e)
  }
  selectTemplate(pT:TemplateFlatI){
    this._selectedTemplate = pT;
    this.sections = []
    this.loadTemplateSingle()
  }

  async loadTemplateSingle(){
    const _detail = await this.api.templateDetail(this._selectedTemplate.template_id);
    const _sections = await this.tc.loadSectionsForCopy(this._selectedTemplate.template_id, this.tc.prefix)

    this.sections = _sections;
    this.panelStates = []
    for(const __s of _sections){
      this.panelStates.push(false);
    }

     this.ui.toggleSplitScreenState(SPLIT_SCREEN_VIEW_STATE.TEMPLATE_SELECTED)
     this.ui.splitScreenTemplateName = _detail['name']

  }


  dropPosition(event){
    if (event.previousContainer === event.container) {

     //moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      var _section:SectionM = this.destinationDropList.find((item) => item.itemId == event.container.id).section
      // console.log('OTHER CONTAINER', event.item.data)
      var newPOs:PositionFlatI = event.item.data
      newPOs.position_order_index = event.currentIndex
      // _section.positions.join()
      // _section.positions.splice(event.currentIndex, 0, newPOs)
      // _section.positions.join()

     /*  for(var i = 0; i < _section.positions.length; i ++){
        _section.positions[i].index = i
      }   */
    }
    // ControllerService.saveTemplate.emit(this.originTemplateID);
   }

   dropSectionObject(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      
    } else {
      this.moveSectionToTempalte(event.item.data.section_id)

      copyArrayItem(event.previousContainer.data, event.container.data, event.previousIndex,event.currentIndex);
    }
    this.updateSectionIndex(0);
  }

  private async moveSectionToTempalte(section_id:number){
    await this.tc.moveSectionToTemplate(this.tc.prefix, section_id, this.tc.currentTemplateId)
  }

  updateSectionIndex(startIndex: number){
   this.destinationIds = []
   this.destinationDropList = []
   for (let i = startIndex; i < this.origin_sections.length; i++){
     this.origin_sections[i].order_index = i;
     this.destinationIds.push('section_index_' + i.toString())
     this.destinationDropList.push({
      order_index: i,
       section: this.origin_sections[i],
       itemId: 'section_index_' + i.toString(),
       positions:[]
     })
   }

    //  ControllerService.saveTemplate.emit(this.originTemplateID);
   }
   toggleTemplateTimeSort(){
    if(this._templateSortTime == 2){
      this._templateSortTime = 0;
    } else {
      this._templateSortTime ++;
    }
    this.joinTemplates()
  }

  toggleTemplateNameSort(){
    if(this._templateSortName == 2){
      this._templateSortName = 0;
    } else {
      this._templateSortName ++;
    }
    this.joinTemplates()
  }

  get templateSort():string {
    const _name = this.templateNameSort
    const _date = this.templateTimeSort
    if(_date == ''){

      return _name == 'nameDesc' ? 'NAME_DESC' : 'NAME_ASC';

    } else if(_date == 'dateAsc'){

      if(_name == '') {
        return 'DATE_ASC'
      } else {
        return _name == 'nameDesc' ? 'DATE_ASC_NAME_DESC' : 'DATE_ASC_NAME_ASC';
      }

    } else if(_date == 'dateDesc'){
      if(_name == '') {
        return 'DATE_DESC'
      } else {
        return _name == 'nameDesc' ? 'DATE_DESC_NAME_DESC' : 'DATE_DESC_NAME_ASC';
      }

    }
  }

  private joinTemplates(){
    switch(this.templateSort){
      case "DATE_DESC_NAME_ASC":
        this.filteredTemplates = _.orderBy(this.filteredTemplates, [
          template => template.lastModified,
          template => template.name.toLowerCase(),
        ], ['desc', 'asc']);
        break;
      case "DATE_ASC_NAME_DESC":
        this.filteredTemplates = _.orderBy(this.filteredTemplates, [
          template => template.lastModified,
          template => template.name.toLowerCase(),
        ], ['asc', 'desc']);
        break;
      case "DATE_ASC_NAME_ASC":
        this.filteredTemplates = _.orderBy(this.filteredTemplates, [
          template => template.lastModified,
          template => template.name.toLowerCase(),
        ], ['asc', 'asc']);
        break;
      case "DATE_DESC_NAME_DESC":
        this.filteredTemplates = _.orderBy(this.filteredTemplates, [
          template => template.lastModified,
          template => template.name.toLowerCase(),
        ], ['desc', 'desc']);
        break;
      case "DATE_DESC":
        this.filteredTemplates = _.orderBy(this.filteredTemplates, [
          template => template.lastModified,
        ], ['desc']);
        break;
      case "DATE_ASC":
        this.filteredTemplates = _.orderBy(this.filteredTemplates, [
          template => template.lastModified
        ], ['asc']);
        break;
      case "NAME_DESC":
        this.filteredTemplates = _.orderBy(this.filteredTemplates, [
          template => template.name.toLowerCase()
        ], ['desc']);
        break;
      case "NAME_ASC":
        this.filteredTemplates = _.orderBy(this.filteredTemplates, [
          template => template.name.toLowerCase()
        ], ['asc']);
        break;
    }
    this.filteredTemplates = this.filteredTemplates.filter((item) => item.template_id != this.originTemplateID)
  }

  ngOnDestroy(): void {
    if(this._templateSplitScreenToggleSubscription){
      this._templateSplitScreenToggleSubscription.unsubscribe();
    }
    this.tc.reInitTemplateSectionsAndPositions()
  }

  deleteSection(section:SectionM){
    const _index = this.origin_sections.findIndex(item => item.section_id == section.section_id);
    if(_index != -1){
      this.origin_sections.splice(_index, 1);
      this.tc.deleteSection(section.section_id, this.tc.currentTemplateId, this.tc.prefix);
      this.updateSectionIndex(0);
    }
  }


}
