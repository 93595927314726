<div class="p-0 flex-fill _settingsPanel" [class._settingsPanelUSERS]="panelType == 'USERS'" #_settingsPanel>

    <div style="width: 100%; height: 100%; " class="p0" #_settingsPanelCard>

      <div class="d-flex flex-column">

        <div class="p-2 p_t15 ml13">
          <mat-card-title>{{panelTitle}}</mat-card-title>
        </div>

        <div class="p-0 flex-fill">
      
          <app-settings-expertises  *ngIf="panelType == 'EXPERTISES'">

          </app-settings-expertises>

          <app-settings-patients *ngIf="panelType == 'PATIENT'"
          ></app-settings-patients>

          <app-settings-employees *ngIf="panelType == 'EMPLOYEE'"
          ></app-settings-employees>

          <app-settings-own-position-items *ngIf="panelType == 'OWN_BILLIG'"
          ></app-settings-own-position-items>

          <app-settings-perioperative-angaben *ngIf="panelType == 'OP_ITEM'"
          ></app-settings-perioperative-angaben>

          <app-settings-material *ngIf="panelType == 'MATERIAL'"
          ></app-settings-material>

          <app-settings-goa *ngIf="panelType == 'GOAE'"
          ></app-settings-goa>
        </div>

      </div>

  </div>

</div>
