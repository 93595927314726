import { environment } from "src/environments/environment";

export enum T_POSITION_CALL {

  CLONE_POSITION,
  POSITIONS_BY_T_ID,
  POSITIONS_BY_S_ID,
  POSITION_DELETE,
  POSITION_UPDATE_SINGLE_ITEM,
  POSITION_UPDATE,
  POSITION_CREATE,
  REPORT_POS_ITEMS,
  GET_SINGLE_POSITION_FULL
}

export function POSITIONS_ENDPOINT(prefix:string,call: T_POSITION_CALL, params: string = '') : string {

  switch(call){

      case T_POSITION_CALL.CLONE_POSITION: return `${environment.endpoints.apiBasePathV2}${prefix}/positions/clone-by`;
      case T_POSITION_CALL.POSITIONS_BY_T_ID: return `${environment.endpoints.apiBasePathV2}${prefix}/positions/by-template-id/${params}`;

      case T_POSITION_CALL.POSITIONS_BY_S_ID: return `${environment.endpoints.apiBasePathV2}${prefix}/positions/by-section-id/${params}`;
      
      case T_POSITION_CALL.POSITION_DELETE: return `${environment.endpoints.apiBasePathV2}${prefix}/positions/delete/${params}`;
      case T_POSITION_CALL.POSITION_UPDATE_SINGLE_ITEM: return `${environment.endpoints.apiBasePathV2}${prefix}/positions/update-single-item/${params}`;
      case T_POSITION_CALL.POSITION_CREATE: return `${environment.endpoints.apiBasePathV2}${prefix}/positions/create/`;
      case T_POSITION_CALL.POSITION_UPDATE: return `${environment.endpoints.apiBasePathV2}${prefix}/positions/update/`;
      
      case T_POSITION_CALL.REPORT_POS_ITEMS: return `${environment.endpoints.apiBasePathV2}${prefix}/positions/report-position-items/${params}`;
      
      case T_POSITION_CALL.GET_SINGLE_POSITION_FULL: return `${environment.endpoints.apiBasePathV2}${prefix}/positions/${params}`;

    }

}
