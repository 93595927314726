import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { apiRequest, API_CALL } from 'src/app/core/models/api/apiCalls';
import { AreaOfExpertise } from 'src/app/core/models/expertiseArea';
import { ApiUtilService } from 'src/app/services/api-util.service';

import { UiService } from 'src/app/services/ui.service';
import { SettingsExpertisesDetailComponent } from 'src/app/ui/overlays/settings-expertises-detail/settings-expertises-detail.component';

@Component({
  selector: 'app-settings-expertises',
  templateUrl: './settings-expertises.component.html',
  // styleUrls: ['./settings-expertises.component.scss']
  styleUrls: ['../settings-panel.scss']
})
export class SettingsExpertisesComponent implements OnInit,OnDestroy {
  fachgebiete:AreaOfExpertise[] = []
  changeHeighSubscription:Subscription;
  displayedColumns = ['name', 'short'];
  _currentHeight:number = 0
  dataSource:any;
  constructor(
    private apiUtil:ApiUtilService,
    private ui:UiService,
    private dialog:MatDialog
  ) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.loadData()
      this.changeHeighSubscription = this.ui.settingsPanelHeightChanged.subscribe(
        _newHeight =>{
          this._currentHeight = _newHeight
        }
      )
    },120)
  }
  async loadData(){
    const _data = await this.apiUtil.apiGetP(apiRequest(API_CALL.GET_AREAS_OF_EXPERTISE_ADMIN))
    console.log(_data)
    this.fachgebiete = _data as AreaOfExpertise []
    this.dataSource = new MatTableDataSource(this.fachgebiete)
  }
  openDetails(row){
    this.dialog.open(SettingsExpertisesDetailComponent,{
      width:"70%",
      maxWidth:"1000px",
      height:"360px",
      backdropClass:"backdrop-background",
      data: row
    }).afterClosed().subscribe(
      data=>{
        this.loadData()
      }
    )
  }
  add(){
   let _newItem:AreaOfExpertise = {
      id: -1,
      is_active: true,
      name: '',
      short: ''
    }
    this.dialog.open(SettingsExpertisesDetailComponent,{
      width:"70%",
      maxWidth:"1000px",
      height:"360px",
      backdropClass:"backdrop-background",
      data: _newItem
    }).afterClosed().subscribe(
      data=>{
        this.loadData()
      }
    )

  }
  get getPanelHeight():string{
    return this._currentHeight + 'px'
  }

  ngOnDestroy(): void {
    if(this.changeHeighSubscription != null){
      this.changeHeighSubscription.unsubscribe()
    }
  }
}
