
<div class="container-fluid p0" style="padding-right:10px!important;">
  <!-- style="padding-top: 10px!important;padding-left: 30px!important; padding-right: 30px!important;" -->
  <div class="d-flex justify-content-between ">
    <div class="p-2 flex-fill">
      <mat-form-field class="textfield100 select-form-field" appearance="outline">
        <input matInput placeholder="Meine Patienten durchsuchen..." (keyup)="applyFilter($event.target.value)" name="search">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <!-- <mat-form-field class="textfield100" appearance="outline">
        <input matInput placeholder="Meine Patienten durchsuchen..." (keyup)="applyFilter($event.target.value)" name="search">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field> -->
    </div>
    <div class="p-0 ml-auto" >
      <div class="d-flex table_cell_button m_t12" (click)='addPatient()'>
        <div class="p-0 align-text-end ml-auto ">
          <mat-icon class="table_cell_button_icon">add</mat-icon>
        </div>
        <div class="p-0 table_cell_button_copy">Neuer Patient</div>
      </div>
      <!-- <button mat-icon-button color='accent' class="mat-elevation-z0" (click)='addPatient()'><mat-icon>add</mat-icon></button> -->
    </div>
    <!-- <div class="p-0 ml10 " *ngIf='showDeletePatientDecyptButton'>
      <div class="d-flex table_cell_button m_t12" (click)='deletePatientEncryption()'>
        <div class="p-0 align-text-end ml-auto">
          <mat-icon class="table_cell_button_icon">delete</mat-icon>
        </div>
        <div class="p-0 table_cell_button_copy">Verschlüsselung aufheben</div>
      </div>
    </div> -->
  </div>
</div>


<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0" style="width: 100%; ">

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 24px!important;">Nachname </th>
        <td mat-cell *matCellDef="let element" style="padding-left: 24px!important;">{{element.lastName}} </td>
        <!-- | decrypt:k -->
      </ng-container>
    
      
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Vorname </th>
        <td mat-cell *matCellDef="let element"> {{element.firstName}}</td>
        <!-- | decrypt:k -->
      </ng-container>
      <ng-container matColumnDef="birthDate">
        <th mat-header-cell *matHeaderCellDef > Geburtsdatum </th>
        <td mat-cell *matCellDef="let element"> {{element.birthDate | date:'dd.MM.yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ort </th>
        <td mat-cell *matCellDef="let element"> {{element.city}}</td>
      </ng-container>
      <ng-container matColumnDef="zip">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Plz </th>
        <td mat-cell *matCellDef="let element"> {{element.zip}}</td>
      </ng-container>
      <!-- <ng-container matColumnDef="zip" stickyEnd>
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: right; width: 20px;" stickyEnd> Aktiviert </th>
        <td mat-cell *matCellDef="let element"> 
          <div class="patientActiveBox" 
          [class.patientActiveBoxDisabled]='!element.enabled'
          [class.patientActiveBoxEnabled]='element.enabled'>
        </div>
        </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="element-row" *matRowDef="let row; columns: displayedColumns;" (click)='openPatient(row)'></tr>

</table>
