import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountI, AddressI } from 'src/app/core/models/accountI';
import { API_CALL, apiRequest } from 'src/app/core/models/api/apiCalls';
import { AccountService } from 'src/app/services/account.service';
import { ApiUtilService } from 'src/app/services/api-util.service';
import { ACCOUNT_CALL } from 'src/app/services/enums/accountS.callEnum';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-address-list-item',
  templateUrl: './address-list-item.component.html',
  styleUrls: ['./address-list-item.component.scss']
})
export class AddressListItemComponent implements OnInit, OnDestroy {
  @Input() address:AddressI
  @Input() addIndex:number
  @Input() account:AccountI
  @Input() showSetBaseAddress:boolean = true
  @Input() showFunktion:boolean = true
  @Input() showPhone:boolean = false
  @Output() baseAddressChanged: EventEmitter<number> = new EventEmitter<number>()
  isSaving = false;
  // _klinikName
  @ViewChild('_klinikName') _klinikName: ElementRef
  constructor(
    private accountS:AccountService,
    private dialog:MatDialog,
    public ui:UiService,
    private apiUtil:ApiUtilService
  ) { }
  ngOnDestroy(): void {
    console.log('ngOnDestroy ', this.addIndex);
  }

  ngOnInit(): void {
    console.log('ngOnInit ', this.addIndex);
    setTimeout(() => {
      this._klinikName.nativeElement.focus()
    },450)
    
  }
  async save(){
    this.address.behandelnder_arzt = this.account.doc_name
    this.address.facharzt_bezeichnung = this.account.doc_description;
    this.isSaving = true;
    if(this.address.id == -1){
      const __data = await this.accountS.apiPostObject(ACCOUNT_CALL.CREATE_NEW_ADDRESS, {payload: this.address} )
      .then(
        (data)=>{
          this.ui.loadAddresses()
          setTimeout(()=>{
            this.isSaving = false;
          },250)
        }, err =>{
          this.isSaving = false;
        })

    } else {
      const __data = await this.accountS.apiPostObjectAndParams(ACCOUNT_CALL.UPDATE_ADDRESS_BY_ID,this.address.id.toString() ,
      { address:this.address } )
      .then(
        (data)=>{
          this.ui.loadAddresses()
          setTimeout(()=>{
            this.isSaving = false;
          },250)
        }, err =>{
          this.isSaving = false;
        })

    }
  }
  // addAdress(){
  //   this.address = {
  //     id:-1,
  //     abteilung:"",
  //     arzt_role:"",
  //     bank_name:"",
  //     behandelnder_arzt:"",
  //     bic:"",
  //     city:"",
  //     facharzt_bezeichnung:"",
  //     iban:"",
  //     klinikName:"",
  //     kontoinhaber:"",
  //     streetName:"",
  //     streetNumber:"",
  //     ust_nr:"",
  //     zip:""

  //   }
  //   this.address.behandelnder_arzt = this.account.doc_name

  //   this.address.facharzt_bezeichnung = this.account.doc_description;
  // }
  async setAsBaseAddress(){
    this.baseAddressChanged.emit(this.address.id)
    // await this.apiUtil.apiGetP(apiRequest(API_CALL.SET_BASE_ADDRESS, this.address.id.toString()))
    // setTimeout(()=>{
    //   this.ui.loadAddresses()
    // },100)
  }
  get saveDisabled():boolean {
    if(this.address.city == null || this.address.city == ''
    || this.address.streetNumber == '' || this.address.streetNumber == null
    || this.address.klinikName == null || this.address.klinikName == ''
    || this.address.streetName == null || this.address.streetName == ''
    || this.address.zip == null || this.address.zip == ''
    ) { return true}
    return false;
  }
}
