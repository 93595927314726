import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UiService } from 'src/app/services/ui.service';
import { TemplateApiService } from 'src/app/services/template-api.service';
import { OverviewProcessI } from 'src/app/core/models/v4/processI';
import { TEMPLATES_CALL } from 'src/app/services/enums/templateS.callEnum';
import { of } from 'rxjs';
@Component({
  selector: 'app-dashboard-processes-item',
  templateUrl: './dashboard-processes-item.component.html',
  styleUrls: ['../dashboard-items.scss']
})
export class DashboardProcessesItemComponent implements OnInit {
  isLoading = true;
  templates:OverviewProcessI[] = []

  showCard = false;
  isEmpty: boolean = false;
  randomTime:number = 200;
  winWasOpen = false;

  constructor(
    private router:Router,
    public ui:UiService,
    private templateApi:TemplateApiService,
    ) { }


  ngOnInit(): void {
    this.isLoading = true;
    this.randomTime = Math.random() * (400 - 150) + 150;
      setTimeout(()=>{
        this._startLoading();
      },500 )
    }

    private _startLoading(){
      const _uid = this.ui.uid;
      if(!_uid){
        setTimeout(()=>{
          this._startLoading();
        },600)
      } else {
        this.loadTemplateItems();
      }
    }
  async loadTemplateItems(){
    const items:any = await this.templateApi.apiGet(TEMPLATES_CALL.GET_LAST_MODIFIED_PROCESSES)
    if (items) {
      this.templates = items
      console.log('this.templates', this.templates);

      this.isEmpty = this.templates.length > 0 ? false : true;
      this.fadeIn();
    }
  }


  fadeIn(){
    if(this.isLoading === false){
      return
    }
    this.isLoading = false;
    setTimeout(()=>{
      this.showCard = true
    },this.randomTime);
  }

  getDisplayName(t:OverviewProcessI):string {
    //return t.name
    var _out = ''
    if ( this.ui.isClinicUser ) {
      if (t.clinicProcessNumber != null) {
        return `${t.clinicProcessNumber}`
      }
    } else {
      if(t.lastName) {
        _out += t.lastName
        _out += " "
      }

      if(t.firstName) {
        _out += t.firstName

      }
    }

    return _out
  }

  getDateFromTS(t:OverviewProcessI) : Date {
    return t.lastModified != null ? new Date(t.lastModified) : new Date(Date.now())
  }
  openDetails(path:number, scs:boolean = false){
    this.router.navigate(['vorgaenge/detail', path]);
  }

  get patientTitle() : string {
    if ( this.ui.isClinicUser ) {
      return "Fallnummer"
    } else {
      return "Patient"
    }

  }

}
