import { Component, OnInit } from '@angular/core';
import { TREATMENT_FILTER_LEVEL } from 'src/app/core/models/treatmentRegionM';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'expertise-area-header',
  templateUrl: './expertise-area-header.component.html',
  styleUrls: ['./expertise-area-header.component.scss']
})
export class ExpertiseAreaHeaderComponent implements OnInit {

  constructor(
    public tc:TemplateControllerService,
    public ui:UiService
  ) { }

  ngOnInit(): void {
  }
  async expertiseChanged(e) {
    console.log('\n\n\n================\n\n\n')

    console.log(e.value)
    console.log('\n\n\n================\n\n\n')
    this.ui.fachgebiet = e.value
    // this.ui.fachgebiet = this.ui.fachgebiete.find(item => item.fachgebiet_id == this.ui.treatmentRegions2[_index].fachgebiet_id)
    this.tc.updateTemplateItem({fachgebiet_id: e.value.fachgebiet_id})
    var _newFirstFilterValue = -1
    var _treatmentFiltersFirst = this.ui.allTreatmentFilterByCurrentFach.filter(item => item.filter_level == TREATMENT_FILTER_LEVEL.FIRST);
    
    if(_treatmentFiltersFirst.length > 0){
      // console.log(_treatmentFiltersFirst)
      const _index = _treatmentFiltersFirst.findIndex(item => item.filter == 'Diversa')
      if(_index != -1){
        const _filterItem = _treatmentFiltersFirst[_index]
        // console.log(_filterItem)
        _newFirstFilterValue = _filterItem.id
      } else {
        _newFirstFilterValue = _treatmentFiltersFirst[0].id
        // console.log(_treatmentFiltersFirst[0])
      }
    }
    this.tc.updateTemplateItem({treatmentfilter_first: _newFirstFilterValue})
    this.tc.activeTemplate.treatmentfilter_first = _newFirstFilterValue
    this.tc.updateTemplateItem({treatmentfilter_second: -1})
    this.tc.activeTemplate.treatmentfilter_second = -1
    this.tc.updateTemplateItem({treatmentfilter_third: -1})
    this.tc.activeTemplate.treatmentfilter_third = -1

    // await this.tc.toggleAreaOfExpertise(e.value.fachgebiet_id)

    // for(let _item of this.ui.treatmentRegions2.filter(_items => _items.fachgebiet_id === item.id)){
    //   _item.selected = item.is_active
    // }
    // this.joinTemplates();
    //await this.tc.toggleAreaOfExpertise(e.value.fachgebiet_id)
    // console.log(e.value)
    // setTimeout(()=>{
    //  console.log(this.ui.fachgebiet)
    // },250)
    // console.log(this.ui.fachgebiet)
    // console.log(this.ui.fachgebiete.find(item => item.id === e.value.id))
    // if(this.ui.fachgebiete){
    //   const _newItem = this.ui.fachgebiete.find(item => item.id == e.value.fachgebiet_id)
      // setTimeout(()=>{
      //   this.ui.fachgebiet = _newItem ? _newItem : null
      // console.log(this.ui.fachgebiet)
      // },250)
    //}
  }
}
