<div class='mat-body d-flex pl0 pr0 ' [class.nonSplitBG]='true' style="border-top:1px solid #D5DDE3!important; height: calc(100% - 34px)" >

  <div class="p-2 w90 pl10 p_t10"[class.error-border-tb]='position.hasError' [class.activeBorderLeft]='position.activated'>
    <mat-checkbox color='accent' class=" mr0"
      [(ngModel)]='position.surgery_relevant' (change)='activateSugeryRelevantTapped()'
      [disabled]='!editable' style="text-align: right;">
    </mat-checkbox>
  </div>

  <div class="p-2 p_t15 p_b20" style="width: 20%!important; padding-left:20px"> 
    <div class="item-text-style h100 fs14-400 " [innerHTML]="position.example" (click)="openFullPusitionOverlay(position, FOCUS_ELMENTS.EXAMPLE_COPY)">
    </div>

  </div>

  <div class="p-0" [class.enabled]='!position.activated' class='fs14-400 p_t15' style="width:  8%!important; text-align: center;" [class.error-border-tb]='position.hasError' >
    <div *ngIf="position.position_type == 'GOA'">
      <span matTooltipClass='sectionGoaeTT' matTooltip='{{position.goa_paragraph.long_description}}' matTooltipPosition='above' style="cursor:crosshair">{{position.goa_paragraph.goa_id}}</span>
    </div>
  </div>
  
  <div class="p-2" style="width:  67%!important;">
    <div class="d-flex flex-column pl8 pr8  p_b20 h100" *ngIf="loaded">
      <div class="p-0 flex-fill" >

        <app-position-surgery-reports 
        (tapped)="tapped($event)"
        [position_id]="position.position_id"
        [reportItems]="reportItemsByPos"
        [activeIndex]="activeIndex"
        [editable]="editable"></app-position-surgery-reports>
      </div>

      <div class="p-0 mt-auto p_t8 " style="max-height: 120px;" *ngIf="position.position_type == 'GOA' ">
<!-- [progression_factors]="getPositionProgressionFactors(position.position_id)" -->
        <app-position-justifications 
        (onTapped)="openFullPusitionOverlay(position, FOCUS_ELMENTS.JUSTIFICATION_COPY)"
        [currentIndex]="position_justification_index"
        [position]="position"></app-position-justifications>
      </div>
    </div>
  </div>

</div>