import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddressI } from 'src/app/core/models/accountI';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { UiService } from 'src/app/services/ui.service';
import { FixAddressComponent } from 'src/app/ui/overlays/fix-address/fix-address.component';

@Component({
  selector: 'app-template-header-address',
  templateUrl: './template-header-address.component.html',
  styleUrls: ['./template-header-address.component.scss']
})
export class TemplateHeaderAddressComponent implements OnInit {

  currentAddress:AddressI
  constructor(public ui:UiService, public tc:TemplateControllerService, private dialog:MatDialog) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.checkAddress()
    },1000)
  }

  // get isDisabled():boolean {
  //   // if(this.ui.isClinicUser) { return true }
  //   if(this.tc.templateHasInvoice || this.tc.templateHasSurgeryReport){
  //     return true
  //   }
  //   return false;
  // }
  async checkAddress(){
    if ( this.tc.activeProcess == null ) { return }
    if(this.tc.activeProcess.address_id == null || this.tc.activeProcess.address_id == -1){

      if(this.ui.isClinicUser){
        const _address = this.ui.addresses.length > 0 ? this.ui.addresses[0] : null
        if(_address){
          this.currentAddress = _address
          this.tc.activeProcess.address_id = _address.id
          this.tc.IPROCESSnvoiceAddressIdChanged(_address.id);
        }
      }


    } else if(this.tc.activeProcess.address_id != null && this.tc.activeProcess.address_id != -1
      && this.ui.addresses && this.ui.addresses.length > 0) {

      const __add = this.ui.addresses.find((item) => item.id === this.tc.activeTemplate.address_id)
      if(__add){
        this.currentAddress = __add
      }
    //  // this.currentAddress.klinikName != null ? this.currentAddress.klinikName : ''
    }
   /*  console.log(this.tc.activeProcess.address_id) */
     console.log(this.currentAddress)
    // console.log(this.ui.addresses.length)
  }
  addressChanged(event){
    var _newAdd:AddressI = event.value
    this.currentAddress = _newAdd
    this.tc.IPROCESSnvoiceAddressIdChanged(_newAdd.id);

  }
  get cardWarn():boolean {
    if(this.currentAddress == null || this.currentAddress.klinikName == null || this.currentAddress.klinikName == ''
    || this.currentAddress.streetName == null || this.currentAddress.streetName == ''
    || this.currentAddress.zip == null || this.currentAddress.zip == ''
    || this.currentAddress.city == null || this.currentAddress.city == ''){
      return true
    }
   /// return this.ui.addresses.length == 1
   return false
  }
  fixAddress(){
    this.dialog.open(FixAddressComponent,{
      width: '80%',
      maxWidth: '1200px',
      height: '550px',
      backdropClass:"backdrop-background",
      data: this.currentAddress
    }).afterClosed().subscribe(
      _data => {
        setTimeout(()=>{
          this.checkAddress()
        },600)
      }
    )
  }
}
