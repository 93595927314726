import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ITextitemtilesOverlayData, TEXT_ITEM_FOCUS_ELEMENT, template_item_text_tiles } from 'src/app/core/models/api/templateUtils';
import { HORIZONTAL_ITEM_SELECTION_ACTION } from 'src/app/core/models/horizontalItemSelectionAction';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { AlertOverlayComponent } from '../../overlays/alert-overlay/alert-overlay.component';
import { AlertActionI } from 'src/app/core/models/alertM';

@Component({
  selector: 'app-textitemtiles-overlay',
  templateUrl: './textitemtiles-overlay.component.html',
  styleUrls: ['./textitemtiles-overlay.component.scss']
})
export class TextitemtilesOverlayComponent implements OnInit {

  @ViewChild('_titleTextField') _titleTextField: ElementRef;
  @ViewChild('_textArea') _textArea: ElementRef;

  private overlayData: ITextitemtilesOverlayData | undefined;

  items: template_item_text_tiles[] = [];
  selectedItem: template_item_text_tiles
  currentIndex: number = 0;

  isTitleEditMode: boolean = false;

  constructor(
    private dialog: MatDialog,
    public tc: TemplateControllerService,
    public dialogRef:MatDialogRef<TextitemtilesOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ITextitemtilesOverlayData
    ) { 
      this.overlayData = data;
      this.items = data.items;
    }

  ngOnInit(): void {
    this.getItems();
    setTimeout(() => { 
      if (this.overlayData && this.overlayData.focus) {
        switch (this.overlayData.focus) {
          case TEXT_ITEM_FOCUS_ELEMENT.ADD:
            break;
          case TEXT_ITEM_FOCUS_ELEMENT.REMOVE:
            break;
          case TEXT_ITEM_FOCUS_ELEMENT.NEXT:
            this.nextItem()
            break;
          case TEXT_ITEM_FOCUS_ELEMENT.PREV:
            this.prevItem()
            break;
          case TEXT_ITEM_FOCUS_ELEMENT.DESCRIPTION_FOCUS:
            this.isTitleEditMode = true;
            setTimeout(() => { 
              if (this._titleTextField.nativeElement) { 
                this._titleTextField.nativeElement.focus()
                this._titleTextField.nativeElement.select()
              }
            },50)
            break;
          case TEXT_ITEM_FOCUS_ELEMENT.TEXT_FOCUS:
            this._textArea.nativeElement.focus();
            break;
        }
      }
    },250)
  }

  private getItems() { 
    const _items = this.tc.getTemplate_item_text_tiles_by_type(this.overlayData.type)
       let _lastModified = 0
      let _newIndex = 0
      
      for (var i = 0; i < _items.length; i ++) {
        if(_items[i].lastModified > _lastModified){
          _lastModified = _items[i].lastModified
          _newIndex = i
        }
    
      }
      setTimeout(()=>{
        this.currentIndex = _newIndex;
        this.items = _items
        if(this.items[this.currentIndex] != null) {
          this.selectedItem = this.items[this.currentIndex]
        }
      },10)
  }
  
  // -----------------------------------------------------------------
  get currentDescription(): string {
    if(this.items.length == 0) { return "" }
    return this.items[this.currentIndex].description ?? "";
  }
  // -----------------------------------------------------------------
  set currentDescription(value: string) {
    if(this.items[this.currentIndex]) {
      this.items[this.currentIndex].description = value;
    }
  }
  // -----------------------------------------------------------------
  get currentText(): string {
    if(this.items.length == 0) { return "" }
    return this.items[this.currentIndex].text ?? "";
  }
  // -----------------------------------------------------------------
  set currentText(value: string) {
    if(this.items[this.currentIndex]) {
      this.items[this.currentIndex].text = value;
    }
  }
  // -----------------------------------------------------------------
  prevItem(){
    if(this.currentIndex > 0){
      this.currentIndex --
      setTimeout(()=>{
        this.save(this.items[this.currentIndex], true)
      },20)
    }
  }
  // -----------------------------------------------------------------
  nextItem(){
    if(this.currentIndex < this.items.length -1){
      this.currentIndex ++
      setTimeout(()=>{
        this.save(this.items[this.currentIndex], true)
      },20)
    }
  }
  // -----------------------------------------------------------------
  async save(item:template_item_text_tiles, reload:boolean = true){
    if(!this.tc.isTemplateEditable){
      return
    }
    item.lastModified = new Date().getTime()
    const __save = await this.tc.update_item_text_tile(this.overlayData.type, this.tc.currentTemplateId.toString(), item)
    if(reload){
      this.getItems();
    }
  }
  // -----------------------------------------------------------------
  async addNewItem() {
    
    const description = `Variante ${this.items.length + 1}`;
    await this.tc.add_new_item_text_tile(this.overlayData.type, description, "");
    setTimeout(() => {
      this.getItems();
       this._textArea.nativeElement.focus();
    },500)
  }
   get showAdd(): boolean {
     if (this.currentText.length == 0) { 
      return false
     } else {
       return true
     }
  }
  // -----------------------------------------------------------------
  removeItem() {
    this.dialog.open(AlertOverlayComponent, {
      width:"400px",
      disableClose:true,
      data: {
        title:"Achtung", 
        bodyMessage:"Diese Aktion kann nicht rückgängig gemacht werden.",
          actions: [
            {title:'Löschen', color: AlertActionI.BUTTON_COLOR_TYPE.WARN, actionType:AlertActionI.BUTTON_ACTION_TYPE.RESUME},
            {title:'Abbrechen', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType:AlertActionI.BUTTON_ACTION_TYPE.CANCEL}
          ]
        },
      backdropClass:"backdrop-background"
    }).afterClosed().subscribe((result) => {
      if(result != null){
        if(result === AlertActionI.BUTTON_ACTION_TYPE.RESUME){
          this._finalDelete()
        }
      }
    });
  }
  private async _finalDelete(){
    await this.tc.delete_item_text_tile(this.overlayData.type, this.tc.currentTemplateId.toString(), this.items[this.currentIndex])
    this.getItems();
  }
  // -----------------------------------------------------------------
  async onTextFieldTitleEnter(event: any) {
    if(this.currentDescription != event.target.value) {
      this.currentDescription = event.target.value
      //await this.tc.updateSingleReport(this.tc.prefix, this.reportItems[this.activeIndex], this.reportItems[this.activeIndex].surgery_report_item_id)
    } 
    this.isTitleEditMode = false
    setTimeout(() => {
      this._textArea.nativeElement.focus()
    },150)
  }
  async onTextFieldTitleEscape(event: any) {
    if(this.currentDescription != event.target.value) {
      this.currentDescription = event.target.value
      await this.tc.update_item_text_tile(this.overlayData.type, this.tc.currentTemplateId.toString(), this.items[this.currentIndex])
    } 
    this.isTitleEditMode = false
  }
  // -----------------------------------------------------------------
  async onTextFieldTitleBlur(event: any) {
    if(this.currentDescription != event.target.value) {
      this.currentDescription = event.target.value
      await this.tc.update_item_text_tile(this.overlayData.type, this.tc.currentTemplateId.toString(), this.items[this.currentIndex])
    } 
    this.isTitleEditMode = false
  }
  // -----------------------------------------------------------------
  async changed(event: MatSelectChange) {
    const index = this.items.findIndex(item => item.text_tile_id == event.value.text_tile_id)
    if(index != -1) {
      this.currentIndex = index;
      if(this.items[this.currentIndex] != null) {
        this.selectedItem = this.items[this.currentIndex]
      }
      this.save(this.items[this.currentIndex], false)
    }
  }
 
  // -----------------------------------------------------------------
  setTitleEditMode() {
    this.isTitleEditMode = !this.isTitleEditMode;
    if (this.isTitleEditMode) {
       setTimeout(() => { 
          if (this._titleTextField.nativeElement) { 
            this._titleTextField.nativeElement.focus()
            this._titleTextField.nativeElement.select()
          }
        },50)
    }
  }
  // -----------------------------------------------------------------
  async onTextAreaBlur(event: any) {
    console.log("onTextAreaBlur");
    
    if(this.currentText != event.target.value) {
      this.currentText = event.target.value
      
      if(this.items.length == 0) {
        const description = 'Variante 1';
        const text = event.target.value
        await this.tc.add_new_item_text_tile(this.overlayData.type, description, text);
        setTimeout(() => {
          this.getItems();
        },500)
        
      } else {
        await this.tc.update_item_text_tile(this.overlayData.type, this.tc.currentTemplateId.toString(), this.items[this.currentIndex])
      }
      
      // setTimeout(() => {
      //   this.isSaving = false;
      // },150)
    } 
  }
  //---------------------------------------------------------------
  async onTextAreaEscape(event: any) {
    if(this.currentText != event.target.value) {
      this.currentText = event.target.value
      
      if(this.items.length == 0) {
          const description = 'Variante 1';
        const text = event.target.value
        await this.tc.add_new_item_text_tile(this.overlayData.type, description, text);
        setTimeout(() => {
          this.getItems();
        },500)
      } else {
        await this.tc.update_item_text_tile(this.overlayData.type, this.tc.currentTemplateId.toString(), this.items[this.currentIndex])
      }
      
      // setTimeout(() => {
      //   this.isSaving = false;
      // },150)
    } 
  }
  // -----------------------------------------------------------------
  async onSelectionAction(e: HORIZONTAL_ITEM_SELECTION_ACTION) {
    console.log(e);
    switch (e) {
      case HORIZONTAL_ITEM_SELECTION_ACTION.ADD:
        this.addNewItem();
        return;
      case HORIZONTAL_ITEM_SELECTION_ACTION.REMOVE:
        this.removeItem();
        return;
      case HORIZONTAL_ITEM_SELECTION_ACTION.NEXT:
        this.nextItem();
        return;
      case HORIZONTAL_ITEM_SELECTION_ACTION.PREV:
        this.prevItem()
        return;
    }
  }
  // -----------------------------------------------------------------
  async close() {
    this.dialogRef.close(this.items);
  }
}
