<mat-card class="mat-card-no-shadow pr0 pt0 pb0 pl0" style="height:173px!important; position: relative;">
  
  <div class="container p_t15" style="height: 100%;">
    <div class="row"><div class="col-10 mat-caption">{{title}}</div></div>
    
    <div *ngIf='itemCount > 0 && !isLoading'>

      <div class="d-flex align-items-center" style="height: 100%;">
        
        <horizontal-item-selection
        [editable]="tc.isTemplateEditable"

        [itemCount]="itemCount"
        [currentIndex]="currentIndex"
        (action)="onSelectionAction($event)"
      ></horizontal-item-selection>

    
        <div class="p-2 pr0 flex-fill" *ngIf="itemCount > 1">
          <div class="item-text-style textDescr truncate-overflow-header-item-tile-descr" (click)="openEditWin(FOCUS_ITEMS.DESCRIPTION_FOCUS)"
          [class.item-text-style-disabled]='!tc.isTemplateEditable'
          >{{getTitle}}</div>
        </div>

      </div>
    
      <div class="d-flex p_t0 p_b10" >
        <div class="p-0 flex-fill" >
          <div class="truncate-overflow-header-item-tile item-text-style" (click)="openEditWin(FOCUS_ITEMS.TEXT_FOCUS)" [innerHtml]="getCopy"
          [class.item-text-style-disabled]='!tc.isTemplateEditable'>
          </div>
        </div>
      </div>
    </div>
    
    <!-- ADD BUTTON -->
    <div *ngIf='itemCount == 0 && !isLoading' style="height:100%">
      <div class="d-flex justify-content-center align-items-center p_t0 p_b20" style="height:calc(100% - 26px);">
        <div class="p-2" style="height: 26px;">
            <button mat-mini-fab color='accent' 
            *ngIf="tc.isTemplateEditable"
              class="mat-elevation-z0" (click)="createNewItem()"
              matTooltipClass='sectionGoaeTT' matTooltip="Anlegen" matTooltipPosition='above'>
             <mat-icon style="font-size:16px!important; margin-top: 4px!important; ">add</mat-icon>
            </button>
        </div>
      </div>
    </div>
  <!--ENDE ADD BUTTON -->
  </div>

  <div class="loadingMod" *ngIf="isLoading" style="height: 100%;">
    <div class="d-flex align-items-center justify-content-center" style="height:100%">
      <div class="p-0">
        <div class="loader">......</div>
      </div>
    </div>
  </div>
 </mat-card>
   