

import '@angular/common/locales/global/de';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PatientService } from './services/patient.service';
import { TemplatesComponent } from './pages/templates/templates.component';
import { AddComponentDirective } from './directives/add-component.directive';
import { AlertOverlayComponent } from './ui/overlays/alert-overlay/alert-overlay.component';
import { TemplatePositionItemComponent } from './ui/widgets/template-modules/template-position-item/template-position-item.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TemplateSectionItemComponent } from './ui/widgets/template-modules/template-section-item/template-section-item.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkScrollableModule} from '@angular/cdk/scrolling';
import { TextOverflowDirective } from './directives/text-overflow.directive';
import { MaterialDetailsComponent } from './ui/overlays/material-details/material-details.component';
import { AccountComponent } from './pages/account/account.component';
import { PatientDetailsOverlayComponent } from './ui/overlays/patient-details-overlay/patient-details-overlay.component';
import { AppOverlayModule } from './ui/overlay/overlay.module';
import { ProgressSpinnerComponent } from './ui/progress-spinner/progress-spinner.component';
import { ProgressSpinnerModule } from './ui/progress-spinner/progress-spinner.module';
import { TexteditOverlayComponent } from './ui/overlays/textedit-overlay/textedit-overlay.component';
import { ProcessesOverviewComponent } from './pages/processes-overview/processes-overview.component';
import { CountoModule }  from 'angular2-counto';
import { CgcurrencyPipe } from './pipes/cgcurrency.pipe';
import { ProcessDetailsComponent } from './pages/process-details/process-details.component';
import { TemplateFooterComponent } from './ui/widgets/template-modules/template-footer/template-footer.component';
import { TreatmentEditOverlayComponent } from './ui/overlays/treatment-edit-overlay/treatment-edit-overlay.component';
import { PatientSelectionComponent } from './ui/overlays/patient-selection/patient-selection.component';
import { BreadcrumpComponent } from './ui/widgets/breadcrump/breadcrump.component';
import { TemplatePatientItemComponent } from './ui/widgets/template-modules/template-patient-item/template-patient-item.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MainMenuItemHzComponent } from './ui/widgets/main-menu-item-hz/main-menu-item-hz.component';
import { PositionJusificationOverlayComponent } from './ui/overlays/position-jusification-overlay/position-jusification-overlay.component';
import { AuthService } from './shared/auth.service';
import { AccessDirective } from './directives/access.directive';
import { DashboardTemplateItemComponent } from './ui/widgets/dashboard-items/dashboard-template-item/dashboard-template-item.component';
import { TemplateOpPositionItemComponent } from './ui/widgets/template-modules/template-op-position-item/template-op-position-item.component';
import { DateSelectionComponent } from './ui/overlays/date-selection/date-selection.component';
import { TemplateTimelineOpSectionItemComponent } from './ui/widgets/template-modules/template-timeline-op-section-item/template-timeline-op-section-item.component';
import { GoaeChangesInfoComponent } from './ui/overlays/goae-changes-info/goae-changes-info.component';
import { FocusDirective } from './directives/focus.directive';
import { EmployeeComponent } from './ui/overlays/employee/employee.component';
import { TemplatePositionScsItemComponent } from './ui/widgets/template-modules/template-position-scs-item/template-position-scs-item.component';
import { TemplateSectionScsItemComponent } from './ui/widgets/template-modules/template-section-scs-item/template-section-scs-item.component';
import { TemplateScsReportPositionItemComponent } from './ui/widgets/template-modules/template-scs-report-position-item/template-scs-report-position-item.component';
import { TemplateScsReportSectionItemComponent } from './ui/widgets/template-modules/template-scs-report-section-item/template-scs-report-section-item.component';
import { AccountNamePipe } from './pipes/account-name.pipe';
import { TemplateBehandlungsschritteViewComponent } from './ui/widgets/template-modules/template-behandlungsschritte-view/template-behandlungsschritte-view.component';
import { TemplateReportViewComponent } from './ui/widgets/template-modules/template-report-view/template-report-view.component';
import { TemplateProcessCopyAlertComponent } from './ui/overlays/template-process-copy-alert/template-process-copy-alert.component';
import { PdfPreviewComponent } from './ui/overlays/pdf-preview/pdf-preview.component';
import { MaterialModule } from './core/material.module';
import { DashboardProcessesItemComponent } from './ui/widgets/dashboard-items/dashboard-processes-item/dashboard-processes-item.component';
import { TemplateScsReportViewComponent } from './ui/widgets/template-modules/template-scs-report-view/template-scs-report-view.component';
import { TemplateDetailComponent } from './pages/template-detail/template-detail.component';
import { SelectPositionCustomItemComponent } from './ui/overlays/select-position-custom-item/select-position-custom-item.component';
import { AddGoaeInlineButtonComponent } from './ui/widgets/template-modules/add-goae-inline-button/add-goae-inline-button.component';
import { AddOwnBillingInlineButtonComponent } from './ui/widgets/template-modules/add-own-billing-inline-button/add-own-billing-inline-button.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ProcessCompletionComponent } from './ui/overlays/process-completion/process-completion.component';
import { SessionTimeoutOverlayComponent } from './ui/overlays/session-timeout-overlay/session-timeout-overlay.component';
import { DurationPipe } from './pipes/duration.pipe';
import { authInterceptorProviders } from './shared/auth.interceptor';
import { UiService } from './services/ui.service';
import {RichTextEditorModule} from '@syncfusion/ej2-angular-richtexteditor';
import { SurgeryReportHeaderComponent } from './ui/widgets/surgery-report-header/surgery-report-header.component';
import { ProcessDateItemComponent } from './ui/widgets/template-modules/process-date-item/process-date-item.component';
import { ShareTemplateOverlayComponent } from './ui/overlays/share-template-overlay/share-template-overlay.component';
import { UserAddressOverlayComponent } from './ui/overlays/user-address-overlay/user-address-overlay.component';
import { ProcessStatesOverlayComponent } from './ui/overlays/process-states-overlay/process-states-overlay.component';
import { AddressComponent } from './ui/widgets/address/address.component';
import { TemplateNoteMethodDiagnoseComponent } from './ui/widgets/template-note-method-diagnose/template-note-method-diagnose.component';
import { BehandlungsregionCompComponent } from './ui/widgets/templateHeaderModules/behandlungsregion-comp/behandlungsregion-comp.component';
import { TemplateHeaderMinderungComponent } from './ui/widgets/templateHeaderModules/template-header-minderung/template-header-minderung.component';
import { TemplateToolbarComponent } from './ui/widgets/template-toolbar/template-toolbar.component';
import { TextItemTileEditComponent } from './ui/overlays/text-item-tile-edit/text-item-tile-edit.component';
import { SimpleDateSelectionComponent } from './ui/overlays/simple-date-selection/simple-date-selection.component';
import { ChangePasswordComponent } from './ui/overlays/change-password/change-password.component';
import { AutocompleteDirective } from './directives/autocomplete.directive';
import { AddSinglelineTextItemComponent } from './ui/overlays/add-singleline-text-item/add-singleline-text-item.component';
import { TemplateNachbehandlungComponent } from './ui/widgets/template-modules/template-nachbehandlung/template-nachbehandlung.component';
import { NachbehandlungPdfPreviewComponent } from './ui/overlays/nachbehandlung-pdf-preview/nachbehandlung-pdf-preview.component';
import { ProgressionFactorComponent } from './ui/widgets/template-modules/progression-factor/progression-factor.component';
import { TemplateCopySplitscreenComponent } from './ui/widgets/template-copy-splitscreen/template-copy-splitscreen.component';
import { PerioperativeAngabenComponent } from './ui/overlays/perioperative-angaben/perioperative-angaben.component';
import { SharedModule } from './shared/SharedPipes.module';
import { OwnBilligEditComponent } from './ui/overlays/own-billig-edit/own-billig-edit.component';
import { PatientModifyComponent } from './ui/widgets/patients/patient-modify/patient-modify.component';
import { DfIconButtonComponent } from './ui/widgets/df-icon-button/df-icon-button.component';
import { PositionJustificationsComponent } from './ui/widgets/template-modules/position-justifications/position-justifications.component';
import { PositionSurgeryReportsComponent, ResizableTextAreaDirective } from './ui/widgets/template-modules/position-surgery-reports/position-surgery-reports.component';
import { TemplateInfoOverlayComponent } from './ui/overlays/template-info-overlay/template-info-overlay.component';
import { TemplateControllerService } from './services/template-controller.service';
import { TemplateApiService } from './services/template-api.service';
// import { PositionControllerService } from './services/position-controller.service';
// import { SectionControllerService } from './services/section-controller.service';
// import { SurgeryControllerService } from './services/surgery-controller.service';
import { SurgeryHeaderComponent } from './ui/widgets/surgery-header/surgery-header.component';
import { SurgerySupportSelectionComponent } from './ui/overlays/surgery-support-selection/surgery-support-selection.component';
import { SimpleTimeSelectionComponent } from './ui/overlays/simple-time-selection/simple-time-selection.component';
import { AddCustomProgressionFactorComponent } from './ui/overlays/add-custom-progression-factor/add-custom-progression-factor.component';
import { SectionMaterialItemComponent } from './ui/widgets/template-modules/section-material-item/section-material-item.component';
import { PatientenComponent } from './administration/patienten/patienten.component';
import { GoaeDetailOverlayComponent } from './administration/goae-detail-overlay/goae-detail-overlay.component';
import { InvoicesComponent } from './administration/invoices/invoices.component';
import { SettingsContentPanelComponent } from './ui/widgets/settings-content-panel/settings-content-panel.component';
import { SettingsPatientsComponent } from './ui/widgets/settings/settings-patients/settings-patients.component';
import { SettingsEmployeesComponent } from './ui/widgets/settings/settings-employees/settings-employees.component';
import { SettingsOwnPositionItemsComponent } from './ui/widgets/settings/settings-own-position-items/settings-own-position-items.component';
import { SettingsPerioperativeAngabenComponent } from './ui/widgets/settings/settings-perioperative-angaben/settings-perioperative-angaben.component';
import { SettingsMaterialComponent } from './ui/widgets/settings/settings-material/settings-material.component';
import { SettingsGoaComponent } from './ui/widgets/settings/settings-goa/settings-goa.component';
import { AdministrationOverviewComponent } from './administration/administration-overview/administration-overview.component';
import { PositionCopyItemComponent } from './ui/widgets/position-copy-item/position-copy-item.component';
import { TemplateOpPositionItemTileComponent } from './ui/widgets/template-modules/template-op-position-item-tile/template-op-position-item-tile.component';

import { TemplateHeaderAddressComponent } from './ui/widgets/templateHeaderModules/template-header-address/template-header-address.component';
import { FixAddressComponent } from './ui/overlays/fix-address/fix-address.component';
import { SurgeryHeaderSupportItemComponent } from './ui/widgets/surgery-header-support-item/surgery-header-support-item.component';
import { ProcessHostorieComponent } from './ui/widgets/template-modules/process-hostorie/process-hostorie.component';
import { SettingsExpertisesComponent } from './ui/widgets/settings/settings-expertises/settings-expertises.component';
import { SettingsExpertisesDetailComponent } from './ui/overlays/settings-expertises-detail/settings-expertises-detail.component';
import { SettingsUserExpertisesComponent } from './ui/overlays/settings-user-expertises/settings-user-expertises.component';
import { ExpertiseAreaHeaderComponent } from './ui/widgets/templateHeaderModules/expertise-area-header/expertise-area-header.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { SetNewPasswordComponent } from './auth/set-new-password/set-new-password.component';
import { PositionQuatityComponent } from './ui/widgets/template-modules/position-quatity/position-quatity.component';
import { AddCustomQuantityComponent } from './ui/overlays/add-custom-quantity/add-custom-quantity.component';
import { SurgeryReportPositionsOverlayComponent } from './ui/overlays/surgery-report-positions-overlay/surgery-report-positions-overlay.component';
import { ProcessClinicNumberComponent } from './ui/widgets/template-modules/process-clinic-number/process-clinic-number.component';
import { LastActivityService } from './services/last-activity.service';
import { NewTemplateNameComponent } from './ui/overlays/new-template-name/new-template-name.component';
import { InactivityWarnOverlayComponent } from './ui/overlays/inactivity-warn-overlay/inactivity-warn-overlay.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AccountingInfoPageComponent } from './pages/accounting-info-page/accounting-info-page.component';
import { AccountAccountingTapComponent } from './pages/account/account-accounting-tap/account-accounting-tap.component';
import { AccountingUserDetailOverlayComponent } from './ui/overlays/accounting-user-detail-overlay/accounting-user-detail-overlay.component';
import { OpenProcessComponentOverlayComponent } from './ui/overlays/open-process-component-overlay/open-process-component-overlay.component';
import { ProcessStageChangeOverlayComponent } from './ui/overlays/process-stage-change-overlay/process-stage-change-overlay.component';
import { TemplateRowComponent } from './pages/templates/template-row/template-row.component';
import { TemplateRowButtonComponent } from './pages/templates/template-row-button/template-row-button.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';

import { ProcessBehandlungsschritteComponent } from './ui/widgets/process-behandlungsschritte/process-behandlungsschritte.component';
import { TestPageComponent } from './pages/test-page/test-page.component';
import { TemplatePositionOverlayComponent } from './ui/components/template-position-overlay/template-position-overlay.component';
import { TemplateDetailOverlayComponentComponent } from './ui/overlays/template-detail-overlay-component/template-detail-overlay-component.component';
import { HorizontalItemSelectionComponent } from './ui/components/horizontal-item-selection/horizontal-item-selection.component';

import { SubscriptionPlanOverlayComponent } from './ui/components/subscription-plan-overlay/subscription-plan-overlay.component';
import { TemplatePoolOverlayComponent } from './ui/components/template-pool-overlay/template-pool-overlay.component';
import { TextitemtilesOverlayComponent } from './ui/components/textitemtiles-overlay/textitemtiles-overlay.component';
import { CgDatePipe } from './pipes/cg-date.pipe';
import { AddressListItemComponent } from './ui/widgets/address/address-list-item/address-list-item.component';
import { PadnextExportOverlayComponent } from './ui/overlays/padnext-export-overlay/padnext-export-overlay.component';
import { DetectFocusDirective } from './directives/detect-focus.directive';
import { PatientGenderSelectComponent } from './ui/widgets/patients/patient-gender-select.component';
import { PatientSalutationSelectComponent } from './ui/widgets/patients/patient-salutation-select.component';



@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    TemplatesComponent,
    AddComponentDirective,
    AlertOverlayComponent,
    TemplatePositionItemComponent,
    TemplateSectionItemComponent,
    TextOverflowDirective,
    MaterialDetailsComponent,
    AccountComponent,
    PatientDetailsOverlayComponent,
    TexteditOverlayComponent,
    ProcessesOverviewComponent,
    CgcurrencyPipe,
    ProcessDetailsComponent,
    TemplateFooterComponent,
    TreatmentEditOverlayComponent,
    PatientSelectionComponent,
    BreadcrumpComponent,
    TemplatePatientItemComponent,
    // AlertWindowHolderComponent,
    MainMenuItemHzComponent,
    PositionJusificationOverlayComponent,
    AccessDirective,
    DashboardTemplateItemComponent,
    TemplateOpPositionItemComponent,
    DateSelectionComponent,
    TemplateTimelineOpSectionItemComponent,
    GoaeChangesInfoComponent,
    FocusDirective,
    EmployeeComponent,
    TemplatePositionScsItemComponent,
    TemplateSectionScsItemComponent,
    TemplateScsReportPositionItemComponent,
    TemplateScsReportSectionItemComponent,
    AccountNamePipe,
    TemplateBehandlungsschritteViewComponent,
    TemplateReportViewComponent,
    TemplateProcessCopyAlertComponent,
    PdfPreviewComponent,
    DashboardProcessesItemComponent,
    TemplateScsReportViewComponent,
    TemplateDetailComponent,
    SelectPositionCustomItemComponent,
    AddGoaeInlineButtonComponent,
    AddOwnBillingInlineButtonComponent,
    PrivacyComponent,
    ProcessCompletionComponent,
    SessionTimeoutOverlayComponent,
    DurationPipe,
    AdministrationOverviewComponent,
    SurgeryReportHeaderComponent,
    ProcessDateItemComponent,
    ShareTemplateOverlayComponent,
    UserAddressOverlayComponent,
    ProcessStatesOverlayComponent,
    AddressComponent,
    TemplateNoteMethodDiagnoseComponent,
    BehandlungsregionCompComponent,
    TemplateHeaderMinderungComponent,
    TemplateToolbarComponent,
    TextItemTileEditComponent,
    SimpleDateSelectionComponent,
    ChangePasswordComponent,
    AutocompleteDirective,
    AddSinglelineTextItemComponent,
    TemplateNachbehandlungComponent,
    NachbehandlungPdfPreviewComponent,
    ProgressionFactorComponent,
    TemplateCopySplitscreenComponent,
    PerioperativeAngabenComponent,
    OwnBilligEditComponent,
    PatientModifyComponent,
    DfIconButtonComponent,
   
    PositionJustificationsComponent,
    PositionSurgeryReportsComponent,
    TemplateInfoOverlayComponent,
    SurgeryHeaderComponent,
    SurgerySupportSelectionComponent,
    SimpleTimeSelectionComponent,
    AddCustomProgressionFactorComponent,
    SectionMaterialItemComponent,
    PatientenComponent,
    GoaeDetailOverlayComponent,
    InvoicesComponent,
    SettingsContentPanelComponent,
    SettingsPatientsComponent,
    SettingsEmployeesComponent,
    SettingsOwnPositionItemsComponent,
    SettingsPerioperativeAngabenComponent,
    SettingsMaterialComponent,
    
    SettingsGoaComponent,
    PositionCopyItemComponent,
    TemplateOpPositionItemTileComponent,

    TemplateHeaderAddressComponent,
    FixAddressComponent,
    
    SurgeryHeaderSupportItemComponent,
    ProcessHostorieComponent,
    SettingsExpertisesComponent,
    SettingsExpertisesDetailComponent,
    SettingsUserExpertisesComponent,
    ExpertiseAreaHeaderComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SetNewPasswordComponent,
    PositionQuatityComponent,
    AddCustomQuantityComponent,
    ResizableTextAreaDirective,
    SurgeryReportPositionsOverlayComponent,
    ProcessClinicNumberComponent,
    NewTemplateNameComponent,
    InactivityWarnOverlayComponent,
    
    AccountingInfoPageComponent,
    AccountAccountingTapComponent,
    AccountingUserDetailOverlayComponent,
    OpenProcessComponentOverlayComponent,
    ProcessStageChangeOverlayComponent,
    TemplateRowComponent,
    TemplateRowButtonComponent,
    ErrorPageComponent,
    ProcessBehandlungsschritteComponent,
    TestPageComponent,
    TemplatePositionOverlayComponent,
    TemplateDetailOverlayComponentComponent,
    HorizontalItemSelectionComponent,
    
    SubscriptionPlanOverlayComponent,
    
    TemplatePoolOverlayComponent,
    
    TextitemtilesOverlayComponent,
    
    AddressListItemComponent,
    
    PadnextExportOverlayComponent,
    
    DetectFocusDirective,
    PatientGenderSelectComponent,
    PatientSalutationSelectComponent
    

  ],
  imports: [
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    PdfViewerModule,
    AppOverlayModule,
    BrowserModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ScrollingModule,
    DragDropModule,
    CdkScrollableModule,
    ProgressSpinnerModule,
    CountoModule,
    RichTextEditorModule
  ],
  exports: [
    AccessDirective, AutocompleteDirective,
  ],
  providers: [
    FocusDirective,
    AuthService,
    authInterceptorProviders,
    AutocompleteDirective,
    CgcurrencyPipe,
    DurationPipe,
    CgDatePipe,
    HttpClient,
    UiService,
    TemplateControllerService,
    TemplateApiService,
    // PositionControllerService,
    // SectionControllerService,
    // SurgeryControllerService,
    PatientService,// CookieService,
    { provide: LOCALE_ID,useValue: 'de'},
    { provide: APP_INITIALIZER, multi: true, deps: [ LastActivityService], useFactory: (lastActiveService: LastActivityService) => () => lastActiveService.setUp()}
  ],

  bootstrap: [AppComponent],
  entryComponents:[
    TemplatePositionOverlayComponent, 
    GoaeDetailOverlayComponent, 
    AddSinglelineTextItemComponent,
    PatientDetailsOverlayComponent, 
    ShareTemplateOverlayComponent, 
    TextItemTileEditComponent,
    AlertOverlayComponent, 
    MaterialDetailsComponent, 
    SelectPositionCustomItemComponent, 
    ProcessStatesOverlayComponent,
   ProgressSpinnerComponent, SessionTimeoutOverlayComponent, SimpleDateSelectionComponent,
  TexteditOverlayComponent, PatientSelectionComponent, ProcessCompletionComponent, UserAddressOverlayComponent, ChangePasswordComponent,
  PositionJusificationOverlayComponent, NachbehandlungPdfPreviewComponent,
     PerioperativeAngabenComponent,
  DateSelectionComponent, GoaeChangesInfoComponent, EmployeeComponent, TemplateProcessCopyAlertComponent, PdfPreviewComponent]
})
export class AppModule { }


/// v1.0.1
