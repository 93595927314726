<!-- <div class="p-0 flex-fill" style="width:100%; height:72px; position:relative;"> -->
    <div class="tableTR" >
      <div class="d-flex align-items-center h100">

    <div class="p-0 ml30 clickable" style="width:62px; height:32px;"  (click)='viewTemplate(template)'>
      <div class="iconShortCut" [ngStyle]='{"background-color": template.iconColor}'>
             <div *ngIf="template.shortCut">
              
               {{template.shortCut}}
             </div>
             <div *ngIf="!template.shortCut" >
              <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.1572 0.0580589C11.9421 -0.0484546 11.7269 0.00480216 11.5655 0.164572L6.93931 4.74465L5.59448 3.73278L0.86069 0.111316C0.69931 0.00480216 0.484138 -0.0484546 0.322759 0.0580589C0.107586 0.164572 0 0.324343 0 0.53737V9.37799C0 9.53776 0.0537931 9.69753 0.215172 9.80405L5.86345 14.5439C5.97103 14.6504 6.07862 14.6504 6.18621 14.6504C6.29379 14.6504 6.45517 14.5972 6.50897 14.5439L12.2648 9.80405C12.3724 9.69753 12.48 9.53776 12.48 9.37799V0.484113C12.48 0.324343 12.3186 0.111316 12.1572 0.0580589ZM11.4041 1.81553V8.09983L7.8 5.38374L11.4041 1.81553ZM1.07586 1.6025L11.2428 9.27148L8.17655 11.7745H4.24966L1.07586 9.11171V1.6025Z" fill="#FAFAFB"/>
                </svg>
                
             </div>
        </div>
      <!-- <div *ngIf='template.privacy === "PUBLISHED_PUBLIC"'>
        <div *ngIf="template.type === 'DOXFOX'" class="publicShortCutDoxFox" matTooltipClass='sectionGoaeTT'
             matTooltip='Öffentliche doxfox Vorlage' matTooltipPosition='above'>
        </div>
        <div *ngIf="template.type === 'SCS'"class="publicShortCutSCS" matTooltipClass='sectionGoaeTT'
             matTooltip='Öffentliche DOC Vorlage' matTooltipPosition='above'>
          DOC
        </div>
      </div>

      <div *ngIf='template.privacy === "USER_OWN"'>
        <div *ngIf="template.type === 'DOXFOX'" class="userShortCutDoxFox" matTooltipClass='sectionGoaeTT'
             matTooltip='Meine Vorlage' matTooltipPosition='above'>
        </div>
        <div *ngIf="template.type === 'SCS'" class="userShortCutSCS" matTooltipClass='sectionGoaeTT'
             matTooltip='Meine DOC Vorlage' matTooltipPosition='above'>
          DOC
        </div>
      </div>

      <div *ngIf='template.privacy === "UNPUBLISHED_PUBLIC"'>
        <div *ngIf="template.type === 'DOXFOX'" class="UnPublishedShortCutDoxFox" matTooltipClass='sectionGoaeTT'
             matTooltip='Unveröffentlichte doxfox Vorlage' matTooltipPosition='above'>
        </div>
        <div *ngIf="template.type === 'SCS'" class="UnPublishedShortCutSCS" matTooltipClass='sectionGoaeTT'
             matTooltip='Unveröffentlichte DOC Vorlage' matTooltipPosition='above'>
          DOC
        </div>
      </div> -->
    </div>

    <div class="p-0 clickable flex-fill h100" (click)='viewTemplate(template)'>
      <div class="d-flex h100 align-items-center">
        <div class="p-0 d-flex flex-column">
          <div class="p-0 semi16">{{template.name}}</div>
          <div class="p-0 lastModifiedCopy">{{templatePoolTitle}}</div>
        </div>
      </div>
    </div>

    <div class="p-0 ml-auto clickable lastModifiedCopy"  (click)='viewTemplate(template)'>
      <div>{{template.lastModified | duration}}</div>
    </div>

    <div class="p-2 justify-content-end" style="width: 155px;">


        <template-row-button 
        (action)="onRowButtonAction($event)"
        [template]="template" ></template-row-button>

    </div>

    <div class="p-2">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" [disabled]="!showSubMenu(template)">
        <mat-icon style="color: #7C8B96!important;">more_horiz</mat-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="after">
        <!--  <button mat-menu-item *ngIf="template.privacy == 'PUBLISHED_PUBLIC'" (click)='cloneOrEdit(template)'>
            <span>Übernehmen</span>
          </button>-->
        <!--<button mat-menu-item  *ngIf="!template.is_public || !template.is_published" ></button>-->
        <button mat-menu-item  *ngIf="canRenameTemplate"
        (click)='renameTemplate(template)'>
          <span>Umbenennen</span>
        </button>
        <button mat-menu-item  *ngIf="canDuplicateTempalte(template)"
                (click)='onRowButtonAction(buttonActionTypes.DUPLICATE)'>
          <span>Duplizieren</span>
        </button>
        <button mat-menu-item *ngIf="deleteButtonAccess(template)"
                (click)='deleteOwnTemplate(template)'>
          <span>Löschen</span>
        </button>
        <button mat-menu-item  *ngIf="canShareTemplate(template)"
                (click)='shareTemplate(template)'>
          <span>Vorlage teilen</span>
        </button>
      </mat-menu>
    </div>
      </div>
  </div>

<!-- </div> -->