import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AddCustomPositionSelectI } from 'src/app/core/models/addCustomPositionSelectI';
import { BilligItemI } from 'src/app/core/models/billing_itemI';
import {GOA_SMALL } from 'src/app/core/models/goaeM';
import { MaterialI } from 'src/app/core/models/materialI';
import { GoaServicesService } from 'src/app/services/goa-services.service';
import { AuthService } from 'src/app/shared/auth.service';
import * as _ from "lodash";
import { LocaleService } from 'src/app/services/locale.service';
import { PositionFlatI } from 'src/app/core/models/sectionPositionM';
import { POSITION_TYPE } from 'src/app/core/models/enums';
import { FormControl } from '@angular/forms';

import { UiService } from 'src/app/services/ui.service';
@Component({
  selector: 'app-select-position-custom-item',
  templateUrl: './select-position-custom-item.component.html',
  styleUrls: ['./select-position-custom-item.component.scss']
})
export class SelectPositionCustomItemComponent implements OnInit {
  @ViewChild('_goaTF') _goaTF:ElementRef;
  @ViewChild('_materialTF') _materialTF:ElementRef;
  @ViewChild('_billingTF') _billingTF:ElementRef;

  public control = new FormControl();
  public filteredOptions: BilligItemI[];
  public height: string;
  type:AddCustomPositionSelectI.CustomPositonType
  // filtereGoaeOptions:MatTableDataSource<GOA_SMALL>;//: GOA_SMALL[] = [];
  filtereGoaeOptions:GOA_SMALL[]=[];//: GOA_SMALL[] = [];
  fullGoae:GOA_SMALL[]=[];
  filtereMaterialOptions;
  filteredBillingOptions;
  goae_list_small:GOA_SMALL[] = [];
  materials:MaterialI[] = [];
  billingItems:BilligItemI[] = [];
  summery:number = 0;
  selectedGoae:GOA_SMALL
  selectedBillingItem:BilligItemI
  selectedMaterialItem:MaterialI
  isCreateOwn = false
  isCreateMat = false
  newBillingItem:BilligItemI
  @ViewChild('_preisTF', {static:false}) _preisTF:ElementRef
  materialItem:MaterialI = {
    bezeichnung:'',
    preis:'0.00'
  }
  constructor(public dialogRef: MatDialogRef<SelectPositionCustomItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddCustomPositionSelectI,
    private goaS:GoaServicesService,
    private ui: UiService,
    private authS:AuthService,
    public locale:LocaleService) {
      this.type = data.type;

     }

  ngOnInit(): void {
    switch (this.type) {
      case AddCustomPositionSelectI.CustomPositonType.GOA:
         this.getGoae();
        break;
      case AddCustomPositionSelectI.CustomPositonType.MATERIAL:
        this.getMaterial();
        break;
      case AddCustomPositionSelectI.CustomPositonType.OWN:
        this.initBillingItems();
        break;

    }

    // this.control.valueChanges
    //   .pipe(
    //     startWith(''),
    //     map((value) => {
    //       // Filter the options
    //       this.filteredBillingOptions = this.billingItems.filter((option) =>
    //         option.short_description .toLowerCase().includes(value.toLowerCase())
    //       );
    //       console.log(this.filteredBillingOptions);

    //       // Recompute how big the viewport should be.
    //       if (this.filteredBillingOptions.length < 4) {
    //         this.height = this.filteredBillingOptions.length * 50 + 'px';
    //       } else {
    //         this.height = '200px';
    //       }
    //     })
    //   )
    //   .subscribe();
  
  }
  sortFunc(a, b) {
    return 1;
  }
  onPreisFocus(){
    this._preisTF.nativeElement.selected()
  }
  addNewMat() {
    this.materialItem = {
      bezeichnung:'',
      preis:'0.00'
    }

    this.isCreateMat = true

  }

  addNewOwn() {
    
  this.newBillingItem =  {
    billing_item_id: -1,
    // id:latestId,
    documentId: "",  
    short_description: "",
    long_description: "",
    amount_euro: 0.0,
    // owner: this.ui.account.uid,
    // access: [
    //   this.ui.account.uid,
    // ]
  }
    this.isCreateOwn = true
  }
  clearEditMode(){
    this.isCreateOwn = false 
    this.isCreateMat = false
  }

  async saveNewMat() {
    let s =  this.materialItem.bezeichnung
   
    let _preis = this.materialItem.preis.toString()
    this.materialItem.preis = _preis.replace(/,/g,'.');
    await this.ui.updateMaterial(this.materialItem).then(
      data => {
        this.getMaterial();
        setTimeout(()=>{
          
          this.isCreateMat = false

        },200)
        setTimeout(()=>{

          this._materialTF.nativeElement.value = s
          this._materialTF.nativeElement.focus();
          this.applyFilterMaterial(s)

        },600)
      }
    )
  }
  async saveNewItem()  {
    let s = this.newBillingItem.short_description
    
    await this.ui.postBillingItem(this.newBillingItem).then(
      (x) => {
        this.initBillingItems()
        setTimeout(()=>{
          
          this.isCreateOwn = false
          this.newBillingItem = null

        },200)
        setTimeout(()=>{
          
          this._billingTF.nativeElement.value = s
          this._billingTF.nativeElement.focus();
          this.applyFilterBilling(s)

        },600)
      }
    )
  }
  get canSaveOwn() : boolean {
    if (this.newBillingItem && this.newBillingItem.short_description.length > 2 && this.newBillingItem.long_description.length > 2 ) {
      return true
    }
    return false;
  }
  get canSaveMat() : boolean {
    if (this.materialItem && this.materialItem.bezeichnung.length > 2 && this.materialItem.preis && this.materialItem.preis.length >= 1) {
      return true
    }
    return false;
  }
  openGOA(){
    if (!this.height) {
      if (this.filtereGoaeOptions.length < 5) {
        this.height = this.filtereGoaeOptions.length * 32 + 'px'
      } else {
        this.height = '168px'
      }
      console.log('OPEN', this.filtereGoaeOptions.length);
    }
  }
  open(){
    if (!this.height) {
      if (this.filteredBillingOptions.filteredData.length < 5) {
        this.height = this.filteredBillingOptions.filteredData.length * 32 + 'px'
      } else {
        this.height = '168px'
      }
      console.log('OPEN', this.filteredBillingOptions.filteredData.length);
    }
    
  }
  openMat(){
    if (!this.height) {
      if (this.filtereMaterialOptions.filteredData.length < 5) {
        this.height = this.filtereMaterialOptions.filteredData.length * 32 + 'px'
      } else {
        this.height = '168px'
      }
      console.log('OPEN', this.filtereMaterialOptions.filteredData.length);
    }
    
  }

  closeed(){
    console.log('closeed');
    
  }
  async getMaterial(){
    const _mat = await this.ui.getAllFromApi()
    this.materials = _mat as MaterialI[];
    this.materials = _.orderBy(this.materials, [material => material.bezeichnung])
    this.filtereMaterialOptions = new MatTableDataSource<MaterialI>(this.materials);
  
  }

  async initBillingItems(){
    const _items = await this.ui.getAllBilligItemsFromApi()
    this.billingItems = _items as BilligItemI[]
    this.billingItems =  _.orderBy(this.billingItems, [billignItem => billignItem.short_description])
    this.filteredBillingOptions = new MatTableDataSource<BilligItemI>(this.billingItems);

  }
  getGoae = async () => {
    let list:GOA_SMALL[]=[];
    list = await this.goaS.goaeListSmall().then(
      _data => {

        return _data as GOA_SMALL[]
      }
    )
    this.fullGoae = _.sortBy(list,['V'])
    this.filtereGoaeOptions = this.fullGoae

  }


  applyFilter(filterValue:string) {
    let numerics= ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    if(numerics.includes(filterValue[0])){
      this.filtereGoaeOptions = this.fullGoae.filter((item) =>  item.name != null && item.name.startsWith(filterValue.trim())).sort((a,b)=> a.V - b.V)
    } else {
      this.filtereGoaeOptions = this.fullGoae.filter((item) =>  (item.searchString != null) && item.searchString.toLowerCase().includes(filterValue.trim().toLowerCase())).sort((a,b)=> a.V - b.V)
      // this.filtereGoaeOptions = this.fullGoae.filter((item) =>  (item.searchString != null && item.V != null) &&   item.searchString.toLowerCase().includes(filterValue.trim().toLowerCase())).sort((a,b)=> a.V - b.V)
    }
    if (this.filtereGoaeOptions.length < 5) {
      this.height = this.filtereGoaeOptions.length * 32 + 'px'
    } else {
      this.height = '168px'
    }
  }
  applyFilterMaterial(filterValue: string) {
    this.filtereMaterialOptions.filter = filterValue.trim().toLowerCase();
    if (this.filtereMaterialOptions.filteredData.length < 5) {
      this.height = this.filtereMaterialOptions.filteredData.length * 32 + 'px'
    } else {
      this.height = '168px'
    }
  }
  applyFilterBilling(filterValue: string) {
    this.filteredBillingOptions.filter = filterValue.trim().toLowerCase();
    // console.log(this.filteredBillingOptions.filteredData.length);
    if (this.filteredBillingOptions.filteredData.length < 5) {
      this.height = this.filteredBillingOptions.filteredData.length * 32 + 'px'
    } else {
      this.height = '168px'
    }
    // console.log('OPEN', this.filteredBillingOptions.filteredData.length);
    
  }
  billingSelectChanged(event){
    this.selectedBillingItem = event.option.value
  }
  materialSelectChanged(event){
    this.selectedMaterialItem = event.option.value

  }
  goaeSelectChanged(event){
    this.selectedGoae = event.option.value
  }
  addNewPosition = async (goae:GOA_SMALL) => {
  
    const __gnr = await this.goaS.getfullItem(goae.documentId).then(
      _data =>{
        return _data;
      }
    )

        let _gnr = __gnr as Object;
        let p:PositionFlatI = {
          position_id:-1,
          position_order_index:0,
          activated:true,
          // justification:'',
          example:'',
          hasOrModeTrenner:false,
          surgery_relevant:true,
          quantity:1,

          progression_factor: Number(_gnr['progression_factor']),
          position_type: POSITION_TYPE.GOA,
          starts_new_group:false,


          goa_paragraph: {
     
            goa_id:_gnr['goa_id'],
			      documentId: goae.documentId,
            long_description:_gnr['long_description'],
            short_description:_gnr['short_description'],
            amount_euro: Number(_gnr['amount_euro'] != null &&_gnr['amount_euro']) ? Number(_gnr['amount_euro']) : 0,
            progression_factor:Number(_gnr['progression_factor'])
          }
        }
        this.dialogRef.close(p);
  }


  clearGoaSearch(){
    this.applyFilter('')
    this._goaTF.nativeElement.value = ''
    this.selectedGoae = null;
    this._goaTF.nativeElement.focus();
  }
  clearMaterialSearch(){
    this.applyFilterMaterial('')
    this._materialTF.nativeElement.value = ''
    this.selectedMaterialItem = null;
    this._materialTF.nativeElement.focus();
  }
  clearBillingSearch(){
    this.applyFilterBilling('')
    this._billingTF.nativeElement.value = ''
    this.selectedBillingItem = null;
    this._billingTF.nativeElement.focus();
  }

  displayFnGOA(item: GOA_SMALL): string {
    return item && item.name ? item.name : '';
  }
  displayFnMaterial(item: MaterialI): string {
    return item && item.bezeichnung ? item.bezeichnung : '';
  }
  displayFnBilling(item: BilligItemI): string {
    return item && item.short_description ? item.short_description : '';
  }
  save(){
    switch (this.type) {
      case AddCustomPositionSelectI.CustomPositonType.GOA:
        this.addNewPosition(this.selectedGoae)

        break;
      case AddCustomPositionSelectI.CustomPositonType.MATERIAL:
        this.selectedMaterialItem.quantity == null ? 1 : this.selectedMaterialItem.quantity
        this.dialogRef.close(this.selectedMaterialItem)
        break;
      case AddCustomPositionSelectI.CustomPositonType.OWN:
        this.dialogRef.close(this.selectedBillingItem)
        break;

    }
  }

  close(){
    this.dialogRef.close()
  }
}
