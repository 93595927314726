<div class="container-fluid pl0 pr0">

    
  <table class="table   pl0 pr0"  cellpadding='0'  cellspacing = '0'  style="padding-left: 0px!important; padding-right: 0px!important;"    displayMode="flat"  >
    <thead style="background-color: #fff; border: none; ">

      <tr class='mat-caption tr_head d-flex pb0' > 
        <th style="width: 30%!important; padding-left:20px">Bezeichnung</th>
        <th style="width: 70%!important;">Bericht</th>
      </tr>
    </thead>
    <tbody >
      
      <tr class='mat-body d-flex pl0 pr0' style="border-top:1px solid #D5DDE3!important">
        <td style="width: 30%!important; margin:0px!important; padding:0px!important; padding-left:20px!important;" >
          <p>Rechtfertigende Indikation</p>
        </td>

        <td style="width: 70%!important;  margin:0px!important; padding:0px!important; overflow: hidden!important;" >
          <div class="container pl8 pr8 p_t8 p_b10">
            <div class="d-flex flex-wrap" *ngIf='befund != null'>
              <div class="p-2 p_t0 pr0 pl0">
                <mat-icon class='justification_arrow_icon' (click)='prev_justifying_indications()'>keyboard_arrow_left</mat-icon>
              </div>
              <div class="p-2 p_t2 p_b0 pr0 pl0" style="font-size:14px; font-weight:300;">
                <span style="color: #1976D2; font-weight:500;">{{getSelected_justifying_indication_Index()+1}}</span>
                <span> von {{befund.justifying_indications.length}}</span>
              </div>
              
              <div class="p-2 p_t0 pr0 pl0">
                <mat-icon class='justification_arrow_icon' (click)='next_justifying_indications()'>keyboard_arrow_right</mat-icon>
              </div>

              <div class="p-2 p_t2 p_b0 pr0 pl10" *ngIf="!isPublicTemplate">
                <mat-icon (click)='add_justifying_indications_Item()' class='justification_arrow_icon' style="font-size: 20px!important;"
                matTooltipClass='sectionGoaeTT' matTooltip='Position hinzufügen' matTooltipPosition='above'>add</mat-icon>
              </div>
              <div class="p-2 p_t2 p_b0 pr0 pl0" *ngIf="!isPublicTemplate">
                <mat-icon (click)='remove_justifying_indications_Item()' class='justification_arrow_icon' style="font-size: 20px!important;"
                matTooltipClass='sectionGoaeTT' matTooltip='Position entfernen' matTooltipPosition='above'>remove</mat-icon>
              </div>

            </div>
            <div class="d-flex p_t0 p_b5 pr20" *ngIf='befund != null'>
                <textarea style="width:100%; height: 100%; background-color:rgba(0,0,0,0);"  [disabled]="isPublicTemplate"
                  rows="2" cols='2' class="mat-body formControl fs14-400" [value]='get_justifying_indications_Copy()'
                (change)='save_justifying_indications($event)'>
                </textarea>
            </div>    
          </div>
        </td>
      </tr>
      
      <tr class='mat-body d-flex pl0 pr0' style="border-top:1px solid #D5DDE3!important">
      
        <td style="width: 30%!important; margin:0px!important; padding:0px!important; padding-left:20px!important;" >
          <p>Technische Durchführung</p>
        </td>

        <td style="width: 70%!important;  margin:0px!important; padding:0px!important; overflow: hidden!important;" >
   
          <div class="container pl8 pr8 p_t8 p_b10 " >

            <div class="d-flex flex-wrap" *ngIf='befund != null'>
              <div class="p-2 p_t0 pr0 pl0">
                <mat-icon class='justification_arrow_icon' (click)='prev_technical_implementation()'>keyboard_arrow_left</mat-icon>
              </div>


              <div class="p-2 p_t2 p_b0 pr0 pl0" style="font-size:14px; font-weight:300;">
                <span style="color: #1976D2; font-weight:500;">{{getSelected_technical_implementation_Index()+1}}</span>
                <span> von {{befund.technical_implementation.length}}</span>
              </div>
              
              <div class="p-2 p_t0 pr0 pl0">
                <mat-icon class='justification_arrow_icon' (click)='next_technical_implementation()'>keyboard_arrow_right</mat-icon>
              </div>



              <div class="p-2 p_t2 p_b0 pr0 pl10" *ngIf="!isPublicTemplate">
                <mat-icon (click)='add_technical_implementation_Item()' class='justification_arrow_icon' style="font-size: 20px!important;"
                matTooltipClass='sectionGoaeTT' matTooltip='Position hinzufügen' matTooltipPosition='above'>add</mat-icon>
              </div>
              <div class="p-2 p_t2 p_b0 pr0 pl0" *ngIf="!isPublicTemplate">
                <mat-icon (click)='remove_technical_implementation_Item()' class='justification_arrow_icon' style="font-size: 20px!important;"
                matTooltipClass='sectionGoaeTT' matTooltip='Position entfernen' matTooltipPosition='above'>remove</mat-icon>
              </div>

            </div>
            <div class="d-flex p_t0 p_b5 pr20" *ngIf='befund != null'>
                <textarea style="width:100%; height: 100%; background-color:rgba(0,0,0,0);" [disabled]="isPublicTemplate"
                  rows="2" cols='2' class="mat-body formControl fs14-400" [value]='get_technical_implementation_Copy()'
                (change)='save_technical_implementation($event)'>
                </textarea>
            </div>    
          </div>
        </td>
      </tr>

      <tr class='mat-body d-flex pl0 pr0' style="border-top:1px solid #D5DDE3!important">
      
        <td style="width: 30%!important; margin:0px!important; padding:0px!important; padding-left:20px!important;" >
          <p>Befundbericht</p>
        </td>

        <td style="width: 70%!important;  margin:0px!important; padding:0px!important; overflow: hidden!important;" >
   
          <div class="container pl8 pr8 p_t8 p_b10 " >

            <div class="d-flex flex-wrap" *ngIf='befund != null'>
              <div class="p-2 p_t0 pr0 pl0">
                <mat-icon class='justification_arrow_icon' (click)='prev_report()'>keyboard_arrow_left</mat-icon>
              </div>


              <div class="p-2 p_t2 p_b0 pr0 pl0" style="font-size:14px; font-weight:300;">
                <span style="color: #1976D2; font-weight:500;">{{getSelected_report_Index()+1}}</span>
                <span> von {{befund.report.length}}</span>
              </div>
              
              <div class="p-2 p_t0 pr0 pl0">
                <mat-icon class='justification_arrow_icon' (click)='next_report()'>keyboard_arrow_right</mat-icon>
              </div>

              <div class="p-2 p_t2 p_b0 pr0 pl10" *ngIf="!isPublicTemplate">
                <mat-icon (click)='add_report_Item()' class='justification_arrow_icon' style="font-size: 20px!important;"
                matTooltipClass='sectionGoaeTT' matTooltip='Position hinzufügen' matTooltipPosition='above'>add</mat-icon>
              </div>
              <div class="p-2 p_t2 p_b0 pr0 pl0" *ngIf="!isPublicTemplate">
                <mat-icon (click)='remove_report_Item()' class='justification_arrow_icon' style="font-size: 20px!important;"
                matTooltipClass='sectionGoaeTT' matTooltip='Position entfernen' matTooltipPosition='above'>remove</mat-icon>
              </div>

            </div>
            <div class="d-flex p_t0 p_b5 pr20" *ngIf='befund != null'>
                <textarea style="width:100%; height: 100%; background-color:rgba(0,0,0,0);"  [disabled]="isPublicTemplate"
                  rows="12" cols='2' class="mat-body formControl fs14-400" [value]='get_report_Copy()'
                (change)='save_report($event)'>
                </textarea>
            </div>    
          </div>
        </td>
      </tr>

    </tbody>
  </table>
</div>
