import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {  PositionFlatI, PositionProgressionFactorChanged, PositionProgressionFactors, ProgressionFactorItem } from 'src/app/core/models/sectionPositionM';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { AddCustomProgressionFactorComponent } from 'src/app/ui/overlays/add-custom-progression-factor/add-custom-progression-factor.component';
import * as _ from "lodash"
import { MatMenuTrigger } from '@angular/material/menu';
export interface IProgressionFactors {
  
  view_value:string;
  factor:number;
  
}
@Component({
  selector: 'position-progression-factor',
  templateUrl: './progression-factor.component.html',
  styleUrls: ['./progression-factor.component.scss']
})
export class ProgressionFactorComponent implements OnInit, OnDestroy{
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @Input() position:PositionFlatI;  
  @Input() itemDisabled:boolean;  
  @Input() showProgressionFactor:boolean;
  // @Input() progression_factors: PositionProgressionFactors[];
   progression_factors: ProgressionFactorItem[] = []
  @Input() set hasFocus(val: boolean) {
    if(val === true) {
      setTimeout(()=>{
        this.menuTrigger.openMenu()
      },250)
    }
  }
  // @Output() progressionFactorChanged:EventEmitter<PositionProgressionFactorChanged> = new EventEmitter<PositionProgressionFactorChanged>();

  oldValue
  newValue
  progression_factor
  factors: IProgressionFactors[] = []

  //factors:PositionProgressionFactors[] = []
  constructor(
    private dialog:MatDialog,
    public tc: TemplateControllerService
    ) { 

  }
  

  
  ngOnInit(): void   {
    this.progression_factor = this.position.progression_factor;
    this.progression_factors = this.tc.progressionFactors
    // this.factors = _.cloneDeep(this.progression_factors)
    this.progression_factors.forEach(element => {
      this.factors.push({view_value: element.view_value, factor: element.factor})
    })
    const index = this.factors.findIndex(item => item.factor == this.position.progression_factor)
    if (index == -1) {
       this.factors.push({view_value: this.position.progression_factor.toString(), factor: this.position.progression_factor})
    }
  }
  
  select(value:PositionProgressionFactors){
    console.log(value);
    
    this.progression_factor = value.view_value
    this.position.progression_factor = value.factor;
    this.tc.updateSinglePositionItem(this.tc.currentTemplateId, this.position.position_id, {progression_factor: value.factor})
    // this.progressionFactorChanged.emit({isPublic: value.isPublic, custom_factor_id: value.id, factor: value.factor, position: this.position})
    // this.tc.positionFactorChanged.emit(this.position.position_id)
    // goaJustigicatiionUpdate
  }
  
  add(){
    this.dialog.open(AddCustomProgressionFactorComponent,{
      width:'250px',
      disableClose:true,
      data: this.position,
    }).afterClosed().subscribe(
      (_newFactor:PositionProgressionFactorChanged) => {
        if (_newFactor) {
          console.log(_newFactor);
          this.progression_factor = _newFactor
          this.position.progression_factor = Number(_newFactor)
          
          // this.progression_factor = _newFactor.factor.toString()
          // this.position.progression_factor = _newFactor.factor
          this.tc.updateSinglePositionItem(this.tc.currentTemplateId, this.position.position_id, {progression_factor: _newFactor})
           //this.progressionFactorChanged.emit({isPublic: _newFactor.isPublic, custom_factor_id: _newFactor.custom_factor_id, factor: _newFactor.factor, position: this.position})
        }
      }
      )
    }
    
    
    ngOnDestroy(): void {
     /*  if(this.changeSubscription != null){
        this.changeSubscription.unsubscribe();
      } */
    }
}
