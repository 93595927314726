import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressI } from 'src/app/core/models/accountI';
import { AccountService } from 'src/app/services/account.service';
import { ACCOUNT_CALL } from 'src/app/services/enums/accountS.callEnum';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-fix-address',
  templateUrl: './fix-address.component.html',
  styleUrls: ['./fix-address.component.scss']
})
export class FixAddressComponent implements OnInit {
  currentAddress:AddressI = {
    id:-1,
    abteilung:"",
    arzt_role:"",
    bank_name:"",
    behandelnder_arzt:"",
    bic:"",
    city:"",
    facharzt_bezeichnung:"",
    iban:"",
    klinikName:"",
    kontoinhaber:"",
    streetName:"",
    streetNumber:"",
    ust_nr:"",
    zip:""
  }
  constructor(public ui:UiService,
    private accountS:AccountService,
    private dialoogRef:MatDialogRef<FixAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddressI
    ) {
      this.currentAddress = data
     }

  ngOnInit(): void {
  }

  async save(){
    /* console.log(this.currentAddress)
    return */
    const __data = await this.accountS.apiPostObjectAndParams(ACCOUNT_CALL.UPDATE_ADDRESS_BY_ID,this.currentAddress.id.toString() ,{payload: this.currentAddress} )
    .then(
      (data)=>{
        this.ui.loadAddresses()
        this.close()
        // setTimeout(()=>{
        //   this.addresses = this.ui.addresses;
        //   this.isSaving = false;
        // },250)
      }, err =>{
        this.close()
        // this.isSaving = false;

      }
      )
  }
  get saveDisabled():boolean{
    if(this.currentAddress == null || this.currentAddress.klinikName == null || this.currentAddress.klinikName == ''
    || this.currentAddress.streetName == null || this.currentAddress.streetName == ''
    || this.currentAddress.zip == null || this.currentAddress.zip == ''
    || this.currentAddress.city == null || this.currentAddress.city == ''){
      return true
    }
   /// return this.ui.addresses.length == 1
   return false
  }
  close(){
    this.dialoogRef.close()
  }
}
