import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PatientFull } from 'src/app/core/models/patient';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientService } from 'src/app/services/patient.service';
import { MatSort } from '@angular/material/sort';

import { UiService } from 'src/app/services/ui.service';
import { PatientFlatI } from 'src/app/core/models/v3/patientFlat';
import { HealthInsuranceM } from 'src/app/core/models/healthinsurance';
import { API_POST_MODE, PATIENT_SELECTION_VIEW_MODE } from 'src/app/core/models/enums';
import { PatientSelectionInputI } from 'src/app/core/models/viewI/patientSelectionInputI';
import { IS_INT } from 'src/app/utils/helpers';


@Component({
  selector: 'app-patient-selection',
  templateUrl: './patient-selection.component.html',
  styleUrls: ['./patient-selection.component.scss']
})
export class PatientSelectionComponent implements OnInit {
  
  // @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static:true}) sort: MatSort;

  healthEnsurances:HealthInsuranceM[] = []
  
  patientDetailViewMode:PATIENT_SELECTION_VIEW_MODE
  currentViewMode:PATIENT_SELECTION_VIEW_MODE
  
  // paginatorPageSize = 0
  // paginatorLength = 100;
 
  // userPath:string;
  patients:PatientFlatI[] = [];
  displayedColumns = ['lastName','firstName','Geburtsdatum','city','zip'];
  dataSource: any; 
  hasExistingUserModified = false
  hasPatientItemErrors = false
  newPatient:PatientFull
  viewModes = PATIENT_SELECTION_VIEW_MODE
  isLoading = true
  private _inputItem:PatientSelectionInputI

  constructor(
    public dialogRef: MatDialogRef<PatientSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PatientSelectionInputI,
    public ui:UiService,
    private patientService:PatientService 
    ) {
      this._inputItem = data;
      this.currentViewMode = data.startMode  
      this.patientDetailViewMode = data.startMode  
    }

  ngOnInit(): void {
    
    

    if(this._inputItem){
      this._initOverlay()
    } else {
      setTimeout(()=>{
        this.close()
      },1000)
    }
    
  }

  private _initOverlay(){

    switch(this._inputItem.startMode){
      case PATIENT_SELECTION_VIEW_MODE.LIST_PATIENTS_MODE:
        this.loadPatienten();
        break;
      case PATIENT_SELECTION_VIEW_MODE.MODIFY_MODE:
      this.openFullPatient(this._inputItem.openWithPatientId)
        break;
      case PATIENT_SELECTION_VIEW_MODE.CREATE_NEW_MODE:
        break;
      // case PATIENT_SELECTION_VIEW_MODE.OPEN_DETAIL_MODE:

      //   break;
    }


    
  }

  async loadPatienten(){
    // let uid = this.ui.account.uid
    // if(this.ui.isAccountant) {
      
    // } else {

    // }
    const _patientsAllFlat = await this.patientService.allFlat(this._inputItem.uid);
    this.patients = []
    this.patients = _patientsAllFlat['result'] as PatientFlatI[]
    this.dataSource = new MatTableDataSource(this.patients);
    this.dataSource.sort = this.sort;
    this.isLoading = false
    console.log(this.patients)
  }
  
  save(){}

  textChanged(e){}

  close(){
    this.dialogRef.close()
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  select(row:PatientFlatI){
    // this.openFullPatient(row.patient_id)
    this.dialogRef.close(row)
  }
  async openFullPatient(patId:any){
    const res = await this.patientService.getSingle(patId)
    // console.log(res)
    if(this.healthEnsurances.length == 0) {
      this.healthEnsurances = await this.patientService.getEnsurances();
    }
    
    this.newPatient = res as PatientFull
    this.currentViewMode = PATIENT_SELECTION_VIEW_MODE.MODIFY_MODE
    
    this.isLoading = false
  }
  changePage(event){
    console.log("change " + event.pageIndex)
  }
  
  showListView(){
    this.loadPatienten();
    this.currentViewMode = PATIENT_SELECTION_VIEW_MODE.LIST_PATIENTS_MODE
    this.newPatient = null
  }
  closeEditMode(){
    this.loadPatienten();
    this.newPatient = null
  }
  async addPatient(){
    
    if(this.healthEnsurances.length == 0) {
      this.healthEnsurances = await this.patientService.getEnsurances();
    }
    
    const _newPatient:PatientFull ={
      patient_id:-1,
      birthDate: null,//new Date(Date.now()).getTime(),
      firstName:'',
      lastName:'',
      oldDocumentId:'',
      invoiceName:'',
      phone:'',
      mobile_phone:'',
      email:'',
      ensuranceDocId:'',
      health_insurance_id: null,
      addon:'',
      street:'',
      zip:'',
      city:'',
      
    }
    this.newPatient = _newPatient
    this.currentViewMode = PATIENT_SELECTION_VIEW_MODE.CREATE_NEW_MODE
    // this.isAddOrModify = true;
    
  }
  
  onExistingUserModified(e){
    if(e === 'patient_changed'){
      this.hasExistingUserModified = true
    } else if(e === 'ERRORS') {
      this.hasPatientItemErrors = true;
    } else if(e === 'NO_ERRORS') {
      this.hasPatientItemErrors = false;
    }
    
  }
  
  insertPat(){
    this.dialogRef.close(this.newPatient)
  }
  async savePatient(andUse:boolean){
    this._save(andUse)
  }
  

  private async _save(pClose: boolean){

    const _data = { "payload": this.newPatient }
    // console.log(_data);
    // return;
    
    let _mode = null 
    if(this.newPatient.patient_id != null && IS_INT(this.newPatient.patient_id)) {
      if(this.newPatient.patient_id == -1){
        _mode = API_POST_MODE.ADD_NEW;
      } else if(this.newPatient.patient_id > 0){
        _mode = API_POST_MODE.UPDATE;
      }
    }
    
    if(_mode == null) {
      this.newPatient = null;
      this.dialogRef.close()
      return
    }
    
    const result = await this.patientService.postPatient(_mode,_data, this._inputItem.uid)

    // console.log(result)
    
      
    setTimeout(()=>{
      this.closeEditMode()
    },150)
    if(pClose || this.currentViewMode === PATIENT_SELECTION_VIEW_MODE.MODIFY_MODE){
      setTimeout(()=>{
        this.dialogRef.close(result)
      },200)
    } else {
      setTimeout(()=>{
        this.showListView()
      },200)
    }
    
  }
  get title() : string {
    return this.patientDetailViewMode === PATIENT_SELECTION_VIEW_MODE.CREATE_NEW_MODE ? 'Neuen Patienten hinzufügen' : 'Patient bearbeiten';
  }
  get canSave():boolean {
    if(this.newPatient == null) { return false }
    if(this.newPatient.firstName.length > 2  && this.newPatient.lastName.length > 2 && 
      this.newPatient.street.length > 3 && this.newPatient.zip.length > 3 && this.newPatient.city.length > 3 || !this.hasPatientItemErrors) {
      return true
    } else {
      return false
    }
  }
  // get firstNameError():boolean {
  //   return this.newPatient == null || this.newPatient.firstName == null || this.newPatient.firstName.length < 2
  // }
  // get lastNameError():boolean {
  //   return this.newPatient == null || this.newPatient.lastName == null || this.newPatient.lastName.length < 2
  // }
  // get streetError():boolean {
  //   return this.newPatient == null || this.newPatient.street == null || this.newPatient.street.length < 3
  // }
  // get zipError():boolean {
  //   return this.newPatient == null || this.newPatient.zip == null || this.newPatient.zip.length < 3
  // }
  // get cityError():boolean {
  //   return this.newPatient == null || this.newPatient.city == null || this.newPatient.city.length < 3
  // }
  
}
