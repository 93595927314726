import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IOpenProcessOverlayData } from 'src/app/core/models/viewI/openProcessOverlayData';
import { LocaleService } from 'src/app/services/locale.service';

@Component({
  selector: 'app-open-process-component-overlay',
  templateUrl: './open-process-component-overlay.component.html',
  styleUrls: ['./open-process-component-overlay.component.scss']
})
export class OpenProcessComponentOverlayComponent implements OnInit {

  title = ""
  body = ""
  resumeBtn?:string
  cancelBtn?:string
  constructor(
    public ls: LocaleService,public dialogRef: MatDialogRef<OpenProcessComponentOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IOpenProcessOverlayData) {
      this.title = data.title
      this.body = data.body
      this.resumeBtn = data.resumeBtn
      this.cancelBtn = data.cancelBtn

    } 

  ngOnInit(): void {
    if(!this.resumeBtn && !this.cancelBtn) {

      setTimeout(()=>{
        this.close()
      },7000)
    }
  }

  get canSave(): boolean {
   return false
  }

  resume() {
    this.dialogRef.close('SHOW_READ_ONLY')
  }
  close() {
    this.dialogRef.close()
  }
}
