<div class="container-fluid pl0 pr0 p_t20 ">
  <template-op-position-item
    [editable]='editable'
    [section]='section'
    [templateDocumentId]='templateDocumentId'
    (deletePositonAtIndex)='deletePositonAtIndexCallback($event)'
  ></template-op-position-item>

  <div class='container p_t20'></div>

</div>
<!-- [showAllSurgeryRelevantItems]='showAllSurgeryRelevantItems' -->
