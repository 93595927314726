import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { template_item_text_tiles, template_item_text_tiles_local } from 'src/app/core/models/api/templateUtils';
import * as _ from 'lodash';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
@Component({
  selector: 'app-text-item-tile-edit',
  templateUrl: './text-item-tile-edit.component.html',
  styleUrls: ['./text-item-tile-edit.component.scss']
})
export class TextItemTileEditComponent implements OnInit {
  oldVersion = false;
  @ViewChild('editTextArea') editTextArea: ElementRef
  currentIndex = 0
  editable = true
  description:string = ''
  text:string = ''
  _template_item_text_tiles:template_item_text_tiles
  _local_tile:template_item_text_tiles_local
  originText = ""
  originDescription = ""
  showSpinner = false

  constructor(
    private tc: TemplateControllerService,
    public dialogRef: MatDialogRef<TextItemTileEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: template_item_text_tiles_local) { 
      this._template_item_text_tiles = {
        description: this.data.item_tile.description,
        text: this.data.item_tile.text,
        lastModified: this.data.item_tile.lastModified,
        text_tile_id: this.data.item_tile.text_tile_id,
        templateId: this.data.item_tile.templateId,
        type: this.data.item_tile.type
      }
      this._local_tile = {
        index: data.index,
        updated: data.updated,
        item_tile: this._template_item_text_tiles 

      }
      this.description = this._template_item_text_tiles.description
      this.text = this._template_item_text_tiles.text
      this.currentIndex = data.index
      this.originText = this.text
      this.originDescription = this.description
    }

  ngOnInit(): void {
    setTimeout(()=>{
      this.editTextArea.nativeElement.focus()
    },250)
  }

  get itemCount() : number {
    return 0
  }

  prevItem(){

  }

  nextItem(){

  }

  addNewItem(){

  }
  
  removeItem() {
    
  }

  desciptionChanged(e){

  }



  get saveDisabled():boolean {
    if(this.data.item_tile.description == this.description && this.text == this.data.item_tile.text){
      return true;
    }
    return false
  }

  private async saveChanges(closeAfterSaving: boolean) {
    this.showSpinner = true;
    this._template_item_text_tiles.description = this.description;
    this._template_item_text_tiles.text = this.text;
    await this.tc.update_item_text_tile(this._template_item_text_tiles.type, this._template_item_text_tiles.templateId, this._template_item_text_tiles)
    setTimeout(() => {
      this.showSpinner = false;
      if(closeAfterSaving === true) {
        this.close();
      }
    },50);
  }

  //----------------------------------------------------------------------
  onEditTextAreaBlur(event: any) {
    const _isEqual = _.isEqual(event.target.value, this.originText)
    if(!_isEqual) {
      // this.save()
      this.saveChanges(false);
    } else {
      // this.close()
    }
  }

  async onEditTextAreaKeyDown(event){
    // console.log("onEditTextAreaKeyDown ", event.target.value);
    if(event.key == 'Escape' || event.key == 'escape') {
      const _isEqual = _.isEqual(event.target.value, this.originText)
      // console.log("onEditTextAreaKeyDown >>>> Escape ", _isEqual);
      if(!_isEqual) {
        // this.save()
        this.saveChanges(true);
      } else {
        this.close()
      }
    }
  }
  
  //----------------------------------------------------------------------
  async onEditTextInputKeyDown(event){
    if(event.key == 'Escape' || event.key == 'escape') {
      const _isEqual = _.isEqual(event.target.value, this.originDescription)
      if(!_isEqual) {
        this.saveChanges(true);
      } else {
        this.close()
      }
    }
  }

  async onEditTextInputBlur(event: any) {
    const _isEqual = _.isEqual(event.target.value, this.originDescription)
      if(!_isEqual) {
        this.saveChanges(false);
      } else {
        // this.close()
      }
  }

  close(){
    this.dialogRef.close();
  }




}
