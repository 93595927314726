<div class="container-fluid pl0 pr0 p_t20">
  <template-scs-report-position-item
  [isPublicTemplate]='isPublicTemplate'
  [isInProcesses]='isInProcesses'
  [section]='section' 
  [templateDocumentId]='templateDocumentId'
  (deletePositonAtIndex)='deletePositonAtIndexCallback($event)'
  ></template-scs-report-position-item>
    
      
  <div class='container p_t20'></div>
</div>
