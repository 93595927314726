import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TemplatePositionOverlayComponent } from 'src/app/ui/components/template-position-overlay/template-position-overlay.component';

@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.scss']
})
export class TestPageComponent implements OnInit {

  constructor(
    private dialog: MatDialog) { }

  ngOnInit(): void {
  }


  openOverlay() {
    this.dialog.open(TemplatePositionOverlayComponent, {
      backdropClass:"backdrop-background",
      width: "80%",
      maxWidth: "1200px",
      height: '80%',
      maxHeight: '900px',
      data: 22655
    })
  }
}
