import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { AuthService } from 'src/app/shared/auth.service';
import { AccountI, AddressI,  UserAddressOverlayComponentData } from 'src/app/core/models/accountI';
import { MatDialog } from '@angular/material/dialog';
import { ControllerService } from 'src/app/shared/controller.service';
import { DxFxBreadCrumb } from 'src/app/core/models/breadCrumpIntemI';
import { Subscription } from 'rxjs';
import { version } from 'src/environments/version';
import { UiService } from 'src/app/services/ui.service';
import { UserAddressOverlayComponent } from 'src/app/ui/overlays/user-address-overlay/user-address-overlay.component';
import { ChangePasswordComponent } from 'src/app/ui/overlays/change-password/change-password.component';
import { HttpClient } from '@angular/common/http';
import { AccountViewService } from './account-view.service';
import { ACCOUNT_VIEW_STATE } from './acount.helper';
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.styles.scss']
  
})
export class AccountComponent implements OnInit {


  greedBackground = false
  ACCOUNT_STATE = ACCOUNT_VIEW_STATE

  userPath:string
  account:AccountI
  loading:boolean = true;
  selectedTabIndex = 0
  
  dataSource: any
  employeeDataSource:any
  displayedColumns = ['titel','firstName','lastName','phone','email'];
  accountSubscription:Subscription
  version:string = ''
  progress: { percentage: number } = { percentage: 0 };
  // addresses:AddressI[] = []
  // addressIndex = 0
  // loadingAccounting = true
  // currentAddress:AddressI  = {
    
  //   id:-1,
  //   klinikName:'',
  //   streetName:'',
  //   streetNumber:'',
  //   zip:'',
  //   city:''
  // }
  panelOpenState = false;
  loadingAccounting = false
  constructor(
    public accountView: AccountViewService,
    private accountS:AccountService,
    private authS:AuthService,
    private dialog:MatDialog,
    private http: HttpClient,
    public ui:UiService

    ) { 

      

    }

  ngOnInit(): void {
    this.version = version.version
    setTimeout(()=>{
      ControllerService.setCurrentPage('einstellungen');
      let _bc:DxFxBreadCrumb = {
        bcItems: [
          {title:'Einstellungen',path:''},
        ]
      }

      ControllerService.setBreadCrumbPath(_bc);
      // if(this.ui.isClinicUser){
      //   this.ui.loadClinicSectors()
      // }
      this.show()
      this.accountView.nav(ACCOUNT_VIEW_STATE.MAIN)
    },1000)



   
    
    // this.http.get<any>(ACCOUNT_API_PATH(ACCOUNT_CALL.GET_ACCOUNTING))
    
    // .subscribe({
    //     next: data => {
    //       console.log(data);
          
    //         // this.totalAngularPackages = data.total;
    //     },
    //     error: error => {
    //         // this.errorMessage = error.message;
    //         console.error('There was an error!', error);
    //     }
    // })
  }
  

  nav(vs: ACCOUNT_VIEW_STATE) {
    this.accountView.nav(vs)
  }
  


  // async loadAccounting() {
  //   this.loadingAccounting = true
  //   if(this.ui.accountingAvailable && !this.ui.isClinicUser){

      // setTimeout(() => {
      //   this.ui.loadAccounting()
      // },1000)
  //   }
  //   // .subscribe(
  //   //   (_data: any) => {
  //   //     if (_data.status == 'ERROR') {
  //   //       console.log("ERROR", _data.message)    
  //   //     } else {
  //   //       this.ui.setAccountingPool(_data)
          
  //   //       this.dataSource = new MatTableDataSource(this.ui.accountingPool.users);
  //   //     }
  //   //     this.loadingAccounting = false
  //   //     // accountingPool
  //   //   }
  //   // )
  //   // if (result.status == 'ERROR') {
      
  //   // } else {

  //   // }
  //   // console.log(result)
  //   // this.http.get<any>(ACCOUNT_API_PATH(ACCOUNT_CALL.GET_ACCOUNTING))
    
  //   // .pipe(
    
  //   //   catchError(error => {
  //   //     // Here you can handle the error and retrieve the HTTP status code
  //   //     const statusCode = error.status;
  //   //     // Do something with the status code
  //   //     // console.log(statusCode);
        
  //   //      return throwError(error);
  //   //   })
  //   // )
  
  //   // .subscribe(response => {
  //   //   // This is where you can handle the successful response
      
  //   //   if ( response.status == 'ERROR') {
  //   //     console.log("ERROR", response.message);
  //   //   } else {
  //   //     console.log(response);
  //   //   }
  //   //       // return response
  //   // });

   
  //   // //await this.ui.reloadAccount()
  //   // await this.ui.loadAccounting()
  
    
    
   
  //   // setTimeout(()=>{
  //   //   this.loadingAccounting = false
  //   // },250)
   
  // }

  nextAddress(){

  }
  prevAddress(){

  }
  onTabChanged(e) {
    this.selectedTabIndex = e.index
    if(e.index == 2 && this.ui.accountingAvailable) {
      // this.loadAccounting()
    }
  }
  get getCurrentAddressIndex():number {
    return 0;
  }

  async show(){
    // this.addresses = this.ui.addresses;
    // this.currentAddress = this.ui.addresses[0]
    this.account = this.ui.account//__account;

    // if(this.account.doc_description == null) {
    //   this.account.doc_description = ''
    // }
    this.loading = false;

  }

  async save(){
    if(this.account != null){
      this.ui.account = this.account
      await this.ui.updateAccont()
    }
    setTimeout(()=>{
      this.show()
    },2000)
  }

  logout(){
    this.authS.SignOut();
  }

  editAddress(address:AddressI){
    let editData: UserAddressOverlayComponentData = {
      mode:"edit",
      address:address
    }
    this.dialog.open(UserAddressOverlayComponent, {
      backdropClass:"backdrop-background",
      width:"70%",
      maxWidth:'650px',
      data:editData,
      disableClose:true,
    }).afterClosed().subscribe(
      _message => {
        if(_message != null){
          this.ui.loadAddresses()
        }
      }
    )
  }
  async addAdress(){

    let editData: UserAddressOverlayComponentData = {
      mode:"add",
      address: {
        id:-1,
        klinikName:'',
        streetName:'',
        streetNumber:'',
        zip:'',
        city:''
      }
    }
    this.dialog.open(UserAddressOverlayComponent, {
      backdropClass:"backdrop-background",
      width:"70%",
      maxWidth:'650px',
      data:editData,
      disableClose:true,
    }).afterClosed().subscribe(
      _message => {
        if(_message != null){
          this.ui.loadAddresses()
        }
      }
    )
  }
  changePasswort(){
    this.dialog.open(ChangePasswordComponent,{
      width: "500px"
    })
  }

  get showAccountingTAB() : boolean {
    if(this.ui.isClinicUser) {
      if(this.ui.hasTemplateAdminRole) {
        return true
      } else {
        return false
      }
    } else {
      if(this.ui.isDocRole) {
        return true
      } else {
        return false
      }
    }
    
  }

}
