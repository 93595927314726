<!-- <mat-tab-group>
  <mat-tab label="ANTIBIOSE">
    
  </mat-tab>
  <mat-tab label="LAGERUNG">

  </mat-tab>
  <mat-tab label="LOKALISIERUNG">

  </mat-tab>
</mat-tab-group> -->



<div class="p-0">
  <div class="d-flex flex-column">
    <div class="p-0" style="height: 50px;">
      <div class="d-flex">
        <div class="p-2 flex-fill p_t4">
          <mat-form-field appearance="outline" class="textfield100 select-form-field">
            <mat-select (selectionChange)='changed($event)' [(value)]="selectedOpItem" >
              <mat-option *ngFor="let llaType of allTypes" [value]="llaType.type">
                {{llaType.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="p-2 ml-auto">
          <div class="d-flex align-items-center add-button pr0" (click)='addOpZeug()'>
            <div class="p_2 align-text-end pl12 pr0 mr0">
              <mat-icon class="nav-icon-mini pr0 mr0">add</mat-icon>
            </div>
            <div class="p-2 btn-copy align-text-start  p_t5 p_b5 pl0 ml0 pr16">Element anlegen</div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-0 flex-fill" style="overflow-y: scroll; " [ngStyle]='{"height":getPanelHeight}'>
      <!-- height: 254px -->
      <div style="padding: 0px!important; margin: 0px!important;" >
        
        <div class="p-0" *ngIf="selectedOpItem === allValues.ANTIBIOSE">
          <div class="d-flex flex-column">
            <div class="p-2 opZeugItem" *ngFor="let _item of allLLA_ANTIBIOSE" (click)="editOpZeug(_item)">
              {{_item.text}}
            </div>
          </div>
        </div>
        <div class="p-0" *ngIf="selectedOpItem === allValues.LAGERUNG">
          <div class="d-flex flex-column">
            <div class="p-2 opZeugItem" *ngFor="let _item of allLLA_LAGERUNG" (click)="editOpZeug(_item)">
              {{_item.text}}
            </div>
          </div>
        </div>
        <div class="p-0" *ngIf="selectedOpItem === allValues.LOKALISIERUNG">
          <div class="d-flex flex-column">
            <div class="p-2 opZeugItem" *ngFor="let _item of allLLA_LOKALISIERUNG" (click)="editOpZeug(_item)">
              {{_item.text}}
            </div>
          </div>
        </div>

        <!-- <div class="row" style="padding: 0px!important; margin: 0px!important;" >
      
          <table mat-table [dataSource]="employeeDataSource" matSort class="mat-elevation-z0" style="width: 100%; ">

            <ng-container matColumnDef="titel">
              <th mat-header-cell *matHeaderCellDef mat-sort-header ><span class="pl30">Titel</span></th>
              <td mat-cell *matCellDef="let element" class="pl40"><span class="pl30">{{element.titel}}</span></td>
            </ng-container>
            
            <ng-container matColumnDef="firstName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Vorname</th>
              <td mat-cell *matCellDef="let element"> {{element.firstName}}</td>
            </ng-container>
          
            <ng-container matColumnDef="lastName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Nachname</th>
              <td mat-cell *matCellDef="let element"> {{element.lastName}}</td>
            </ng-container>
          
            
            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Tel </th>
              <td mat-cell *matCellDef="let element">{{element.phone}}</td>
            </ng-container>
            
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef >Email</th>
              <td mat-cell *matCellDef="let element">{{element.email}}</td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row class="element-row" *matRowDef="let row; columns: displayedColumns;" (click)='openDetails(row)' ></tr>
          
          </table>
      
       </div> -->
      
      </div>
    </div>
  </div>
</div>