export interface Patient {
  docId?:string;
  firstName?:string;
  lastName?:string;
  invoiceName?:string;
  addressAddon?:string;
  displayName?:any;
  phone?:string;
  email?:string;
  street?:string;
  zip?:string;
  city?:string;
  ensuranceDocId?:string;
  private?:boolean;
  birthDate?:Date
  enabled?:boolean
  unencrypted?:boolean;
}
export interface PatientFull {
  patient_id:number;
  state?:PatentState
  anrede?:string;
  geschlecht?:Geschlecht;
  oldDocumentId?:string;
  birthDate?:number
  invoiceName?:string;
  mobile_phone?:string;
  phone?:string;
  email?:string;
  firstName?:string;
  lastName?:string;
  notes?:string;
  health_insurance_id?:number
  title?:string;
  patient_address_id?:number
  ensuranceDocId?:string;
  addon?:string;
  street?:string;
  streetNumber?:string
  city?:string;
  zip?:string;


}
export enum Geschlecht {
  m = 'm',
  w = 'w',
  u = 'u',
}
export enum Anrede {
  Ohne_Anrede = 'Ohne_Anrede',
  Frau = 'Frau',
  Herr = 'Herr',
  Herr__Frau = 'Herr_/_Frau'
}
interface PDisplayName {
  firstName:string;
  lastName:string;
}
export enum PatentState {
  ENABLED = 'ENABLED',
  DELETED = 'DELETED'
}
