import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-df-icon-button',
  templateUrl: './df-icon-button.component.html',
  styleUrls: ['./df-icon-button.component.scss']
})
export class DfIconButtonComponent implements OnInit {

  @Input() disabled:boolean = false
  @Input() color:string = 'base'
  
  @Input() title:string
  @Input() icon:string
  @Output() clicked = new EventEmitter();
  constructor() { 
    if(this.color == null){}
  }

  ngOnInit(): void {

  }
  tapped(){
    this.clicked.emit()
  }

}
