<div class="container-fluid op-container">
  <div class="container-fluid p_t20">
    <div class="row pl20 pr20" style="position: relative;">
      <div class="col-12" style="min-height: 510px;">
        <mat-card>
          <div class="d-flex flex-column">
            <div class="p-0">
              <mat-toolbar class="d-flex align-items-center" style="height: 52px;">

                  <div class="p-0 p_t2" *ngIf='!isEmpty'>
                    <mat-icon class='justification_arrow_icon' [class.enabled]='editable'
                     (click)='prevItem()'>keyboard_arrow_left</mat-icon>
                  </div>
              
                  <div class="p-0 " style="font-size:14px; font-weight:300; margin-top: 3px;"  *ngIf='!isEmpty'>
                    <span style="color: #1976D2; font-weight:500;"  
                    [class.enabled]='editable'>{{currentIndex + 1}}</span>
                    <span [class.enabled]='editable'> von {{itemCount}}</span>
                  </div>
              
                  <div class="p-0 p_t2" *ngIf='!isEmpty'>
                    <mat-icon class='justification_arrow_icon' [class.enabled]='editable'
                    (click)='nextItem()'>keyboard_arrow_right</mat-icon>
                  </div>
          
                  <div class="p-0 p_t2">
                    <mat-icon (click)='addNewItem()' class='justification_arrow_icon' style="font-size: 20px!important;"
                    >add</mat-icon>
                  </div>
                  <div class="p-0 p_t2" *ngIf='!isEmpty'>
                    <mat-icon (click)='removeItem()' class='justification_arrow_icon' style="font-size: 20px!important;"
                    >delete</mat-icon>
                  </div>
              
                  <div class="p-2 pr0 flex-fill" *ngIf='!isEmpty'>
                    <input class="formControl mat-body formControlDescription fs14-400" placeholder="Kurze Beschreibung (max 80 Zeichen)" [disabled]='!editable'
                    [value]='description' maxlength="80" (change)="descrChangeFn($event)">
                  </div>
          
                  <!-- <mat-icon (click)='print()' class='justification_arrow_icon' style="font-size: 20px!important;"                  *ngIf="editable && !isEmpty && showPrintButton" >print</mat-icon> -->
                <span class="toolbar-spacer"></span>
                <app-df-icon-button  title="Nachbehandlungsschema erstellen" icon="print"  [disabled]="!canFinalCreate" color="primary" (clicked)="download()" ></app-df-icon-button>  
                <app-df-icon-button  title="Speichern" icon="save" *ngIf="hasChanges" color="accent" (clicked)="save()" ></app-df-icon-button>  
                <!-- <mat-icon (click)='download()' class='justification_arrow_icon' style="font-size: 20px!important;"*ngIf="editable && !isEmpty" >save</mat-icon> -->
              </mat-toolbar>
            </div>
            <div class="p-0" *ngIf="editable && !isEmpty">
              <!-- (change)="modelChangeFn($event)" -->
              <ejs-richtexteditor 
          
              #templateRTE  [enableResize]="false" 
              id="name" 
              (change)="textChangeFn($event)"
              (keyup)="modelChangeFn($event)"
              name="name" [value]="nachbehandlungsschema_text" 
              [toolbarSettings]='tools' 
              [editorMode]='mode' 
              [pasteCleanupSettings]="pasteCleanupSettings"
              >
            </ejs-richtexteditor>
            </div>
            <div class="p-0" *ngIf="!editable && !isEmpty" [innerHTML]="nachbehandlungsschema_text">
              
            </div>
          </div>
          <div class="preloader" *ngIf="isLoading">
            <div class="d-flex align-items-center justify-content-center h100">
              <div class="p-2">
                <mat-spinner diameter='25' color='primary'></mat-spinner>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
