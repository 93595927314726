import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ChangesItem } from 'src/app/core/models/goaeM';

@Component({
  selector: 'app-goae-changes-info',
  templateUrl: './goae-changes-info.component.html',
  styleUrls: ['./goae-changes-info.component.scss']
})
export class GoaeChangesInfoComponent implements OnInit {

  changesInfo:ChangesItem = {
    changesCopy:"",
    timestamp: {seconds: new Date(Date.now()).getTime()}
  }
  constructor(public dialogRef: MatDialogRef<GoaeChangesInfoComponent>,) { }

  ngOnInit(): void {

  }

  getDateFromTimeStamp(second:number):Date{
    return new Date(this.changesInfo.timestamp.seconds)
  }

  close(){
    this.dialogRef.close();
  }
  save(){
    this.dialogRef.close(this.changesInfo);
  }

}
