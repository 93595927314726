import { Pipe, PipeTransform } from '@angular/core';
import { differenceInMinutes, differenceInDays } from 'date-fns';
import differenceInHours from 'date-fns/differenceInHours/index';
// import { differenceInDays } from 'date-fns/esm';
@Pipe({
  name: 'duration',
  pure: true
})
export class DurationPipe implements PipeTransform {

  transform(value: any): any {
    const _now = new Date(Date.now()).getTime()
    const _dateValue = new Date(value).getTime()
    const minutesAgo = differenceInMinutes(_now,_dateValue)
    if(minutesAgo < 60){
      return minutesAgo <= 1 ? 'vor einer Minute' : `vor ${minutesAgo} Minuten`;
    } else {
      const hoursAgo = differenceInHours(_now,_dateValue);
      if(hoursAgo < 24){
        return hoursAgo == 1 ? 'vor einer Stunde' :  `vor ${hoursAgo} Stunden`;
      } else {
        const daysAgo = differenceInDays(_now,_dateValue);
        if(daysAgo == 1) {
          return 'vor einem Tag';
        } else if (daysAgo < 50) {
          return `vor ${daysAgo} Tagen`;
        } else {
          return 'vor einem Monat'
        }
        //return daysAgo == 1 ? 'vor einem Tag' : `vor ${daysAgo} Tagen`;
      }
    }


   /*  return this.format(seconds, minutes, hours, days); */
  }
  // private format(seconds, minutes, hours, days) {
  //   (days < 10) ? days = '0' + days : days;
  //   (hours < 10) ? hours = '0' + hours : hours;
  //   (minutes < 10) ? minutes = '0' + minutes : minutes;
  //   (seconds < 10) ? seconds = '0' + seconds : seconds;
  //   return `${days} DAYS . ${hours} : ${minutes} : ${seconds} `;
  // }
}
