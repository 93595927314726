
  <div class="container p_l40">
    <div class="row p_t60 justify-content-between">
      <div class="col-10">
        <h1 class="display-1">Einstellungen</h1>
      </div>
      <div class="col-2 tle" >
        <button mat-flat-button  color='warn' (click)='logout()'>Logout</button>
      </div>
    </div>
  </div>

<!--   <div class="container p_t60 preloader" style="padding-left: 0px!important;" *ngIf='loading'>
    <div class="row justify-content-center">
      <div class="col-auto">
        <mat-spinner color='primary' diameter='30'></mat-spinner>
      </div>
    </div>
  </div>
 -->

  
  <!-- <div class="container">
    <div class="row pl0 pr0">
     <div class="col-auto verySmallGutter">
       <div class="invoiceButton" (click)='nav(ACCOUNT_STATE.MAIN)' [class.invoiceButtonActive]="accountView.currentViewMode == ACCOUNT_STATE.MAIN">
         <div class="btnCopy">Behandlungsschritte</div>
       </div>
     </div>
     <div class="col-auto verySmallGutter">
       <div class="reportButton" (click)='nav(ACCOUNT_STATE.ADDRESS)' [class.reportButtonActive]="accountView.currentViewMode == ACCOUNT_STATE.ADDRESS">
       <div class="btnCopy">Befundungsbericht</div>
         
       </div>
     </div>
     <div class="col-auto verySmallGutter">
       <div class="reportButton" (click)='nav(ACCOUNT_STATE.ACCOUNTING)' [class.reportButtonActive]="accountView.currentViewMode == ACCOUNT_STATE.ACCOUNTING">
         <div class="btnCopy">
           Nachbehandlungsschema
         </div>
       </div>
     </div>
    </div>
   </div> -->

  <!-- <ng-template add-host></ng-template> -->
  <!-- <div class="content-holder-container" #_contentHolder>
    <div add-host></div>
  </div> -->

  <div class="container p_t20 p_b60" *ngIf='account != null && !loading'>
    <mat-card class="p0">
      <div class="container pl0 pr0" class="p_b40">
        <!--  -->
        <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged($event);">
  
          <mat-tab label="Allgemein">
            <div class="container ">
              <div class="row pl10 p_t25">
                <div class="col-6">
                  <h2>Arzt</h2>
                </div>
              </div>
              
              <div class="d-flex">
                <div class="p-2 w30p">
                  <mat-label class='mat-caption'>Titel</mat-label>  
                  <input class="formControl mat-body" [(ngModel)]="account.title" >
                </div>
                <div class="p-2 w40p">
                  <mat-label class='mat-caption'>Facharztbezeichnung</mat-label>  
                  <input class="formControl mat-body" [(ngModel)]="account.medical_specialty_designation" >
                </div>
              </div>
  
              <div class="row pl10 p_t25 ">
                <div class="col-6">
                  <h2>Behandelnde(r) Arzt/Ärztin</h2>
                </div>
              </div> 
  
  
              <div class="d-flex">
                <div class="p-2 w30p">
                  <mat-label class='mat-caption'>Vorname</mat-label>  
                  <input class="formControl mat-body" [(ngModel)]="account.firstName" disabled>
                </div>
                <div class="p-2 w30p">
                  <mat-label class='mat-caption'>Nachname</mat-label>  
                  <input class="formControl mat-body" [(ngModel)]="account.lastName" disabled>
                </div>
              </div>
              <div class="d-flex">
                <div class="p-2 w30p">
                  <mat-label class='mat-caption'>Email</mat-label>  
                  <input class="formControl mat-body" [(ngModel)]="account.email" disabled>
                </div>
                <div class="p-2 w30p">
                  <mat-label class='mat-caption'>Tel.</mat-label>  
                  <input class="formControl mat-body" [(ngModel)]="account.phone" >
                </div>
              </div>
              <div class="d-flex w100  pl10 pr10 p_t30">
                <div class="p-2 ml-auto">
                  <app-df-icon-button title= "Passwort ändern" icon="key" (clicked)="changePasswort()">
                  </app-df-icon-button>  
                </div>
                <div class="p-2">
                  <app-df-icon-button title= "Einstellungen speichern" icon="save" color="primary" (clicked)="save()">
                  </app-df-icon-button>  
                </div>
              </div>
                
            </div>
          </mat-tab>
<!--   
          *ngIf="!ui.isClinicUser" -->
          <!-- *appAccess="['doc']" -->
          <mat-tab label="Praxis/Klinik"  >
            <app-address *ngIf="selectedTabIndex == 1 && !ui.hasTemplateAdminRole"  [account]='account'></app-address>
          </mat-tab>


          <mat-tab label="Abrechnung" *ngIf="showAccountingTAB">
            <account-accounting-tap  *ngIf="selectedTabIndex == 2"  [account]='account' ></account-accounting-tap>
            <!-- (reload)="loadAccounting()" -->
          </mat-tab>

        </mat-tab-group>
      </div>
    </mat-card>
  </div>
<!--   
  [dataSource]="dataSource"
  [accountingPool]="ui.accountingPool" -->

<div class="versionContainer">
  <!-- {{selectedTabIndex}} -->
    {{version}}
</div>
