
<div class="authTable _content" #_content>
  <div class="authTableCell">
    <div class="authCard" >

      <div class="row justify-content-center" style="background-color: #fff; padding-bottom:20px;">
        <div  style="text-align: center;">
          <h3>{{title}}</h3>
        </div>
      </div>
      
      <div class="formGroup" *ngIf='!canRegister'>
        <p style="text-align: center;">{{completeMessage}}</p>
      </div>   

      <div class="formGroup" *ngIf='canRegister'>
        <mat-label>Ihre E-Mail-Adresse</mat-label>
        <input type="email" class="formControl" [(ngModel)]='email' disabled>         
      </div>   
    
      <div class="formGroup" *ngIf='canRegister'>
        <mat-label>Ihr Name</mat-label>
        <input type="text" class="formControl" placeholder="Dr. Max Mustermann" 
        [(ngModel)]='name' required>
      </div>
      
      <div class="formGroup" *ngIf='canRegister'>
        <mat-label>Praxis / Klinik </mat-label>
        <input type="text" class="formControl" placeholder="Name der Praxis/Kinik" 
          [(ngModel)]='companyName' [formControl]="companyFormControl"
          (ngModelChange)="inputChanged($event)" 
          required>
      </div>
      
      <div class="formGroup" *ngIf='canRegister'>
        <mat-label>Neues Passwort</mat-label>
        <input type="password" class="formControl" [(ngModel)]='pw' (ngModelChange)="passwordChanged($event)" 
        [formControl]="passwordCopyFormControl"  required>
      </div>

      <div class="formGroup d-flex flex-column pt0" *ngIf='canRegister'>
        <div class="p-0" style="height: 18px;">
          <div class="d-flex p0">
            <div class="p-0"><mat-icon style="font-size: 16px; margin-top: 2px;" 
              [class.checkOk]='charLengthOk' [class.checkWait]='!charLengthOk' >check_circle_outline</mat-icon></div>
            <div class="p-0 pl10" style="font-size: 12px;">min. 8 Zeichen</div>
          </div>
        </div>
        <div class="p-0" style="height: 18px;">
          <div class="d-flex p0">
            <div class="p-0"><mat-icon style="font-size: 16px; margin-top: 2px;"
              [class.checkOk]='upperCaseCharOK' [class.checkWait]='!upperCaseCharOK' >check_circle_outline</mat-icon></div>
            <div class="p-0 pl10" style="font-size: 12px;">min 1 Großbuchstaben</div>
          </div>
        </div>
        <div class="p-0" style="height: 18px;">
          <div class="d-flex p0">
            <div class="p-0"><mat-icon style="font-size: 16px; margin-top: 2px;"
              [class.checkOk]='specialCharOk' [class.checkWait]='!specialCharOk' >check_circle_outline</mat-icon></div>
            <div class="p-0 pl10" style="font-size: 12px;">1 Sonderzeichen (# $ @ & * ! %)</div>
          </div>
        </div>
      </div>

      
      
      <div class="formGroup" style="padding-top: 10px;" *ngIf='!isLoading && canRegister'>
        <button mat-flat-button color='accent' style="width: 100%; padding-top: 3px; padding-bottom: 3px;"
        (click)="register()" [disabled]='!registerEnabled'
        >Registrieren</button>
      </div>

      <div class="formGroup d-flex justify-content-center" *ngIf='isLoading && canRegister'
      style="padding-top: 10px; width: 100%;" >
        <mat-spinner  color='primary' diameter='25'></mat-spinner>
      </div>
    
    </div>

    <div class="forgotPassword">
      <span (click)='navigate("auth/sign-in")'>Zum Login</span>
    </div>
    

  </div>
</div>

