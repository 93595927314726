import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { isValid, lastDayOfMonth } from 'date-fns';
import {FormControl, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DetectFocusDirective } from 'src/app/directives/detect-focus.directive';

@Component({
  selector: 'app-simple-date-selection',
  templateUrl: './simple-date-selection.component.html',
  styleUrls: ['./simple-date-selection.component.scss']
})
export class SimpleDateSelectionComponent implements OnInit {

  @ViewChild('_dayFocus') _dayFocus:DetectFocusDirective;
  @ViewChild('_monthFocus') _monthFocus:any;
  
  
  @ViewChild('_day') _day:ElementRef;
  @ViewChild('_month') _month:ElementRef;
  @ViewChild('_year') _year:ElementRef;

  dateTime: Date | undefined;/// = new Date();
  // day 
  // month
  // year 
  // day = 1;
  // month = 0;
  // year = 1950;

  // allowd= [0,1,2,3,4,5,6,7,8,9]
  allowdMonths= [1,2,3,4,5,6,7,8,9, 10, 11, 12]
  months= ['1','2','3','4','5','6','7','8','9','0']

  _dayValue:number | undefined;// = 1;
  _monthValue:number | undefined;//  = 1;
  _yearValue:number = 1970;

  dayControl: FormControl
  monthControl: FormControl
  yearControl: FormControl
  /* numbersControl: FormControl = new FormControl('', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]) */

  constructor(
    private dialogRef:MatDialogRef<SimpleDateSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Date) {
      // console.log(data.toDateString());
      // console.log(data.toISOString());
      
      if(data) {
        this.dateTime = new Date(data.toDateString());
        this._dayValue = this.dateTime.getDate()
        this._monthValue = this.dateTime.getMonth() 
        this._yearValue = this.dateTime.getFullYear();
        
        // this.day = this.dateTime.getDate()
        // this.month = this.dateTime.getMonth() + 1
        // this.year = this.dateTime.getFullYear();
        // this._dayValue = this.day
        // this._monthValue = this.dateTime.getMonth()
        // this._yearValue = this.year
      }
   }

  ngOnInit(): void {
    if(this.dateTime) {

      this.dayControl = new FormControl(this.dateTime.getDate(), [Validators.required, Validators.max(31), Validators.min(1)])
      this.monthControl = new FormControl(this.dateTime.getMonth() + 1, [Validators.required, Validators.max(12), Validators.min(1)])
      this.yearControl = new FormControl(this.dateTime.getFullYear(), [Validators.required, Validators.max(2030), Validators.min(1900)])
    } else {
      this.dayControl = new FormControl('', [Validators.required, Validators.max(31), Validators.min(1)])
      this.monthControl = new FormControl('', [Validators.required, Validators.max(12), Validators.min(1)])
      this.yearControl = new FormControl(1970, [Validators.required, Validators.max(2030), Validators.min(1900)])

    }
  }

  onDayInput(e: string){
    console.log(`onDayInput: ${e}`);
  
    let _value = parseInt(e);
    
    if(!isNaN(_value)){
      this._dayValue = _value;
      // console.log('!isNaN');
      setTimeout(()=>{
        this.validateDay();
      },10);
    } else {
      // console.log('isNaN');
      setTimeout(()=>{
        this.dayControl.setValue(this._dayValue ?? '')
      },100);
    }
    // console.log(_value);
    // console.log(_value);
    //  return
    /* if(!isNaN(parseInt(e)) && this.dayControl.valid){
      let _value = parseInt(e)
      this._dayValue = _value;
      setTimeout(()=>{
        this.validateDay()
      },10)
    } */
  }


  onMonthInput(e: any) {
    console.log(`onMonthInput: key: ${e.key} `, e.target.value);

    if(e.key == 'ArrowUp' && this._monthValue) {
      this._monthValue = (this._monthValue + 1) < 13 ? this._monthValue + 1 : 1
      this.monthControl.setValue(this._monthValue)
      this.validateDay();
    } else if(e.key == 'ArrowDown' && this._monthValue) {
      this._monthValue = (this._monthValue - 1) > 0 ? this._monthValue - 1 : 12;
      this.monthControl.setValue(this._monthValue)
      this.validateDay();
    } else {
      let _value = parseInt(e.target.value);
      
      if(!isNaN(_value)){
        this._monthValue = _value;
  
        setTimeout(()=>{
          this.validateDay();
        },10);
        if(e.target.value.length == 2 && this.months.includes(e.key)) {
          setTimeout(() => {
            this._year.nativeElement.focus();
       
          },100);
        }
      } else {
        setTimeout(()=>{
          this.monthControl.setValue(this._monthValue ?? '')
        },100)
      }
    }

  }

  onYearInput(e: any) {
    console.log(e);
    if(e.key == 'ArrowUp') {
      this._yearValue = ((this._yearValue + 1) < (new Date().getFullYear() - 1)) ? this._yearValue + 1 : this._yearValue;
      this.yearControl.setValue(this._yearValue)
    } else if(e.key == 'ArrowDown') {
      this._yearValue = (this._yearValue - 1) > 1900 ? this._yearValue - 1 : this._yearValue;
      this.yearControl.setValue(this._yearValue)
    } else {
      if(!isNaN(parseInt(e.target.value)) && this.dayControl.valid){
          let _value = parseInt(e.target.value);
          this._yearValue = _value;
        }
    }
    this.validateDay();
    // return
    // console.log(`onYearInput: ${e}`);
    // // return
    // if(!isNaN(parseInt(e)) && this.dayControl.valid){
    // // if(parseInt(e.target.value) != NaN){
    //   let _value = parseInt(e);
    //   this._yearValue = _value;
    // }
    // this.validateDay();
  }





  validateDay(){
    if(!this._monthValue || !this._yearValue || !this._dayValue) {
      return
    }
    var _date = new Date();
    _date.setFullYear(this._yearValue, this._monthValue - 1, 1);
    
    let _lastDayOfMonth = lastDayOfMonth(_date).getDate();
    this.dayControl = new FormControl(this._dayValue, [Validators.required, Validators.max(_lastDayOfMonth), Validators.min(1)]);
    // if(isValid(_date)){
    //   //this.dateTime = _date;
    // }
   setTimeout(()=>{
    if(this.dayControl.valid){
      this.validate();
    }
   },10);
  }


  validate(){
    if(this.dayControl.valid){
      var _date = new Date();
    _date.setFullYear(this._yearValue, this._monthValue - 1,  this._dayValue)
    
      if(isValid(_date) && this.dayControl.valid && this.monthControl.valid){
        this.dateTime = _date;
      } else {
        this.dateTime = null
      }
    } else {
      this.dateTime = null
    }
  }


  onDayFocus(){
    console.log(`onDayFocus`);
    this._day.nativeElement.select();
    
  }

  onMonthFocus(){
    console.log(`onMonthFocus ${this._dayValue} valid: ${this.dayControl.valid}`);
    this._month.nativeElement.select();
    if(!this._dayValue) {
      setTimeout(()=>{
        this._day.nativeElement.focus()
      },100)
    }
  }

  onYearFocus(){
    console.log(`onYearFocus`);
    this._year.nativeElement.select();
  }

  // onYearEnter(){

  // }

  close(){
    this.dialogRef.close()
  }

  save(){
    if(this.dayControl.valid && this.monthControl.valid && this.yearControl.valid){
      this.validate();
    }
    setTimeout(()=>{
      // console.log(this.dateTime.toISOString())
      this.dateTime.setHours(0,0,0);
      this.dialogRef.close(this.dateTime);
    },100)
    // console.log(this.month)
    // console.log(this.day)
    // console.log(this.year)
  }

  get dateTimeValid():boolean {
    return this.dayControl.valid && this.monthControl.valid && this.yearControl.valid;
  }

  get dayHasFocus(): boolean {
    return this._dayFocus?.isFocus ?? false;
  }


    
  // dayChanged(e){
  //   // console.log(`Day changed `);
  //   // console.log(e);
    
    
  //   // var _date = new Date();
  //   // _date.setFullYear(this._yearValue, this._monthValue, this._dateValue)

  //   // if(isValid(_date)){
  //   //   //this.dateTime = _date;
  //   // }
  //   // this.validate()
  // }
  // monthChanged(e){
  //   // var _date = new Date();
  //   // _date.setFullYear(this._yearValue, this._monthValue, this._dateValue)
  //   // if(isValid(_date)){
  //   //   this.dateTime = _date;
  //   // }

  //   // let __day = _date.getDate()
  //   // let __month = _date.getMonth()
  //   // if(this._dateValue != __day){
  //   //   console.log("ANDERS __day")
  //   //   this._dateValue = __day;
  //   //   this.day = this._dateValue;
  //   // }
  //   // if(this._monthValue != __month){
  //   //   console.log("ANDERS __month")
  //   //   this._monthValue = __month;
  //   //   this.month = this._monthValue += 1;
  //   // }
  // }
  // yearChanged(e){

  // }
}
