import {Component, Input, OnInit} from '@angular/core';
import { AccountI } from 'src/app/core/models/accountI';
import {SurgeryReportI} from 'src/app/core/models/surgeryReportI';

@Component({
  selector: 'surgery-report-header',
  templateUrl: './surgery-report-header.component.html',
  styleUrls: ['./surgery-report-header.component.scss']
})
export class SurgeryReportHeaderComponent implements OnInit {

  @Input() surgeryReport:SurgeryReportI
  @Input() userAccount:AccountI
  constructor() { }

  ngOnInit(): void {

  }

}
