<mat-card class="mat-card-no-shadow pr0 pt0 pb0 pl0 " style="height:100%!important;">
  <div class="container ">
    <div class="row justify-content-between pl16 pr16 " style="padding-top: 13px!important;">

      <div class="col-12 ">
        <div class="row"><span class="mat-caption">{{title}}</span></div>
      </div>
        <div class="col-12 pl0 pr0 " style="padding-top: 4px!important;">
          <div class="item-text-style" [class.txtDisabled]="!tc.isTemplateEditable" (click)='openDateSelection()' style="height: 34px;">
            {{inputDate | date: 'dd.MM. yyyy'}}
          </div>
      </div>
    </div>
  </div>
</mat-card>
