import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Pipe({
  name: 'accountName'
})
export class AccountNamePipe implements PipeTransform {

  constructor() {}

  transform(value: any): any {
    // return this.db.doc(value).valueChanges()
    return ''
    
  }

}





