<div class="authWrapper" >

    <div class="authCard" #_authCard [class.authCardNewPW]='authService.sign_in_mode == "UPDATE_PASSWORD_REQIERED" '>

      <!-- LOGO -->
      <div class="row justify-content-center" style="background-color: #fff; padding-bottom:40px;">
        <div  style="text-align: center;">
          <img src="/assets/logo_blue.svg" width="80" alt="">
        </div>
      </div>

      <!-- EMAIL & PASSWORD -->
      <div *ngIf='authService.sign_in_mode == "SIGN_IN"'>
      <div class="formGroup" >
        <input class="formControl" type='text'  placeholder="Ihre Emailadresse" 
        [(ngModel)]='email' 
        (ngModelChange)="onEmailChanged($event)"
        #userName [disabled]="loginLoading">
      </div>

      <div class="formGroup">
        <input type="password" class="formControl" placeholder="Ihr Passwort" #userPassword [disabled]="loginLoading"
        (keydown.enter)="trySignIn(userName.value, userPassword.value)">
      </div>

      <!-- SUBMIT -->
      <div class="formGroup">
        <button *ngIf="!loginLoading" mat-flat-button color='primary' style="width: 100%; height:38px;  padding-bottom: 6px;"
        (click)="trySignIn(userName.value, userPassword.value)"
        >{{locales.locales["AUTH"]['BTN_LOGIN']}}</button>
        <div *ngIf="loginLoading" class="loginButtonLoader d-flex justify-content-center align-items-center" style="width: 320px; height: 38px;  border-radius: 6px; border:1px solid #017bdf;">
          <div class="p-0">
            <mat-spinner diameter="25" class="loginSpinner"></mat-spinner>
          </div>
        </div>
      </div>
    </div>


    <div *ngIf='authService.sign_in_mode == "UPDATE_PASSWORD_REQIERED"'>
      <div class="formGroup" >
        <input type="password" class="formControl" placeholder="Neues Password" name="firstNewPassword" id="firstNewPassword"
        [(ngModel)]='firstPassword' (ngModelChange)="passwordChanged($event)"  required>
      </div>

      <div class="formGroup">
        <input type="password" class="formControl" name="secondNewPassword" id="secondNewPassword"
        placeholder="Neues Password wiederholen" [disabled]='!upperCaseCharOK || !specialCharOk || !charLengthOk'
        [(ngModel)]='secondPassword' (ngModelChange)="secondPasswordChanged($event)"  required
        >
        <!-- (keydown.enter)="authService.SignIn(userName.value, userPassword.value)" -->
      </div>


      <div class="formGroup d-flex flex-column pt0" *ngIf='authService.sign_in_mode == "UPDATE_PASSWORD_REQIERED"'>
        <div class="p-0" style="height: 18px;">
          <div class="d-flex p0">
            <div class="p-0"><mat-icon style="font-size: 16px; margin-top: 2px;"
              [class.checkOk]='charLengthOk' [class.checkWait]='!charLengthOk' >check_circle_outline</mat-icon></div>
            <div class="p-0 pl10" style="font-size: 12px;">min. 8 Zeichen</div>
          </div>
        </div>
        <div class="p-0" style="height: 18px;">
          <div class="d-flex p0">
            <div class="p-0"><mat-icon style="font-size: 16px; margin-top: 2px;"
              [class.checkOk]='upperCaseCharOK' [class.checkWait]='!upperCaseCharOK' >check_circle_outline</mat-icon></div>
            <div class="p-0 pl10" style="font-size: 12px;">min 1 Großbuchstaben</div>
          </div>
        </div>
        <div class="p-0" style="height: 18px;">
          <div class="d-flex p0">
            <div class="p-0"><mat-icon style="font-size: 16px; margin-top: 2px;"
              [class.checkOk]='specialCharOk' [class.checkWait]='!specialCharOk' >check_circle_outline</mat-icon></div>
            <div class="p-0 pl10" style="font-size: 12px;">1 Sonderzeichen (# $ @ & * ! %)</div>
          </div>
        </div>
      </div>

      <div class="formGroup">
        <button mat-flat-button color='primary' [disabled]='!saveNewPasswordEnabled' style="width: 100%; height: 38px;  padding-bottom: 6px;"
        (click)="setNewPassword()"
        >Neues Password speichern</button>
      </div>
    </div>


    <div class="container-fluid p0 w100" *ngIf='authService.sign_in_mode == "ADDRESS_MISSING" '>

      <div class="row p0 pl10 pr10" *ngIf="address">

        <div class="d-flex p0 w100">
          <div class="p-2" >
            <h2>Account vervollständigen</h2>
          </div>
        </div>

        <div class="d-flex p0  w100 ">
          <div class="p-2 w50p">
            <mat-label class='mat-caption'>Name der Praxis/Klinik</mat-label>
            <input class="formControl mat-body"
            [class.formControlErrorAddress]="address.klinikName == '' || address.klinikName == null"
            [(ngModel)]="address.klinikName">
          </div>

          <div class="p-2 w50p">
            <mat-label class='mat-caption'>Abteilung</mat-label>
            <input class="formControl mat-body" [(ngModel)]="address.abteilung">
          </div>
        </div>

  

        <div class="d-flex p0  w100">
          <div class="p-2 w50p">
            <mat-label class='mat-caption'>Straße und Hausnummer</mat-label>
            <input class="formControl mat-body"
            [class.formControlErrorAddress]="address.streetName == '' || address.streetName == null"
            [(ngModel)]="address.streetName">
          </div>

          <div class="p-2 w15p">
            <mat-label class='mat-caption'>PLZ</mat-label>
            <input class="formControl mat-body"
            [class.formControlErrorAddress]="address.zip == '' || address.zip == null" [(ngModel)]="address.zip">
          </div>

          <div class="p-2 w35p">
            <mat-label class='mat-caption'>Stadt</mat-label>
            <input class="formControl mat-body"
            [class.formControlErrorAddress]="address.city == '' || address.city == null"  [(ngModel)]="address.city">
          </div>
        </div>

        <div class="d-flex p0 p_t15 w100">
          <div class="p-2" >
            <h2>Bankverbindung</h2>
          </div>
        </div>

        <div class="d-flex p0 w100">
          <div class="p-2 w50p">
            <mat-label class='mat-caption'>Kontoinhaber/in</mat-label>
            <input class="formControl mat-body" [(ngModel)]="address.kontoinhaber">
          </div>

          <div class="p-2 w50p">
            <mat-label class='mat-caption'>USt-IdNr.</mat-label>
            <input class="formControl mat-body" [(ngModel)]="address.ust_nr">
          </div>
        </div>

        <div class="d-flex p0  w100">
          <div class="p-2 w33p">
            <mat-label class='mat-caption'>Bankinstitut</mat-label>
            <input class="formControl mat-body" [(ngModel)]="address.bank_name">
          </div>

          <div class="p-2 w33p">
            <mat-label class='mat-caption'>IBAN</mat-label>
            <input class="formControl mat-body" [(ngModel)]="address.iban">
          </div>

          <div class="p-2 w33p">
            <mat-label class='mat-caption'>BIC</mat-label>
            <input class="formControl mat-body" [(ngModel)]="address.bic">
          </div>
        </div>

      </div>

    <div class="row p0 pl10  pr10">
      <div class="d-flex w100">
        <div class="p-2 ml-auto">
         <app-df-icon-button
          title="Einstellungen speichern" icon="save" color="primary"
          (clicked)="saveAddress()"
          [disabled]="saveDisabled"
          >
          </app-df-icon-button>
        </div>
      </div>
    </div>


    </div>



  </div>



  <div class="forgotPassword" #_forgotPwContainer>
    <span  (click)='navigate("auth/forgot-password")'>{{locales.locales["AUTH"]['BTN_FORGOT_PASSWORD']}}</span>
  </div>
  
</div>


