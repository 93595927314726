<mat-toolbar style="height: 52px; border-radius: 6px; background-color: #ffffff!important;">
  <p>Password ändern</p>
  <span class="toolbar-spacer"></span>
  <mat-icon style="color:#ABBBC7; cursor: pointer;   -webkit-user-select:none;" (click)='close()'>close</mat-icon>
</mat-toolbar>
<div class="container">
  <form autocomplete="off">

  <div class="formGroup" >
    <input autocompleteOff type="password" class="formControl" placeholder="Neues Password" 
    [value]='firstPassword' (input)="passwordChanged($event)"  required>
  </div>

  <div class="formGroup">
    <input type="password" class="formControl" 
    placeholder="Neues Password wiederholen" [disabled]='!upperCaseCharOK || !specialCharOk || !charLengthOk'
    [value]='secondPassword' (input)="secondPasswordChanged($event)" autocompleteOff  required
    >
    <!-- (keydown.enter)="authService.SignIn(userName.value, userPassword.value)" -->
  </div>

  <div class="formGroup d-flex flex-column pt0" >
    <div class="p-0" style="height: 18px;">
      <div class="d-flex p0">
        <div class="p-0"><mat-icon style="font-size: 16px; margin-top: 2px;" 
          [class.checkOk]='charLengthOk' [class.checkWait]='!charLengthOk' >check_circle_outline</mat-icon></div>
        <div class="p-0 pl10" style="font-size: 12px;">min. 8 Zeichen</div>
      </div>
    </div>
    <div class="p-0" style="height: 18px;">
      <div class="d-flex p0">
        <div class="p-0"><mat-icon style="font-size: 16px; margin-top: 2px;"
          [class.checkOk]='upperCaseCharOK' [class.checkWait]='!upperCaseCharOK' >check_circle_outline</mat-icon></div>
        <div class="p-0 pl10" style="font-size: 12px;">min 1 Großbuchstaben</div>
      </div>
    </div>
    <div class="p-0" style="height: 18px;">
      <div class="d-flex p0">
        <div class="p-0"><mat-icon style="font-size: 16px; margin-top: 2px;"
          [class.checkOk]='specialCharOk' [class.checkWait]='!specialCharOk' >check_circle_outline</mat-icon></div>
        <div class="p-0 pl10" style="font-size: 12px;">1 Sonderzeichen (# $ @ & * ! %)</div>
      </div>
    </div>
  </div>
  
  <!-- <div class="formGroup">
    <button mat-flat-button color='primary' [disabled]='!saveNewPasswordEnabled' style="width: 100%; padding-top: 6px; padding-bottom: 6px;"
    (click)="setNewPassword()"
    >Neues Password speichern</button>
  </div> -->
</form>
</div>
<mat-toolbar style="height: 52px; width: 100%; border-radius: 6px;" class="tlc justify-content-center">
  <div style="width:197px; text-align:center;" class="tlc justify-content-center" >
  <button mat-flat-button color='primary' (click)='setNewPassword()' [disabled]='!saveNewPasswordEnabled'>Neues Password speichern</button>
  </div>
</mat-toolbar>