<div class="container-fluid template-inner-wrapper">
    <div class='row d-flex p_t10 pl0 pr0 justify-content-between'>
      <div class='p-2 d-flex align-items-center mr-auto'>
        <div class="behandlungsschritte-icon pl0"></div>
        <div class="behandlungsschritte mat-title">Behandlungsabschnitte</div>
      </div>
  
         <!-- SHOW INVOICE PREVIEW -->
      <div class="p-2 tle" *ngIf='showInvoiecePreviewButton'>
        <button mat-button color="basic" class="m_b15" (click)="invoicePreview()">
          <mat-icon style="font-size: 16px;  margin-right: 4px; color: #7C8B96;" class="pl0 ml0" inline=true>
            picture_as_pdf</mat-icon>
          <span style="color: #7C8B96;" inline=true>Vorschau Rechnung</span>
        </button>
      </div>
  
      
      <!-- SHARE TO ACCOUNTING -->
      <div class="p-2 pl0 tle" *ngIf="showShareWithAccountingButton">
        <button mat-flat-button color="primary" class="m_b15" (click)="shareWithAccounting()">
          <mat-icon style="font-size: 14px;  margin-right: 4px;" class="pl0 ml0" inline=true>ios_share</mat-icon>
          <span inline=true>{{locales.locales["T_BH_BUTTONS"]['BTN_SHARE_WITH_ACCOUNTING']}}</span>
        </button>
      </div>
      
      <!-- SHARE BACK TO DOC -->
      <div class="p-2 pl0 tle" *ngIf="showShareWithBackToDocButton">
        <button mat-flat-button color="primary" class="m_b15" (click)="backToDoc()">
          <mat-icon style="font-size: 14px;  margin-right: 4px;" class="pl0 ml0" inline=true>ios_share</mat-icon>
          <span inline=true>{{locales.locales["T_BH_BUTTONS"]['BTN_SHARE_WITH_DOC']}}</span>
        </button>
      </div>
      
      <!-- CREATE FINAL INVOICE -->
     <div class="p-2 tle" *ngIf="showCreateFinalInvoiceButton">
       <button mat-flat-button color="primary" class="m_b15" (click)="finalInvoice()" >
         <mat-icon style="font-size: 14px;  margin-right: 4px;" class="pl0 ml0" inline=true>lock</mat-icon>
         <span inline=true>Fertigstellen</span>
       </button>
     </div>
  
     <!-- UNLOCK INVOICE -->
     <div class="p-2 pr0" *ngIf="showInvoieceUnlockButton">
       <mat-icon class="pl0 ml0 pr0 unlockButton" (click)='unlockInvoice()'>lock_open</mat-icon>
     </div>
  
      <!-- SHOW FINAL INVOICE -->
     <div class="p-2 pl0 tle" *ngIf="tc.templateHasInvoice">
       <button mat-flat-button color="primary" class="m_b15" (click)="showInvoice()">
         <mat-icon style="font-size: 14px;  margin-right: 4px;" class="pl0 ml0" inline=true>picture_as_pdf</mat-icon>
         <span inline=true>Rechnung ansehen</span>
       </button>
     </div>
      
     <div class="p-2 pl0 tle" *ngIf="ui.hasBillingCompanies">
      <button mat-flat-button color="primary" class="m_b15" (click)="openTestBillingCompanyStuff()">
        <mat-icon style="font-size: 14px;  margin-right: 4px;" class="pl0 ml0" inline=true>ios_share</mat-icon>
        <span inline=true>PADneXt export erstellen</span>
      </button>
    </div>
    </div>
  </div>
  
  <div class="container-fluid template-inner-wrapper" >
   
    <div class='row d-flex pl0 pr0'>
      <div class='col-12 pl8 pr8'>
        <div *ngIf="tc.currentTemplateType === 'DOXFOX'" style="width: 100%;" #_dragListContainer>
          <mat-accordion [togglePosition]="'before'" cdkDropList class="drag-list " displayMode="flat" multi
            (cdkDropListDropped)="drop($event)">
            <div *ngFor="let section of sections" class="drag-box" cdkDrag [class.move-cursor]='tc.isTemplateEditable && tc.canRenameTemplate' [cdkDragDisabled]='!tc.isTemplateEditable || !tc.canRenameTemplate'>
              <div class="custom-section-placeholder" *cdkDragPlaceholder></div>
              <template-section-item 
                (sectionDateChanged)='sectionDateChanged($event)' [section]='section' 
                style="width: 100%;">
              </template-section-item>
  
              <div class="example-handle" cdkDragHandle>
                <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </div>
            </div>
          </mat-accordion>
        </div>
        <div *ngIf="tc.currentTemplateType === 'SCS'" style="width: 100%;" #_dragListContainer>
          <mat-accordion [togglePosition]="'before'" displayMode="flat" >
            <div *ngFor="let section of tc.currentSections">
              <template-section-scs-item [isInProcesses]='true' [isPublicTemplate]='editable'
                [section]='section' [templateDocumentId]='template.documentId' style="width: 100%;">
              </template-section-scs-item>
            </div>
          </mat-accordion>
  
  
        </div>
      </div>
    </div>
  





    <div class="row d-flex p_t20 p_b20"  #button>
      
      <div class="p-2 mr-auto pl8">
        <button *ngIf="!tc.templateHasInvoice && template.isEditable" mat-raised-button (click)='addAbschnitt()' [disabled]='!tc.canRenameTemplate'>Neuer Behandlungsabschnitt</button>
      </div> 
  
   
      <!-- SHOW INVOICE PREVIEW -->
      <div class="p-2 tle" *ngIf='showInvoiecePreviewButton'>
      <button mat-button color="basic" class="m_b15" (click)="invoicePreview()">
        <mat-icon style="font-size: 16px;  margin-right: 4px; color: #7C8B96;" class="pl0 ml0" inline=true>
          picture_as_pdf</mat-icon>
        <span style="color: #7C8B96;" inline=true>Vorschau Rechnung</span>
      </button>
    </div>
  
  
      <!-- SHARE TO ACCOUNTING -->
      <div class="p-2 pl0 tle" *ngIf="showShareWithAccountingButton">
        <button mat-flat-button color="primary" class="m_b15" (click)="shareWithAccounting()">
          <mat-icon style="font-size: 14px;  margin-right: 4px;" class="pl0 ml0" inline=true>ios_share</mat-icon>
          <span inline=true>{{locales.locales["T_BH_BUTTONS"]['BTN_SHARE_WITH_ACCOUNTING']}}</span>
        </button>
      </div>
  
      <!-- SHARE BACK TO DOC -->
      <div class="p-2 pl0 tle" *ngIf="showShareWithBackToDocButton">
        <button mat-flat-button color="primary" class="m_b15" (click)="backToDoc()">
          <mat-icon style="font-size: 14px;  margin-right: 4px;" class="pl0 ml0" inline=true>ios_share</mat-icon>
          <span inline=true>{{locales.locales["T_BH_BUTTONS"]['BTN_SHARE_WITH_DOC']}}</span>
        </button>
      </div>
  
      <!-- CREATE FINAL INVOICE -->
      <div class="p-2 tle" *ngIf="showCreateFinalInvoiceButton">
      <button mat-flat-button color="primary" class="m_b15" (click)="finalInvoice()" >
        <mat-icon style="font-size: 14px;  margin-right: 4px;" class="pl0 ml0" inline=true>lock</mat-icon>
        <span inline=true>Fertigstellen</span>
      </button>
      </div>
  
      <!-- UNLOCK INVOICE -->
      <div class="p-2 pr0" *ngIf="showInvoieceUnlockButton">
      <mat-icon class="pl0 ml0 pr0 unlockButton" (click)='unlockInvoice()'>lock_open</mat-icon>
      </div>
  
      <!-- SHOW FINAL INVOICE -->
      <div class="p-2 pl0 tle" *ngIf="tc.templateHasInvoice">
      <button mat-flat-button color="primary" class="m_b15" (click)="showInvoice()">
        <mat-icon style="font-size: 14px;  margin-right: 4px;" class="pl0 ml0" inline=true>picture_as_pdf</mat-icon>
        <span inline=true>Rechnung ansehen</span>
      </button>
      </div>
  
  
  </div>
  
