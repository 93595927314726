<div class="d-flex align-items-center p_r20 " *ngIf='reportItems != null && !isLoading' >
  
  <div class="p-0 p_t2" *ngIf="reportItems.length > 1">
    <mat-icon class='justification_arrow_icon'
    *ngIf="tc.isTemplateEditable"
    [class.justification_arrow_icon_green_disabled]="reportItems.length == 0 || activeIndex == 0 && tc.templateHasSurgeryReport"
    (click)='openOverlay(FOCUS_ELMENTS.PREV_REPORT)'>keyboard_arrow_left</mat-icon>
  </div> 

  <div class="p-0" style="font-size:14px; font-weight:300;" *ngIf="reportItems.length > 1">
    <span style="color: #1976D2; font-weight:500;" *ngIf="reportItems.length == 0">0 </span>
    <span style="color: #1976D2; font-weight:500;" *ngIf="reportItems.length > 0">{{activeIndex + 1}}</span>
    <span> von {{reportItems.length}}</span>
  </div>

  <div class="p-0 p_t2" *ngIf="reportItems.length > 1">
    <mat-icon class='justification_arrow_icon'
    *ngIf="tc.isTemplateEditable"
    [class.justification_arrow_icon_green_disabled]="reportItems.length == 0 && tc.templateHasSurgeryReport"
    (click)='openOverlay(FOCUS_ELMENTS.NEXT_REPORT)'>keyboard_arrow_right</mat-icon>
  </div>

  <div class="p-0 p_t2 mt4" *ngIf="reportItems.length > 0">
    <mat-icon (click)='openOverlay(FOCUS_ELMENTS.ADD_REPORT)' class='justification_arrow_icon_mini' *ngIf="tc.isTemplateEditable"
    matTooltipClass='sectionGoaeTT' matTooltip='Position hinzufügen' matTooltipPosition='above'>add</mat-icon>
  </div>

  <div class="p-0 p_t2 mt4">
    <mat-icon  *ngIf="tc.isTemplateEditable && reportItems.length > 0" (click)='openOverlay(FOCUS_ELMENTS.REMOVE_REPORT)'
     class='justification_arrow_icon_mini'
    [class.justification_arrow_icon_green_disabled]="reportItems.length == 0"
    matTooltipClass='sectionGoaeTT' matTooltip='Position entfernen' matTooltipPosition='above'>delete</mat-icon>
  </div>

  <div class="p-2 pr20 flex-fill" *ngIf="reportItems.length > 1" >
    <div class="d-flex">
      <div class="p-0 flex-fill">
        <div class="item-text-style fs14-400"  [class.itemDisabled]="!tc.isTemplateEditable"
        [innerHTML]="getReportionCopyDescription" (click)="openOverlay(FOCUS_ELMENTS.SURGERY_ITEM_COPY)">
        </div>
        <!-- <mat-form-field appearance="outline" class="textfield100 select-form-field" *ngIf="!isTitleEditMode">
          <mat-select (selectionChange)='changed($event)' [(value)]="selectedReportItem" [disabled]="!tc.isTemplateEditable">
            <mat-option *ngFor="let reportItem of reportItems" [value]="reportItem">
              {{reportItem.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <input class="formControl mat-body formControlDescription fs14-400" placeholder="Beschreibung" [disabled]='!editable' maxlength="250"
        style="background-color:rgba(0,0,0,0); " 
        #_titleTextField
        (keydown.enter)="onTextFieldTitleEnter()"
        (change)="reportDescriptionInputChanged()"
        (blur)="reportDescriptionInputBlur($event)"
        [value]='getReportionCopyDescription'
        *ngIf="isTitleEditMode"
        > -->
      </div>
      <div class="p-0 p_t8 pl8" >
        <mat-icon  *ngIf="!isTitleEditMode" (click)='openOverlay(FOCUS_ELMENTS.SURGERY_ITEM_DESCRIPTION)'
          class='justification_arrow_icon_mini'
         [class.justification_arrow_icon_green_disabled]="reportItems.length == 0">edit</mat-icon>
      </div>
    </div>
    
  </div>



</div>


<div class="d-flex p_t0 p_b5 pr20 h100" *ngIf='reportItems != null && !isLoading '>
  
  
  <div class="p-0 flex-fill" >
    <div class="item-text-style fs14-400" style="min-height: 180px;" [class.itemDisabled]="!tc.isTemplateEditable"
    [innerHTML]="getReportionCopy" (click)="openOverlay(FOCUS_ELMENTS.SURGERY_ITEM_COPY)">
    </div>
  </div>

  <!-- <div class="p-0 flex-fill" *ngIf="resizableTextarea" style="height: calc(100% - 120px);">
    <textarea style="width:100%; height: 100%; background-color:rgba(0,0,0,0);  resize: vertical !important;"  maxlength="15000"
      rows="4" cols='2' class="mat-body formControl fs14-400"
      #_textArea
      (change)="reportCopyAreaChanged()"
      (resize)="resizeEvent($event)"
      (keydown)="onTextAreaKeyDown($event)"
      (blur)="reportCopyAreaBlur($event)"
      [value]="getReportionCopy" [disabled]='!editable'  >
    </textarea>
  </div> -->

</div>

<div class="d-flex justify-content-center p_b5 pr20" style="min-height:150px" *ngIf='reportItems != null && isLoading'>
  <div class="p-2" *ngIf="isLoading">
    <mat-spinner diameter="25"></mat-spinner>
  </div>

</div>
