import { Injectable } from '@angular/core';
import { IAuthToken } from '../core/models/auth.token';
const TOKEN_KEY = 'auth-token-obj';
const UID= 'uid';
@Injectable({
  providedIn: 'root'
})
export class TokenstorageService {

  constructor() { }
  signOut(): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(UID);
    window.sessionStorage.clear();
  }

  public saveToken(tItem:IAuthToken): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, JSON.stringify(tItem));
  }

  public getToken(): IAuthToken {
    const _oldToken = JSON.parse(sessionStorage.getItem("auth-token"))
    if(_oldToken){
      window.sessionStorage.removeItem("auth-token");
    }
    return JSON.parse(sessionStorage.getItem(TOKEN_KEY)) as IAuthToken;
  }

  public getUID(): string {

    return sessionStorage.getItem(UID);
  }
  public saveUID(uid:string): void {
    window.sessionStorage.removeItem(UID);
    window.sessionStorage.setItem(UID, uid);
  }
  // public setUid(uid:string){
  //   window.sessionStorage
  // }
}
