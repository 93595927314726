import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import {FormControl} from '@angular/forms';
import { GOA_SMALL } from 'src/app/core/models/goaeM';
import { GoaServicesService } from 'src/app/services/goa-services.service';
import {  JustificationItemI, PositionsHelper } from 'src/app/core/models/sectionPositionM';
import { ControllerService } from 'src/app/shared/controller.service';
import {AlertActionI } from 'src/app/core/models/alertM';
import { SectionM } from 'src/app/core/models/sectionM';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { AlertOverlayComponent } from 'src/app/ui/overlays/alert-overlay/alert-overlay.component';
import { AlertService } from 'src/app/services/alert.service';
import { TextEditI } from 'src/app/core/models/textOverlayEditI';
import { TexteditOverlayComponent } from 'src/app/ui/overlays/textedit-overlay/textedit-overlay.component';
import { GoaeAusschlussI } from 'src/app/core/models/goaeAusschlussI';
import { AddCustomPositionSelectI } from 'src/app/core/models/addCustomPositionSelectI';
import { SelectPositionCustomItemComponent } from 'src/app/ui/overlays/select-position-custom-item/select-position-custom-item.component';
import { BilligItemI } from 'src/app/core/models/billing_itemI';


declare var TweenMax: any;

@Component({
  selector: 'template-position-scs-item',
  templateUrl: './template-position-scs-item.component.html',
  // styleUrls: ['../positions.scss'],
})
export class TemplatePositionScsItemComponent implements OnInit {
  @Input() templateDocumentId: string;
  @Input() isInProcesses:boolean
  @Input() isPublicTemplate:boolean
  @Input() section:SectionM;

  @ViewChild('_goaTF') _goaTF:ElementRef;

  @Output() deletePositonAtIndex  = new EventEmitter();


  myControl = new FormControl();

  filteredOptions;//: GOA_SMALL[] = [];
  goae_list_small:GOA_SMALL[] = [];
  summery:number = 0;
  resizeTimeout: any;
  splitTimeOut:any = null
  deletePositonSubscription:any
  // indentedItems:Array<boolean> = [];
  constructor(private goaS:GoaServicesService, private dialog:MatDialog, private alertService:AlertService) { }

  errorMessage = 'null';

  ngOnInit() {
/*     setTimeout(()=>{
      this.getGoae();
    },600) */
  }

  displayFn(item: GOA_SMALL): string {
    return item && item.name ? item.name : '';
  }

//   justifications(p:PositionM) : JustificationItemI {
//     if(p.justification_items != null  ){
//       for(var j = 0; j < p.justification_items.length; j++){
//          if(p.justification_items[j].progression_factor == p.progression_factor){
//           return p.justification_items[j]
//          }
//       }
//     } else {
//       return null;
//     }
//     return null;
//   }
//   getJustificationCopy(p:PositionM):string {
//     let _justification:JustificationItemI = p.justification_items.find((item)=> item.progression_factor == p.progression_factor);
//     if(_justification != null){
//       for (const text_item of _justification.text_items) {
//         if(text_item.selected){
//           return text_item.text;
//         }
//       }
//       return _justification.text_items[0].text;
//     } else {
//       return "";
//     }
//   }

//   getSelectedJustificationIndex(p:PositionM) : number {
//     var _selectedIndex = 0;
//     let _justification:JustificationItemI = p.justification_items.find((item)=> item.progression_factor == p.progression_factor);

//     if(_justification != null){
//       let index = _justification.text_items.findIndex((item)=>item.selected);
//       return index == -1 ? _selectedIndex : index;
//     }
//     return _selectedIndex;
//   }
//   activateJustificationIndex(p:PositionM, index:number){
//     let _justification:JustificationItemI = p.justification_items.find((item)=> item.progression_factor == p.progression_factor);
//     if(_justification != null){
//       if(!_justification.text_items[index].selected){
//         for(var i=0; i<_justification.text_items.length; i++){
//           _justification.text_items[i].selected = (i != index) ? false : true;
//         }
//       }

//     }
//   }
//   prevJusitification(p:PositionM){
//     if(this.section.templateHasInvoice || this.isPublicTemplate){
//       return
//     }
//     var _selectedIndex = 0;
//     let _justification:JustificationItemI = p.justification_items.find((item)=> item.progression_factor == p.progression_factor);
//     _selectedIndex = _justification.text_items.findIndex((item)=>item.selected);
//     if(_selectedIndex > 0){
//       _selectedIndex --;
//     }
//     for (var i = 0; i < p.justification_items.length; i++) {
//       if(p.justification_items[i].progression_factor == p.progression_factor){
//         for(var j= 0; j<p.justification_items[i].text_items.length;j++){
//           p.justification_items[i].text_items[j].selected = j == _selectedIndex ? true : false;
//         }
//       }
//     }
//   }
//   nextJusitification(p:PositionM){
//     if(this.section.templateHasInvoice || this.isPublicTemplate){
//       return
//     }
//     var _selectedIndex = 0;
//     let _justification:JustificationItemI = p.justification_items.find((item)=> item.progression_factor == p.progression_factor);
//     _selectedIndex = _justification.text_items.findIndex((item)=>item.selected);
//     if(_selectedIndex < _justification.text_items.length){
//       _selectedIndex ++;
//     }
//     for (var i = 0; i < p.justification_items.length; i++) {
//       if(p.justification_items[i].progression_factor == p.progression_factor){
//         for(var j= 0; j<p.justification_items[i].text_items.length;j++){
//           p.justification_items[i].text_items[j].selected = j == _selectedIndex ? true : false;
//         }
//       }
//     }
//   }
//   openJustificationOverlay(position:PositionM, index:number = 0){
//     // if(this.section.templateHasInvoice){
//     //   return
//     // }
//     // let m:JustificationEditI = {
//     //   positionModel:position,
//     //   selectedIndex:index
//     // }
//     // this.dialog.open(PositionJusificationComponent,{
//     //   width:'450px',
//     //   // height:'450px',
//     //   data: m
//     // }).afterClosed().subscribe(
//     //   _items => {
//     //     if(_items != null && _items != undefined){
//     //       let i = this.section.positions.indexOf(position);
//     //       if( i > -1){
//     //         if(this.section.positions[i].justification_items != null) {
//     //           var k = this.section.positions[i].justification_items.find((item)=> item.progression_factor == position.progression_factor);
//     //           if(k != null){
//     //             k.text_items = _items.text_items;
//     //           } else {
//     //             this.section.positions[i].justification_items.push(_items)
//     //           }
//     //         } else {
//     //           this.section.positions[i].justification_items = [_items];
//     //         }
//     //       }
//     //       this.save();
//     //     }
//     //   }
//     // )
//   }
//   getSelectedIndex(p:PositionM):number {
//     if(p.justification_items != null  ){
//       for(var j = 0; j < p.justification_items.length; j++){
//          if(p.justification_items[j].progression_factor == p.progression_factor){
//           return p.justification_items[j].text_items == null ? 0 : p.justification_items[j].text_items.findIndex((item)=> item.selected)
//          }
//       }
//     } else {
//       return 0;
//     }
//     return 0;
//   }

//   getErrorTooltip(p:PositionM):string {
//     return (p.errorModel != null && p.errorModel.errorMessage != null) ? p.errorModel.errorMessage : "ERROR"
//   }

//   private _disableItem(atIndex:number){
//     if(this.section.positions[atIndex].activated){
//       this.section.positions[atIndex].activated = false;

//     }
//   }

//   activateSugeryRelevantTapped(p:PositionM, index:number){
//     this.save();
//   }

//   drawPosition(p:PositionM):boolean {
//     return p.templateHasInvoice && !p.activated ? false : true;
//   }
//   itemDisabled(p:PositionM):boolean {
//     // return this.section.templateHasInvoice || this.isPublicTemplate ?  true : false;
//     if(p == null) return true;

//     if(this.section.templateHasInvoice ){
//       return true
//     }

//     if(this.isPublicTemplate){
//       return true;
//     }


//     return  false;
//   }
//   get addPositionDisabled():boolean {
//     return this.isPublicTemplate
//   }
//   itemNeedJustification(p:PositionM):boolean {
//     if(p.goa_paragraph == null || p.isOwnBillingItem){
//       return false;
//     }
//     if(p.progression_factor > Number(p.goa_paragraph.progression_factor) || p.progression_factor > 2.3) {
//       return true;
//     }
//     return  false;
//   }

//   async checkAusschlussForId(p:PositionM){
//     // var _errorText = ''
//     // var _items:GoaeAusschlussI[] = []
//     // _items = await this.getAusschluss("GOA."+p.goa_paragraph.goa_id).then((data)=>{
//     //   return data;
//     // });

//     // if(_items != null && _items.length > 0){
//     //   let _item:GoaeAusschlussI = _items.find((goe)=> goe.U == "3" || goe.U == "4");
//     //   if(_item != null && _item.regel.ausschluss_liste != null && _item.regel.type != null){
//     //     if(_item.regel.type == 'only_allowed_with'){

//     //       _errorText = "Darf nur allein oder zusammen mit einer der folgenden Ziffern abgerechnet werden: " ;
//     //       // this.errorMessage = "Darf nur allein oder zusammen mit einer der folgenden Ziffern abgerechnet werden: " ;
//     //       for (const  textItem of _item.regel.ausschluss_liste) {
//     //         //this.errorMessage += textItem + ", "
//     //         _errorText += textItem + ", "
//     //       }

//     //       _item.errorMessage =  _errorText;
//     //       p.errorModel = _item;
//     //       p.hasError = true;
//     //     } else if(_item.regel.type == 'exclusion'){
//     //       for (var i=0; i<this.section.positions.length; i++) {
//     //         if(this.section.positions[i].activated && !this.section.positions[i].indented) {
//     //           let _errorID = _item.regel.ausschluss_liste.find((goaeId)=>goaeId == this.section.positions[i].goa_paragraph.goa_id);
//     //           if(_errorID) {
//     //             _errorText = "Kann nicht zusammen mit Ziffer " + this.section.positions[i].goa_paragraph.goa_id  + " abgerechnet werden"
//     //             _item.errorMessage =  _errorText;
//     //             p.errorModel = _item;
//     //             p.hasError = true;
//     //             //p.hasError = true;
//     //             //p.errorModel = _item;
//     //             //this.errorMessage = "Kann nicht zusammen mit Ziffer " + this.section.positions[i].goa_paragraph.goa_id  + " abgerechnet werden"
//     //           }
//     //         }
//     //       }
//     //     }
//     //   }
//     // }
//   }

//   activateToggleTapped(p:PositionM, index:number){


//     let _isSelected:boolean = p.activated;
//     if(!_isSelected){
//       if(p.hasError){
//         this.errorMessage = 'null'
//         p.hasError = false;
//       }
//       this.save();
//       return;
//     }
//     //this.checkAusschlussForId(p);

//     this.save();

//   }

//   openAlert(){
//     //ControllerService.alertMessageEmitter.emit({title:"Einrücken nicht möglich",bodyMessage:"Die erste Maßnahme in der Liste kann nicht eingerückt werden."})
//   }

// /*   secletedChanged(event){

//     this.addNewPosition(event.option.value);
//   } */

//   save(){
//     if(this.section.templateHasInvoice){
//       return
//     }
//     if (this.resizeTimeout) {
//       clearTimeout(this.resizeTimeout);
//     }
//     if(this.isPublicTemplate){
//       // ControllerService.showIsPublicTemplateAlertEmitter.emit(this.templateDocumentId)
//       this.alertService.templateItemPositonWantEditTemplate(this.templateDocumentId)
//       // this.alertService.clearWantEditPublicTemplate();

//     } else {
//       this.resizeTimeout = setTimeout((() => {
//         ControllerService.saveTemplate.emit(this.templateDocumentId)
//       }).bind(this), 500);
//     }


//   }


//   drop(event: CdkDragDrop<PositionM[]>) {
//     moveItemInArray(this.section.positions, event.previousIndex, event.currentIndex);
//     this.save();
//   }
//   deletePosition(pPosition:PositionM){
//     if(this.isPublicTemplate){
//       this.alertService.templateItemPositonWantEditTemplate(this.templateDocumentId)
//       return
//     }
//     const dialogReg = this.dialog.open(AlertOverlayComponent, {
//       width:"400px",
//       disableClose:true,
//       data: {
//         title:"Position löschen",
//         bodyMessage:"Sind Sie sicher, dass Sie diese Position löschen möchten? "
//           +"Diese Aktion kann nicht rückgängig gemacht werden.",
//           actions: [
//             {
//               title:'Löschen', color: AlertActionI.BUTTON_COLOR_TYPE.WARN, actionType:AlertActionI.BUTTON_ACTION_TYPE.RESUME
//             },
//             {
//               title:'Abbrechen', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType:AlertActionI.BUTTON_ACTION_TYPE.CANCEL
//             }
//           ]

//         },
//       backdropClass:"backdrop-background"
//     }).afterClosed().subscribe((result) => {

//       if(result != null){

//         if(result === AlertActionI.BUTTON_ACTION_TYPE.RESUME){
//           this._finalDeletePosition(pPosition)
//           this.deletePositonSubscription = null
//         } else {
//           this.deletePositonSubscription = null
//         }
//       }

//     });

//   }
//   _openAlert(a){
//     const dialogReg = this.dialog.open(AlertOverlayComponent, {
//       disableClose:true,
//       data: {
//         title:"Position löschen",
//         bodyMessage:"Sind Sie sicher, dass Sie diese Position löschen möchten? "
//           +"Diese Aktion kann nicht rückgängig gemacht werden.",
//           actions: [
//             {
//               title:'Löschen', color: AlertActionI.BUTTON_COLOR_TYPE.WARN, actionType:AlertActionI.BUTTON_ACTION_TYPE.RESUME
//             },
//             {
//               title:'Abbrechen', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType:AlertActionI.BUTTON_ACTION_TYPE.CANCEL
//             }
//           ]

//         },
//       backdropClass:"backdrop-background"
//     }).afterClosed().subscribe((result:AlertActionI) => {

//       if(result != null){

//       }

//     });
//   }
//   // getAusschluss = async (goaDocId:string) => {

//   //   return await this.templateS.getGoaAusschluss(goaDocId).then(
//   //     _data => {
//   //       return _data.docs.map(action => {

//   //           const model = action.data();
//   //           model['documentId'] = action.id;
//   //           return model;
//   //       })
//   //     }
//   //   )
//   // }
//   _finalDeletePosition(pPosition:PositionM){
//     const index = this.section.positions.indexOf(pPosition , 0)
//     this.deletePositonAtIndex.emit(index);

//     //this.section.positions.splice(index,1);
//     //this.save();
//   }

//   /* addNewPosition = async (goae:GOA_SMALL) => {
//     if(this.section.templateHasInvoice){
//       return
//     }
//     const shot = await this.goaS.getfullItem(goae.documentId)
//     shot.pipe(take(1)).subscribe(
//       s => {
//         let _gnr:Gnr = s.data() as Gnr;
//         // console.log(_gnr)
//         let p:PositionM = {
//           documentId:'',
//           invoice_section_id:0,
//           // id:0,
//           sectionDocumentId:'',
//           index:99,
//           activated:true,
//           justification:'',
//           example:'',
//           surgery_relevant:false,
//           hasOrModeTrenner:false,
//           quantity:1,
//           progression_factor:2.3,
//           or_mode:false,
//           indented:false,
//           starts_new_group:false,
//           goa_paragraph: {
//             goa_id:_gnr.V,
//             long_description:_gnr.allgemein.legende.langtext,
//             amount_euro: Number(_gnr.allgemein.bewertung_liste.bewertung_liste[1].bewertung.V),
//             progression_factor:Number(_gnr.progression_factor)
//           }
//         }
//        this.addItem(p)
//       }

//     )


//   } */
//  /*  addItem(p:PositionM){

//     this.section.positions.push(p)
//     this.applyFilter('')
//     this._goaTF.nativeElement.value = ''
//     this.save();
//   } */
// /*   getGoae = async () => {
//     //this.goae_list_small = JSON.parse(localStorage.getItem('goae'))['items'];
//     this.filteredOptions = new MatTableDataSource<GOA_SMALL>(this.templateS.goae_list_small);

//    } */

//  /*  applyFilter(filterValue: string) {
//     this.filteredOptions.filter = filterValue.trim().toLowerCase();
//   }  */
//   getGoaText(p:PositionM):string{
//     if(p.isOwnBillingItem){
//       return p.ownBillingItem.long_description
//     } else if(p.goa_paragraph == null){
//       return ''
//     }
//     let item =  this.goae_list_small.find((item)=>item.goa_id == p.goa_paragraph.goa_id)
//     if(item != null && item.long != null){

//       if(p.goa_paragraph.long_description != item.long){
//         p.goa_paragraph.long_description = item.long

//       }
//     }
//     return p.goa_paragraph.long_description
//   }
//   getSummery(position:PositionM) : string {
//     if(position.ownBillingItem != null || position.isOwnBillingItem === true || position.goa_paragraph == null){
//       return '€ 0.00'
//     }
//     return ((position.progression_factor * position.goa_paragraph.amount_euro) * position.quantity).toFixed(2).toString();
//   }

//   editMeasure(pPosition:PositionM){
//     if(pPosition.templateHasInvoice || this.isPublicTemplate){
//       return
//     }
//     let d:TextEditI= {'singleline':false, 'text':pPosition.example, "title":'Maßnahme'}
//     this.dialog.open(TexteditOverlayComponent,{
//       width:"450px",
//       // height:"400px",
//       data:d,
//       backdropClass:"backdrop-background",

//     }).afterClosed().subscribe(
//       _newText => {
//         if(_newText != null){
//           pPosition.example = _newText
//           this.save();
//         }
//       }
//     )
//   }
//   getCheckboxColor(p:PositionM):string {
//     return p.hasError ? 'warn' : 'primary'
//   }



//     ///// ADD POSITION OVERLAY
//     addCustomPositionFor(type:string, p:PositionM = null){
//       let _type:AddCustomPositionSelectI.CustomPositonType
//       switch(type){
//         case 'goae':
//           _type = AddCustomPositionSelectI.CustomPositonType.GOA;
//         break;
//         case 'own':
//           _type = AddCustomPositionSelectI.CustomPositonType.OWN;
//         break;
//         case 'material':
//           _type = AddCustomPositionSelectI.CustomPositonType.MATERIAL;
//         break;
//       }

//       let  _modalDate:AddCustomPositionSelectI = {
//         type: _type
//       }

//       this.dialog.open(SelectPositionCustomItemComponent,{
//         width:"600px",
//         // height:"400px",
//         data:_modalDate,
//         backdropClass:"backdrop-background",
//       }).afterClosed().subscribe(
//         _newItem => {
//           if(_newItem != null){
//             switch(type){
//               case 'goae':
//                 if(p!= null) {
//                   p.isOwnBillingItem = false;
//                   p.goa_paragraph =_newItem.goa_paragraph
//                   p.example = _newItem.goa_paragraph.short_description
//                 } else if(p==null){
//                   this.addNewGoaePosition(_newItem)
//                 }
//               break;
//               case 'own':
//                 if(p!= null) {
//                   p.isOwnBillingItem = true;
//                   p.ownBillingItem = _newItem
//                   p.example = _newItem.short_description;
//                 } else if(p==null){
//                   // _newItem = BilligItemI
//                   this.addNewOwnPosition(_newItem)
//                 }
//               break;
//               case 'material':

//               break;
//             }
//           }
//         }
//       )
//     }

//   private addNewGoaePosition(p:PositionM){
//     let _lastIndex = this.section.positions.length > 0 ? this.section.positions.length  : 0;
//     let _id = this.getNewIdForPosition();

//       p.position_order_index = _lastIndex;
//       // p.id = _id;
//       p.example = p.goa_paragraph.short_description;

//     this.section.positions.push(p)
//     this.save()
//   }

//   private addNewOwnPosition(billigitem:BilligItemI){
//     let p:PositionM = PositionsHelper.getCleanPositon(0,0);

//     let _lastIndex = this.section.positions.length > 0 ? this.section.positions.length  : 0;
//     let _id = this.getNewIdForPosition();

//       p.position_order_index = _lastIndex;
//       // p.id = _id;
//       p.isOwnBillingItem = true;
//       p.ownBillingItem = billigitem
//       p.example = billigitem.short_description;

//     this.section.positions.push(p)
//     this.save()
//   }
//   getNewIdForPosition():number{
//     if(this.section.positions.length == 0){
//       return 0;
//     }
//     let _tmpPos:PositionM[] = [];
//     // for (let i = 0; i < this.section.positions.length; i++) {
//     //   const element = this.section.positions[i];
//     //   _tmpPos.push(element)
//     // }
//     _tmpPos = this.section.positions.map(x=>x);
//     _tmpPos.sort((a, b) =>{
//       return a.position_order_index - b.position_order_index;
//     })
//     // console.log(_tmpPos);
//     let _latestId:number = _tmpPos[_tmpPos.length -1].position_order_index;
//     // console.log("_latestId ", _latestId);
//     const newId = _latestId += 1;
//     // console.log("newId ", newId);
//     return newId
//   }
//   showProgressionFactor(p:PositionM):boolean {

//     if(p.goa_paragraph == null && p.ownBillingItem == null){
//       return false;
//     }


//     if(p.isOwnBillingItem){
//       return false
//     }
//     return true;
//   }
}
