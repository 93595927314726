import { Input, Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TemplateFlatI } from 'src/app/core/models/api/TemplateFlatI';
import { PRIVACY } from 'src/app/core/models/api/templatePrivacy';
import { ROW_BUTTON_ACTION_TYPE, ROW_BUTTON_TYPE } from 'src/app/core/models/enums';
import { UiService } from 'src/app/services/ui.service';



@Component({
  selector: 'template-row-button',
  templateUrl: './template-row-button.component.html',
  styleUrls: ['./template-row-button.component.scss']
})
export class TemplateRowButtonComponent implements OnInit {

  buttonTypes = ROW_BUTTON_TYPE

  currentButtonType?:ROW_BUTTON_TYPE = ROW_BUTTON_TYPE.NONE

  @Input() template:TemplateFlatI
  @Output() action: EventEmitter<ROW_BUTTON_ACTION_TYPE> = new EventEmitter<ROW_BUTTON_ACTION_TYPE>()
  constructor(public ui: UiService) { }

  ngOnInit(): void {
    switch(this.template.privacy) {
      case PRIVACY.PUBLISHED_PUBLIC: 
      
      if(this.ui.hasTemplateAdminRole) {

        this.currentButtonType = this.template.isEditable  ?  ROW_BUTTON_TYPE.EDIT :   ROW_BUTTON_TYPE.DUPLICATE
        return 
      } else {
        this.currentButtonType =  ROW_BUTTON_TYPE.CLONE
        return 
      }

      // this.currentButtonType = this.ui.hasTemplateAdminRole && this.template.isEditable  ?  ROW_BUTTON_TYPE.EDIT :   ROW_BUTTON_TYPE.CLONE
      // return 
      
      case PRIVACY.UNPUBLISHED_PUBLIC: 
      this.currentButtonType = this.ui.hasTemplateAdminRole && this.template.isEditable ? ROW_BUTTON_TYPE.EDIT : ROW_BUTTON_TYPE.NONE
      return

      case PRIVACY.USER_OWN: 
      this.currentButtonType = !this.ui.hasTemplateAdminRole && this.template.isEditable ? ROW_BUTTON_TYPE.CREATE_PROCESS : ROW_BUTTON_TYPE.NONE
      return

      default:
      
      this.currentButtonType = ROW_BUTTON_TYPE.NONE
      return
    }
    
  }

  getButtonText(template: TemplateFlatI): string {
    if(template.privacy === PRIVACY.UNPUBLISHED_PUBLIC){
      return "Bearbeiten"
    }
    return template.privacy == PRIVACY.USER_OWN ?  'Verwenden' : 'Übernehmen';
  }


  addTemplateToProcessesTapped() {
    this.action.emit(ROW_BUTTON_ACTION_TYPE.CREATE_PROCESS)
  }
  clonePublicTemplateTapped() {
    this.action.emit(ROW_BUTTON_ACTION_TYPE.CLONE)
  }
  viewTemplate() {
    this.action.emit(ROW_BUTTON_ACTION_TYPE.OPEN_DETAILS)
  }
  cloneToOnwPool() {
    this.action.emit(ROW_BUTTON_ACTION_TYPE.CLONE_TO_OWN_POOL)
  }
}
