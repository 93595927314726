<mat-toolbar class="overlay-toolbar">
  <span class="big">{{title}}</span>
  <span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_mini" (click)='close()'>close</mat-icon>
</mat-toolbar>

<div class="container fs14-400" style="height: calc(100% - 88px);">

  <div class="d-flex flex-column h100 justify-content-center align-items-center"  *ngIf="isSaving">
    <mat-spinner diameter='25' color='primary'></mat-spinner>
  </div>


  <div class="d-flex flex-column"  *ngIf="viewMode !== VIEWMODES.RESET_SHARE_TO_ACCOUNTING && !isSaving">

    <div class="p-2 big" [innerHTML]="infoMessage"></div>
    
    <!-- ERRORS -->
    <div class="p-2" *ngIf="olData && olData.errors.length > 0">

      <div class="d-flex flex-column">
        
        <div class="p-0" *ngFor="let error of olData.errors">

          <div class="alert alert-danger" role="alert">
            {{error}}
          </div>

          <!-- <div class="d-flex">

            <div class="p-2 " >
              {{error}}
            </div>
            <div class="p-2 ml-auto">
              <mat-icon color='warn'>cancel</mat-icon>
            </div>

          </div> -->

        </div>
        
      </div>



    </div>

    <div class="p-2 flex-fill" >
      <mat-label class='mat-caption'>Beschreibung:</mat-label>  
        <textarea style="width:100%; height: 100%; background-color:rgba(0,0,0,0);  resize: vertical !important;"  maxlength="15000"
            rows="6" cols='2' class="mat-body formControl fs14-400"
            #_textArea
            placeholder="Bitte geben Sie eine Beschreibung an, warum Sie den Status ändern wollen"
            (change)="onTextAreaChanged()"
            (resize)="resizeEvent($event)"
            (keydown)="onTextAreaKeyDown($event)"
            (blur)="onTextAreaBlur($event)"
            [(ngModel)]="userMessage" [disabled]='textDisabled'  >
        </textarea>
          
    </div>
  </div>

  <div class="d-flex flex-column h100 justify-content-center align-items-center"  *ngIf="viewMode === VIEWMODES.RESET_SHARE_TO_ACCOUNTING && !isSaving">
    <div class="p-2 big" [innerHTML]="infoMessage"></div>
  </div>
</div>

  <mat-toolbar class="overlay-toolbar">
    <div class="d-flex w100 justify-content-center" *ngIf="!isSaving">
      <div class="p-2" *ngIf="viewMode != VIEWMODES.RESET_SHARE_TO_ACCOUNTING">
        <app-df-icon-button title= "{{readyBtnTxt}}" icon="save" [disabled]="!canSave" color="primary" (clicked)="save()"></app-df-icon-button>
      </div>
      <div class="p-2" *ngIf="viewMode === VIEWMODES.RESET_SHARE_TO_ACCOUNTING">
        <app-df-icon-button title= "Abbrechen" icon="close" (clicked)="close()"></app-df-icon-button>
      </div>
      <div class="p-2" *ngIf="viewMode === VIEWMODES.RESET_SHARE_TO_ACCOUNTING">
        <app-df-icon-button title= "Fortfahren" icon="check" color="primary" (clicked)="resetShareAccounting()"></app-df-icon-button>
      </div>
    </div>
  
  </mat-toolbar>