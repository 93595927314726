import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { employeeI, EmployeeOverlayDataI } from 'src/app/core/models/employeeI';
import { AccountService } from 'src/app/services/account.service';
import { UiService } from 'src/app/services/ui.service';
import { EmployeeComponent } from 'src/app/ui/overlays/employee/employee.component';

@Component({
  selector: 'app-settings-employees',
  templateUrl: './settings-employees.component.html',
  // styleUrls: ['./settings-employees.component.scss']
  styleUrls: ['../settings-panel.scss']
})
export class SettingsEmployeesComponent implements OnInit , OnDestroy {

  @ViewChild(MatSort, {static:true}) sort: MatSort;
  employeeDataSource:any
  // userPath:string
  /* account:AccountI */
  employees:employeeI[] = [];
  displayedColumns = ['titel','firstName','lastName','phone','email'];
  changeHeighSubscription:Subscription
  _currentHeight:number = 0
  
  constructor(
    private ui:UiService,
    private dialog:MatDialog,
    private aS:AccountService
    ) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.loadData()
      this.changeHeighSubscription = this.ui.settingsPanelHeightChanged.subscribe(
        _newHeight =>{
          this._currentHeight = _newHeight
        }
      )
    },120)
  }
  // TODO MIGRATION
  async loadData(){
    const empls = await this.aS.getEmployees(true)
        .then(
          _data => { 
         
            return _data}
        )
        this.employees = empls as employeeI[];
        this.employeeDataSource = new MatTableDataSource(this.employees.filter(item => item.user_state == 'active'));
        this.employeeDataSource.sort = this.sort

        
  }

  addEmployee(){
    let _data:EmployeeOverlayDataI = {
      userPath: this.ui.account.uid
    }
    this.dialog.open(EmployeeComponent,{
      width:"70%",
      maxWidth:"1000px",
      height:"360px",
      backdropClass:"backdrop-background",
      // backdropClass:"backdrop-background",
      // width:"70%",
      // maxWidth:'650px',
      // disableClose:true, 
      data:_data
    }).afterClosed().subscribe(
      data=>{
        this.loadData()
      }
    )
  }
  openDetails(empl:employeeI){
    
    let _data:EmployeeOverlayDataI = {
      userPath: this.ui.account.uid,
       employee:empl
    }
    this.dialog.open(EmployeeComponent,{
      width:"70%",
      maxWidth:"1000px",
      height:"360px",
      backdropClass:"backdrop-background",
      // backdropClass:"backdrop-background",
      // width:"70%",
      // maxWidth:'650px',
      // disableClose:true, 
      data:_data
    }).afterClosed().subscribe(
      data=>{
        this.loadData()
      }
    )
  }

  applyFilter(filterValue: string) {
    this.employeeDataSource.filter = filterValue.trim().toLowerCase();
  }
  get getPanelHeight():string{
    return this._currentHeight + 'px'
  }
  ngOnDestroy(): void {
    if(this.changeHeighSubscription != null){
      this.changeHeighSubscription.unsubscribe()
    }
  }
}
