<!-- <mat-toolbar style="height: 45px; border-radius: 6px; background-color: #ffffff!important;">
  <span class="toolbar-spacer"></span>
  <button mat-icon-button color='basic' style="background-color: #ffffff!important;" (click)='close()' ><mat-icon>close</mat-icon></button>
</mat-toolbar> -->
<!-- [minDate]="minDate"  -->
<div class="container">
  <mat-calendar 
  [selected]="selectedDate" 
  (selectedChange)="onSelect($event)">
</mat-calendar>
</div> 
<mat-toolbar style="height: 64px; width: 100%; border-radius: 6px;" class="tlc justify-content-center">
  <div style="width:92px; text-align:center; margin-right:10px;" class="tlc justify-content-center" >
    <button mat-flat-button color='basic' (click)='close()'>Abbrechen</button>
  </div>
  <div style="width:92px; text-align:center; margin-left:10px;" class="tlc justify-content-center" >
    <button mat-flat-button color='primary' (click)='save()'>Speichern</button>
  </div>

</mat-toolbar>
