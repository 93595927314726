import { environment } from "src/environments/environment";

export enum SECTION_ENDPOINT {
  SECTIONS_BY_T_ID,
  SECTION_MAT_ITEMS,
  SECTION_UPDATE_SINGLE_ITEM,
  SECTION_DELETE,
  SECTION_DUPLICATE,

  SECTION_MAT_ITEM_DELETE,
  SECTION_MAT_ITEM_UPDATE,
  SECTION_MAT_ITEM_ADD,
}

export function SECTION_API_PATH(prefix: string, call: SECTION_ENDPOINT, params: string = '') : string {

  switch(call){

    case SECTION_ENDPOINT.SECTIONS_BY_T_ID: return `${environment.endpoints.apiBasePathV2}${prefix}/sections/by-template-id/${params}`;
    case SECTION_ENDPOINT.SECTION_MAT_ITEMS: return `${environment.endpoints.apiBasePathV2}${prefix}/modules/material/by-section/${params}`;
    case SECTION_ENDPOINT.SECTION_UPDATE_SINGLE_ITEM: return `${environment.endpoints.apiBasePathV2}${prefix}/sections/update-single-item/${params}`;
    
    case SECTION_ENDPOINT.SECTION_DUPLICATE: return `${environment.endpoints.apiBasePathV2}${prefix}/sections/duplicate/${params}`;
    
    case SECTION_ENDPOINT.SECTION_DELETE: return `${environment.endpoints.apiBasePathV2}${prefix}/sections/delete/${params}`;
  
    case SECTION_ENDPOINT.SECTION_MAT_ITEM_DELETE: return `${environment.endpoints.apiBasePathV2}${prefix}/modules/material/remove-from-section/${params}`;
    
    case SECTION_ENDPOINT.SECTION_MAT_ITEM_UPDATE: return `${environment.endpoints.apiBasePathV2}${prefix}/modules/material/section-update/${params}`;
    
    case SECTION_ENDPOINT.SECTION_MAT_ITEM_ADD: return `${environment.endpoints.apiBasePathV2}${prefix}/modules/material/add-to-section/${params}`;
  
    }

}