import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ControllerService } from 'src/app/shared/controller.service';
import { AuthService } from 'src/app/shared/auth.service';
import { TweenMax, Expo } from 'gsap';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { AccountI } from 'src/app/core/models/accountI';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['../auth.scss']
})
export class SetNewPasswordComponent implements OnInit {
  @ViewChild('_content') _content:ElementRef;
  registerEnabled:boolean = false
    completeMessage:string ='Diese Url ist nicht mehr gültig'
  _isEmailOK:boolean = false;
  charLengthOk = false
  specialCharOk = false
  upperCaseCharOK = false
  email:string=''
  emailCopy:string=''
  name:string=''
  companyName:string=''
  pw:string=''
  isLoading:boolean = false;
  title:string = 'Bitte vergeben Sie ein neues Passwort'
  // newAccount :AccountI = {
  //   doc_name:"",
  //   managedBy:"",
  //   klinik_name:"",
  //   status: AccountI.ACCOUNT_STATE.REGISTERED,
  //   email:"",
  //   fax:'',
  //   phone:'',
  //   city:'',
  //   zip:'',
  //   street_nr:'',
  //   uid:'',
  //   roles:[],
  //   registered_date: {
  //     seconds: new Date(Date.now()).getTime(),
  //     nanoseconds:0
  //   },
    
  // }

  canRegister:boolean = false;


  companyFormControl = new FormControl('', [
    Validators.required,
    
  ]);

  passwordCopyFormControl = new FormControl('', [
    Validators.required,
    
  ]);

  constructor(private accountS:AccountService, 
    private authS:AuthService, private router:Router) { }
  _currentCenter = 0  
  ngOnInit(): void {
    ControllerService.windowResize.subscribe(
      _newWindowSize => {
         if(this._currentCenter != _newWindowSize.height / 2){
          this.changeCenterY(_newWindowSize.height / 2);
         } 
      }
    )
  }
  changeCenterY(newCenterY:number){
    let newYPositionStart = this._currentCenter - 305
    this._currentCenter =  newCenterY
    let newYPosition = newCenterY - 305
    TweenMax.fromTo(this._content.nativeElement, 0.65, { 'margin-top':newYPositionStart}, 
    { 'margin-top':newYPosition, ease: Expo.easeOut, });

  }
  fadeIn(){
    this._currentCenter =  (ControllerService.currentScreenSize.height / 2) 
    let newYPositionStart = (ControllerService.currentScreenSize.height / 2) - 320 
    let newYPosition = (ControllerService.currentScreenSize.height / 2) - 305
    TweenMax.fromTo(this._content.nativeElement, 0.65, { 'opacity':0 , 'margin-top':newYPositionStart}, 
    { 'opacity':1, 'margin-top':newYPosition, ease: Expo.easeOut, delay: 0.25});

  }

  fadeOut(){
    this._currentCenter =  (ControllerService.currentScreenSize.height / 2) 
    let newYPositionStart = (ControllerService.currentScreenSize.height / 2) - 305 
    let newYPosition = (ControllerService.currentScreenSize.height / 2) - 280
    TweenMax.fromTo(this._content.nativeElement, 0.65, { 'opacity':1 , 'margin-top':newYPositionStart}, 
    { 'opacity':0, 'margin-top':newYPosition, ease: Expo.easeOut, });
  }

  navigate(path:string){
    this.fadeOut()
    setTimeout(()=>{
      this.router.navigateByUrl(path)
    },650)
  }

  inputChanged(e){
    this.checkValidation();
  }
  passwordChanged(e){
    /* console.log(e)
    if(this.hasUpperCase(e)){
      console.log("ŸES")
    }
    if(this.hastSpecialChar(e)){
      console.log("hastSpecialChar")
    } */
    this.upperCaseCharOK = this.hasUpperCase(e)
    this.specialCharOk = this.hastSpecialChar(e)
    this.charLengthOk = e.length > 7;
    this.checkValidation()
  }
  private hastSpecialChar(str) {
    return (/[#,$,@,&,*,!,%]/.test(str));
  }
  private hasUpperCase(str) {
    return (/[A-Z]/.test(str));
  }
  checkValidation(){
    //this.emailFormControl.valid 
     if(this.upperCaseCharOK &&
     this.specialCharOk &&
     this.charLengthOk &&
       this.companyFormControl.valid //&& this.passwordCopyFormControl.valid
      ) {
        this.registerEnabled = true;
      } 
  }
  register(){}

}
