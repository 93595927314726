import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { employeeI } from 'src/app/core/models/employeeI';
import { PdfPreviewI } from 'src/app/core/models/pdfPreviewI';
import { MatDialog } from '@angular/material/dialog';
import { PdfPreviewComponent } from 'src/app/ui/overlays/pdf-preview/pdf-preview.component';
import { ControllerService } from 'src/app/shared/controller.service';
import { TemplateApiService } from 'src/app/services/template-api.service';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { TemplateFlatI } from 'src/app/core/models/api/TemplateFlatI';
import { SurgerySectionI } from 'src/app/core/models/v4/surgeryPositionI';
import { addDays } from 'date-fns';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-template-report-view',
  templateUrl: './template-report-view.component.html',
  styleUrls: ['../../../../pages/templates-details.scss'],
})
export class TemplateReportViewComponent implements OnInit {

  @Input() template:TemplateFlatI;
  @Input() showSurgeryInfos:boolean;
  @Input() editable:boolean;
  surgeryInfosInit:boolean = true
  @Input() employees:employeeI[]

  public mode: string = 'Markdown';

  showPrintButtons:boolean = false
  showAllSurgeryRelevantItemsToggleText:string = 'Ausgewählte Elemente';
  surgerSectionsAll: SurgerySectionI[] = []

  constructor(
    public tc:TemplateControllerService,
    private activatedRoute:ActivatedRoute,
     private dialog:MatDialog,
     public ui:UiService,
     private templateApi:TemplateApiService) { }


  ngOnInit(): void {
    if(this.template.type == 'SCS' && this.template.befund_id == null){

      // this.template.befund_id = TemplateHelp.cleanBefundModel()
    }

    if(this.activatedRoute.snapshot.routeConfig.path == 'vorgaenge/detail/:id'){
      this.showPrintButtons = true;
    } else if(this.activatedRoute.snapshot.routeConfig.path == 'template-detail/:id'){
      this.showPrintButtons = false;
    }
     this.loadITems()
  }

  get showButtonType(): string {
    if(this.template.surgery_report_id != null && this.template.surgery_report_id != -1){
      return 'FINALIZED_BUTTON'
    } else {
      return 'PREVIEW_BUTTON'
    }
  }

  private async loadITems(){
    const _items = await this.tc.getSurgeryReportPositionItemsByTemplateId(this.tc.currentTemplateId, this.tc.prefix)
    this.surgerSectionsAll = _items;
    this.surgerSectionsAll.sort((a,b) => a.order_index - b.order_index)
  }

  additionalSurgeryInfosChanged(e){
    ControllerService.saveTemplate.emit(this.template.documentId)
  }

  getDateFromSeconds(section:SurgerySectionI):Date {
    let d:Date = new Date(this.tc.activeTemplateDate)
    return addDays(d, section.treated_at_difference_days)
  }

  rteCreated(): void {

  }

  // hasSurgeryRelvantItems(section:SectionM):boolean {
  // //   for(var i = 0; i < section.positions.length; i ++){
  // //     if(section.positions[i].surgery_relevant){
  // //       return true;
  // //     }
  // //  /*   if(this.showAllSurgeryRelevantItems){
  // //       if(section.positions[i].surgery_relevant){
  // //         return true;
  // //       }
  // //     } else if(!this.showAllSurgeryRelevantItems){
  // //       if(section.positions[i].activated && section.positions[i].surgery_relevant){
  // //         return true;
  // //       }
  // //     }*/
  // //   }
  //   // return false;
  //   return true;
  // }



  scsPdfPreview(){
    // let _data:PdfPreviewI = {
    //   template: this.template,
    //   type: PdfPreviewI.Type.SCSREPORT,
    // }
    // this.dialog.open(PdfPreviewComponent,{
    //   disableClose:true,
    //   width:'820px',
    //   height:"92%",
    //   backdropClass:"backdrop-background",
    //   data: _data
    // }).afterClosed().subscribe(
    //   _print => {
    //     if(_print != null){
    //       _print.autoPrint();
    //       // _print.output("dataurlnewwindow");
    //       _print.save("befund-"+ this.template.name +".pdf");
    //     }
    //   }
    // )
  }
  finalReport(){

  }

  async unlockReport(){
    await this.tc.unlockSurgeryReportById(this.tc.currentTemplateId)
    this.tc.activeProcess.surgery_report_id = -1
  }

  showSurgeryReport(){
    let _data:PdfPreviewI = {
      template: this.template,
      type: PdfPreviewI.Type.FINALSURGERYREPORT,
    }
    this.dialog.open(PdfPreviewComponent,{
      disableClose:true,
      width:'820px',
      height:"92%",
      backdropClass:"backdrop-background",
      data: _data
    }).afterClosed().subscribe(
      _print => {
        if(_print != null){
          _print.autoPrint();
          // _print.output("dataurlnewwindow");
          _print.save("op-bericht-"+ this.template.name +".pdf");
        }
      }
    )
  }

  surgeryPdfPreview(){
    let _data:PdfPreviewI = {
      template: this.template,
      type: PdfPreviewI.Type.SURGERYREPORT,
    }
    this.dialog.open(PdfPreviewComponent,{
      disableClose:true,
      width:'820px',
      height:"92%",
      backdropClass:"backdrop-background",
      data: _data
    }).afterClosed().subscribe(
      _print => {
        if(_print != null){
          _print.autoPrint();
          // _print.output("dataurlnewwindow");
          _print.save("op-bericht-"+ this.template.name +".pdf");
        }
      }
    )
  }

  get surgeryReportCreateBtnEnabled() : boolean {

    if(this.ui.isClinicUser) {
      if(this.template &&  this.template.address_id && this.template.address_id != -1){
          return true
        }
        return false;
    } else {
      if(this.template && this.template.patient_id != null && this.template.patient_id != -1
        && this.template.address_id && this.template.address_id != -1){
          return true
        }
        return false;
    }

    return false;
  }

  get createSurgeryReportButtonTooltipText():string {
    if(this.ui.isClinicUser) {
      if(this.template.address_id == null || this.template.address_id == -1){
        return "OP-Bericht kann nicht erstellt werden. Bitte Praxis/Klinik auswählen."
      } else {
        return "OP-Bericht erstellen"
      }
    } else {
      if(this.template.patient_id == null || this.template.patient_id == -1){
        return "OP-Bericht kann nicht erstellt werden. Bitte Patient auswählen."
      } else if(this.template.address_id == null || this.template.address_id == -1){
        return "OP-Bericht kann nicht erstellt werden. Bitte Praxis/Klinik auswählen."
      } else {
        return "OP-Bericht erstellen"
      }
    }

  }
  
}

