
<mat-toolbar class="overlay-toolbar" >
    <mat-label style="font-weight:500!important; font-size: 16px!important;">{{title}}</mat-label>
    <span class="toolbar-spacer"></span>
      <mat-icon style="color:#ABBBC7; cursor: pointer; user-select:none;" (click)='close()'>close</mat-icon>
  </mat-toolbar>



  <div class="container" style="height: 272px;">
  

    <div class="d-flex">
      <div class="p-2 " [class.w75p]="!isClinicMode" [class.w40p]="isClinicMode">
        <div class="d-flex flex-column">
          <div class="p-2" *ngIf="role != 'USER' ">
            <mat-label class='mat-caption'>Vorname *</mat-label>
            <input class="formControl mat-body" [(ngModel)]="firstName">    
          </div>
          <div class="p-2" *ngIf="role != 'USER' ">
            <mat-label class='mat-caption'>Nachname *</mat-label>
            <input class="formControl mat-body" [(ngModel)]="lastName">    
          </div>
          <div class="p-2">
            <mat-label class='mat-caption'>Email *</mat-label>
            <input class="formControl mat-body" [(ngModel)]="email">
          </div>
        </div>
      </div>
      <div class="p-2 w35p" *ngIf="isClinicMode">
        <mat-label class='mat-caption'>Fachbereiche *</mat-label>
          <mat-selection-list #shoes multiple (selectionChange)="sectorSelectionChanged($event)">
            <mat-list-option *ngFor="let userPool of clinicAccountantUserPools" [value]="userPool">
              {{userPool.description}}
            </mat-list-option>
          </mat-selection-list>
        <!-- <mat-label class='mat-caption'>Fachbereiche *</mat-label>
          <mat-selection-list #shoes multiple (selectionChange)="sectorSelectionChanged($event)">
            <mat-list-option *ngFor="let sector of clinicSectors" [value]="sector">
              {{sector.title}}
            </mat-list-option>
          </mat-selection-list> -->
        
      </div>
      <div class="p-2 ml-auto" [class.w15p]="isClinicMode">
        <div class="d-flex flex-column">
          <div class="p-2">
            <h2>INFO</h2>
            <div class="p">Hier steht was zu Abrechnungs Person oder Arzt einladen.</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row p_t20">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <mat-label class='mat-caption'>Vorname *</mat-label>
        <input class="formControl mat-body" [(ngModel)]="firstName">
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <mat-label class='mat-caption'>Nachname *</mat-label>
        <input class="formControl mat-body" [(ngModel)]="lastName">
      </div>
    </div> -->
    
<!--     <div class="row p_t20 p_b20">
    
        <div class="col-lg-6 col-md-6 col-sm-12">
            <mat-label class='mat-caption'>Email *</mat-label>
            <input class="formControl mat-body" [(ngModel)]="email">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <mat-radio-group aria-label="Select an option"  [(ngModel)]="role">
                <mat-radio-button value="ACCOUNTANT">Abrechnungsperson</mat-radio-button>
                <mat-radio-button value="USER">Arzt</mat-radio-button>
            </mat-radio-group>
        </div>
        
        
    </div> -->
    <div class="row p_t20 p_b20" *ngIf="errorMessage" style="color:#ff0000; text-align: center;">
      <div class="col-12 fs16">
        {{errorMessage}}
      </div>
    </div>
    
  </div> 

<mat-toolbar class="overlay-toolbar" >
    <span class="toolbar-spacer"></span>
    
    <div class="d-flex">
    
      <div class="p-0">
        <app-df-icon-button
        title="Speichern"
        icon="save"
        (clicked)="save()"
        [disabled]="!canSave"
        >
        </app-df-icon-button>  
      </div>
    </div>

  </mat-toolbar>
    