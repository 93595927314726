 <div class="doxfox-breadcrump " [class.doxfox-breadcrump-unpinned]='isPinned' *ngIf='breadcrumb != null'>
  <div class="unpinned-content ">
      <p class="mat-body">
        <span (click)='home()' class="home">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px" class="home-icon">
            <path d="M0 0h24v24H0V0z" fill="none"/>
            <path d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z"/>
          </svg>
        </span>

        <span class='bc' *ngFor='let item of breadcrumb.bcItems'>
          <span class="bc-trenner">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#bac7cc" width="18px" height="18px">
              <path d="M0 0h24v24H0V0z" fill="none"/>
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/>
            </svg>
          </span>
          <a routerLink="{{item.path}}" *ngIf='item.path != "" '>{{item.title}}</a>
          <span class="bc-text"    *ngIf='item.path == "" '>{{item.title}}</span>
        </span>
      </p>
  </div>
</div>


<div class="container-fluid" >
  <div class="row">
    <div class="header" >
      <div class='logoC'></div>
      <div class="nav-items">

        <app-main-menu-item-hz *ngFor="let btn of btns"
        [btnTitle]="btn.btnTitle"
        [path]="btn.path"
        [hasManagerRole]="btn.hasManagerRole"
        [hasSubMenu]="btn.hasSubmenu"
        [icon]="btn.icon"
        >

        </app-main-menu-item-hz>
        <!-- <app-main-menu-item-hz btnTitle='Übersicht' path='dashboard' [hasManagerRole]='isManager'
        [hasSubMenu]='false' icon='/assets/nav/dashboard-24px.svg'></app-main-menu-item-hz>

        <app-main-menu-item-hz btnTitle='Vorlagen' path='templates' [hasManagerRole]='isManager'
        [hasSubMenu]='false' icon='/assets/nav/custom_icon_rechnungen.svg'></app-main-menu-item-hz>

        <app-main-menu-item-hz btnTitle='Meine Leistungen' path='vorgaenge' [hasManagerRole]='isManager'
        [hasSubMenu]='false' icon='/assets/nav/format_list_bulleted-24px.svg'></app-main-menu-item-hz>

        <app-main-menu-item-hz btnTitle='Verwaltung' path='verwaltung' [hasManagerRole]='isManager'
        [hasSubMenu]='false' icon='/assets/nav/verwaltung.svg'></app-main-menu-item-hz> -->

      </div>
    </div>
  </div>
  <div class="row">
    <div class="sub-head" *ngIf='breadcrumb != null'>
      <p class="mat-body">
        <span (click)='home()' class="home">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px" class="home-icon">
            <path d="M0 0h24v24H0V0z" fill="none"/>
            <path d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z"/>
          </svg>
        </span>
        <span class='bc' *ngFor='let item of breadcrumb.bcItems'>
          <span class="bc-trenner">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#bac7cc" width="18px" height="18px">
              <path d="M0 0h24v24H0V0z" fill="none"/>
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/>
            </svg>
          </span>
          <a routerLink="{{item.path}}" *ngIf='item.path != "" '>{{item.title}}</a>
          <span class="bc-text"    *ngIf='item.path == "" '>{{item.title}}</span>
        </span>
      </p>
    </div>
  </div>
</div>
