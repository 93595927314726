import { Component, Input, OnInit } from '@angular/core';
import { BefundItemModelI, BefundModelI } from 'src/app/core/models/templateM';
import { ControllerService } from 'src/app/shared/controller.service';

@Component({
  selector: 'template-scs-report-view',
  templateUrl: './template-scs-report-view.component.html',
  styleUrls: ['./template-scs-report-view.component.scss']
  // styleUrls: ['../positions.scss']
})
export class TemplateScsReportViewComponent implements OnInit {

   befund:BefundModelI
  @Input() befund_id:number = -1
  @Input() templateDocumentId: string;
  @Input() isPublicTemplate:boolean
  resizeTimeout: any;
  constructor() { }

  ngOnInit(): void {
  }
  
  next_justifying_indications(){
    var _selectedIndex = 0;
    _selectedIndex = this.befund.justifying_indications.findIndex((item)=>item.selected);
    if(_selectedIndex < this.befund.justifying_indications.length){
      _selectedIndex ++;
    }
    for (var i = 0; i < this.befund.justifying_indications.length; i++) {
      this.befund.justifying_indications[i].selected = i == _selectedIndex ? true : false; 
    }
  }
  prev_justifying_indications(){
    var _selectedIndex = 0;
    _selectedIndex = this.befund.justifying_indications.findIndex((item)=>item.selected);
    if(_selectedIndex > 0){
      _selectedIndex --;
    }
    for (var i = 0; i < this.befund.justifying_indications.length; i++) {
      this.befund.justifying_indications[i].selected = i == _selectedIndex ? true : false; 
    }
  }
  next_technical_implementation(){
    var _selectedIndex = 0;
    _selectedIndex = this.befund.technical_implementation.findIndex((item)=>item.selected);
    if(_selectedIndex < this.befund.technical_implementation.length){
      _selectedIndex ++;
    }
    for (var i = 0; i < this.befund.technical_implementation.length; i++) {
      this.befund.technical_implementation[i].selected = i == _selectedIndex ? true : false; 
    }
  }
  prev_technical_implementation(){
    var _selectedIndex = 0;
    _selectedIndex = this.befund.technical_implementation.findIndex((item)=>item.selected);
    if(_selectedIndex > 0){
      _selectedIndex --;
    }
    for (var i = 0; i < this.befund.technical_implementation.length; i++) {
      this.befund.technical_implementation[i].selected = i == _selectedIndex ? true : false; 
    }
  }
  next_report(){
    var _selectedIndex = 0;
    _selectedIndex = this.befund.report.findIndex((item)=>item.selected);
    if(_selectedIndex < this.befund.report.length){
      _selectedIndex ++;
    }
    for (var i = 0; i < this.befund.report.length; i++) {
      this.befund.report[i].selected = i == _selectedIndex ? true : false; 
    }
  }
  prev_report(){
    var _selectedIndex = 0;
    _selectedIndex = this.befund.report.findIndex((item)=>item.selected);
    if(_selectedIndex > 0){
      _selectedIndex --;
    }
    for (var i = 0; i < this.befund.report.length; i++) {
      this.befund.report[i].selected = i == _selectedIndex ? true : false; 
    }
  }

  getSelected_justifying_indication_Index() : number {
    var _selectedIndex = 0;
    if(this.befund != null && this.befund.justifying_indications != null){
      let index = this.befund.justifying_indications.findIndex((item)=>item.selected);
      return index == -1 ? _selectedIndex : index;
    } 
    return _selectedIndex;
  }

  getSelected_technical_implementation_Index() : number {
    var _selectedIndex = 0;
    if(this.befund != null && this.befund.technical_implementation != null){
      let index = this.befund.technical_implementation.findIndex((item)=>item.selected);
      return index == -1 ? _selectedIndex : index;
    } 
    return _selectedIndex;
  }

  getSelected_report_Index() : number {
    var _selectedIndex = 0;
    if(this.befund != null && this.befund.report != null){
      let index = this.befund.report.findIndex((item)=>item.selected);
      return index == -1 ? _selectedIndex : index;
    } 
    return _selectedIndex;
  }

  get_justifying_indications_Copy():string {
    
    if(this.befund != null && this.befund.justifying_indications != null){
      for (const text_item of this.befund.justifying_indications) {
        if(text_item.selected){
          return text_item.text;
        }
      }
      return this.befund.justifying_indications[0].text;
    } else {
      return "";
    }
  }
  get_technical_implementation_Copy():string {
    
    if(this.befund != null && this.befund.technical_implementation != null){
      for (const text_item of this.befund.technical_implementation) {
        if(text_item.selected){
          return text_item.text;
        }
      }
      return this.befund.technical_implementation[0].text;
    } else {
      return "";
    }
  }
  get_report_Copy():string {
    
    if(this.befund != null && this.befund.report != null){
      for (const text_item of this.befund.report) {
        if(text_item.selected){
          return text_item.text;
        }
      }
      return this.befund.report[0].text;
    } else {
      return "";
    }
  }

  add_justifying_indications_Item(){
    this.befund.justifying_indications.push(
      {
        selected:true,
        text:""
      }
    );
    this.next_justifying_indications()
    this.save()
  }
  remove_justifying_indications_Item(){
    if(this.befund.justifying_indications.length > 1){
      let currentIndex = this.getSelected_justifying_indication_Index();
      this.befund.justifying_indications.splice(currentIndex,1);
      
      this.prev_justifying_indications()
      this.save()
    }
    
  }
  add_technical_implementation_Item(){
    this.befund.technical_implementation.push(
      {
        selected:true,
        text:""
      }
    );
    this.next_technical_implementation()
    this.save()
  }
  remove_technical_implementation_Item(){
    if(this.befund.technical_implementation.length > 1){
      let currentIndex = this.getSelected_technical_implementation_Index();
      this.befund.technical_implementation.splice(currentIndex,1);
      
      this.prev_technical_implementation()
      this.save()
    }
    
  }
  add_report_Item(){
    this.befund.report.push(
      {
        selected:true,
        text:""
      }
    );
    this.next_report()
    this.save()
  }
  remove_report_Item(){
    if(this.befund.report.length > 1){
      let currentIndex = this.getSelected_report_Index();
      this.befund.report.splice(currentIndex,1);
      
      this.prev_report()
      this.save()
    }
  }

save_justifying_indications(w){
  this.befund.justifying_indications[this.getSelected_justifying_indication_Index()].text = w.target.value;
  this.save()
}
save_technical_implementation(w){
  this.befund.technical_implementation[this.getSelected_technical_implementation_Index()].text = w.target.value;
  this.save()
}
save_report(w){
  this.befund.report[this.getSelected_report_Index()].text = w.target.value;
  this.save()
}

  save(){
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      ControllerService.saveTemplate.emit(this.templateDocumentId)
    }).bind(this), 500);
  }
  //justifying_indications?:BefundItemModelI[];
  //technical_implementation?:BefundItemModelI[];
  //report?:BefundItemModelI[];
}
