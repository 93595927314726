import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {



  



  private _initRoute:string
  constructor() { }

  get initRoute():string {
    return this._initRoute
  }
  clearRoute(){
    this._initRoute = null
  }
  setInitialRoute(route:string){

    if(this._initRoute == null){
      if(route == 'accounting-confirm') {
        this._initRoute = 'dashboard'
      } else {
        console.log("setInitialRoute",route)
      this._initRoute = route
      }
      
    }
      
  }


}
