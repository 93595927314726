import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { apiRequest, API_CALL } from 'src/app/core/models/api/apiCalls';
import { AreaOfExpertise } from 'src/app/core/models/expertiseArea';
import { TreatmentRegions, TREATMENT_FILTER, TREATMENT_FILTER_LEVEL } from 'src/app/core/models/treatmentRegionM';
import { ApiUtilService } from 'src/app/services/api-util.service';
import { UiService } from 'src/app/services/ui.service';
import * as _ from 'lodash'
@Component({
  selector: 'app-settings-expertises-detail',
  templateUrl: './settings-expertises-detail.component.html',
  styleUrls: ['./settings-expertises-detail.component.scss']
})
export class SettingsExpertisesDetailComponent implements OnInit {
  selectedTab = 0
  selectedFilter:TREATMENT_FILTER
  _areaOfExpertise:AreaOfExpertise;
  treatmentFilerDetail = false
   _treatmentRegions2: TREATMENT_FILTER[] = [];
   treatmentFilterAll: TREATMENT_FILTER[] = [];
   treatmentFilter1: TREATMENT_FILTER[] = [];
   treatmentFilter2: TREATMENT_FILTER[] = [];
   treatmentFilter3: TREATMENT_FILTER[] = [];
   treatmentFilterGroups = []
  // currentRegionTitle = ''
  constructor(
    private dialogRef:MatDialogRef<SettingsExpertisesDetailComponent>, 
    private apiUtil:ApiUtilService,
    public ui:UiService,
    @Inject(MAT_DIALOG_DATA) public data:AreaOfExpertise 
    ) {
      this._areaOfExpertise = data
  }

  ngOnInit(): void {
    // this._treatmentRegions2 = this.ui.treatmentRegions2.filter(item => item.fachgebiet_id === this._areaOfExpertise.id)
    this.load()
  }
  close(){
    this.dialogRef.close()
  }
  selectedTabChanged(e){
    // console.log(e)
    this.selectedTab = e
    this.clearAddingFillter()
  }
  toggleRegionDetail(showDetail:boolean){
    this.treatmentFilerDetail = showDetail
  }
  async load(){
    if(this._areaOfExpertise.id != -1){
      const _data = await this.apiUtil.apiGetP(apiRequest(API_CALL.GET_TREATMENT_REGIONS_BY_EXPERTISE,this._areaOfExpertise.id.toString()))
      this.treatmentFilterAll = _data as TREATMENT_FILTER[]
      this.treatmentFilter1 = this.treatmentFilterAll.filter(item => item.filter_level == TREATMENT_FILTER_LEVEL.FIRST) 
      this.treatmentFilter2 = this.treatmentFilterAll.filter(item => item.filter_level == TREATMENT_FILTER_LEVEL.SECOND) 
      this.treatmentFilter3 = this.treatmentFilterAll.filter(item => item.filter_level == TREATMENT_FILTER_LEVEL.THIRD) 
      console.log(this.treatmentFilter1)
      console.log(this.treatmentFilter2)
      console.log(this.treatmentFilter3)
      const _index1 = this.treatmentFilterAll.findIndex(item => item.filter_level == TREATMENT_FILTER_LEVEL.FIRST)
      if(_index1 != -1){
        this.treatmentFilterGroups.push('Filter 1')
        const _index2 = this.treatmentFilterAll.findIndex(item => item.filter_level == TREATMENT_FILTER_LEVEL.SECOND)
        if(_index2 != -1){
          this.treatmentFilterGroups.push('Filter 2')
          const _index3 = this.treatmentFilterAll.findIndex(item => item.filter_level == TREATMENT_FILTER_LEVEL.THIRD)
          if(_index3 != -1){
            this.treatmentFilterGroups.push('Filter 3')
            
          }
        }
      }
      
      
    }
    
  }
  async saveCurrentRegion(){
//     this._treatmentRegions2.push({
//       fachgebiet_id: this._areaOfExpertise.id,
//       filter: this.currentRegionTitle, 

//       id: -1,
//       selected: true
//     })

// /*     if(this._areaOfExpertise.id == -1){
//       const _resp = await this.apiUtil.apiPost(apiRequest(API_CALL.POST_AREAS_OF_EXPERTISE),{name:this._areaOfExpertise.name})
//       console.log(_resp)
//     } else {
//       const _path = apiRequest(API_CALL.POST_AREAS_OF_EXPERTISE_UPDATE, this._areaOfExpertise.id.toString())
//       await this.apiUtil.apiPost(_path,{name:this._areaOfExpertise.name})
//       // this.close()
//     } */
//     // const _resp = await this.apiUtil.apiPost(apiRequest(API_CALL.POST_AREAS_OF_EXPERTISE),{name:this._areaOfExpertise.name})
//     // console.log(_resp)
//     setTimeout(()=>{
//       this.currentRegionTitle = ''
//       this.treatmentFilerDetail = false
//     },150)
  }


  async save(){

    // this._saveTreatmentFilter()
    // return

    if(this._areaOfExpertise.id == -1){
      const _resp = await this.apiUtil.apiPost(apiRequest(API_CALL.POST_AREAS_OF_EXPERTISE),{name:this._areaOfExpertise.name, short:this._areaOfExpertise.short})
      console.log(_resp)
      this._areaOfExpertise.id = Number(_resp)
      this._saveTreatmentFilter()
    } else {
     const _path = apiRequest(API_CALL.POST_AREAS_OF_EXPERTISE_UPDATE, this._areaOfExpertise.id.toString())
      await this.apiUtil.apiPost(_path,{name:this._areaOfExpertise.name, short:this._areaOfExpertise.short})
      this._saveTreatmentFilter()
      
    }
  }

  private async _saveTreatmentFilter(){
    let _all = []
    _all = _.concat(this.treatmentFilter1, this.treatmentFilter2, this.treatmentFilter3)
    console.log(_all)
    for(var i = 0; i < _all.length; i ++){
      
      if(_all[i].id == -1 || _all[i].edited){

        const _path = apiRequest(API_CALL.POST_TREATMENT_FILTER, this._areaOfExpertise.id.toString())
        await this.apiUtil.apiPost(_path,{treatmentfilter:_all[i]})
        
      } 
      // else  if( && _all[i].id != -1){
      //   // const _path = apiRequest(API_CALL.POST_TREATMENT_FILTER, this._areaOfExpertise.id.toString())
      //   // await this.apiUtil.apiPost(_path,{treatmentfilter:_all[i]})
      // }
    }
    this.close()
  }

  // get treatmentRegions(){
  //   return this._treatmentRegions2
    
  // }
  // toggleRegion(item){
  //   item.selected = !item.selected;
    
  // }
  regionAllTapped(){
    for(let _expertise of this.ui.fachgebiete){
      _expertise.is_active = true;
    }
    for(let _region of this.ui.treatmentRegions2){
      _region.selected = true;
    }
  
  }
  regionNothingTapped(){
 
    for(let _region of this.ui.treatmentRegions2){
      _region.selected = false;
    }
  }
  addFilterGroup(){
    this.treatmentFilterGroups.push(`Filter ${this.treatmentFilterGroups.length + 1}` )
    this.selectedTab = this.treatmentFilterGroups.length
  } 
  addTreatmentFiler(){
    this.selectedFilter = {
      id: -1,
          fachgebiet_id: -1,
          filter: '',
          filter_level: this.selectedTab == 0 ? TREATMENT_FILTER_LEVEL.FIRST : this.selectedTab == 1 ? TREATMENT_FILTER_LEVEL.SECOND : TREATMENT_FILTER_LEVEL.THIRD,
          selected:true
    }

    
    this.treatmentFilerDetail = true;
  }
  editChip(e:TREATMENT_FILTER){

    console.log(e)
    switch(this.selectedTab){
      case 0:
        const _item = this.treatmentFilter1.find(item => item == e);
        this.selectedFilter = _item
        this.treatmentFilerDetail = true
        return
        case 1:
          const _item2 = this.treatmentFilter2.find(item => item == e);
          this.selectedFilter = _item2
          this.treatmentFilerDetail = true
        return
          case 2:
        const _item3 = this.treatmentFilter3.find(item => item == e);
        this.selectedFilter = _item3
        this.treatmentFilerDetail = true
        return
    }

    
   
  }
  clearAddingFillter(){
    this.selectedFilter = null
    this.treatmentFilerDetail = false
  }
  get saveBtnDisabled():boolean {
    return this._areaOfExpertise.name == '' || this._areaOfExpertise.short == ''
  }
  saveCurrenFilter(){
   
    switch(this.selectedTab){
      case 0:
        if(this.selectedFilter.id == -1){
          this.treatmentFilter1.push(this.selectedFilter)
        } else {
          const _item = this.treatmentFilter1.find(item => item == this.selectedFilter);
          if(_item){
            _item.filter = this.selectedFilter.filter
            _item.edited = true
          }
        }
      
        
        break
      case 1:
      
        if(this.selectedFilter.id == -1){
          this.treatmentFilter2.push(this.selectedFilter)
        } else {
          const _item = this.treatmentFilter2.find(item => item == this.selectedFilter);
          if(_item){
            _item.filter = this.selectedFilter.filter
            _item.edited = true
          }
        }
        break
      case 2:
    
        if(this.selectedFilter.id == -1){
          this.treatmentFilter3.push(this.selectedFilter)
        } else {
          const _item = this.treatmentFilter3.find(item => item == this.selectedFilter);
          if(_item){
            _item.filter = this.selectedFilter.filter
            _item.edited = true
          }
        }
        break
    }
    this.clearAddingFillter()
  }
  deleteTreatmentFilter(){
    if(this.selectedFilter){
      if(this.selectedFilter.id == -1){
        let _index = -1;
        switch(this.selectedTab){
          case 0:
            _index = this.treatmentFilter1.findIndex(item => item == this.selectedFilter);
            if(_index != -0){
              this.treatmentFilter1.splice(_index, 1)
            }
            this.clearAddingFillter()
                return
            case 1:
            _index = this.treatmentFilter2.findIndex(item => item == this.selectedFilter);
            if(_index != -0){
              this.treatmentFilter2.splice(_index, 1)
            }
            this.clearAddingFillter()
              return
            case 2:
            _index = this.treatmentFilter3.findIndex(item => item == this.selectedFilter);
            if(_index != -0){
              this.treatmentFilter3.splice(_index, 1)
            }
            this.clearAddingFillter()
            return
        }
        
      }
    }
  }
}
// switch(this.selectedTab){
//   case 0:
//     this.treatmentFilter1.push(this.selectedFilter)
    
//     break
//   case 1:
//     this.treatmentFilter1.push(this.selectedFilter)
//     // this.treatmentFilter2.push({
//     //   id: -1,
//     //   fachgebiet_id: -1,
//     //   filter: this.currentRegionTitle,
//     //   filter_level: TREATMENT_FILTER_LEVEL.SECOND,
//     //   selected:true
//     // })
//     break
//   case 2:
//     this.treatmentFilter1.push(this.selectedFilter)
//     // this.treatmentFilter3.push({
//     //   id: -1,
//     //   fachgebiet_id: -1,
//     //   filter: this.currentRegionTitle,
//     //   filter_level: TREATMENT_FILTER_LEVEL.THIRD,
//     //   selected:true
//     // })
//     break
// }