import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { PositionFlatI } from 'src/app/core/models/sectionPositionM';
import { AddCustomQuantityComponent } from 'src/app/ui/overlays/add-custom-quantity/add-custom-quantity.component';

@Component({
  selector: 'position-quatity',
  templateUrl: './position-quatity.component.html',
  styleUrls: ['./position-quatity.component.scss']
})
export class PositionQuatityComponent implements OnInit {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @Input() position:PositionFlatI;
  @Input() itemDisabled:boolean;
  @Input() set hasFocus(val: boolean) {
    if(val === true) {
      setTimeout(()=>{
        this.menuTrigger.openMenu()
      },250)
    }
  }
  @Output() quantityChanged = new EventEmitter<number>()

  quantities = [
    {val: 1, viewVal: '1'},
    {val: 2, viewVal: '2'},
    {val: 3, viewVal: '3'},
    {val: 4, viewVal: '4'},
    {val: 5, viewVal: '5'},
  ]

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    // if(this.hasFocus === true) {
    //   setTimeout(()=>{
    //     this.menuTrigger.openMenu()
    //   },250)
    // }
  }
  add() {
    this.dialog.open(AddCustomQuantityComponent,{
      width:'250px',
      data: this.position,
    }).afterClosed().subscribe(
      (quantity:number) => {
        if(quantity){
          this.position.quantity = quantity;
          this.quantityChanged.emit(quantity)
        }
      }
    )
  }

  select(val){
    this.position.quantity = val.val;
    
    this.quantityChanged.emit(val.val)
  }

}
