import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-template-process-copy-alert',
  templateUrl: './template-process-copy-alert.component.html',
  styleUrls: ['./template-process-copy-alert.component.scss']
})
export class TemplateProcessCopyAlertComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<TemplateProcessCopyAlertComponent>,
    ) { }
    // @Inject(MAT_DIALOG_DATA) public data: JustificationEditI

  ngOnInit(): void {
  }
  checkboxChanged(e){
    if(e.checked){
      localStorage.setItem('cpa',"true");
    }
  }
  save(){
    this.dialogRef.close('run');
  }
  close(){
    this.dialogRef.close();
  }

}
