import { Type } from '@angular/core';
import { differenceInMinutes } from 'date-fns';
import { TemplateFlatI } from '../core/models/api/TemplateFlatI';
import { employeeI } from '../core/models/employeeI';
import { SectionMaterialI } from '../core/models/materialI';
import { PositionFlatI } from '../core/models/sectionPositionM';
import { ProcessHostorieComponent } from '../ui/widgets/template-modules/process-hostorie/process-hostorie.component';
import { TemplateBehandlungsschritteViewComponent } from '../ui/widgets/template-modules/template-behandlungsschritte-view/template-behandlungsschritte-view.component';
import { TemplateNachbehandlungComponent } from '../ui/widgets/template-modules/template-nachbehandlung/template-nachbehandlung.component';
import { TemplateReportViewComponent } from '../ui/widgets/template-modules/template-report-view/template-report-view.component';
import { ProcessBehandlungsschritteComponent } from '../ui/widgets/process-behandlungsschritte/process-behandlungsschritte.component';

export class InvoiceView {
  constructor(public component:Type<TemplateBehandlungsschritteViewComponent>, public template:TemplateFlatI, public editable:boolean) {}
}
export class InvoiceViewProcess {
  constructor(public component:Type<ProcessBehandlungsschritteComponent>, public template:TemplateFlatI, public editable:boolean) {}
}

export class ReportView {
  constructor(public component:Type<TemplateReportViewComponent>, public employees:employeeI[], public template:TemplateFlatI,
    public editable:boolean, public showSurgeryInfos:boolean = true) {}
}
export class NachbehandlungView {
  constructor(public component:Type<TemplateNachbehandlungComponent>) {}
}
export class ProcessHistoryView {
  constructor(public component:Type<ProcessHostorieComponent>) {}
}

export const DATETOSTRING = (date:Date, withTime:boolean = false, full: boolean = false) => {
  let MM:number = date.getMonth() + 1;
  let DAY:number = date.getDate();

  let _d:string = DAY < 10 ? '0'+DAY.toString() : DAY.toString();
  let _m:string = MM < 10 ? '0'+MM.toString() : MM.toString();
  if(full === true) {
    let _h = date.getHours()
    let _min = date.getMinutes()
    let _sek = date.getSeconds()

    return `${_d}.${_m}.${date.getFullYear()} ${_h}:${_min}:${_sek}`; 
  }
  if(withTime){
    let _h = date.getHours()
    let _min = date.getMinutes()
    let _sek = date.getSeconds()
    return  _h+ ":" + _min + ":" + _sek
  } else {
    return _d +'.'+ _m  +'.'+ date.getFullYear().toString();
  }
}

export const DATETOSTRING_Extend = (date:Date,seperator:string ,withTime:boolean = false, full: boolean = false) => {
  let MM:number = date.getMonth() + 1;
  let DAY:number = date.getDate();

  let _d:string = DAY < 10 ? '0'+DAY.toString() : DAY.toString();
  let _m:string = MM < 10 ? '0'+MM.toString() : MM.toString();
  if(full === true) {
    let _h = date.getHours()
    let _min = date.getMinutes()
    let _sek = date.getSeconds()

    return `${_d}${seperator}${_m}${seperator}${date.getFullYear()} ${_h}:${_min}:${_sek}`; 
  }
  if(withTime){
    let _h = date.getHours()
    let _min = date.getMinutes()
    let _sek = date.getSeconds()
    return  _h+ ":" + _min + ":" + _sek
  } else {
    return `${_d}${seperator}${_m}${seperator}${date.getFullYear().toString()}`//_d +'.'+ _m  +'.'+ date.getFullYear().toString();
  }
}

export const DATE_TO_TIME_STRING = (date: Date) => {
  let _startHours:string = date.getHours() < 10 ? "0" + date.getHours().toString() : date.getHours().toString();
  let _startMinutes:string = date.getMinutes() < 10 ? "0" + date.getMinutes().toString() : date.getMinutes().toString();
  return _startHours + ':' +_startMinutes;
}

export const DURATION_BETWEEN_DATES_STRING = (dateStart: Date, dateEnd: Date) => {
  let minutes:number = differenceInMinutes(dateEnd, dateStart)
  let _hoursOut = minutes/60;
  let _rHoursOut = Math.floor(_hoursOut);
  var _minutesOut = (_hoursOut - _rHoursOut) * 60;
  var _rMinutesOut = Math.round(_minutesOut);
  return _rHoursOut.toString() + 'h '+ _rMinutesOut.toString() +'min'
}

export const SECTION_HEADER_SUMMERY = (
  positions:PositionFlatI[],
  materialItems:SectionMaterialI[],
  asString:boolean = true) => {

  var _sum:number = 0.0;
    for (var j = 0; j < positions.length; j++) {
      var __position:PositionFlatI = positions[j];
      if(__position.activated && __position.goa_paragraph != null){

        if(__position.goa_paragraph.amount_euro && __position.goa_paragraph.amount_euro != 0){
          const progressFact:number = Number(__position.progression_factor)
          const amountE:number = Number(__position.goa_paragraph.amount_euro)
          const quantityPP:number = Number(__position.quantity)
          let _itemAmount:number = ((progressFact * amountE) * quantityPP);

          let _itemSum = Math.round((_itemAmount + Number.EPSILON) *100) / 100;

          _sum += _itemSum
          _sum =  Math.round((_sum + Number.EPSILON) *100) / 100;
        }

    }
  }
  if(materialItems != null && materialItems.length > 0){
    for(var k = 0; k < materialItems.length; k ++){
      let matItem = materialItems[k]
      let _itemAmount:number = matItem.quantity * Number(matItem.preis)
      let _itemSum = _itemAmount
      _sum += _itemSum
    }
  }
  const output:number = _sum;
  return asString ?  output.toString() : output;
}

export const IS_INT = (value) => {
  var er = /^-?[0-9]+$/;
  return er.test(value);
}
