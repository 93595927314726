export interface AddCustomPositionSelectI {
  type:AddCustomPositionSelectI.CustomPositonType;
}

export namespace AddCustomPositionSelectI {
  export type CustomPositonType = 'OWN' | 'GOA' | 'MATERIAL';
  export const CustomPositonType = {
    OWN: 'OWN' as CustomPositonType,
    GOA: 'GOA' as CustomPositonType,
    MATERIAL: 'MATERIAL' as CustomPositonType
  }
}