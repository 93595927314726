<!-- <div class="container-fluid p0" style="width: 100%; overflow: hidden;"> -->

<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <mat-icon  class='justification_arrow_icon' style="font-size: 20px!important;" (click)="close()">close</mat-icon>
</mat-toolbar>
<div class="container" style="height: 300px;">
  <div class="d-flex flex-column">  
    <div class="p-2">
      <mat-label class='mat-caption'>Suchtext </mat-label>
      <input class="mat-body formControl" 
      [(ngModel)]='newBillingItem.short_description'  
      [disabled]='!canEdit || isLoading' />
    </div>
    <!-- (change)='shortChanged($event)' -->
  
    <div class="p-2">
      <mat-label class='mat-caption'>Langtext</mat-label>
      <textarea class="mat-body formControl" rows="9" 
      [(ngModel)]='newBillingItem.long_description' [disabled]='!canEdit || isLoading'></textarea>
    </div>  
    <!-- (change)='longChanged($event)' -->
  </div>
  
  
  
<!--   <div class="row p_t40 p_b40">
  <div class="container " *ngIf='newBillingItem != null'>
    <div class="row" *ngIf='canEdit(newBillingItem)'>
      <div class="col-auto" *ngIf="!isLoading">
        <button mat-flat-button color='accent' (click)='saveNewItem()'>Speichern</button>
      </div>
      <div class="col-auto" *ngIf="isLoading">
        <mat-spinner diameter='25' color='primary'></mat-spinner>
      </div>
    </div>
  </div>
  </div> -->
</div>

<!-- <mat-toolbar style="height: 42px;">
  <span class="toolbar-spacer"></span>
  <mat-icon  class='justification_arrow_icon' style="font-size: 20px!important;" (click)="saveNewItem()">save</mat-icon>
</mat-toolbar> -->
<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  
    
    <div class="p-0 " *ngIf="canEdit">
      <div class="d-flex align-items-center add-button pr0" (click)='saveNewItem()' [class.add-button-disabled]="!canSave">
        <div class="p_2 align-text-end pl12 pr0 mr0">
          <mat-icon class="nav-icon-mini pr0 mr0" [class.nav-icon-mini-disabled]="!canSave">save</mat-icon>
        </div>
        <div class="p-2 btn-copy align-text-start  p_t5 p_b5 pl0 ml0 pr16">Speichern</div>
      </div>
    </div>

</mat-toolbar>
<!-- </div> -->