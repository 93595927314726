<mat-toolbar class="overlay-toolbar">
  <span *ngIf="dateTime">
    {{dateTime | date: 'dd. MMMM  yyyy'}}
  </span>
  <span class="toolbar-spacer"></span>
  <mat-icon (click)='close()' matTooltip='Abbrechen' class='justification_arrow_icon ml10' style="font-size: 20px!important;">close</mat-icon>
</mat-toolbar>


<div class="d-flex flex-column">
<div class="p-0">
  <div class="d-flex">
    <div class="p-2">
      <div class="d-flex flex-column">
        <div class="p-0">
          <!-- <div class="mat-caption"  [class.dateErrorHead]="dayControl.invalid">dayFocus</div> -->
          <div class="mat-caption"  [class.dateErrorHead]="dayControl.invalid">Tag</div>
        </div>
        <div class="p-0" style="width: 40px;">
          <input class="formControl fs-md18" placeholder="T" 
          appDetectFocus
          #_dayFocus="detectFocus"
          #_day type="text" 
          [class.dateError]="dayControl.invalid && !dayHasFocus"
          (keyup)="onDayInput($event.target.value)"  (focus)="onDayFocus()" [formControl]="dayControl" 
          maxlength="2" >
          <!-- [value]='day' -->
          <!-- (change)="dayChanged($event)" -->
        </div>
      </div>
      
    </div>
    <div class="p-2">
      <div class="d-flex flex-column">
        <div class="p-0">
          <div class="mat-caption" [class.dateErrorHead]="monthControl.invalid">Monat</div>
        </div>
        <div class="p-0" style="width: 40px;">
          <input class="formControl fs-md18" placeholder="M" 
          appDetectFocus
          #_monthFocus="detectFocus"
          #_month type="text" 
          (keyup)="onMonthInput($event)" (focus)="onMonthFocus()" [formControl]="monthControl" [class.dateError]="monthControl.invalid"
          maxlength="2" >
          <!-- [value]='month'  -->
          <!-- (change)="monthChanged($event)" -->
        </div>
      </div>
      
    </div>
    <div class="p-2">
      <div class="d-flex flex-column">
        <div class="p-0">
          <div class="mat-caption">Jahr</div>
        </div>
        <div class="p-0" style="width: 75px;">
          <input class="formControl fs-md18" placeholder="Jahr" #_year type="text"
          (focus)="onYearFocus()" (keyup)="onYearInput($event)" 
          [formControl]="yearControl" [class.dateError]="yearControl.invalid"
          maxlength="4">
          <!-- [value]='year'  -->
          <!-- (change)="yearChanged($event)"  -->
        </div>
      </div>
      
    </div>
  </div>
</div>
<!-- <div class="p-2">
  <button mat-flat-button (click)="close()">Abbrechen</button>
  <button mat-flat-button color="primary" (click)="save()">Speichern</button>
</div> -->
</div>
<mat-toolbar class="overlay-toolbar mt10">

      <span class="toolbar-spacer"></span>
      <app-df-icon-button
      title="Speichern"
      icon="save"
      [disabled]="!dateTimeValid"
      (clicked)="save()" >
      </app-df-icon-button>  
      <span class="toolbar-spacer"></span>
</mat-toolbar>