import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-treatment-edit-overlay',
  templateUrl: './treatment-edit-overlay.component.html',
  styleUrls: ['./treatment-edit-overlay.component.scss']
})
export class TreatmentEditOverlayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
