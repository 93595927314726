import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ScreenSize } from 'src/app/core/models/screenSize';
import { ControllerService } from 'src/app/shared/controller.service';
import { SettingsPanelType } from 'src/app/shared/settingsContentPanelType';
import {TweenMax} from 'gsap';
import { Expo} from 'gsap/all'
import { UiService } from 'src/app/services/ui.service';


@Component({
  selector: 'app-settings-content-panel',
  templateUrl: './settings-content-panel.component.html',
  styleUrls: ['./settings-content-panel.component.scss'],
})
export class SettingsContentPanelComponent implements OnInit , OnDestroy{

   @Input() visiblePanelCount:number
  // @Input() panelTitle:string
  @Input() panelType:SettingsPanelType
  @ViewChild('_settingsPanel') _settingsPanel:ElementRef
  @ViewChild('_settingsPanelCard') _settingsPanelCard:ElementRef

  panelTitle:string = ''
  resizeSubscription: Subscription;
  currentScreenSize: ScreenSize;

  constructor(
    private ui:UiService,
    ) { }

  ngOnDestroy(): void {
    if(this.resizeSubscription != null){
      this.resizeSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    setTimeout(()=>{
      // this._loadData()
      this.currentScreenSize = ControllerService.currentScreenSize;
      this.resizeSubscription = ControllerService.windowResize.subscribe(
      (screeSize: ScreenSize) => {
        if (screeSize != null){
          // || this.currentScreenSize !== screeSize
          this.currentScreenSize = screeSize;
          this.resize();
        }
      }
    );
    this.resize();
    this._fadeIn()
    },220);
    switch(this.panelType){
      case SettingsPanelType.USERS:
        this.panelTitle = 'Userverwaltung'
      return;
      case SettingsPanelType.PATIENT:
        this.panelTitle = 'Patientenverwaltung'
      return;
      case SettingsPanelType.EMPLOYEE:
        this.panelTitle = 'Mitarbeiter'
      return;
      case SettingsPanelType.OWN_BILLIG:
        this.panelTitle = 'Eigene Textbausteine'
      return;
      case SettingsPanelType.MATERIAL:
        this.panelTitle = 'Materialliste'
      return;
      case SettingsPanelType.OP_ITEM:
        this.panelTitle = 'Perioperative Angaben'
      return;
      case SettingsPanelType.INVOICE:
        this.panelTitle = 'Rechnungen'
      return;
      case SettingsPanelType.GOAE:
        this.panelTitle = 'GOÄ Ziffer Editor'
      return;
      case SettingsPanelType.EXPERTISES:
        this.panelTitle = 'Fachbereiche'
      return;
    }
  }

  private _fadeIn(){
    let randomDelay= Math.random() * (0.9 - 0.2) + 0.2;

    TweenMax.to(this._settingsPanel.nativeElement, 0.45,
      {  'opacity':1,  delay:randomDelay, ease: Expo.easeInOut});
  }
  resize(){
    var _newPanelWidth = 500
    var _newPanelHeight = 500
    //EXPERTISES
    //GOAE

    const _maxWidth = 1050
    const _maxHeight = 670
    const _minHeight = 420

    const _screenWidth = this.currentScreenSize.width - 64
    const _screenHeight = this.currentScreenSize.height - 250

    // if(this.visiblePanelCount >= 3) {
    //   _newPanelWidth = _screenWidth / 2;
    // } else if(this.visiblePanelCount < )
    // _newPanelWidth = _screenWidth /  (this.visiblePanelCount / 2);
    // _newPanelHeight = _screenHeight /  this.visiblePanelCount;

    if(this.currentScreenSize.width >= 1921){
      let _panelCols = (this.visiblePanelCount == 2 ? 2 : this.visiblePanelCount == 3 ? 3  : this.visiblePanelCount > 4 ? 3 : 4 );
      let _panelWtemp = (_screenWidth -(_panelCols * 10)) / _panelCols
      // let _panelWtemp = (_screenWidth - (this.visiblePanelCount * 10)) / this.visiblePanelCount;
      _newPanelWidth = _panelWtemp
      const _panelRows = Math.round((this.visiblePanelCount / _panelCols))
      _newPanelHeight = _panelRows > 1 ? _panelWtemp / 1.5 : _screenHeight < _maxHeight ? _screenHeight : _maxHeight
  //  console.log('_panelRows ',_panelRows)
      //padding 64  x 2
    } else if(this.currentScreenSize.width > 1440 && this.currentScreenSize.width < 1921 ){
      let _panelCols = (this.visiblePanelCount == 2 ? 2 : this.visiblePanelCount > 3 ? 2 : 3 );
      let _panelWtemp = (_screenWidth -(_panelCols * 10)) / _panelCols
      _newPanelWidth = _panelWtemp
      // _newPanelHeight = _panelWtemp / 1.5

      const _panelRows = Math.round((this.visiblePanelCount / _panelCols))
      _newPanelHeight = _panelRows > 1 ? _panelWtemp / 1.5 : _screenHeight < _maxHeight ? _screenHeight : _maxHeight
      // console.log('_panelRows ',_panelRows)
      //padding 32  x 2
      // 600 w
    } else if(this.currentScreenSize.width <= 1440){
      let _panelCols = 2
      let _panelWtemp = (_screenWidth -(_panelCols * 10)) / _panelCols
      _newPanelWidth = _panelWtemp
      // _newPanelHeight = _panelWtemp / 1.5
      // _newPanelHeight = _panelCols > 1 ? _panelWtemp / 1.5 : _screenHeight < _maxHeight ? _screenHeight : _maxHeight
      const _panelRows = Math.round((this.visiblePanelCount / _panelCols))
      // console.log('_panelRows ',_panelRows)
      _newPanelHeight = _panelRows > 1 ? _panelWtemp / 1.5 : _screenHeight < _maxHeight ? _screenHeight : _maxHeight

    }
    // if(this.panelType == 'USERS'){
    //   _newPanelHeight = this.currentScreenSize.height - 250
    //   _newPanelWidth = _screenWidth//(this.currentScreenSize.width - (30 + 5) )
    // }
    // if(this.panelType == 'EXPERTISES'){
    //   _newPanelHeight = _maxHeight
    //   _newPanelWidth = _maxWidth
    // }

    // if(this.panelType == 'GOAE'){
    //   _newPanelHeight = _maxHeight
    //   _newPanelWidth = _maxWidth
    // }

    if(this.visiblePanelCount ==1 ){
      _newPanelHeight = this.currentScreenSize.height - 250
      _newPanelWidth = _screenWidth//(this.currentScreenSize.width - (30 + 5) )
    }
    setTimeout(()=>{

  //  _newPanelHeight = _newPanelWidth / 1.5

    TweenMax.to(this._settingsPanel.nativeElement, 0.65,
      {  'width':_newPanelWidth, ease: Expo.easeInOut});
    TweenMax.to(this._settingsPanelCard.nativeElement, 0.65,
      {  'height':_newPanelHeight, ease: Expo.easeInOut});
    this.ui.settingsPanelHeightChanged.emit(_newPanelHeight -100)
    },20)
  }

  openEditWin(){

  }
}

// if(this.currentScreenSize.width >= 1921){
//   // show 3 panels
//   let fourPanelW = (this.currentScreenSize.width - (128 + 40) ) / (this.visiblePanelCount < 4 ? this.visiblePanelCount : 4)
//   _newPanelWidth = fourPanelW
//   _newPanelHeight = fourPanelW / 1.5
//   if(this.panelType == 'USERS'){
//     _newPanelWidth =  (this.currentScreenSize.width - (128 + 10) )
//   }
//   //padding 64  x 2
// } else if(this.currentScreenSize.width > 1440 && this.currentScreenSize.width < 1921 ){
//   let threePanelW = (this.currentScreenSize.width - (54 + 10) ) / 3

//   _newPanelWidth = threePanelW
//   _newPanelHeight = threePanelW / 1.5
//   if(this.panelType == 'USERS'){
//     _newPanelWidth = (this.currentScreenSize.width - (54 + 10) )
//   }
//   //padding 32  x 2
//   // 600 w
// } else if(this.currentScreenSize.width <= 1440){
//   let twoPanelW = (this.currentScreenSize.width - (30 + 20) ) / 2
//   _newPanelWidth = twoPanelW
//   _newPanelHeight = twoPanelW / 1.5
//   if(this.panelType == 'USERS'){
//     _newPanelWidth = (this.currentScreenSize.width - (30 + 5) )

//   }
//   //padding 16  x 2
//   //694 w
// }
