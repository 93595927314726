import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { AlertActionI } from 'src/app/core/models/alertM';
import { TEMPLATE_COPY_MODE, TemplateFlatI } from 'src/app/core/models/api/TemplateFlatI';
import { PRIVACY } from 'src/app/core/models/api/templatePrivacy';
import { ROW_BUTTON_ACTION_TYPE, TemplateInfoOverlayActionE, TemplateInfoOverlayActionResult, TemplateInfoOverlayComponentDataI } from 'src/app/core/models/enums';
import { IRenameTemplateOverlayCloseData, IRenameTemplateOverlayData } from 'src/app/core/models/renameTemplateOverlayData.model';
import { TextEditI } from 'src/app/core/models/textOverlayEditI';
import { AlertService } from 'src/app/services/alert.service';
import { TEMPLATES_CALL } from 'src/app/services/enums/templateS.callEnum';
import { TemplateApiService } from 'src/app/services/template-api.service';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { UiService } from 'src/app/services/ui.service';
import { AuthService } from 'src/app/shared/auth.service';
import { ControllerService } from 'src/app/shared/controller.service';
import { AlertOverlayComponent } from 'src/app/ui/overlays/alert-overlay/alert-overlay.component';
import { NewTemplateNameComponent } from 'src/app/ui/overlays/new-template-name/new-template-name.component';
import { ShareTemplateOverlayComponent } from 'src/app/ui/overlays/share-template-overlay/share-template-overlay.component';
import { TemplateInfoOverlayComponent } from 'src/app/ui/overlays/template-info-overlay/template-info-overlay.component';
import { TexteditOverlayComponent } from 'src/app/ui/overlays/textedit-overlay/textedit-overlay.component';

@Component({
  selector: 'template-row',
  templateUrl: './template-row.component.html',
  // styleUrls: ['./template-row.component.scss']
  styleUrls: ['../templates.component.scss', '../../templates-details.scss']
})
export class TemplateRowComponent implements OnInit {

  @Input() template:TemplateFlatI
  @Output() showDetails: EventEmitter<number> = new EventEmitter<number>()
  @Output() onReloadTemplates: EventEmitter<void> = new EventEmitter<void>()
  TEMPLATE_COPY_MODES = TEMPLATE_COPY_MODE
  // templateFilters = TemplateFilterType
  templatePoolTitle = ""
  buttonActionTypes = ROW_BUTTON_ACTION_TYPE
  canRenameTemplate = false
  constructor(
    private tc:TemplateControllerService,
    private router: Router,
    private dialog: MatDialog,
    private alertService: AlertService,
    private authS: AuthService,
    private templateApi:TemplateApiService,
    public ui:UiService
    ) { }

  ngOnInit(): void {

    if(this.ui.account && this.template.pool_id) {
      
      const pools = this.ui.account.templatePools
      const index = pools.findIndex(item => item.template_pool_id == this.template.pool_id)
      if(index != -1) {
        this.templatePoolTitle = `In: ${pools[index].name}`
        this.canRenameTemplate = pools[index].canEdit
      }
    }
  }

  
  viewTemplate(template: TemplateFlatI){
    this.showDetails.emit(template.template_id)
  }
  onRowButtonAction(action: ROW_BUTTON_ACTION_TYPE) {
    switch(action) {
      case ROW_BUTTON_ACTION_TYPE.CLONE:
        this.renameTemplateActionFireAction(this.template, TEMPLATE_COPY_MODE.CLONE)
      return

      case ROW_BUTTON_ACTION_TYPE.CREATE_PROCESS:
        this.addTemplateToProcessesTapped()
      return

      case ROW_BUTTON_ACTION_TYPE.OPEN_DETAILS:
        this.showDetails.emit(this.template.template_id)
      return

      case ROW_BUTTON_ACTION_TYPE.DUPLICATE:
        this.renameTemplateActionFireAction(this.template, TEMPLATE_COPY_MODE.DUPLICATE)
      return

      case ROW_BUTTON_ACTION_TYPE.CLONE_TO_OWN_POOL:
        this.renameTemplateActionFireAction(this.template, TEMPLATE_COPY_MODE.CLONE_TO_OWN_POOL)
      return
    }
  }


  private renameTemplateActionFireAction(template: TemplateFlatI, mode: TEMPLATE_COPY_MODE ) {
    const pools = this.ui.account.templatePools.filter(item => item.canEdit == true);
    let olData: IRenameTemplateOverlayData = {
      templateName: template.name,
      poolSelectionRequired: mode == TEMPLATE_COPY_MODE.CLONE_TO_OWN_POOL ? true : false,
      templatePools: pools
    }
    
    let newName:string = template.name;
    this.dialog.open(NewTemplateNameComponent,{
      width:"850px",
      height:"250px",
      disableClose:true,
      backdropClass:"backdrop-background",
      data: olData
    }).afterClosed().subscribe(
      (result:IRenameTemplateOverlayCloseData) => {
      if(result){
        newName = result.newName

      if (mode == TEMPLATE_COPY_MODE.DUPLICATE){
        // this.duplicateTemplate(template, newName)
        const _dto = {
          actionType: "DUPLICATE",
          template_id: template.template_id,
          template_pool_id: template.pool_id ? template.pool_id : null,
          newTemplateName: newName,
          owner_uid: template.owner,
          creator_uid: template.owner,
          privacy: template.privacy
        }
        this.onFireTransfer(template, _dto, TemplateInfoOverlayActionE.DUPLICATE_TEMPLATE, newName)
        // this.templateApi.apiPostObject(TEMPLATES_CALL.POST_TEMPLATE_TRANSFER, {dto: _dto})
      } else if (mode == TEMPLATE_COPY_MODE.CLONE){
        const _dto = {
          actionType: "CLONE_PUBLIC",
          template_id: template.template_id,
          template_pool_id: null,
          newTemplateName: newName,
          owner_uid: this.ui.account.uid,
          creator_uid: this.ui.account.uid,
          privacy: "USER_OWN"
        }
        this.onFireTransfer(template, _dto, TemplateInfoOverlayActionE.CLONE_PUBLIC, newName)
        // this.cloneTemplate(template, newName)
      } else if (mode == TEMPLATE_COPY_MODE.CLONE_TO_OWN_POOL){

        // const index = this.ui.account.templatePools.findIndex(item => item.canEdit == true)
        // const pools = this.ui.account.templatePools.filter(item => item.canEdit == true);
        // console.log(pools);
        if(result.selectedPool) {

          const _dto = {
            actionType: "CLONE_TO_OWN_POOL",
            template_id: template.template_id,
            template_pool_id: result.selectedPool.template_pool_id,
            // template_pool_id: this.ui.account.templatePools[index].template_pool_id,
            newTemplateName: newName,
            owner_uid: this.ui.accountRoles.includes('CLINIC_ADMIN') ? this.ui.account.managedBy : this.ui.account.uid,
            creator_uid: this.ui.account.uid,
            privacy: "UNPUBLISHED_PUBLIC"
          }
          console.log(_dto);
          
          this.onFireTransfer(template, _dto, TemplateInfoOverlayActionE.CLONE_TO_OWN_POOL, newName)
        }
        // if(index != -1) {
        //   const _dto = {
        //     actionType: "CLONE_TO_OWN_POOL",
        //     template_id: template.template_id,
        //     template_pool_id: this.ui.account.templatePools[index].template_pool_id,
        //     newTemplateName: newName,
        //     owner_uid: this.ui.accountRoles.includes('CLINIC_ADMIN') ? this.ui.account.managedBy : this.ui.account.uid,
        //     creator_uid: this.ui.account.uid,
        //     privacy: "UNPUBLISHED_PUBLIC"
        //   }
        //   //this.onFireTransfer(template, _dto, TemplateInfoOverlayActionE.CLONE_TO_OWN_POOL, newName)
        // }
        
      }
      } else {

      }


    });
  }

  private async onFireTransfer(template: TemplateFlatI, dto:Object, overlayAction: TemplateInfoOverlayActionE, newName: string){
    
    if (template.type === 'DOXFOX'){
      if(!this.ui.templateFilterInclude('USER_OWN_DOXFOX')){
        this.ui.loadTemplateFilter()
      }
    }
    const _overlayData:TemplateInfoOverlayComponentDataI = {
      overlayAction: overlayAction,
      template_id: template.template_id,
      dto:dto,
      templateName: newName
    }

    this.dialog.open(TemplateInfoOverlayComponent,{
      width:"450px",
      height:"450px",
      disableClose:true,
      backdropClass:"backdrop-background",
      data:_overlayData
    }).afterClosed().subscribe(
      (result:TemplateInfoOverlayActionResult) => {
      if(result != null){
        this.router.navigate([result.path, result.template_id]);
      } else {
        this.onReloadTemplates.emit()
      }
    });
  }

  private addTemplateToProcessesTapped(){
    if(this.template.privacy === PRIVACY.USER_OWN) {
      this.copyTemplateToProcesses(this.template);
    }
  }

  async deleteOwnTemplate(template: TemplateFlatI) {

    this.alertService.clear();
    this.alertService.clearResult();

      const dialogReg = this.dialog.open(AlertOverlayComponent, {
        width:"400px",
        disableClose:true,
        data:  {
          title:"Vorlage löschen",
          bodyMessage:"Sind Sie sicher, dass Sie diese Vorlage löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
          actions: [
            {
              title:'Fortfahren', color: AlertActionI.BUTTON_COLOR_TYPE.WARN, actionType:AlertActionI.BUTTON_ACTION_TYPE.RESUME
            },
            {
              title:'Abbrechen', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType:AlertActionI.BUTTON_ACTION_TYPE.CANCEL
            }
          ]
        },
        backdropClass:"backdrop-background"
      }).afterClosed().subscribe((result) => {

        if(result != null){
          if(result === AlertActionI.BUTTON_ACTION_TYPE.RESUME){
            this.tc.deleteTemplate(template.template_id)
          } else {
            /* this.deleteAlertSubscruption(); */
          }
        }
      });
  }


  private async copyTemplateToProcesses(template: TemplateFlatI){

    const _overlayData:TemplateInfoOverlayComponentDataI = {
      overlayAction: TemplateInfoOverlayActionE.USE_AS_PROCESS,
      template_id: template.template_id,
      // templateName: newName
    }

    this.dialog.open(TemplateInfoOverlayComponent,{
      width:"450px",
      height:"450px",
      disableClose:true,
      backdropClass:"backdrop-background",
      data: _overlayData
    }).afterClosed().subscribe(
      (result:TemplateInfoOverlayActionResult) => {
      if(result != null){
        if(result.template_id.toString() == "no access") {
          ControllerService.customSnack.emit({
            panelClass: "customSnack",
            duration: 5000,
            message: "Ein Fehler ist aufgetreten bitte laden Sie die Seite neu!"
          })
        } else {
          this.router.navigate([result.path, result.template_id]);
        }
        
      }
    });
  }
  
  private async cloneTemplate(template: TemplateFlatI, newName:string) {

    const _overlayData:TemplateInfoOverlayComponentDataI = {
      overlayAction: TemplateInfoOverlayActionE.CLONE_PUBLIC,
      template_id: template.template_id,
      templateName: newName
    }

    this.dialog.open(TemplateInfoOverlayComponent,{
      width:"450px",
      height:"450px",
      disableClose:true,
      backdropClass:"backdrop-background",
      data:_overlayData
    }).afterClosed().subscribe(
      (result:TemplateInfoOverlayActionResult) => {
      if(result != null){
        this.router.navigate([result.path, result.template_id]);
      } else {
        this.onReloadTemplates.emit()
      }
    });
  }

  async renameTemplate(template: TemplateFlatI) {
    const d: TextEditI = {singleline: true, text: template.name, title: 'Vorlage umbenennen', largeFont:true};
    this.dialog.open(TexteditOverlayComponent, {
      width: '600px',
      // height:"400px",
      data: d,
      backdropClass: 'backdrop-background',

    }).afterClosed().subscribe(
      _newText => {
        if (_newText != null){
          template.name = _newText;
          this.templateApi.apiPostObjectAndParams(TEMPLATES_CALL.UPDATE_SINGLE_TEMPLATE_NODE,this.template.template_id.toString(), {item:{name: _newText}})
        }
      }
    );
  }


  async shareTemplate(template: TemplateFlatI) {
    this.dialog.open(ShareTemplateOverlayComponent, {
      disableClose: true,
      width: '450px',
      height: '320px',
      backdropClass: 'backdrop-background',
      data: template,
    })
  }

  showSubMenu(template: TemplateFlatI): boolean {
    return template.isEditable
  }
  deleteButtonAccess(template: TemplateFlatI): boolean {
    return template.isEditable
  }
  canDuplicateTempalte(template:TemplateFlatI):boolean {
    return template.isEditable
  }
  canShareTemplate(template:TemplateFlatI):boolean {
    return template.isEditable && template.privacy == PRIVACY.USER_OWN
  }
}
