import { HTTP_INTERCEPTORS, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { TokenstorageService } from '../services/tokenstorage.service';



/* const TOKEN_HEADER_KEY = 'Authorization'; */
const TOKEN_HEADER_KEY = 'x-access-token';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private token: TokenstorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let authReq = req;
    //const token = this.token.getToken();
    //authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, token.token) })
     if(req.url.startsWith('https://api.ipify') || req.url.includes('/lost-password') || req.url.includes('/validate_fb')){
     authReq = req.clone( { })
    //  headers: req.headers.set("Access-Control-Allow-Origin", "https://app.d0xf0x.de/")
     } else if (req.url.includes('pad-export')) {

      const token = this.token.getToken();
      if (token != null) {
        authReq = req.clone({headers: req.headers.set(TOKEN_HEADER_KEY, token.token).append('accept', 'application/zip') })
        // authReq = req.clone({ headers: req.headers.append("Access-Control-Allow-Origin", "https://app.d0xf0x.de/") })
      }
      //  authReq = req.clone({headers: req.headers.set('accept', 'application/zip') })
       
    } else {
      const token = this.token.getToken();
      authReq = req.clone({})
      // 
      if (token != null) {
        authReq = req.clone({headers: req.headers.set(TOKEN_HEADER_KEY, token.token) })
        // authReq = req.clone({ headers: req.headers.append("Access-Control-Allow-Origin", "https://app.d0xf0x.de/") })
      }
     }

    return next.handle(authReq)
    //.handle
    // (authReq).pipe(
    //   tap( (evt: any) => {
    //     if( evt.body && evt.body.type && evt.body.type == "TOKEN_EXPIRED") {
    //       console.log("TOKEN_EXPIRED");
    //     }


    //   })
    // )
  }


}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];


/**
 

import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable, Subscription, of, throwError } from 'rxjs';
import { TokenstorageService } from '../services/tokenstorage.service';
import { catchError, finalize, retry } from 'rxjs/operators';


import { EMPTY } from 'rxjs';
import { SubjectSubscription } from 'rxjs/internal-compatibility';
const TOKEN_HEADER_KEY = 'Authorization'; 
const TOKEN_HEADER_KEY = 'x-access-token';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
 
  // sss?: Subscription
 
  constructor(private token: TokenstorageService) {

   }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let authReq = req;
    const token = this.token.getToken();
    if (req.url.includes('auth/')){
      console.log("----------------------AUTH INCLUDED\n\n")
      if(!token) {
        console.log("----------------------NO TOKEN\n\n")
      }
    }
    //const token = this.token.getToken();
    //authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, token.token) })
     if(req.url.startsWith('https://api.ipify') || req.url.includes('/lost-password') || req.url.includes('/validate_fb')) {
      authReq = req.clone({  })
     } else {
     

      if (token != null) {
        // return EMPTY
        authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, token.token) })
      } else {
       
      }
     }
    
    //  this.sss = next

     return next.handle(authReq)
    //  .pipe(retry(2),
    //       catchError(error => of([]))
    //   )
    //   .subscribe(
    //     authReq => console.log('HTTP response', authReq),
    //     error => console.log('HTTP Error', error),
    //       () => console.log('HTTP request completed.')
    //   );
    
      
      // finalize( () => {
      //   // console.log("----------------------FIN\n\n")

      //   return []
      // })
      // catchError((error: HttpErrorResponse) => {
      //   if (error.status !== 401) {
      //     // 401 handled in auth.interceptor
      //     console.log(error.message);      
      //   }
      //   return throwError(error);
   //  )
      // })
    //  console.log(_next)
 
    //.handle
    // (authReq).pipe(
    //   tap( (evt: any) => {
    //     if( evt.body && evt.body.type && evt.body.type == "TOKEN_EXPIRED") {
    //       console.log("TOKEN_EXPIRED");
    //     }


    //   })
    
   // )
  }


}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];



 */