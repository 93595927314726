import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import {  AccountI, AddressI, IAccountingPool, IClinicSectorFull, IProcessStateAccount, ITemplatePool, TemplateFilterAccess, TemplatePoolFilterItem, TemplatePoolFilterItemUser, TestBillingCompany } from '../core/models/accountI';
import { AccountService } from './account.service';
import {TreatmentType} from '../core/models/treatment_typeM';
import { TreatmentRegions, TREATMENT_FILTER, TREATMENT_FILTER_LEVEL} from '../core/models/treatmentRegionM';
import { ProcessesSort, SPLIT_SCREEN_CHANGED, SPLIT_SCREEN_VIEW_STATE } from '../core/models/enums';
import * as _ from "lodash";
import { template_lla_item, template_lla_items_by_type } from '../core/models/api/templateUtils';
import { TemplateControllerService } from './template-controller.service';
import { IProcessState } from '../core/models/iProcessState';
import { SurgerySupportRoleI } from '../core/models/v4/surgerySupportRoleI';
import { ControllerService } from '../shared/controller.service';
import { AreaOfExpertiseUserI } from '../core/models/expertiseArea';
import { ACCOUNT_API_PATH, ACCOUNT_CALL } from './enums/accountS.callEnum';
import { HttpClient } from '@angular/common/http';

import { UiUtilServiceService } from './ui-util-service.service';
import { SocketService } from './socket.service';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { DurationPipe } from '../pipes/duration.pipe';
import { CgDatePipe } from '../pipes/cg-date.pipe';
import { addDays, differenceInDays } from 'date-fns';
import { ApiUtilService } from './api-util.service';


@Injectable({
  providedIn: 'root'
})
export class UiService extends UiUtilServiceService implements OnDestroy {

  USE_ACCOUNTING = true
  private _accountingPool: IAccountingPool = null
  private testBillingCompany: TestBillingCompany[] = []
  private _accountSubject = new Subject<AccountI>();
  private _treatmentTypes: TreatmentType[] = [];;
  private _treatmentRegions2: TreatmentRegions[] = [];
  private _allTreatmentFilter: TREATMENT_FILTER[] = []
  private _isClinicUser: boolean = false;
  private _isAccountant: boolean = false;
  private _isAccountExpired: boolean = false;
  private _isInactivityOverlay: boolean = false;

  private _selectedType: TreatmentType;
  private _userAccount:AccountI;
  private _fachgebiete:AreaOfExpertiseUserI[];
  private _fachgebiet:AreaOfExpertiseUserI;
  private _templateFilters:string[];
  private _processFilters:IProcessState[];
  private _templateSortName:number = 0;
  private _templateSortTime:number = 0;
  private _surgerySupportRoles:SurgerySupportRoleI[] = []
  private sortNameArr:string[] = ['nameAsc', 'nameDesc', ''];
  private sortDateArr:string[] = ['','dateAsc', 'dateDesc'];
  private _processesOverviewSort:ProcessesSort = ProcessesSort.DATE_DESC
  private _templateFilterAccess:TemplateFilterAccess[] = []
  private _templatePoolFilterItems:TemplatePoolFilterItem[] = []
  private _templatePoolFilterItemsActive:TemplatePoolFilterItemUser[] = []
  private _splitScreenState = SPLIT_SCREEN_VIEW_STATE.CLOSED;
  private allSplitStates = SPLIT_SCREEN_VIEW_STATE
  private _addresses:AddressI[] = []
  // private _accountingState: ACCOUNTING_STATE = ACCOUNTING_STATE.INVITED
  private _poolUserIdsForAccountant:string[] = []

  activityDetected:EventEmitter<boolean> = new EventEmitter<boolean>();
  settingsPanelHeightChanged:EventEmitter<number> = new EventEmitter<number>();
  filterchangedEmitter:EventEmitter<string[]> = new EventEmitter<string[]>();
  processFilterchangedEmitter:EventEmitter<IProcessState[]> = new EventEmitter<IProcessState[]>();
  templateSortChanged:EventEmitter<number[]> = new EventEmitter<number[]>();
  processesSortChanged:EventEmitter<ProcessesSort> = new EventEmitter<ProcessesSort>();
  templateSplitScreenViewChanged:EventEmitter<SPLIT_SCREEN_CHANGED> = new EventEmitter<SPLIT_SCREEN_CHANGED>();
  loginViewSignInEmitter:EventEmitter<string> = new EventEmitter<string>();

  private _lla_items_by_type:template_lla_items_by_type[] = []
  // processFBSubscription:Subscription

  private accessInterVal:any
  constructor(
    uiutils: UiUtilServiceService,
    private ss: SocketService,
    private accountService:AccountService,
    private apiUtil:ApiUtilService,
    public http: HttpClient,
    private durationPipe: CgDatePipe,
    private tc: TemplateControllerService) {
      
    super(http);
      tc.processReloaded.subscribe(
        _d => {
          //this.fetchTemplateStates()
        }
      )
  }
  ngOnDestroy(): void {
    this.SIGN_OUT()
  }
  //  fetchTemplateStates() {
  //   var ids: number[] = []
  //   ids =  _.map(this.tc.processes,'template_id')
  //   // console.log(ids);
  //   let pids = ids
  //   if (this.accessInterVal) {
  //     clearInterval(this.accessInterVal)
  //   }
  //   this.accessInterVal = setInterval(()=> {
  //     this.processAccessAll(pids)
  //   },1500)
  //   if(this.processFBSubscription != null){
  //     this.processFBSubscription.unsubscribe();
  //   }

  //   // this.processFBSubscription
  //   // this.processFBSubscription = this.fb.
  //   // collection('processInAccounting').doc(doc).valueChanges()
  //   // .subscribe(_data => {
  //   //   console.log(_data)
  //   // })
  // }
  SIGN_OUT(){
    clearInterval(this.accessInterVal)
    this.ss.signOut()
    this._treatmentTypes = []
    this._clinicSectorsFull = []
    this._treatmentRegions2 = []
    this.testBillingCompany = []
    this._poolUserIdsForAccountant = []
    this._userAccount = null
    this._accountingPool = null
    this._isAccountant = false
    this._isClinicUser = false;
    this._isAccountant = false;
    this._isAccountExpired = false;
    // this._accountingState = ACCOUNTING_STATE.CONFIRMED
    ControllerService.setRoles([])
    this._addresses = []
    this.tc.SIGN_OUT()
    this._accountSubject.complete()
    // console.log(this._accountingPool)
    // console.log(this._poolUserIdsForAccountant);
    // if(this.processFBSubscription != null){
    //   this.processFBSubscription.unsubscribe();
    // }
  }
  toggleSplitScreenState(value){
    const __old = this._splitScreenState
    const __new = value
    this.templateSplitScreenViewChanged.emit({oldValue:__old, newValue: __new})
    this._splitScreenState = value
    if(value === 'CLOSED'){
      setTimeout(()=>{
        this.tc.getSections(this.tc.currentTemplateId, this.tc.prefix);
        this.tc.initTemplatePositions(this.tc.currentTemplateId, this.tc.prefix);
      },400)
      return
    }
  }
  toggleProcessesSort(sortType:string){
    switch(sortType){
      case 'patientName':
        this._processesOverviewSort = this._processesOverviewSort == ProcessesSort.PATIENT_ASC ?
        ProcessesSort.PATIENT_DESC : ProcessesSort.PATIENT_ASC;
        this.processesSortChanged.emit(this._processesOverviewSort)
        return;
      case 'date':
        this._processesOverviewSort = this._processesOverviewSort == ProcessesSort.DATE_ASC ?
        ProcessesSort.DATE_DESC : ProcessesSort.DATE_ASC;
        this.processesSortChanged.emit(this._processesOverviewSort)
        return;
      case 'summary':
        this._processesOverviewSort = this._processesOverviewSort == ProcessesSort.SUMMARY_ASC ?
        ProcessesSort.SUMMARY_DESC : ProcessesSort.SUMMARY_ASC;
        this.processesSortChanged.emit(this._processesOverviewSort)
        return;

    }
  }
  private _splitScreenTemplateName = ''

  get fachgebiet():AreaOfExpertiseUserI{
    return this._fachgebiet
  }

  get isInactivityOverlay() : boolean {
    return this._isInactivityOverlay
  }
  set isInactivityOverlay(val) {
    this._isInactivityOverlay = val
  }

  get treatmentFilterGroups(): string[] {
    let _out = []
    const _index1 = this.allTreatmentFilterByCurrentFach.findIndex(item => item.filter_level == TREATMENT_FILTER_LEVEL.FIRST)
    if(_index1 != -1){
      _out.push('Filter 1')
      const _index2 = this.allTreatmentFilterByCurrentFach.findIndex(item => item.filter_level == TREATMENT_FILTER_LEVEL.SECOND)
      if(_index2 != -1){
        _out.push('Filter 2')
        const _index3 = this.allTreatmentFilterByCurrentFach.findIndex(item => item.filter_level == TREATMENT_FILTER_LEVEL.THIRD)
        if(_index3 != -1){
          _out.push('Filter 3')
        }
      }
    }
    return _out
  }


  getFilterByGroup(group:string):TREATMENT_FILTER[] {
    if(group == 'Filter 1'){
      return this.allTreatmentFilterByCurrentFach.filter(item => item.filter_level == TREATMENT_FILTER_LEVEL.FIRST)
    }
    if(group == 'Filter 2'){
      return this.allTreatmentFilterByCurrentFach.filter(item => item.filter_level == TREATMENT_FILTER_LEVEL.SECOND)
    }
    if(group == 'Filter 3'){
      return this.allTreatmentFilterByCurrentFach.filter(item => item.filter_level == TREATMENT_FILTER_LEVEL.THIRD)
    }
     return []
  }

  getFilterLevelByGroup(group:string):TREATMENT_FILTER_LEVEL {
    if(group == 'Filter 1'){
      return TREATMENT_FILTER_LEVEL.FIRST
    }
    if(group == 'Filter 2'){
      return TREATMENT_FILTER_LEVEL.SECOND
    }
    if(group == 'Filter 3'){
      return TREATMENT_FILTER_LEVEL.THIRD
    }
     return null
  }

  set fachgebiet(val:AreaOfExpertiseUserI){
    this._fachgebiet = val
  }
  get fachgebiete():AreaOfExpertiseUserI[]{
    return this._fachgebiete
  }


  get splitScreenTemplateName():string{
    return this._splitScreenTemplateName
  }
  set splitScreenTemplateName(value){
    this._splitScreenTemplateName = value;
  }


  get splitScreenStates(){
    return this.allSplitStates
  }
  get splitScreenState():SPLIT_SCREEN_VIEW_STATE{
    return this._splitScreenState
  }


  get surgerySupportRoles() : SurgerySupportRoleI[] {
    return this._surgerySupportRoles
  }

  get processStates():IProcessState[] {
    return this.tc.processStates
  }

  get lla_items_by_type():template_lla_items_by_type[] {
    return this._lla_items_by_type;
  }

  get addresses():AddressI[] {
    return this._addresses;
  }

  get processesOverviewSort() {
    return this._processesOverviewSort;
  }

  get treatmentTypes(){
    if(this.fachgebiet){
      return this._treatmentTypes.filter(item => item.fachgebiet_id == this.fachgebiet.fachgebiet_id)
    } else {
      return []
    }
  }

  get treatmentRegions2(){
    return this._treatmentRegions2
  }

  get allTreatmentFilterByCurrentFach(){
    if(this._allTreatmentFilter && this._allTreatmentFilter.length > 0 && this._fachgebiet){
      return this._allTreatmentFilter.filter(item => item.fachgebiet_id == this._fachgebiet.fachgebiet_id)
    } else {
      return []
    }
  }


 get isClinicUser(): boolean {
  return this._isClinicUser;
 }


  // get treatmentRegions(){
  //   return this._treatmentRegions
  // }

  // set selectedRegion(val){
  //    this._selectedRegion = val
  // }

  set selectedType(val){
    this._selectedType = val
  }
  // get selectedRegion(){
  //   return this._selectedRegion
  // }

  get selectedType(){
    return this._selectedType
  }
  get uid():string{
    return this._userAccount.uid
  }
  get isAccountExpired():boolean{
    return this._isAccountExpired
  }

  async reloadEmployees(){
    return this.accountService.getEmployees(true);
  }


  get hasBillingCompanies(): boolean {
    return this.testBillingCompany.length > 0
  }


  async reloadAccount(){
    // const b = await this.accountService.apiGet(ACCOUNT_CALL.GET_PADNEXT_FACH).then(
    //   _data => {
    //     console.log(_data);
        
    //     return _data
    //   }
    // )
    const a = await this.accountService.apiGet(ACCOUNT_CALL.GET_ACCOUNT).then(
      _data => {
        // console.log(_data);
        
        return _data
      }
    )
     this._userAccount = a as AccountI;
     this._isClinicUser = this._userAccount.isClinicUser;
     this.tc.isClinicUser = this.isClinicUser
     this._isAccountant = this._userAccount.isAccountant
     this.tc.currenUid = this._userAccount.uid;
     this.loadTestBillingCompanies()
     this.loadAccounting()
     this.stopInterval()
     this.startInterVal()
  }
  ///---------------------------------------------------------------------------------
  private async loadTestBillingCompanies(){
    const _billingCompanies = await this.tc.getUserBillingCompanies();
   this.testBillingCompany = _billingCompanies as TestBillingCompany[]
   console.log("_billingCompanies ", _billingCompanies)
 }
  async loadAccunt(){
    await this.reloadAccount()

    this._accountSubject.next(this._userAccount);
    console.log(this._userAccount);
  

    if(this._userAccount && this._userAccount.dateCreated && this._userAccount.roles.includes('TEST_ACCCOUNT')) {
      let dC = new Date(this._userAccount.dateCreated)
      let expiredDate = addDays(dC, 90)

      let hbetween = differenceInDays(expiredDate, this.currentDateTime)
      this._isAccountExpired = hbetween < 0
      
    }

    this._processFilters = this._userAccount.activeProcessFilters != null ? this._userAccount.activeProcessFilters : []

    // await this.accountantState()
    this.loadTemplateUtils()
    this.accountService.getSurgery_support_roles()

    this.loadTemplateFilter()

    await this.loadProcessFilters()
    const _roles = await this.accountService.apiGet(ACCOUNT_CALL.GET_ROLES)
    ControllerService.setRoles(_roles)
    this.checkAddress();


    const _templateFilterAccess:any = await this.accountService.apiGet(ACCOUNT_CALL.GET_TEMPLATE_FILTER)
    this._templateFilterAccess = _templateFilterAccess
    console.log('_templateFilterAccess ', this._templateFilterAccess)

  }

  get templateFilterAccess():TemplateFilterAccess[] {
    if( this.hasTemplateAdminRole) {
      return this._templateFilterAccess.filter(item => (item.filter_type !== 'USER_OWN_DOXFOX' && item.filter_type !== 'USER_OWN_SCS' ))
    } else {
      return this._templateFilterAccess
    }
     
  }


  async toogleTemplateAccessFilter(filter:TemplateFilterAccess){
    const _itemIndex = this._templateFilterAccess.findIndex((item) => item.id == filter.id);
    if(_itemIndex != -1){
      this._templateFilterAccess[_itemIndex].is_active = !this._templateFilterAccess[_itemIndex].is_active
      // console.log(this._templateFilterAccess[_itemIndex].is_active )

    }
    setTimeout(()=> {
      this.accountService.apiGet(ACCOUNT_CALL.TOGGLE_TEMPLATE_FILTER, `${this._templateFilterAccess[_itemIndex].id}/${this._templateFilterAccess[_itemIndex].is_active ? 1 : 0}`)

    },10)
    setTimeout(()=> {
      this.loadTemplateFilter()
    },100)
  }


  get allTreatmentFilter(): TREATMENT_FILTER[] {
    return this._allTreatmentFilter
  }

  async loadTemplateUtils() {
    const _allUserFilters = await this.tc.user_reatment_filters_all()

    this._allTreatmentFilter = _allUserFilters as TREATMENT_FILTER[]
    // console.log("_allTreatmentFilter", this._allTreatmentFilter)



    const _data2 = await this.tc.areasOfExpertise()

    this._fachgebiete = _data2 as AreaOfExpertiseUserI[]

    if(this._fachgebiete) {
      const _index = this._fachgebiete.findIndex(item => item.is_primary)
      this._fachgebiet = _index != -1 ? this._fachgebiete[_index] : this._fachgebiete[0]
    }

    const _data3 = await this.tc.treatment_types_for_user()
    this._treatmentTypes = _data3 as TreatmentType[]


    // const _activeIds = await this.tc.user_reatment_filters_active()
    // console.log("_activeIds", _activeIds)


    await this.tc.loadPState()
    await this.tc.initPogressionFactors()
    await this.loadProcessFilters()
  }


  async loadTemplateFilter(){
    this._templateFilters = []

    const res = await this.accountService.apiGet(ACCOUNT_CALL.GET_ACTIVE_TEMPLATE_FILTER)
    // console.log("GET_ACTIVE_TEMPLATE_FILTER -------")
    // console.log(res)
    var _filters = res;
    var obj = Object.values(_filters);
    for(const t of obj){
      this._templateFilters.push(t)

    }

    const userTemplateFilterResult = await this.accountService.apiGet(ACCOUNT_CALL.GET_USER_TEMPLATE_FILTER)
    console.log("userTemplateFilterResult -------")
    this._templatePoolFilterItems = userTemplateFilterResult as TemplatePoolFilterItem[]
    // console.log("-------      userTemplateFilterResult")
    console.log(this._templatePoolFilterItems)
    
    //  const adminTemplatePools = await this.accountService.apiGet(ACCOUNT_CALL.TEMPLATE_POOLS_GET)
    //   console.log("-------      adminTemplatePools")
    // console.log(adminTemplatePools)
    const userPoolFilerResult = await this.accountService.apiGet(ACCOUNT_CALL.GET_ACTIVE_POOL_TEMPLATE_FILTER)
    
    // console.log("userPoolFilerResult -------")
    this._templatePoolFilterItemsActive = userPoolFilerResult as TemplatePoolFilterItemUser[]
    // console.log(this._templatePoolFilterItemsActive)
    // console.log("-------      userPoolFilerResult")
    // console.log('_templateFilterAccess ', this._templateFilterAccess)
    // console.log('_templateFilters ', this._templateFilters)
    setTimeout(()=> {
      this.filterchangedEmitter.emit(this._templateFilters)
    },500)
  }

  get getTemplatePools(): ITemplatePool[] {

    return []
  }

  get templatePoolFilters() : TemplatePoolFilterItem[] {
    return this._templatePoolFilterItems
  }
  get templatePoolFiltersActive() : TemplatePoolFilterItemUser[] {
    return this._templatePoolFilterItemsActive
  }
  isTemplatePoolFilterActive(filterItem: TemplatePoolFilterItem) : boolean {
    const element = this._templatePoolFilterItemsActive.find(item => item.template_pool_id == filterItem.template_pool_id)
    if(element && element.filters.includes(filterItem.filterType)) {
      return true
    }
    return false
  }

  async toogleTemplatePoolFilter(filter:TemplatePoolFilterItem){
    const elementIndex = this._templatePoolFilterItemsActive.findIndex(item => item.template_pool_id == filter.template_pool_id)
    if(elementIndex != -1) {
      
      var filters = this._templatePoolFilterItemsActive[elementIndex].filters
      const index = filters.findIndex(item => item == filter.filterType)
      if(index == -1) {
        filters.push(filter.filterType)
      } else {
        filters.splice(index, 1)
      }
      this._templatePoolFilterItemsActive[elementIndex].filters = filters
      setTimeout(()=> {
        this.accountService.apiPostObject(ACCOUNT_CALL.TOGGLE_ACTIVE_POOL_TEMPLATE_FILTER, {filter: filters.toString(), poolid: filter.template_pool_id})
  
      },10)
    } else {
      var filters = [filter.filterType]
      this.accountService.apiPostObject(ACCOUNT_CALL.TOGGLE_ACTIVE_POOL_TEMPLATE_FILTER, {filter: filters.toString(), poolid: filter.template_pool_id})
    }
    
    setTimeout(()=> {
      this.loadTemplateFilter()
    },100)
    
  }

  async loadProcessFilters(){
    this._processFilters = []
    const _r:any =  await this.accountService.apiGet(ACCOUNT_CALL.GET_ACTIVE_PROCESS_STATES)
    if(_r.length > 0){
      let _loadedFilters = _r.split(',')

      for(const _f of this.tc.processStates){
        if(_loadedFilters.includes(_f.id.toString())){
          this._processFilters.push(_f)
        }
      }
    }
  }


  get activeProcessFilterIds(): number[] {
    let out = []
    for(const p of this._processFilters){
      out.push(p.id)
    }
    return out
  }


  async checkAddress() {
    this.loadAddresses()
  }

  async loadAddresses() {
    if(this._userAccount){
      if(!this.hasTemplateAdminRole) {
        const __addresses = await this.accountService.apiGet(ACCOUNT_CALL.GET_ALL_ADDRESSES);
        this._addresses = __addresses as AddressI[]
      }
    // console.log(this._addresses);
    
    } else {
      setTimeout(()=> {
        this.checkAddress()
      },1500)
    }
  }

  async setBaseAddress(id:number) {

  }

  accountSubject():Observable<AccountI>{
    return this._accountSubject.asObservable()
  }

  get account(){
    return this._userAccount
  }

  set account(val){
    this._userAccount = val
    this.updateAccont()
  }

  async updateAccont(){
    await this.accountService.apiPostObject(ACCOUNT_CALL.ACCOUNT_UPDATE,{
      account: {
        title: this.account.title,
      phone: this.account.phone,
      medical_specialty_designation: this.account.medical_specialty_designation
      }
    }).then(
      x => { this.loadAccunt()},
    )
  }


  templateFilterInclude(filter:string):boolean{
    if(this._templateFilters == null){
      this._templateFilters = []
    }
    return this._templateFilters.includes(filter)
  }

  get templateFilter():string[]{
    if(this._templateFilters == null){
      this._templateFilters = []
    }
    return this._templateFilters
  }

  processFilterInclude(filter:IProcessState):boolean{
    if(this._processFilters == null){
      this._processFilters = []
    }
    return this._processFilters.includes(filter)
  }

  get processFilter():IProcessState[]{
    if(this._processFilters == null){
      this._processFilters = []
    }
    return this._processFilters
  }

 async toggleProcessFilter(filter:IProcessState, emit:boolean = true){
  console.log(`toggleProcessFilter ${filter.view_value}`);
  
    if(this._processFilters == null){
      this._processFilters = []
    }
    if(this._processFilters.includes(filter)){
      this._processFilters.splice(this._processFilters.indexOf(filter), 1)
    } else {
      this._processFilters.push(filter)
    }
    if(emit == true) {
      this.processFilterchangedEmitter.emit(this._processFilters)
      var _out = []
      for(const _f of this._processFilters){
        _out.push(_f.id)
      }
      await this.accountService.apiPostObject(ACCOUNT_CALL.PROCESS_FILTER_TOGGLE, {filter:_out.toString()})
    }

  }


  toggleTemplateNameSort(){
    
    if(this._templateSortName == 2){
      this._templateSortName = 0;
    } else {
      this._templateSortName ++;
    }
    this.templateSortChanged.emit([this._templateSortName, this._templateSortTime])
  }


  toggleTemplateTimeSort(){
    if(this._templateSortTime == 2){
      this._templateSortTime = 0;
    } else {
      this._templateSortTime ++;
    }
    this.templateSortChanged.emit([this._templateSortName, this._templateSortTime])
  }

  get templateNameSort():string {
    return this.sortNameArr[this._templateSortName];
  }

  get templateTimeSort():string {
    return this.sortDateArr[this._templateSortTime];
  }

  get templateSort():string {
    const _name = this.templateNameSort
    const _date = this.templateTimeSort
    if(_date == ''){

      return _name == 'nameDesc' ? 'NAME_DESC' : 'NAME_ASC';

    } else if(_date == 'dateAsc'){

      if(_name == '') {
        return 'DATE_ASC'
      } else {
        return _name == 'nameDesc' ? 'DATE_ASC_NAME_DESC' : 'DATE_ASC_NAME_ASC';
      }

    } else if(_date == 'dateDesc'){
      if(_name == '') {
        return 'DATE_DESC'
      } else {
        return _name == 'nameDesc' ? 'DATE_DESC_NAME_DESC' : 'DATE_DESC_NAME_ASC';
      }

    }
  }



  async getOpItems(){
    this._lla_items_by_type = []
    let __items = await this.getLLAsForUser();
    var _items2:template_lla_item[] = []
    _items2 = __items as template_lla_item[]

    for (var i = 0; i < _items2.length;i ++) {

      const _index = this._lla_items_by_type.findIndex((item) => item.type == _items2[i].type)
      if(_index != -1){
        if(this._lla_items_by_type[_index].items == null){
          this._lla_items_by_type[_index].items = []
        }
        this._lla_items_by_type[_index].items.push(_items2[i])
      } else {
        this._lla_items_by_type.push({
          type:_items2[i].type,
          items: [_items2[i]]
        })

      }
    }
  }

  /*DATE_DESC_NAME_ASC
  DATE_ASC_NAME_DESC
  DATE_ASC_NAME_ASC
  DATE_DESC_NAME_DESC
  DATE_DESC
  DATE_ASC
  NAME_DESC
  NAME_ASC*/

  /******** ACCOUNTING ******** */


  // async accountantState() {
  //   const _templateFilterAccess:any = await this.accountService.apiGet(ACCOUNT_CALL.GET_ACCOUNTING_STATE)
  //   this._accountingState = _templateFilterAccess.message
  //   console.log(this._accountingState);
    
  // }

  hasAccess (p:string[]) : boolean  {
    if(!this._userAccount.roles) {
      return false
    }
    return _.some(this._userAccount.roles, p)// this.account.roles.includes(p) ? true : false;
  }
   loadAccounting()  {
    if(this.hasTemplateAdminRole) { return }
    // console.log('ui s ; load accounting');
    
    this.http.get<any>(ACCOUNT_API_PATH(ACCOUNT_CALL.GET_ACCOUNTING))
    .pipe()
    .subscribe(
      (_data: any) => {
        if (_data.status == 'ERROR') {
          console.log("loadAccounting _ ERROR _")    
        } else {
          // console.log("loadAccounting _ data _", _data)   
          if(_data['accounting_user_pool_id']) {
            this.setAccountingPool(_data as IAccountingPool)
            this.accountantPoolUsers()
          }
        }
      }
    )
  }
   private accountantPoolUsers()  {
    
    if(this.isAccountant) { 
      this.http.get<any>(ACCOUNT_API_PATH(ACCOUNT_CALL.POOL_USER_IDS_ACCOUNTANT))
      .pipe()
      .subscribe(
        (_data: any) => {
          if (_data.status == 'ERROR') {
            console.log("accountantPoolUsers _ ERROR _")    
          } else {
            // console.log("accountantPoolUsers _ data _", _data)    
            let userIds = _data as string[]
            userIds.forEach(id => {
              if (!this._poolUserIdsForAccountant.includes(id)) {
                console.log(`Subscribe to ${id}`);
                
                this.ss.subscribeAccountingChannel(id)
                this._poolUserIdsForAccountant.push(id)
              }
            })
          }
        }
      )
     } else {
      return
     }
    // console.log('ui s ; load accounting');
   
   }

  get poolUserIdsForAccountant():string[] {
    return this._poolUserIdsForAccountant
  }
  // get accountingState(): ACCOUNTING_STATE {
  //   return this._accountingState;
  //  }
  get isAccountant(): boolean {
   return this._isAccountant;
  }
  get accountingInUse(): boolean {
    if(this._userAccount) {
      if(this._userAccount.useAccounting === true) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  get accountingPool(): IAccountingPool {
    return this._accountingPool
  }
  get accountingAvailable(): boolean {
    const plansWithAccounting = [2,3,5]
    return this._userAccount == null ? false : plansWithAccounting.includes(this._userAccount.subscription_plan_id)
  }
  setAccountingPool(val: IAccountingPool) {
    this._accountingPool = val
    console.log(this._accountingPool)
    // this.firebsae(val.accounting_pool_id)
  }


  private _processStatesAccount:IProcessStateAccount[] = [];

  get processStatesAccount():IProcessStateAccount[] {
    return this._processStatesAccount
  }


  get canCreatePublicDxFx():boolean {
    const _itemIndex = this._templateFilterAccess.findIndex((item) => item.filter_type == 'UNPUBLISHED_PUBLIC_DOXFOX');
    return _itemIndex != -1
  }





  get hasOwnTemplatePools(): boolean {
    
    if(this._userAccount && this._userAccount.templatePools) {


      const items = this._userAccount.templatePools.filter(item => item.canEdit == true)
      return items.length > 0
    }
    return false
  }
  get hasTemplateAdminRole(): boolean {
  
    if(this._userAccount && this._userAccount.roles) {


      return this._userAccount.roles.includes('TEMPLATE_ADMIN')
    }
    return false
  }
  get hasAccountantRole(): boolean {
    
    if(this._userAccount && this._userAccount.roles) {
      return this._userAccount.roles.includes('ACCOUNTANT')
    }
    return false
  }

  get isDocRole(): boolean {
    
    if(this._userAccount && this._userAccount.roles) {
      return this._userAccount.roles.includes('DOC')
    }
    return false
  }

  get useAccounting(): boolean {  
    if(this._userAccount && this._userAccount.useAccounting && this._accountingPool != null) {
      return this._userAccount.useAccounting
    }
    return false
  }

  get accountRoles() : string[] {
    if(this._userAccount && this._userAccount.roles) {


      return this._userAccount.roles
    }
    return []
  }


  private _clinicSectorsFull: IClinicSectorFull[] = []

  get clinicSectorsFull(): IClinicSectorFull[] {
    return this._clinicSectorsFull
  }

  // async loadClinicSectors() {
  //   const _r = await this.accountService.apiGet(ACCOUNT_CALL.GET_CLINIC_SECTORS)
  //   // const _r = await this.accountService.apiGet(ACCOUNT_CALL.GET_CLINIC_FULL)
  //   // const _response = _r as IClinicSectorFull[]
  //   // this._clinicSectorsFull = _response
  //   console.log(_r)
  // }

  get subscriptionPlanId() : number {
    return this._userAccount && this._userAccount.subscription_plan_id ? this._userAccount.subscription_plan_id : -1
  }
   
  welcomeTitle:string = 'Herzlich Willkommen'
  
  currentDateTime:Date = new Date();
  get helloMessage():string {
    const _hours:number = this.currentDateTime.getHours()

    if(this._userAccount && this._userAccount.dateCreated && this._userAccount.roles.includes('TEST_ACCCOUNT')) {
      let dC = new Date(this._userAccount.dateCreated)
      let expiredDate = addDays(dC, 90)

      // let hbetween = differenceInMinutes(this.currentDateTime, dC)
      let hbetween = differenceInDays(expiredDate, this.currentDateTime)
      let isNegative = hbetween < 0
      if(hbetween > 0) {

      } else {

      }
      // return  `created : ${this.durationPipe.transform(dC)} expiredDate : ${this.durationPipe.transform(expiredDate)} hbetween : ${hbetween} isNegative: ${isNegative}`
      // return  `${this.welcomeTitle} : ${this.durationPipe.transform(dC)}`
      return  `${this.welcomeTitle} ${'<br>'} Dein Testzeitraum endet in: ${hbetween} tagen.`
    } else {
      if(_.inRange(_hours, 3, 12)){
        return "Guten Morgen  "// + this.ui.account.docName
      } else if(_.inRange(_hours, 12, 18)){
        return "Guten Tag  " //+ this.ui.account.docName
      } else if(_.inRange(_hours, 19, 24)){
        return "Guten Abend  " //+ this.ui.account.docName
      } else {
        return this.welcomeTitle
      }
    }

    
  }
}
// async loadClinic() {
//   console.log("load clinic")
  // const _r = await this.accountS.apiGet(ACCOUNT_CALL.GET_CLINIC_FULL)
  // const _response = _r as IClinicSectorFull[]
//   console.log(_response)
// }

export interface session_item {
  dateTime: number
  info: string
}