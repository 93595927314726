import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AuthGuard } from './shared/auth.guard';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { TemplatesComponent } from './pages/templates/templates.component';
import { AccountComponent } from './pages/account/account.component';
import { ProcessesOverviewComponent } from './pages/processes-overview/processes-overview.component';
import { ProcessDetailsComponent } from './pages/process-details/process-details.component';
import { TemplateDetailComponent } from './pages/template-detail/template-detail.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { AdministrationOverviewComponent } from './administration/administration-overview/administration-overview.component';
import { LoginComponent } from './auth/login/login.component';
import { SecureInnerPagesGuard } from './shared/secure-inner-pages.guard';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
// import { TestPageComponent } from './pages/test-page/test-page.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  
  // { 
  //   path: 'dashboard', 
  //   component: TestPageComponent,
  //   canActivate: [AuthGuard]
  // },
  { 
    path: 'dashboard', 
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },

  {
    path:'privacy',
    component: PrivacyComponent
  },
  { 
    path: 'vorgaenge', 
    component: ProcessesOverviewComponent, 
    canActivate: [AuthGuard],
    data:{breadcrumb:'Vorgänge'}
  },
  { 
    path: 'vorgaenge/detail/:id', 
    component: ProcessDetailsComponent, 
    canActivate: [AuthGuard],
    data:{breadcrumb:'Vorgänge'} 
  },
  { 
    path: 'einstellungen', 
    component: AccountComponent, 
    canActivate: [] //AuthGuard
  },
  
  { 
    path: 'templates',
    component: TemplatesComponent, 
    canActivate: [AuthGuard],
    data:{breadcrumb:'Vorlagen'}
  },

  { 
    path: 'template-detail/:id', 
    component: TemplateDetailComponent, 
    canActivate: [AuthGuard]
  },
  { 
    path: 'verwaltung', 
    component: AdministrationOverviewComponent, 
    canActivate: [AuthGuard]
  },

  { 
    path: 'error', 
    component: ErrorPageComponent, 
    canActivate: [AuthGuard]
  },
  { 
    path: 'error/:ll', 
    component: ErrorPageComponent, 
    canActivate: [AuthGuard]
  },
  //// verwaltung
  // {
  //   path: 'verwaltung',
  //   loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
  //   canActivate: [AuthGuard]
    
  // },
  /////// auth //////
  // {
  //   path: 'auth',
  //   loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    
  // },

  {
    path: 'auth/sign-in',
    component: LoginComponent,
    canActivate: [SecureInnerPagesGuard]
  },
  {
    path: 'auth/sign-in/:ll',
    component: LoginComponent,
    canActivate: [SecureInnerPagesGuard]
  },
  { 
    path: 'auth/forgot-password', 
    component: ForgotPasswordComponent, 
    canActivate: [SecureInnerPagesGuard]
  },
];
const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
  useHash: false 
};
@NgModule({
  imports: [RouterModule.forRoot(routes,routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
