<mat-toolbar style="height: 45px; border-radius: 6px; background-color: #ffffff!important;">
  <mat-label style="font-weight:500!important; font-size: 16px!important;">
    Welche Änderungen wurden Vorgenommen</mat-label>
  <span class="toolbar-spacer"></span>
    <mat-icon style="color:#ABBBC7; cursor: pointer;   -webkit-user-select:none;" (click)='close()'>close</mat-icon>
</mat-toolbar>
<div class="container">
  <div class="d-flex justify-content-between flex-column " >  
    
    <div class="p-2">
      <mat-label style="font-weight:400!important; font-size: 14px!important;">Änderungen vom: 
        {{getDateFromTimeStamp(changesInfo.timestamp.seconds) | date: 'dd.MM.yyyy HH:mm'}}</mat-label>
        
        <textarea class="mat-body formControl" [(ngModel)]="changesInfo.changesCopy" 
        [class.errorFormfield]="changesInfo.changesCopy =='' "
        rows="5 "></textarea>

      <!-- <mat-form-field class="textfield100" appearance="outline">
        <textarea matInput [(ngModel)]="changesInfo.changesCopy" 
        [class.errorFormfield]="changesInfo.changesCopy =='' "
        rows="5 " ></textarea>
      </mat-form-field> -->
    </div>
  </div>
      
</div> 
<mat-toolbar style="height: 64px; width: 100%; border-radius: 6px;" class="tlc justify-content-center">
  <div style="width:92px; text-align:center;" class="tlc justify-content-center" >
  <button mat-flat-button color='primary' (click)='save()' [disabled]='changesInfo.changesCopy == "" '>Speichern</button>
  </div>
</mat-toolbar>
  