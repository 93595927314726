

  <div class="d-flex flex-column" >
    <div class="p-0">
      <div class="d-flex">
        <div class="p-2 ml-auto text-right">
          <div class="d-flex flex-column rightTitle">
            <div class="p-0">
              {{userAccount?.title}}
            </div>
            <div class="p-0">
              beh. Arzt: {{userAccount?.doc_name}}
            </div>
            <div class="p-0">
              {{userAccount?.doc_description}}
            </div>
            <div class="p-0">
              <!-- {{userAccount?.street_nr}} -->
            </div>
            <div class="p-0">
              <!-- {{userAccount?.zip}} {{userAccount?.city}} -->
            </div>
            
          </div>
          
        
        </div>
      </div>
    </div>

    <div class="p-2" >
      
      <h2 style="margin-bottom: 0px!important;">OP-Bericht</h2>

    </div>

    <div class="p-2">

      <div class="d-flex">
        <div class="p-0 titleTxt">
          Patient:
        </div>
        <div class="p-0 copyTxt">
          <!-- {{surgeryReport.patientFirstName}} {{surgeryReport.patientLastName}} -->
        </div>
      </div>

      <div class="d-flex">
        <div class="p-0 titleTxt">
          OP-Datum:
        </div>
        <div class="p-0 copyTxt">
          <!-- {{surgeryReport.surgeryDateString}} -->
        </div>
      </div>

      <div class="d-flex" >
        <div class="p-0 titleTxt">
          OP-Zeit:
        </div>
        <div class="p-0 copyTxt">
          XXX
        </div>
      </div>

      <div class="d-flex m_t8" style="border-bottom: 1px solid rgba(0,0,0,0.14);">
      </div>

    </div>

    <div class="p-2">

      <div class="d-flex">
        <div class="p-0 titleTxt">
          Operateur:in:
        </div>
        <div class="p-0 copyTxt">
          XXX
        </div>
      </div>

      <div class="d-flex">
        <div class="p-0 titleTxt">
          Assistenz:
        </div>
        <div class="p-0 copyTxt">
          XXX
        </div>
      </div>

      <div class="d-flex" >
        <div class="p-0 titleTxt">
          Instrumentierung:
        </div>
        <div class="p-0 copyTxt">
          XXX
        </div>
      </div>


      <div class="d-flex">
        <div class="p-0 titleTxt">
          Weitere Assistenz:
        </div>
        <div class="p-0 copyTxt">
          XXX
        </div>
      </div>

      <div class="d-flex">
        <div class="p-0 titleTxt">
          Anästhesie:
        </div>
        <div class="p-0 copyTxt">
          XXX
        </div>
      </div>

      <div class="d-flex m_t8" style="border-bottom: 1px solid rgba(0,0,0,0.14);">
      </div>
    </div>

      <div class="p-2">
      <div class="d-flex" >
        <div class="p-0 titleTxt">
          Lokalisation:
        </div>
        <div class="p-0 copyTxt">
          XXX
        </div>
      </div>

      <div class="d-flex" >
        <div class="p-0 titleTxt">
          Lagerung:
        </div>
        <div class="p-0 copyTxt">
          XXX
        </div>
      </div>

      <div class="d-flex" >
        <div class="p-0 titleTxt">
          Blutsperre in Minuten:
        </div>
        <div class="p-0 copyTxt">
          XXX
        </div>
      </div>

      <div class="d-flex" >
        <div class="p-0 titleTxt">
          Periop. Antibiose:
        </div>
        <div class="p-0 copyTxt">
          XXX
        </div>
      </div>

      <div class="d-flex" >
        <div class="p-0 titleTxt">
          Diagnose:
        </div>
        <div class="p-0 copyTxt">
          XXX
        </div>
      </div>

      <div class="d-flex" >
        <div class="p-0 titleTxt">
          Therapiemethode:
        </div>
        <div class="p-0 copyTxt">
          XXX
        </div>
      </div>
      

      <div class="d-flex m_t8" style="border-bottom: 1px solid rgba(0,0,0,0.14);">
      </div>

    </div>

    <div class="p-2">
      OP-Durchführung:
      

    </div>

  </div>

