
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { SimpleDateSelectionComponent } from 'src/app/ui/overlays/simple-date-selection/simple-date-selection.component';

@Component({
  selector: 'process-date-item',
  templateUrl: './process-date-item.component.html',
  styleUrls: ['./process-date-item.component.scss']
})
export class ProcessDateItemComponent implements OnInit {
 
  @Input()  inputDate:Date;
  @Output() changedEmitter = new EventEmitter<Date>()
  @Input()  title:string  = 'Behandlungsdatum'
  constructor(public tc: TemplateControllerService, private dialog:MatDialog) { }

  ngOnInit(): void {

  }

  // get editable():boolean {
  //   if(this.tc.templateHasInvoice || this.tc.templateHasSurgeryReport){
  //     return false
  //   }
  //   return true;
  // }

  openDateSelection(){
    if(!this.tc.isTemplateEditable){
      return;
    }
    this.dialog.open(SimpleDateSelectionComponent,{
      data: new Date(this.inputDate),
      disableClose: true
    }).afterClosed().subscribe(
      (_newDate:Date) => {
        if(_newDate != null){
          this.changedEmitter.emit(_newDate);
        }
      }
    )
  }
}
