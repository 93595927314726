<mat-toolbar style="height: 42px;">
  <span class="toolbar-spacer"></span>
  <mat-icon  class='justification_arrow_icon' style="font-size: 20px!important;" (click)="close()">close</mat-icon>
</mat-toolbar>

<div class="d-flex flex-column">
  <div class="p-2 flex-fill">
    <input class="formControl mat-body formControlDescription fs14-400" [(ngModel)]="llaItem.text">
  </div>

</div>
<mat-toolbar style="height: 42px;">
  <span class="toolbar-spacer"></span>
  <mat-icon *ngIf="llaItem.lla_text_id != -1" (click)='deleteItem()' class='justification_arrow_icon_warn' style="font-size: 20px!important;">delete</mat-icon>
  <mat-icon (click)='save()' class='justification_arrow_icon_green' style="font-size: 20px!important;">save</mat-icon>
</mat-toolbar>
