import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ControllerService } from '../shared/controller.service';
import { POSITIONS_ENDPOINT, T_POSITION_CALL } from './enums/positionS.call';

@Injectable({
  providedIn: 'root'
})
export class PositionApiService {
  private _endpoint:string = environment.endpoints.apiBasePathV2
  constructor(private http:HttpClient) { }

  //---------------------------------------------------------------------------------------
  async postAndGetId(prefix: string, call:T_POSITION_CALL, payload:Object){
    const _path = POSITIONS_ENDPOINT(prefix, call)
    const _res = await this.http.post(_path, payload).toPromise()
    if (_res && _res['status'] ) {
      if (_res['status'] == "ERROR" && _res['message']) {
        ControllerService.customSnack.emit({
          panelClass: "customSnack",
          duration: 5000,
          message: `ERROR ${_res['message']}`
        })
        return null
      } else if (_res['status'] == "SUCCESS" && _res['insertId']) {
        return _res['insertId']
      } else {
        ControllerService.customSnack.emit({
          panelClass: "customSnack",
          duration: 5000,
          message: "Ein Fehler ist aufgetreten bitte laden Sie die Seite neu!"
        })
        return null
      }

    } else {
      ControllerService.customSnack.emit({
        panelClass: "customSnack",
        duration: 5000,
        message: "Ein Fehler ist aufgetreten bitte laden Sie die Seite neu!"
      })
      return null
    }

  }

  //---------------------------------------------------------------------------------------
  async positionsGET(prefix: string, call:T_POSITION_CALL, params:string = ''){
    const _path = POSITIONS_ENDPOINT(prefix, call, params)
    const _res = await this.http.get(_path).toPromise()
    return _res
  }

  //---------------------------------------------------------------------------------------
  async positionsPOST(prefix: string, call:T_POSITION_CALL, item:Object, params:string = ''){
    const _path = POSITIONS_ENDPOINT(prefix, call, params)
    const _r = await this.http.post(_path, item).toPromise()    
    return _r
  }

  //---------------------------------------------------------------------------------------
  // async updatePosition(prefix: string,position: Object) {
  //   const _path = this._endpoint + `${prefix}/positions/update`
  //   return await this.http.post(_path, {position:position}).toPromise()
  //   .then(
  //     _data => {
  //       return _data
  //     }
  //   )
  // }


  //---------------------------------------------------------------------------------------
  async updateSinglePositionItem(prefix: string,template_id:number, position_id:number, item: Object) {
    const _path =  `${this._endpoint}${prefix}/positions/update-single-item/${template_id}/${position_id}`
    return await this.http.post(_path, {item:item}).toPromise()
    .then(
      _data => {
        return _data
      }
    )
  }


  /**
   *
   *
   * CUSTOM PROGRESSION FACTORS
   *
   *
   */


   async getCustomProgressionFactor(prefix: string,position_id:number){
    const _res = await this.http.get(`${this._endpoint}${prefix}/positions/custom-progression-factor/${position_id}`).toPromise().then(
      _data => { return _data; }
    )
    return _res;
  }

  //---------------------------------------------------------------------------------------
  async addNewCustomProgressionFactor(prefix: string,section_id:number,position_id:number, factor:number){
    const _res = await this.http
    .put(`${this._endpoint}${prefix}/positions/custom-progression-factor/${position_id}`, {factor:factor, section_id: section_id}).toPromise().then(
      _data => { return _data; }
    )
    return _res;
  }


  //---------------------------------------------------------------------------------------
  async updateCustomProgressionFactor(prefix: string,factorId:number, factor:number){
    const _res = await this.http
    .post(`${this._endpoint}${prefix}/positions/custom-progression-factor/${factorId}`, {factor:factor}).toPromise().then(
      _data => { return _data; }
    )
    return _res;
  }

  //---------------------------------------------------------------------------------------
  async deleteCustomProgressionFactor(prefix: string,factorId:number) {
    const _res = await this.http
    .delete(`${this._endpoint}${prefix}/positions/custom-progression-factor/${factorId}`).toPromise().then(
      _data => { return _data; }
    )
    return _res;
  }



  // --- GOA JUSTIFICATIONS
  //---------------------------------------------------------------------------------------
  async getJustificationById(prefix: string,position_id:number, progressionFactorId:number, isPublic:number){
    const _res = await this.http
    .get(`${this._endpoint}${prefix}/positions/goa-justification/${position_id}`).toPromise().then(
      _data => { return _data; }
    )
    console.log(_res)
    return _res;
  }

  //---------------------------------------------------------------------------------------
  async addGoaJustification(prefix: string,position_id:number, item:Object){
    const _res = await this.http
    .put(`${this._endpoint}${prefix}/positions/goa-justification/${position_id}`,item).toPromise().then(
      _data => { return _data; }
    )
    return _res;
  }

  //---------------------------------------------------------------------------------------
  async updateGoaJustification(prefix: string, goa_justification_id:number, item: object){
    const _res = await this.http
    .post(`${this._endpoint}${prefix}/positions/goa-justification/${goa_justification_id}`,{
      item
    }).toPromise().then(
      _data => { return _data; }
    )
    return _res;
  }

  //---------------------------------------------------------------------------------------
  async deleteGOAJustificationById(prefix: string,id:number){
    const _res = await this.http
    .delete(`${this._endpoint}${prefix}/positions/goa-justification/${id}`).toPromise().then(
      _data => { return _data; }
    )
    return _res;
  }


  /**
   *
   *
   * CREAT OR BLOCK
   *
   *
   */
   async createOrGroup(prefix: string,section_id:number, template_id:number,startIndex:number){
    const _res = await this.http
    .post(`${this._endpoint}${prefix}/positions/create-or-block/${template_id}/${section_id}`,{
      startIndex:startIndex
    }).toPromise().then(
      _data => { return _data; }
    )
    return _res;
  }



  /**
   *
   *
   * SURGERY POSITION ITEMS
   *
   *
   */

  async getSurgeryReportPositionItemsByTemplateId(prefix: string,template_id:number){
    const _r = await this.http.get<any[]>(`${this._endpoint}${prefix}/positions/report-position-items/${template_id}`)
    .toPromise().then(x => { return x })
    return _r
  }
  
}
