import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SectionM } from 'src/app/core/models/sectionM';


import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'template-section-scs-item',
  templateUrl: './template-section-scs-item.component.html',
  styleUrls: ['../sections.scss']
})
export class TemplateSectionScsItemComponent implements OnInit {

  @Input() section: SectionM;
  @Input() isInProcesses:boolean
  @Input() templateDocumentId: string;
  @Input() isPublicTemplate:boolean
  @ViewChild(MatExpansionPanel, {static: true}) panel: MatExpansionPanel;
  resizeTimeout: any;
  constructor(public dialog:MatDialog) { }

  isItemOpen:boolean = false;
  isDateEditMode:boolean = false;
  isTitleEditMode:boolean = false;
  title = "Präoperative Behandlung";
  panelOpenState : boolean = false;
  summe = ""

  ngOnInit(): void {

  }


}
