import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PositionFlatI } from 'src/app/core/models/sectionPositionM';
import { TemplateControllerService } from 'src/app/services/template-controller.service';

@Component({
  selector: 'app-add-custom-progression-factor',
  templateUrl: './add-custom-progression-factor.component.html',
  styleUrls: ['./add-custom-progression-factor.component.scss']
})
export class AddCustomProgressionFactorComponent implements OnInit {
  modelControl:FormControl 
  @ViewChild('_txtInput', {static:false}) _txtInput:ElementRef;

  title = 'Eigenen Abrechnungsfaktor anlegen'
  factor = ''
  count = 0
  customFactor:number = 0.0
  position:PositionFlatI
  constructor(
    private tc: TemplateControllerService,
    public dialogRef: MatDialogRef<AddCustomProgressionFactorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PositionFlatI) {
      this.position = data;
    } 

  ngOnInit(): void {
    this.modelControl = new FormControl(this.factor, [Validators.required, Validators.maxLength(4), Validators.pattern(/^[0-9,]*\.?[0-9.]*$/)])
    setTimeout(()=>{
      if(this._txtInput != null) {
        this._txtInput.nativeElement.focus()
        this._txtInput.nativeElement.select()
      }
    },200)
  }
  
  async save(){
    this.factor = this.modelControl.value.replace(',','.');
    this.customFactor = parseFloat(this.factor);
    console.log(this.customFactor)

    if (this.position && this.position.position_id) {
      
      // const res = await this.tc.addNewCustomProgressionFactor(this.tc.prefix, this.position.section_id, this.position.position_id, this.customFactor)
      // const _out = {
      //   isPublic: false, 
      //   custom_factor_id: res['insertId'],
      //   factor: this.customFactor, 
      //   position:this.position.position_id
      // }
      // await this.tc.getCustomProgressionFactorBySectionId(this.tc.prefix, this.position.section_id);
      setTimeout(()=>{
        this.dialogRef.close(this.customFactor)
      },20)
    }
    

    // this.dialogRef.close()
  }
  onKeyUp(e){
    setTimeout(()=>{
      this.count = this.modelControl.value.length
    },5)
  }
  close(){
    this.dialogRef.close()
  }
  get canSave():boolean {
    return this.modelControl.valid && this.position && this.position.position_id != -1
  }
  // get canSave() : boolean {
  //   var rgx = RegExp(/^[0-9]*\.?[0-9]*$/);
  //   return this.factor.match(rgx).
  // }
}
