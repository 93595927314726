export interface TreatmentRegion {
  documentId?: string;
  key?:        string;
  name?:       string;
  selected?:boolean;
}
export interface TreatmentRegions {
  
  id: number;
  fachgebiet_id: number;
  name: string;
  selected?:boolean;
}
export interface TREATMENT_FILTER {
  
  id: number;
  fachgebiet_id: number;
  filter: string;
  filter_level?: TREATMENT_FILTER_LEVEL;
  selected?:boolean;
  edited?:boolean;
}
export enum TREATMENT_FILTER_LEVEL {
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  THIRD = 'THIRD'
}