import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TemplateInfoOverlayActionE, TemplateInfoOverlayComponentDataI } from 'src/app/core/models/enums';
import { TEMPLATES_CALL } from 'src/app/services/enums/templateS.callEnum';
import { TemplateApiService } from 'src/app/services/template-api.service';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
@Component({
  selector: 'app-template-info-overlay',
  templateUrl: './template-info-overlay.component.html',
  styleUrls: ['./template-info-overlay.component.scss']
})
export class TemplateInfoOverlayComponent implements OnInit, OnDestroy {
  template_id:number
  templateName?:string
  loadingMessage = 'Bitte warten...'
  bodyMessage = 'Bitte warten...'
  openMessage = ''
  newTemplate_id?:number
  action:TemplateInfoOverlayActionE;
  overlayData: TemplateInfoOverlayComponentDataI
  loaderTimeout = null;
  isLoading = false
  showOpenMessage = false
  cpaChecker = false
   _resultPath:string
   _resultTemplateId
  constructor(
    private dialoogRef:MatDialogRef<TemplateInfoOverlayComponent>,
    private templateApi:TemplateApiService,
    private tc:TemplateControllerService,
    @Inject(MAT_DIALOG_DATA) public data: TemplateInfoOverlayComponentDataI
    ) {
      this.template_id = data.template_id
      this.templateName = data.templateName
      this.overlayData = data
      this.action = data.overlayAction
      this._resultTemplateId = null
      this._resultPath = null
      this.showOpenMessage = false
      // this.bodyMessage = 'Bitte warten...'
     }


    ngOnInit(): void {
      const el = localStorage.getItem('cpa');
      if(el){
        this.cpaChecker = el == 'true' ? true : false

      }
      console.log(this.action);
      
      if(this.action != TemplateInfoOverlayActionE.USE_AS_PROCESS){
        this.isLoading = true
        this.prcessData()
      } else {
        setTimeout(()=> {
        if(this.cpaChecker == true) {
          this.isLoading = true
        this.prcessData()
        }
        },1500)
        
      }
    }

    async prcessData(){
      this.loaderTimeout = setTimeout(()=> {
        this._timerDone()
      },30000)
      const updateData = {
        data:{
          template_id: this.template_id,
          newName: this.templateName
        }
      }
      if(this.action == TemplateInfoOverlayActionE.CLONE_PUBLIC){

        // const message = await this.tc.cloneTemplate(this.template_id, updateData.data);
        const _result = await this.templateApi.apiPostObject(TEMPLATES_CALL.POST_TEMPLATE_TRANSFER, {dto: this.overlayData.dto})
        this._resultPath = 'template-detail'

        console.log(_result)
        this._resultTemplateId = _result
        this.bodyMessage = "Die Vorlage wurde erfolgreich übernommen. Möchten sie <b>" + this.templateName + " </b> öffnen?",
        this.processDone()
      

      } else if(this.action == TemplateInfoOverlayActionE.DUPLICATE_TEMPLATE){

        const _result = await this.templateApi.apiPostObject(TEMPLATES_CALL.POST_TEMPLATE_TRANSFER, {dto: this.overlayData.dto})
        // const _result =  await this.tc.duplicateTemplate(this.template_id, updateData.data)
        this._resultPath = 'template-detail'
        this._resultTemplateId = _result
        this.bodyMessage = "Die Vorlage wurde erfolgreich dupliziert. Möchten sie <b>" + this.templateName + " </b> öffnen?",
        this.processDone()


      } else if(this.action == TemplateInfoOverlayActionE.USE_AS_PROCESS){

        // console.log(this.template_id)
        // return
        const _result =  await this.tc.moveTemplateToProcesses(this.template_id)
        // this._resultPath = 'vorgaenge/detail'
        // this._resultTemplateId = _result
        // this.processDone()
        // console.log(_result)
        this.dialoogRef.close({path:'vorgaenge/detail', template_id:_result})

      } else if(this.action == TemplateInfoOverlayActionE.CLONE_TO_OWN_POOL){

        const _result = await this.templateApi.apiPostObject(TEMPLATES_CALL.POST_TEMPLATE_TRANSFER, {dto: this.overlayData.dto})
        // const _result =  await this.tc.duplicateTemplate(this.template_id, updateData.data)
        this._resultPath = 'template-detail'
        this._resultTemplateId = _result
        this.bodyMessage = "Die Vorlage wurde erfolgreich dupliziert. Möchten sie <b>" + this.templateName + " </b> öffnen?",
        this.processDone()


      }

    }

    openTemplate(){
      if(this._resultTemplateId && this._resultPath){
        this.dialoogRef.close({path: this._resultPath, template_id: this._resultTemplateId})
      }

    }
    runProcess(){
      this.prcessData();
      this.isLoading = true
    }
    private processDone(){

      clearTimeout(this.loaderTimeout)
      this.isLoading = false
      this.showOpenMessage = true
    }
    checkboxChanged(e){
      localStorage.setItem('cpa',e.checked ? 'true' : 'false');
      if(e.checked){
      }
    }
    private _timerDone(){
      if(this.loaderTimeout != null){
        clearTimeout(this.loaderTimeout)
      }
      this.isLoading = false;
      this.close()
      // this.openMessage = `${this.templateName} jetzt öffnen?`
      // this.bodyMessage = `Die Vorlage <br> wurde erfolgreich übertragen`
      // ${this.templateName} <br>
    }
    save(){
      //this.dialoogRef.close(this.newTemplateDocId)
    }
    close(){
      this.dialoogRef.close()
    }

  ngOnDestroy(): void {
   if(this.loaderTimeout != null){
     clearTimeout(this.loaderTimeout)
   }
  }
}
