<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <mat-icon style="color:#ABBBC7; cursor: pointer;   -webkit-user-select:none;" (click)='close()'>close</mat-icon>
</mat-toolbar>
<div class="container">
  <div class="d-flex flex-column p_t25" *ngIf="!showSubscriptionRequestMessage">

    <div class="p-2 fs14-400">
      Sie haben derzeit folgende Lizenz gebucht: 
    </div>
  
    <div class="p-0 trennerBottom" >
      <div class="d-flex fs14-400">
        <div class="p-2 ">
          <p class="fs-md18">Basic <span *ngIf="ui.subscriptionPlanId == 1" class="activeLicenceMarker">aktiv</span> </p>
          <p>Einzeluser</p>
        </div>
        <div class="p-2 ml-auto w-25 tlc">
          <h2 class="mb0">XXX €</h2>
          <p>pro Monat/Nutzer</p>
          <button *ngIf="ui.subscriptionPlanId != 1  && showSubscriptionChangeButtons" mat-flat-button color="primary" (click)="onSubscriptionRequest('Basic')">Downgrade anfragen</button>
        </div>
      
      </div>
    </div>
  
  
    <div class="p-0 trennerBottom p_t15">
      <div class="d-flex">
        <div class="p-2  accountingList fs14-400">
          <p class="fs-md18">Premium <span *ngIf="ui.subscriptionPlanId == 2" class="activeLicenceMarker">aktiv</span> </p>
          <p>Team, inklusive Abrechnungsperson</p> 
          <div class="d-flex">
            <div class="p-0"><mat-icon class="accountingCheckListIcon">check</mat-icon></div>
            <div class="p-0">Abrechnungsperson hinzufügen</div>
          </div>
          <div class="d-flex p_t8">
            <div class="p-0"><mat-icon class="accountingCheckListIcon">check</mat-icon></div>
            <div class="p-0">Einmalige Schulung für Abrechnungsperson</div>
          </div>
          <div class="d-flex p_t8">
            <div class="p-0"><mat-icon class="accountingCheckListIcon">check</mat-icon></div>
            <div class="p-0">Mindest Vertragslaufzeit 1 Jahr</div>
          </div>
          <div class="d-flex">
            <div class="p-2">Für den endgültigen Preis, einschließlich Rabatten, <br>tritt doxfox direkt mit Ihnen in Kontakt.</div>
          </div>
        </div>
        <div class="p-2 ml-auto  w-25 tlc">
          <h2 class="">XXX €</h2>
          <button *ngIf="ui.subscriptionPlanId != 2 && showSubscriptionChangeButtons" mat-flat-button color="primary" (click)="onSubscriptionRequest('Premium')">Premium beantragen</button>
        </div>
      </div>
    </div>
  
  
    <div class="p-0 p_t15">
      <div class="d-flex">
        <div class="p-2  accountingList fs14-400">
          <p class="fs-md18">Premium Plus  <span *ngIf="ui.subscriptionPlanId == 3" class="activeLicenceMarker">aktiv</span> </p>
          <p>Team, inklusive Abrechnungsperson</p> 
          <div class="d-flex">
            <div class="p-0"><mat-icon class="accountingCheckListIcon">check</mat-icon></div>
            <div class="p-0">Abrechnungsperson hinzufügen</div>
          </div>
          <div class="d-flex p_t8">
            <div class="p-0"><mat-icon class="accountingCheckListIcon">check</mat-icon></div>
            <div class="p-0">olleg:in zum Team hinzufügen </div>
          </div>
          <div class="d-flex p_t8">
            <div class="p-0"><mat-icon class="accountingCheckListIcon">check</mat-icon></div>
            <div class="p-0">Einmalige Schulung für Abrechnungsperson</div>
          </div>
          <div class="d-flex p_t8">
            <div class="p-0"><mat-icon class="accountingCheckListIcon">check</mat-icon></div>
            <div class="p-0">Mindest Vertragslaufzeit 1 Jahr</div>
          </div>
          <div class="d-flex">
            <div class="p-2">Für den endgültigen Preis, einschließlich Rabatten, <br>tritt doxfox direkt mit Ihnen in Kontakt.</div>
          </div>
        </div>
        <div class="p-2 ml-auto w-25 tlc">
          <h2 class="">XXX €</h2>
          <button *ngIf="ui.subscriptionPlanId != 3 && showSubscriptionChangeButtons" mat-flat-button color="primary" (click)="onSubscriptionRequest('Premium Plus')">Premium Plus beantragen</button>
        </div>
      </div>
    </div>
  
  </div>

  <div class="d-flex flex-column p_t25" *ngIf="showSubscriptionRequestMessage">
    <mat-toolbar class="overlay-toolbar tlc justify-content-center">
      {{locales.locales['SUBSCRIPTION_PLAN_INFO'].TITLE}}
      </mat-toolbar>
      <div class="container p_t40 p_b40">
      
        <div class="row">
          <div class="col-12 pr32 pl32">
            <p style="text-align:center; font-size:16px; font-weight:400;" >{{locales.locales['SUBSCRIPTION_PLAN_INFO'].BODY}}</p>
          </div>
        </div>
      
      </div>
  </div>
</div>