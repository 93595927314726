import { Component, OnInit, Input } from '@angular/core';
import { SectionM } from 'src/app/core/models/sectionM';;
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { SurgeryReportItenV3I } from 'src/app/core/models/v3/SurgeryReportItemIV3';
import { PositionFlatI } from 'src/app/core/models/sectionPositionM';
@Component({
  selector: 'template-op-position-item',
  templateUrl: './template-op-position-item.component.html',
  styleUrls: ['../positions.scss'],
})
export class TemplateOpPositionItemComponent implements OnInit {
  @Input() templateDocumentId: string;
  @Input() template_id: number;
  @Input() editable:boolean
  @Input() section:SectionM;
  // @Input() showAllSurgeryRelevantItems:boolean = false;
  resizeTimeout:any
  isMassnameDiabled:boolean = false

  reportItems: SurgeryReportItenV3I[] = []
  constructor(
    private tc:TemplateControllerService,
    ) { }

  ngOnInit(): void {

    if(this.editable === true){

    }
    if(this.section.templateHasSurgeryReport === true || this.section.templateHasInvoice === true){
      this.isMassnameDiabled = true
    }

  }
  
  get positions():PositionFlatI [] {
    return this.tc.positionForSectionId(this.section.section_id).sort((a,b)=> a.position_order_index - b.position_order_index)
  }


}
