import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { GoaeAusschlussI, GoaeConflictType } from 'src/app/core/models/goaeAusschlussI';
import { ChangesItem, Gnr, GOA_SMALL } from 'src/app/core/models/goaeM';
import { AlertService } from 'src/app/services/alert.service';
import { GoaServicesService } from 'src/app/services/goa-services.service';
import { GoaeChangesInfoComponent } from 'src/app/ui/overlays/goae-changes-info/goae-changes-info.component';
import * as _ from "lodash";
import { GoaDetailOverlayParams, GoaParagraph, GoaParagraphChanges } from 'src/app/core/models/goaParagraphM';
interface AddRegelAusschlussItem{
  ausschluss_type:  GoaeConflictType;
  viewValue:string;
}
@Component({
  selector: 'app-goae-detail-overlay',
  templateUrl: './goae-detail-overlay.component.html',
  styleUrls: ['./goae-detail-overlay.component.scss']
})
export class GoaeDetailOverlayComponent implements OnInit, OnDestroy {
  mode:string;
  newGoaId:string = ''
  selectedId:string;
  ausschlussitem:GoaeAusschlussI
  // gnr:Gnr
  gnrSmallItem:GOA_SMALL
  // regelCopy:string =''
  gaoeFullSubscription:Subscription
  removeGoaeItemAlertSubscription:Subscription
  removable = true;
  selectable = true;
  isLoading: boolean = false;
  isSelectGoaeIdMode: boolean = false;
  filtereGoaeOptions:GOA_SMALL[]=[];
  fullGoae:GOA_SMALL[]=[];
  selectedGoae:GOA_SMALL
  errorMessage:string
  gnrDeleteItem:string
  betrag: string ='0'
  bewertung: string ='0'
  isHistory = false
  goaItem:GoaParagraph
  changes:GoaParagraphChanges[] = []
  isAddRegelMode = false
  selectedRegelType: string;
  hasChanged = false;
  hasAddRegel = false
  addMessageIn = false
  addMessage = 'added'

  regelTypes: AddRegelAusschlussItem[] = [
      {ausschluss_type: GoaeConflictType.exclusion, viewValue: 'NICHT mit ...'},
      {ausschluss_type: GoaeConflictType.only_allowed_with, viewValue: 'NUR mit ...'},
  ];
 // regelTypes: string[] = ['exclusion', 'only_allowed_with'];
  constructor(public dialogRef: MatDialogRef<GoaeDetailOverlayComponent>,private alertService:AlertService,private dialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: GoaDetailOverlayParams, private goaS: GoaServicesService) {
      this.mode = data.mode
      this.selectedId = data.selectedId
      this.fullGoae = data.listSmall
      // this.filtereGoaeOptions = this.fullGoae
  }

  ngOnDestroy(): void {
    if(this.gaoeFullSubscription != null){
      this.gaoeFullSubscription.unsubscribe()
    }
  }

  ngOnInit(): void {
    if(this.mode == 'edit'){
      this.load(this.selectedId)
    } else {
      
    }
  }
  get regelCopy() : string {
    if(!this.goaItem) return ''

    if(!this.goaItem.ausschluss_type) { return ''}
   
    // if(this.goaItem.ausschluss_type == ) { return ''}

    return this.goaItem.ausschluss_type === GoaeConflictType.only_allowed_with ? 'Nur mit folgenden Ziffern .... ' : this.goaItem.ausschluss_type === GoaeConflictType.exclusion ? 'Kann nicht mit folgenden Ziffern abgerechnet werden ...' : ''
  }

  get hasAusschluss() : boolean {
    if(!this.goaItem) return false

    if(!this.goaItem.ausschluss_type) return false
    
    if(this.goaItem.ausschluss_type === GoaeConflictType.none) return false

    return true
  }
  private async load(goaId:string) {
    const _data = await this.goaS.getGOAFull(goaId);
    this.goaItem = _data as GoaParagraph
    const _changes = await this.goaS.getGOAFullChanges(goaId);
    this.changes = _changes as GoaParagraphChanges[]
    
    this.filtereGoaeOptions = this.fullGoae.filter((item) => item.goa_id.toString() != this.goaItem.goa_id.toString())
    // this.fullGoae.filter((item) => item.goa_id.toString() != this.goaItem.goa_id.toString())
   if(this.goaItem.ausschluss_type == null || this.goaItem.ausschluss_type == GoaeConflictType.none){
    this.goaItem.ausschluss_liste = []
   }
  //  console.log(this.goaItem)
  //  console.log(this.changes)
     
  }
  
  getDateFromTimeStamp(seconds:number):Date{
    return new Date(seconds)
  }

  modelChangeFn(e){
    this.hasChanged = true;
  }
  addGoaeAussschluss(){
    this.isSelectGoaeIdMode = true;
  }

  close(){
    this.dialogRef.close();
  }

  cancelDeleteAusschluss(){
    this.errorMessage = null
    this.gnrDeleteItem = null
  }

  deleteSelectedAusschluss(){
    if(this.gnrDeleteItem == null) return;
    // if(this.gnr.lastChanges == null){
    //   this.gnr.lastChanges = []
    // }
    // this.gnr.lastChanges.push({
    //   changesCopy:"remove " + this.gnrDeleteItem,
    //   timestamp:{seconds: new Date(Date.now()).getTime()}
    // })
   
   // 
    this._finalRemove(this.gnrDeleteItem)
  }

  private _finalRemove(item: string){
    if(this.goaItem == null || this.goaItem.ausschluss_liste == null){
      this.errorMessage = null
      this.gnrDeleteItem = null
      this.toggleSaveMode(false)
      return
    }
    
    const index = this.goaItem.ausschluss_liste.indexOf(item);
    console.log(index)
    
    
    if (index != -1) {
      this.goaItem.ausschluss_liste.splice(index, 1);
      this.saveChanges("remove " + item)
    }
 
    if(this.ausschlussitem != null){
      if(this.ausschlussitem.regel == null || this.ausschlussitem.regel.ausschluss_liste == null){
        this.ausschlussitem.regel.ausschluss_liste = []
      }
      if(this.ausschlussitem.regel.ausschluss_liste.includes(item)){
        const index2 = this.ausschlussitem.regel.ausschluss_liste.indexOf(item);
 
        if (index2 >= 0) {
          this.ausschlussitem.regel.ausschluss_liste.splice(index2, 1);
          this.updateAusschlussItem()
          
        }
      }
    }
    this.errorMessage = null
    this.gnrDeleteItem = null
    this.toggleSaveMode(true)
  }
  
  removeGOAE_Ausschluss(item: string): void {
    this.errorMessage = `Wollen sie diese Ausschlussziffer ${item} wirklich entfernen?`;
    this.gnrDeleteItem = item;
    /* this.alertService.clear();
    this.alertService.clearResult(); */
    
   /*  this.alertService.removeGOAE_ExclusionNumberAlert(); */
    /* if(this.removeGoaeItemAlertSubscription != null){
      this.removeGoaeItemAlertSubscription.unsubscribe()
    } */
 /*    this.removeGoaeItemAlertSubscription = this.alertService.getAlertResultAction()
      .subscribe(
        result => {
          
          if(result === AlertActionI.BUTTON_ACTION_TYPE.RESUME){
            console.log('result.actionType == AlertActionI.BUTTON_ACTION_TYPE.RESUME ', result)
            if(this.gnr.lastChanges == null){
              this.gnr.lastChanges = []
            }
            this.gnr.lastChanges.push({
              changesCopy:"remove " + item,
              timestamp:{seconds: new Date(Date.now()).getTime()}
            })
            this._finalRemove(item)
            this.deleteAlertSubscruption()
            
          } else {
            console.log('result.actionType == AlertActionI.BUTTON_ACTION_TYPE.RESUME  FALSE', result)
            this.deleteAlertSubscruption()
          }
          
        }
      ) */

  }  
  showHistory(){
    this.isHistory = true;
  } 
  hideHistory(){
    this.isHistory = false;
  }

  deleteAlertSubscruption(){
    setTimeout(()=>{
      if(this.removeGoaeItemAlertSubscription != null){
        this.removeGoaeItemAlertSubscription.unsubscribe()
        this.removeGoaeItemAlertSubscription = null
      }
      
    },100)
  }

  
  
  updateAusschlussItem(){
    this.goaS.updateGoaAusschlussItem(this.ausschlussitem);
    this.gnrDeleteItem = null
  }
  applyFilter(filterValue:string) {
    let numerics= ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    
    if(filterValue[0] != null){
      
      if(numerics.includes(filterValue[0])){
        this.filtereGoaeOptions = this.fullGoae.filter((item) => item.name.startsWith(filterValue) && item.goa_id.toString() != this.goaItem.goa_id.toString())
        this.filtereGoaeOptions = this.fullGoae.filter((item) => item.name.startsWith(filterValue) && item.goa_id.toString() != this.goaItem.goa_id.toString())

      } else {
        this.filtereGoaeOptions = this.fullGoae.filter((item) => item.long != null && item.long.startsWith(filterValue.trim().toLowerCase())  && item.goa_id.toString() != this.goaItem.goa_id.toString())
      }
    } else {
      this.filtereGoaeOptions = this.fullGoae.filter((item) => item.goa_id.toString() != this.goaItem.goa_id.toString())
    }
    
  } 
   displayFnGOA(item: GOA_SMALL): string {
    return item && item.name ? item.name : '';
  }
  goaeSelectChanged(event){
    this.selectedGoae = event.option.value
    // this.addMessage = `GOÄ Ziffer ${this.selectedGoae.goa_id} hinzugefügt`
    // this.addMessageIn = true
    // setTimeout(() => {
    //   this.addMessageIn = false
    // },4000)

  }

  clearSelectedGoae(){
    this.selectedGoae = null
    this.isSelectGoaeIdMode = false;
  }

  async addSelectedAusschl(){
    if(this.selectedGoae == null) return
    if(this.selectedGoae.goa_id == this.goaItem.goa_id) { 
      return 
    }

    const _full = await this.goaS.getGOAFull(this.selectedGoae.documentId);
    let _newFullItem:GoaParagraph = _full;

    if(this.goaItem.ausschluss_liste == null){

      this.goaItem.ausschluss_liste = []

    } else if(!this.goaItem.ausschluss_liste.includes(_newFullItem.goa_id)) {
      this.hasChanged = true;
      this.goaItem.ausschluss_liste.push(_newFullItem.goa_id)
      const _addedZiffer = this.selectedGoae.goa_id
      this.addMessage = `GOÄ Ziffer ${_addedZiffer} hinzugefügt`
      this.addMessageIn = true
      setTimeout(() => {
        this.selectedGoae = null
        this.isSelectGoaeIdMode = false;
      },10)
      setTimeout(() => {
        this.addMessageIn = false;
      },4000)
      // if(this.ausschlussitem != null){
      //   if(this.ausschlussitem.regel == null || this.ausschlussitem.regel.ausschluss_liste == null){
      //     this.ausschlussitem.regel.ausschluss_liste = []
      //   }
      //   if(!this.ausschlussitem.regel.ausschluss_liste.includes(this.selectedGoae.goa_id)){
      //     this.ausschlussitem.regel.ausschluss_liste.push(this.selectedGoae.goa_id)
      //     this.isLoading = true
      //   /* this.updateAusschlussItem() */
      //     await this.goaS.updateGoaAusschlussItem(this.ausschlussitem).then((x)=>{
      //       this.toggleSaveMode(true)
      //     })
      //   }
      // }
    }
      
  }
  saveItem(){
    this.dialog.open(GoaeChangesInfoComponent, {
      backdropClass:"backdrop-background",
      width:"450px",
      disableClose:true
    }).afterClosed().subscribe(
      (_changesItem:ChangesItem) => {
        if(_changesItem != null){
          this.saveChanges(_changesItem.changesCopy)
        }   
      }
    )
  }
  private async saveChanges(text:string) {
    await this.goaS.postChanges(this.goaItem.goae_full_id, text);
    this.toggleSaveMode(true)
  }
  private toggleSaveMode(starting:boolean) {
    this.isLoading = starting;
    if(starting){
      this.onSaveItem();    
    } 
  }
  onSaveItem = async () => { 
    await this.goaS.updateSingleFull(this.goaItem).then(x => {
      this.toggleSaveMode(false)
      this.dialogRef.close();
    })
  }


  checkNewGoaeId(e){
    this.newGoaId = e.target.value
  }
  get canAddNewId():boolean {
    if(!this.fullGoae){
      return false
    }
    return this.newGoaId != null  && (this.fullGoae.findIndex((item) => item.goa_id === this.newGoaId) === -1) 
    && (this.fullGoae.findIndex((item) => item.documentId === 'GOA_'+this.newGoaId) === -1)
    && this.newGoaId != '' && this.newGoaId.length > 1
  }

  async addNewOwnGoaId(){
    await this.goaS.addOwnNewGoaId(this.newGoaId);
    setTimeout(()=>{
      this.selectedId = 'GOA_' + this.newGoaId
      this.mode = 'edit'
      this.load(this.selectedId)
      
    },100)
    setTimeout(()=>{
      this.newGoaId = ''
    },500)
  }

  addRegel(){
    this.isAddRegelMode = true
    this.hasAddRegel = true
    this.hasChanged = true;
  }
  clearAddNewRegel(){
    this.selectedRegelType = null
    this.isAddRegelMode = false
    this.hasAddRegel = false
    this.goaItem.ausschluss_type = GoaeConflictType.none
    this.goaItem.ausschluss_liste = []
  }
  regelTypeChanged(e){
    this.hasChanged = true;
    this.goaItem.ausschluss_type = e.value.ausschluss_type;
    setTimeout(()=>{
      console.log(this.goaItem)
    },500)
  }
  get newRegelTypeSelected():boolean {
    return this.selectedRegelType != null
  }
}
