import { environment } from "src/environments/environment";

export enum TEMPLATES_CALL {

  TEMPLATE_OVERVIEW_ALL,
  TEMPLATE_DETAIL,
  TEMPLATE_OVERVIEW_OWN,
  RENAME_TEMPLATE,
  DELETE_TEMPLATE,
  DUPLICATE,
  CLONE_PUBLIC,
  ADD_TO_PROCESS,

  PROCESSES_OVERVIEW,
  PROCESSES_OVERVIEW_SINGLE,
  PROCESS_DETAIL,

  UPDATE_SINGLE_TEMPLATE_NODE,
  UPDATE_SINGLE_PROCESS_NODE,
  // REDUCTION_CHANGED,
  // SUMMERY_CHANGED,

  GET_LAST_MODIFIED_PROCESSES,
  GET_LAST_ADDED_PROCESSES,
  GET_LAST_UNPUBLISHED,

  POST_TEMPLATE_TRANSFER,
  PROCESS_STATE_CHANGED,




}

export function TEMPLATE_API_PATH(call: TEMPLATES_CALL, params: string = '') : string {

  switch(call){

      case TEMPLATES_CALL.TEMPLATE_OVERVIEW_ALL: return `${environment.endpoints.apiBasePathV2}templates/overview`;
      case TEMPLATES_CALL.TEMPLATE_DETAIL: return `${environment.endpoints.apiBasePathV2}templates/single/${params}`;

      case TEMPLATES_CALL.TEMPLATE_OVERVIEW_OWN: return `${environment.endpoints.apiBasePathV2}templates/overview-own`;

      case TEMPLATES_CALL.RENAME_TEMPLATE: return `${environment.endpoints.apiBasePathV2}templates/rename/${params}`;
      case TEMPLATES_CALL.DELETE_TEMPLATE: return `${environment.endpoints.apiBasePathV2}templates/delete/${params}`;

      case TEMPLATES_CALL.DUPLICATE: return `${environment.endpoints.apiBasePathV2}templates/duplicateTemplate/${params}`;
      
      case TEMPLATES_CALL.POST_TEMPLATE_TRANSFER: return `${environment.endpoints.apiBasePathV2}templates/template-action/`;

      case TEMPLATES_CALL.CLONE_PUBLIC: return `${environment.endpoints.apiBasePathV2}templates/clone-template/${params}`;

      case TEMPLATES_CALL.ADD_TO_PROCESS: return `${environment.endpoints.apiBasePathV2}templates/set-process/${params}`;

      case TEMPLATES_CALL.PROCESSES_OVERVIEW: return `${environment.endpoints.apiBasePathV2}processes/overview/`;

      case TEMPLATES_CALL.PROCESSES_OVERVIEW_SINGLE: return `${environment.endpoints.apiBasePathV2}processes/reload-from-update/${params}`;
      case TEMPLATES_CALL.PROCESS_DETAIL: return `${environment.endpoints.apiBasePathV2}processes/single/${params}`;

      case TEMPLATES_CALL.UPDATE_SINGLE_TEMPLATE_NODE: return `${environment.endpoints.apiBasePathV2}templates/update-item/${params}`;
      
      case TEMPLATES_CALL.UPDATE_SINGLE_PROCESS_NODE: return `${environment.endpoints.apiBasePathV2}processes/update-item/${params}`;

      // case TEMPLATES_CALL.REDUCTION_CHANGED: return `${environment.endpoints.apiBasePathV2}templates/reduction-changed/${params}`;

      // case TEMPLATES_CALL.SUMMERY_CHANGED: return `${environment.endpoints.apiBasePathV2}templates/summary/${params}`;

      case TEMPLATES_CALL.GET_LAST_MODIFIED_PROCESSES: return `${environment.endpoints.apiBasePathV2}processes/limited`;

      case TEMPLATES_CALL.GET_LAST_ADDED_PROCESSES: return `${environment.endpoints.apiBasePathV2}templates/last-created-processses/`;

      case TEMPLATES_CALL.GET_LAST_UNPUBLISHED: return `${environment.endpoints.apiBasePathV2}templates/limited-unpublished`;
      
      case TEMPLATES_CALL.PROCESS_STATE_CHANGED: return `${environment.endpoints.apiBasePathV2}processes/modules/states/update`;


      


    }

}
