import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { employeeI, EmployeeOverlayDataI } from 'src/app/core/models/employeeI';
import { SurgerySupportPersonI } from 'src/app/core/models/v4/surgery.personi';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { UiService } from 'src/app/services/ui.service';
import { EmployeeComponent } from '../../overlays/employee/employee.component';

@Component({
  selector: 'surgery-header-support-item',
  templateUrl: './surgery-header-support-item.component.html',
  styleUrls: ['./surgery-header-support-item.component.scss']
})
export class SurgeryHeaderSupportItemComponent implements OnInit {
  @ViewChild('_trigger') _trigger;
  @ViewChild('_selector') _selector;
  private _editable:boolean
  @Input() supportUser:SurgerySupportPersonI;
  @Input() editable:boolean;
  @Input() employees:employeeI[]
  currentUser:employeeI
  selectedUser:employeeI
  @Output() deleteSupportEvent:EventEmitter<SurgerySupportPersonI> = new EventEmitter<SurgerySupportPersonI>();
  @Output() reloadSupportUsers:EventEmitter<void> = new EventEmitter<void>();
  constructor(
    private tc:TemplateControllerService,
    public ui:UiService,
    private dialog:MatDialog
    ) { }

  ngOnInit(): void {
    this._editable = this.editable ? true : false
    this.currentUser = this.employees.find(item => item.id == this.supportUser.employee_id)
    this.selectedUser = _.cloneDeep(this.currentUser)

  }
  
  get employeeList():employeeI[]{
    return this._editable ? this.employees.filter(item => item.user_state == 'active') : this.employees;
  }
  deleteSupport(u){
    this.deleteSupportEvent.emit(u);
  }
  addPerson(){
    let _data:EmployeeOverlayDataI = {
      userPath: this.ui.account.uid
    }
    this.dialog.open(EmployeeComponent,{
      width:"70%",
      maxWidth:"1000px",
      height:"360px",
      backdropClass:"backdrop-background",
      data:_data
    }).afterClosed().subscribe(
      data=>{
        this.reloadSupportUsers.emit()
      }
    )
  }
  openedChange(e){
    // console.log('click 2', this._selector)
    // if(e == true && this._editable == false && this.editable === true){
    //   this._editable = true
    // }
    
  }
  toggleSelectClick(){
    // console.log('click ', this._selector)
    if(this._editable == false && this.editable === true){
      this._editable = true
    }
    setTimeout(()=>{
      this._selector.open()
    },120)
    
  }
  async supportUserChanged(e){
    this.selectedUser = e.value

    if(this.supportUser.id == -1) {
      let newItem:SurgerySupportPersonI =  {
        id:-1,
        employee_id: e.value.id,
        firstName: e.value.firstName,
        lastName: e.value.lastName,
        role_title: this.supportUser.role_title,
        role_id: this.supportUser.role_id
      }
      await this.tc.addSurgerySupport(this.tc.currentTemplateId,newItem)
      this.reloadSupportUsers.emit()
    } else {

      this.tc.changeSurgerySupport(this.tc.currentTemplateId, this.supportUser.id, e.value.id)
    }
    
    //changeSurgerySupport

    // this.supportUser.employee_id = e.
    // if(e.value == null){
    //   this.surgery_doc_uid = ''
    //   this.additionalSurgeryInfos.surgery_doc_uid = ''
     
    // } else {
    //   this.surgery_doc_uid = e.value
    // this.additionalSurgeryInfos.surgery_doc_uid = this.surgery_doc_uid;
    // }
    
    // this.save()
  }
}
