<mat-toolbar class="overlay-toolbar">
  <span style="font-size: 12px;">{{title}}</span>
  <span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_mini" (click)='close()'>close</mat-icon>
</mat-toolbar>
<div class="container">

  <div class="d-flex justify-content-center align-items-center" >  
    <div class="p-2" style="width: 120px">
      <input class="formControl mat-body formControlDescription fs14-400" [formControl]="modelControl"
      #_txtInput maxlength="4" (keyup)="onKeyUp($event)" >
    </div>
    <div class="p-2 counter">({{count}}/4)</div>
  </div>
</div> 

<mat-toolbar class="overlay-toolbar">
  <div class="d-flex w100 justify-content-center">
    <div class="p-2 ">
      <app-df-icon-button
      title= "Speichern"
      icon="save"
      [disabled]="!canSave"
      color="primary"
      (clicked)="save()"      
      >
      </app-df-icon-button>  
    </div>
  </div>

</mat-toolbar>