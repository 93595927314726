import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { employeeI, EmployeeOverlayDataI } from 'src/app/core/models/employeeI';
import { SurgerSupportSelectI } from 'src/app/core/models/surgerSupportSelectI';
import { SurgerySupportPersonI } from 'src/app/core/models/v4/surgery.personi';
import { SurgerySupportRoleI } from 'src/app/core/models/v4/surgerySupportRoleI';
import { AccountService } from 'src/app/services/account.service';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { UiService } from 'src/app/services/ui.service';
import { EmployeeComponent } from '../employee/employee.component';

@Component({
  selector: 'app-surgery-support-selection',
  templateUrl: './surgery-support-selection.component.html',
  styleUrls: ['./surgery-support-selection.component.scss']
})
export class SurgerySupportSelectionComponent implements OnInit {

  selectedRole
  // :SurgerySupportRoleI = {
  //   role_title: '',
  //   id:0
  // }
  selectedEmployee
  employees:employeeI[]= []
  supportRoles:SurgerySupportRoleI[] = []
  constructor(public dialogRef: MatDialogRef<SurgerySupportSelectionComponent>,
    private ui:UiService,
    public tc:TemplateControllerService,
    private dialog:MatDialog,
    private aS:AccountService,
    @Inject(MAT_DIALOG_DATA) public data: SurgerSupportSelectI) {
      this.employees = data.employees;
      // this.supportRoles = this.supportRoles 
    }

  ngOnInit(): void {
    this.supportRoles = this.aS.surgerySupportRoles
    
  }

  async save(){
    if(!this.selectedEmployee || !this.selectedRole) { return }
    let newItem:SurgerySupportPersonI =  {
      id:-1,
      title: '',
      employee_id: this.selectedEmployee.id,
      firstName:'',
      lastName:'',
      role_title:'',
      role_id: this.selectedRole.id
    }
    await this.tc.addSurgerySupport(this.tc.currentTemplateId,newItem)
    this.dialogRef.close('reload')
  }
  close(){
    this.dialogRef.close()
  }

  supportRoleChanged(e){
    
  }
  async reloadEmployees(){
    this.employees = this.aS.employees
    
  }
  employeeChanged(e){

  }
  async deleteSupportItem(item:any){
    await this.tc.removeSupportItem(item.id)
  }
  get isSaveDisabled():boolean {
    if(this.selectedEmployee && this.selectedRole){
      return false
    }
    return true
  }

  async addEmployee(){
    let _data:EmployeeOverlayDataI = {
      userPath: this.ui.account.uid
    }
    this.dialog.open(EmployeeComponent,{
      width:"70%",
      maxWidth:"1000px",
      height:"360px",
      backdropClass:"backdrop-background",
      data:_data
    }).afterClosed().subscribe(
      data=>{
       this.reloadEmployees()
      }
    )
  }
}
