import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControllerService } from 'src/app/shared/controller.service';
import { Router } from '@angular/router';
import { DxFxBreadCrumb } from 'src/app/core/models/breadCrumpIntemI';
import { UiService } from 'src/app/services/ui.service';
import { LocaleService } from 'src/app/services/locale.service';
import * as _ from 'lodash';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  unpublished_teplates = 'Unveröffentlichte Vorlagen'
  welcomeTitle:string = 'Herzlich Willkommen'
  isLoading = true;
  showCard = false
  timeout:any;
  currentDateTime:Date = new Date();

  constructor(
    public locales:LocaleService,
    private router:Router, public ui:UiService) { }

  ngOnInit(): void {
    this.startTimer();
    setTimeout(()=>{
      ControllerService.setCurrentPage('dashboard');
      let _bc:DxFxBreadCrumb = {
        bcItems: []
      }
    ControllerService.setBreadCrumbPath(_bc);
      this.isLoading = false;
      this.showCard = true;
      },1000);
    this.currentDateTime = new Date(Date.now());
  }
  
  private startTimer(){
    if(this.timeout != null){
      clearInterval(this.timeout)
    }
    this.timeout = setInterval(() =>{
      this.currentDateTime = new Date(Date.now())
    },1000)
  }

  navigate(path:string){
    this.router.navigateByUrl(path)
  }

  get currentDateTime2() :string {
    return this.currentDateTime.getHours().toString()
  }

  get helloMessage():string {
    const _hours:number = this.currentDateTime.getHours()
    if(_.inRange(_hours, 3, 12)){
      return "Guten Morgen  "// + this.ui.account.docName
    } else if(_.inRange(_hours, 12, 18)){
      return "Guten Tag  " //+ this.ui.account.docName
    } else if(_.inRange(_hours, 19, 24)){
      return "Guten Abend  " //+ this.ui.account.docName
    } else {
      return this.welcomeTitle
    }
  }

  ngOnDestroy(): void {
    if(this.timeout != null){
      clearInterval(this.timeout)
    }
    /* throw new Error('Method not implemented.'); */
  }
}
