import { EventEmitter, Injectable } from '@angular/core';
import { PositionFlatI } from '../core/models/sectionPositionM';
import * as _ from 'lodash';
import { TemplateControllerService } from './template-controller.service';
import { BASE_TEMPLATE_TYPE, POSITION_TYPE } from '../core/models/enums';
import { PositionErrorI } from '../core/models/goaeAusschlussI';
@Injectable({
  providedIn: 'root'
})
export class FooterService {

  private _errorItems:PositionErrorI[] = []
  private _positions:PositionFlatI[] = []

  summeryChangedEmitter:EventEmitter<number> = new EventEmitter<number>()

  constructor(public tc:TemplateControllerService) { 
    console.log('> F.S. INIT <')
    
    this.tc.positionsEmitter.subscribe( 
      
      
      (incoming:PositionFlatI[]) => {
        // console.log('\n\n FOOTER SUBSCRIBE');
        // console.log(incoming);
        
        this._calcWithPositions(incoming)
      })
    this.tc.itemsUpdated.subscribe(
      x => {
        this._itemsUpdated()
      }
    )  
  }

  get errorCount():number {
    return this.tc.errorItems.length
  }

  public footerAction(){
    console.log('<<<<<FOOTER ACTION>>>>>')
  }

  get reduction():number {
    if(this.tc.activeTemplate){
      if(this.tc.activeTemplate.reduction){
        return this.tc.activeTemplate.reduction
      }
    }
    return 0
  }
  private _itemsUpdated(){
    this._calcWithPositions(this.tc.templatePositions);
  }

  private _calcWithPositions(positions:PositionFlatI[]){
    var _sum = 0;
    positions.forEach(_pos => {
      if(_pos.activated && _pos.position_type == POSITION_TYPE.GOA){
        let _sumTemp = (_pos.progression_factor * _pos.goa_paragraph.amount_euro) * _pos.quantity
        _sum += _sumTemp
      }
    })

    if(this.tc.allSectionMaterialItems != null && this.tc.allSectionMaterialItems.length > 0){
      for(var k = 0; k < this.tc.allSectionMaterialItems.length; k ++){
        let matItem = this.tc.allSectionMaterialItems[k]
        let _itemAmount:number = matItem.quantity * Number(matItem.preis)
        let _itemSum = _itemAmount
        _sum += _itemSum
      }
    }
    if(this.reduction != null && this.reduction != 0){
      let _reduction =  this.reduction;
      let _tempSum = _sum * (_reduction / 100);
      _sum -= _tempSum;
      _sum =  Math.round((_sum + Number.EPSILON) *100) / 100;
    }



    this.summeryChangedEmitter.emit(_sum)
  }

  sectionIdAdd(){

  }
  positionIdsAdd(){
    
  }

  clear(){

  }
  async changeSum(summary:number){
    if(!this.tc.isTemplateEditable){
      return false
    }
    

    if(this.tc.activeTemplate.summary == summary) {
      
       
    } else {
      this.tc.activeTemplate.summary = summary
      if(this.tc.baseTemplateType == BASE_TEMPLATE_TYPE.TEMPLATE){
        console.log(`\n UPDATE SUMMARY ${summary}`)
        await this.tc.updateTemplateItem({summary:summary})
      } else if(this.tc.baseTemplateType == BASE_TEMPLATE_TYPE.PROCESS){
        // console.log(`\n UPDATE SUMMARY ${summary}`)
        await this.tc.updateProcessItem({summary:summary})
      }
    }
    
  }
}
