import { GoaParagraph } from './goaParagraphM';
import { GoaeAusschlussI } from './goaeAusschlussI';
import { POSITION_TYPE } from './enums';
import { SectionM } from './sectionM';
import { GoaJustificationI } from './goaJustificationI';


// }
export interface SECTION_DROPLIST {
  itemId:string
  order_index:number
  section:SectionM
  positions:PositionFlatI[]
}

export interface PositionDragStartConf {
  position_id:number
  position_order_index:number
  oe_index:number
  new_index:number
  indented:boolean
  orMode:boolean
}

export interface PositionOrderUpdate{
  position_id: number;
  position: PositionFlatI;
  //position_order_index: number;
  fromIndex: number
  toIndex:number
}
export interface PositionFlatI {
  position_id: number;
  section_id?:number;
  template_id?:number;
  position_type: POSITION_TYPE
  position_order_index?: number;
  example?: string;

  own_long?: string
  own_billing_item_id?:number
  activated?:boolean;

  quantity?:           number;

  progression_factor?: number;

  hasOrModeTrenner?:            boolean;
  surgery_relevant?:            boolean;
 
  // justification?:string;
  justification_items?: JustificationItemI[];

  befunde?:BefundTextItem[];

  indentedFromIndex?:  number;
  starts_new_group?:   boolean;
  goa_paragraph?:      GoaParagraph;

  hasError?: boolean;
  errorModel?: GoaeAusschlussI;
  orGroupPosIds?:number[]

  orType?: OR_TYPE
  orMarginType?:OR_MARGIN_TYPE
  startNewOrCollection?: boolean
  pos_group_id?: number
  group_index?: number



  justifications?:GoaJustificationI[]

}

export interface PositionFlatIGroup {
  id: number
  order_index:number
  isOrGroup:boolean
  positions:PositionFlatI[]
  items: ORPosFlatCollections[]
}
export interface ORPosFlatCollections {
  items: ORPosFlat[]
}
export interface ORPosFlat {
  order_index:number
  position_id:number
}
export enum OR_MARGIN_TYPE {
  NEW_BLOCK = 'NEW_BLOCK',
  NEW_GROUP = 'NEW_GROUP',
  NONE = "NONE",
}
export enum OR_TYPE {
  NONE = "NONE",
  OR = "OR",
  TRENNER = 'TRENNER',
  INDENTED = 'INDENTED',

}
export interface JustificationItemI {
  selected_progression_factor:number;
  progression_factor:number;
  text_items:JustificationTextItem[];
}

export interface JustificationTextItem{
  selected:boolean;
  text:string;
}


export interface SurgeryProtocollItem{
  selected:boolean;
  text:string;
  description?:string;
}

export interface BefundTextItem{
  selected:boolean;
  text:string;
}


export interface OR_EDITIN_MODULE {

  isEditingStartet: boolean

  startIndex?: number;
  startPosId?: number;

  lastIndex?: number;
  lastPosId?: number;

  orGroup?: OR_GROUP_THIRD
  positions?: PositionFlatI[]

}

export interface OR_GROUP_THIRD {
  startIndex: number;
  positions: OR_POSITION_THIRD[];
}

export interface OR_POSITION_THIRD {
  startsORGroup: boolean
  startsBLOCK: boolean
  hasOrModeTrenner: boolean
  position_order_index: number;
  position: PositionFlatI;
}


export class PositionsHelper {



 static cleanPositionFlat(index:number, id:number):PositionFlatI{
    let p:PositionFlatI = {
      position_id: -1,
      section_id: -1,
      template_id: -1,
      position_type: POSITION_TYPE.NONE,
      position_order_index:index,
      example: '',
      own_billing_item_id:-1,
      activated: true,
      quantity:1,
      progression_factor:2.3,

      hasOrModeTrenner: false,
      surgery_relevant: true,


      starts_new_group: false,
      goa_paragraph:null,

    }
    return p;
  }

}


export interface PositionProgressionFactorChanged{
  position:PositionFlatI;
  custom_factor_id?:number;
  factor:number;
  isPublic:boolean;
}
export interface ProgressionFactorItem{
  id:number;
  view_value:string;
  factor:number;
}

export interface PositionProgressionFactors {
  id?:number
  view_value:string;
  factor:number;
  isPublic:boolean
}





// POSITION FULL
export interface PositionFullI {

  position_id: number;
  section_id:number;
  template_id:number;
  activated:boolean;
  example: string;
  quantity: number;
  progression_factor: number;
  surgery_relevant: boolean;
  position_type: POSITION_TYPE;

  reports: PositionReport[]

  justifications: any[]

}

export interface PositionReport {
  surgery_report_item_id: number;
  is_active: boolean;
  lastModified: number;
  description: string;
  text: string;
}


