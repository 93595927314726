import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SurgerySectionI } from 'src/app/core/models/v4/surgeryPositionI';

@Component({
  selector: 'template-timeline-op-section-item',
  templateUrl: './template-timeline-op-section-item.component.html',
  styleUrls: ['../sections.scss']
})
export class TemplateTimelineOpSectionItemComponent implements OnInit {

  @Input() section: SurgerySectionI;
  @Input() templateDocumentId: string;
  @Input() editable:boolean
  // @Input() showAllSurgeryRelevantItems:boolean = false;
  @ViewChild(MatExpansionPanel, {static: true}) panel: MatExpansionPanel;
  isItemOpen:boolean = false;
  summe = ""
  constructor() { }

  ngOnInit(): void {

  }

  toggleOpenClose(){
    if(this.isItemOpen){
      this.panel.close();
    } else {
      this.panel.open();
    }
    this.isItemOpen = !this.isItemOpen;
  }
  deletePositonAtIndexCallback(e: any){

  }

  editTitle(){}
  deleteSection(){}

}
