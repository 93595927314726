
<div *ngFor='let position of positions; let i = index;' style="border-bottom: 1px solid rgba(0,0,0,0.05); padding: 5px 0px 5px 0px;"

[class.splitBG]='position.orType != OR_TYPES.NONE'
>
  <div  cdkDrag [cdkDragData]='position' (cdkDragStarted)="onPosDragStarted($event)" (cdkDropListDropped)="dropPosition($event)" [cdkDragDisabled]="true" >

    <div class="splitBG-Trenner" style="padding-left: 30%; width: 100%; overflow: hidden;" *ngIf='position.hasOrModeTrenner'>
      <div class="positionOrModeTrenner" ></div>
    </div>

    <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
    <div class="d-flex colum-drag-box-item " >

      <div class="p-2 cell pl25 pr0 pt0 _dragWidth" >
        <div *ngIf='position.hasError' matTooltipClass='errorTT' class="errorIconCell pl0 pt0" >
            <svg width="44" height="46" viewBox="0 0 44 46" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0H44L24 20.5L0 46V0Z" fill="url(#paint0_linear)"/>
              <path d="M13.432 15.44C13.3687 15.44 13.3117 15.421 13.261 15.383C13.223 15.3323 13.204 15.2753 13.204 15.212L12.957 6.928C12.957 6.86467 12.976 6.814 13.014 6.776C13.0647 6.72533 13.1217 6.7 13.185 6.7H15.408C15.4713 6.7 15.522 6.72533 15.56 6.776C15.6107 6.814 15.636 6.86467 15.636 6.928L15.351 15.212C15.351 15.2753 15.3257 15.3323 15.275 15.383C15.237 15.421 15.1863 15.44 15.123 15.44H13.432ZM14.173 19.981C13.7423 19.981 13.3813 19.8417 13.09 19.563C12.8113 19.2717 12.672 18.9107 12.672 18.48C12.672 18.0367 12.8113 17.6757 13.09 17.397C13.3687 17.1183 13.7297 16.979 14.173 16.979C14.6163 16.979 14.971 17.1183 15.237 17.397C15.5157 17.6757 15.655 18.0367 15.655 18.48C15.655 18.9107 15.5157 19.2717 15.237 19.563C14.9583 19.8417 14.6037 19.981 14.173 19.981Z" fill="white"/>
              <defs>
              <linearGradient id="paint0_linear" x1="21.5" y1="12" x2="-5.5" y2="23.5" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF4548"/>
              <stop offset="1" stop-color="#D33C3E"/>
              </linearGradient>
              </defs>
            </svg>
          </div>
        <div class="cell-drag-item pl0 p_t8 " cdkDragHandle *ngIf='!position.or_mode && !position.hasError'>
          <img src="/assets/positionTable/drag_handle-24px.svg" class='cell-drag-item-img' alt="">
        </div>
      </div>

      <div class="p-0 cell _activeWidth p_t8">
        <mat-checkbox *ngIf='!position.indented'
          class="checkbox pr16 pl8" [(ngModel)]='position.activated'
          disabled ></mat-checkbox>
        <div class="pl8">
          <svg *ngIf='position.indented'  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 15L13 21L11.58 19.58L15.17 16H4V4H6V14H15.17L11.58 10.42L13 9L19 15Z" fill="#3EC667"
            [class.split_active]='position.or_mode || position.indented'
                [class.split_unactive]='!position.or_mode && !position.indented'/>
          </svg>
        </div>
      </div>

    <!-- <div class="p-2 cell_splitWidth ">
      <div *ngIf='!position.indented'  style="width:24px; height:24px;">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 14L17.71 16.29L14.83 13.41L13.41 14.83L16.29 17.71L14 20H20V14ZM20 10V4H14L16.29 6.29L11.59 11H4V13L12.41 13L17.71 7.71L20 10Z" fill="#5796DB"
            [class.split_active]='position.or_mode'
            [class.split_unactive]='!showActiveSplitBG(section,position)' [class.split_disabled]='!position.or_mode && !position.indented'/>
        </svg>
      </div>
      <mat-checkbox *ngIf='position.indented'  color='primary' class="checkbox pr16 pl8" disabled
        [(ngModel)]='position.activated' >
      </mat-checkbox>
    </div> -->

    <!-- <div class="p-0" ></div> -->

    <div class="p-0 cell mr0 _activeWidth p_t8">
        <mat-checkbox color='accent' class=" mr0"
          [(ngModel)]='position.surgery_relevant' disabled style="text-align: right;">
        </mat-checkbox>
    </div>

    <div class="p-0 cell flex-fill  " >
      <!-- {{position.position_id}} -->
        <textarea *ngIf='position.ownBillingItem == null' placeholder="Maßnahme"
        rows="3" cols='3' class="mat-body formControl fs14-400 massnahme_copy"
        [(ngModel)]='position.example' disabled >
        </textarea>
        <textarea style="width:100%; height: 100%; background-color:rgba(0,0,0,0); border: none!important;" *ngIf='position.ownBillingItem != null'
        placeholder="Maßnahme" rows="2" cols='2' class="mat-body formControl fs14-400" [(ngModel)]='position.ownBillingItem.short_description'
            [class.enabled]='false'  disabled>
        </textarea>
    </div>


    <div class="p-2  ml-auto cell _goa_idWidth">
      <!-- {{position.position_order_index}} -->
        <span *ngIf='position.goa_paragraph != null' >{{position.goa_paragraph.goa_id}}</span>
    </div>


    <div class="p-2 cell _quantityWidth">{{position.quantity}}</div>

    <div class="p-2 cell _SUMWidth" >
      <div class="tle" >
        <mat-icon class="dxfx_icon_btn_medium " matTooltip="In die Zwischenablage kopieren"
        (click)='copyItem(position)'>content_copy</mat-icon>
        <!-- <mat-icon>content_copy</mat-icon> -->
        <!-- {{getSummery(position) | cgcurrency}} -->

      </div>
    </div>


    </div>
  </div>
  <div class="pastePosition" *ngIf="isPositionInClipBoard">
    <mat-icon class="dxfx_icon_btn_medium " matTooltip="Position einfügen"
        (click)='pasteFromClipBoard(position)'>content_paste</mat-icon>
  </div>
</div>
<div *ngIf='positions.length == 0;' style="border-bottom: 1px solid rgba(0,0,0,0.05); padding: 5px 0px 5px 0px;">
  <div class="pastePosition" *ngIf="isPositionInClipBoard">
    <mat-icon class="dxfx_icon_btn_medium " matTooltip="Position einfügen"
        (click)='pasteFromClipBoardEmpty()'>content_paste</mat-icon>
  </div>
</div>