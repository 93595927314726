import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { lla_item_type, template_lla_item } from 'src/app/core/models/api/templateUtils';
import { UiService } from 'src/app/services/ui.service';
import { PerioperativeAngabenComponent } from 'src/app/ui/overlays/perioperative-angaben/perioperative-angaben.component';

@Component({
  selector: 'app-settings-perioperative-angaben',
  templateUrl: './settings-perioperative-angaben.component.html',
  // styleUrls: ['./settings-perioperative-angaben.component.scss']
  styleUrls: ['../settings-panel.scss']
})
export class SettingsPerioperativeAngabenComponent implements OnInit,OnDestroy {

  changeHeighSubscription:Subscription
  
  _currentHeight:number = 0

  
  allTypes = [
    {
      name: "ANTIBIOSE", type:lla_item_type.ANTIBIOSE
    },
    {
      name: "LAGERUNG", type:lla_item_type.LAGERUNG
    },
    {
      name: "LOKALISIERUNG", type:lla_item_type.LOKALISIERUNG
    }
  ]
  
  selectedOpItem:lla_item_type = lla_item_type.ANTIBIOSE
  allValues = lla_item_type
  allLLA:template_lla_item[] = []
  allLLA_ANTIBIOSE:template_lla_item[] = []
  allLLA_LAGERUNG:template_lla_item[] = []
  allLLA_LOKALISIERUNG:template_lla_item[] = []
  constructor(
    private ui:UiService, 
    private dialog:MatDialog
  ) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.getLLA()
      this.changeHeighSubscription = this.ui.settingsPanelHeightChanged.subscribe(
        _newHeight =>{
          this._currentHeight = _newHeight
        }
      )
    },120)
  }
  async getLLA(){
    this.allLLA = []
    this.allLLA_ANTIBIOSE = []
    this.allLLA_LAGERUNG = []
    this.allLLA_LOKALISIERUNG = []
    const list = await this.ui.getLLAsForUser()
    // console.log(list)
    this.allLLA = list as template_lla_item[]

    this.allLLA.forEach((item)=>{
      if(item.type == "LOKALISIERUNG"){
        this.allLLA_LOKALISIERUNG.push(item)
      } else if(item.type == "LAGERUNG"){
        this.allLLA_LAGERUNG.push(item)
      } else if(item.type == "ANTIBIOSE"){
        this.allLLA_ANTIBIOSE.push(item)
      }
    }) 
  }
  get getPanelHeight():string{
    return this._currentHeight + 'px'
  }
  ngOnDestroy(): void {
    if(this.changeHeighSubscription != null){
      this.changeHeighSubscription.unsubscribe()
    }
  }
  changed(e){

  }
  addOpZeug(){
    this.editOpZeug({
      lastModified: 0,
      text: '', 
      lla_text_id: -1, 
      type: this.selectedOpItem, 
      user_id: ''
    })
  }

  editOpZeug(item:template_lla_item){
    console.log(item)
    this.dialog.open(PerioperativeAngabenComponent, {
      // disableClose:true,
      width: '550px',
      height: '134px',
      backdropClass:"backdrop-background",
      data:item
    }).afterClosed().subscribe(
      _reload => {
        if(_reload && _reload==='reload'){
          this.getLLA();
        }
      }
    )
  }
}
