<div class="container dashboad-item mh" [class.cardOut]='!showCard' [class.cardIn]='showCard'>
  <div class="d-flex dashboad-item-header p_t15 ">
    <div class="p_2 rechnungen dashboad-item-icon"></div>
    <div class="p_2 dashboad-item-title ">{{title}}</div>
    <div class="p_2 ml-auto " *ngIf='isLoading'><mat-spinner color='primary' diameter='25'></mat-spinner></div>
  </div>
  <div class="d-flex align-items-center align-content-stretch justify-content-center" *ngIf='!isLoading && isEmpty' style="position: relative;min-height:205px;">
    <div class="p-2"  *ngIf="!isUnpublishedMode">
      {{locales.locales["DASHBOAD"]["PUBLISHED_TEMPLATES_EMPTY"]}}
    </div>
    <div class="p-2" *ngIf='isUnpublishedMode'>
      {{locales.locales["DASHBOAD"]["UNPUBLISHED_TEMPLATES_EMPTY"]}}
    </div>
  </div>

  <div class="row p_t20 pl10 pr10" *ngIf='!isLoading && !isEmpty'>
    <div class="container template-list pl0 pr0" >  
      <table class="table  pl0 pr0"  cellpadding='0'  cellspacing = '0' style="padding-left: 0px!important; padding-right: 0px!important;" displayMode="flat">
      <thead style="background-color: #fff; border: none; ">
        <tr class='mat-caption tr_head  pb0'>
          <th style="width:  24px!important;">Typ</th>
          <th>Name</th>
          <th style="width: 140px!important; text-align: right;">
            <span *ngIf="isUnpublishedMode">zuletzt bearbeitet</span>
            <span *ngIf="!isUnpublishedMode">zuletzt verwendet</span>

            </th>
        </tr>
      </thead>
      <tbody>

      <tr *ngFor='let template of templates; let i = index;' class="element-row" (click)="openDetails(template.template_id)">
        <td style="width:24px!important;">
          <div *ngIf='isUnpublishedMode'>
            <div style="width:24px!important;">
              <div *ngIf="template.type === 'DOXFOX'"  class="UnPublishedShortCutDoxFoxMini" style="width:24px!important;"></div> 
              <div *ngIf="template.type === 'SCS'"  class="UnPublishedShortCutSCSMini" style="width:24px!important;">DOC</div> 
            </div>
          </div>
          <div *ngIf='!isUnpublishedMode'>
            <div  style="width:24px!important;">
              <div *ngIf="template.type === 'DOXFOX'"  class="userShortCutDoxFoxMini" style="width:24px!important;"></div> 
              <div *ngIf="template.type === 'SCS'"  class="userShortCutSCSMini" style="width:24px!important;">DOC</div> 
            </div>
          </div>

        </td>
        <td>{{template.name}}</td>
        <td style="width:140px!important; text-align: right;">{{getDateFromTS(template) | date:"dd.MM.yyyy HH:mm"}}</td>
      </tr>
      </tbody>
      </table>
    </div>
  </div>
</div>
