<div class="container ">
  <div class="row justify-content-center p_t20">
    <div class="col-10 tlc">
      <p style="font-weight: 600;">Vorlage übernehmen?</p>
    </div>
    <div class="col-10 tlc">
      Die doxfox Vorlage wird in “Meine Leistungen” kopiert und kann dort als eigene Vorlage verwendet werden.
    </div>
  </div>
  <div class="row justify-content-center p_t30">
    <div class="col-auto">
      <img src="assets/templateToProcessCopyExample.png" alt="">
    </div>
  </div>
  <div class="row justify-content-center p_t30 p_b15">
    <div class="col-auto">
      <mat-checkbox (change)='checkboxChanged($event)' ><span style="color: #ABBBC7;">Diese Meldung künftig nicht mehr anzeigen</span></mat-checkbox>
    </div>
  </div>
</div>
<mat-toolbar style="height: 64px; width: 100%; border-radius: 6px;">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-auto">
        <button mat-flat-button color='accent' (click)='save()'>Fortfahren</button>
      </div>
      <div class="col-auto">
        <button mat-flat-button (click)='close()' >Abbrechen</button>    
      </div>
    </div>
  </div>
</mat-toolbar>