import { Directive, OnInit, TemplateRef, ViewContainerRef, Input, OnChanges, Renderer2, ElementRef, SimpleChanges } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import * as _ from 'lodash';
import { UiService } from '../services/ui.service';

@Directive({
  selector: '[appAccess]'
})
export class AccessDirective implements OnInit{
  userRole: string[] = []
//   constructor(
//     private elRef: ElementRef,
//     private renderer: Renderer2,
//     private ui: UiService,
// ) {
// this.validateAccess();
// }
  constructor(
    private templateRef: TemplateRef<any>,
    private ui: UiService,
    private authService: AuthService,
    private viewContainer: ViewContainerRef
  ) { }

  @Input() 
  set appAccess(role: string[]) {
      if (!role || !role.length) {
          throw new Error('Roles value is empty or missed');
      }

      this.userRole = role;
      // console.log(this.userRole)
  }


  // ngOnChanges(changes: SimpleChanges): void {
  //   this.validateAccess();
  // }

  // private validateAccess(): void {
  //   if (this.userRole && !this.ui.hasAccess(this.userRole)) {
  //     this.renderer.setAttribute(this.elRef.nativeElement, 'disabled', 'true');
  //     this.renderer.setStyle(this.elRef.nativeElement, 'cursor', 'not-allowed');
  //   }
  // }

  ngOnInit() {
      let hasAccess = false;
      //this.authService.isLoggedIn &&
      if ( this.userRole) {
        hasAccess = this.ui.hasAccess(this.userRole)
        // hasAccess = this.ui.hasAccess(this.userRole)
        //
        // hasAccess = this.ui.account.roles.some(r => this.userRole);
      }

      if (hasAccess) {
          this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
          this.viewContainer.clear();
      }
  }
}