<div class="authWrapper" >

    <div class="authCard" #_authCard>

      <div class="row justify-content-center" style="background-color: #fff; padding-bottom:40px;">
        <div  style="text-align: center;">
          <img src="/assets/logo_blue.svg" width="80" alt="">
        </div>
      </div>

      <div class="row justify-content-center" style="background-color: #fff; padding-bottom:40px;">
        <div  style="text-align: center;">
          Passwort vergessen?
        </div>
      </div>

      <div class="formGroup">
        <input type="text" [(ngModel)]='email' class="formControl" (ngModelChange)="emailChanged($event)" placeholder="Ihre Emailadresse" [formControl]="_emailFC">
        <!-- <input type="email" class="formControl"  disabled>          -->
        <!-- [(ngModel)]='email' -->
      </div>


      <div class="formGroup">
        <button *ngIf="!loginLoading" mat-flat-button color='primary'
        style="width: 100%; height:38px;  padding-bottom: 6px;"
        (click)="resetPasswort()"  [disabled]='!cta'
        >Passwort zurücksetzen</button>
        <div *ngIf="loginLoading" class="loginButtonLoader d-flex justify-content-center align-items-center" style="width: 320px; height: 38px;  border-radius: 6px; border:1px solid #017bdf;">
          <div class="p-0">
            <mat-spinner diameter="25" class="loginSpinner"></mat-spinner>
          </div>
        </div>
      </div>


      <!-- <div class="formGroup">


        <button mat-flat-button color="accent" [disabled]='!cta'
          style="width: 100%; padding-top: 6px; padding-bottom: 6px;"
          (click)="resetPasswort()">Passwort zurücksetzen</button>
      </div> -->


    </div>

    <div class="forgotPassword" #_forgotPwContainer>
      <span  (click)='navigate("auth/sign-in")'>Zum Login</span>
    </div>
</div>
