<mat-card class="mat-card-no-shadow p0" style="height:100%!important;" [class.card-warn]="cardWarn">
  <div class="container" >
    <!-- *ngIf="currentAddress" -->

    <div class="row justify-content-between pl16 pr16 " style="padding-top: 13px!important;">
      <div class="col-12 ">
        <div class="row"><span class="mat-caption">Praxis/Klinik</span></div>
      </div>
      <div class="col-12 pl0 pr0 " style="padding-top: 0px!important;">
        <!-- *ngIf="!cardWarn" -->
        <mat-form-field appearance="outline" class="textfield100 select-form-field " >
          <mat-select (selectionChange)='addressChanged($event)' [value]="currentAddress" [disabled]='!tc.isTemplateEditable'>
            <mat-option *ngFor="let address of ui.addresses" [value]="address">
              <span *ngIf="!ui.isClinicUser">{{address.klinikName }} </span>
                <span *ngIf="ui.isClinicUser">...{{address.klinikName | slice: -10}} | {{address.behandelnder_arzt}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  </div>
</mat-card>
