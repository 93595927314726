import { environment } from "src/environments/environment";

export enum ACCOUNT_CALL {
  // // old
  // SIGN_IN = "SIGN_IN",


  // CHECK_UID = "CHECK_UID",

  GET_ROLES = "GET_ROLES",

  GET_TEMPLATE_FILTER = "GET_TEMPLATE_FILTER",

  GET_ACTIVE_TEMPLATE_FILTER = "GET_ACTIVE_TEMPLATE_FILTER",
  GET_ACTIVE_POOL_TEMPLATE_FILTER = "GET_ACTIVE_POOL_TEMPLATE_FILTER",
  TOGGLE_ACTIVE_POOL_TEMPLATE_FILTER = "TOGGLE_ACTIVE_POOL_TEMPLATE_FILTER",

  TOGGLE_TEMPLATE_FILTER = "TOGGLE_TEMPLATE_FILTER",

  GET_ACTIVE_PROCESS_STATES = "GET_ACTIVE_PROCESS_STATES",

  PROCESS_FILTER_TOGGLE = "PROCESS_FILTER_TOGGLE",

  GET_SURGERY_SUPPORT_ROLES = "GET_SURGERY_SUPPORT_ROLES",


  IS_ACTIVE_USER_WITH_EMAIL = "IS_ACTIVE_USER_WITH_EMAIL",


  ACCOUNT_UPDATE = "ACCOUNT_UPDATE",

  GET_ACCOUNT = "GET_ACCOUNT",
  // GET_PADNEXT_FACH = "GET_PADNEXT_FACH",


  DELETE_EMPLOYEE_BY_ID = "DELETE_EMPLOYEE_BY_ID",

  GET_EMPLOYEES = "GET_EMPLOYEES",

  UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE",

  CREATE_EMPLOYEE = "CREATE_EMPLOYEE",


  CREATE_NEW_ADDRESS = "CREATE_NEW_ADDRESS",

  UPDATE_ADDRESS_BY_ID = "UPDATE_ADDRESS_BY_ID",
  GET_ALL_ADDRESSES = "GET_ALL_ADDRESSES",

  // GET_CLINIC_ADDRESS_BY_ID = "GET_CLINIC_ADDRESS_BY_ID",

  GET_CLINIC_FULL = "GET_CLINIC_FULL",
  // GET_CLINIC_SECTORS = "GET_CLINIC_SECTORS",
  
  CREATE_ACCOUNTING_SECTION_POOL = "CREATE_ACCOUNTING_SECTION_POOL",
  GET_CLINIC_ACCOUNTING = "GET_CLINIC_ACCOUNTING",
  GET_ACCOUNTING = "GET_ACCOUNTING",
  // GET_ACCOUNTING_STATE = "GET_ACCOUNTING_STATE",
  // OPEN_ACCOUNTING_INVITE = "OPEN_ACCOUNTING_INVITE",
  ACCEPT_ACCOUNTING_INVITE = "ACCEPT_ACCOUNTING_INVITE",
  INVITE_TO_ACCOUNTINGPOOL = "INVITE_TO_ACCOUNTINGPOOL",
  POOL_USER_IDS_ACCOUNTANT = "POOL_USER_IDS_ACCOUNTANT",
  
  SUBSCRIPTION_REQUEST = "SUBSCRIPTION_REQUEST",
  
  CREATE_ACCOUNTING = "CREATE_ACCOUNTING",

  GET_USER_TEMPLATE_FILTER = "GET_USER_TEMPLATE_FILTER",


  TEMPLATE_POOLS_GET = "TEMPLATE_POOLS_GET",
  TEMPLATE_POOL_UPDATE = "TEMPLATE_POOL_UPDATE",
  // CREATE_ACCOUNTING = "CREATE_ACCOUNTING",
 
//   //old
//   CHECK_MAIN_ACCESS = "CHECK_MAIN_ACCESS",

// //old
//   SET_NEW_PASSWORD = "SET_NEW_PASSWORD",

//   //old
//   CHANGE_PASSWORD = "CHANGE_PASSWORD",

// //old
//   VALIDATE_FB = "VALIDATE_FB"
}

export function ACCOUNT_API_PATH(call: ACCOUNT_CALL, params: string = '') : string {

  switch(call){

      // case ACCOUNT_CALL.SIGN_IN:
      //   return `${environment.endpoints.apiBasePathV2}accounts/sign-in`;

      // case ACCOUNT_CALL.CHECK_UID:
      //   return `${environment.endpoints.apiBasePathV2}accounts/check-sign-in`;

      case ACCOUNT_CALL.POOL_USER_IDS_ACCOUNTANT:
        return `${environment.endpoints.apiBasePathV2}accounting/pooluser-ids-for-accountant`;

      case ACCOUNT_CALL.GET_ROLES:
        return `${environment.endpoints.apiBasePathV2}accounts/roles`;

      case ACCOUNT_CALL.GET_TEMPLATE_FILTER:
        return `${environment.endpoints.apiBasePathV2}templates/ui/template-filter`;


      case ACCOUNT_CALL.GET_ACTIVE_TEMPLATE_FILTER:
        return `${environment.endpoints.apiBasePathV2}templates/ui/template-filter-active`;
      case ACCOUNT_CALL.GET_ACTIVE_POOL_TEMPLATE_FILTER:
        return `${environment.endpoints.apiBasePathV2}templates/ui/template-pool-filter-active`;
      case ACCOUNT_CALL.TOGGLE_ACTIVE_POOL_TEMPLATE_FILTER:
        return `${environment.endpoints.apiBasePathV2}templates/ui/template-pool-filter-toggle`;


      case ACCOUNT_CALL.TOGGLE_TEMPLATE_FILTER:
        return `${environment.endpoints.apiBasePathV2}templates/ui/template-filter-toggle/${params}`;

      case ACCOUNT_CALL.GET_ACTIVE_PROCESS_STATES:
        return `${environment.endpoints.apiBasePathV2}templates/ui/process-filter-active`;

      case ACCOUNT_CALL.PROCESS_FILTER_TOGGLE:
        return `${environment.endpoints.apiBasePathV2}templates/ui/process-filter-toggle/`;

      case ACCOUNT_CALL.GET_SURGERY_SUPPORT_ROLES:
          return `${environment.endpoints.apiBasePathV2}ui/surgery-support-roles`;


      case ACCOUNT_CALL.IS_ACTIVE_USER_WITH_EMAIL:
        return `${environment.endpoints.apiBasePathV2}accounts/email-exists/?email=${params}`;

      case ACCOUNT_CALL.ACCOUNT_UPDATE:
          return `${environment.endpoints.apiBasePathV2}accounts/update`;

      case ACCOUNT_CALL.GET_ACCOUNT:
        return `${environment.endpoints.apiBasePathV2}accounts/`;

      // case ACCOUNT_CALL.GET_PADNEXT_FACH:
      //   return `${environment.endpoints.apiBasePathV2}accounts/pad-fach`;

      case ACCOUNT_CALL.DELETE_EMPLOYEE_BY_ID:
        return `${environment.endpoints.apiBasePathV2}accounts/employees/delete/${params}`;

      case ACCOUNT_CALL.GET_EMPLOYEES:
        return `${environment.endpoints.apiBasePathV2}accounts/employees`;

      case ACCOUNT_CALL.UPDATE_EMPLOYEE:
        return `${environment.endpoints.apiBasePathV2}accounts/employees/${params}`;

      case ACCOUNT_CALL.CREATE_EMPLOYEE:
        return `${environment.endpoints.apiBasePathV2}accounts/employees`;


        case ACCOUNT_CALL.INVITE_TO_ACCOUNTINGPOOL:
          return `${environment.endpoints.apiBasePathV2}accounting/invite-to-pool/`;

      case ACCOUNT_CALL.CREATE_NEW_ADDRESS:
        return `${environment.endpoints.apiBasePathV2}accounts/address-new`;
        case ACCOUNT_CALL.UPDATE_ADDRESS_BY_ID:
          return `${environment.endpoints.apiBasePathV2}accounts/address-update/${params}`;

      case ACCOUNT_CALL.GET_ALL_ADDRESSES:
          return `${environment.endpoints.apiBasePathV2}accounts/address-all`;
      // case ACCOUNT_CALL.GET_CLINIC_ADDRESS_BY_ID:
      //     return `${environment.endpoints.apiBasePathV2}accounts/clinic/${params}`;

          case ACCOUNT_CALL.CREATE_ACCOUNTING:
            return `${environment.endpoints.apiBasePathV2}accounting/create/`;
          
            case ACCOUNT_CALL.GET_ACCOUNTING:
            return `${environment.endpoints.apiBasePathV2}accounting/accounting-pool/`;
            
            case ACCOUNT_CALL.GET_CLINIC_ACCOUNTING:
            return `${environment.endpoints.apiBasePathV2}accounting/clinic-accounting-pool/`;
        
            case ACCOUNT_CALL.CREATE_ACCOUNTING_SECTION_POOL:
        return `${environment.endpoints.apiBasePathV2}accounting/create-clinic-section-pool/`;


          // case ACCOUNT_CALL.GET_ACCOUNTING_STATE:
          //   return `${environment.endpoints.apiBasePathV2}accounts/accounting/accounting-state/`;
          // case ACCOUNT_CALL.OPEN_ACCOUNTING_INVITE:
          //   return `${environment.endpoints.apiBasePathV2}accounts/accounting/open-invite/`;
          case ACCOUNT_CALL.ACCEPT_ACCOUNTING_INVITE:
            return `${environment.endpoints.apiBasePathV2}accounting/accept-invite/${params}`;
            
            // case ACCOUNT_CALL.CHECK_MAIN_ACCESS:
            //    return `${environment.endpoints.apiBasePathV2}accounts/doc-access/`;
            
            
            // case ACCOUNT_CALL.SET_NEW_PASSWORD:
            //    return `${environment.endpoints.apiBasePathV2}accounts/set-new-password/`;
            // case ACCOUNT_CALL.CHANGE_PASSWORD:
            //    return `${environment.endpoints.apiBasePathV2}accounts/changePassword/`;
            // case ACCOUNT_CALL.VALIDATE_FB:
            //    return `${environment.endpoints.apiBasePathV2}accounts/validate_fb/`;


      case ACCOUNT_CALL.GET_CLINIC_FULL:
        return `${environment.endpoints.apiBasePathV2}accounts/clinic/full/`;
        case ACCOUNT_CALL.SUBSCRIPTION_REQUEST:
          return `${environment.endpoints.apiBasePathV2}accounts/subscriptionplan/`;
          
          case ACCOUNT_CALL.GET_USER_TEMPLATE_FILTER:
      return `${environment.endpoints.apiBasePathV2}templates/pool/filter`;
    
    
    
          case ACCOUNT_CALL.TEMPLATE_POOLS_GET:
      return `${environment.endpoints.apiBasePathV2}templates/pool/`;
    
       case ACCOUNT_CALL.TEMPLATE_POOL_UPDATE:
            return `${environment.endpoints.apiBasePathV2}templates/pool/${params}`;
            
      // case ACCOUNT_CALL.GET_CLINIC_SECTORS:
      //   return `${environment.endpoints.apiBasePathV2}accounts/clinic/clinic-sectors/`;

        
    }

}
