import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AccountI, IAccountingPool, IAccountingPoolUser, IAccountantUserPool, IClinicSectorFull, IAccountantFull } from 'src/app/core/models/accountI';
import { AccountService } from 'src/app/services/account.service';
import { ACCOUNT_API_PATH, ACCOUNT_CALL } from 'src/app/services/enums/accountS.callEnum';
import { UiService } from 'src/app/services/ui.service';
import { AccountingUserDetailOverlayComponent, AccountingUserDetailOverlayComponentData } from 'src/app/ui/overlays/accounting-user-detail-overlay/accounting-user-detail-overlay.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import * as _ from "lodash";
import { LocaleService } from 'src/app/services/locale.service';
import { SubscriptionPlanOverlayComponent } from 'src/app/ui/components/subscription-plan-overlay/subscription-plan-overlay.component';
@Component({
  selector: 'account-accounting-tap',
  templateUrl: './account-accounting-tap.component.html',
  styleUrls: ['../account.styles.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AccountAccountingTapComponent implements OnInit {

  private _clinicSectorsFull: IClinicSectorFull[] = []
  @Input() account:AccountI
  dataSource:any 
  clinicDataSource:any 
  sectorataSource:any 
  description = ''
  isAddAccountingMode = false
  errorMessage = null
  isViewLoaded = false
  loading = false
  clincAccontingPools:IAccountingPool[] = []
  @ViewChild(MatSort, {static:true}) sort: MatSort;  
  displayedColumns = ['firstName','lastName','email','role','accounting_state'];
  // clinicDisplayedColumns = ['fachbereich','firstName','lastName','email','role','accounting_state'];
  clinicDisplayedColumns = ['userName','email','accounting_state', 'fachbereich', 'countDocs','action'];
  fachbereichDisplayedColumns = ['fachbereich','countDocs','countAccountant','action'];
  columnsToDisplayWithExpand = [...this.fachbereichDisplayedColumns, 'expand'];
  expandedElement: IClinicSectorFull | null;

  accountantsFull:IAccountantFull[] = []
  _clinicAccountantUserPools: IAccountantUserPool[] = []
  
  accountingListView = 'type'

  constructor(
    public locales: LocaleService,
    public ui: UiService,
    private as: AccountService, 
    private http: HttpClient,
    public dialog: MatDialog) {
  }

  ngOnInit(): void {
    console.log("AccountAccountingTapComponent INIT")
    if(this.ui.isClinicUser) {
      this.checkClinicAccounting()
      this.loadAccounting()
    } else {
      this.ui.loadAccounting()
      this.checkAccounting()
    }
   
  }

  get showCreateACcountingButton() : boolean {
    if(this.ui.isClinicUser == true) {
      if(!this._clinicAccountantUserPools || this._clinicAccountantUserPools.length == 0) {
        return true
      } else {
        return false
      }
    } else {

      if(this.ui.subscriptionPlanId > 1 && this.ui.accountingPool == null) {
        return true
      } else {
        return false
      }
    }
  }

  private checkAccounting() {
    console.log("AccountAccountingTapComponent checkAccounting")
    if(this.ui.accountingPool && this.ui.accountingPool.users){

       this.dataSource = new MatTableDataSource(this.ui.accountingPool.users);
       this.isViewLoaded = true
      
    } else {
      setTimeout(()=>{
        this.checkAccounting()
      },1000)
    }
  }
  
  onViewChaged(e:any) {
    console.log(e);
    console.log(this.accountingListView);
    
  }
  getFachbereichTitle(user:IAccountingPoolUser): string {
    const index = this.clinicSectorsFull.findIndex(item => item.accounting_user_pool_id == user.accounting_user_pool_id)
    if(index != -1){
      return this.clinicSectorsFull[index].title
    }
    return ""
  }

  private checkClinicAccounting() {
    console.log("AccountAccountingTapComponent checkClinicAccounting")
    if(this.clinicSectorsFull.length){
      
      console.log(this.clinicSectorsFull)
      //  this.clinicDataSource = new MatTableDataSource(this.ui.clinicSectorsFull);
      
    } else {
      setTimeout(()=>{
        this.checkClinicAccounting()
      },1000)
    }
  }

  toggleAccountingMode(){
    if(this.ui.accountingAvailable){
      this.description = ''
      this.isAddAccountingMode = !this.isAddAccountingMode
    } else {
      this.errorMessage = "Ihr Abo ist leider nicht für Abrechnung geeignet"
      setTimeout(()=> {
        this.errorMessage = null
      },6000)
    }
    
  }

  // saveChanged(){
  //   if(this.canCreate) {
  //     this.createNewAccounting()
  //   }
  // }

   async createNewAccounting() {
    this.loading = true
    this.isAddAccountingMode = false
    const name = this.ui.account?.firstName + ' ' + this.ui.account?.lastName
    const dto = {
      description: `Abrechnung ${name}`
    }
    await this.as.apiPostObject(ACCOUNT_CALL.CREATE_ACCOUNTING, {dto: dto})
    // this.reload.emit()
    this.ui.loadAccounting()
    setTimeout(() => {
      this.loading = false
      this.isAddAccountingMode = false
      
      if(this.ui.isClinicUser) {
        this.checkClinicAccounting()
        this.loadAccounting()
      } else {
        this.checkAccounting()
      }
    },1000)

  }

  inviteUser(role: string){
    let _isClinicMode =  this.ui.isClinicUser
    let accountingPoolId = null
    if(_isClinicMode) {
      if(this.clincAccontingPools && this.clincAccontingPools.length > 0) {
        accountingPoolId = this.clincAccontingPools[0].accounting_pool_id
      }
    }
    let _data:AccountingUserDetailOverlayComponentData = {
      accountingPool: _isClinicMode ? null : this.ui.accountingPool,
      inviteRole: role,
      isClinicMode: _isClinicMode, 
      neetToCreateClinicSectionPool: false,
      clinicAccountantUserPools: this.clinicAccountantUserPools
    }
    
    this.dialog.open(AccountingUserDetailOverlayComponent,{
      width:"70%",
      maxWidth:"1000px",
      height:"360px",
      backdropClass:"backdrop-background",
      data:_data
    }).afterClosed().subscribe(
      data=>{
        // this.reload.emit()
        this.ui.loadAccounting()
        setTimeout(() => {
          if(this.ui.isClinicUser) {
            this.checkClinicAccounting()
            this.loadAccounting()
          } else {
            this.checkAccounting()
          }
        },500)
      }
    )
  }
  openDetails(row) {
    
  }

  // get canCreate() : boolean {
  //   return this.description.length > 4
  // }

  get clinicSectorsFull(): IClinicSectorFull[] {
    return this._clinicSectorsFull
  }
  get clinicAccountantUserPools(): IAccountantUserPool[] {
    return this._clinicAccountantUserPools
  }

  private async loadAccounting()  {

    const _sectorsR = await this.as.apiGet(ACCOUNT_CALL.GET_CLINIC_FULL)
    const _response = _sectorsR as IClinicSectorFull[]
    this._clinicSectorsFull = _response
    // this.sectorataSource = new MatTableDataSource(this._clinicSectorsFull);
    // this.clinicDataSource = new MatTableDataSource(this._clinicSectorsFull);
    this.http.get<any>(ACCOUNT_API_PATH(ACCOUNT_CALL.GET_CLINIC_ACCOUNTING))
    .pipe()
    
    .subscribe(
      (_data: any) => {
  
        if (_data.status == 'ERROR') {
    
        } else {
          // this.setAccountingPool(_data as IAccountingPool)
            // this.clincAccontingPools = _data as IAccountingPool[]
            this._clinicAccountantUserPools = _data as IAccountantUserPool[]
            console.log(this._clinicAccountantUserPools);
            this.sectorataSource = new MatTableDataSource(this._clinicAccountantUserPools);

            this.accountantsFull = []
            var _accountantsFull: IAccountantFull[] = []

            for(let accUserPool of this._clinicAccountantUserPools) {
              for(let accountant of accUserPool.accountants) {
                const index = _accountantsFull.findIndex(item => item.uid == accountant.uid)
                if(index != -1) {
                  _accountantsFull[index].description = `${_accountantsFull[index].description} | ${accUserPool.description}`,
                  _accountantsFull[index].docs = _.concat(_accountantsFull[index].docs, accUserPool.docs)
                  
                } else {

                  _accountantsFull.push({
                    uid: accountant.uid,
                    firstName: accountant.firstName,
                    lastName: accountant.lastName,
                    email: accountant.email,
                    accounting_state: accountant.accounting_state,
                    id: accUserPool.id,
                    accounting_user_pool_id: accUserPool.accounting_user_pool_id,
                    description: accUserPool.description,
                    docs: accUserPool.docs
                  })
                }
              }
            }
            this.accountantsFull = _accountantsFull
            this.clinicDataSource = new MatTableDataSource(this.accountantsFull);
            // var _users:IAccountingPoolUser[] = []
          //   this.clincAccontingPools.forEach(p => {
          //   if(p.users){
          //     p.users.forEach(u => {
          //       if(u.role != "CLINIC_SECTOR") {
          //         _users.push(u)
          //       }
                
          //     })  
          //   }
          // })

          // this.clinicDataSource = new MatTableDataSource(_users);
          this.isViewLoaded = true
          // this.dataSource = new MatTableDataSource(this.ui.accountingPool.users);
        }
        // this.loadingAccounting = false
        // accountingPool
      }
    )
  }

  addAccountantForClinic(sector: IClinicSectorFull){
    // console.log(sector);
    // return
    // var neetToCreateClinicSectionPool = false
    // var _pool: IAccountingPool = {
    //   accounting_pool_id: -1,
    //   accounting_user_pool_id: -1, 
    //   clinic_sector_id: "",
    //   description: ""
    // }

    // if(this.clincAccontingPools && this.clincAccontingPools.length > 0) {
    //   const index = this.clincAccontingPools.findIndex(item => item.accounting_user_pool_id ==  sector.accounting_user_pool_id)
    //   if(index != -1) {
    //     const _element = this.clincAccontingPools[index]
    //     if(_element.clinic_sector_id) {
    //       _pool.clinic_sector_id = _element.clinic_sector_id
    //     }
    //     _pool.description = sector.title
    //     _pool.accounting_pool_id = this.clincAccontingPools[index].accounting_pool_id
    //     _pool.accounting_user_pool_id = this.clincAccontingPools[index].accounting_user_pool_id
  
    //   } else {
    //     if(sector.user_pool_id) {
    //       _pool.clinic_sector_id = sector.user_pool_id
    //       _pool['create_accounting_user_pool'] = true
    //       neetToCreateClinicSectionPool = true
    //     }
    //     _pool.description = sector.title
    //   }
      
    // }else {
    //   if(sector.user_pool_id) {
    //     _pool.clinic_sector_id = sector.user_pool_id
    //     _pool['create_accounting_user_pool'] = true
    //     neetToCreateClinicSectionPool = true
    //   }
    //   _pool.description = sector.title
    // }
   

    
    // let _data:AccountingUserDetailOverlayComponentData = {
    //   accountingPool: _pool,
    //   inviteRole: "ACCOUNTANT",
    //   isClinicMode: true,
    //   neetToCreateClinicSectionPool: neetToCreateClinicSectionPool,
    //   clinicSectors: this.clinicSectorsFull

    // }
    // this.dialog.open(AccountingUserDetailOverlayComponent,{
    //   width:"70%",
    //   maxWidth:"1000px",
    //   height:"360px",
    //   backdropClass:"backdrop-background",
    //   data:_data
    // }).afterClosed().subscribe(
    //   data=>{
    //     setTimeout(() => {
    //       this.loadAccounting()
    //     },1000)
    //   }
    // )
  }
  getDocsToolTipUserFull(element: IAccountantFull) {
    var outPut = ''
    for(let doc of element.docs) {
      outPut += `${doc.title} ${doc.firstName} ${doc.lastName} \n`
    }
    return outPut;
  }
  getDocsToolTip(element: IAccountantUserPool) {
    var outPut = ''
    for(let doc of element.docs) {
      outPut += `${doc.title} ${doc.firstName} ${doc.lastName} \n`
    }
    return outPut;
  }
  getAccountantsToolTip(element: IAccountantUserPool) {
    var outPut = ''
    for(let doc of element.accountants) {
      outPut += `${doc.firstName} ${doc.lastName} ${doc.email} \n`
    }
    return outPut;
  }
//   getTooltipText(element: IAccountantUserPool) {
//     var outPut = ''
//     for(let doc of element.docs) {
//       outPut += `${doc.firstName} ${doc.lastName} \n`
//     }
//     // return `klaus ${element.description}
//     // klaus ${element.description}
//     // klaus ${element.description}`
//     return outPut;

// }
  openFullplanOverlay(){
    this.dialog.open(SubscriptionPlanOverlayComponent, {
      width:"70%",
      maxWidth:"1200px",
      minHeight:"560px",
      backdropClass:"backdrop-background",
    })
      //   width: '400px',
  }


}
