<div class="d-flex align-items-center" style="width: 142px; height: 50px;">
  <div class="p-0">
    <mat-icon class='justification_arrow_icon' [class.enabled]='editable' *ngIf="editable && itemCount > 1"
    (click)='prevElement()'>keyboard_arrow_left</mat-icon>
  </div>

  <div class="p-0  " style="font-size:14px; font-weight:300;" *ngIf="editable && itemCount > 1">
    <span style="color: #1976D2; font-weight:500;">{{currentIndex + 1}}</span>
    <span> von {{itemCount}}</span>
  </div>

  <div class="p-0">
    <mat-icon class='justification_arrow_icon' [class.enabled]='!activated' *ngIf="editable && itemCount > 1"
    (click)='nextElement()'>keyboard_arrow_right</mat-icon>
  </div>

  <div class="p-0 ">
    <mat-icon class='justification_arrow_icon_mini p_t2' [class.enabled]='!activated' *ngIf="editable && showAdd"
    (click)='addElement()'>add</mat-icon>
  </div>

  <div class="p-0 ">
    <mat-icon class='justification_arrow_icon_mini p_t2' [class.enabled]='!activated' *ngIf="editable && showRemove"
    (click)='deleteElement()'>delete</mat-icon>
  </div>
</div>