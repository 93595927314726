import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { API_POST_MODE } from '../core/models/enums';
import { HealthInsuranceM } from '../core/models/healthinsurance';
@Injectable({
  providedIn: 'root'
})
export class PatientService {
   
  private _insurances:HealthInsuranceM[] = null
  private _url = environment.endpoints.apiBasePathV2 + 'patients/healthinsurances'
  
  constructor(
    
    private http:HttpClient ) {
    // if(this.authS.getUser() != null){
    //   this.userPath = this.authS.getUser()['uid'];
      
    // }
  }

  async getEnsurances(){
    if(this._insurances && this._insurances.length) { return this._insurances}

    else {
      const result = await this.http.get(this._url).toPromise()
      this._insurances = []
      this._insurances = result as HealthInsuranceM[]

      return this._insurances;
    }
  }

  
  async patientDisplayName(patientId:any) {

  }

  async allFlat(uid:string) {
    const _result=  await this.http.get(`${environment.endpoints.apiBasePathV2}patients/all-flat/${uid}`).toPromise()
    
    return _result
  }



  /**
   * 
   * 
   * GET SINGLE PATIENT FULL
   * 
   * 
   */
  async getSingle(patId:any) {
    const _path = environment.endpoints.apiBasePathV2 + 'patients/single/'+patId
    const _result=  await this.http.get(_path).toPromise()
    // console.log('getSingle > result', _result)
    return _result['result'] != null && _result['result'][0] != null ? _result['result'][0] : null
  }


  /**
   * 
   * 
   * GET SINGLE PATIENT FLAT
   * 
   * 
   */
  async singlePatientFlat(patId:any) {
    const _path = environment.endpoints.apiBasePathV2 + 'patients/single-flat/'+patId
    const _result=  await this.http.get(_path).toPromise()
    // console.log('singlePatientFlat > result', _result)
    return _result['result'] != null && _result['result'][0] != null ? _result['result'][0] : null
  }

  /**
   * 
   * 
   * POST PATIENT
   * 
   * 
   */

  async postPatient(mode:API_POST_MODE, payload:any, uid: string) {

    const _path = 
    (mode == API_POST_MODE.ADD_NEW) ? (environment.endpoints.apiBasePathV2  + `patients/create/${uid}`) : (environment.endpoints.apiBasePathV2  + `patients/update/${uid}`)
    const result = await this.http.post(_path, payload).toPromise()
    return result['result']
    
  }

}

