import { Injectable } from '@angular/core';

import { AccountI } from '../core/models/accountI';
import { employeeI } from '../core/models/employeeI';

import { HttpClient } from '@angular/common/http';
import {TreatmentRegion} from '../core/models/treatmentRegionM';
import {TreatmentType} from '../core/models/treatment_typeM';
import { environment } from 'src/environments/environment';
import { TokenstorageService } from './tokenstorage.service';
import { SurgerySupportRoleI } from '../core/models/v4/surgerySupportRoleI';
import { InvoiceModelI } from '../core/models/v4/invoice/invoiceModel';
import { ACCOUNT_API_PATH, ACCOUNT_CALL } from './enums/accountS.callEnum';
import { ControllerService } from '../shared/controller.service';
import { IAuthResponse } from '../core/models/api/auth.response.mode';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private _account:AccountI
  private _endPoint:string = environment.endpoints.apiBasePathV2;
  private _surgerySupportRoles: SurgerySupportRoleI[] = []

  treatmentTypes:TreatmentType[] = [];
  treatmentRegions:TreatmentRegion[] = [];
  _employees: employeeI[] = []

  get employees() : employeeI[] {
    return this._employees
  }

  get surgerySupportRoles(): SurgerySupportRoleI[] {
    return this._surgerySupportRoles
  }

  constructor(

    private http:HttpClient,
    private ts:TokenstorageService
    ) { }



    /**
     *
     * minimallistics
     *
     *
     */
    async apiGet(call:ACCOUNT_CALL, params:string = ''){
      const _path = ACCOUNT_API_PATH(call) + params
      const _res = await this.http.get(_path).toPromise()
      if (_res['status'] && _res['status'] == "ERROR" && _res['type']){
        ControllerService.sessionTimeoutEmitter.emit(_res['type'])
        // switch(_res['type']) {
        //   case "WRONG_PW":
        //     ControllerService.snack.emit("Password ist falsch")
        //     //ControllerService.sessionTimeoutEmitter.emit(_res['type'])
        //     return;
        //     case "NO_PW_IN_DB":
        //       return _res;
        //       default:
        //       return _res;
        // }

       return
      }
      return _res
    }

    async apiPostObject(call:ACCOUNT_CALL, payload:Object){
      const _path = ACCOUNT_API_PATH(call)
      const _res = await this.http.post(_path,payload).toPromise()
      if (_res['status'] && _res['status'] == "ERROR" && _res['type']){
        // ControllerService.sessionTimeoutEmitter.emit(_res['type'])
        switch(_res['type']) {
          case "WRONG_PW":
            ControllerService.snack.emit("Password ist falsch")
            //ControllerService.sessionTimeoutEmitter.emit(_res['type'])
            return;
            case "NO_PW_IN_DB":
              return _res;
              default:
              return _res;
        }
        return;
      }
      return _res
    }

    async apiPostObjectAndParams(call:ACCOUNT_CALL, params:string, payload:Object){
      const _path = ACCOUNT_API_PATH(call) + params
      const _res = await this.http.post(_path,payload).toPromise()
      if (_res['status'] && _res['status'] == "ERROR" && _res['type']){
        ControllerService.sessionTimeoutEmitter.emit(_res['type'])
        return;
      }
      return _res
    }

    async apiPutObject(call:ACCOUNT_CALL, payload:Object){
      const _path = ACCOUNT_API_PATH(call)
      const _res = await this.http.put(_path,payload).toPromise()
      if (_res['status'] && _res['status'] == "ERROR" && _res['type']){
        ControllerService.sessionTimeoutEmitter.emit(_res['type'])
        return;
      }
      return _res
    }
    /**
     *
     * @param email
     * @param pw
     * @returns
     */



    // ----- LOGIN
    async apiLogin(call:ACCOUNT_CALL, payload:Object): Promise<IAuthResponse> {
      const _path = ACCOUNT_API_PATH(call)
      const _res = await this.http.post(_path,payload).toPromise()
      return _res as IAuthResponse
    }



    

/**
 *
 * SURGERY SUPPORT ROLES
 *
 *
 *
 */

  async getSurgery_support_roles() {
    const res = await this.apiGet(ACCOUNT_CALL.GET_SURGERY_SUPPORT_ROLES)
    .then(_data => {
      return _data
    })
    this._surgerySupportRoles = res as SurgerySupportRoleI[]
  }


// TODO ? needed?
  async getManagedAccounts(){
    const _path = this._endPoint + 'accounts/managed'
    const accounts = await this.http.get(_path).toPromise().then(
      _data => {
        return _data;
      }
    )
    return accounts;
  }


  get account(){
    return this._account
  }


  async getEmployees(forceRoload:boolean = false){
    console.log('getEmployees');
    
    if(!forceRoload){
      return this._employees;
    }
    const arrItems = await this.apiGet(ACCOUNT_CALL.GET_EMPLOYEES)
    this._employees = arrItems as employeeI[]
    return this._employees;
  }



  async postEmployee(employee:employeeI) {
    if(employee.id == -1){
      await this.apiPutObject(ACCOUNT_CALL.CREATE_EMPLOYEE, employee)
      .then(
        _data => {
          this.getEmployees(true);
        }
      )
    } else {
      await this.apiPostObjectAndParams(ACCOUNT_CALL.UPDATE_EMPLOYEE,employee.id.toString(), employee)
      .then(
        _data => {
          this.getEmployees(true);
        }
      )
    }

  }


  async createInvoice(p:object){
    const _path = this._endPoint + "invoice/create-invoice/"
    const message = await this.http.post<number>(_path, p).toPromise()
    return message;
  }

  async getInvoiceById(invoice_id:number){
    const _res = await this.http.get<InvoiceModelI>(`${this._endPoint}invoice/invoice-by-id/${invoice_id}`).toPromise()
    return _res;
  }
  async getInvoiceItemsById(invoice_id:number){
    const _res = await this.http.get<any[]>(`${this._endPoint}invoice/invoice-items-by-id/${invoice_id}`).toPromise()
    return _res;
  }
  async getLastInvoiceNumber(){
    const _res = await this.http.get<number>(`${this._endPoint}invoice/last-number`).toPromise()
    return _res;
  }
  async getLastInvoiceByProcessId(processId:number){
    const _res = await this.http.get<InvoiceModelI>(`${this._endPoint}invoice/last-invoice/${processId}`).toPromise()
    return _res;
  }
  async invoicesByProcessId(processId:number){
    const _res = await this.http.get<InvoiceModelI[]>(`${this._endPoint}invoice/invoices-by-process/${processId}`).toPromise()
    return _res;
  }




}



