<mat-toolbar class="overlay-toolbar">
    <span style="font-size: 12px;">{{title}}</span>
    <span class="toolbar-spacer"></span>
    <mat-icon class="dxfx_icon_btn_mini" (click)='close()'>close</mat-icon>
  </mat-toolbar>


  <div class="container big" style="height: calc(100% - 88px);">
    <div class="d-flex  justify-content-center align-items-center h100">
      <div class="p-2" [innerHTML]="body">
      </div>
    </div>
  </div>

  <mat-toolbar class="overlay-toolbar">
    <div class="d-flex w100 justify-content-center">
      <div class="p-2" *ngIf="cancelBtn">
        <app-df-icon-button title= "{{cancelBtn}}" icon="close" (clicked)="close()"></app-df-icon-button>
      </div>
      <div class="p-2" *ngIf="resumeBtn">
        <app-df-icon-button title= "{{resumeBtn}}" icon="check" color="primary" (clicked)="resume()"></app-df-icon-button>
      </div>
    </div>
  
  </mat-toolbar>