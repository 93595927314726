import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-singleline-text-item',
  templateUrl: './add-singleline-text-item.component.html',
  styleUrls: ['./add-singleline-text-item.component.scss']
})
export class AddSinglelineTextItemComponent implements OnInit {
  textEntry: string = ''
  title:string = ''
  constructor(private dialogRef:MatDialogRef<AddSinglelineTextItemComponent>,@Inject(MAT_DIALOG_DATA) public data: string[] ) {
    this.textEntry = data[0]
    this.title = data[1]
   }

  ngOnInit(): void {
    
  }
  textInput(e){
    this.textEntry = e.target.value
   
  }
  close(){
    this.dialogRef.close()
  }

  get saveEnabled():boolean{
    return this.textEntry.length > 0 
  }
  save(){
    this.dialogRef.close(this.textEntry)
  }
}
