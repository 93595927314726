<!-- <div class="loader-box"  *ngIf='isLoading'>
  <div class="loader">......</div>
</div> -->

<div [class.accessDenied]="accessDeniedHidden" [class.accessDeniedHidden]="!accessDeniedHidden">
  <p>Sie haben keinen Zugriff für diese Vorlage</p>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M466.5 83.71l-192-80C269.6 1.67 261.3 0 256 0C250.7 0 242.5 1.67 237.6 3.702l-192 80C27.7 91.1 16 108.6 16 127.1c0 257.2 189.2 384 239.1 384c51.1 0 240-128.2 240-384C496 108.6 484.3 91.1 466.5 83.71zM256 446.5l.0234-381.1c.0059-.0234 0 0 0 0l175.9 73.17C427.8 319.7 319 417.1 256 446.5z"/></svg>
</div>

<div class="container-fluid p_t20 template-detail-wrapper pl0 pr0"  [class.CONTENTOUT]='fadeOut' [class.CONTENTIN]='!fadeOut' style="margin-bottom: 60px;">



  <div *ngIf='template.activeTemplate'>

    <div class="container-fluid p_b10  template-inner-wrapper ">
      <div class="d-flex m_t10 pr0 mr0">

        <div class="p-2 d-flex align-items-start pl0 ">
          <div class="iconShortCut" [ngStyle]='{"background-color": template.activeTemplate.iconColor}'>
            <div *ngIf="template.activeTemplate.shortCut">
             
              {{template.activeTemplate.shortCut}}
            </div>
            <div *ngIf="!template.activeTemplate.shortCut" >
             <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M12.1572 0.0580589C11.9421 -0.0484546 11.7269 0.00480216 11.5655 0.164572L6.93931 4.74465L5.59448 3.73278L0.86069 0.111316C0.69931 0.00480216 0.484138 -0.0484546 0.322759 0.0580589C0.107586 0.164572 0 0.324343 0 0.53737V9.37799C0 9.53776 0.0537931 9.69753 0.215172 9.80405L5.86345 14.5439C5.97103 14.6504 6.07862 14.6504 6.18621 14.6504C6.29379 14.6504 6.45517 14.5972 6.50897 14.5439L12.2648 9.80405C12.3724 9.69753 12.48 9.53776 12.48 9.37799V0.484113C12.48 0.324343 12.3186 0.111316 12.1572 0.0580589ZM11.4041 1.81553V8.09983L7.8 5.38374L11.4041 1.81553ZM1.07586 1.6025L11.2428 9.27148L8.17655 11.7745H4.24966L1.07586 9.11171V1.6025Z" fill="#FAFAFB"/>
               </svg>
               
            </div>
       </div>
       
        </div>

        <div class="p-2 template-headline prelative" [class.template_headline_disabled]="!template.canRenameTemplate" >
          <div class="template_headline_edit_backdrop"  (click)='renameTemplate()'>
            <div class="d-flex flex-column align-items-center justify-content-center h100">
              <div class="p-0 custom_headline_tooltip">{{template.templateNameTooltip}}</div>
            </div>
          </div>
          {{template.activeProcess.name}}
        </div>


        <div class="p-2  pr0 mr0 ml-auto"  >
          
          <div class="col-auto  p0 pr0 mr0" *ngIf="template.activeTemplate.privacy === 'PUBLISHED_PUBLIC' &&  !ui.hasTemplateAdminRole">
            <button mat-raised-button color="primary" class="m_b15" (click)="startCopyTemplate()" matTooltipClass='sectionGoaeTT' matTooltipPosition='above' matTooltip='Öffentliche Vorlage zu *Meinen Vorlagen hinzufügen*'>
              <mat-icon style="font-size: 14px;  margin-right: 4px;" class="pl0 ml0"  inline=true>cloud_download</mat-icon>
              <span  inline=true>Zu meinen Vorlagen hinzufügen</span>
            </button>
          </div>

          <div class="col-auto  p0 pr0 mr0"  *ngIf="template.activeTemplate.privacy === 'USER_OWN' &&  !ui.hasTemplateAdminRole">
            <div class="d-flex align-items-center add-button pr0" (click)='addToProcess()' >
              <div class="p-2  btn-copy pl12 p_t5 p_b5">Verwenden</div>
              <div class="p_2 align-text-end ml-auto">
                <mat-icon class="nav-icon-mini">edit</mat-icon>
              </div>
            </div>
          </div>
        </div>

        <!-- PUBLISH / UNPUBLISH IF TEMPLATE ADMIN ROLE -->
        <div class="p-2 pr0 mr0" >
          <div class="col-auto  p0 pr0 mr0" *ngIf="ui.hasTemplateAdminRole && template.activeTemplate.isEditable" >
            <div class="d-flex align-items-center add-button pr0" (click)='asyncOP("publishT")' *ngIf="template.activeTemplate.privacy === 'UNPUBLISHED_PUBLIC'">
              <div class="p-2  btn-copy pl12 p_t5 p_b5">Veröffentlichen</div>
              <div class="p_2 align-text-end ml-auto">
                <mat-icon class="nav-icon-mini" >public</mat-icon>
              </div>
            </div>
            <div class="d-flex align-items-center add-button pr0" (click)='asyncOP("unpublishT")' *ngIf="template.activeTemplate.privacy === 'PUBLISHED_PUBLIC'">
              <div class="p-2  btn-copy pl12 p_t5 p_b5">Unveröffentlichen</div>
              <div class="p_2 align-text-end ml-auto">
                <mat-icon class="nav-icon-mini" >public_off</mat-icon>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="container-fluid template-inner-wrapper"  #_templateItems>
      <div class="row p_t10 " >
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 verySmallGutter">
          <expertise-area-header></expertise-area-header>
        </div>
        <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 verySmallGutter" *ngFor="let filter of ui.treatmentFilterGroups">
          <behandlungsregion-comp [title]="filter" [filter_level]="ui.getFilterLevelByGroup(filter)" [filters]="ui.getFilterByGroup(filter)" ></behandlungsregion-comp>
        </div>

        <div class="col-xxl-2 col-xl-2 col-lg-4 col-md-6 verySmallGutter">
          <template-header-minderung></template-header-minderung>
        </div>
      </div>

      <div class="row">
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 p_t30 verySmallGutter">
          <app-template-note-method-diagnose title='Diagnose(n)' [templateDocId]='documentId' type='DIAGNOSE'></app-template-note-method-diagnose>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 p_t30 verySmallGutter">
          <app-template-note-method-diagnose title='Therapie(n)' [templateDocId]='documentId' type='THERAPY_METHOD'></app-template-note-method-diagnose>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 p_t30 verySmallGutter">
          <app-template-note-method-diagnose title='Notizen' [templateDocId]='documentId' type='NOTE'></app-template-note-method-diagnose>
        </div>
      </div>
    </div>


    <div class="container-fluid" #_templateNavStuff>
      <div class='row pl0 pr0 p_t30'  *ngIf='template.activeTemplate != null' [class.greyBottomBorder]="!greedBackground" [class.greenBottomBorder]="greedBackground">
        <div class="container-fluid template-inner-wrapper">
         <div class="row pl0 pr0">
          <div class="col-auto verySmallGutter">
            <div class="invoiceButton" (click)='tabbarTap("INVOICE")' [class.invoiceButtonActive]="!greedBackground && !nachbehandlungActive">
              <div class="btnCopy">Behandlungsschritte</div>
            </div>
          </div>
          <div class="col-auto verySmallGutter">
            <div class="reportButton" (click)='tabbarTap("REPORT")' [class.reportButtonActive]="greedBackground && !nachbehandlungActive">
              <div *ngIf="template.activeTemplate.type === 'SCS'" class="btnCopy">Befundungsbericht</div>
              <div *ngIf="template.activeTemplate.type === 'DOXFOX'" class="btnCopy">OP Bericht Ansicht</div>
            </div>
          </div>
          <div class="col-auto verySmallGutter">
            <div class="reportButton" (click)='tabbarTap("NACHBEHANDLUNG")' [class.reportButtonActive]="nachbehandlungActive">
              <div class="btnCopy">
                Nachbehandlungsschema
              </div>
            </div>
          </div>
         </div>
        </div>
      </div>
    </div>

    <div class="template-inner-wrapper" *ngIf="ui.splitScreenState != ui.splitScreenStates.CLOSED">
      <div class="d-flex">
        <div class="p-2" style="width: 50%;">
        </div>
        <div class="p-2" style="width: 50%;">
          <div class="d-flex">
            <div class="p-0">
              <mat-icon (click)='ui.toggleSplitScreenState(ui.splitScreenStates.CLOSED)' class='dxfx_icon_btn_medium'>close</mat-icon>
            </div>
            <div class="p-0">
              <p class="mat-body">
                <!--             
                            <span (click)='ui.toggleSplitScreenState(ui.splitScreenStates.CLOSED)'  class="splitScreenClose">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="black" height="20" width="20" class="simple-close-icon"><path d="M5.375 15.854 4.146 14.625 8.771 10 4.146 5.375 5.375 4.146 10 8.771 14.625 4.146 15.854 5.375 11.229 10 15.854 14.625 14.625 15.854 10 11.229Z"/></svg>      
                            </span> -->
                            <span class='bc'>
                              <span class="bc-text" [class.bc-text-a]="ui.splitScreenState == ui.splitScreenStates.TEMPLATE_SELECTED" (click)="ui.toggleSplitScreenState(ui.splitScreenStates.TEMPLATE_LIST)">Vorlagen</span>
                              <span class="bc-trenner"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#bac7cc" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/></svg></span>
                              <span class="bc-text" *ngIf="ui.splitScreenState == ui.splitScreenStates.TEMPLATE_SELECTED">{{ui.splitScreenTemplateName}}</span>
                            </span>
                          </p>
            </div>
          </div>
          
          
        </div>
      </div>
    </div>

    <div class="bg-container-fuck" #_bgContainerFuck [class.bgFuckGreen]='greedBackground' [class.bgFuckGrey]='!greedBackground' style="min-height: 56vh!important;">
      <div class="content-holder-container" #_contentHolder>
        <div add-host></div>
      </div>
      <div class="container-fluid template-inner-wrapper">
        <div class="d-flex pr0 mr0" *ngIf="!ui.hasTemplateAdminRole">
          <div class="p-2 ml-auto pr0 mr0" *ngIf="template.activeTemplate.privacy === 'PUBLISHED_PUBLIC'">
            <button mat-raised-button color="primary" class="m_b15" (click)="startCopyTemplate()"
            matTooltipClass='sectionGoaeTT' matTooltipPosition='above'
            matTooltip='Öffentliche Vorlage zu *Meinen Vorlagen hinzufügen*'>
              <mat-icon style="font-size: 14px;  margin-right: 4px;" class="pl0 ml0"  inline=true>cloud_download</mat-icon>
              <span  inline=true>Zu meinen Vorlagen hinzufügen</span>
            </button>
          </div>
        </div>
      </div>
    </div>

</div>


<div [class.CONTENTOUT3]='isLoading' [class.CONTENTIN3]='!isLoading'>
  <template-footer ></template-footer>
</div>

