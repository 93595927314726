import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { apiRequest, API_CALL } from 'src/app/core/models/api/apiCalls';
import { TemplateFlatI } from 'src/app/core/models/api/TemplateFlatI';
import { BASE_TEMPLATE_TYPE,  SPLIT_SCREEN_VIEW_STATE } from 'src/app/core/models/enums';
import { ScreenSize } from 'src/app/core/models/screenSize';
import { SectionM, SectionMDateChanged } from 'src/app/core/models/sectionM';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { UiService } from 'src/app/services/ui.service';
import { ControllerService } from 'src/app/shared/controller.service';
import { TokenstorageService } from 'src/app/services/tokenstorage.service';
import { LocaleService } from 'src/app/services/locale.service';
import { IProcessState } from 'src/app/core/models/iProcessState';
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';

@Component({
  selector: 'app-template-behandlungsschritte-view',
  templateUrl: './template-behandlungsschritte-view.component.html',
  styleUrls: ['../../../../pages/templates-details.scss'],
  providers:[DatePipe],
  // animations: [
  //   trigger('listAnimation2', [
  //     transition('* => *', [ // each time the binding value changes
  //       query(':enter', [
  //         style({ opacity: 0  }),
  //         stagger(15, [
  //           animate('0.25s ease-out', style({ opacity: 1, height: '52px' }))
  //         ])
  //       ], { optional: true })
  //     ])
  //   ])
  // ]

})
export class TemplateBehandlungsschritteViewComponent implements OnInit, OnDestroy {

  @ViewChild('_dragListContainer') _dragListContainer: ElementRef;
  @Input() template: TemplateFlatI;
  @Input() editable: boolean;
  @ViewChild('splitScreenView',{static:false}) splitScreenView:ElementRef
  isSplitScreenMode = false;
  newState: IProcessState//string = ''
  changedState: IProcessState//string = ''
  resizeSubscription: Subscription;
  currentScreenSize: ScreenSize;
  isLoading = true;
  // columStyles: PositionColumType;
  sections: SectionM[] = []
  // testBillingCompany:TestBillingCompany[] = []
  private sectionChangeSubscription: Subscription;

  constructor(
    public tc:TemplateControllerService,
    public ui:UiService,
    public locales:LocaleService,

      private ts: TokenstorageService) { }
      private  _splitScreenHeight:number = 100
  get splitScreenHeight():number {
    return this._splitScreenHeight
  }
  ngOnInit(): void {
    setTimeout(() => {

      this.sectionChangeSubscription = this.tc.sectionChanged.subscribe(
        _changed => {
          this.initSections()
        }
      )
      this.currentScreenSize = ControllerService.currentScreenSize;
      this.resizeSubscription = ControllerService.windowResize.subscribe(
        (screeSize: ScreenSize) => {
          if (this.currentScreenSize == null || this.currentScreenSize !== screeSize){
            this.currentScreenSize = screeSize;

            this.calPosTable();
          }
        }
      );
      this.initSections()
      this.calPosTable();
      console.log(`CURRENT TEMPLATE STATE ${this.tc.activeProcess}`, this.tc.activeProcess)
      // if(this.tc.baseTemplateType == BASE_TEMPLATE_TYPE.PROCESS){
      //   this.loadTestBillingCompanies()
      // }
     
      this.newState = this.tc.getProcessState()
 //     console.log(this.newState);
    }, 1000);

 

  }

  ngOnDestroy(){
    if(this.resizeSubscription != null){
      this.resizeSubscription.unsubscribe();
    }
    if(this.sectionChangeSubscription != null){
      this.sectionChangeSubscription.unsubscribe();
    }
  }

  // private async loadTestBillingCompanies(){
  //   const _billingCompanies = await this.tc.getUserBillingCompanies();
  //   this.testBillingCompany = _billingCompanies as TestBillingCompany[]
  //   // console.log("_billingCompanies ", _billingCompanies)
  // }
  initSections(){
    this.sections = this.tc.currentSections;
    this.sections.sort((a,b) => a.order_index - b.order_index)
  }
  calPosTable(){
    this._splitScreenHeight = this.currentScreenSize.height - (78 + 52 + 112 + 40 +64 +104)
  
  }

  async addAbschnitt(){
    this.tc.addNewSection(this.tc.activeTemplate.template_id, this.tc.prefix)
  }

  scrollTo(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }
  logAnimation(_event) {
    // console.log(_event)
  }
  drop(event: CdkDragDrop<SectionM[]>) {
    moveItemInArray(this.tc.currentSections, event.previousIndex, event.currentIndex);
    this.tc.currentSections[event.currentIndex].order_index =  event.currentIndex;
    this.updateSectionIndex(0);
  }

  sectionDateChanged(e: SectionMDateChanged){
    const _sectiion: SectionM = this.tc.currentSections.find((item) => item.section_id === e.section.section_id);
    if (_sectiion == null){
      return;
    }
    var _last_treated_at_difference_days = e.section.treated_at_difference_days;
    for(var i = 0; i < this.tc.currentSections.length; i ++){
      if(i >= e.section.order_index && e.section.treated_at_difference_days > 0){
        this.tc.currentSections[i].treated_at_difference_days = _last_treated_at_difference_days
        // _last_treated_at_difference_days ++;
      }
    }

    this.tc.updateAllSections(this.tc.prefix)
  }


  updateSectionIndex(startIndex: number){
    this.tc.updateSectionIndex(startIndex, this.tc.prefix)
  }


  addAbschnittAusVorlage(){
       this.ui.toggleSplitScreenState(SPLIT_SCREEN_VIEW_STATE.TEMPLATE_LIST)
       setTimeout(()=>{
         this.scrollIntoSplitScreen()
       },600)
  }
  scrollIntoSplitScreen(){
    console.log(this.splitScreenView)
    setTimeout(()=>{

      this.splitScreenView.nativeElement.scrollIntoView({behavior:"smooth"});
    },820)
  }




  async openTestBillingCompanyStuff(){
   
    if(this.tc.activeTemplate.address_id == null || this.tc.activeTemplate.address_id == -1){
      ControllerService.snack.emit('Adresse fehlt')
      return
    }
    if(this.tc.activeTemplate.patient_id == null || this.tc.activeTemplate.patient_id == -1){
      ControllerService.snack.emit('Patient fehlt')
      return
    }

      let _url = apiRequest(API_CALL.XML_TEST) + this.tc.currentTemplateId + '/' +this.tc.activeTemplate.address_id +'/'+this.ts.getToken()
      window.open(_url, '_blank')

  }

  async tragsportToAccounting() {
    
  }
  
  get availableStates() :IProcessState[]{
    let geht:number[] = [10,6,8,9]
    return this.tc.processStates.filter(item => geht.includes(item.id))
  }

}
