import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { Subscription } from 'rxjs';
import { UiService } from 'src/app/services/ui.service';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-inactivity-warn-overlay',
  templateUrl: './inactivity-warn-overlay.component.html',
  styleUrls: ['./inactivity-warn-overlay.component.scss']
})
export class InactivityWarnOverlayComponent implements OnInit, OnDestroy {

  progress = 30
  degrees = 180
  mode: ProgressSpinnerMode = 'determinate';

  private activityDetected: boolean = false
  private interval: any
  private activiySubscription: Subscription


  constructor(
    private ui: UiService,
    private authS:AuthService,
    private dialogRef:MatDialogRef<InactivityWarnOverlayComponent>) { }


  ngOnInit(): void {
    if (this.ui.isInactivityOverlay) {

    } else {
      this.ui.isInactivityOverlay = true
      this.activityDetected = false
      this.interval = null
      this.progress = 30
      this.degrees = 180
      this.activiySubscription = null;
      this.activiySubscription = this.ui.activityDetected.subscribe(
        _detedted => {
          if (_detedted == true) {

            this.clearAndClose()
          }
        }
      )
      this.interval = setInterval(()=> {
        this.setTime()
      }, 1000)
    }
  }


  private setTime() {
    if (this.progress == 1) {
      clearInterval(this.interval)
      this.closeAndLogout()
    } else {
      this.progress --
      this.degrees -= 6
    }
  }

  private clearAndClose() {
    if (this.activityDetected) { return }
    this.activityDetected = true
    clearInterval(this.interval)
    this.dialogRef.close()
  }
  private closeAndLogout() {
    this.activityDetected = true
    clearInterval(this.interval)
    this.authS.SignOut()
    this.dialogRef.close()
  }
  close() {
    this.dialogRef.close()
  }

  ngOnDestroy(): void {
    if ( this.activiySubscription) {
      this.activiySubscription.unsubscribe()
    }
  }
}
