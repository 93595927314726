import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { employeeI, EmployeeOverlayDataI } from 'src/app/core/models/employeeI';
import { AccountService } from 'src/app/services/account.service';
import { ACCOUNT_CALL } from 'src/app/services/enums/accountS.callEnum';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {

  isSaving:boolean = false

  employee:employeeI = {
    id:-1,
    title:'',
    uid: '',
    firstName:'',
    lastName:'',
    email:'',
    phone:'',
    disabled:false
  }
  constructor(public dialogRef: MatDialogRef<EmployeeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EmployeeOverlayDataI,
    private accountS:AccountService,
    private tc: TemplateControllerService,
    private ui: UiService) {
      if(this.data.employee != null && this.data.employee.id != -1){
        this.employee = this.data.employee;
      }
     }



  ngOnInit(): void {

  }
  save(){
    this.isSaving = true;
    let needAbort = false
    if(this.ui.isAccountant) {
      const creator = this.tc.activeProcess.creator
      if(creator) {
        this.employee.uid = creator
      } else {
        needAbort =  true
      }
    } else {
      this.employee.uid = this.ui.account.uid
    }

    if(needAbort) {
      this.isSaving = false;
      this.dialogRef.close();
      return
    }
    this.accountS.postEmployee(this.employee).then(
      (alright)=>{
        setTimeout(()=>{
          this.isSaving = false;
          this.dialogRef.close();
        },1000)
      }
    )

  }
  delete(){
    this.accountS.apiGet(ACCOUNT_CALL.DELETE_EMPLOYEE_BY_ID,this.employee.id.toString())
    setTimeout(()=>{
      this.isSaving = false;
      this.dialogRef.close();
    },100)
  }
  close(){
    this.dialogRef.close();
  }

}
