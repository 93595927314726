<div style="position: relative; height: 100%;">

  <mat-toolbar class="overlay-toolbar">
    <span class="toolbar-spacer"></span>
    <button mat-flat-button (click)='close()'>Fertig</button>
  </mat-toolbar>

  <div class="d-flex flex-column">

    <!-- TITLE STUFF -->
    <div class="p-2">
      <div class="d-flex align-items-center">
        <div class="p-0">
          <horizontal-item-selection [editable]="tc.isTemplateEditable" [itemCount]="items.length"
                  [showAdd]="showAdd"
                  [showRemove]="items.length > 0"
            [currentIndex]="currentIndex" (action)="onSelectionAction($event)"></horizontal-item-selection>
        </div>
        <div class="p-0 flex-fill" *ngIf="items.length > 1">
          <mat-form-field appearance="outline" class="textfield100 select-form-field" *ngIf="!isTitleEditMode">
            <mat-select (selectionChange)='changed($event)' [(value)]="selectedItem" [disabled]="!tc.isTemplateEditable">
              <mat-option *ngFor="let item of items" [value]="item">
                {{item.description}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <input class="formControl mat-body formControlDescription fs14-400" placeholder="Beschreibung"
            [disabled]='!tc.isTemplateEditable' maxlength="250" style="background-color:rgba(0,0,0,0); " #_titleTextField
            (keydown.enter)="onTextFieldTitleEnter($event)" (keydown.esc)="onTextFieldTitleEscape($event)"
            (blur)="onTextFieldTitleBlur($event)" [value]='currentDescription' *ngIf="isTitleEditMode">
        </div>
        <div class="p-0 p_t8 pl8" *ngIf="items.length > 1">
          <mat-icon *ngIf="!isTitleEditMode" (click)='setTitleEditMode()' class='justification_arrow_icon_mini'
            [class.justification_arrow_icon_green_disabled]="items.length == 0">edit</mat-icon>
        </div>
      </div>
    

    </div>
    <!-- END TITLE STUFF -->
    
    <!-- TEXTAREA -->
    <div class="p-2 pr15 pl15">
      <textarea style="width:100%; height: 100%; background-color:rgba(0,0,0,0);  resize: vertical !important;"
      maxlength="15000"
      rows="14" cols='2' class="mat-body formControl fs14-400"
      #_textArea
      (keydown.esc)="onTextAreaEscape($event)"
      (blur)="onTextAreaBlur($event)"
      [value]="currentText" [disabled]='!tc.isTemplateEditable'  >
    </textarea>
    </div>
    <!-- END TEXTAREA -->

  </div>

</div>