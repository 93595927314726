export const toolbar_tools: object = {
  /* 'SourceCode', */
  items:['FullScreen', 'Undo', 'Redo']
  // 'SourceCode', , 'InsertCode'

//   items: ['OrderedList', 'UnorderedList', '|', 'ClearFormat',  '|', 'Undo', 'Redo', 
// '|', {
//   tooltipText: 'Insert Br',
//   template: '<button class="e-tbar-btn e-btn" tabindex="-1" id="custom_tbar"  style="width:100%">'
//       + '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M4 19h6v-2H4v2zM20 5H4v2h16V5zm-3 6H4v2h13.25c1.1 0 2 .9 2 2s-.9 2-2 2H15v-2l-3 3 3 3v-2h2c2.21 0 4-1.79 4-4s-1.79-4-4-4z"/></svg></button>'
// }]
};
export const toolbar_tools_NACHBEHANDLUNG: object = {
  /* 'SourceCode', */
  items:[ 'Undo', 'Redo', 'Bold', 'Italic', 'UnorderedList']
  // ,  'ClearFormat', 'SourceCode'
};
export const  texteditor_cleanup: object = {
  prompt: false,
  plainText: true,
  keepFormat: false,
  /* deniedTags: ['p'], */
  // deniedAttrs: ['class', 'title', 'id'],
  allowedStyleProps: []
};