import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SectionMaterialI } from 'src/app/core/models/materialI';
import { TemplateControllerService } from 'src/app/services/template-controller.service';

@Component({
  selector: 'app-section-material-item',
  templateUrl: './section-material-item.component.html',
  styleUrls: ['./section-material-item.component.scss']
})
export class SectionMaterialItemComponent implements OnInit {

  @Input() section_id:number;
  @Output() actions:EventEmitter<string> = new EventEmitter<string>();

  // matItems:SectionMaterialI[] = []

  constructor(
    public tc:TemplateControllerService) { }

  ngOnInit(): void {
    setTimeout(()=>{
      // this.loadMatItems()
    },500)
  }

  get matItems():SectionMaterialI[]{
    return this.tc.allSectionMaterialItems.filter(item => item.section_id === this.section_id)
  }
  private async loadMatItems(){
    // const _items = await this.tc.getSectionMaterialItems(this.section_id);
    // this.matItems = _items;
  }
  getMaterialSummery(matItem:SectionMaterialI) : string {
    if(matItem.preis != null && matItem.quantity != null){
      return "€ " +(matItem.quantity * Number(matItem.preis)).toFixed(2).toString();  
    }
    return '€ 0.00'
    
  }
  async matQuantityChanged(e, item:SectionMaterialI){
    const _matItem:SectionMaterialI = {
      id: item.id, 
      section_id: item.section_id,
      quantity: e.target.value,
      preis: item.preis,
      bezeichnung: item.bezeichnung
    }
    await this.tc.updateSectionMaterialItem(this.tc.prefix,_matItem);
    
    await this.tc.getSectionMaterialItems(this.tc.prefix,this.section_id, true);
  }
  async deleteMaterialItem(matItem:SectionMaterialI){
    await this.tc.deleteSectionMaterialItem(this.tc.prefix,matItem.id);
    this.tc.getSectionMaterialItems(this.tc.prefix,this.section_id, true);
  }

  createNewOrSection(){

  }

  
}
