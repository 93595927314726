<div class="container " *ngIf='account'>
  <div class="container-fluid" >
   <div class="row pl10 pr10">
    <div class="col-12 p_t5">
      <p>Diese Informationen werden auf den generierten Rechnungs-PDFs angezeigt.</p>
    </div>
  </div>
  <div class="row pl10 pr10 p_b20 p_t15" style="background-color: rgba(0,0,0,0.05)" >
    <div class="col-12 ">
      <div class="d-flex w100">
        
       <!--  <div class="p-2">
          <horizontal-item-selection
      [editable]="true"
      [itemCount]="addresses.length"
      [currentIndex]="currenIndex"
      (action)="onReportSelectionAction($event)"
    ></horizontal-item-selection>
        </div> -->
     <!--    <div class="p-0 w45p">
          <mat-label class='mat-caption'>Adressen</mat-label>
          <mat-form-field appearance="outline" class="textfield100 select-form-field">
            <mat-select (selectionChange)='addresssChanged($event)' [(value)]="address" >
              <mat-option *ngFor="let _address of addresses" [value]="_address">
                {{_address.klinikName}} <span *ngIf="ui.isClinicUser"> | {{_address.behandelnder_arzt}}</span>
              </mat-option>
              <div mat-option class="d-flex align-items-center add-button-select" (click)="addAdress()" *ngIf="!ui.account.isClinicUser">
                <div class="p-2  btn-copy pl12 p_t5 p_b5">Weitere Praxis/Belegklinikadresse anlegen</div>
                <div class="p_2 align-text-end ml-auto">
                  <mat-icon class="nav-icon-mini">add</mat-icon>
                </div>
              </div>
            </mat-select>
          </mat-form-field>
        </div> -->
      <!--   <div class="p-2 ml10" *ngIf="!address.isMainAddress && !ui.isClinicUser" style="padding-top: 23px!important;">
          <app-df-icon-button *ngIf="address.id != -1"
          title= "Als Standard Addresse festlegen"
          icon="save"
          color="accent"
          (clicked)="setAsBaseAddress()"
          >
          </app-df-icon-button>
        </div> -->
        <!-- <div class="p-2" *ngIf="address.isMainAddress">{{address.isMainAddress}}</div> -->
      </div>
    </div>
  </div>

</div>
<mat-tab-group [(selectedIndex)]="currenIndex" (selectedTabChange)="onTabChanged($event);">
  
  <mat-tab  *ngFor="let _address of addresses; let i = index" [label]="_address.klinikName">
    

      <app-address-list-item 
      [addIndex]="i"
      (baseAddressChanged)="onBaseAdressChanged($event)"
      [account]="account" [address]="_address" *ngIf="i == currenIndex"></app-address-list-item>
    
  </mat-tab>
</mat-tab-group>
<!-- <div class="p-0" *ngFor="let _address of addresses; let i = index">

  <app-address-list-item [account]="account" [address]="_address" *ngIf="i == currenIndex"></app-address-list-item>
</div> -->
<!-- 
<div class="container-fluid m_t5" *ngIf="address" style="background-color: rgba(0,0,0,0.05)">

  <div class="row pl10 p_t10">
    <div class="col-6">
      <h2>Praxis/Klinik</h2>
    </div>
  </div>

  <div class="row pl10 p_t15 pr10">

    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Name der Praxis/Klinik</mat-label>
      <input class="formControl mat-body"
      [class.formControlError]="address.klinikName == '' || address.klinikName == null"
      [(ngModel)]="address.klinikName" [disabled]="ui.isClinicUser">
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Abteilung</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.abteilung" [disabled]="ui.isClinicUser" [class.formControlDisabled]="ui.isClinicUser">
    </div>
  </div>

  <div class="row pl10 p_t15 pr10">
    <div class="col-lg-5 col-md-5 col-sm-12">
      <mat-label class='mat-caption'>Behandelnde(r) Arzt/Ärztin</mat-label>
      <input class="formControl mat-body formControlDisabled" disabled [(ngModel)]="account.docName">
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <mat-label class='mat-caption'>Facharztbezeichnung</mat-label>
      <input class="formControl mat-body formControlDisabled" disabled [(ngModel)]="account.doc_description">
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6">
      <mat-label class='mat-caption'>Funktion</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.arzt_role">
    </div>
  </div>

  <div class="row pl10 p_t15 pr10">

    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Straße und Hausnummer</mat-label>
      <input class="formControl mat-body" [disabled]="ui.isClinicUser"
      [class.formControlError]="address.streetName == '' || address.streetName == null"  [(ngModel)]="address.streetName">
    </div>

    <div class="col-lg-2 col-md-2 col-sm-6">
      <mat-label class='mat-caption'>PLZ</mat-label>
      <input class="formControl mat-body" [class.formControlError]="address.zip == '' || address.zip == null"  [(ngModel)]="address.zip" [disabled]="ui.isClinicUser">
    </div>

    <div class="col-lg-4 col-md-4 col-sm-6">
      <mat-label class='mat-caption'>Stadt</mat-label>
      <input class="formControl mat-body" [class.formControlError]="address.city == '' || address.city == null"  [(ngModel)]="address.city" [disabled]="ui.isClinicUser">
    </div>

  </div>

  <div class="row pl10 p_t25">
    <div class="col-6">
      <h2>Bankverbindung</h2>
    </div>
  </div>

  <div class="row pl10 p_t15 pr10">

    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Kontoinhaber/in</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.kontoinhaber" [disabled]="ui.isClinicUser">
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>USt-IdNr.</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.ust_nr" [disabled]="ui.isClinicUser">
    </div>

  </div>

  <div class="row pl10 p_t15 pr10 p_b20">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Bankinstitut</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.bank_name" [disabled]="ui.isClinicUser">
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6">
      <mat-label class='mat-caption'>IBAN</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.iban" [disabled]="ui.isClinicUser">
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6">
      <mat-label class='mat-caption'>BIC</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.bic" [disabled]="ui.isClinicUser">
    </div>

  </div>

</div> -->

  <div class="row pl10 p_t15 pr10" *ngIf="!ui.isClinicUser">

    <div class="col-12" >
      <div class="d-flex" *ngIf='!isSaving'>
        <div class="p-2 pl0"  >
          <app-df-icon-button
          title= "Weitere Praxis/Belegklinikadresse anlegen"
          icon="add"
          (clicked)="addAdress()"
          >
          </app-df-icon-button>

        </div>
        <!-- <div class="p-2 ml-auto">
          <app-df-icon-button
          title= "Einstellungen speichern"
          icon="save"
          color="primary"
          (clicked)="save()"
          [disabled]="saveDisabled"
          >
          </app-df-icon-button>
        </div> -->
      </div>

      <div class="d-flex justify-content-center" *ngIf='isSaving'>
        <div class="p-2">
          <mat-spinner diameter='25' color='primary'></mat-spinner>
        </div>
      </div>
    </div>


  </div>

</div>
