
import { SectionM } from "../sectionM";
import { PositionFlatI } from "../sectionPositionM";
import { PRIVACY } from "./templatePrivacy";

export interface TemplateFlatI {
  template_id:number;
  documentId?:string;
  name?:string;
  originName?:string;
  originId?:string;
  privacy?:PRIVACY;
  date?: number;
  uid?:string;
  owner?:string;
  creator?:string;
  createdByUid?:string;
  // types?:TemplateType
  type?:string;
  lastModified?: number;
  patient_id?:number;
  treatment_region?: string;
  treatment_type?: string;
  hasInvoice?:boolean;
  hasSurgeryReport?:boolean;
  status?: string;
  summary?: number;
  reduction?: number;
  patientDisplayName?: string;
  invoice_id?: number;
  surgery_report_id?: number;

  befund_id?:number

  additionalSurgeryInfos?:any
  address_id?:number

  fachgebiet_id?:number;
  treatment_type_id?:number;
  treatment_region_id?:number;

  treatmentfilter_first?:number;
  treatmentfilter_second?:number;
  treatmentfilter_third?:number;

  status_id?:number;
  status_color?: string
  status_view_value?: string
  status_list_value?: string

  clinicProcessNumber?:number;
  isEditable:boolean
  pool_id?:number

  assigneeLastName?: string
  assigneeFirstName?: string
  creatorFirstName?: string
  creatorLastName?: string
  assignee_uid?: string

  sections?: SectionM[]
 positions?:PositionFlatI[]
  shortCut?: string;
  iconColor?: string;
  // unpublished_color?: string;
  // user_own_color?: string;
}


export enum TEMPLATE_COPY_MODE {
  DUPLICATE,
  CLONE,
  MOVE_TO_PROCESS,
  CLONE_TO_OWN_POOL
}
