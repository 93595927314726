import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { IClinicSectorFlat, ITemplatePool, ITemplatePoolsSelected } from 'src/app/core/models/accountI';
import { API_CALL, apiRequest } from 'src/app/core/models/api/apiCalls';
import { ApiUtilService } from 'src/app/services/api-util.service';
import * as _ from 'lodash';
import { ITemplatePoolOverlayData } from 'src/app/core/models/renameTemplateOverlayData.model';
import { UiService } from 'src/app/services/ui.service';
import { AccountService } from 'src/app/services/account.service';
import { ACCOUNT_CALL } from 'src/app/services/enums/accountS.callEnum';
@Component({
  selector: 'app-template-pool-overlay',
  templateUrl: './template-pool-overlay.component.html',
  styleUrls: ['./template-pool-overlay.component.scss']
})
export class TemplatePoolOverlayComponent implements OnInit {

  // templatePools: ITemplatePool[] = []
  selectedPool:ITemplatePool
  olData: ITemplatePoolOverlayData
  title = ""
  poolName: string = 'Pool Name'
  shortCut: string = 'x'
  // filterTitle: string = 'x'
  selectedColor = '#cecece'
  chipActive = true
  selectedColorIndex = -1
  clinicSectors: IClinicSectorFlat[] = []
  clinicSectorsSelected: IClinicSectorFlat[] = []
  sectorsSelected: ITemplatePoolsSelected[] = []
  colors = [
    '#76B9C4',
    '#B09393',
    '#D2AC32',
    '#DD6161',
    '#CE5A97',
    '#AD72DA'
  ]
  publishBtnColors = [
    '#A4B8BB',
    '#B7AEAE',
    '#CEBE8A',
    '#E19A9A',
    '#D0A7BD',
    '#C6A7DE'
  ]
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ITemplatePoolOverlayData,
    private apiUtil: ApiUtilService,
    public ui: UiService,
    private as: AccountService,
    private dialogRef: MatDialogRef<TemplatePoolOverlayComponent>
  ) {
    this.olData = data
   }

  async ngOnInit() {
    this.loadClinicSectors()

    if (this.olData.isEditMode) {
      
      const adminTemplatePools = await this.as.apiGet(ACCOUNT_CALL.TEMPLATE_POOLS_GET)
      this.sectorsSelected = adminTemplatePools as ITemplatePoolsSelected[]
      this.clinicSectorsSelected = []
      this.sectorsSelected.forEach(element => {
        this.clinicSectorsSelected.push({
          user_pool_id: element.uid,
          title: element.title
        })
      })
     console.log("-------      clinicSectorsSelected")
      console.log(this.clinicSectorsSelected)
      if (this.templatePools.length == 1) {
        this.selectPool(this.templatePools[0])
      }
    }
  }

  selectionChange(e: MatSelectionListChange) {
  console.log(e);
  
}
  get templatePools(): ITemplatePool[] {
    if (this.ui.account && this.ui.account.templatePools) { 
      const pools = this.ui.account.templatePools.filter(item => item.canEdit === true)
      return pools
    }
    return []
  }
  isSectorSelected(s: IClinicSectorFlat): boolean {
    return this.clinicSectorsSelected.findIndex(item => item.user_pool_id == s.user_pool_id) != -1
  }
  selectPool(p: ITemplatePool) {
    this.selectedPool = p
    const index = this.colors.findIndex(item => item == p.user_own_color);
    if (index != -1) {
      this.selectedColor = this.colors[index]
      this.selectedColorIndex = index
      this.shortCut = p.shortCut
      this.poolName = p.name
    }
  }
  get filterTitle(): string {
    return `Meine ${this.poolName} Vorlagen`
  }
  setColor(index: number) {
    this.selectedColorIndex = index
    this.selectedColor = this.colors[index]
  }
  toggleChip(){
    this.chipActive = !this.chipActive;
  }
  close() {
    this.dialogRef.close()
  }

  async loadClinicSectors() {
    const _path = apiRequest(API_CALL.GET_CLINIC_SECTORS)
    const result = await this.apiUtil.apiGetP(_path)
this.clinicSectors = result as IClinicSectorFlat[]
    console.log(this.clinicSectors)
  }

  sectorSelectionChanged(e:MatSelectionListChange) {
    console.log(e.option.value);
    const index = this.clinicSectorsSelected.findIndex(item => item.user_pool_id == e.option.value.user_pool_id);
    if(index != -1) {
      this.clinicSectorsSelected.splice(index, 1);
    } else {
      this.clinicSectorsSelected.push(e.option.value)
    }
    
  }
  async save() {
    const poolids = _.map(this.clinicSectorsSelected, 'user_pool_id')
    if (this.olData.isEditMode && this.selectedPool) {
      const _path = apiRequest(API_CALL.UPDATE_TEMPLATE_POOL,  this.selectedPool.template_pool_id.toString())
    const dto = {
      dto: {
        poolName: this.poolName, 
        short: this.shortCut.toUpperCase(), 
        color: this.colors[this.selectedColorIndex],
        publishedColor: this.publishBtnColors[this.selectedColorIndex],
        templateUserPoolIds: poolids
      }
    }
    console.log(dto);
    const result = await this.apiUtil.apiPost(_path, dto)
    
    } else {
      const _path = apiRequest(API_CALL.TEMPLATE_POOL_CREATE)
    const dto = {
      dto: {
        poolName: this.poolName, 
        short: this.shortCut.toUpperCase(), 
        color: this.colors[this.selectedColorIndex],
        publishedColor: this.publishBtnColors[this.selectedColorIndex],
        templateUserPoolIds: poolids
      }
    }
    console.log(dto);
    const result = await this.apiUtil.apiPost(_path, dto)
    
    console.log(result);
    }
    this.close()
  }
}
