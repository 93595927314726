import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITemplateDetailOverlayData } from 'src/app/core/models/template-detail-overlay-data.mode';
import { TemplateDetailService } from '../template-detail.service';
import { TemplateFlatI } from 'src/app/core/models/api/TemplateFlatI';


@Component({
  selector: 'app-template-detail-overlay-component',
  templateUrl: './template-detail-overlay-component.component.html',
  styleUrls: ['./template-detail-overlay-component.component.scss', '../../../pages/templates-details.scss']
  // styleUrls: ['../templates-details.scss'],
})
export class TemplateDetailOverlayComponentComponent implements OnInit {
  
  template_id:number
  overlayData: ITemplateDetailOverlayData
  templates: TemplateFlatI[] = []
  constructor(

    public dialogRef: MatDialogRef<TemplateDetailOverlayComponentComponent>,
    private tds:TemplateDetailService,
    @Inject(MAT_DIALOG_DATA) public data: ITemplateDetailOverlayData,
  ) { 
    this.overlayData = data
  }
  
   
  
 


  ngOnInit(): void {
    if(this.overlayData) {
      this.template_id = this.overlayData.template_id
      this.load()
    }


  }

  private async load(): Promise<void> {
    const response = await this.tds.loadTemplatesTest();
    console.log(response)
  }

  close() {
  
  }
}
