import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ITemplatePool } from 'src/app/core/models/accountI';
import { IRenameTemplateOverlayCloseData, IRenameTemplateOverlayData } from 'src/app/core/models/renameTemplateOverlayData.model';

@Component({
  selector: 'app-new-template-name',
  templateUrl: './new-template-name.component.html',
  styleUrls: ['./new-template-name.component.scss']
})
export class NewTemplateNameComponent implements OnInit {


  @ViewChild('singleLine', {static:false}) singleLine:ElementRef;
  templateName = ""


  overlayData: IRenameTemplateOverlayData
  templatePools: ITemplatePool[] = []
  selectedPool?: ITemplatePool
  constructor(public dialogRef: MatDialogRef<NewTemplateNameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IRenameTemplateOverlayData) {
      this.overlayData = data
      this.templateName = data.templateName
      this.templatePools = data.templatePools ? data.templatePools : []
    }

  ngOnInit(): void {
    if(this.overlayData && this.overlayData.poolSelectionRequired) {
      if(this.overlayData.templatePools) {
        this.selectedPool = this.templatePools[0]
      }
    }
    setTimeout(()=>{
      if(this.singleLine != null) {
        this.singleLine.nativeElement.focus()
        // this.singleLine.nativeElement.select()
      }
    },200)
    // this.textEdit = this.data;
    // this.textModel = this.textEdit.text

  }

  poolSelectionChanged(e:MatSelectChange) {
    this.selectedPool = e.value
  }
  close(){
    this.dialogRef.close()
  }
  save(){
    let closeData: IRenameTemplateOverlayCloseData = {
      newName: this.templateName,
      selectedPool: this.selectedPool,
      poolSelectionRequired: this.overlayData.poolSelectionRequired
    }
    this.dialogRef.close(closeData)
  }

  async onTextAreaKeyDown(event){
    if(event.key == 'Escape' || event.key == 'escape'){
      this.close()
    } else if(event.key == "enter" || event.key == 'Enter') {
      this.save()
    }
  }
}
