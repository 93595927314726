import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { TemplateFlatI } from 'src/app/core/models/api/TemplateFlatI';
import { LocaleService } from 'src/app/services/locale.service';
import { UiService } from 'src/app/services/ui.service';
import { TemplateApiService } from 'src/app/services/template-api.service';
import { TEMPLATES_CALL } from 'src/app/services/enums/templateS.callEnum';

@Component({
  selector: 'app-dashboard-template-item',
  templateUrl: './dashboard-template-item.component.html',
  styleUrls: ['../dashboard-items.scss']
})
export class DashboardTemplateItemComponent implements OnInit {

  @Input() title;
  @Input() isUnpublishedMode;
  isLoading = true;
  templates:TemplateFlatI[] = []

  dataSource:any
  displayedColumns: string[] = ['type', 'name','last_modified'];
  showCard = false;
  isEmpty: boolean = false;
  randomTime:number = 200

  constructor(
    public ui:UiService,
    private templateApi:TemplateApiService,
    public locales:LocaleService,
    private router:Router) { }

  ngOnInit(): void {
  this.isLoading = true;
  this.randomTime = Math.random() * (400 - 150) + 150;
    setTimeout(()=>{
      this._startLoading();
    },500 )
  }

  private _startLoading(){
    const _uid = this.ui.uid;
    if(!_uid){
      setTimeout(()=>{
        this._startLoading();
      },600)
    } else {
      this.loadTemplateItems();
    }
  }

  private async loadTemplateItems(){
    if(this.isUnpublishedMode === true){
      const _resp = await this.templateApi.apiGet(TEMPLATES_CALL.GET_LAST_UNPUBLISHED).then(
        _data => { return _data }
      )
      if(_resp){
        this.templates = _resp as TemplateFlatI[]
      }
      this.dataSource = new MatTableDataSource(this.templates)
      this.isEmpty = this.templates.length > 0 ? false : true;
      this.fadeIn()

    } else {
      const _resp = await this.templateApi.apiGet(TEMPLATES_CALL.GET_LAST_ADDED_PROCESSES).then(
        _data => { return _data }
      )
      if(_resp){
        this.templates = _resp as TemplateFlatI[]
      }
      this.dataSource = new MatTableDataSource(this.templates)
      this.isEmpty = this.templates.length > 0 ? false : true;
      this.fadeIn()

    }

  }

  navigate(path:string, id:string =''){

    let p = id != '' ?
    this.router.navigate([path, id]) : this.router.navigate([path]);
  }
  openDetails(path:number, scs:boolean = false){
    this.router.navigate(['template-detail', path]);

  }

  fadeIn(){
    if(this.isLoading === false){
      return
    }

    this.isLoading = false;
    setTimeout(()=>{
      this.showCard = true
    },200);
  }
  viewTemplate(t){

  }
  getDateFromTS(t:TemplateFlatI) : Date {

    return t.lastModified != null ? new Date(t.lastModified) : new Date(Date.now())
  }


}
