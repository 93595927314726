<div class='BTN' (click)='tapped()'>
  <div class="hit-item" #_hitItem  (mouseover)='mouseover()' (mouseout)='mouseout()'></div>
  <div class="btn-active-background" [class.btn-active-background-active]='_isActive'></div>
  <div class="btn-holder" [class.button-holder-active]='_isActive'>
    <div class="item" [class.item-sub]='hasSubMenu'>
      <div class="bg" [style.background-image]="'url(' + icon + ')'" ></div>
      <div class="item-copy tlc">{{btnTitle}}</div>
    </div>
    <div class="underLineAnimated pl0 pr0" #_underLineAnimated></div>

    <div class="sub-menu-holder" *ngIf='hasSubMenu' #_subMenuHolder (mouseover)='subMenuOver()' (mouseout)='subMenuOut()'>

      <div class="p_t5 pl0 pr0" style="position:absolute; width: 100%;" *ngIf='showSubItems'>

        <div class="subMenuButton" (click)='navigate("patientenverwaltung")'>
          <div class="subMenuButtonIcon">
            <img src="/assets/nav/subMenu/patienten.svg" alt="">
          </div>
          <div class="subMenuButtonCopy">Patienten</div>
        </div>

        <div class="subMenuButton" (click)='navigate("materialliste")'>
          <div class="subMenuButtonIcon">
            <img src="/assets/nav/subMenu/material.svg" alt="">
          </div>
          <div class="subMenuButtonCopy">Material</div>
        </div>

        <div class="subMenuButton" (click)='navigate("mitarbeiter")'>
          <div class="subMenuButtonIcon">
            <img src="/assets/nav/subMenu/mitarbeiter.svg" alt="">
          </div>
          <div class="subMenuButtonCopy">Mitarbeiter</div>
        </div>

         <div class="subMenuButton" (click)='navigate("textbausteine")'>
          <div class="subMenuButtonIcon">
            <img src="/assets/nav/subMenu/abrechnungsziffern.svg" alt="">
          </div>
          <div class="subMenuButtonCopy">Textbausteine</div>
        </div>

        <div class="subMenuButton" (click)='navigate("benutzerkonten")' *ngIf='hasManagerRole'>
          <div class="subMenuButtonIcon">
            <img src="/assets/nav/subMenu/mitarbeiter.svg" alt="">
          </div>
          <div class="subMenuButtonCopy">Benutzerkonten</div>
        </div>

      </div>

    </div>

  </div>

</div>


