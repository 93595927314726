import { Injectable } from '@angular/core';
import { ACCOUNT_VIEW_STATE } from './acount.helper';
import { AccountI, AddressI } from 'src/app/core/models/accountI';
import { UiService } from 'src/app/services/ui.service';
import { Expo, Circ, Power1} from 'gsap/all';
import {TweenMax} from 'gsap';
@Injectable({
  providedIn: 'root'
})
export class AccountViewService{
  
  addresses:AddressI[] = []
  addressIndex = 0
  loadingAccounting = true
  currentAddress:AddressI  = {
    
    id:-1,
    klinikName:'',
    streetName:'',
    streetNumber:'',
    zip:'',
    city:''
  }
  
  account:AccountI

  private _accountPageState = ACCOUNT_VIEW_STATE.MAIN



  get currentViewMode() : ACCOUNT_VIEW_STATE {
    return this._accountPageState
  }

  constructor(public ui:UiService) { 
    
    this.addresses = this.ui.addresses;
    this.currentAddress = this.ui.addresses[0]
    this.account = this.ui.account//__account;

    // if(this.account.doc_description == null) {
    //   this.account.doc_description = ''
    // }
  }

  nav(vs: ACCOUNT_VIEW_STATE) {
    this._accountPageState = vs
  }

  setAccountNavigation() {

  }

  changePasswort() {

  }
  save() {

  }


  fadeIn() {

    TweenMax.to()
    // TweenMax.fromTo(this._authCard.nativeElement, 1, { 'margin-top':newYPositionStart},
    // { 'margin-top':newYPosition, ease: Circ.easeOut, delay: 0.25});

    // TweenMax.fromTo(this._forgotPwContainer.nativeElement, 1, { 'margin-top':newYPositionStart +320},
    // { 'margin-top':newYPosition + 320, ease: Circ.easeOut});

  }
  
}