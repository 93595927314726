import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountI, AddressI } from 'src/app/core/models/accountI';
import { apiRequest, API_CALL } from 'src/app/core/models/api/apiCalls';
import { HORIZONTAL_ITEM_SELECTION_ACTION } from 'src/app/core/models/horizontalItemSelectionAction';
import { AccountService } from 'src/app/services/account.service';
import { ApiUtilService } from 'src/app/services/api-util.service';
import { ACCOUNT_CALL } from 'src/app/services/enums/accountS.callEnum';
import { UiService } from 'src/app/services/ui.service';


@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnDestroy{

  @Input() account:AccountI
  address:AddressI = {
    id:-1,
    abteilung:"",
    arzt_role:"",
    bank_name:"",
    behandelnder_arzt:"",
    bic:"",
    city:"",
    facharzt_bezeichnung:"",
    iban:"",
    klinikName:"",
    kontoinhaber:"",
    streetName:"",
    streetNumber:"",
    ust_nr:"",
    zip:"",
    isMainAddress:false
  }

  currenIndex = 0;
  addresses:AddressI[] = []
  next() {
    if(this.currenIndex < this.addresses.length -1) {
      this.currenIndex ++
    }
  }
  prev() {
    if(this.currenIndex >= 0) {
      this.currenIndex --
    }
  }
  isSaving = false;

  constructor(
    private accountS:AccountService,
    private dialog:MatDialog,
    public ui:UiService,
    private apiUtil:ApiUtilService
  ) { }
  ngOnDestroy(): void {
    console.log("ADDRESS DESTROY")
  }

  ngOnInit(): void {
    console.log("ADDRESS INIT")
    this.addresses = this.ui.addresses
    setTimeout(()=>{
      this.address = this.addresses[0]

      if (!this.ui.isClinicUser) {
        this.address.behandelnder_arzt = this.account.doc_name
      }

      this.address.facharzt_bezeichnung = this.account.doc_description;
    },1000)

  }
  // get addresses():AddressI[] {
  //   return this.ui.addresses;
  // }
  async save(){
    this.address.behandelnder_arzt = this.account.doc_name
    this.address.facharzt_bezeichnung = this.account.doc_description;
    this.isSaving = true;
    if(this.address.id == -1){
      const __data = await this.accountS.apiPostObject(ACCOUNT_CALL.CREATE_NEW_ADDRESS, {payload: this.address} )
      .then(
        (data)=>{
          this.ui.loadAddresses()
          setTimeout(()=>{
            this.isSaving = false;
          },250)
        }, err =>{
          this.isSaving = false;
        })

    } else {
      const __data = await this.accountS.apiPostObjectAndParams(ACCOUNT_CALL.UPDATE_ADDRESS_BY_ID,this.address.id.toString() ,
      { address:this.address } )
      .then(
        (data)=>{
          this.ui.loadAddresses()
          setTimeout(()=>{
            this.isSaving = false;
          },250)
        }, err =>{
          this.isSaving = false;
        })

    }
  }
  addAdress(){
    const newAddress = {
      id:-1,
      abteilung:"",
      arzt_role:"",
      bank_name:"",
      behandelnder_arzt:"",
      bic:"",
      city:"",
      facharzt_bezeichnung:"",
      iban:"",
      klinikName:"Neuer Name",
      kontoinhaber:"",
      streetName:"",
      streetNumber:"",
      ust_nr:"",
      zip:""

    }
    newAddress.behandelnder_arzt = this.account.doc_name

    newAddress.facharzt_bezeichnung = this.account.doc_description;
    this.addresses.push(newAddress)
    
    setTimeout(()=>{
      this.currenIndex = this.addresses.length -1
    },250)
  }

  // get saveDisabled():boolean {
  //   if(this.address.city == null || this.address.city == ''
  //   || this.address.klinikName == null || this.address.klinikName == ''
  //   || this.address.streetName == null || this.address.streetName == ''
  //   || this.address.zip == null || this.address.zip == ''
  //   ) { return true}
  //   return false;
  // }
  // editAddress(address){
  //   this.address = address
  // }
  // addresssChanged(e){
  //   console.log(e);
    
  // }
  async setAsBaseAddress(){
    await this.apiUtil.apiGetP(apiRequest(API_CALL.SET_BASE_ADDRESS, this.address.id.toString()))
    setTimeout(()=>{
      this.ui.loadAddresses()
    },100)
  }

  // async onReportSelectionAction(e: HORIZONTAL_ITEM_SELECTION_ACTION) {
  //   console.log(e);
  //   switch (e) {
  //     case HORIZONTAL_ITEM_SELECTION_ACTION.ADD:
  //       // this.addReport();
  //       return;
  //     case HORIZONTAL_ITEM_SELECTION_ACTION.REMOVE:
  //       // this.removeReport();
  //       return;
  //     case HORIZONTAL_ITEM_SELECTION_ACTION.NEXT:
  //       this.next();
  //       return;
  //     case HORIZONTAL_ITEM_SELECTION_ACTION.PREV:
  //       this.prev()
  //       return;
  //   }
  // }
  onTabChanged(e) {
    this.currenIndex = e.index
    console.log('onTabChanged', e.index);
    
  }
  async onBaseAdressChanged(id:number){
    this.addresses.forEach(element => {
      element.isMainAddress = element.id == id ? true : false
    })
    await this.apiUtil.apiGetP(apiRequest(API_CALL.SET_BASE_ADDRESS, id.toString()))
    setTimeout(()=>{
      this.ui.loadAddresses()
    },100)
    // this.baseAddressChanged.emit(this.address.id)
    // await this.apiUtil.apiGetP(apiRequest(API_CALL.SET_BASE_ADDRESS, this.address.id.toString()))
    // setTimeout(()=>{
    //   this.ui.loadAddresses()
    // },100)
  }
}
