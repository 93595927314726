import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SIGN_IN_MODE } from '../core/models/enums';
import { NavigationService } from '../services/navigation.service';
import { AuthService } from './auth.service';
import { UiService } from '../services/ui.service';
import { ACCOUNTING_STATE } from '../core/models/accountI';

@Injectable({
  providedIn: 'root'
})
export class SecureInnerPagesGuard implements CanActivate {

  constructor(
    public authService: AuthService,
    public router: Router,
    private _nav:NavigationService,
     public ui: UiService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

     

      // && this.ui.accountingState != ACCOUNTING_STATE.REMOVED && this.ui.accountingState != ACCOUNTING_STATE.REJECTED 
      // && this.ui.accountingState != ACCOUNTING_STATE.INVITED
     if(this.authService.isLoggedIn === true && this.authService.sign_in_mode == SIGN_IN_MODE.READY
      ) {

      if(this._nav.initRoute){
        this.router.navigate([this._nav.initRoute]);
      } else {
        this.router.navigate(['dashboard']);
      }
       return false;
    } 
    
    return true;
  }
  
}



