
<div class="d-flex table_cell_button " (click)='viewTemplate()'
*ngIf='currentButtonType === buttonTypes.EDIT'>
<!-- *ngIf='template.privacy === "UNPUBLISHED_PUBLIC"'> -->
<div class="p-0 align-text-end ml-auto">
<mat-icon class="table_cell_button_icon">edit</mat-icon>
</div>
<div class="p-0 table_cell_button_copy">Bearbeiten</div>
</div>

<div class="d-flex table_cell_button " (click)='clonePublicTemplateTapped()' matTooltipClass='sectionGoaeTT'
matTooltip='Mit Hilfe dieser Vorlage eine Rechnung / OP Bericht erstellen' matTooltipPosition='above'
*ngIf='currentButtonType === buttonTypes.CLONE'>

<div class="p-0 align-text-end ml-auto">
<mat-icon class="table_cell_button_icon">cloud_download</mat-icon>
</div>
<div class="p-0 table_cell_button_copy">Übernehmen</div>
</div>

    

<div class="d-flex table_cell_button" (click)='addTemplateToProcessesTapped()' matTooltipClass='sectionGoaeTT'
matTooltip='Diese doxfox Vorlage zu meinen Leistungen übernehmen, um damit eine Rechnungen zu erstellen.' matTooltipPosition='above'
*ngIf='currentButtonType === buttonTypes.CREATE_PROCESS'>

<div class="p-0 align-text-end ml-auto">
<mat-icon class="table_cell_button_icon">move_to_inbox</mat-icon>
</div>
<div class="p-0 table_cell_button_copy">Verwenden</div>
</div>

<div class="d-flex table_cell_button" (click)='cloneToOnwPool()' matTooltipClass='sectionGoaeTT'
matTooltip='Diese doxfox Vorlage zu meinen Öffentlichen Vorlagen übernehmen.' matTooltipPosition='above'
*ngIf='currentButtonType === buttonTypes.DUPLICATE'>

<div class="p-0 align-text-end ml-auto">
<mat-icon class="table_cell_button_icon">move_to_inbox</mat-icon>
</div>
<div class="p-0 table_cell_button_copy">Dupllizieren</div>
</div>