import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/shared/auth.service';
import { ControllerService } from 'src/app/shared/controller.service';
import { Router } from '@angular/router';
import {FormControl, Validators} from '@angular/forms';
import {Expo, Circ, Power1 } from 'gsap/all'
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
declare var TweenMax: any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../auth.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('_authCard') _authCard:ElementRef;
  @ViewChild('_forgotPwContainer') _forgotPwContainer:ElementRef;
  _currentCenter = 0
  email = ''
  loginLoading = false;

  _emailFC:FormControl = new FormControl('', Validators.compose([
		Validators.required,
    // Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')
		Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
	]))
  // emailFormControl = new FormControl('', [
  //   Validators.required,
  //   Validators.email,
  // ]);
  constructor( public authService: AuthService, private router:Router, private http:HttpClient) { }

  ngOnInit() {

    ControllerService.windowResize.subscribe(
      _newWindowSize => {
         if(this._currentCenter != _newWindowSize.height / 2){
          this.changeCenterY(_newWindowSize.height / 2);
         }
      }
    )
    setTimeout(()=>{
      this.fadeIn();
    }, 250)
  }

  changeCenterY(newCenterY:number){
    let newYPositionStart = this._currentCenter - 190
    this._currentCenter =  newCenterY
    let newYPosition = newCenterY - 190
    TweenMax.fromTo(this._authCard.nativeElement, 0.65, { 'margin-top':newYPositionStart},
    { 'margin-top':newYPosition, ease: Expo.easeOut, });
    TweenMax.fromTo(this._forgotPwContainer.nativeElement, 1, { 'margin-top':newYPositionStart +320},
    { 'margin-top':newYPosition + 320, ease: Circ.easeOut});

  }
  fadeIn(){
    this._currentCenter =  (ControllerService.currentScreenSize.height / 2)
    let newYPositionStart = (ControllerService.currentScreenSize.height / 2) - 250
    let newYPosition = (ControllerService.currentScreenSize.height / 2) - 190
    TweenMax.fromTo(this._authCard.nativeElement, 0.65, { 'opacity':0 , 'margin-top':newYPositionStart},
    { 'opacity':1, 'margin-top':newYPosition, ease: Expo.easeOut, delay: 0.25});
    TweenMax.fromTo(this._forgotPwContainer.nativeElement, 1, { 'margin-top':newYPosition },
    { 'margin-top':newYPosition + 320, ease: Expo.easeInOut, delay: 2.1});

    TweenMax.fromTo(this._forgotPwContainer.nativeElement, 0.35, { 'opacity':0},
    { 'opacity':1, ease: Power1, delay: 2.2});
  }

  fadeOut(){
    this._currentCenter =  (ControllerService.currentScreenSize.height / 2)
    let newYPositionStart = (ControllerService.currentScreenSize.height / 2) - 190
    let newYPosition = (ControllerService.currentScreenSize.height / 2) - 100
    TweenMax.fromTo(this._authCard.nativeElement, 0.65, { 'opacity':1 , 'margin-top':newYPositionStart},
    { 'opacity':0, 'margin-top':newYPosition, ease: Expo.easeOut, });
    TweenMax.to(this._forgotPwContainer.nativeElement, 0.35,
      { 'opacity':0,'margin-top':newYPositionStart, ease: Power1});
  }
  emailChanged(e){
    // console.log(this._emailFC.valid)
    // this.checkValidation();

  }
  resetPasswort(){
    this.loginLoading = true;
    this.postForgot()
  /*   this.authService.RecoverPassword(this.email);
    setTimeout(()=>{
      this.navigate("auth/sign-in")
    },1000) */
  }
  checkValidation(){
    if(this.email != ''){
      // this.cta = true;


    }
    // if(this.emailFormControl.valid) {

    //   }
  }
  get cta():boolean {
    return this._emailFC.valid
  }
  async postForgot(){
    const _path =  environment.endpoints.apiBasePathV2 +"auth/lost-password"
    const data = {
      "payload":{
        'email': this.email
        }

    }
    const message = await this.http.post(_path, data).toPromise().then(
      _data => {
        console.log(_data)
        this.authService.SignOut()
        return _data;
      }
    )
    if(message['status']=="error"){
      if(message["message"] != null){
        switch (message["message"]["code"]) {
          case 'auth/wrong-password':
            ControllerService.snack.emit('Ihr Passwort ist falsch');
            break;
          case 'auth/user-not-found':
            ControllerService.snack.emit('dieser benuter wurde nicht gefunden');
            break;
          case 'auth/invalid-email':
            ControllerService.snack.emit('ungültige email');
            break;
          default:
            ControllerService.snack.emit('ein fehler ist aufgetreten');
            break;
        }
      }
    } else if(message['status']=='ready'){
      this.navigate("auth/sign-in")
    }

   /*  setTimeout(()=>{
      this.navigate("auth/sign-in")
    },1000) */
  }
  navigate(path:string){
    this.fadeOut()
    setTimeout(()=>{
      this.router.navigateByUrl(path)
    },650)
  }

}
