import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { POSITION_TYPE } from 'src/app/core/models/enums';
import { FullPositionOverlayData, TEXT_FOCUS_ELEMENT } from 'src/app/core/models/final/textoverlay.model';
import { GoaJustificationI } from 'src/app/core/models/goaJustificationI';
import { PositionFlatI, PositionFullI, ProgressionFactorItem } from 'src/app/core/models/sectionPositionM';
import { SurgeryReportItenV4I } from 'src/app/core/models/v3/SurgeryReportItemIV3';
import { PositionApiService } from 'src/app/services/position-api.service';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import * as _ from 'lodash';
import { AlertOverlayComponent } from '../../overlays/alert-overlay/alert-overlay.component';
import { AlertActionI } from 'src/app/core/models/alertM';
import { LocaleService } from 'src/app/services/locale.service';
import { MatSelectChange } from '@angular/material/select';
import { HORIZONTAL_ITEM_SELECTION_ACTION } from 'src/app/core/models/horizontalItemSelectionAction';

@Component({
  selector: 'app-template-position-overlay',
  templateUrl: './template-position-overlay.component.html',
  styleUrls: ['./template-position-overlay.component.scss']
})
export class TemplatePositionOverlayComponent implements OnInit {

  @ViewChild('exampleTextArea') exampleTextArea: ElementRef;
  @ViewChild('_textArea') _textArea: ElementRef;
  @ViewChild('_justificationTextArea',{static: false}) _justificationTextArea: ElementRef;
  @ViewChild('_titleTextField',{static: false}) _titleTextField: ElementRef;
  @ViewChild('_justificationTitleTextField',{static: false}) _justificationTitleTextField: ElementRef;
  overlayData: FullPositionOverlayData
  isSaving: boolean = false;
  isLoaded: boolean = false;
  editable: boolean = true;
  resizableTextarea: boolean = true;
  isTitleEditMode: boolean = false
  isJustificationTitleEditMode: boolean = false
  private _position_id: number
  goaJustifications:GoaJustificationI[] = []
  selectedJustification
  position_full: PositionFullI;
  position: PositionFlatI
  activeIndex: number = 0
  currentJustificationIndex: number = 0
  exampleCopy: string = '';

  reportItems:SurgeryReportItenV4I[] = []
  selectedReportItem
  POSITION_TYPES = POSITION_TYPE

  hasQuantityFocus = false;
  hasProgressFactorFocus = false;
  progression_factors:ProgressionFactorItem[] = []
  // TODO CHECK!!!
  // customProgressFactors:CustomProgressionFactorI[] = []
  //---------------------------------------------------------------
  constructor(
    public locales: LocaleService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<TemplatePositionOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FullPositionOverlayData,
    private positionApi:PositionApiService,
    public tc: TemplateControllerService
  ) { 
    this.overlayData = data;
    this._position_id = data.position.position_id
    this.position = data.position
    this.exampleCopy = data.position.example
    
  }

  //---------------------------------------------------------------
  ngOnInit(): void {
    console.log('\n\n>>>>>>>>this.initData();');
    
    this.progression_factors = this.tc.progressionFactors
  //   if(this.overlayData) {
  //     if(this.overlayData.textfocusElement) {
  //       setTimeout(() => {
  //         if(this.overlayData.textfocusElement == TEXT_FOCUS_ELEMENT.QUANTITY) {
  //           this.hasQuantityFocus = true;
  //         } else if(this.overlayData.textfocusElement == TEXT_FOCUS_ELEMENT.PROGRESS_FACTOR) {
  //           this.hasProgressFactorFocus = true;
  //         }
  //       },200);
        
  //     }
  // }
    this.initData();
    // this.dialogRef.beforeClosed().subscribe(
    //   _data => {
    //     this.dialogRef.
    //     console.log(_data);
        
    //   }
    // )
  }

  //---------------------------------------------------------------
  private async initData() {
    console.log(`TemplatePositionOverlay - initData() - position_id ${this._position_id}`);
    await this.loadReportItems();
    this.initGoaJustifications();
  }

  //---------------------------------------------------------------
  private async loadReportItems() {
    if(this.overlayData.reportItems) {
      this.reportItems = this.overlayData.reportItems
    } else {
      const _items = await this.tc.loadSurgReportItemsByPosId(this.tc.prefix, this.position.position_id)
      this.reportItems = _items;
    }
  }

  //---------------------------------------------------------------
  private initGoaJustifications() {
    if(this.position && this.position.justifications) {
      // const _factorItem = this.progression_factors.find(item => item.factor == this.position.progression_factor)
      this.goaJustifications = this.position.justifications//.filter(item => item.public_factor == _factorItem.factor)
      var _latestMod
      for(var i = 0; i < this.goaJustifications.length; i++) {
        const jstf = this.goaJustifications[i]
        if(!_latestMod){
          _latestMod = jstf.lastModified
        }
        if(jstf.lastModified > _latestMod){
          _latestMod = jstf.lastModified
          this.currentJustificationIndex = i
        }
      }
      
      this.selectedJustification = this.goaJustifications[this.currentJustificationIndex]
    }
    this.dataComplete();
  }

  //---------------------------------------------------------------
  private dataComplete() { 
    setTimeout(() => {
      this.isLoaded = true;
      const fIndex = this.reportItems.findIndex(item => item.is_active === true);
      this.activeIndex = fIndex != null && fIndex !== -1 ? fIndex : 0;
      if(this.reportItems[this.activeIndex] != null) {
        this.selectedReportItem = this.reportItems[this.activeIndex]
      }
    },200);
    setTimeout(() => {
    if(this.overlayData) {
        if(this.overlayData.textfocusElement) {

          switch(this.overlayData.textfocusElement) {
            case TEXT_FOCUS_ELEMENT.EXAMPLE_COPY:
              this.exampleTextArea.nativeElement.focus();
            return;
            case TEXT_FOCUS_ELEMENT.SURGERY_ITEM_COPY:
              this._textArea.nativeElement.focus();
            return;
            case TEXT_FOCUS_ELEMENT.SURGERY_ITEM_DESCRIPTION:
              this.setTitleEditMode()
            return;
            case TEXT_FOCUS_ELEMENT.JUSTIFICATION_COPY:
              this._justificationTextArea.nativeElement.focus();
            return;
            case TEXT_FOCUS_ELEMENT.PREV_REPORT:
              this.prevReport()
            return;
            case TEXT_FOCUS_ELEMENT.NEXT_REPORT:
              this.nextReport()
            return;
            case TEXT_FOCUS_ELEMENT.ADD_REPORT:
              this.addReport()
            return;
            case TEXT_FOCUS_ELEMENT.REMOVE_REPORT:
              this.removeReport()
            return;
            case TEXT_FOCUS_ELEMENT.QUANTITY:
              this.hasQuantityFocus = true;
            return;
            case TEXT_FOCUS_ELEMENT.PROGRESS_FACTOR:
              this.hasProgressFactorFocus = true;
            return;
          }
        }
      }
     
      },250);
  }

  //---------------------------------------------------------------
  // Justifications
  //---------------------------------------------------------------
  async createNewJustificationText() {
    
  }
  //---------------------------------------------------------------

  // Textfield Events
  //---------------------------------------------------------------
  async onTextEscape(event:any) {
    const _isEqual = _.isEqual(event.target.value, this.exampleCopy);
    if(!_isEqual) {
      this.isSaving = true;
      await this.tc.updateSinglePositionItem(this.tc.currentTemplateId, this.position.position_id, {example: event.target.value})
      this.exampleCopy = event.target.value;
      this.position.example = this.exampleCopy;
      setTimeout(() => {
        this.isSaving = false;
      },150)
    } else {
      this.closeOverlay();
    }
  }
  //---------------------------------------------------------------
  onTextFieldKeyUp(event:any) {

  }
  //---------------------------------------------------------------
  async onTextFieldBlur(event: any){
    const _isEqual = _.isEqual(event.target.value, this.exampleCopy);
    if(!_isEqual) {
      // this.isSaving = true;
      await this.tc.updateSinglePositionItem(this.tc.currentTemplateId, this.position.position_id, {example: event.target.value})
      this.exampleCopy = event.target.value;
      this.position.example = this.exampleCopy;
      // setTimeout(() => {
      //   this.isSaving = false;
      // },150)
    }
  }


  //---------------------------------------------------------------
  private async prevReport(){
    if(!this.editable) { return; }
    if(this.activeIndex == 0) { return; } else {
      this.activeIndex --;
    }
    this.selectedReportItem = this.reportItems[this.activeIndex]
    this.updateActiveSurgeryReport()
  }
  //---------------------------------------------------------------
  private async nextReport() {
    if(!this.editable) { return; }
    if(this.activeIndex == (this.reportItems.length - 1)){
      return;
    } else {
      this.activeIndex ++;
    }
    this.selectedReportItem = this.reportItems[this.activeIndex]
    this.updateActiveSurgeryReport()
  }

  //---------------------------------------------------------------
  private async addReport() {
    if(!this.editable) { return; }
    let newIndexCopy = this.reportItems.length == 0 ? '1' : this.reportItems.length + 1
    let newItem = {
      surgery_report_item_id: -1,
      template_id: this.position.template_id,
      position_id: this.position.position_id,
      is_active: true,
      description: `Variante ${newIndexCopy}`,
      text: '...' 
    }
    const insertId = await this.tc.addSurgReportPosItem(this.tc.prefix,this.tc.currentTemplateId, this.position.position_id)
    let newId:number = insertId as number
    newItem.surgery_report_item_id = newId
    this.reportItems.push(newItem);
    this.activeIndex =  this.reportItems.length - 1
    setTimeout(() => {
      //this.checkEditMode()
      this.selectedReportItem = this.reportItems[this.activeIndex]
      this._textArea.nativeElement.focus();
      this._textArea.nativeElement.select();
    }, 250);

  }

  //---------------------------------------------------------------
  private async removeReport() {
    const dialogReg = this.dialog.open(AlertOverlayComponent, {
      width: '400px',
      data: {
        title: this.locales.locales['SURGERY_REPORT'].DELETE_ALERT.TITLE,
        bodyMessage: this.locales.locales['SURGERY_REPORT'].DELETE_ALERT.BODY,
          actions: [
            {title: this.locales.locales['SURGERY_REPORT'].DELETE_ALERT.ACTION_RESUME, color: AlertActionI.BUTTON_COLOR_TYPE.WARN, actionType: AlertActionI.BUTTON_ACTION_TYPE.RESUME},
            {title: this.locales.locales['SURGERY_REPORT'].DELETE_ALERT.ACTION_CANCEL, color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType: AlertActionI.BUTTON_ACTION_TYPE.CANCEL}
          ]
        },
      backdropClass: 'backdrop-background'
    }).afterClosed().subscribe((result) => {
      if (result != null){
        if (result === AlertActionI.BUTTON_ACTION_TYPE.RESUME){
          this.finalRemoveReport();
        }
      }
    });

  }
  private async finalRemoveReport(){
    const deleteId = this.reportItems[this.activeIndex].surgery_report_item_id
    const index = this.reportItems.findIndex(item => item.surgery_report_item_id == deleteId)
    if(index  != -1){
      this.reportItems.splice(index, 1)
    }
    await this.tc.removeSurgeryReportItem(this.tc.prefix,deleteId,this.tc.currentTemplateId)
    await this.loadReportItems();
    setTimeout(() => {
      this.activeIndex = index != -1 && index > 0 ? index - 1 : 0;
      this.updateActiveSurgeryReport();
    }, 100);
    setTimeout(() => {
      this.selectedReportItem = this.reportItems[this.activeIndex]
     }, 500);
  }


  async onReportSelectionAction(e: HORIZONTAL_ITEM_SELECTION_ACTION) {
    console.log(e);
    switch (e) {
      case HORIZONTAL_ITEM_SELECTION_ACTION.ADD:
        this.addReport();
        return;
      case HORIZONTAL_ITEM_SELECTION_ACTION.REMOVE:
        this.removeReport();
        return;
      case HORIZONTAL_ITEM_SELECTION_ACTION.NEXT:
        this.nextReport();
        return;
      case HORIZONTAL_ITEM_SELECTION_ACTION.PREV:
        this.prevReport()
        return;
    }
  }

  //---------------------------------------------------------------
  async changed(event: MatSelectChange) {
    const index = this.reportItems.findIndex(item => item.surgery_report_item_id == event.value.surgery_report_item_id)
    if(index != -1) {
      this.activeIndex = index;
      if(this.reportItems[this.activeIndex] != null) {
        this.selectedReportItem = this.reportItems[this.activeIndex]
      }
      this.updateActiveSurgeryReport()
    }
  }
  //---------------------------------------------------------------
  setTitleEditMode() {
    this.isTitleEditMode = true
    setTimeout(() => {
      this._titleTextField.nativeElement.focus()
      this._titleTextField.nativeElement.select()
    },150)
  }
  
  //---------------------------------------------------------------
  async updateActiveSurgeryReport(){
    if (this.reportItems.length == 0) { return }
    await this.tc.updateActiveSurgeryReport(this.tc.prefix,this.reportItems[this.activeIndex].position_id, this.reportItems[this.activeIndex].surgery_report_item_id)
    if (!this.reportItems[this.activeIndex]) {
      return
    }
    this.reportItems[this.activeIndex].surgery_report_item_id
    for(const report of this.reportItems) {
      if(report.surgery_report_item_id == this.reportItems[this.activeIndex].surgery_report_item_id) {
        report.is_active = true;
      } else {
        report.is_active = false;
      }
    }
    //this.setTextAreaFocus()
  }
  //---------------------------------------------------------------
  async onTextFieldTitleEnter(event: any) {
    if(this.getReportionCopyDescription != event.target.value) {
      this.getReportionCopyDescription = event.target.value
      await this.tc.updateSingleReport(this.tc.prefix, this.reportItems[this.activeIndex], this.reportItems[this.activeIndex].surgery_report_item_id)
    } 
    this.isTitleEditMode = false
    setTimeout(() => {
      this._textArea.nativeElement.focus()
    },150)
  }
  onTextFieldTitleEscape(event: any) {
    if(this.getReportionCopyDescription != event.target.value) {
      this.getReportionCopyDescription = event.target.value
      this.tc.updateSingleReport(this.tc.prefix, this.reportItems[this.activeIndex], this.reportItems[this.activeIndex].surgery_report_item_id)
    } 
    this.isTitleEditMode = false
  }
  //---------------------------------------------------------------
  reportDescriptionInputChanged() {

  }
  //---------------------------------------------------------------
  async reportDescriptionInputBlur(event: any) {
    if(this.getReportionCopyDescription != event.target.value) {
      this.getReportionCopyDescription = event.target.value
      await this.tc.updateSingleReport(this.tc.prefix, this.reportItems[this.activeIndex], this.reportItems[this.activeIndex].surgery_report_item_id)
    } 
    this.isTitleEditMode = false
    setTimeout(() => {
      this._textArea.nativeElement.focus()
    },150)
  }
  //---------------------------------------------------------------
  reportCopyAreaChanged() {
    
  }
  //---------------------------------------------------------------
  resizeEvent(event: any){
    console.log('resizeEvent', event);
  }
  //---------------------------------------------------------------
  async onReportCopyAreaEscape(event: any) {
    if(this.getReportionCopy != event.target.value) {
      this.getReportionCopy = event.target.value
      // this.isSaving = true;
      
      if(this.reportItems.length == 0) {
        const description = 'Variante 1';
        const text = event.target.value
        const _id = await this.tc.addSurgReportPosItemFull(this.tc.prefix, this.tc.currentTemplateId, this.position.position_id, text, description );
        this.reportItems.push({
          surgery_report_item_id: _id,
          is_active: true, 
          template_id: this.tc.currentTemplateId, 
          position_id: this.position.position_id,
          description: description, 
          text: text
        });
        
      } else {
        await this.tc.updateSingleReport(this.tc.prefix, this.reportItems[this.activeIndex], this.reportItems[this.activeIndex].surgery_report_item_id)
      }
    } 
  }
  //---------------------------------------------------------------
  async reportCopyAreaBlur(event: any) {
    if(this.getReportionCopy != event.target.value) {
      this.getReportionCopy = event.target.value
      // this.isSaving = true;
      
      if(this.reportItems.length == 0) {
        const description = 'Variante 1';
        const text = event.target.value
        const _id = await this.tc.addSurgReportPosItemFull(this.tc.prefix, this.tc.currentTemplateId, this.position.position_id, text, description );
        this.reportItems.push({
          surgery_report_item_id: _id,
          is_active: true, 
          template_id: this.tc.currentTemplateId, 
          position_id: this.position.position_id,
          description: description, 
          text: text
        });
        
      } else {
        await this.tc.updateSingleReport(this.tc.prefix, this.reportItems[this.activeIndex], this.reportItems[this.activeIndex].surgery_report_item_id)
      }
    } 
  }
  //---------------------------------------------------------------
  async surgeryRelevantCB_changed(){
    await this.tc.updateSinglePositionItem(this.tc.currentTemplateId, this.position.position_id, {surgery_relevant: this.position.surgery_relevant})
  }
  //---------------------------------------------------------------
  quantityChanged(val:number){
    if(this.position) {
      this.tc.updateSinglePositionItem(this.tc.currentTemplateId, this.position.position_id, {quantity: val})
      this.position.quantity = val;
    }
  }
  //---------------------------------------------------------------
  // progressionFactorChanged(changeItem:PositionProgressionFactorChanged){
  //   this.tc.updateSinglePositionItem(this.tc.currentTemplateId, changeItem.position.position_id, {progression_factor: changeItem.factor})
  //   // setTimeout(()=>{
  //   //   this.checkProgressFacts()
  //   // },100)
  // }
  //---------------------------------------------------------------
  // customFactorsByPos(position_id:number):CustomProgressionFactorI[] {
  //   return this.customProgressFactors.filter(item => item.position_id == position_id);
  // }
  //---------------------------------------------------------------
  // getPositionProgressionFactors(position_id:number):PositionProgressionFactors[] {
  //   var _all:PositionProgressionFactors [] = []
  //   this.progression_factors.forEach(_item => {
  //     _all.push({
  //       id: _item.id,
  //       isPublic: true,
  //       factor: _item.factor,
  //       view_value: _item.view_value
  //     })
  //   })
  //   this.customFactorsByPos(position_id).forEach(_customItem => {
  //     _all.push({
  //       isPublic: false,
  //       factor: _customItem.factor,
  //       id: _customItem.id,
  //       view_value: _customItem.view_value
  //     })
  //   })
  //   return _all
  // }
  //---------------------------------------------------------------
  get getReportionCopyDescription():string {
    if(this.reportItems[this.activeIndex] != null && this.reportItems[this.activeIndex].description != null){
      return this.reportItems[this.activeIndex].description
    }
    return ''
  }
  set getReportionCopyDescription(value:string) {
    if(this.reportItems[this.activeIndex] != null && this.reportItems[this.activeIndex].description != null){
     this.reportItems[this.activeIndex].description = value
    }
  }
  get getReportionCopy():string {
    if(this.reportItems[this.activeIndex] != null && this.reportItems[this.activeIndex].text != null){
      return this.reportItems[this.activeIndex].text
    }
    return ''
  }
  set getReportionCopy(val) {
    if(this.reportItems[this.activeIndex] != null && this.reportItems[this.activeIndex].text != null){
      this.reportItems[this.activeIndex].text = val
    }
  }
  //---------------------------------------------------------------
  get goaText():string {
    if(this.position && this.position.position_type == POSITION_TYPE.OWN){
      return this.position.own_long
    } else if(this.position.goa_paragraph == null){
      return ''
    } else {
      return this.position.goa_paragraph.long_description
    }
  }

  //---------------------------------------------------------------
  get itemNeedJustification():boolean {
    if(this.position && this.position.progression_factor >  2.3) {
      return true;
    }
    return  false;
  }
  //---------------------------------------------------------------
  get showJustificationButton(): boolean {
    if(this.goaJustifications.length == 0 && this.itemNeedJustification) {
      return true
    }
    return false
  }
  //---------------------------------------------------------------
  openJustificationOverlay(){
    console.log('openJustificationOverlay')
    this.addJustification()
  }

  //---------------------------------------------------------------
  // async prevJusitification() {
  //   if(this.currentJustificationIndex > 0){
  //     this.currentJustificationIndex  --
  //     this.selectedJustification = this.goaJustifications[this.currentJustificationIndex]
  //     await this.updateItem(this.goaJustifications[this.currentJustificationIndex], {})
  //   }
  // }

  //---------------------------------------------------------------
  // async nextJusitification() {
  //   if(this.currentJustificationIndex < this.goaJustifications.length -1){
  //     this.currentJustificationIndex ++
  //     this.selectedJustification = this.goaJustifications[this.currentJustificationIndex]
  //     await this.updateItem(this.goaJustifications[this.currentJustificationIndex], {})
  //   }
  // }

  //---------------------------------------------------------------
  async addJustification() {
    let newIndexCopy = this.goaJustifications.length == 0 ? '1' : this.goaJustifications.length + 1
    let newItem = {
      id: -1,
      template_id: this.position.template_id,
      position_id: this.position.position_id,
      lastModified: new Date().getTime(),
      description: `Variante ${newIndexCopy}`,
      text: 'Neue Begründung...' 
    }
    const insertId = await this.tc.addGoaJustification(this.position.position_id, newItem)
    let newId:number = insertId as number
    newItem.id = newId
    this.goaJustifications.push(newItem);
    this.currentJustificationIndex = this.goaJustifications.length - 1
    this.selectedJustification = this.goaJustifications[this.currentJustificationIndex]
    setTimeout(()=> {
      this._justificationTextArea.nativeElement.focus()
      this._justificationTextArea.nativeElement.select()
    },500)
  }

  //---------------------------------------------------------------
  private removeJustification() {
    let _deleteId = -1;
    let _deleteIndex = -1;
    if(!this.goaJustifications[this.currentJustificationIndex]) { 
      return;
    } else {
      _deleteId = this.goaJustifications[this.currentJustificationIndex].id;
      _deleteIndex = this.goaJustifications.findIndex(item => item.id == _deleteId);
    }
    if(_deleteId == -1 || _deleteIndex == -1) { return }
    const dialogReg = this.dialog.open(AlertOverlayComponent, {
      width:"400px",
      data: {
        title: this.locales.locales['JUSTIFICATION'].DELETE_ALERT.TITLE,
        bodyMessage: this.locales.locales['JUSTIFICATION'].DELETE_ALERT.BODY,
          actions: [
            {title: this.locales.locales['JUSTIFICATION'].DELETE_ALERT.ACTION_RESUME, color: AlertActionI.BUTTON_COLOR_TYPE.WARN, actionType: AlertActionI.BUTTON_ACTION_TYPE.RESUME},
            {title: this.locales.locales['JUSTIFICATION'].DELETE_ALERT.ACTION_CANCEL, color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType: AlertActionI.BUTTON_ACTION_TYPE.CANCEL}
          ]
        },
      backdropClass:"backdrop-background"
    }).afterClosed().subscribe((result) => {
      if(result != null){
        if(result === AlertActionI.BUTTON_ACTION_TYPE.RESUME){
          this.finalRemoveJustification(_deleteId, _deleteIndex)
        }
      }
    });
  }
  private async finalRemoveJustification(id:number, deleteIndex:number){
    await this.tc.deleteGOAJustificationById(id);
    this.goaJustifications.splice(deleteIndex, 1)
    this.currentJustificationIndex = 0;
    this.selectedJustification = this.goaJustifications[this.currentJustificationIndex]
    // await this.updateItem(this.goaJustifications[this.currentJustificationIndex], {})

  }
  //---------------------------------------------------------------
  //---------------------------------------------------------------
  justificationChanged(event: MatSelectChange) {
    const index = this.goaJustifications.findIndex( item => item.id == event.value.id)
    if(index != -1) {
      this.currentJustificationIndex = index
      this.goaJustifications[this.currentJustificationIndex].lastModified = new Date().getTime();
      this.tc.updateGoaJustification(this.goaJustifications[this.currentJustificationIndex].id, {})
    }
  }
  //---------------------------------------------------------------
  async updateItem(item:GoaJustificationI, object: Object){
    item.lastModified = new Date().getTime();
    await this.tc.updateGoaJustification(item.id, object)
  }
  //---------------------------------------------------------------
  get getJustificationCopy():string {
    if(this.goaJustifications[this.currentJustificationIndex] && this.goaJustifications[this.currentJustificationIndex].text){
      return this.goaJustifications[this.currentJustificationIndex].text
    }
    return ''
  }
  set getJustificationCopy(value: string) {
    if(this.goaJustifications[this.currentJustificationIndex] && this.goaJustifications[this.currentJustificationIndex].text){
     this.goaJustifications[this.currentJustificationIndex].text =  value
    }
  }
  get justificationDescription():string {
    if(this.goaJustifications[this.currentJustificationIndex] && this.goaJustifications[this.currentJustificationIndex].description){
       return this.goaJustifications[this.currentJustificationIndex].description
       //`Variante ${this.currentJustificationIndex + 1}`//
    }
    return `Variante ${this.currentJustificationIndex + 1}`
  }
  set justificationDescription(value: string) {
    if(this.goaJustifications[this.currentJustificationIndex] && this.goaJustifications[this.currentJustificationIndex].description){
       this.goaJustifications[this.currentJustificationIndex].description = value
    }
  }
  //---------------------------------------------------------------
  setJustificationTitleEditMode() {
    this.isJustificationTitleEditMode = true
    setTimeout(() => {
      this._justificationTitleTextField.nativeElement.focus()
      this._justificationTitleTextField.nativeElement.select()
    },150)
  }
  //---------------------------------------------------------------
  onJustificationTitleEnter(event: any) {
    // console.log('onJustificationTitleEnter ', event.target.value)
    if(this.justificationDescription != event.target.value) {
      this.justificationDescription = event.target.value
      this.updateItem(this.goaJustifications[this.currentJustificationIndex], {description: this.justificationDescription})
    } 
    this.isJustificationTitleEditMode = false
    setTimeout(() => {
      this._justificationTextArea.nativeElement.focus()
    },150)
  }
  onJustificationTitleEscape(event: any) {
    if(this.justificationDescription != event.target.value) {
      this.justificationDescription = event.target.value
      this.updateItem(this.goaJustifications[this.currentJustificationIndex], {description: this.justificationDescription})
    } 
    this.isJustificationTitleEditMode = false

  }
  //---------------------------------------------------------------
  justificationDescriptionInputBlur(event: any) {
    // console.log('Description Blur ', event.target.value)
    if(this.justificationDescription != event.target.value) {
      this.justificationDescription = event.target.value
      this.updateItem(this.goaJustifications[this.currentJustificationIndex], {description: this.justificationDescription})
    } 
    this.isJustificationTitleEditMode = false
    setTimeout(() => {
      this._justificationTextArea.nativeElement.focus()
    },150)
  }
  //---------------------------------------------------------------
  justificationDescriptionInputChanged() {

  }
  //---------------------------------------------------------------
  justificationCopyAreaChanged() {

  }
  //---------------------------------------------------------------
  justificationResizeEvent(event: any){
    // console.log('justificationResizeEvent', event);
  }
  //---------------------------------------------------------------
  async onJustificationTextAreaEscape(event: any) {
    if(this.getJustificationCopy != event.target.value) {
      this.getJustificationCopy = event.target.value
      // this.isSaving = true;
      this.updateItem(this.goaJustifications[this.currentJustificationIndex], {text: this.getJustificationCopy})
      // setTimeout(() => {
      //   this.isSaving = false;
      // },150)
    } 
  }
  //---------------------------------------------------------------
  async justificationTextAreaBlur(event: any) {
    if(this.getJustificationCopy != event.target.value) {
      this.getJustificationCopy = event.target.value
      // this.isSaving = true;
      this.updateItem(this.goaJustifications[this.currentJustificationIndex], {text: this.getJustificationCopy})
      // setTimeout(() => {
      //   this.isSaving = false;
      // },150)
    } 
  }


  //---------------------------------------------------------------
  closeOverlay() {
    console.log('CLOSE');
    
    this.position.justifications = this.goaJustifications
    this.dialogRef.close(this.reportItems);
  }


  // closeTapped(e:any) {
  //   console.log(e);
    
  //   console.log('CLOSE');
  //   e.stopPropagation();
  //   e.preventDefault();
  // }

  // Surgery Reports // TODO delete if not needed
  //---------------------------------------------------------------
  async createSurgeryReportItem() {

  }
  //---------------------------------------------------------------
  async updateSurgeryReportItem() {

  }
  //---------------------------------------------------------------
  async deleteSurgeryReportItem() {

  }

  async onJustificationSelectionAction(e: HORIZONTAL_ITEM_SELECTION_ACTION) {
    console.log(e);
    switch (e) {
      case HORIZONTAL_ITEM_SELECTION_ACTION.ADD:
        this.addJustification();
        return;
      case HORIZONTAL_ITEM_SELECTION_ACTION.REMOVE:
        this.removeJustification();
        return;
      case HORIZONTAL_ITEM_SELECTION_ACTION.NEXT:
        if(this.currentJustificationIndex < this.goaJustifications.length -1){
          this.currentJustificationIndex ++
          this.selectedJustification = this.goaJustifications[this.currentJustificationIndex]
          await this.updateItem(this.goaJustifications[this.currentJustificationIndex], {})
        }
        return;
      case HORIZONTAL_ITEM_SELECTION_ACTION.PREV:
        if(this.currentJustificationIndex > 0){
          this.currentJustificationIndex  --
          this.selectedJustification = this.goaJustifications[this.currentJustificationIndex]
          await this.updateItem(this.goaJustifications[this.currentJustificationIndex], {})
        }
        return;
    }
  }
}
