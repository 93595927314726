import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressI, UserAddressOverlayComponentData } from 'src/app/core/models/accountI';
import { AccountService } from 'src/app/services/account.service';
import { ACCOUNT_CALL } from 'src/app/services/enums/accountS.callEnum';

@Component({
  selector: 'app-user-address-overlay',
  templateUrl: './user-address-overlay.component.html',
  styleUrls: ['./user-address-overlay.component.scss']
})
export class UserAddressOverlayComponent implements OnInit {

  isSaving:boolean = false
  title:string = 'Neue Adresse hinzufügen'
  newAddress:AddressI  = {
    id:-1,
    klinikName:'',
    streetName:'',
    streetNumber:'',
    zip:'',
    city:''
  }
  constructor(public dialogRef: MatDialogRef<UserAddressOverlayComponent>,
    private accountS:AccountService,  @Inject(MAT_DIALOG_DATA) public data: UserAddressOverlayComponentData
    ) {
      this.newAddress = this.data.address
      this.title = this.data.mode == 'Adresse bearbeiten' ? "" : "Neue Adresse hinzufügen"

     }



  ngOnInit(): void {

  }
  async save(){
    this.isSaving = true;
    if(this.data.mode == 'edit'){
      await this.accountS.apiPostObjectAndParams(ACCOUNT_CALL.UPDATE_ADDRESS_BY_ID,this.newAddress.id.toString() ,{payload: this.newAddress} )

    } else {
      await this.accountS.apiPostObject(ACCOUNT_CALL.CREATE_NEW_ADDRESS, {payload: this.newAddress} )
    }


    /* console.log(__data) */
    this.dialogRef.close('added');
  }
  close(){
    this.dialogRef.close();
  }

}
