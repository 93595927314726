<div class="container-fluid template-inner-wrapper" *ngIf="ui.splitScreenState == 'CLOSED' ">
  <div class='row d-flex p_t10 pl0 pr0 justify-content-between'>
    <div class='p-2 d-flex align-items-center mr-auto'>
      <div class="behandlungsschritte-icon pl0"></div>
      <div class="behandlungsschritte mat-title">Behandlungsabschnitte</div>
    </div>

    

  </div>
</div>

<div class="container-fluid template-inner-wrapper" *ngIf="ui.splitScreenState == 'CLOSED' ">
  <div class='row d-flex pl0 pr0'>
    <div class='col-12 pl8 pr8'>
      <div *ngIf="tc.currentTemplateType === 'DOXFOX'" style="width: 100%;" #_dragListContainer>
        <mat-accordion [togglePosition]="'before'" cdkDropList class="drag-list " displayMode="flat" multi
          (cdkDropListDropped)="drop($event)" >
          <div *ngFor="let section of sections" class="drag-box" cdkDrag [class.move-cursor]='tc.isTemplateEditable && tc.canRenameTemplate' [cdkDragDisabled]='!tc.isTemplateEditable || !tc.canRenameTemplate' 
         >
            <div class="custom-section-placeholder" *cdkDragPlaceholder></div>
            <!-- [@listAnimation2]="sections.length"
            (@listAnimation2.start)="logAnimation($event)"
            (@listAnimation2.done)="logAnimation($event)" -->
            <!-- [columStyles]='columStyles' -->
            <!-- [isInProcesses]='false' [isPublicTemplate]='editable' -->
            <template-section-item 
              (sectionDateChanged)='sectionDateChanged($event)' [section]='section'
              style="width: 100%;">
            </template-section-item>

            <div class="example-handle" cdkDragHandle>
              <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </div>
          </div>
        </mat-accordion>
      </div>
      <div *ngIf="tc.currentTemplateType === 'SCS'" style="width: 100%;" #_dragListContainer>
        <mat-accordion [togglePosition]="'before'" displayMode="flat" >
          <div *ngFor="let section of tc.currentSections">
            <template-section-scs-item [isInProcesses]='false' [isPublicTemplate]='editable'
              [section]='section' [templateDocumentId]='template.documentId' style="width: 100%;">
            </template-section-scs-item>
          </div>
        </mat-accordion>


      </div>
    </div>
  </div>

  <div class="row d-flex p_t20 p_b20" *ngIf='tc.isTemplateEditable' #button>
    
    <div class="p-2  pl8">
      <button mat-raised-button (click)='addAbschnitt()' [disabled]='!tc.isTemplateEditable'>Neuer Behandlungsabschnitt</button>
    </div>

    <div class="p-2 mr-auto pl8" >
       <button *ngIf="tc.baseTemplateType == 'TEMPLATE' " mat-raised-button (click)='addAbschnittAusVorlage()' [disabled]='!tc.isTemplateEditable'>Kopieren und Einfügen</button>
    </div>

  </div>
</div>

  


<div class="m_b20"  *ngIf="ui.splitScreenState != 'CLOSED' "  #splitScreenView>
  <app-template-copy-splitscreen
  [originTemplateID]="template.template_id"
  [elementHeight]="splitScreenHeight" [origin_sections]="sections"></app-template-copy-splitscreen>
</div>
