import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-date-selection',
  templateUrl: './date-selection.component.html',
  styleUrls: ['./date-selection.component.scss']
})
export class DateSelectionComponent implements OnInit {
  selectedDate:Date
  // minDate: Date;
  // maxDate: Date;
  constructor(public dialogRef: MatDialogRef<DateSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Date) {
      this.selectedDate = data
      // this.minDate = subDays(data, 2);
      // this.maxDate = addDays(data, 20);
    }

  ngOnInit(): void {
    this.onSelect(this.selectedDate);
  }
  onSelect(event) {

    this.selectedDate = new Date(event);

    // const dateString = event.toDateString();
    // const dateValue = dateString.split(' ');
    // this.year = dateValue[3];
    // this.DayAndDate = dateValue[0] + ',' + ' ' + dateValue[1] + ' ' + dateValue[2];
  }
  myDateFilter = (d: Date): boolean => {
    const day = d.getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6 ;
  }
  close(){
    this.dialogRef.close();
  }
  save(){

    this.dialogRef.close(this.selectedDate);
  }

}
