import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { BilligItemI } from 'src/app/core/models/billing_itemI';
import { UiService } from 'src/app/services/ui.service';
import { OwnBilligEditComponent } from 'src/app/ui/overlays/own-billig-edit/own-billig-edit.component';

@Component({
  selector: 'app-settings-own-position-items',
  templateUrl: './settings-own-position-items.component.html',
  // styleUrls: ['./settings-own-position-items.component.scss'],
  styleUrls: ['../settings-panel.scss']
})
export class SettingsOwnPositionItemsComponent implements OnInit,OnDestroy {
  
  billingItems:BilligItemI[] = [];
  dataSource:any
  changeHeighSubscription:Subscription
  displayedColumns = ['short_description'];
  _currentHeight:number = 0
  constructor(
    private ui:UiService,
    private dialog:MatDialog
  ) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.loadData()
      this.changeHeighSubscription = this.ui.settingsPanelHeightChanged.subscribe(
        _newHeight =>{
          this._currentHeight = _newHeight
        }
      )
    },120)
  }

  async loadData(){
    const _nlItems = await this.ui.getAllBilligItemsFromApi()
        .then(
          
          _data => { 
    
            return _data}
        )
        this.billingItems = _nlItems as BilligItemI[];
        this.dataSource = new MatTableDataSource(this.billingItems);
    
        
  }
  openDetails(row){
    this.dialog.open(OwnBilligEditComponent, {
      
      width:"70%",
      maxWidth:"1000px",
      height:"388px",
      data: row,
      backdropClass:"backdrop-background",
    }).afterClosed().subscribe(
      _message => {
        if(_message && _message == 'reload'){
          this.loadData()
        }
      }
    )
  }
  add(){
    let latestId:number = 0
          if(this.billingItems.length > 0){
            latestId = this.billingItems.sort((a,b)=>
            b.id - a.id
            )[0].id + 1;
          }
          
          
          let i:BilligItemI = {
            billing_item_id: -1,
            id:latestId,
            documentId: "",  
            short_description: "",
            long_description: "",
            amount_euro: 0.0,
            owner: this.ui.account.uid,
            access: [
              this.ui.account.uid,
            ]
          }
      this.openDetails(i)    
  }
  applyFilter(filterValue: string) {
     this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  get getPanelHeight():string{
    return this._currentHeight + 'px'
  }
  ngOnDestroy(): void {
    if(this.changeHeighSubscription != null){
      this.changeHeighSubscription.unsubscribe()
    }
  }

}
