import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { apiRequest, API_CALL } from 'src/app/core/models/api/apiCalls';
import { AreaOfExpertise, AreaOfExpertiseUserI } from 'src/app/core/models/expertiseArea';
import { TreatmentRegions } from 'src/app/core/models/treatmentRegionM';
import { ApiUtilService } from 'src/app/services/api-util.service';
import { UiService } from 'src/app/services/ui.service';
import * as _ from "lodash"
@Component({
  selector: 'app-settings-user-expertises',
  templateUrl: './settings-user-expertises.component.html',
  styleUrls: ['./settings-user-expertises.component.scss']
})
export class SettingsUserExpertisesComponent implements OnInit {
  @ViewChild('_options') _options:MatSelectionList
  
  areaOfExpertiseUser:AreaOfExpertiseUserI[] = [];
  _areaOfExpertiseUser:AreaOfExpertise[] = [];
  
  _areaOfExpertisAll:AreaOfExpertise[] = [];
  
  _uid:string
  
  _treatmentRegions2: TreatmentRegions[] = [];
  
  currentRegionTitle = ''
  constructor(
    private dialogRef:MatDialogRef<SettingsUserExpertisesComponent>, 
    private apiUtil:ApiUtilService,
    public ui:UiService,
    @Inject(MAT_DIALOG_DATA) public data:string 
    ) {
      this._uid = data
  }

  ngOnInit(): void {
    this.load()
  }
  close(){
    this.dialogRef.close()
  }


  async load(){
    const _data = await this.apiUtil.apiGetP(apiRequest(API_CALL.GET_AREAS_OF_EXPERTISE_BY_UID,this._uid))
    this.areaOfExpertiseUser = _data as AreaOfExpertiseUserI[]
    console.log(this.areaOfExpertiseUser)
    const _data2 = await this.apiUtil.apiGetP(apiRequest(API_CALL.GET_AREAS_OF_EXPERTISE_ADMIN))
    this._areaOfExpertisAll = _data2 as AreaOfExpertise[]

/*     console.log(this._areaOfExpertisAll)
    console.log(this._areaOfExpertiseUser) */
    
  }
  
  async save() {
    // console.log(this._options.selectedOptions.selected)
    //  console.log(this._options.options.length)
   // var _newItemsSelected =[]// _.map(this._options.selectedOptions.selected,'value')
    for(var i = 0; i < this._areaOfExpertisAll.length; i++){
      const _item = this._areaOfExpertisAll[i]
      console.log(_item)
      // _newItemsSelected.push(_item.value.id)
      if(this._isSelected(_item)){
        if(!this._existsByUser(_item)){
          // console.log('!this._existsByUser > add ', _item.name + ' : ' + _item.id)
          // this._areaOfExpertiseUser.push(_item.value)
           await this.apiUtil.apiPost(apiRequest(API_CALL.POST_AREAS_OF_EXPERTISE_BY_UID,this._uid),{id:_item.id})
        }
      } else {
        if(this._existsByUser(_item)){
          const _index = this._areaOfExpertiseUser.findIndex(item => item.fachgebiet_id == _item.id)
          // console.log('this._existsByUser > remove', _item.name + "  : " + this._areaOfExpertiseUser[_index].id)
          if(_index != -1){
            const _removeId = this._areaOfExpertiseUser[_index].id
            // this._areaOfExpertiseUser.splice(_index, 1)
            await this.apiUtil.apiGetP(apiRequest(API_CALL.DELETE_AREAS_OF_EXPERTISE_BY_UID,_removeId.toString()))
          }
        }
      }
      // console.log(_item.value.id + " : " + _item.selected )
    }
    // var _userItemsSelected =[]// _.map(this._options.selectedOptions.selected,'value')
    // for(const _item of this._areaOfExpertiseUser){
    //   _userItemsSelected.push(_item.id)
    // }

    // var Diff = _.difference(_newItemsSelected,_userItemsSelected)
    // console.log(Diff)
    setTimeout(()=>{
      this.close()
    },100)
  }

  private _isSelected(val:AreaOfExpertise):boolean {
    return this._options.selectedOptions.selected.findIndex(item => item.value.id == val.id) != -1
  }
  private _existsByUser(val:AreaOfExpertise):boolean {
    return this._areaOfExpertiseUser.findIndex(item => item.fachgebiet_id == val.id) != -1
  }
  userHasExpertise(allItem:AreaOfExpertise) : boolean {
    return this._areaOfExpertiseUser.findIndex(item => item.fachgebiet_id == allItem.id) != -1
    return false
  }
  optionChanged(e){
    // console.log(e)
    // console.log(this._options.selectedOptions.selected)
   /*  console.log(this._options.selectedOptions.selected.length) */
  }

}
