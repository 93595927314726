import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { HtmlEditorService, MarkdownEditorService, PasteCleanupService, RichTextEditorComponent, TableService, ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { NachbehandlungItemI } from 'src/app/core/models/treatment_typeM';
import { TemplateApiService } from 'src/app/services/template-api.service';
import { NachbehandlungPdfPreviewComponent } from 'src/app/ui/overlays/nachbehandlung-pdf-preview/nachbehandlung-pdf-preview.component';
import { toolbar_tools_NACHBEHANDLUNG } from 'src/app/utils/texteditor-tools';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import { TemplateFlatI } from 'src/app/core/models/api/TemplateFlatI';
import { TemplateControllerService } from 'src/app/services/template-controller.service';

export const  texteditor_cleanup: object = {
  prompt: false,
  plainText: false,
  keepFormat: false,
  deniedTags: ['a', 'span','p',  'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
  deniedAttrs: ['class', 'title', 'id', 'dir', 'style'],
  allowedStyleProps: []
};
@Component({
  selector: 'app-template-nachbehandlung',
  templateUrl: './template-nachbehandlung.component.html',
  styleUrls: ['./template-nachbehandlung.component.scss'],
  providers: [DatePipe, ToolbarService,  HtmlEditorService, TableService,PasteCleanupService, RichTextEditorComponent, MarkdownEditorService]
})
export class TemplateNachbehandlungComponent implements OnInit {
  pasteCleanupSettings: object = texteditor_cleanup
    
  @ViewChild('templateRTE') rteEle: RichTextEditorComponent;
  mode: string = 'HTML';
  tools: object = toolbar_tools_NACHBEHANDLUNG
  currentIndex = 0
  showPrintButton = false
  nachbehandlungItems:NachbehandlungItemI[] = []
  hasChanges = false;
  isLoading = true;
  
  constructor(
    private tmA:TemplateApiService,
    private tc:TemplateControllerService,
    private activatedRoute:ActivatedRoute,
    private dialog:MatDialog
  ) { }
  
  ngOnInit(): void {
   this.reload()
   if(this.activatedRoute.snapshot.routeConfig.path == 'vorgaenge/detail/:id'){
    this.showPrintButton = true;
   } else if(this.activatedRoute.snapshot.routeConfig.path == 'template-detail/:id'){
      this.showPrintButton = false;
   }
  }

  async reload(){
    this.currentIndex = 0
    // this.nachbehandlungItems = []
    const _result = await this.tmA.getTemplateNachbehandlungFor(this.template.template_id, this.tc.prefix);
    this.nachbehandlungItems = _result
    setTimeout(()=>{
      var _lastModified = 0
      var _lastModifiedIndex = 0
      for(var i = 0; i < this.nachbehandlungItems.length; i ++) {
        if(this.nachbehandlungItems[i].lastModified > _lastModified){
          _lastModified = this.nachbehandlungItems[i].lastModified
          _lastModifiedIndex = i
        }
      }
      this.currentIndex = _lastModifiedIndex
      this.isLoading = false;
    },100)
  }

  get canFinalCreate():boolean {
    if(!this.editable || this.isEmpty || this.hasChanges){
      return false;
    }
    if(this.nachbehandlungsschema_text == null || this.nachbehandlungsschema_text == '') {
      return false;
    }
    return true
  }
  get template():TemplateFlatI {
    return this.tc.activeTemplate
  }
  get editable():boolean {
    return this.tc.isTemplateEditable
  }

  get isEmpty():boolean {
    return this.nachbehandlungItems == null || this.nachbehandlungItems.length == 0 ? true : false
  }
  modelChangeFn(e){
    
    this.hasChanges = true
    // this.nachbehandlungItems[this.currentIndex].text = e.target.value
    // console.log(this.nachbehandlungItems[this.currentIndex].text)
    // setTimeout(()=>{
    //   this.save()
    // },100)
  }
  textChangeFn(e){
    console.log(e)
  }
  descrChangeFn(e){
    this.hasChanges = true
    this.nachbehandlungItems[this.currentIndex].description = e.target.value
    // // console.log(this.nachbehandlungItems[this.currentIndex].text)
    // setTimeout(()=>{
    //   this.save()

    // },100)
  }

  get itemCount() :number {
    return this.nachbehandlungItems.length
  }

  get description():string {
    if(this.nachbehandlungItems[this.currentIndex] == null || this.nachbehandlungItems[this.currentIndex].description == null){
      return ''
    } else {
      return this.nachbehandlungItems[this.currentIndex].description
    }
  }

  get nachbehandlungsschema_text():string {
    if(this.nachbehandlungItems[this.currentIndex] == null || this.nachbehandlungItems[this.currentIndex].text == null){
      return ''
    } else {
      return this.nachbehandlungItems[this.currentIndex].text
    }
  }

  async prevItem(){
    if(!this.editable){
      return;
    }
    if(this.currentIndex > 0){
      this.currentIndex --
      await this.tmA.updateNachbehandlungFor(this.nachbehandlungItems[this.currentIndex], this.tc.prefix)
    }
  }
  
  async nextItem(){
    if(!this.editable){
      return;
    }
    if(this.currentIndex < this.nachbehandlungItems.length -1){
      this.currentIndex ++
      await this.tmA.updateNachbehandlungFor(this.nachbehandlungItems[this.currentIndex], this.tc.prefix)
      
    }
  }

  async addNewItem(){
    if(!this.editable){
      return;
    }
    this.isLoading = true;
    await this.tmA.addNachbehandlungFor(this.template.template_id, '', '', this.tc.prefix);
    
    setTimeout(()=>{
      this.reload()
    },100)
  }

  async removeItem(){
    if(!this.editable){
      return;
    }
    this.isLoading = true;
    const docId = this.nachbehandlungItems[this.currentIndex].template_nachbehandlung_id;
    await this.tmA.deleteTemplateNachbehandlungFor(docId, this.tc.prefix)
    setTimeout(()=>{
      this.reload()
    },100)
  }

  openEditWin(){

  }

  download(){
    var html = htmlToPdfmake(this.nachbehandlungItems[this.currentIndex].text);
    // console.log(html);
    // return
     pdfMake.createPdf({ content: html }).download('Nachbehandlung '  +this.template.name + '.pdf')
  }

  async save(reload:boolean = true){
    if(!this.editable){
      return;
    }
    this.isLoading = true;
    this.nachbehandlungItems[this.currentIndex].text = this.rteEle.value
    const _r = await this.tmA.updateNachbehandlungFor(this.nachbehandlungItems[this.currentIndex], this.tc.prefix)
    setTimeout(()=>{
      this.reload()
    },100)

    setTimeout(()=>{
      this.hasChanges = false
    },150)
    
  }

  print(){
    const text = this.nachbehandlungItems[this.currentIndex].text
    this.dialog.open(NachbehandlungPdfPreviewComponent, {
      disableClose:true,
      width:'820px',
      height:"92%",   
      backdropClass:"backdrop-background",
      data: [text, this.showPrintButton, this.template.name]
    })
  }

}
