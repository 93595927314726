import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountI, AddressI, PadNextFachArztFull, PadNextFachArztItem, PadNextFachBereichSelected } from 'src/app/core/models/accountI';
import { TemplateFlatI } from 'src/app/core/models/api/TemplateFlatI';
import { API_CALL, apiRequest } from 'src/app/core/models/api/apiCalls';
import { API_POST_MODE, PATIENT_SELECTION_VIEW_MODE } from 'src/app/core/models/enums';
import { Geschlecht, PatientFull } from 'src/app/core/models/patient';
import { ApiUtilService } from 'src/app/services/api-util.service';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { TokenstorageService } from 'src/app/services/tokenstorage.service';
import { UiService } from 'src/app/services/ui.service';
import { DATETOSTRING_Extend, IS_INT } from 'src/app/utils/helpers';
import { HealthInsuranceM } from 'src/app/core/models/healthinsurance';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-padnext-export-overlay',
  templateUrl: './padnext-export-overlay.component.html',
  styleUrls: ['./padnext-export-overlay.component.scss']
})
export class PadnextExportOverlayComponent implements OnInit {
  errorMessages: string[] = []
  bdyMesg = ""
  address:AddressI
  account:AccountI;
  patientFull:PatientFull | undefined
  showAddress = false
  hasExistingUserModified = false
  patientError = false
  patientEdit = false
  healthEnsurances:HealthInsuranceM[] = []
  padNextFachFull: PadNextFachArztFull[] = []
  // padNextItems: PadNextFachArztItem[] = []
  // padNextFachBereichSelected: PadNextFachBereichSelected | undefined
  padNextFachBereichSelected: PadNextFachArztFull | undefined
  padNextFachArztBereichSelected: PadNextFachArztItem | undefined
  genders = [Geschlecht.m, Geschlecht.w, Geschlecht.u]
  constructor(
    private dialogRef:MatDialogRef<PadnextExportOverlayComponent>, 
    public ui:UiService,
    private apiUtil: ApiUtilService,
    public tc:TemplateControllerService,
    private ts: TokenstorageService,
    private patientService:PatientService, 
    @Inject(MAT_DIALOG_DATA) public data: TemplateFlatI 
    ) {
      console.log(data);
      
  }
  
  ngOnInit(): void {
    let account = this.ui.account
    this.account = this.ui.account
    console.log('account: \n');
    console.log(account);
    console.log('\n');


    this.load()
  }
  fachbereichCHanged(e:any) {
    console.log(e.value);
    // this.padNextFachBereichSelected = e.value
  }
  fachArztCHanged(e:any) {
    console.log(e.value);
    // this.padNextFachBereichSelected = e.value
  }
  get padNextItemsSelected(): PadNextFachArztItem[] {
    if(this.padNextFachBereichSelected) {
      return this.padNextFachBereichSelected.items
    }
    return []
  }
  
  private async load() {
    const padAll = await this.tc.padnextFachAll()
    // console.log(padAll);
    this.padNextFachFull = padAll as PadNextFachArztFull[]
    // console.log(this.padNextFachFull);
    if(this.account.fachgebietPadNext) {
        
      const index1 = this.padNextFachFull.findIndex(item => item.id == this.account.fachgebietPadNext?.fachgebietId)
      if(index1 != -1) {
        this.padNextFachBereichSelected = this.padNextFachFull[index1]

        if(this.padNextFachBereichSelected) {

          const index2 = this.padNextFachBereichSelected.items.findIndex(item => item.id == this.account.fachgebietPadNext?.facharzt_pad_next_fachgebiete_id)
          if(index2 != -1) {
            this.padNextFachArztBereichSelected = this.padNextFachBereichSelected.items[index2]
          }
        }
      }
    }
    // var _patientFull:PatientFull;
    var address:AddressI
    if(this.tc.activeTemplate.address_id != null && this.tc.activeTemplate.address_id != -1){
      const _addressIndex = this.ui.addresses.findIndex(item => item.id == this.tc.activeTemplate.address_id)
      if(_addressIndex != -1){
        address = this.ui.addresses[_addressIndex]
      } else {
        address = this.ui.addresses[0]
      }
    } else {
      address = this.ui.addresses[0]
    }
    console.log('address: \n');
    console.log(address);
    console.log('\n');
    this.address = address
    var _diagnose = this.tc.getLatesDiagnose()
    var _tMethod = this.tc.getLatesTherapie()
    if(this.tc.activeTemplate.patient_id != null && this.tc.activeTemplate.patient_id != -1){

      const _result = await this.apiUtil.apiGetP(apiRequest(API_CALL.GET_SINGLE_PATIENT_FULL, this.tc.activeTemplate.patient_id.toString()))
      const _resPat = _result['result'] != null && _result['result'][0] != null ? _result['result'][0] : null
      this.patientFull = _resPat as PatientFull
      console.log('_patientFull: \n');
      console.log(this.patientFull)
      console.log('\n');
    }

    // this.errorMessages.push('Hausnummer fehlt')
    // this.errorMessages.push('Hausnummer fehlt')
    // this.errorMessages.push('Hausnummer fehlt')
    // this.errorMessages.push('Hausnummer fehlt')

    if(!this.patientFull) {
      this.errorMessages.push('Patient fehlt')
      this.patientError = true
      
    } else {
      if(!this.patientFull.streetNumber) {
        this.errorMessages.push('Patient Hausnummer fehlt')
        this.patientError = true
      }
      if(!this.patientFull.street) {
        this.errorMessages.push('Strasse fehlt')
        
        this.patientError = true
      }
      if(!this.patientFull.city) {
        this.errorMessages.push('Stadt fehlt')
        this.patientError = true
      }
      if(!this.patientFull.zip) {
        this.errorMessages.push('Plz fehlt')
        this.patientError = true
      }
      if(!this.patientFull.birthDate) {
        this.errorMessages.push('Patient Geb. Datum fehlt')
        this.patientError = true
      }
    }
    if(!address) {
      this.errorMessages.push('Adresse fehlt')
    } else {
      if(!address.streetNumber) {
        this.errorMessages.push('Hausnummer fehlt')
      }
    }
    
    if(this.errorMessages.length > 0) {
      // this.showAlert()
      this.bdyMesg = ""
    this.errorMessages.forEach((el)=> {
      this.bdyMesg += `${el} <br>`
    })
    } else {
      let _url = apiRequest(API_CALL.XML_TEST) + this.tc.currentTemplateId + '/' +this.tc.activeTemplate.address_id +'/'+this.ts.getToken().token
      // window.open(_url, '_blank')
    }
  }
  close() {
    this.dialogRef.close()
  }
  currentViewMode:PATIENT_SELECTION_VIEW_MODE
  viewModes = PATIENT_SELECTION_VIEW_MODE
  onExistingUserModified(e){
    if(e === 'patient_changed'){
      this.hasExistingUserModified = true
    } else if(e === 'ERRORS') {
      // this.hasPatientItemErrors = true;
    } else if(e === 'NO_ERRORS') {
      // this.hasPatientItemErrors = false;
    }
    
  }
  async savePatient(andUse:boolean){
    this._save(andUse)
  }
  get canSave():boolean {
    if(this.patientFull == null) { return false }
    if(this.patientFull.firstName.length > 2  && this.patientFull.lastName.length > 2 && 
      this.patientFull.street.length > 3 && this.patientFull.zip.length > 3 && this.patientFull.city.length > 3 ) {//|| !this.hasPatientItemErrors
      return true
    } else {
      return false
    }
  }

  get canDownload():boolean {
    if(this.padNextFachArztBereichSelected) {
      return true
    }
    return false
  }
  JIJI(){
    let _erstelldatum = new Date()
    let _transferNr = "000001";
    let ddd = DATETOSTRING_Extend(_erstelldatum,'', false, false)
    // console.log(ddd);
    // return
    let _zipFilename = `${ddd}_ADL_${_transferNr}.zip` 
    // console.log(this.padNextFachArztBereichSelected);
    let _url = apiRequest(API_CALL.PAD_EXPORT) 
    //+ this.tc.currentTemplateId + '/'+this.ts.getToken().token
//     // let _url = apiRequest(API_CALL.XML_TEST) + this.tc.currentTemplateId + '/' +this.tc.activeTemplate.address_id +'/'+this.ts.getToken().token
    let r = this.apiUtil.apiPostZipRep(_url, {
      templateId: this.tc.currentTemplateId,
      fachgebietTitle: this.padNextFachArztBereichSelected.fachgebietTitle,
      fachArztTitle: this.padNextFachArztBereichSelected.title,
      fachgebiet_pad_next_id: this.padNextFachArztBereichSelected.fachgebiet_pad_next_id,
      fachArztWert: this.padNextFachArztBereichSelected.wert,
      // fachgebiet: this.padNextFachArztBereichSelected
      // token: this.ts.getToken().token
    }).then(d => {
// console.log(d);
let blob = new Blob([d], {type: 'application/zip'});
let downloadLink = document.createElement('a');
let url = URL.createObjectURL(blob);
downloadLink.setAttribute('href', url);
downloadLink.setAttribute('download', _zipFilename);
downloadLink.style.visibility = 'hidden';
document.body.appendChild(downloadLink);
downloadLink.click();
document.body.removeChild(downloadLink);
// window.open(d., '_blank')
})
// console.log(r);

// window.open(_url, '_blank')
  }
  async onBaseAdressChanged(id:number){}
  async editPatient(){
    if(this.healthEnsurances.length == 0) {
      this.healthEnsurances = await this.patientService.getEnsurances();
    }
    
    // this.newPatient = res as PatientFull
    this.currentViewMode = PATIENT_SELECTION_VIEW_MODE.MODIFY_MODE
    this.patientEdit = true
    this.patientError = false
    return
    // let _overlayData:PatientSelectionInputI = {
    //   startMode:PATIENT_SELECTION_VIEW_MODE.LIST_PATIENTS_MODE,
    //   openWithPatientId:null,
    //   uid: this.tc.activeProcess.creator
    // }
    // if(this.patientFull != null && this.patientFull.patient_id != null){
    //   this.loadPatient(this.tc.activeTemplate.patient_id)
    //   _overlayData.startMode = PATIENT_SELECTION_VIEW_MODE.MODIFY_MODE
    //   _overlayData.openWithPatientId = this.patientFull.patient_id
    // }

    // this.dialog.open(PatientSelectionComponent,{
    //   disableClose:true,
    //   width:"70%",
    //   maxWidth:"1200px",
    //   height:"560px",
    //   backdropClass:"backdrop-background",
    //   data: _overlayData
    // }).afterClosed().
    // subscribe(
    //   (_patientResult: PatientFlatI)=> {
    //     if(_patientResult != null && _patientResult.patient_id != null){
    //       if(this.tc.baseTemplateType == BASE_TEMPLATE_TYPE.PROCESS) {

    //         this.tc.updateProcessItem({patient_id:_patientResult.patient_id});
    //         this.tc.activeTemplate.patient_id = _patientResult.patient_id
    //       } else {

    //         this.tc.updateTemplateItem({patient_id:_patientResult.patient_id});
    //         this.tc.activeTemplate.patient_id = _patientResult.patient_id
    //       }
    //      this.loadPatient(_patientResult.patient_id)
    //     }
    //  }
      
    // )
  }
  private async _save(pClose: boolean){

    const _data = { "payload": this.patientFull }
    // console.log(_data);
    // return;
    
    let _mode = null 
    if(this.patientFull.patient_id != null && IS_INT(this.patientFull.patient_id)) {
      if(this.patientFull.patient_id == -1){
        _mode = API_POST_MODE.ADD_NEW;
      } else if(this.patientFull.patient_id > 0){
        _mode = API_POST_MODE.UPDATE;
      }
    }
    
    // if(_mode == null) {
    //   this.patientFull = null;
    //   this.dialogRef.close()
    //   return
    // }
    
    const result = await this.patientService.postPatient(_mode,_data, this.ui.account.uid)

    // console.log(result)
    
      
    setTimeout(()=>{
      // this.closeEditMode()
      this.patientEdit = false
      this.patientError = false
    },150)
    if(pClose || this.currentViewMode === PATIENT_SELECTION_VIEW_MODE.MODIFY_MODE){
      // setTimeout(()=>{
      //   this.dialogRef.close(result)
      // },200)
    } else {
      // setTimeout(()=>{
      //   this.showListView()
      // },200)
    }
    
  }
}
