import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MaterialI } from 'src/app/core/models/materialI';
import { UiService } from 'src/app/services/ui.service';


@Component({
  selector: 'app-material-details',
  templateUrl: './material-details.component.html',
  styleUrls: ['./material-details.component.scss']
})
export class MaterialDetailsComponent implements OnInit {
  @ViewChild('_preisTF', {static:false}) _preisTF:ElementRef
  materialItem:MaterialI

  constructor(public ui: UiService, 
    public dialogRef: MatDialogRef<MaterialDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MaterialI) { }

  ngOnInit(): void {
    this.materialItem = this.data;
  }
  onPreisFocus(){
    this._preisTF.nativeElement.selected()
  }
  async save(){
    let _preis = this.materialItem.preis.toString()
    this.materialItem.preis = _preis.replace(/,/g,'.');
    await this.ui.updateMaterial(this.materialItem).then(
      data => {
        this.close();
      }
    )
  }
  close(){
    this.dialogRef.close();
  }

}
