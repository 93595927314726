

<div [class.accessDenied]="accessDeniedHidden" [class.accessDeniedHidden]="!accessDeniedHidden">
  <p>Sie haben keinen Zugriff für diese Vorlage</p>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M466.5 83.71l-192-80C269.6 1.67 261.3 0 256 0C250.7 0 242.5 1.67 237.6 3.702l-192 80C27.7 91.1 16 108.6 16 127.1c0 257.2 189.2 384 239.1 384c51.1 0 240-128.2 240-384C496 108.6 484.3 91.1 466.5 83.71zM256 446.5l.0234-381.1c.0059-.0234 0 0 0 0l175.9 73.17C427.8 319.7 319 417.1 256 446.5z"/></svg>
</div>

<div class="container-fluid p_t40 template-detail-wrapper pl0 pr0"  [class.CONTENTOUT]='isLoading' [class.CONTENTIN]='!isLoading' style="margin-bottom: 65px;">

  <div *ngIf='tc.activeProcess != null'>
    <div class="container-fluid p_b10 template-inner-wrapper">
      <div class="d-flex">
        <div class="p-2 d-flex align-items-start pl0 ">
          <div class="iconShortCut" [ngStyle]='{"background-color": tc.activeProcess.iconColor}'>
            <div *ngIf="tc.activeProcess.shortCut">
        
              {{tc.activeProcess.shortCut}}
            </div>
            <div *ngIf="!tc.activeProcess.shortCut">
              <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.1572 0.0580589C11.9421 -0.0484546 11.7269 0.00480216 11.5655 0.164572L6.93931 4.74465L5.59448 3.73278L0.86069 0.111316C0.69931 0.00480216 0.484138 -0.0484546 0.322759 0.0580589C0.107586 0.164572 0 0.324343 0 0.53737V9.37799C0 9.53776 0.0537931 9.69753 0.215172 9.80405L5.86345 14.5439C5.97103 14.6504 6.07862 14.6504 6.18621 14.6504C6.29379 14.6504 6.45517 14.5972 6.50897 14.5439L12.2648 9.80405C12.3724 9.69753 12.48 9.53776 12.48 9.37799V0.484113C12.48 0.324343 12.3186 0.111316 12.1572 0.0580589ZM11.4041 1.81553V8.09983L7.8 5.38374L11.4041 1.81553ZM1.07586 1.6025L11.2428 9.27148L8.17655 11.7745H4.24966L1.07586 9.11171V1.6025Z"
                  fill="#FAFAFB" />
              </svg>
        
            </div>
          </div>
        
        </div>
       
        <div class="p-2 template-headline prelative" [class.template_headline_disabled]="!tc.canRenameTemplate" >
          <div class="template_headline_edit_backdrop"  (click)='renameTemplate()'>
            <div class="d-flex flex-column align-items-center justify-content-center h100">
              <div class="p-0 custom_headline_tooltip">{{tc.templateNameTooltip}}</div>
            </div>
          </div>
            {{tc.activeProcess.name}}
        </div>
      </div>

    </div>



  <div class="container-fluid template-inner-wrapper">

    <div class="row p_t10" >
      <div class="col-xxl-4 col-xl-4 col-lg-5 col-md-12 verySmallGutter" >
        <template-patient-item *ngIf="!ui.isClinicUser"
        [patientFlat]="patientFlat" (onEditPatient)="onEditPatient($event)" ></template-patient-item>
        <app-process-clinic-number *ngIf="ui.isClinicUser"
        [fallnummer]="tc.activeProcess.clinicProcessNumber"></app-process-clinic-number>
      </div>
      <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-6 verySmallGutter">
        <process-date-item  [inputDate]='tc.activeTemplateDate' (changedEmitter)="dateChanged($event)"></process-date-item>
      </div>
      <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-6 verySmallGutter" >
        <app-template-header-address></app-template-header-address>
      </div>
      <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-6 verySmallGutter">
        <template-header-minderung ></template-header-minderung>

      </div>
      <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-6 verySmallGutter">
        <mat-card class="mat-card-no-shadow pr0 pt0 pb0 pl0 " style="height:100%!important;">
          <div class="container ">

            <div class="row justify-content-between pl16 pr16 " style="padding-top: 13px!important;">
              <span class="mat-caption">Status</span>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="d-flex stateBox align-items-center" (click)='changeStateTapped()'>
                  <div class="p-0"><div class='stateDot' [ngStyle]='{"background-color": tc.activeProcess.status_color}'></div>  </div>
                  <div class="p-0">{{tc.activeProcess.status_view_value}}</div>
                  <div class="p-0 ml-auto" *ngIf="tc.isTemplateEditable && tc.activeProcess.status_id >= 5"><mat-icon class="dxfx_icon_btn_mini">edit</mat-icon></div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>

    </div>
    <div class="row">
      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 p_t30 verySmallGutter">

        <app-template-note-method-diagnose
          title='Diagnose(n)' [templateDocId]='documentId' type='DIAGNOSE'
        ></app-template-note-method-diagnose>
      </div>
       <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 p_t30 verySmallGutter">

        <app-template-note-method-diagnose
          title='Therapie(n)' [templateDocId]='documentId' type='THERAPY_METHOD'
        ></app-template-note-method-diagnose>
      </div>
      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 p_t30 verySmallGutter">

        <app-template-note-method-diagnose
          title='Notizen' [templateDocId]='documentId' type='NOTE'
        ></app-template-note-method-diagnose>
      </div>
    </div>

  </div>

  <div  class="container-fluid">
    <div class='row pl0 pr0 p_t30'  *ngIf='tc.activeProcess != null' [class.greyBottomBorder]="!greedBackground" [class.greenBottomBorder]="greedBackground">
      <div class="container-fluid template-inner-wrapper">
       <div class="row pl0 pr0">
        <div class="col-auto verySmallGutter">
          <div class="invoiceButton" (click)='tabbarTap("INVOICE")' [class.invoiceButtonActive]="!greedBackground && !nachbehandlungActive && !historyActive">
            <div class="btnCopy">
              Rechnung
            </div>
          </div>
        </div>
        <div class="col-auto verySmallGutter">
          <div class="reportButton" (click)='tabbarTap("REPORT")' [class.reportButtonActive]="greedBackground && !nachbehandlungActive && !historyActive">
            <div *ngIf='tc.activeProcess.type === "SCS"' class="btnCopy">Befundungsbericht</div>
            <div *ngIf='tc.activeProcess.type === "DOXFOX"' class="btnCopy">OP Bericht</div>
          </div>
        </div>
        <div class="col-auto verySmallGutter">
          <div class="reportButton" (click)='tabbarTap("NACHBEHANDLUNG")' [class.reportButtonActive]="nachbehandlungActive">
            <div class="btnCopy">
              Nachbehandlungsschema
            </div>
          </div>
        </div>
        <div class="col-auto verySmallGutter" *ngIf="tc.templateHasInvoice">
          <div class="reportButton" (click)='tabbarTap("HISTORY")' [class.reportButtonActive]="historyActive">
            <div class="btnCopy">
              Historie
            </div>
          </div>
        </div>
       </div>
      </div>
    </div>
  </div>

  <div class="bg-container-fuck" #_bgContainerFuck [class.bgFuckGreen]='greedBackground || nachbehandlungActive' [class.bgFuckGrey]='!greedBackground && !nachbehandlungActive'>
    <div class="content-holder-container" #_contentHolder>
      <div add-host></div>
    </div>
  </div>


</div>
</div>
<div style="position: absolute; z-index: 1!important;">
  <template-footer></template-footer>
</div>
