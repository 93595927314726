<div class="container dashboad-item mh" [class.cardOut]='!showCard' [class.cardIn]='showCard'>
  <div class="d-flex dashboad-item-header p_t15 ">
    <div class="p_2 overview dashboad-item-icon"></div>
    <div class="p_2 dashboad-item-title ">Meine Leistungen</div>
    <div class="p_2 ml-auto " *ngIf='isLoading'><mat-spinner color='primary' diameter='25'></mat-spinner></div>
  </div>

  <div class="d-flex align-items-center align-content-stretch justify-content-center" *ngIf='!isLoading && isEmpty' style="position: relative;min-height:205px;">
    <!-- template-list pl0 pr0 -->
    <div class="p-2">
      Sie haben noch keine Leistungen erstellt.
    </div>
  </div>
  <div class="row p_t20 pl10 pr10" *ngIf='!isLoading && !isEmpty'>
    <div class="container template-list pl0 pr0 " >

      <table class="table  pl0 pr0"  cellpadding='0'  cellspacing = '0'
      style="padding-left: 0px!important; padding-right: 0px!important;" displayMode="flat">

      <thead style="background-color: #fff; border: none; ">
        <tr class='mat-caption tr_head  pb0'>
          <th style="width:  24px!important;">Typ</th>
          <th style="width:  24px!important;">Status</th>
          <th>{{patientTitle}}</th>
          <th style="width: 140px!important; text-align: right;">zuletzt bearbeitet</th>
        </tr>
      </thead>

      <tbody>
      <tr *ngFor='let template of templates; let i = index;' class="element-row" (click)="openDetails(template.template_id)">

        <td style="width:24px!important;">
          <div *ngIf='template.is_public'>
            <div [ngSwitch]="template.type" style="width:24px!important;">
              <div *ngSwitchCase="'DOXFOX'" class="publicShortCutDoxFoxMini"
                matTooltipClass='sectionGoaeTT' matTooltip='Öffentliche doxfox Vorlage' matTooltipPosition='above' style="width:24px!important;">
              </div>
              <div *ngSwitchCase="'SCS'" class="publicShortCutSCSMini"
                matTooltipClass='sectionGoaeTT' matTooltip='Öffentliche SCS Vorlage' matTooltipPosition='above' style="width:24px!important;">
                DOC
              </div>
            </div>
          </div>
          <div *ngIf='!template.is_public'>
            <div [ngSwitch]="template.type" style="width:24px!important;">
              <div *ngSwitchCase="'DOXFOX'" class="userShortCutDoxFoxMini"
                matTooltipClass='sectionGoaeTT' matTooltip='Öffentliche doxfox Vorlage' matTooltipPosition='above' style="width:24px!important;">
              </div>
              <div *ngSwitchCase="'SCS'" class="userShortCutSCSMini"
                matTooltipClass='sectionGoaeTT' matTooltip='Öffentliche SCS Vorlage' matTooltipPosition='above' style="width:24px!important;">
                DOC
              </div>
            </div>
          </div>
        </td>

        <td>
          <div class="reg10" matTooltipClass='sectionGoaeTT' matTooltip='{{template.status_view_value}}' matTooltipPosition='above'>
            <div class='stateDot' [ngStyle]='{"background-color": template.status_color}'></div>
          </div>
        </td>
        <td>
          <span >{{ getDisplayName(template)}}</span>
        </td>

        <td style="width:140px!important; text-align: right;">
          {{getDateFromTS(template) | date:"dd.MM.yyyy HH:mm"}}
        </td>

      </tr>
      </tbody>

      </table>

    </div>
  </div>

</div>
