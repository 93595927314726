import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[autofocus]'
})
export class FocusDirective implements AfterViewInit {

  private _autofocus;
  constructor(private el: ElementRef)
  {
  }

  ngAfterViewInit()
  {
      
      
          window.setTimeout(() => {
            if (this._autofocus || typeof this._autofocus === "undefined") {
              this.el.nativeElement.focus();
            }
            
        },500);//For SSR (server side rendering) this is not safe. Use: https://github.com/angular/angular/issues/15008#issuecomment-285141070)
  }

  @Input() set autofocus(condition: boolean)
  {
      this._autofocus = condition != false;
  }

}
