<div class="p-2 factorMenu" [matMenuTriggerFor]="menu" style="width: 52px; height:28px;" *ngIf="!itemDisabled" #menuTrigger="matMenuTrigger">
  <div class="d-flex h100 align-items-center factor_menu_row">
    <div class="p-0" style="width: 30px;padding-left: 6px!important;">{{position.quantity}}</div>
    <div class="p-0 factor_menu_icon"><mat-icon>expand_more</mat-icon></div>
  </div>
</div>
<div class="p-2 factorMenu"  style="width: 52px; height:28px;" *ngIf="itemDisabled">
  <div class="d-flex h100 align-items-center factor_menu_row">
    <div class="p-0" style="width: 30px; padding-left: 6px!important;">{{position.quantity}}</div>
    <div class="p-0 factor_menu_icon"><mat-icon>expand_more</mat-icon></div>
  </div>
</div>
<mat-menu #menu="matMenu">
  <div *ngFor='let _quantity of quantities' class="factorMenuOption" (click)="select(_quantity)" >{{_quantity.viewVal}}</div>
  <div class="factorMenuOptionAdd" (click)="add()"><mat-icon>add</mat-icon></div>
</mat-menu>
