import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { apiRequest, API_CALL } from 'src/app/core/models/api/apiCalls';
import { TemplateFlatI } from 'src/app/core/models/api/TemplateFlatI';
import { BASE_TEMPLATE_TYPE, ProcessCompletionOverlayData, ProcessCompletionType } from 'src/app/core/models/enums';
import { FinalInvoiceRecieptI } from 'src/app/core/models/invoiceI';
import { PdfPreviewI } from 'src/app/core/models/pdfPreviewI';
import { ScreenSize } from 'src/app/core/models/screenSize';
import { SectionM, SectionMDateChanged } from 'src/app/core/models/sectionM';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { UiService } from 'src/app/services/ui.service';
import { ControllerService } from 'src/app/shared/controller.service';
import { PdfPreviewComponent } from 'src/app/ui/overlays/pdf-preview/pdf-preview.component';
import { ProcessCompletionComponent } from 'src/app/ui/overlays/process-completion/process-completion.component';
import { TokenstorageService } from 'src/app/services/tokenstorage.service';
import { LocaleService } from 'src/app/services/locale.service';
import { ProcessStageChangeOverlayComponent } from 'src/app/ui/overlays/process-stage-change-overlay/process-stage-change-overlay.component';
import { Router } from '@angular/router';
import { IProcessState } from 'src/app/core/models/iProcessState';
import { IProcessChangeOverlayCloseAction, IProcessChangeOverlayData, IProcessChangeOverlayDataViewMode } from 'src/app/core/models/viewI/openProcessOverlayData';
import * as _ from 'lodash';
import { AddressI } from 'src/app/core/models/accountI';
import { PatientFull } from 'src/app/core/models/patient';
import { ApiUtilService } from 'src/app/services/api-util.service';
import { AlertOverlayComponent } from '../../overlays/alert-overlay/alert-overlay.component';
import { AlertActionI } from 'src/app/core/models/alertM';
import { PadnextExportOverlayComponent } from '../../overlays/padnext-export-overlay/padnext-export-overlay.component';
@Component({
  selector: 'app-process-behandlungsschritte',
  templateUrl: './process-behandlungsschritte.component.html',
  styleUrls: ['./process-behandlungsschritte.component.scss', '../../../pages/templates-details.scss']
})
export class ProcessBehandlungsschritteComponent implements OnInit {

  @ViewChild('_dragListContainer') _dragListContainer: ElementRef;
  @Input() template: TemplateFlatI;
  @Input() editable: boolean;
  isSplitScreenMode = false;
  newState: IProcessState//string = ''
  changedState: IProcessState//string = ''
  resizeSubscription: Subscription;
  currentScreenSize: ScreenSize;
  isLoading = true;
  // columStyles: PositionColumType;
  sections: SectionM[] = []
  errorMessages: string[] = []
  private sectionChangeSubscription: Subscription;

  constructor(
    public tc:TemplateControllerService,
    public ui:UiService,
    public locales:LocaleService,
    private dialog: MatDialog,
    private router: Router,
    private apiUtil: ApiUtilService,
    private ts: TokenstorageService) 
    { }

  private _splitScreenHeight:number = 100

  get splitScreenHeight():number {
    return this._splitScreenHeight
  }

  ngOnInit(): void {
  
    setTimeout(() => {

      this.sectionChangeSubscription = this.tc.sectionChanged.subscribe(
        _changed => {
          this.initSections()
        }
      )
      this.currentScreenSize = ControllerService.currentScreenSize;
      this.resizeSubscription = ControllerService.windowResize.subscribe(
        (screeSize: ScreenSize) => {
          if (this.currentScreenSize == null || this.currentScreenSize !== screeSize){
            this.currentScreenSize = screeSize;

            this.calPosTable();
          }
        }
      );
      this.initSections()
      this.calPosTable();
      console.log(`CURRENT TEMPLATE STATE ${this.tc.activeProcess}`, this.tc.activeProcess)
      // if(this.tc.baseTemplateType == BASE_TEMPLATE_TYPE.PROCESS){
      //   this.loadTestBillingCompanies()
      // }
     
      this.newState = this.tc.getProcessState()
 //     console.log(this.newState);
    }, 1000);

  }

  
  ///---------------------------------------------------------------------------------
  initSections(){
    this.sections = this.tc.currentSections;
    this.sections.sort((a,b) => a.order_index - b.order_index)
    console.log(this.sections)
  }
  

  ///---------------------------------------------------------------------------------
  async addAbschnitt(){
    this.tc.addNewSection(this.tc.activeTemplate.template_id, 'processes')
  }

  scrollTo(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }

  drop(event: CdkDragDrop<SectionM[]>) {
    moveItemInArray(this.tc.currentSections, event.previousIndex, event.currentIndex);
    this.tc.currentSections[event.currentIndex].order_index =  event.currentIndex;
    this.updateSectionIndex(0);
  }

  ///---------------------------------------------------------------------------------
  sectionDateChanged(e: SectionMDateChanged){
    // const _templateDate: Date  = new Date(this.template.date);
    const _sectiion: SectionM = this.tc.currentSections.find((item) => item.section_id === e.section.section_id);
    if (_sectiion == null){
      return;
    }
    var _last_treated_at_difference_days = e.section.treated_at_difference_days;
    for(var i = 0; i < this.tc.currentSections.length; i ++){
      if(i >= e.section.order_index && e.section.treated_at_difference_days > 0){
        this.tc.currentSections[i].treated_at_difference_days = _last_treated_at_difference_days
        // _last_treated_at_difference_days ++;
      }
    }

    this.tc.updateAllSections(this.tc.prefix)
    //ControllerService.saveTemplate.emit(this.template.documentId);
  }

  ///---------------------------------------------------------------------------------
  showInvoice(){  
    // console.log(this.template);
    // console.log(this.tc.activeProcess);
    // return
    const _data: PdfPreviewI = {
      // template: this.template,
      template: this.tc.activeProcess,
      type: PdfPreviewI.Type.SHOWINVOICE
    };
    this.dialog.open(PdfPreviewComponent, {
      disableClose: true,
      width: '820px',
      height: '92%',
      backdropClass: 'backdrop-background',
      data: _data
    }).afterClosed().subscribe(
      _print => {
        /* if(_print != null){
          _print.save("rechnung-"+ this.template.name +".pdf");
        } */
      }
    );
  }

  ///---------------------------------------------------------------------------------
  finalInvoice(){
    const _data: ProcessCompletionOverlayData = {
      template: this.template,
      type: ProcessCompletionType.INVOICE
    };
    this.dialog.open(ProcessCompletionComponent, {
      disableClose: true,
      width: '640px',
      height: '450px',
      backdropClass: 'backdrop-background',
      data: _data
    }).afterClosed().subscribe(
      (_invoice: FinalInvoiceRecieptI) => {
        if (_invoice != null){
          // _invoice.pdf.save(_invoice.documentTitle + '.pdf');
          this.updateState(_invoice)
         
        }
      }
      );
  }
    
  ///---------------------------------------------------------------------------------
  private openFinalInvoice(pdf: FinalInvoiceRecieptI) {
    pdf.pdf.save(pdf.documentTitle + '.pdf');
  }

  ///---------------------------------------------------------------------------------
  invoicePreview() {

    const _data: PdfPreviewI = {
      template: this.template,
      type: PdfPreviewI.Type.INVOICE
    };
    this.dialog.open(PdfPreviewComponent, {
      disableClose: true,
      width: '820px',
      height: '92%',
      backdropClass: 'backdrop-background',
     data: _data
    }).afterClosed().subscribe(
      _print => {
        if (_print != null){
          // _print.autoPrint();
          _print.save('rechnung-' + this.template.name + '.pdf');
        }
      }
    );
  }

  ///---------------------------------------------------------------------------------
  updateSectionIndex(startIndex: number){
    this.tc.updateSectionIndex(startIndex, this.tc.prefix)
  }

  ///---------------------------------------------------------------------------------
  private async updateState(_invoice) {
    const dto = {
      message: "Rechnung wurde erstellt",
      assigned_role: "ACCOUNTANT",
      assignee_uid: this.ui.uid
    }
    await this.tc.processStateChanged(5, this.template.template_id, dto);
    setTimeout(()=>{
      this.openFinalInvoice(_invoice)
    },550)
  }

  ///---------------------------------------------------------------------------------
  async unlockInvoice(){

    if ((this.ui.isAccountant && this.ui.useAccounting) || ( (this.tc.activeProcess.assignee_uid == this.ui.account.uid)) && this.template.status_id >= 5) {
        const olData: IProcessChangeOverlayData = {
        windowTitle: "Leistung zurücksetzen",
        windowBodyMessage: "Falls Sie diese bereits übergebene Leistung erneut bearbeiten möchten, wird der Status wieder auf <strong>Entwurf</strong> gesetzt",
        old_assignee_uid: this.template.assignee_uid,
        new_assignee_uid: this.ui.account.uid,
        template_id: this.template.template_id,
        viewMode: IProcessChangeOverlayDataViewMode.UNLOCK_INVOICE,
        oldStatus_id: this.template.status_id,
        newStatus_id: this.ui.accountingInUse ? (this.ui.isAccountant) ? 3 : 1 : 1,
        // newStatus_id: this.ui.accountingInUse ? (this.tc.activeProcess.createdByUid == this.ui.account.uid) ? 1 : 1 : 1,
        role: "ACCOUNTANT",
        closeAction: IProcessChangeOverlayCloseAction.RELOAD,
        errors:[]
      }
      this.changeOverlay(olData)
    } else if (!this.ui.useAccounting) {
        const olData: IProcessChangeOverlayData = {
        windowTitle: "Leistung zurücksetzen",
        windowBodyMessage: "Falls Sie diese bereits übergebene Leistung erneut bearbeiten möchten, wird der Status wieder auf <strong>Entwurf</strong> gesetzt",
        old_assignee_uid: this.template.assignee_uid,
        new_assignee_uid: this.ui.account.uid,
        template_id: this.template.template_id,
        viewMode: IProcessChangeOverlayDataViewMode.UNLOCK_INVOICE,
        oldStatus_id: this.template.status_id,
        newStatus_id: this.ui.accountingInUse ? 3 : 1,
        role: "ACCOUNTANT",
        closeAction: IProcessChangeOverlayCloseAction.RELOAD,
        errors:[]
      }
      this.changeOverlay(olData)
    }

  
    
  }

  ///---------------------------------------------------------------------------------
  async shareWithAccounting() {

    const olData: IProcessChangeOverlayData = {
      windowTitle: "Leistung an Abrechnung übergeben",
      windowBodyMessage: "Der Status wird in <strong>An Abrechnungsperson / Team übertragen</strong> geändert.",
      old_assignee_uid: this.template.assignee_uid,
      new_assignee_uid: null,
      template_id: this.template.template_id,
      viewMode: IProcessChangeOverlayDataViewMode.SHARE_TO_ACCOUNTING,
      oldStatus_id: this.template.status_id,
      newStatus_id: 2,
      role: "ACCOUNTANT",
      closeAction: IProcessChangeOverlayCloseAction.BACK_TO_OVERVIEW,
      errors: []
      // errors:errors
    }
    this.changeOverlay(olData)
  }

  private changeOverlay(data: IProcessChangeOverlayData) {
    
    this.dialog.open(ProcessStageChangeOverlayComponent,  { 
      disableClose: true, 
      width: "80%",
      maxWidth: "900px",
      // height: "550px",
      minHeight: "250px",
      data: data,
      backdropClass:"backdrop-background",
      
      
    }).afterClosed().subscribe(
      (action:IProcessChangeOverlayCloseAction) => {
        if(!action) {
          return
        }

        if(action == IProcessChangeOverlayCloseAction.BACK_TO_OVERVIEW) {
          this.router.navigateByUrl('vorgaenge')
          return
        } else if(action == IProcessChangeOverlayCloseAction.RELOAD) {
          this.router.navigate(['vorgaenge/detail', this.template.template_id]);
          return
        }

      
      }
    )
  }

  ///---------------------------------------------------------------------------------
  async backToDoc() {

    const olData: IProcessChangeOverlayData = {
      windowTitle: "Leistung zur Prüfung zurück an den Arzt.",
      windowBodyMessage: "Status wird zu <strong>Zur Prüfung</strong> geändert und eine Bearbeitung ist nicht mehr möglich.",
      old_assignee_uid: this.template.assignee_uid,
      new_assignee_uid: this.template.creator,
      template_id: this.template.template_id,
      viewMode: IProcessChangeOverlayDataViewMode.BACK_TO_DOC,
      oldStatus_id: this.template.status_id,
      newStatus_id: 4,
      role: "DOC",
      closeAction: IProcessChangeOverlayCloseAction.BACK_TO_OVERVIEW,
      errors:[]
   
    }
    this.changeOverlay(olData)
   
  }

  // addAbschnittAusVorlage(){
  //      this.ui.toggleSplitScreenState(SPLIT_SCREEN_VIEW_STATE.TEMPLATE_LIST)
  //      setTimeout(()=>{
  //        this.scrollIntoSplitScreen()
  //      },500)
  // }
  // scrollIntoSplitScreen(){
  //   setTimeout(()=>{

  //     this.splitScreenView.nativeElement.scrollIntoView({behavior:"smooth"});
  //   },820)
  // }

  get showCreateFinalInvoiceButton() : boolean {
    if(!this.tc.isTemplateEditable) { return false }
    if(this.tc.baseTemplateType == 'TEMPLATE') { return false }
    if(this.tc.templateHasInvoice) { return false }
    const allowedIds = [3, 2, 1]
    if(this.ui.isAccountant && this.tc.activeTemplate.status_id == 3) {
      return true
    } else if(!this.ui.isAccountant && this.tc.activeTemplate.status_id == 1 && !this.ui.isClinicUser) {
      return true
    } else if(!this.ui.useAccounting) { 
      return true
    } else {
      return false
    } 

    // else if(this.ui.account.hasAccountant) {

    // } else {

    // }
    // return this.tc.templatePrintVisible && !this.tc.templateHasInvoice
  }

  get showShareWithAccountingButton() : boolean {
    if(!this.tc.isTemplateEditable) { return false }
    return this.tc.baseTemplateType == 'PROCESS' && this.ui.accountingPool != null && this.ui.isDocRole  && this.ui.useAccounting
  }

  get showShareWithBackToDocButton() : boolean {
    if(!this.tc.isTemplateEditable) { return false }
    return this.tc.baseTemplateType == 'PROCESS' && this.ui.hasAccountantRole && !this.tc.templateHasInvoice && this.ui.useAccounting
  }

  get showInvoieceUnlockButton() : boolean {
    // if(!this.tc.isTemplateEditable) { return false }
    if (this.tc.templateHasInvoice) {
      return this.ui.hasAccountantRole && this.ui.useAccounting || (this.tc.activeProcess.assignee_uid == this.ui.account.uid)
    } else {
      return false;
    }
  }

  get showInvoiecePreviewButton() : boolean {
    if(!this.tc.isTemplateEditable) { return false }
    if(this.tc.baseTemplateType == 'TEMPLATE') { return false }
    if(!this.tc.templateHasInvoice) {
      return true
    } else {

      return false //this.tc.templatePrintVisible && this.tc.templateHasInvoice
    }
  }

  changed(e) {
    if(e.value.id != this.tc.getProcessState().id) {
      this.changedState = e.value as IProcessState
      console.log(e.value);
      console.log(this.newState.id);
    }
  }

  async saveNewFinalState(){

  }

  get availableStates() :IProcessState[]{
    let geht:number[] = [10,6,8,9]
    return this.tc.processStates.filter(item => geht.includes(item.id))
  }


  async openTestBillingCompanyStuff(){
    this.dialog.open(PadnextExportOverlayComponent,{
      disableClose: true,
      width: '80%',
      maxWidth: '1620px',
      height: '70%',
      maxHeight: '1500px',
      backdropClass: 'backdrop-background',
      data: this.tc.activeTemplate
    })
    return
    var _patientFull:PatientFull;
    var address:AddressI
    if(this.tc.activeTemplate.address_id != null && this.tc.activeTemplate.address_id != -1){
      const _addressIndex = this.ui.addresses.findIndex(item => item.id == this.tc.activeTemplate.address_id)
      if(_addressIndex != -1){
        address = this.ui.addresses[_addressIndex]
      } else {
        address = this.ui.addresses[0]
      }
    } else {
      address = this.ui.addresses[0]
    }
    console.log(address);
    
    var _diagnose = this.tc.getLatesDiagnose()
    var _tMethod = this.tc.getLatesTherapie()
    if(this.tc.activeTemplate.patient_id != null && this.tc.activeTemplate.patient_id != -1){

      const _result = await this.apiUtil.apiGetP(apiRequest(API_CALL.GET_SINGLE_PATIENT_FULL, this.tc.activeTemplate.patient_id.toString()))
      const _resPat = _result['result'] != null && _result['result'][0] != null ? _result['result'][0] : null
      _patientFull = _resPat as PatientFull
      console.log(_patientFull)
    }

    // this.errorMessages.push('Hausnummer fehlt')
    // this.errorMessages.push('Hausnummer fehlt')
    // this.errorMessages.push('Hausnummer fehlt')
    // this.errorMessages.push('Hausnummer fehlt')

    if(!_patientFull) {
      this.errorMessages.push('Patient fehlt')
      
    } else {
      if(!_patientFull.streetNumber) {
        this.errorMessages.push('Hausnummer fehlt')
      }
      if(!_patientFull.street) {
        this.errorMessages.push('Strasse fehlt')
      }
      if(!_patientFull.city) {
        this.errorMessages.push('Stadt fehlt')
      }
      if(!_patientFull.zip) {
        this.errorMessages.push('Plz fehlt')
      }
      if(!_patientFull.birthDate) {
        this.errorMessages.push('Patient Geb. Datum fehlt')
      }
    }
    if(!address) {
      this.errorMessages.push('Adresse fehlt')
    } else {
      if(!address.streetNumber) {
        this.errorMessages.push('Hausnummer fehlt')
      }
    }
    
    if(this.errorMessages.length > 0) {
      this.showAlert()
    } else {
      let _url = apiRequest(API_CALL.XML_TEST) + this.tc.currentTemplateId + '/' +this.tc.activeTemplate.address_id +'/'+this.ts.getToken().token
      window.open(_url, '_blank')
    }
    
    // if(this.tc.activeTemplate.address_id == null || this.tc.activeTemplate.address_id == -1){
    //   ControllerService.snack.emit('Adresse fehlt')
    //   return
    // }
    // if(this.tc.activeTemplate.patient_id == null || this.tc.activeTemplate.patient_id == -1){
    //   ControllerService.snack.emit('Patient fehlt')
    //   return
    // }

      


          // let _invoiceCreator = new InvoiceCreator(this.tc,this.ui, this.tc.activeTemplate,_patientFull, address, this.datePipe, this.currencyPipe)
    // const _resultXML = await _invoiceCreator.createBillingXml()
    // const serializer = new XMLSerializer();
    // const _xmlDoc = new XMLDocument();
      // var xmltext = "<sometag><someothertag></someothertag></sometag>";
      // xmltext.append('klaus')

      // const _resultXML = await this.apiUtil.apiGetP(apiRequest(API_CALL.XML_TEST))
      // // console.log(_resultXML)
      // const blob = new Blob([_resultXML.toString()], {type: 'application/xml'});

      // var downloadURL = window.URL.createObjectURL(data);
      // var link = document.createElement('a');
      // link.href = downloadURL;
      // link.download = "help.pdf";
      // link.click();
      // var filename = "file.xml";
      // var pom = document.createElement('a');
      // var bb = new Blob([_resultXML], {type: 'application/xml'});

      // pom.setAttribute('href', window.URL.createObjectURL(bb));
      // pom.setAttribute('download', filename);

      // pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
      // pom.draggable = true;
      // pom.classList.add('dragout');

      // pom.click();
    // const data = _resultXML.toString();
    // const blob = new Blob([data], { type: 'application/octet-stream' });

    // var fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob))
    // var element = document.createElement('a');
    // // var blob = new Blob([data], {
    // //   type: 'text/xml'
    // // });
    // var url = URL.createObjectURL(blob);
    // element.href = url;
    // element.setAttribute('download', 'acl.xml');
    // document.body.appendChild(element);
    // element.click();

    // // const blob = new Blob(ArrayBuffer, {type: "application/xml"});
    // console.log(await blob.text());

    // saveAs(blob, "save-me.xml");

    //
  }

  showAlert(){
    var bdyMesg = ""
    this.errorMessages.forEach((el)=> {
      bdyMesg += `${el} <br>`
    })
    this.dialog.open(AlertOverlayComponent, {
      width: '350px',
      backdropClass: 'backdrop-background',
      data: {
        title: 'Achtung',
        bodyMessage: bdyMesg,
        actions: [
          {
            title: 'OK', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType: AlertActionI.BUTTON_ACTION_TYPE.CANCEL
          },
          // {
          //   title: 'Löschen', color: AlertActionI.BUTTON_COLOR_TYPE.WARN, actionType: AlertActionI.BUTTON_ACTION_TYPE.RESUME
          // },
          {
            title: 'Abbrechen', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType: AlertActionI.BUTTON_ACTION_TYPE.CANCEL
          }
        ]
      }
    }).afterClosed().subscribe((result) => {
      if (result != null){
        if (result === AlertActionI.BUTTON_ACTION_TYPE.RESUME){
          
          
          // this.save()
        }
      }
    });
  }
  async tragsportToAccounting() {
    
  }


  ngOnDestroy(){
    if(this.resizeSubscription != null){
      this.resizeSubscription.unsubscribe();
    }
    if(this.sectionChangeSubscription != null){
      this.sectionChangeSubscription.unsubscribe();
    }
  }
  
  // ///---------------------------------------------------------------------------------
  // private async loadTestBillingCompanies(){
  //    const _billingCompanies = await this.tc.getUserBillingCompanies();
  //   this.testBillingCompany = _billingCompanies as TestBillingCompany[]
  //   // console.log("_billingCompanies ", _billingCompanies)
  // }
  ///---------------------------------------------------------------------------------
  calPosTable(){
    this._splitScreenHeight = this.currentScreenSize.height - (78 + 52 + 112 + 40 +64 +104)
   
  }
}
// this.dialog.open(ProcessStageChangeOverlayComponent,{
    //   disableClose: true, 
    //   width: "80%",
    //   maxWidth: "900px",
    //   height: "550px",
    //   data: olData, 
    //   // {
    //   //   title: "Leistung zurücksetzen",
    //   //   infoMessage: "Der Status wird in <strong>In Abrechnung</strong> geändert. Sie können die nix mehr machen dann. s klar",
    //   //   changeToStatusId: 3,
    //   //   template_id: this.template.template_id,
    //   //   template: this.template,
    //   //   role: "ACCOUNTANT"
    //   // },
    //   backdropClass:"backdrop-background"
    // }).afterClosed().subscribe(
    //   _message => {
    //     if(_message) {

    //       if(_message == "BACK_TO_OVERVIEW"){
    //         this.router.navigateByUrl('vorgaenge')
    //       } else 
    //       if(_message == "RELOAD"){
    //         this.router.navigate(['vorgaenge/detail', this.template.template_id]);
    //       }
    //     }
    //   }
    // )

   
    // this.tc.activeTemplate.invoice_id = -1;
  //   const userPath: string = this.template.uid;
  //   const invoiceId: string = this.template.invoiceDocId  != null ? this.template.invoiceDocId : null;
  //   /* const surgeryReportDocId = this.template.surgeryReportDocId != null ? this.template.surgeryReportDocId : null; */
  //   if (invoiceId != null && invoiceId !== '') {
  //     await this.templateS.deleteInvoice(userPath, invoiceId);
  //   }
  //   /* if (surgeryReportDocId != null && surgeryReportDocId !== ''){
  //     await this.templateS.deleteSurgeryReport(userPath, surgeryReportDocId);
  //   } */

  //   this.template.invoiceDocId = '';
  //   /* this.template.surgeryReportDocId = ''; */
  //   this.template.hasInvoice = false;
  //   /* this.template.hasSurgeryReport = false; */
  //  /*  for (const section of this.template.sections) {
  //     //TODO
  //   } */
  //   for (var i = 0; i < this.template.sections.length; i++) {
  //     const section = this.template.sections[i];
  //     /* this.template.sections[i].templateHasSurgeryReport = false; */
  //     this.template.sections[i].templateHasInvoice = false;
  //     for (let j = 0; j < section.positions.length; j ++) {
  //       /* this.template.sections[i].positions[j].templateHasSurgeryReport = false; */
  //       this.template.sections[i].positions[j].templateHasInvoice = false;

  //     }
  //   }
  //   /*await this.templateS.updateProcess(this.template);*/
  //   const message = await this.templateApi.updateProcessFull(this.template);
  //   console.log("message ", message)


  /// from onInit {}

  /*

  this.columStyles = {
      drag: { widthPx: '3.664%' },
      active: { widthPx: '3.165%' },
      split: { widthPx: '3.165%' },
      indented: { widthPx: '3.165%' },
      action_copy: { widthPx: '18.196%' },
      goa_id: { widthPx: '3.797%' },
      quantity: { widthPx: '6.329%' },
      progression_factor: { widthPx: '7.12%' },
      justification: { widthPx: '16.93%' },
      goa_text: { widthPx: '16.139%' },
      surgery_relevant: { widthPx: '5.6%' },
      summary: { widthPx: '7.405%' },
      sub_menu: { widthPx: '5.063%' },
    };

  */



    /*

      calPosTable(){
    this._splitScreenHeight = this.currentScreenSize.height - (78 + 52 + 112 + 40 +64 +104)
    // let _tableWidth = this._dragListContainer.nativeElement.offsetWidth;
    // const _dragWidth = 60; // 72;
    // const _active = 44;
    // const _indented = 1;
    // const _goa_id = 72;
    // const _quantity = 60// 90;
    // const _progression_factor = 80///= 90;
    // const _sum = 90;
    // const _sub = 72;

    // this.columStyles.drag.widthPx               = _dragWidth.toString() + 'px';
    // this.columStyles.active.widthPx             = _active.toString() + 'px';
    // this.columStyles.split.widthPx              = _active.toString() + 'px';
    // this.columStyles.indented.widthPx           = _indented.toString() + 'px';

    // this.columStyles.goa_id.widthPx             = _goa_id.toString() + 'px';
    // this.columStyles.quantity.widthPx           = _quantity.toString() + 'px';
    // this.columStyles.progression_factor.widthPx = _progression_factor.toString() + 'px';

    // this.columStyles.surgery_relevant.widthPx   = _active.toString() + 'px';
    // this.columStyles.summary.widthPx            = _sum.toString() + 'px';
    // this.columStyles.sub_menu.widthPx           = _sub.toString() + 'px';



    // let _n = 0;
    // _n += _dragWidth;
    // _n += _active;
    // // _n += _active;
    // // _n += _active;
    // // _n += _indented;
    // _n += _goa_id;
    // _n += _quantity;
    // _n += _progression_factor;
    // _n += _active;
    // _n += _sum;
    // _n += _sub;
    // _tableWidth -= _n;

    // const _colWidth = _tableWidth / 3;
    // this.columStyles.action_copy.widthPx       = _colWidth.toString() + 'px';
    // this.columStyles.justification.widthPx      = _colWidth.toString() + 'px';
    // this.columStyles.goa_text.widthPx          = (88).toString() + 'px' //_colWidth.toString() + 'px';
  }
    */