<div style="position: relative; height: 100%; ">
  <mat-toolbar class="overlay-toolbar">
    <div class="d-flex align-items-center" style="height: 100%; width: 100%;">

      <div class="p-2  pr0 pl0">
        <mat-icon class='justification_arrow_icon' (click)='prevReport()'>keyboard_arrow_left</mat-icon>
      </div>


      <div class="p-2  pr0 pl0" style="font-size:14px; font-weight:300;">
        <span style="color: #1976D2; font-weight:500;">{{selectedIndex+1}}</span>
        <span> von {{surgeryReports.length}}</span>
      </div>

      <div class="p-2  pr0 pl0">
        <mat-icon class='justification_arrow_icon' (click)='nextReport()'>keyboard_arrow_right</mat-icon>
      </div>
      <div class="p-2 pl10 flex-fill">
        <input class="formControl mat-body formControlDescription fs14-400" placeholder="Beschreibung" [disabled]='!editable'
        maxlength="250"
        (blur)="onTextFieldBlur($event, 'description')"
        (keydown.esc)="onEscapeKeyDown($event, 'description')"
        [value]='reportionCopyDescription' >
      </div>
    </div>
    <span class="toolbar-spacer"></span>
    <mat-icon (click)='close()' class='dxfx_icon_btn_medium'>close</mat-icon>
  </mat-toolbar>

  <div class="container" style="height: calc(100% - 120px); width: 100%; margin-top: 15px; margin-bottom: 15px;">
    <div class="d-flex" style="height: 100%; width: 100%;">
      <div class="p-0 flex-fill">
        <textarea style="width:100%; height: 100%; background-color:rgba(0,0,0,0);  resize: none !important;"  maxlength="15000"
          rows="12" cols='4' class="mat-body formControl fs14-400" #_reportTextArea
          (blur)="onTextFieldBlur($event, 'text')"
          (keyup)="onTextAreaKeyUp($event)"
          (keydown.esc)="onEscapeKeyDown($event, 'text')"
          [value]='getReportionCopy' [disabled]='!editable'  >
        </textarea>
      </div>
    </div>
  </div>
  
  <mat-toolbar class="overlay-toolbar">
    <span style="font-size:12px;" [class.warnCopy]="charCount > 15000">{{charCount}} </span>  <span style="color: rgba(0,0,0,0.5); font-size:12px;"> &nbsp; / 15000</span>
    <span class="toolbar-spacer"></span>
  </mat-toolbar>
  <div class="loadOverlay" *ngIf="isSaving">
    <div class="d-flex h100 align-items-center justify-content-center">
      <div class="p-0"><mat-spinner diameter="25"></mat-spinner></div>
    </div>
  </div>

</div>
