import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { TextEditI } from 'src/app/core/models/textOverlayEditI';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-textedit-overlay',
  templateUrl: './textedit-overlay.component.html',
  styleUrls: ['./textedit-overlay.component.scss']
})
export class TexteditOverlayComponent implements OnInit {

  @ViewChild('singleLine', {static:false}) singleLine:ElementRef;
  @ViewChild('multiLine', {static:false}) multiLine:ElementRef;

  textEdit:TextEditI
  textModel:string = ''
  showLargeFontSize = false;
  constructor(public dialogRef: MatDialogRef<TexteditOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TextEditI) {
      this.showLargeFontSize = data.largeFont
    }

  ngOnInit(): void {

    setTimeout(()=>{
      if(this.singleLine != null) {
        this.singleLine.nativeElement.focus()
        this.singleLine.nativeElement.select()
      } else if(this.multiLine != null) {
        this.multiLine.nativeElement.focus()
        this.multiLine.nativeElement.select()
      }
    },200)
    this.textEdit = this.data;
    this.textModel = this.textEdit.text

  }
  onKeyDown(key:KeyboardEvent){
    console.log(key)

  }
  onBlur(){
    console.log('BLUR');

  }
  save(){
    this.dialogRef.close(this.textModel)
  }
  close(){
    this.dialogRef.close()
  }
  onKeyDown2(e:any){
    console.log('', e);

  }
}
