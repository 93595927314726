<div class="container p_l40" [class.CONTENTOUT]='isLoading' [class.CONTENTIN]='!isLoading'>

  <div class="row p_t40" >
    <div class="col-12">
      <h1 class="display-1">Meine Leistungen</h1>
    </div>

    <div class="col-12 ">
      <div class="d-flex flex-column _filterHolder " style="height: 130px;">

        <div class="p-0 flex-fill pr16 w50p p_t20">
          <mat-form-field class="textfield100 select-form-field" appearance="outline">
            <input matInput placeholder="Leistungen durchsuchen..." (keyup)="applyFilter($event.target.value)">
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>
        <div class="p-0 p_b20" >
        <mat-chip-list class="example-chip" #chipList multiple="true" *ngIf='!isLoading'>
          <mat-chip #chip="matChip" class="filter-chip" *ngFor="let state of tc.processStates"
            [class.filterChipUnactive]="!ui.processFilterInclude(state)"
            [selected]='ui.processFilterInclude(state)'
            [ngStyle]='{"background-color": ui.processFilterInclude(state) ? state.color : "rgba(0,0,0,0)"}'
            (click)='ui.toggleProcessFilter(state)'>
            <mat-icon class="filter-chip-icon" *ngIf="ui.processFilterInclude(state)">done</mat-icon>
            <mat-icon class="filter-chip-icon" *ngIf="!ui.processFilterInclude(state)">add</mat-icon>
            {{state.view_value}}
          </mat-chip>
        </mat-chip-list>
        </div>
      </div>
    </div>

  </div>
<!-- </div> -->

<!-- <div class="container p_t20 p_b60" [class.CONTENTOUT]='isLoading' [class.CONTENTIN]='!isLoading'> -->
  <mat-card class="p0" >
    <div class="container-fluid p0" >

      <div class="row justify-content-center" *ngIf='tableIsEmpty' style="min-height: 360px;">
        <div class="col-auto d-flex align-items-end" >
          <svg viewBox="0 0 64 64" width="64" height="64"><title>zoom split</title><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2" fill="#DCECF2" stroke="#DCECF2"><line data-cap="butt" data-color="color-2" fill="none" stroke-miterlimit="10" x1="60" y1="60" x2="48" y2="48" stroke-linecap="butt"></line><circle fill="none" stroke="#DCECF2" stroke-miterlimit="10" cx="26" cy="26" r="24"></circle><path data-color="color-2" fill="none" stroke-miterlimit="10" d="M10.5,22 C12,16.4,16.4,12,22,10.5"></path></g></svg>
        </div>
        <div class="col-12 tlc p_t20 align-items-end" style="height: 65px;">
          <p style="font-weight: 400; font-size: 18px;">Keine Ergebnisse gefunden</p>
        </div>
      </div>


      <div class="container-fluid _tableHeader p0" [hidden]='tableIsEmpty'>
        <div class="d-flex p0 align-items-center" style="height: 100%;">

          <div class="p-0 ml15 " style="width:72px">Typ</div>

          <div class="p-0 " style="width:175px">Status</div>

          <div class="p-0 " style="width:175px">
            <div class="d-flex p0 align-items-center" style="height: 40px;" >

              <div class="p-0 " style="cursor:pointer;" (click)="ui.toggleProcessesSort('patientName')">{{!ui.isClinicUser ? "Patient" : "Fallnummer"}}</div>
              <div class="p-0" style="margin-top: 8px;">
                <mat-icon class="tableFilterIcon" *ngIf="ui.processesOverviewSort == 'PATIENT_DESC'"
                          [class.tableFilterIconActive]="ui.processesOverviewSort == 'PATIENT_DESC'">south</mat-icon>
                <mat-icon class="tableFilterIcon" *ngIf="ui.processesOverviewSort == 'PATIENT_ASC'"
                          [class.tableFilterIconActive]="ui.processesOverviewSort == 'PATIENT_ASC'">north</mat-icon>
              </div>
            </div>
          </div>


          <div class="p-0 " style="width: 470px;" >Behandlung/Therapie</div>

          <div class="p-0  thCenter w90 ml-auto" >Rechnung</div>

          <div class="p-0  thCenter w90" >OP Bericht</div>

          <div class="p-0  " style="width: 130px;">
            <div class="d-flex p0 align-items-center justify-content-end" style="height: 40px;">
              <div class="p-0 " style="cursor:pointer; text-align:right;" (click)="ui.toggleProcessesSort('date')">Datum</div>
              <div class="p-0" style="margin-top: 8px; width: 24px;">
                <mat-icon class="tableFilterIcon" *ngIf="ui.processesOverviewSort == 'DATE_DESC'"
                          [class.tableFilterIconActive]="ui.processesOverviewSort == 'DATE_DESC'">south</mat-icon>
                <mat-icon class="tableFilterIcon" *ngIf="ui.processesOverviewSort == 'DATE_ASC'"
                          [class.tableFilterIconActive]="ui.processesOverviewSort == 'DATE_ASC'">north</mat-icon>
              </div>
            </div>
          </div>


          <div class="p-0 w90 ">
            <div class="d-flex p0 align-items-center justify-content-end" style="height: 40px;">
                <div class="p-0 " style="cursor:pointer; text-align:right;"  (click)="ui.toggleProcessesSort('summary')">Summe</div>
              <div class="p-0" style="margin-top: 8px; width: 24px;">
                <mat-icon class="tableFilterIcon" *ngIf="ui.processesOverviewSort == 'SUMMARY_DESC'"
                          [class.tableFilterIconActive]="ui.processesOverviewSort == 'SUMMARY_DESC'">south</mat-icon>
                <mat-icon class="tableFilterIcon" *ngIf="ui.processesOverviewSort == 'SUMMARY_ASC'"
                          [class.tableFilterIconActive]="ui.processesOverviewSort == 'SUMMARY_ASC'">north</mat-icon>
              </div>
            </div>
          </div>

          <div class="p-0 w20 "></div>

        </div>
      </div>


      <div class="container-fluid p0" style="width: 100%; border-radius: 6px; margin-left: 0px;">
        <!--  -->
        <!-- <div class="d-flex p0 IPROCESStemRow align-items-center" *ngFor='let element of filteredProcesses;'  (click)='openProcess($event,element)' style="cursor: pointer;"> -->
          <div [@processListAnimation]="tc.processes.length"
          (@processListAnimation.start)="logAnimation($event)"
          (@processListAnimation.done)="logAnimation($event)"
          >

        <div class="d-flex p0  align-items-center IPROCESStemRow" *ngFor='let element of tc.processes;' (click)='openProcess($event,element)' style="cursor: pointer;">
          <div class="p-0 ml10 clickable" style="width:52px; height:32px;"(click)='openProcess($event,element)'>
              <div class="iconShortCut" [ngStyle]='{"background-color": element.iconColor}'>
                <div *ngIf="element.shortCut">
              
                  {{element.shortCut}}
                </div>
                <div *ngIf="!element.shortCut">
                  <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.1572 0.0580589C11.9421 -0.0484546 11.7269 0.00480216 11.5655 0.164572L6.93931 4.74465L5.59448 3.73278L0.86069 0.111316C0.69931 0.00480216 0.484138 -0.0484546 0.322759 0.0580589C0.107586 0.164572 0 0.324343 0 0.53737V9.37799C0 9.53776 0.0537931 9.69753 0.215172 9.80405L5.86345 14.5439C5.97103 14.6504 6.07862 14.6504 6.18621 14.6504C6.29379 14.6504 6.45517 14.5972 6.50897 14.5439L12.2648 9.80405C12.3724 9.69753 12.48 9.53776 12.48 9.37799V0.484113C12.48 0.324343 12.3186 0.111316 12.1572 0.0580589ZM11.4041 1.81553V8.09983L7.8 5.38374L11.4041 1.81553ZM1.07586 1.6025L11.2428 9.27148L8.17655 11.7745H4.24966L1.07586 9.11171V1.6025Z"
                      fill="#FAFAFB" />
                  </svg>
              
                </div>
              </div>
          </div>
        
          
            <!-- <div class="p-0 ml10 " style="width:52px" *ngIf="element">
              <div *ngIf="element.type == 'DOXFOX'" class="userShortCutDoxFox"
                matTooltipClass='sectionGoaeTT' matTooltip='Meine Vorlage' matTooltipPosition='above'>
              </div>
              <div *ngIf="element.type == 'SCS'" class="userShortCutSCS"
                matTooltipClass='sectionGoaeTT' matTooltip='Meine DOC Vorlage' matTooltipPosition='above'>
                DOC
              </div>
            </div> -->
  
            <div class="p-0 ml5 statsRow" style="width: 190px; user-select: none;"  (click)='changeStat($event, element)' *ngIf="element">
              <div class="reg10"> 
                <div class='stateDot' [ngStyle]='{"background-color": getStatus(element).color}'></div>
                {{getStatus(element).view_value}} 
                <br> <span *ngIf="getStatusUser(element) != '' ">{{getStatusUser(element)}}</span>
              </div>
            </div>
  
  
            <div class="p-0  " style="width:180px"  [class.disabled]="!element.isEditable && ui.USE_ACCOUNTING" *ngIf="element">
              {{ getDisplayName(element)}}
            </div>
  
  
            <div class="p-0 " style="width: 470px;" [class.disabled]="!element.isEditable  && ui.USE_ACCOUNTING" *ngIf="element">{{element.name}}</div>
  
            <div class="p-0  w90 thCenter ml-auto" *ngIf="element">
              <img src="/assets/template_table_item_included.svg" alt="" *ngIf='element.invoice_id != null && element.invoice_id != -1'>
              <img src="/assets/template_table_item_not_included.svg" alt="" *ngIf='element.invoice_id == null || element.invoice_id == -1'>
            </div>
  
            <div class="p-0   thCenter w90" *ngIf="element">
              <img src="/assets/template_table_item_included.svg" alt=""  *ngIf='element.surgery_report_id != null && element.surgery_report_id != -1'>
              <img src="/assets/template_table_item_not_included.svg" alt="" *ngIf='element.surgery_report_id == null || element.surgery_report_id == -1'>
            </div>
  
  
  
            <div class="p-0 " style="width: 110px; text-align: right;" [class.disabled]="!element.isEditable && ui.USE_ACCOUNTING" *ngIf="element">
  
              {{element.date | cgDate}}
            </div>
  
  
            <div class="p-0 w90  " style="text-align:right;" [class.disabled]="!element.isEditable && ui.USE_ACCOUNTING" *ngIf="element">
              {{element.summary | cgcurrency}}
            </div>
  
            <div class="p-0 w40" *ngIf="element">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" (click)="subOpen($event)" *ngIf="showSubMenu(element)">
                <mat-icon style="color: #7C8B96!important;">more_horiz</mat-icon>
              </button>
              <mat-menu #menu="matMenu" xPosition="before" [overlapTrigger]="false">
                <button mat-menu-item [disabled]='element.hasSurgeryReport === true || element.hasInvoice === true'  (click)='deleteProcess(element)'>
                  <span>Löschen</span>
                </button>
              </mat-menu>
            </div>
          
 
      
        </div>

      </div>
      </div>


    </div>
  </mat-card>

</div>

