import { TemplateFlatI } from "../core/models/api/TemplateFlatI";
import { InvoicePreviewPositionItemI } from "../core/models/v4/invoice/invoicePositionI";
import { TemplateControllerService } from "../services/template-controller.service";
import { UiService } from "../services/ui.service";
import * as _ from 'lodash';
import { PatientFull } from "../core/models/patient";
import { AddressI } from "../core/models/accountI";
import  jsPDF  from "jspdf";
import { DatePipe } from "@angular/common";
import { CgcurrencyPipe } from "../pipes/cgcurrency.pipe";
import { Fonts } from "../ui/pdf-dependencies/pdfFonts";
import { DATETOSTRING } from "src/app/utils/helpers";

import { POSITION_TYPE } from "../core/models/enums";

import autoTable from 'jspdf-autotable';
import { InvoiceModelI } from "../core/models/v4/invoice/invoiceModel";
import { getYear, subDays, addDays } from "date-fns";
export class InvoiceCreator {

  private _tc:TemplateControllerService;
  private _template:TemplateFlatI
  // private _patientFull:PatientFull
  private _ui:UiService
  private _address:AddressI
  private datePipe: DatePipe
  private currencyPipe:CgcurrencyPipe
  private _invoiceDate: Date
  constructor(
     tc:TemplateControllerService,
     ui:UiService,
     template:TemplateFlatI,

     address:AddressI,
     pDatePipe: DatePipe,
     pCurrencyPipe: CgcurrencyPipe,
     invoiceDate: Date
  ){
    this._tc = tc;
    this._ui = ui
    this._template = template;

    this._address = address
    this.currencyPipe = pCurrencyPipe
    this.datePipe = pDatePipe
    this._invoiceDate = invoiceDate
  }



  async createInvoiceItem(invoiceNumber:number, isClinic: boolean):Promise<InvoiceModelI>{

    var _diagnose = this._tc.getLatesDiagnose()
    var _tMethod = this._tc.getLatesTherapie()
    let _items:InvoicePreviewPositionItemI[] = []

    let _matItems = this._tc.allSectionMaterialItems

    _items = await this._tc.getInvoicePreviewItems(this._template.template_id) as InvoicePreviewPositionItemI[]

    _items = _.orderBy(_items, [item => item.order_index, item => item.position_order_index])


    for(var i = 0; i < _matItems.length; i ++){
      const _tadd = _items.find(item => item.section_id == _matItems[i].section_id)
      // console.log(_tadd);
      
      let positionsBySection = _items.filter(item => item.section_id == _matItems[i].section_id)
      .sort((a,b) => a.position_order_index - b.position_order_index)
      let newPorderIndex = positionsBySection.length == 0 ? 0 : positionsBySection[positionsBySection.length - 1].position_order_index + 1
    
      const newItem = {
        section_id: _matItems[i].section_id,
        position_id: 99999999,
        amount_euro: _matItems[i].preis,
        order_index: _tadd != null ? _tadd.order_index : 99 + i,
        treated_at_difference_days: _tadd != null && _tadd.treated_at_difference_days != null ? _tadd.treated_at_difference_days : 0,
        position_order_index: newPorderIndex,
        // invoiceCopy: _matItems[i].bezeichnung,
        example: _matItems[i].bezeichnung,
        progression_factor: 1,
        quantity: _matItems[i].quantity,
        position_type: POSITION_TYPE.NONE
      }
      console.log(newItem)
      _items.push(newItem)
    }


    _items = _.orderBy(_items, [
      t => t.order_index,
      t => t.position_order_index
    ], ['asc']);



    // _items.sort((a,b) => a.s)

    var _sum = 0;
    let _lastSectionId: number;
    let _templateDate: Date = new Date(this._template.date);
    _templateDate.setHours(0,0,0,0);

    let _currentDate:Date = _templateDate;
    let _lastDate:Date = _templateDate;
    let _last_treated_at_difference_days = 0
    var _date = this.datePipe.transform(_currentDate, 'dd.MM.yyyy')

    for(var i = 0; i < _items.length; i ++){

      if(!_lastSectionId || _lastSectionId != _items[i].section_id) {
        _lastSectionId = _items[i].section_id
        // _currentDate = _templateDate
      }

      if(_items[i].treated_at_difference_days != _last_treated_at_difference_days){
        _last_treated_at_difference_days = _items[i].treated_at_difference_days

        _currentDate = addDays(_templateDate, _last_treated_at_difference_days)

      }



      if(_lastDate != _currentDate){
        _lastDate = _currentDate;
        _date = this.datePipe.transform(_currentDate, 'dd.MM.yyyy')
      }

      if(i > 0 && _items[i -1].date != _date){
        _items[i].date = _date;
      } else if(i==0){
        _items[i].date = _date;
      }

      if(_items[i].position_type == POSITION_TYPE.OWN){
        _items[i].progression_factor = 0
      }

      if(_items[i].position_type == POSITION_TYPE.GOA || _items[i].position_type == POSITION_TYPE.NONE){
        let _itemSum = (_items[i].progression_factor * _items[i].amount_euro) * _items[i].quantity
        _sum += _itemSum
        _items[i].summary = this.currencyPipe.transform(_itemSum)
      } else {
        _items[i].summary = "0.0"
      }

      _date = ''
    }


    let _tempSum = 0
    if(this._template.reduction != null && this._template.reduction != 0){
      let _reduction =  this._template.reduction;
       _tempSum = _sum * (_reduction / 100);
      _sum -= _tempSum;

    }


    let _patient_name = ""
    let _patient_birthdate = ""
    let _patient_address_1 = ""
    let _patient_address_2 = ""

    let legalMain = this._ui.isClinicUser && this._address.clinicLegalMain != null ? this._address.clinicLegalMain : ""
    let clinicInvoiceProviderFirst = this._ui.isClinicUser && this._address.clinicInvoiceProviderFirst ? this._address.clinicInvoiceProviderFirst : ""
    let clinicInvoiceProviderSecond = this._ui.isClinicUser && this._address.clinicInvoiceProviderSecond ? this._address.clinicInvoiceProviderSecond : ""
    let clinicInvoiceProviderThird = this._ui.isClinicUser && this._address.clinicInvoiceProviderThird ? this._address.clinicInvoiceProviderThird : ""
    let clinicInvoiceProviderFourth = this._ui.isClinicUser && this._address.clinicInvoiceProviderFourth ? this._address.clinicInvoiceProviderFourth : ""

    let _klinikName = this._address.klinikName
    let _abteilung = this._address.abteilung
    let _behandelnder_arzt = this._address.behandelnder_arzt// this._ui.account.title + ' ' + this._ui.account.firstName + ' ' + this._ui.account.lastName
    let _facharzt_bezeichnung =  this._address.facharzt_bezeichnung//this._ui.account.medical_specialty_designation

    if(!isClinic) {
      var _patientFull:PatientFull;
      if(this._template.patient_id != null && this._template.patient_id != -1){
        const _resPat = await this._tc.getSinglePatientFull(this._template.patient_id)
        _patientFull = _resPat
        
        
        if(_patientFull) {
          _patient_name =  _patientFull.firstName + ' ' +  _patientFull.lastName
          _patient_birthdate =  this.datePipe.transform( _patientFull.birthDate)
          _patient_address_1 =  _patientFull.street + ' ' + (_patientFull.streetNumber != null ? _patientFull.streetNumber : '')
          _patient_address_2 = _patientFull.zip + ' ' +  _patientFull.city
        }
      }
    }

    return {
      id: -1,
      processId: this._template.template_id,
      invoiceItems: _items,
      invoice_date_year: getYear(new Date()),
      invoice_date_time: this._invoiceDate.getTime(),
      invoice_number: invoiceNumber,
      reduction: this._template.reduction,
      reductionSummary: this.currencyPipe.transform(_tempSum),
      summary: this.currencyPipe.transform(_sum),
      patient_name:  _patient_name,
      patient_birthdate: _patient_birthdate,
      patient_address_1:  _patient_address_1,
      patient_address_2: _patient_address_2,
      diagnose: _diagnose,
      therapiemethode: _tMethod,
      klinikName: _klinikName,
      abteilung: _abteilung,
      behandelnder_arzt: _behandelnder_arzt,
      facharzt_bezeichnung: _facharzt_bezeichnung,
      arzt_role: this._address.arzt_role,
      address_1: this._address.streetName  + ' ' + this._address.streetNumber,
      address_2: this._address.zip + ' ' + this._address.city,
      kontoinhaber: this._address.kontoinhaber,
      bank_name: this._address.bank_name,
      iban: this._address.iban,
      bic: this._address.bic,
      ust_nr: this._address.ust_nr,
      legalMain: legalMain,
      invoiceProviderFirst: clinicInvoiceProviderFirst,
      invoiceProviderSecond: clinicInvoiceProviderSecond,
      invoiceProviderThird: clinicInvoiceProviderThird,
      invoiceProviderFourth: clinicInvoiceProviderFourth,
      isClinic: isClinic

    } //this.getInvoicePDF(_finalInvItem);
  }
   getInvoicePDF(invoiceI:InvoiceModelI, isClinicUser: boolean = false):jsPDF {
    var lastCursor
    var lastY
    const addFooters = doc => {
      const pageCount = doc.internal.getNumberOfPages()

      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        if(i == pageCount){

          if(invoiceI.reduction == 25){
            doc.setFont('Barlow-Light', 'normal')
            doc.setFontSize(10)
            .text("Minderung gem. § 6a, Abs. 1, GOÄ (25%): " + invoiceI.reductionSummary , 551, lastCursor.y + 20, {align:'right'});

          } else if(invoiceI.reduction == 15){
            doc.setFont('Barlow-Light', 'normal')
            doc.setFontSize(10)
            .text("Minderung gem. § 6a, Abs. 1, GOÄ (15%): " + invoiceI.reductionSummary , 551, lastCursor.y + 20, {align:'right'});

          }

          doc.setFont('Barlow-Light', 'normal')
          doc.setFontSize(14)
          .text('Gesamtbetrag: ' + invoiceI.summary, 551, lastCursor.y + 37, {align:'right'});

          let currentY = 781;
          doc.text("Bankverbindung " , 40, currentY + 24,{align:'left'})
          doc.setFontSize(8)

          var _kontoInhaber = "Kontoinhaber: "

          if(invoiceI.kontoinhaber != null && invoiceI.kontoinhaber != "" ){
            _kontoInhaber +=  invoiceI.kontoinhaber;
          }

          if(invoiceI.bank_name != null && invoiceI.bank_name != ""){
            _kontoInhaber += " | Bankinstitut: " + invoiceI.bank_name
          }
          doc.text(_kontoInhaber, 40, currentY +36,{align:'left'})


          var _ibanOutput = ""
          if(invoiceI.iban != null && invoiceI.iban != ""){
            _ibanOutput += "IBAN: " + invoiceI.iban
          }
          if(invoiceI.bic != null && invoiceI.bic != ""){
            _ibanOutput += " | BIC: " + invoiceI.bic;
          }


          if(invoiceI.ust_nr != null && invoiceI.ust_nr != ""){
            _ibanOutput += " | USt-IdNr.: " + invoiceI.ust_nr
          }
          doc.text(_ibanOutput, 40, currentY + 48,{align:'left'})
          // if(invoiceI.address != null){
          // }



        }

        doc.setFontSize(8)
        doc.text('Seite ' + String(i) + ' von ' + String(pageCount), 551, 820, {
          align: 'right'
        })
      }
    }

    var tableData = [];
    var lastDate =''
    for (var i = 0; i < invoiceI.invoiceItems.length; i++) {
      let _item = invoiceI.invoiceItems[i];

         let item = [_item.date, _item.goa_id, _item.quantity, _item.example, _item.progression_factor == 0 ? "" : _item.progression_factor, _item.summary]
         tableData.push(item);

    }

    const head = [['Behandlung', 'Ziffer', 'Menge', 'Bezeichnung','Faktor','Betrag']]

    const doc = new jsPDF('p','pt');

    let _curY = invoiceI.isClinic ? 34 : 44;
    doc.addFileToVFS('Barlow-Light.ttf', Fonts.light());
    doc.addFont('Barlow-Light.ttf', 'Barlow-Light', 'normal');

    doc.addFileToVFS('Barlow-Regular.ttf', Fonts.regular());
    doc.addFont('Barlow-Regular.ttf', 'Barlow-Regular', 'normal');

    doc.addImage('assets/doxfox.png', 40, 34, 32, 40)

    doc.setFont('Barlow-Light');
    doc.setFontSize(8)


    if (invoiceI.isClinic) {
      doc.setFont('Barlow-Light');
      if(invoiceI.legalMain != null && invoiceI.legalMain != ""){
        doc.text(invoiceI.legalMain, 551, _curY, { align: "right" })
        _curY += 10;
      }
    }

    doc.setFont('Barlow-Regular');
    if(invoiceI.klinikName != null && invoiceI.klinikName != ""){
      doc.text(invoiceI.klinikName, 551, _curY, { align: "right" })
      _curY += 10;
    }

    if (invoiceI.isClinic) {

    } else {
      doc.setFont('Barlow-Light');
      if(invoiceI.behandelnder_arzt != null && invoiceI.behandelnder_arzt != ""){
        // doc.text("beh. Arzt: " + invoiceI.behandelnder_arzt, 551, _curY, { align: "right" });
        doc.text(invoiceI.behandelnder_arzt, 551, _curY, { align: "right" });
        _curY += 10;
      }

      if(invoiceI.facharzt_bezeichnung != null && invoiceI.facharzt_bezeichnung != ""){
        doc.text(invoiceI.facharzt_bezeichnung, 551, _curY, { align: "right" })
        _curY += 10;
      }


      if(invoiceI.arzt_role != null && invoiceI.arzt_role != ""){
        doc.text(invoiceI.arzt_role, 551, _curY, { align: "right" })
        _curY += 10;
      }

      if(invoiceI.abteilung != null && invoiceI.abteilung != ""){
        doc.text(invoiceI.abteilung, 551, _curY, { align: "right" });
        _curY += 10;
      }


    }

    doc.setFont('Barlow-Regular');
    if(invoiceI.address_1 != null && invoiceI.address_1 != ""){
      doc.text(invoiceI.address_1, 551, _curY, { align: "right" })
    }
    _curY += 10;

    if(invoiceI.address_2 != null && invoiceI.address_2 != ""){
      doc.text(invoiceI.address_2, 551, _curY, { align: "right" });
      _curY += 10;
    }


    if (invoiceI.isClinic) {
      _curY += 12;
      doc.setFont('Barlow-Light');
      doc.setFontSize(8)

      if(invoiceI.invoiceProviderFirst != null && invoiceI.invoiceProviderFirst != ""){
        doc.text(invoiceI.invoiceProviderFirst, 551, _curY, { align: "right" });
        _curY += 10;
      }
      if(invoiceI.invoiceProviderSecond != null && invoiceI.invoiceProviderSecond != ""){
        doc.text(invoiceI.invoiceProviderSecond, 551, _curY, { align: "right" });
        _curY += 10;
      }
      if(invoiceI.invoiceProviderThird != null && invoiceI.invoiceProviderThird != ""){
        doc.text(invoiceI.invoiceProviderThird, 551, _curY, { align: "right" });
        _curY += 10;
      }
      if(invoiceI.invoiceProviderFourth != null && invoiceI.invoiceProviderFourth != ""){
        doc.text(invoiceI.invoiceProviderFourth, 551, _curY, { align: "right" });
        _curY += 10;
      }

    }



    /* doc.text(invoiceI.streetName, 551, _curY, { align: "right" })
    _curY += 10;
    doc.text(invoiceI.zip + " " + invoiceI.city, 551, _curY, { align: "right" }); */

    doc.setFontSize(8);
    if(invoiceI.klinikName != null && invoiceI.klinikName != ""){
      doc.text(invoiceI.klinikName, 40, 135);
    } else {
      /* doc.text("XXXXXXXXXXXX", 40, 135); */
    }

    doc.setDrawColor(180,180,180);
    doc.setLineWidth(0.5);
    doc.line(40, 139, 270, 139);

    doc.rect(351,132,200, 80);
    doc.setFont('Barlow-Light')
    .setFontSize(10)
    .setDrawColor("#444444");
    var __y = 148;

    if(!isClinicUser) {
      doc.text('Patient:', 361, __y)
      __y += 13;
      doc.setFont('Barlow-Regular')
      .text(invoiceI.patient_name, 361, __y);
      __y += 13;
      doc.setFont('Barlow-Light');
      doc.text('Geburtsdatum:', 361, __y)
      .text(invoiceI.patient_birthdate, 541, __y,{align:'right'});
      __y += 13;
    }


    doc.text('Rechnungsdatum:', 361, __y)
    .text(DATETOSTRING(new Date(invoiceI.invoice_date_time)), 541, __y, {align:'right'});
    __y += 13;

    if(isClinicUser) {
      doc.text('Fallnummer:', 361, __y)
      .text(invoiceI.invoice_number.toString(), 541, __y, {align:'right'});
    __y += 13;
    } else {
      doc.text('Rechnungsnr.:', 361, __y)
      .text(invoiceI.invoice_date_year.toString() + invoiceI.invoice_number.toString(), 541, __y, {align:'right'});
      __y += 13;
    }



    /* doc.text('Patient:', 365, __y)
    .text(invoiceI.patientFirstName + " " + invoiceI.patientLastName, 475, __y);
    __y += 12;
    doc.text('Geburtsdatum:', 365, __y)
    .text(invoiceI.patientBirthday, 475, __y); */

    doc.text(invoiceI.patient_name, 40, 158)
      .text(invoiceI.patient_address_1, 40, 171)
      .text(invoiceI.patient_address_2, 40, 184)


     doc
     .setFont('Barlow-Regular')
     .setFontSize(16)
     .setDrawColor("#444444")
     .text('Privatliquidation nach GOÄ', 40, 274);

     autoTable(doc, {
      margin: {top:10,right:35,left:35},
      theme: 'plain',
      styles:  { font: "Barlow-Light", fontStyle: 'normal',fillColor:[255,255,255] },
      // columnStyles: { 5: { halign: 'right' }, 2:{halign:'center' } , },
      startY: 290,

      body:  [
        ['Diagnose: '],
        [invoiceI.diagnose],
        ['Therapie: '],
        [invoiceI.therapiemethode],
      ],

      didDrawPage: (data) => {
        lastCursor = data.cursor

     },

      didDrawCell: (data) => {

       data.cell.styles.fillColor = [255,255,255],
       data.cell.styles.lineColor = [255,255,255]
      },
    });



    autoTable(doc, {
      head: head,
      styles:  { font: "Barlow-Light", fontStyle: 'normal'},
      columnStyles: { 5: { halign: 'right', cellWidth: 55 }, 2:{halign:'center' } , },
      startY: lastCursor.y,
      body: tableData,
      didDrawPage: (data) => {
        lastCursor = data.cursor
     },
      didDrawCell: (data) => {
      },
    })


    addFooters(doc)




    return doc;
  }
   getInvoicePDFFinal(invoiceI:InvoiceModelI):jsPDF {
    var lastCursor
    var lastY
    const addFooters = doc => {
      const pageCount = doc.internal.getNumberOfPages()

      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        if(i == pageCount){

          if(invoiceI.reduction == 25){
            doc.setFont('Barlow-Light', 'normal')
            doc.setFontSize(10)
            .text("Minderung gem. § 6a, Abs. 1, GOÄ (25%): " + invoiceI.reductionSummary , 551, lastCursor.y + 35, {align:'right'});

          } else if(invoiceI.reduction == 15){
            doc.setFont('Barlow-Light', 'normal')
            doc.setFontSize(10)
            .text("Minderung gem. § 6a, Abs. 1, GOÄ (15%): " + invoiceI.reductionSummary , 551, lastCursor.y + 35, {align:'right'});

          }

          doc.setFont('Barlow-Light', 'normal')
          doc.setFontSize(14)
          .text('Gesamtbetrag: ' + invoiceI.summary, 551, lastCursor.y + 57, {align:'right'});

          let currentY = 781;
          doc.text("Bankverbindung " , 40, currentY + 24,{align:'left'})
          doc.setFontSize(8)

          var _kontoInhaber = "Kontoinhaber: "

          // if(invoiceI.address != null){
            if(invoiceI.kontoinhaber != null && invoiceI.kontoinhaber != "" ){
              _kontoInhaber +=  invoiceI.kontoinhaber;
            }

            if(invoiceI.bank_name != null && invoiceI.bank_name != ""){
              _kontoInhaber += " | Bankinstitut: " + invoiceI.bank_name
            }
            doc.text(_kontoInhaber, 40, currentY +36,{align:'left'})


            var _ibanOutput = ""
            if(invoiceI.iban != null && invoiceI.iban != ""){
              _ibanOutput += "IBAN: " + invoiceI.iban
            }
            if(invoiceI.bic != null && invoiceI.bic != ""){
              _ibanOutput += " | BIC: " + invoiceI.bic;
            }


            if(invoiceI.ust_nr != null && invoiceI.ust_nr != ""){
              _ibanOutput += " | USt-IdNr.: " + invoiceI.ust_nr
            }
            doc.text(_ibanOutput, 40, currentY + 48,{align:'left'})
          // }



        }

        doc.setFontSize(8)
        doc.text('Seite ' + String(i) + ' von ' + String(pageCount), 551, 820, {
          align: 'right'
        })
      }
    }

    var tableData = [];
    var lastDate =''
    for (var i = 0; i < invoiceI.invoiceItems.length; i++) {
      let _item = invoiceI.invoiceItems[i];

         let item = [_item.date, _item.goa_id, _item.quantity, _item.example, _item.progression_factor == 0 ? "" : _item.progression_factor, _item.summary]
         tableData.push(item);

    }

    const head = [['Behandlung', 'Ziffer', 'Menge', 'Bezeichnung','Faktor','Betrag']]

    const doc = new jsPDF('p','pt');

    let _curY = 54;
    doc.addFileToVFS('Barlow-Light.ttf', Fonts.light());
    doc.addFont('Barlow-Light.ttf', 'Barlow-Light', 'normal');

    doc.addFileToVFS('Barlow-Regular.ttf', Fonts.regular());
    doc.addFont('Barlow-Regular.ttf', 'Barlow-Regular', 'normal');

    doc.addImage('assets/doxfox.png', 40, 34, 32, 40)

    doc.setFont('Barlow-Light');
    doc.setFontSize(10)

    if(invoiceI.klinikName != null && invoiceI.klinikName != ""){
      doc.text(invoiceI.klinikName, 551, 44, { align: "right" })
    } else {
   /*    doc.text("XXXXXXXXXXXX", 551, 44, { align: "right" }) */
    }


    doc.setFontSize(8)


    if(invoiceI.abteilung != null && invoiceI.abteilung != ""){
      doc.text(invoiceI.abteilung, 551, _curY, { align: "right" });
      _curY += 10;
    }

    if(invoiceI != null && invoiceI.behandelnder_arzt != null && invoiceI.behandelnder_arzt != ""){
      // doc.text("beh. Arzt: " + invoiceI.behandelnder_arzt, 551, _curY, { align: "right" });
      doc.text(invoiceI.behandelnder_arzt, 551, _curY, { align: "right" });
      _curY += 10;
    }

    if(invoiceI != null && invoiceI.facharzt_bezeichnung != null && invoiceI.facharzt_bezeichnung != ""){
      doc.text(invoiceI.facharzt_bezeichnung, 551, _curY, { align: "right" })
      _curY += 10;
    }


    if(invoiceI != null && invoiceI.arzt_role != null && invoiceI.arzt_role != ""){
      doc.text(invoiceI.arzt_role, 551, _curY, { align: "right" })
      _curY += 10;
    }

    if(invoiceI != null && invoiceI.address_1 != null && invoiceI.address_1 != ""){
      doc.text(invoiceI.address_1, 551, _curY, { align: "right" })
    }
    _curY += 10;

    if(invoiceI != null && invoiceI.address_2 != ""){
      doc.text(invoiceI.address_2, 551, _curY, { align: "right" });
    }





    doc.setFontSize(8);
    if(invoiceI.klinikName != null && invoiceI.klinikName != ""){
      doc.text(invoiceI.klinikName, 40, 135);
    } else {

    }

    doc.setDrawColor(180,180,180);
    doc.setLineWidth(0.5);
    doc.line(40, 139, 270, 139);

    doc.rect(351,132,200, 80);
    doc.setFont('Barlow-Light')
    .setFontSize(10)
    .setDrawColor("#444444");
    var __y = 148;

    doc.text(this._ui.isClinicUser ? "" : 'Patient:', 361, __y)
    __y += 13;

    doc.setFont('Barlow-Regular')
    .text(invoiceI.patient_name, 361, __y);
    __y += 13;

    doc.setFont('Barlow-Light');
    doc.text(this._ui.isClinicUser ? "" : 'Geburtsdatum:', 361, __y)
    .text(invoiceI.patient_birthdate, 541, __y,{align:'right'});
    __y += 13;
    doc.text('Rechnungsdatum:', 361, __y)
    .text(DATETOSTRING(new Date(invoiceI.invoice_date_time)), 541, __y, {align:'right'});
    __y += 13;

    // doc.text('Rechnungsnr.:', 361, __y)
    // .text(invoiceI.invoice_date_year.toString() + invoiceI.invoice_number.toString(), 541, __y, {align:'right'});
    // __y += 13;


    if(this._ui.isClinicUser) {
      doc.text('Fallnummer:', 361, __y)
      .text(invoiceI.invoice_number.toString(), 541, __y, {align:'right'});
    __y += 13;
    } else {
      doc.text('Rechnungsnr.:', 361, __y)
      .text(invoiceI.invoice_date_year.toString() + invoiceI.invoice_number.toString(), 541, __y, {align:'right'});
      __y += 13;
    }

    doc.text(invoiceI.patient_name, 40, 158)
      .text(invoiceI.patient_address_1, 40, 171)
      .text(invoiceI.patient_address_2, 40, 184)


     doc
     .setFont('Barlow-Regular')
     .setFontSize(16)
     .setDrawColor("#444444")
     .text('Privatliquidation nach GOÄ', 40, 274);

     autoTable(doc, {
      margin: {top:10,right:35,left:35},
      theme: 'plain',
      styles:  { font: "Barlow-Light", fontStyle: 'normal',fillColor:[255,255,255] },
      // columnStyles: { 5: { halign: 'right' }, 2:{halign:'center' } , },
      startY: 290,

      body:  [
        ['Diagnose: '],
        [invoiceI.diagnose],
        ['Therapie: '],
        [invoiceI.therapiemethode],
      ],

      didDrawPage: (data) => {
        lastCursor = data.cursor

     },

      didDrawCell: (data) => {

       data.cell.styles.fillColor = [255,255,255],
       data.cell.styles.lineColor = [255,255,255]
      },
    });



    autoTable(doc, {
      head: head,
      styles:  { font: "Barlow-Light", fontStyle: 'normal'},
      columnStyles: { 5: { halign: 'right', cellWidth: 55 }, 2:{halign:'center' } , },
      startY: lastCursor.y,
      body: tableData,
      didDrawPage: (data) => {
        lastCursor = data.cursor
     },
      didDrawCell: (data) => {
      },
    })


    addFooters(doc)




    return doc;
  }



  async createBillingXml(){

    // let _items:InvoicePreviewPositionItemI[] = []
    // _items = await this._tc.getInvoicePreviewItems(this._template.template_id) as InvoicePreviewPositionItemI[]
    // _items = _.orderBy(_items, [item => item.order_index, item => item.position_order_index])

    // // const _invoice_number_next = await this.accountS.getLastInvoiceNumber()
    // // const _procssInvoices = await this.accountS.invoicesByProcessId(this._template.template_id);
    // // // console.log('_invoice_number_next ', _invoice_number_next)
    // // // console.log('_procssInvoices ', _procssInvoices)
    // // let _invoiceNumber;
    // // if(_procssInvoices.length > 0){
    // //   _invoiceNumber = _procssInvoices[0].invoice_number;
    // // } else {
    // //   _invoiceNumber =  _invoice_number_next + 1
    // // }

    // let _matItems = this._tc.allSectionMaterialItems
    // let _ip:InvoicePreviewC = new InvoicePreviewC(this._address, this._template, this.datePipe, _items, _matItems, this._patientFull, this.currencyPipe, '_diagnose', '_tMethod');

    // const _pdf = _ip.createPreview(11122233)
    // const _anrede = 'Herr'


    // let xmlStr = '<?xml version="1.0" encoding="iso-8859-15"?>';
    // xmlStr += `\n<rechnungen xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://padinfo.de/ns/pad padx_adl_v2.12.xsd" anzahl="47" xmlns="http://padinfo.de/ns/pad">`
    // xmlStr += `\n<leistungserbringer id="1" aisid="SCB">`
    // xmlStr += `\n<titel>${this._ui.account.doc_description}</titel>`
    // xmlStr += `\n<vorname>${this._ui.account.docName}</vorname>`
    // xmlStr += `\n<name>${this._ui.account.docName}</name>`
    // xmlStr += `\n<anschrift>`
    // xmlStr += `\n<hausadresse>`
    // xmlStr += `\n<land>D</land>`
    // xmlStr += `\n<plz>${this._address.zip}</plz>`
    // xmlStr += `\n<ort>${this._address.city}</ort>`
    // xmlStr += `\n<strasse>${this._address.streetName}</strasse>`
    // xmlStr += `\n</hausadresse>`
    // xmlStr += `\n</anschrift>`
    // xmlStr += `\n<lanr>201234567</lanr>`
    // xmlStr += `\n<betriebsstaette>`
    // xmlStr += `\n<bsnr>912345678</bsnr>`
    // xmlStr += `\n</betriebsstaette>`
    // xmlStr += `\n</leistungserbringer>`

    // xmlStr += `\n<rechnung id="${this._template.template_id.toString()}" druckkennzeichen="true" eabgabe="false" aisendbetrag="${_ip.invoiceI.summary}">`
    // xmlStr += `\n<rechnungsempfaenger>`
    // xmlStr += `\n<person>`
    // xmlStr += `\n<anrede>${_anrede}</anrede>`
    // xmlStr += `\n<vorname>${this._patientFull.firstName}</vorname>`
    // xmlStr += `\n<name>${this._patientFull.lastName}</name>`
    // xmlStr += `\n<gebdatum>1971-01-01</gebdatum>`
    // xmlStr += `\n<anschrift>`
    // xmlStr += `\n<hausadresse>`
    //   xmlStr += `\n<plz>${this._patientFull.zip}</plz>`
    //     xmlStr += `\n<ort>${this._patientFull.city}</ort>`
    //     xmlStr += `\n<strasse>${this._patientFull.street}</strasse>`
    //     xmlStr += `\n</hausadresse>`
    //   xmlStr += `\n</anschrift>`
    // xmlStr += `\n</person>`
    // xmlStr += `\n</rechnungsempfaenger>`
    // xmlStr += `\n<abrechnungsfall>`
    // xmlStr += `\n<humanmedizin>`
    // xmlStr += `\n<leistungserbringerid>1</leistungserbringerid>`
    // xmlStr += `\n<behandelter aisid="5475">`
    // xmlStr += `\n<anrede>${this._patientFull.anrede}</anrede>`
    // xmlStr += `\n<vorname>${this._patientFull.firstName}</vorname>`
    // xmlStr += `\n<name>${this._patientFull.lastName}</name>`
    // xmlStr += `\n<gebdatum>1984-05-04</gebdatum>`
    // xmlStr += `\n<geschlecht>${this._patientFull.geschlecht.toString()}</geschlecht>`
    // xmlStr += `\n<verwandtschaft>0</verwandtschaft>`
    // xmlStr += `\n</behandelter>`
    // xmlStr += `\n<versicherter>`
    // xmlStr += `\n<anrede>${_anrede}</anrede>`
    // xmlStr += `\n<vorname>${this._patientFull.firstName}</vorname>`
    // xmlStr += `\n<name>${this._patientFull.lastName}</name>`
    // xmlStr += `\n<gebdatum>1971-01-01</gebdatum>`
    // xmlStr += `\n<anschrift>`
    // xmlStr += `\n<hausadresse>`
    //   xmlStr += `\n<plz>${this._patientFull.zip}</plz>`
    //     xmlStr += `\n<ort>${this._patientFull.city}</ort>`
    //     xmlStr += `\n<strasse>${this._patientFull.street}</strasse>`
    //     xmlStr += `\n</hausadresse>`
    //   xmlStr += `\n</anschrift>`
    // xmlStr += `\n</versicherter>`
    // xmlStr += this._getXmlPositions(_items)
    // // <mwstsatz>0</mwstsatz>
    // // <minderungssatz>0</minderungssatz>
    // // <behandlungsart>0</behandlungsart>
    // // <vertragsart>1</vertragsart>
    // // <diagnose>
    // //   <text>Vitamin-B12-Mangel</text>
    // //   <code system="ICD-10">E53.8</code>
    // //   <typ>1</typ>
    // //   <datum>2016-11-11</datum>
    // //   <sicherheit>G</sicherheit>
    // // </diagnose>

    // ///// POSITIONEN
    // // xmlStr += ``
    // xmlStr += `\n</humanmedizin>`
    // xmlStr += `\n</abrechnungsfall>`
    // xmlStr += `\n</rechnung>`
    // xmlStr += `\n</rechnungen>`


    // /* xmlStr += `\n<rechnungsempfaenger id="a"><b id="b">hey!</b></rechnungsempfaenger>` */
    // const parser = new DOMParser();

    // const doc = parser.parseFromString(xmlStr, "application/xml");

    // // print the name of the root element or error message
    // const errorNode = doc.querySelector("parsererror");
    // if (errorNode) {
    //   return "error while parsing"
    // } else {

    //   // console.log(doc.documentElement.getAttributeNames());
    //   // console.log(doc.documentElement.nodeName);
    //   // console.log(doc.documentElement.childNodes[0]);
    //   return xmlStr
    // }
    // return xmlStr.trim()
  }

}
