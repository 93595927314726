import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

@Component({
  selector: 'app-nachbehandlung-pdf-preview',
  templateUrl: './nachbehandlung-pdf-preview.component.html',
  styleUrls: ['./nachbehandlung-pdf-preview.component.scss']
})
export class NachbehandlungPdfPreviewComponent implements OnInit {
 /*  @ViewChild('_htmlEle') _htmlEle:ElementRef
  @ViewChild('_pdfView') _pdfView:ElementRef */
  pdfSrc
  pdfDocGenerator
  showPrintButton = false
 /*  _pdf:jsPDF */
  show = false;
  htmlText = ""
  name = ''
  constructor(
    private dialogRef:MatDialogRef<NachbehandlungPdfPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) 
  { 
    this.htmlText = data[0] 
    this.showPrintButton = data[1]
    this.name = data[2]
    console.log( this.htmlText)
  }

  ngOnInit(): void {
  //   this.pdf = new jsPDF('p','pt');
  //   //NachbehandlungPDF.getPDF(this.data)
  //   this.pdf.html("<html><h1 style='color:#000000;'>Hello world</h1></html>")
  //     this.pdf.save('Test.pdf');
  
  //  // this.pdfSrc = this.pdf.output('datauristring');
  
  setTimeout(()=>{
    this.create()
  },100)
    //this.show = false;
  }
  async create(){
    var html = htmlToPdfmake(this.htmlText);
     
    const documentDefinition = { content: html };
    this.pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    this.pdfSrc = await this.pdfDocGenerator.getDataUrl((dataUrl) => {
      // const targetElement = document.querySelector('#iframeContainer');
      // const iframe = document.createElement('iframe');
      this.show = true;
      this.pdfSrc = dataUrl;
      // targetElement.appendChild(iframe);
    });
  }
  close(){
    this.dialogRef.close();
  }
  print(){
    var html = htmlToPdfmake(this.htmlText);
   // var win = window.open('', '_blank');
    this.pdfDocGenerator=  pdfMake.createPdf({ content: html }).download('Nachbehandlung '  +this.name + '.pdf')
    
    /* $http.post('/someUrl', data).then(function(response) {
      // pass the "win" argument
      this.pdfDocGenerator=  pdfMake.createPdf({ content: html }).print({}, win);
    }); */
  
  }
}
