<mat-toolbar class="overlay-toolbar">
  Vorlagen Name
  <span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_mini" (click)='close()'>close</mat-icon>
</mat-toolbar>

<div class="container-fluid" style="height: calc(100% - 88px);">

  <div class="d-flex flex-column justify-content-center flex-column h100" >
    <div class="p-2 " >
      <input class="formControl mat-body formControlDescription largeFont"
       [(ngModel)]="templateName" #singleLine
       (keydown)="onTextAreaKeyDown($event)"
      >
    </div>

    <div class="p-2" *ngIf="overlayData && overlayData.poolSelectionRequired">
       <div class="container w50p"> 
        <mat-form-field appearance="outline" class="textfield100 select-form-field" style="margin-top: -5px!important">
          <mat-select [(value)]="selectedPool"  (selectionChange)='poolSelectionChanged($event)'>
          <mat-option *ngFor="let templatePool of templatePools" [value]="templatePool">
            {{templatePool.name}}
          </mat-option>
        </mat-select>
        </mat-form-field>
       </div>
    </div>
  </div>
</div>
<mat-toolbar class="overlay-toolbar">
  <div class="d-flex w100">
    <div class="p-2 ml-auto">
      <app-df-icon-button
      title= "Speichern"
      icon="save"
      color="primary"
      (clicked)="save()"
      >
      </app-df-icon-button>
    </div>
  </div>

</mat-toolbar>
