import { HORIZONTAL_ITEM_SELECTION_ACTION } from "../horizontalItemSelectionAction";

export interface TherapyMethod {
  id:number;
  templateId:string;
  activeTextIndex:number;
  text1:string;
  text2:string;
  text3:string;
  text4:string;
  text5:string;
  description1:string;
  description2:string;
  description3:string;
  description4:string;
  description5:string;
}
export interface TemplateNote {
  id:number;
  templateId:string;
  activeTextIndex:number;
  text1:string;
  text2:string;
  text3:string;
  text4:string;
  text5:string;
  description1:string;
  description2:string;
  description3:string;
  description4:string;
  description5:string;
}
export interface TemplateDiagnose {
  id:number;
  templateId:string;
  activeTextIndex:number;
  text1:string;
  text2:string;
  text3:string;
  text4:string;
  text5:string;
  description1:string;
  description2:string;
  description3:string;
  description4:string;
  description5:string;
}

export interface template_item_text_tiles {
  text_tile_id:number;
  templateId:string;
  type:string;
  lastModified:number;
  text:string;
  description:string;
  
}
export interface template_item_text_tiles_local {
  item_tile:template_item_text_tiles;
  index:number;
  updated:boolean;
}
export enum lla_item_type {
  LOKALISIERUNG = 'LOKALISIERUNG',
  LAGERUNG = 'LAGERUNG',
  ANTIBIOSE = 'ANTIBIOSE',
  
}
export interface template_lla_item {
  lla_text_id:number;
  user_id:string;
  type:string;
  lastModified:number;
  text:string;
  
}


export interface template_lla_items_by_type {
  type:string;
  items?:template_lla_item[]
}

export interface ITextitemtilesOverlayData {
  items: template_item_text_tiles[]
  type: string;
  focus: TEXT_ITEM_FOCUS_ELEMENT
}

export enum TEXT_ITEM_FOCUS_ELEMENT {
  NONE = 'NONE',
  TEXT_FOCUS = 'TEXT_FOCUS',
  DESCRIPTION_FOCUS = 'DESCRIPTION_FOCUS',
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  NEXT = 'NEXT',
  PREV = 'PREV'
}