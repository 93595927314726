import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'own-billing-inline-button',
  templateUrl: './add-own-billing-inline-button.component.html',
  styleUrls: ['./add-own-billing-inline-button.component.scss']
})
export class AddOwnBillingInlineButtonComponent implements OnInit {

  // @Input() position:PositionM
  constructor() { }

  ngOnInit(): void {
  }
  // selectBillingItem(){

  // }

}
