import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PatientService } from 'src/app/services/patient.service';
import { Patient} from 'src/app/core/models/patient';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PatientDetailsOverlayComponent } from 'src/app/ui/overlays/patient-details-overlay/patient-details-overlay.component';
import { UiService } from 'src/app/services/ui.service';
import { PatientFlatI } from 'src/app/core/models/v3/patientFlat';

@Component({
  selector: 'app-patienten',
  templateUrl: './patienten.component.html',
  styleUrls: ['./patienten.component.scss']
})
export class PatientenComponent implements OnInit {
  @ViewChild(MatSort, {static:true}) sort: MatSort;
  patients:PatientFlatI[] = [];
  /* patients:Patient[] = []; */
  displayedColumns = ['lastName','firstName','birthDate','city','zip'];
  dataSource: any; 
  winWasOpen = false;
  showDeletePatientDecyptButton = false;
  k:string =''
  constructor(private dialog:MatDialog,
    public ui:UiService,
    private patientService:PatientService, 
    private router:Router) {
    
  }

  ngOnInit(): void {


   setTimeout(()=>{
    this.bcPath()
    this.loadPatienten();
   },1000)

  }

  bcPath(){
   
  }

  async loadPatienten(){
    console.log(new Date().getTime())
    const _patientsAllFlat = await this.patientService.allFlat(this.ui.account.uid);
    this.patients = _patientsAllFlat['result'] as PatientFlatI[]
    this.dataSource = new MatTableDataSource(this.patients);
    this.dataSource.sort = this.sort;
    
  }
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  openPatient(p:PatientFlatI){
    this.router.navigate(['verwaltung/patientenverwaltung/patient',p.patient_id]);
  }
 
  addPatient(){
   // this.addSamplePatients()

     let newP:Patient = {
      docId:'',
      birthDate: new Date(Date.now()),
      firstName:'',
      invoiceName:'',
      addressAddon:'',
      lastName:'',
      phone:'',
      email:'',
      street:'',
      zip:'',
      city:'',
      ensuranceDocId:'',
      unencrypted: this.ui.account.hastPatientEncryptions != null && this.ui.account.hastPatientEncryptions == false ? true : false,
      private: false,
      enabled: true

    }
    const dialogRef = this.dialog.open(PatientDetailsOverlayComponent, {
      disableClose:true,
      width: '80%',
      data: newP,
      backdropClass:"backdrop-background"
    });

    dialogRef.afterClosed().subscribe(result => {
      
      
    }); 
  }
}

