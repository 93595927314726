import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import _ from 'lodash';
import { apiSugeryRequest, SURGERY_REPORT_CALL } from 'src/app/core/models/api/sugeryReportApiCalls';
import { SurgeryProtocolEditI } from 'src/app/core/models/justificationEditI';
import { SurgeryReportItenV4I } from 'src/app/core/models/v3/SurgeryReportItemIV3';
import { ApiUtilService } from 'src/app/services/api-util.service';

import { TemplateControllerService } from 'src/app/services/template-controller.service';

interface TextKeyManagerOption {
  disabled?:boolean;
  getLabel?():string
}
interface Highlightable extends TextKeyManagerOption {
  setActiveStyles(): void;
  setInactiveStyles(): void;
}
interface FocusableOption extends TextKeyManagerOption {
  focus(): void;
}
@Component({
  selector: 'app-surgery-report-positions-overlay',
  templateUrl: './surgery-report-positions-overlay.component.html',
  styleUrls: ['./surgery-report-positions-overlay.component.scss'],
  
})
export class SurgeryReportPositionsOverlayComponent implements OnInit {
  @ViewChild('tabbar') tabbar:MatTabGroup
  @ViewChild('_reportTextArea') _reportTextArea:ElementRef

  selectedIndex:number=0;
  surgeryReports:SurgeryReportItenV4I[] = []
  surgeryReportsOriginal:SurgeryReportItenV4I[] = []
  text:string = ''
  editable = true
  isSaving = false
  charCount = 0
  constructor(
    public dialogRef: MatDialogRef<SurgeryReportPositionsOverlayComponent>,
    private tc:TemplateControllerService,
    @Inject(MAT_DIALOG_DATA) public data: SurgeryProtocolEditI,
    private apiUtil:ApiUtilService
    ) {
    }


  ngOnInit(): void {
    if(this.data.reports != null) {
      this.surgeryReports = _.cloneDeep(this.data.reports)
      this.surgeryReportsOriginal = _.cloneDeep(this.data.reports)
    }

    setTimeout(()=>{
      this.selectedIndex = this.data.selectedIndex
      // this.focusIndex = this.data.selectedIndex
      // this.charCount =  this.surgeryReports[this.selectedIndex].text.length
      this.initCharCount()
    },250)

  }
  get getReportionCopy():string {
    if(this.surgeryReports[this.selectedIndex] != null && this.surgeryReports[this.selectedIndex].text != null){
      return this.surgeryReports[this.selectedIndex].text
    }
    return ''
  }
  set getReportionCopy(val) {
    if(this.surgeryReports[this.selectedIndex] != null && this.surgeryReports[this.selectedIndex].text != null){
      this.surgeryReports[this.selectedIndex].text = val
    }

  }

  initCharCount(){
    setTimeout(()=>{
      this.charCount =  this.surgeryReports[this.selectedIndex].text.length
    },150)
  }

  onTextAreaKeyUp(event){
    this.charCount = event.target.value.length;
  }

  // ON ESCAPE KEY
  async onEscapeKeyDown(event:any, textfield:string){
    // console.log('onEscapeKeyDown');

    if(textfield == 'description') {
      this.reportionCopyDescription = event.target.value
    } else if(textfield == 'text'){
      this.getReportionCopy = event.target.value
    } else {
      this.dialogRef.close(this.surgeryReports)
      return;
    }

    const _report = this.surgeryReports[this.selectedIndex]
    const _obj = await this.syncTextChanged();
    // console.log('onEscapeKeyDown > Objs ', _obj);
    let _timeout = 10
    if(Object.keys(_obj).length){
      await this.saveReportContent(_obj, _report.surgery_report_item_id.toString())
      // console.log('onEscapeKeyDown > SAVE');
      _timeout = 350;
    }

    setTimeout(()=> {
      this.dialogRef.close(this.surgeryReports)
    },_timeout)
  }


  // on TEXTFIELD BLUR
  async onTextFieldBlur(event:any, textfield: string) {
    if(textfield == 'description') {
      const _isEqual = _.isEqual(event.target.value, this.reportionCopyDescription)
      if(!_isEqual) {
        this.reportionCopyDescription = event.target.value
        const _obj = await this.syncTextChanged();
        if(Object.keys(_obj).length){
          await this.saveReportContent(_obj, this.surgeryReports[this.selectedIndex].surgery_report_item_id.toString())
        }
      }
    } else if(textfield == 'text'){
      const _isEqual = _.isEqual(event.target.value, this.getReportionCopy)
      if(!_isEqual) {
        this.getReportionCopy = event.target.value
        const _obj = await this.syncTextChanged();
        if(Object.keys(_obj).length){
          await this.saveReportContent(_obj, this.surgeryReports[this.selectedIndex].surgery_report_item_id.toString())
        }

      }
    }
  }


  private async saveReportContent(obj:Object, id:string) {
    this.isSaving = true;
    const _keys = Object.keys(obj);
    let _payload = {}
    for(const key of _keys) {
      _payload[key] = obj[key]
    }
    const _path = apiSugeryRequest(this.tc.prefix,SURGERY_REPORT_CALL.UPDATE_REPORT_OBJ_BY_ID, id)
    await this.apiUtil.apiPost(_path,{ payload: _payload})
    this.isSaving = false;
  }


  async syncTextChanged(): Promise<Object> {
    return new Promise((resolve, reject) => {
      let _obj = {}
      const _isDescriptionEqual = _.isEqual(this.surgeryReports[this.selectedIndex].description, this.surgeryReportsOriginal[this.selectedIndex].description)
      if(!_isDescriptionEqual){
        _obj["description"] = this.reportionCopyDescription;
        this.surgeryReportsOriginal[this.selectedIndex].description = this.reportionCopyDescription;
      }
      const _isTextEqual = _.isEqual(this.surgeryReports[this.selectedIndex].text, this.surgeryReportsOriginal[this.selectedIndex].text)
      if(!_isTextEqual){
        _obj["text"] = this.getReportionCopy;
        this.surgeryReportsOriginal[this.selectedIndex].text = this.getReportionCopy;
      }
      // const _report = this.surgeryReports[this.selectedIndex]
      // console.log(_obj)
      resolve(_obj);
    });
  }

  async close(){
    this.dialogRef.close(this.surgeryReports)
  }
  saveReport(w){
   /*  this.surgeryReports[this.selectedIndex].text = w.target.value; */
    this.charCount = this.surgeryReports[this.selectedIndex].text.length
    // this.save()
  }

  // async updateActiveIndex(){
  //   await this.tc.updateActiveSurgeryReport(this.surgeryReports[this.selectedIndex].position_id, this.surgeryReports[this.selectedIndex].surgery_report_item_id)
  // }
  private setTextAreaFocus(){
    setTimeout(()=>{
      this._reportTextArea.nativeElement.focus()
    },150)
  }

  // saveReportDescription(w){
  //   this.surgeryReports[this.selectedIndex].description = w.target.value;
  // }
  get reportionCopyDescription():string {
    return this.surgeryReports[this.selectedIndex].description;
  }
  set reportionCopyDescription(val) {
    this.surgeryReports[this.selectedIndex].description = val;
  }

  set reportionCopy(val){
    this.surgeryReports[this.selectedIndex].text = val
  }

  get reportionCopy():string {
    return this.surgeryReports[this.selectedIndex].text;
  }

  async prevReport(){
    if(!this.editable){
      return;
    }
    // await this.checkChanges()
    if(this.selectedIndex > 0){
      this.selectedIndex --
      this.setTextAreaFocus()
      this.initCharCount()
      // await this.updateActiveIndex()
    }
  }
  async nextReport(){
    if(!this.editable){
      return;
    }
    //await this.checkChanges()
    if(this.selectedIndex < this.surgeryReports.length -1){
      this.selectedIndex ++
      this.setTextAreaFocus()
      this.initCharCount()
      // await this.updateActiveIndex()
    }
  }
}
