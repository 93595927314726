import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import * as _ from 'lodash';
import { ControllerService } from 'src/app/shared/controller.service';
import { NavigationService } from '../services/navigation.service';
import { SIGN_IN_MODE } from '../core/models/enums';
import { UiService } from '../services/ui.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
constructor(
    public auth: AuthService,
    public ui: UiService,
    public router: Router,
    public afAuth: AngularFireAuth,
    private _nav:NavigationService

  ){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
     /*  console.log(next)  */

    //  if(this._nav.initRoute == null){
    //   console.log('\n\n??????????INIT ROUTE SIGN OUT')
    //  }
      this._nav.setInitialRoute(state.url)
      // let u = this.auth.getUser();
      let r = ControllerService.getRoles();


      if(this.auth.isLoggedIn === false || this.auth.sign_in_mode == SIGN_IN_MODE.UPDATE_PASSWORD_REQIERED
        || this.auth.sign_in_mode == SIGN_IN_MODE.SIGN_IN) {
        //

        this.router.navigate(['auth/sign-in'])
        return false;
      } 
     
      if(this.auth.sign_in_mode == SIGN_IN_MODE.READY){
        return true
      }
      if(!next.data.roles) {
        return true;
      }
      if(next.data.roles && r &&
        _.intersection(next.data.roles, r).length > 0) {
        return true;
      }
      
    return false;
  }

}

