<div class="container-fluid">

  <div class="row p_t40 pl40 pr40" [class.cardOut]='!showCard' [class.cardIn]='showCard'>
    <div class="col-8">
      <h1 [innerHTML]="ui.helloMessage"></h1>
      <!-- <h1 [innerHTML]="ui.helloMessage"></h1> -->
    </div>
    
  </div>
  <div class="row pl40 pr40" *ngIf='!isLoading'>
  
     <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 p_t30" *ngIf="!ui.isAccountant">
      <app-dashboard-template-item title="Vorlagen" [isUnpublishedMode]=false></app-dashboard-template-item>
    </div> 

    <div class="col-xl-6 col-lg-8 col-md-12 col-sm-12 p_t30" *ngIf="ui.canCreatePublicDxFx">
      <app-dashboard-template-item [title]="unpublished_teplates" [isUnpublishedMode]=true></app-dashboard-template-item>
    </div>
    
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 p_t30" >
      <app-dashboard-processes-item></app-dashboard-processes-item>
    </div>


  </div>
