import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { IAccountantUserPool, IAccountingPool, IClinicSectorFull } from 'src/app/core/models/accountI';
import { ACCOUNTING_INVITE_RESPONSE_CODE, CREATE_ACCOUNTING_SECTION_POOL_RESPONSE, CREATE_PASSWORD_RESPONSE_STATUS, IAccountingInviteResponse, STATUS_RESPONSE } from 'src/app/core/models/api/auth.response.mode';
import { AccountService } from 'src/app/services/account.service';
import { ACCOUNT_CALL } from 'src/app/services/enums/accountS.callEnum';
import { UiService } from 'src/app/services/ui.service';
import { ControllerService } from 'src/app/shared/controller.service';
import * as _ from "lodash";
@Component({
  selector: 'app-accounting-user-detail-overlay',
  templateUrl: './accounting-user-detail-overlay.component.html',
  styleUrls: ['./accounting-user-detail-overlay.component.scss']
})
export class AccountingUserDetailOverlayComponent implements OnInit {
  isSaving:boolean = false
  firstName= ''
  lastName= ''
  email= ''
  role = 'ACCOUNTANT'
  accountingPool:IAccountingPool
  clinicAccountantUserPools: IAccountantUserPool[] = []
  clinicAccountantUserPoolsSelected: IAccountantUserPool[] = []
  // clinicSectors:IClinicSectorFull[] = []
  // clinicSectorsSelected: IClinicSectorFull[] = []
  errorMessage: string = null
  isClinicMode = false
  // accountingPoolId = null
  get title():string {
    return this.role == 'ACCOUNTANT' ? "Neue Abrechnugsperson einladen" : "Einen Kollegen einladen"
  }
  constructor(public dialogRef: MatDialogRef<AccountingUserDetailOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AccountingUserDetailOverlayComponentData,
    private ui: UiService,
    private accountS:AccountService) {
      console.log(data)
      this.accountingPool = data.accountingPool
      this.clinicAccountantUserPools = data.clinicAccountantUserPools
      this.role = this.data.inviteRole ? this.data.inviteRole : 'ACCOUNTANT'
      this.isClinicMode = data.isClinicMode


     }

  ngOnInit(): void {
  }

  async save(){
    this.isSaving = true;

    let userPoolIds =  this.isClinicMode ? _.map(this.clinicAccountantUserPoolsSelected, 'accounting_user_pool_id') : [this.accountingPool.accounting_user_pool_id]
    const dto = {
      firstName : this.firstName,
      lastName : this.lastName,
      email : this.email,
      role : this.role,
      // pool_id: this.clinicAccountantUserPoolsSelected[i].accounting_user_pool_id,
      accountType: this.isClinicMode ? 'CLINIC' : 'BASIC',
      subscription_plan_id: this.ui.account.subscription_plan_id,
      userPoolIds: userPoolIds
    }
    console.log(dto);
    const _result = await this.accountS.apiPostObject(ACCOUNT_CALL.INVITE_TO_ACCOUNTINGPOOL, {dto})
    console.log(_result);
      let respone = _result as IAccountingInviteResponse
      if (respone.status == STATUS_RESPONSE.ERROR) {
        if (respone.code == ACCOUNTING_INVITE_RESPONSE_CODE.USER_ACCOUNT_NOT_REGISTERED) {
          this.errorMessage = 'Dieser User ist bei doxfox nicht registriert. Bitte wenden Sie sich an den Support.'
        } else if (respone.code == ACCOUNTING_INVITE_RESPONSE_CODE.USER_ALREADY_HAS_ACCOUNTANT) {
          this.errorMessage = 'Dieser User ist bereits eine Abrechnungsperson.'
        } else if (respone.code == ACCOUNTING_INVITE_RESPONSE_CODE.USER_HAS_ALSO_ACCOUNTING) {
          this.errorMessage = 'Dieser User hat bereits einen Abrechnungspool.'
        }
      } else {
        this.onClose();
      }
      
   
    
    
  }
  private onClose() {
    setTimeout(()=>{
        this.close()
      },450)
   }

  sectorSelectionChanged(e:MatSelectionListChange) {
    console.log(e.option.value);
    const index = this.clinicAccountantUserPoolsSelected.findIndex(item => item == e.option.value);
    if(index != -1) {
      this.clinicAccountantUserPoolsSelected.splice(index, 1);
    } else {
      this.clinicAccountantUserPoolsSelected.push(e.option.value)
    }
    console.log(this.clinicAccountantUserPoolsSelected);
  }
  get canSave(): boolean {
    if(this.isClinicMode && this.clinicAccountantUserPoolsSelected.length == 0) {
      return false;
    }
    if (this.role == 'USER') {
      
      if(this.email.length < 2) {
        return false
      }
    } else {
        if(this.firstName.length < 2 || this.lastName.length < 2 || this.email.length < 2) {
        return false
      }
    }
    return true
  }
  close(){
    this.dialogRef.close();
  }
}

export interface AccountingUserDetailOverlayComponentData {
  isClinicMode: boolean
  accountingPool?:IAccountingPool,
  inviteRole?:string
  neetToCreateClinicSectionPool:boolean
  // clinicSectors?:IClinicSectorFull[]
  clinicAccountantUserPools?:IAccountantUserPool[]
  // accountingPoolId?: number
  
}

