import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BilligItemI } from 'src/app/core/models/billing_itemI';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-own-billig-edit',
  templateUrl: './own-billig-edit.component.html',
  styleUrls: ['./own-billig-edit.component.scss']
})
export class OwnBilligEditComponent implements OnInit {
  newBillingItem:BilligItemI
  isLoading = false

  constructor(
    private ui:UiService,
    private dialogRef:MatDialogRef<OwnBilligEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data:BilligItemI 
    ) {

      this.newBillingItem = data
     }

  ngOnInit(): void {
  }

  shortChanged(e){
    this.newBillingItem.short_description = e.target.value
  }
  longChanged(e){
    this.newBillingItem.long_description = e.target.value
  }
  get canEdit():boolean{
    return this.newBillingItem.owner == this.ui.account.uid;
  }
  
  async saveNewItem(){
    this.isLoading = true;
    await this.ui.postBillingItem(this.newBillingItem).then(
      (x) => {
        setTimeout(()=>{
          this.isLoading = false;
          
          this.dialogRef.close('reload')
        },500)
      }
    )
    
  }
  get canSave():boolean {
    if(this.newBillingItem == null) { return false }
    if(this.newBillingItem.long_description.length > 2  && this.newBillingItem.short_description.length > 2 ) {
      return true
    } else {
      return false
    }
  }
  close(){
    this.dialogRef.close();
  }
}
