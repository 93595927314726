import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TemplateFlatI } from 'src/app/core/models/api/TemplateFlatI';
import { API_RESPONSE, API_RESPONSE_STATE, VIEWSTATE } from 'src/app/core/models/api/auth.response.mode';
import { TemplateApiService } from 'src/app/services/template-api.service';
@Component({
  selector: 'app-share-template-overlay',
  templateUrl: './share-template-overlay.component.html',
  styleUrls: ['./share-template-overlay.component.scss']
})
export class ShareTemplateOverlayComponent implements OnInit {
  @ViewChild('_inviteEmailTF') _inviteEmailTF: ElementRef;
  email:string =''
  message:string =''
  error: string 
  template:TemplateFlatI
  viewState: VIEWSTATE = VIEWSTATE.NONE
  VIEWSTATES = VIEWSTATE

  constructor(
    public dialogRef: MatDialogRef<ShareTemplateOverlayComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: TemplateFlatI, private templateApi: TemplateApiService
    ) { 
      this.template = data;
    }

  ngOnInit(): void {
  }
  
  private clear() {
    // setTimeout(() => {
    //   this.error = null
    //   this.email = ""
    //   this.viewState = VIEWSTATE.NONE
    // },4000)
  }
  inviteWithOtherEmail() { 
    this.error = null
    this.email = ""
    this.viewState = VIEWSTATE.NONE
    setTimeout(() => {
      this._inviteEmailTF.nativeElement.focus();
    },50)
  }
  private closeWithSuccess() {
    setTimeout(() => {
      this.dialogRef.close();
    },2500)
  }
  async save(){
    this.viewState = VIEWSTATE.LOADING
    const payload = {
        templateDocumentId:this.template.template_id,
        inviteEmail: this.email,
        inviteMessage: this.message,
        templateName: this.template.name
      }
  
    const response = await this.templateApi.shareTemplate(payload) as API_RESPONSE

    if(response.status == API_RESPONSE_STATE.SUCCESS) {
      this.viewState = VIEWSTATE.SUCCESS
      this.closeWithSuccess()
    } else {
      if(response.message) {
        this.error = response.message
        // this.isLoading = false
        this.viewState = VIEWSTATE.ERROR
        this.clear()
      }
    }
    
  }
  close() {
    this.error = null
    this.email = ""
    this.viewState = VIEWSTATE.NONE
    this.dialogRef.close();
  }

}
