import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DxFxBreadCrumb } from 'src/app/core/models/breadCrumpIntemI';
import { AccountI } from 'src/app/core/models/accountI';
import { ControllerService } from 'src/app/shared/controller.service';
import { UiService } from 'src/app/services/ui.service';

interface IMainButton {
  btnTitle: string
  path: string
  icon: string
  hasManagerRole: boolean;
  hasSubMenu: boolean;
}

interface IBreadCrumb {
  label: string;
  url: string;

}
@Component({
  selector: 'app-breadcrump',
  templateUrl: './breadcrump.component.html',
  styleUrls: ['./breadcrump.component.scss']
})
export class BreadcrumpComponent implements OnInit {
  public breadcrumb: DxFxBreadCrumb
  currentScrollOffetY:number = 0
  isPinned = false;
  animationTimeout:any
  hasAdminAccess:boolean = false;
  userPath=''
  account:AccountI
  trenner:string ='>'

  pos1 = 105
  pos2 = 107
  isManager:boolean = false;

  _btnsAdmin: IMainButton[] = [
    { btnTitle: "Übersicht", path: "dashboard", icon: "/assets/nav/dashboard-24px.svg", hasManagerRole: false, hasSubMenu: false},
    { btnTitle: "Vorlagen", path: "templates", icon: "/assets/nav/custom_icon_rechnungen.svg", hasManagerRole: false, hasSubMenu: false},
    // { btnTitle: "Meine Leistungen", path: "vorgaenge", icon: "/assets/nav/format_list_bulleted-24px.svg", hasManagerRole: false, hasSubMenu: false},
    { btnTitle: "Verwaltung", path: "verwaltung", icon: "/assets/nav/verwaltung.svg", hasManagerRole: false, hasSubMenu: false},

  ]
  _btns: IMainButton[] = [
    { btnTitle: "Übersicht", path: "dashboard", icon: "/assets/nav/dashboard-24px.svg", hasManagerRole: false, hasSubMenu: false},
    { btnTitle: "Vorlagen", path: "templates", icon: "/assets/nav/custom_icon_rechnungen.svg", hasManagerRole: false, hasSubMenu: false},
    { btnTitle: "Meine Leistungen", path: "vorgaenge", icon: "/assets/nav/format_list_bulleted-24px.svg", hasManagerRole: false, hasSubMenu: false},
    { btnTitle: "Verwaltung", path: "verwaltung", icon: "/assets/nav/verwaltung.svg", hasManagerRole: false, hasSubMenu: false},

  ]

  _buttonsAccountant: IMainButton[] = [
    { btnTitle: "Übersicht", path: "dashboard", icon: "/assets/nav/dashboard-24px.svg", hasManagerRole: false, hasSubMenu: false},

    { btnTitle: "Meine Leistungen", path: "vorgaenge", icon: "/assets/nav/format_list_bulleted-24px.svg", hasManagerRole: false, hasSubMenu: false},
    // { btnTitle: "Verwaltung", path: "verwaltung", icon: "/assets/nav/verwaltung.svg", hasManagerRole: false, hasSubMenu: false},

  ]
  constructor(
    private ui:UiService,
    private router: Router,
    ) { }

  ngOnInit(): void {
    // setTimeout(()=>{
    //   this.subscr()
    // },1500)

    ControllerService.breadCrumpEmitter.subscribe(
      (_bcData:DxFxBreadCrumb)=>{
        if(this.breadcrumb != _bcData){
          let itemTitles = ''
          for(const title of _bcData.bcItems){
            itemTitles += title.title
            itemTitles += ' | '
          }
        }
        this.breadcrumb = _bcData
      }
    );

    ControllerService.windowScrollEmitter.subscribe(
      _yOffset => {
         this.checkBreadCrumpPosition(_yOffset)
      }
    )
  }

  get btns(): IMainButton[] {
    if (this.ui.USE_ACCOUNTING) {
      if (this.ui.account == null) {
        return []
      }
      
      if (this.ui.hasAccountantRole) {
        return this._buttonsAccountant
      } else if (this.ui.hasTemplateAdminRole) {
        
        return this._btnsAdmin  
      } else {
        return this._btns
      }
      //let roles = this.ui.account.roles

      // if (roles == null || roles.length == 0) {
      //   return []
      // } else {

      //   if (roles.includes('accounting')) {
      //     return this._btnsAccounting
      //   } else {
      //     return this._btns
      //   }
      // }


    } else {
      return this._btns
    }

  }
  checkBreadCrumpPosition(newP:number){
    this.currentScrollOffetY = newP;
    if(newP > this.pos1 && this.isPinned === true ){
      return
    }
    if(newP <= this.pos2 && this.isPinned === false){
      return
    }

    if(this.animationTimeout){
      clearTimeout(this.animationTimeout)
    }

    this.animationTimeout = setTimeout((() => {
      if(newP > this.pos1 && this.isPinned === false ){
        this.isPinned = true;
      }
      if(newP < this.pos2 && this.isPinned === true){
        this.isPinned = false;
      }
  }).bind(this), 50);


  }
  home(){
    this.router.navigateByUrl('dashboard')
  }
}
