 import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { API_POST_MODE, PATIENT_SELECTION_VIEW_MODE } from 'src/app/core/models/enums';
import { HealthInsuranceM } from 'src/app/core/models/healthinsurance';
import { PatientFull } from 'src/app/core/models/patient';
import { UiService } from 'src/app/services/ui.service';
import { PatientDetailOverlayInputI } from 'src/app/core/models/viewI/patientSelectionInputI';
import { PatientService } from 'src/app/services/patient.service';
import { IS_INT } from 'src/app/utils/helpers';

 @Component({
  selector: 'app-patient-details-overlay',
  templateUrl: './patient-details-overlay.component.html',
  styleUrls:  ['./patient-details-overlay.component.scss']
})
export class PatientDetailsOverlayComponent implements OnInit {

  isLoading = true;
  patient:PatientFull
  healthEnsurances:HealthInsuranceM[]
  patientDetailViewMode
  hasExistingUserModified = false
  // insurance:HealthInsuranceM
  inputItem:PatientDetailOverlayInputI
  
  constructor(
    private ui: UiService,
    public dialogRef: MatDialogRef<PatientDetailsOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PatientDetailOverlayInputI,
    private patientService:PatientService
    ){
      this.inputItem = data
  }

  ngOnInit(){
    if(this.inputItem) {

      this.patientDetailViewMode = this.inputItem.startMode

      if(this.inputItem.startMode === PATIENT_SELECTION_VIEW_MODE.MODIFY_MODE 
        && this.inputItem.openWithPatientId != null) {
          this.initPatient(this.inputItem.openWithPatientId);
        }  else if (this.inputItem.startMode === PATIENT_SELECTION_VIEW_MODE.CREATE_NEW_MODE){
          this.initAndCreateNew();
        }


    } else {
      setTimeout(()=>{
        this.close()
      },5000)
    }
    
    // setTimeout(()=>{
    //   this.loadPatient()
    // },250)
  }

  private async initPatient(patId:any) {
    const patResponse = await this.patientService.getSingle(patId);
    this.patient = patResponse as PatientFull

    // if(this.patient != null && this.patient.health_insurance_id != null && this.patient.title != null) {
    //   this.insurance = {
    //   health_insurance_id: this.patient.health_insurance_id, 
    //     title: this.patient.title
    //   }
    // }
    this.healthEnsurances = await this.patientService.getEnsurances();
    this.isLoading = false
  }

  private async initAndCreateNew() {
    this.patient  = {
      patient_id:-1,
      birthDate: null,//new Date(Date.now()).getTime(),
      firstName:'',
      lastName:'',
      oldDocumentId:'',
      invoiceName:'',
      phone:'',
      mobile_phone:'',
      email:'',
      ensuranceDocId:'',
      health_insurance_id: null,
      addon:'',
      street:'',
      zip:'',
      city:'',
      notes:'',
      anrede:null,
      geschlecht:null
    }
    this.healthEnsurances = await this.patientService.getEnsurances();
    this.isLoading = false
  }

  private emitEvent(){
    this.hasExistingUserModified = true
    // if(!this.hasTextChanged){
    //   this.hasTextChanged = true
    //   this.onExistingUserModified.emit('yes')
    // }
  }
  /* secletedChanged(event){
    this.patient.health_insurance_id = event.option.value.health_insurance_id
    this.emitEvent()
  } */
  /* applyFilter(filterValue: string) {
    this.filteredOptions.filter = filterValue.trim().toLowerCase();
  }  */
  
  // displayFn(item: HealthInsuranceM): string {
  //   return item && item.title ? item.title : '';
  // }
  // textChanged(e){
  //   this.emitEvent()
  // }

  async save(){
    const _data = { "payload": this.patient }
    let _mode = null 
    if(this.patient.patient_id != null && IS_INT(this.patient.patient_id)) {
      if(this.patient.patient_id == -1){
        _mode = API_POST_MODE.ADD_NEW;
      } else if(this.patient.patient_id > 0){
        _mode = API_POST_MODE.UPDATE;
      }
    }
    
    if(_mode == null) {
      // this.newPatient = null;
      this.dialogRef.close()
      return
    }
    
    const result = await this.patientService.postPatient(_mode,_data, this.ui.account.uid)
    setTimeout(()=>{
      this.dialogRef.close('reload')
    },250)
    
  }

  close(){
    this.dialogRef.close()
  }
  // onBirthDateEdit(){
  //   this.emitEvent()
  //   let _birthDate = this.patient != null  && this.patient.birthDate != null ? new Date(this.patient.birthDate) : null;
  //   this.dialog.open(SimpleDateSelectionComponent,{ 
  //     data: _birthDate,
  //     disableClose: true
  //   }).afterClosed().subscribe(
  //     (_newDate:Date) => {
  //       if(_newDate != null){
  //         this.patient.birthDate = new Date(_newDate).getTime()
  //       }
  //     }
  //   )
  // }
  get title() : string {
    return this.patientDetailViewMode === PATIENT_SELECTION_VIEW_MODE.CREATE_NEW_MODE ? 'Neuen Patienten hinzufügen' : 'Patient bearbeiten';
  }
  // get firstNameError():boolean {
  //   return this.patient == null || this.patient.firstName == null || this.patient.firstName.length < 3
  // }
  // get lastNameError():boolean {
  //   return this.patient == null || this.patient.lastName == null || this.patient.lastName.length < 3
  // }
  // get streetError():boolean {
  //   return this.patient == null || this.patient.street == null || this.patient.street.length < 4
  // }
  // get zipError():boolean {
  //   return this.patient == null || this.patient.zip == null || this.patient.zip.length < 4
  // }
  // get cityError():boolean {
  //   return this.patient == null || this.patient.city == null || this.patient.city.length < 4
  // }
  // get bithDateError():boolean {
  //   return this.patient == null || this.patient.birthDate == null
  // }
  get canSave():boolean {
    if(this.patient == null) { return false }
    if(this.patient.firstName.length > 2  && this.patient.lastName.length > 2 && 
    this.patient.street.length > 3 && this.patient.zip.length > 3 && this.patient.city.length > 3 && this.patient.birthDate) {
      return true
    } else {
      return false
    }
  }
  
  onExistingUserModified(e){
    if(e === 'patient_changed'){
      this.hasExistingUserModified = true
    } else if(e === 'ERRORS') {
      // this.hasPatientItemErrors = true;
    } else if(e === 'NO_ERRORS') {
      // this.hasPatientItemErrors = false;
    }
    
  }
}

  /* async loadPatient(){

    if(this.inputItem){

      // this.patientDetailViewMode = this.inputItem.startMode

      if(this.inputItem.startMode === PATIENT_SELECTION_VIEW_MODE.MODIFY_MODE 
        && this.inputItem.openWithPatientId != null) {

        const _patientFull = await this.patientService.getSingle(this.inputItem.openWithPatientId)

        this.patient = _patientFull as PatientFull

        if(this.patient != null && this.patient.health_insurance_id != null && this.patient.title != null) {
          this.insurance = {
          health_insurance_id: this.patient.health_insurance_id, 
            title: this.patient.title
          }
        }
        this.healthEnsurances = await this.patientService.getEnsurances();
        this.isLoading = false
      } else if (this.inputItem.startMode === PATIENT_SELECTION_VIEW_MODE.CREATE_NEW_MODE){
        this.patient  = {
          patient_id:-1,
          birthDate: null,//new Date(Date.now()).getTime(),
          firstName:'',
          lastName:'',
          oldDocumentId:'',
          invoiceName:'',
          phone:'',
          mobile_phone:'',
          email:'',
          ensuranceDocId:'',
          health_insurance_id: null,
          addon:'',
          street:'',
          zip:'',
          city:'',
          notes:'',
          anrede:null,
          geschlecht:null
        }
        this.healthEnsurances = await this.patientService.getEnsurances();
        this.isLoading = false
      } else {
        setTimeout(()=>{
          this.close()
        },1000)
      }
      
      // this.healthEnsurances = await this.patientService.getEnsurances();
      // // this.filteredOptions = new MatTableDataSource<HealthInsuranceM>(this.healthEnsurances);
      // this.isLoading = false
    }
    
  }
   */