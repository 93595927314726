<div class="p-0">
  <mat-label class='mat-caption'>{{supportUser.role_title}}</mat-label>
</div>
<div class="p-0 supportUserItem" >
  <div class="d-flex align-items-center">
    <div class="p-0 flex-fill prelative">
      <div class="customOpenTrigger" #_trigger (click)="toggleSelectClick()"></div>
      <mat-form-field appearance="outline" class="textfield100 select-form-field panel-class-applied-on-mat-select" >
        <mat-select  #_selector [(value)]="currentUser" (selectionChange)='supportUserChanged($event)' [disabled]='!editable' (click)="toggleSelectClick()" style="pointer-events: all!important;">
          <!-- (openedChange)="openedChange($event)" -->
          <!-- (click)="toggleSelectClick()"  -->
          <!-- <mat-select-trigger>{{selectedUser.title}}  {{selectedUser.firstName}} {{selectedUser.lastName}}</mat-select-trigger> -->
          
          <mat-option *ngFor="let employee of employeeList" [value]="employee">
            {{employee.title}}  {{employee.firstName}} {{employee.lastName}}
          </mat-option>
         
            <div mat-option class="d-flex align-items-center add-button-select" (click)="addPerson()">
              <div class="p-2  btn-copy pl12 p_t5 p_b5">Neue Person</div>
              <div class="p_2 align-text-end ml-auto">
                <mat-icon class="nav-icon-mini">add</mat-icon>
              </div>
            </div>
       
        </mat-select>
      </mat-form-field>
      <!-- {{supportUser.firstName}} {{supportUser.lastName}} -->
    </div>
    <div class="p-0 ml-auto" style="height: 18px;width: 37px; padding-left: 8px!important;" *ngIf="editable">
      <mat-icon class="dxfx_icon_btn_mini" (click)="deleteSupport(supportUser)">delete</mat-icon>
    </div>
  </div>
</div>