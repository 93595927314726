import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from "lodash";
import { GoaJustificationI } from 'src/app/core/models/goaJustificationI';
import { JustificationItemI,  PositionFlatI } from 'src/app/core/models/sectionPositionM';
import { TemplateControllerService } from 'src/app/services/template-controller.service';


@Component({
  selector: 'app-position-justifications',
  templateUrl: './position-justifications.component.html',
  styleUrls: ['./position-justifications.component.scss']
})
export class PositionJustificationsComponent implements OnInit, OnDestroy {

  @Input() currentIndex:number = 0
  @Input() position:PositionFlatI
  // @Input() progression_factors:PositionProgressionFactors[];
  @Output() onTapped: EventEmitter<void> = new EventEmitter<void>()

  // currentIndex = 0
  currentFactor = 2.3
  // _goaJustifications:GoaJustificationI[] = []
  // factorChangedSubscription:Subscription

  constructor(private dialog:MatDialog, public tc:TemplateControllerService) { }

  ngOnDestroy(): void {
    // if(this.factorChangedSubscription != null){
    //   this.factorChangedSubscription.unsubscribe()
    // }
  }

  ngOnInit(): void {
    if(this.position.justifications && this.position.justifications.length > 0) {
      const _lastIndented = _.maxBy(this.position.justifications, item => item.lastModified)
      console.log(_lastIndented);
      
    }
    
  //  setTimeout(()=>{
  //   if(this.positionFactor > 2.3){
  //     this.loadJustificationFor()
  //   }

  //   this.factorChangedSubscription = this.tc.positionFactorChanged.subscribe(
  //     position_id =>{
  //       if(position_id == this.position.position_id){
  //         // console.log('position_id ', this.position.progression_factor)
  //         this.loadJustificationFor()
  //       }
  //     }
  //   )
  //  },150)
  //  this.tc.goaJustigicatiionUpdate.subscribe(
  //    _update => {
  //     this.loadJustificationFor()
  //    }
  //  )
  }


  get isEditable():boolean {
    return this.tc.isTemplateEditable
  }

  get itemNeedJustification():boolean {
    if(this.position.progression_factor >  2.3) {
      return true;
    }
    return  false;
  }

  get positionFactor():number {
    return this.position.progression_factor
  }

  // async loadJustificationFor(){

  // // // const _res = await this.tc.getJustificationById(this.tc.prefix,this.position.position_id, -1, -1)
  // // // let ___goaJustifications:GoaJustificationI[] = _res as GoaJustificationI[]
  // // this._goaJustifications = this.position.justifications
  // // // const _factorItem = this.progression_factors.find(item => item.factor == this.positionFactor)
  // // // if(_factorItem && _factorItem.isPublic){
  // // //   this._goaJustifications = ___goaJustifications.filter(item => item.public_factor == this.positionFactor)
  // // //   //return this._goaJustifications.filter(item => item.public_factor == this.positionFactor)
  // // // } else {
  // // //   this._goaJustifications =  ___goaJustifications.filter(item => item.custom_factor == this.positionFactor)
  // // // }

  // // var _latestMod
  // // for(var i = 0; i < this._goaJustifications.length; i++) {
  // //   const jstf = this._goaJustifications[i]
  // //   if(!_latestMod){
  // //     _latestMod = jstf.lastModified
  // //   }
  // //   if(jstf.lastModified > _latestMod){
  // //     _latestMod = jstf.lastModified
  // //     this.currentIndex = i
  // //   }
  // // }
  // //console.log(this._goaJustifications)
  // }

  get goaJustifications(): GoaJustificationI[] {
    if (!this.position.justifications) {
      return []
    }
    return this.position.justifications
  }

  getjustifcationCount(){
    //  console.log(this.goaJustifications)
  }

  openJustificationOverlay(addNewItem:boolean=false, oldValue:string = '-1'){
    this.onTapped.emit();
  }

  justifications(p:PositionFlatI) : JustificationItemI {
    if(p.justification_items != null  ){
      for(var j = 0; j < p.justification_items.length; j++){
          if(p.justification_items[j].progression_factor == p.progression_factor){
          return p.justification_items[j]
          }
      }
    } else {
      return null;
    }
    return null;
  }

  get getJustificationCopy():string {
    if(this.goaJustifications[this.currentIndex] && this.goaJustifications[this.currentIndex].text){
      return this.goaJustifications[this.currentIndex].text
    }
    return ''
  }

  getSelectedJustificationIndex(p:PositionFlatI) : number {
    var _selectedIndex = 0;
    return _selectedIndex;
  }


  async prevJusitification(p:PositionFlatI){
    this.onTapped.emit();
  }

  async nextJusitification(p:PositionFlatI){
    this.onTapped.emit();
  }

  addItem(){
    this.onTapped.emit();
  }

  removeItem(){
    this.onTapped.emit();
  }

/*   private async finalRemoveItem(id:number){
    await this.tc.deleteGOAJustificationById(id);
    this.currentIndex = 0;
    setTimeout(()=>{
      this.loadJustificationFor()
    },150)
  } */

  showJustificationButton(p:PositionFlatI): boolean {
    return this.goaJustifications && this.goaJustifications.length == 0 && this.itemNeedJustification
  }

  async updateItem(item:GoaJustificationI){
    //await this.tc.updateGoaJustification(item.id, item.text)
  }

  async save(item:GoaJustificationI){
    if(item.id > 0){
        //this.tc.updateGoaJustification(item.id, item.text)
      } else {
        const _result = await this.tc.addGoaJustification(this.position.position_id, item)
        item.id = _result['insertId']
      }
    }
}
