<div class="container-fluid pl0 pr0 ">
  <table class="table pl0 pr0" cellpadding='0' cellspacing='0' style="padding-left: 0px!important; padding-right: 0px!important;" displayMode="flat" multi>
    <thead style="background-color: #fff; border: none; ">
      <tr class='mat-caption tr_head d-flex pb0'>
        <th style="width: 4.9827%;">OP-Bericht</th>
        <th style="width: 20%!important; padding-left:20px">Maßnahme</th>
        <th style="width:  8%!important; text-align: center;">Ziffer</th>
        <th style="width:  67%!important;">Bericht</th>
      </tr>
    </thead>
    <tbody>
      <div *ngFor='let position of positions; let i = index;'>
        <template-op-position-item-tile *ngIf="position.surgery_relevant" [position]="position"></template-op-position-item-tile>
      </div>
    </tbody>
  </table>
</div>
