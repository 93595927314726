<div class="container-fluid p0 h100"  >
  <mat-toolbar class="overlay-toolbar  align-items-center">
    <div class="p-2">{{title}}</div>
    <span class="toolbar-spacer"></span>
    <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
  </mat-toolbar>


  
  <div class="d-flex flex-column " style="height: calc(100% - 88px); overflow: hidden;" *ngIf="olData && !olData.isEditMode">

    <div class="p-2">

      <div class="d-flex">
        <div class="p-2 w50p">
          <mat-label class='mat-caption'>Name</mat-label>  
          <input class="formControl mat-body" [(ngModel)]="poolName" >
        </div>
        <div class="p-2 w25p">
          <mat-label class='mat-caption'>Short</mat-label>  
          <input class="formControl mat-body" [(ngModel)]="shortCut" maxlength="3">
        </div>
        <div class="p-2 w25p">
          <mat-label class='mat-caption'>Farbe auswählen</mat-label>  
          <div class="d-flex">
            <div class="p-2" *ngFor="let color of colors; let i = index">
              <div class="colorCircleOuter" [class.colorCircleOuterActive]="selectedColorIndex == i">
                <div  class="colorCircleInner" [ngStyle]='{"background-color": color}'  (click)="setColor(i)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-0">

      <div class="d-flex">

        <div class="p-0 w35p">

              <div class="container-fluid   " style="border: 1px solid rgba(0, 0, 0, 0.1);">
                 <div class="d-flex p0 h100 align-items-center">
                   <div class="p-2 " style="width:62px">
                    <div class="iconPreview" [ngStyle]='{"background-color": selectedColor}' >{{shortCut}}</div>
                  </div>
                   <div class="p-2">
                     <div class="d-flex p0">
                       <div class="p-0 semi16 ml10" >Meine Vorlage</div>
                       <div class="p-0 mt2 ml5"></div>
                     </div>
                   </div>
            </div>
          </div>
        </div>
        <div class="p-0 w35p  justify-content-center align-items-center" style="height: 50px;" >

          <mat-chip-list class="example-chip" #chipList multiple="true" >
            <mat-chip #chip="matChip" class="filter-chip" style="min-width: 180px;"
            [ngStyle]='{"background-color": chipActive ? selectedColor : "rgba(0,0,0,0)"}'
            [class.filterChipUnactive]="!chipActive"
            [selected]='chipActive'
              (click)='toggleChip()'>
              <mat-icon class="filter-chip-icon" *ngIf="chipActive">done</mat-icon>
              <mat-icon class="filter-chip-icon" *ngIf="!chipActive">add</mat-icon>
              {{filterTitle}}
            </mat-chip>

          </mat-chip-list>

        </div>

        <div class="p-2 w35p" style="border: 1px solid rgba(0, 0, 0, 0.1);">
          <mat-selection-list #shoes (selectionChange)="sectorSelectionChanged($event)" multiple>
            <mat-list-option *ngFor="let sector of clinicSectors" [value]="sector">
              {{sector.title}}
            </mat-list-option>
          </mat-selection-list>
        </div>

      </div>

    </div>

    
    
  </div>



  <div class="d-flex flex-column " style="height: calc(100% - 88px); overflow: hidden;"
    *ngIf="olData && olData.isEditMode">

    <div class="p-2" *ngIf="!selectedPool">
    
      <div class="d-flex">
        <div class="p-2 w50p">
          <mat-nav-list >
            <!-- (selectionChange)="selectionChange($event)" -->
            <mat-list-item *ngFor="let pool of templatePools" (click)="selectPool(pool)">
              <div matListItemTitle >{{pool.name}}</div>
            </mat-list-item>
            
            
          </mat-nav-list>
        </div>
      </div>
    </div>

    <div class="p-2" *ngIf="selectedPool">
    
      <div class="d-flex">
        <div class="p-2 w50p">
          <mat-label class='mat-caption'>Name</mat-label>
          <input class="formControl mat-body" [(ngModel)]="poolName">
        </div>
        <div class="p-2 w25p">
          <mat-label class='mat-caption'>Short</mat-label>
          <input class="formControl mat-body" [(ngModel)]="shortCut" maxlength="3">
        </div>
        <div class="p-2 w25p">
          <mat-label class='mat-caption'>Farbe auswählen</mat-label>
          <div class="d-flex">
            <div class="p-2" *ngFor="let color of colors; let i = index">
              <div class="colorCircleOuter" [class.colorCircleOuterActive]="selectedColorIndex == i">
                <div class="colorCircleInner" [ngStyle]='{"background-color": color}' (click)="setColor(i)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="p-0" *ngIf="selectedPool">
    
      <div class="d-flex">
    
        <div class="p-0 w35p">
    
          <div class="container-fluid   " style="border: 1px solid rgba(0, 0, 0, 0.1);">
            <div class="d-flex p0 h100 align-items-center">
              <div class="p-2 " style="width:62px">
                <div class="iconPreview" [ngStyle]='{"background-color": selectedColor}'>{{shortCut}}</div>
              </div>
              <div class="p-2">
                <div class="d-flex p0">
                  <div class="p-0 semi16 ml10">Meine Vorlage</div>
                  <div class="p-0 mt2 ml5"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-0 w35p  justify-content-center align-items-center" style="height: 50px;">
    
          <mat-chip-list class="example-chip" #chipList multiple="true">
            <mat-chip #chip="matChip" class="filter-chip" style="min-width: 180px;"
              [ngStyle]='{"background-color": chipActive ? selectedColor : "rgba(0,0,0,0)"}'
              [class.filterChipUnactive]="!chipActive" [selected]='chipActive' (click)='toggleChip()'>
              <mat-icon class="filter-chip-icon" *ngIf="chipActive">done</mat-icon>
              <mat-icon class="filter-chip-icon" *ngIf="!chipActive">add</mat-icon>
              {{filterTitle}}
            </mat-chip>
    
          </mat-chip-list>
    
        </div>
    
        <div class="p-2 w35p" style="border: 1px solid rgba(0, 0, 0, 0.1);">
          <mat-selection-list #shoes (selectionChange)="sectorSelectionChanged($event)" multiple>
            <mat-list-option *ngFor="let sector of clinicSectors" [value]="sector" [selected]="isSectorSelected(sector)">
              <!--  -->
              {{sector.title}}
            </mat-list-option>
          </mat-selection-list>
        </div>
    
      </div>
    
    </div>


  </div>

  <mat-toolbar class="overlay-toolbar" >
    <app-df-icon-button  title="Speichern" color="primary" icon="save" [disabled]="clinicSectorsSelected.length == 0" (clicked)="save()"></app-df-icon-button>  
  </mat-toolbar>
</div>