import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountI, AddressI } from 'src/app/core/models/accountI';
import {InvoiceI } from 'src/app/core/models/invoiceI';
import { PatientFull } from 'src/app/core/models/patient';
import { PdfPreviewI } from 'src/app/core/models/pdfPreviewI';
import {FinalSurgeryReportI, SurgeryReportC, SurgeryReportI } from 'src/app/core/models/surgeryReportI';
import { PatientService } from 'src/app/services/patient.service';
import * as _ from "lodash";
import { AccountService } from 'src/app/services/account.service';
import { SurgeryReportPDF } from 'src/app/utils/sugeryReportPDF';
import jsPDF from 'jspdf';
import { differenceInMinutes } from 'date-fns';
import { CgcurrencyPipe } from 'src/app/pipes/cgcurrency.pipe';
import { UiService } from 'src/app/services/ui.service';
import { ToolbarService,  HtmlEditorService, MarkdownEditorService, PasteCleanupService, RichTextEditorComponent} from '@syncfusion/ej2-angular-richtexteditor';
import { toolbar_tools } from 'src/app/utils/texteditor-tools';
import { TemplateApiService } from 'src/app/services/template-api.service';
import { FormControl } from '@angular/forms';
import { InvoicePreviewPositionItemI } from 'src/app/core/models/v4/invoice/invoicePositionI';
import { TemplateFlatI } from 'src/app/core/models/api/TemplateFlatI';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { InvoicePreviewC } from 'src/app/core/models/v4/invoice/invoicePreviewI';
import { SurgeryHeaderI } from 'src/app/core/models/sectionM';
import { SurgeryReportPositionI } from 'src/app/core/models/v4/surgeryPositionI';
import { SurgerySupportPersonI } from 'src/app/core/models/v4/surgery.personi';
import { InvoiceModelI } from 'src/app/core/models/v4/invoice/invoiceModel';
import { InvoiceCreator } from 'src/app/utils/invoiceCreator';
import { DATETOSTRING, DATE_TO_TIME_STRING, DURATION_BETWEEN_DATES_STRING } from 'src/app/utils/helpers';

@Component({
  selector: 'app-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss'],
  providers: [DatePipe, ToolbarService,  HtmlEditorService, PasteCleanupService, RichTextEditorComponent, MarkdownEditorService]
})
export class PdfPreviewComponent implements OnInit {
  @ViewChild('templateRTE') rteEle: RichTextEditorComponent;
  date = new FormControl(new Date());
  finalInvItem:InvoiceModelI
  serializedDate = new FormControl((new Date()).toISOString());
  pdfSrc
  show = false
  isPreview = true;
  saveOnDisk:boolean = false
  showEditorButton = false;
  showRichTextEditor = false;
  isCompletionMode = false;
  patientError = false;
  missingSurgeryDocError = false;
  missingReportCopiesError = false;
  missingJustificationsError = false;
  positions:SurgeryReportPositionI[] = []
  readyBtnTxt = 'Speichern'
  isContentAvailable = false;
  finalCreateDisabled = true;
  isInvoice:boolean = false;
  userpath
  datumsTitle:string =''
  tools: object = toolbar_tools
  headerInfos:SurgeryHeaderI
  pdf:jsPDF
  initialSurgeryReportText: string = ''
  _report:SurgeryReportI
  address:AddressI
  userAccount:AccountI;
  surgerySupportUser:SurgerySupportPersonI[] = []
  isGenerating = false;
  invoiceDate = new Date()
  repC
  k
  fullopTextClipboard = ""

  private invoiceAddress:AddressI
  public mode: string = 'Markdown';
  private _finalInvoice:InvoiceI
  private _finalSurgeryReport:SurgeryReportI
  private _patient:PatientFull;
  private _year:number;

  constructor(
    private currencyPipe:CgcurrencyPipe,
    private datePipe: DatePipe,
    private ui:UiService,
    private tc:TemplateControllerService,
    private templateApi:TemplateApiService,
    private patientS:PatientService,
    public dialogRef: MatDialogRef<PdfPreviewComponent>,
    private accountS:AccountService,
    @Inject(MAT_DIALOG_DATA) public data:PdfPreviewI) { }

  get _template():TemplateFlatI {
    return this.tc.activeTemplate
  }

  ngOnInit(): void {
    console.log('>>>>> PdfPreviewComponent<<<<<< '+ this.data.type);
    console.log(this.data);

    switch (this.data.type) {

      case PdfPreviewI.Type.FINALSURGERYREPORT:
        this.loadSurgeryReport()
      return ;

      case PdfPreviewI.Type.SHOWINVOICE:
        this.isInvoice = true;
        if(this.data.invoice_id){
          console.log('this.data.invoice_id');

          this.loadInvoiceById(this.data.invoice_id)
        } else {
          const invoiceId =this.data.template.invoice_id
          if(invoiceId) {

            console.log('this.data.template.invoice_id');
            this.loadInvoiceById(this.data.template.invoice_id)
          }
        }
        return

      case PdfPreviewI.Type.SURGERYREPORT:
        this.generateSurgeryReport();
        return;

      default:
        this.isInvoice = true;
        this.initInvoicePreview();
        break;
    }

  }

  get showFertigStellen():boolean {
    if(this.isInvoice) { return false}
    if(this.isPreview && !this.showRichTextEditor) { return true}
    return false
  }

  showErrorOverlay():boolean {
    if(this.isCompletionMode){
      if(this.missingSurgeryDocError === true
        || this.patientError === true
        || this.missingReportCopiesError === true){
          this.readyBtnTxt = 'Trotzdem abschliessen'
          return true
        } else {
          this.readyBtnTxt = 'Speichern'
          return false
        }
    }
    return false
  }


  
  async initInvoicePreview() {

    if(this._template.address_id != null && this._template.address_id != -1){
      // const _addressIndex = this.ui.addresses.findIndex(item => item.id == this._template.address_id)
      const r = await this.tc.getFullInvoiceAddress(this._template.template_id) 

      if(r) {
        this.address = r
      }

    } else {
      this.address = this.ui.addresses[0]
    }
    console.log(this.address);
    
    const _invoice_number_next = await this.accountS.getLastInvoiceNumber()
    const _procssInvoices = await this.accountS.invoicesByProcessId(this._template.template_id);

    let _invoiceNumber;
    if (this.ui.isClinicUser) {
      if ( this._template.clinicProcessNumber != null){
        _invoiceNumber = this._template.clinicProcessNumber
      }
    } else {
      if(_procssInvoices.length > 0){
        _invoiceNumber = _procssInvoices[0].invoice_number;
      } else {
        _invoiceNumber =  _invoice_number_next + 1
      }
    }

    let _invoiceCreator = new InvoiceCreator(this.tc, this.ui, this.tc.activeTemplate,  this.address, this.datePipe, this.currencyPipe, this.invoiceDate)
    const _invoiceItem = await _invoiceCreator.createInvoiceItem(_invoiceNumber, this.ui.isClinicUser)

    this.pdf = _invoiceCreator.getInvoicePDF(_invoiceItem, this.ui.isClinicUser)

    this.pdfSrc = this.pdf.output('datauristring');
    this.show = true;
  }

  async loadInvoiceById(invoice_id:number){
    const _res = await this.accountS.getInvoiceById(invoice_id)

    const _res2 = await this.accountS.getInvoiceItemsById(invoice_id)
    var _i: InvoiceModelI = _res;
    _i.invoiceItems = _res2 as InvoicePreviewPositionItemI[]
    for(var i = 0; i < _i.invoiceItems.length; i ++){
      var _amount:number =  _i.invoiceItems[i].amount_euro;
      var _count:number =  _i.invoiceItems[i].quantity;
      var _sum = _count * _amount
      _i.invoiceItems[i].summary = _sum.toString() + ' €'
      // console.log(`quantity : ${_count} + amount_euro: ${_amount} SUM:  ${_sum}`)
    }

    var _diagnose = this.tc.getLatesDiagnose()
    var _tMethod = this.tc.getLatesTherapie()
    let _ip:InvoicePreviewC = new InvoicePreviewC(null, this._template, this.datePipe, null, null, null, this.currencyPipe, _diagnose, _tMethod);
    this.pdf = _ip.getInvoicePDFFinal(_i);

    this.pdfSrc = this.pdf.output('datauristring');
    this.show = true;
  }

  async createFinalReport(){
    this.isCompletionMode = true;

    console.log('createFinalReport');

    switch(this.data.type){
      case PdfPreviewI.Type.INVOICE:
        if(this.finalInvItem){
         const _res =  await this.accountS.createInvoice(this.finalInvItem)
         this.tc.activeTemplate.invoice_id = _res;
        }

        break;

      case PdfPreviewI.Type.SURGERYREPORT:
        if ( !this.ui.isClinicUser) {
          if(this._patient == null){
            this.patientError = true;
          }

          if(this.surgerySupportUser == null || this.surgerySupportUser.length == 0){
            this.missingSurgeryDocError = true;
          }
        }

        if(this.missingSurgeryDocError || this.patientError){
            this.readyBtnTxt = 'Trotzdem abschliessen'
          } else {
            this.saveSurgeryReport()
          }
          this.isContentAvailable = true;
          this.finalCreateDisabled = false;
        break;

    }

    if(this.isContentAvailable === true){
      switch(this.data.type){
        case PdfPreviewI.Type.INVOICE:
          this.isInvoice = true;

          return;
        case PdfPreviewI.Type.SURGERYREPORT:
        this.isInvoice = false;
        this.datumsTitle = 'OP Bericht Datum'
         this.finalCreateDisabled = false;
         return
      }
    }


  }

  hasReportPreview():boolean {
    return this._report != null
  }

  get showPreviewResetButton():boolean {
    if(this._report != null && this._report.editorReport != this.initialSurgeryReportText && this._report.report_mode == 'PREVIEW'){
      return true
    }
    return false;
  }

  private async loadSurgeryReport(){
    console.log("loadSurgeryReport");
    
    const _r = await this.tc.getSurgeryReportById(this._template.template_id)
    console.log(_r);

    const _result = await this.tc.getAdditionalsureryInfos(this.tc.currentTemplateId);
    this.headerInfos = _result as SurgeryHeaderI

    var patBirtg = ''
   
    if ( !this.ui.isClinicUser ) {
      if(this.tc.activeProcess.patient_id){
        const patRes = await this.patientS.getSingle(this.tc.activeProcess.patient_id);
        this._patient = patRes as PatientFull
        patBirtg = ` Geb.Datum ${this.datePipe.transform(this._patient.birthDate)}`
        this.fullopTextClipboard = `Patient:\t\t\t${this._patient.firstName} ${this._patient.lastName},${patBirtg}\n`
      }
    }

    this.initClipboardHeader()

    const _itemsSR = await this.reloadSupport();

    this.surgerySupportUser = _itemsSR as  SurgerySupportPersonI[]
    this._report = _r as SurgeryReportI
    this._report.editorReport = this._report.editorCopy != '' ?  this._report.editorCopy : ""


    var _diagnose = this._report.diagnose
    let _diagnoseClipboard = _diagnose.replace(/\n/g, "\n\t\t\t")//.trim()
    if(_diagnoseClipboard.length) {
      this.fullopTextClipboard += `Diagnose(n):\t\t${_diagnoseClipboard}\n`
      this.fullopTextClipboard += `\n`
    }
    var _tMethod = this._report.therapiemethode
    var _tMethodClipboard = _tMethod//.trim()
    _tMethodClipboard = _tMethodClipboard.replace(/\n/g, "\n\t\t\t")//.trim()
    if(_tMethodClipboard.length) {

      this.fullopTextClipboard += `Therapie(n):\t\t${_tMethodClipboard}\n`
      
      this.fullopTextClipboard += `\n`
    }
    this.fullopTextClipboard += `\n`


    this.isPreview = false
    this.pdf = SurgeryReportPDF.getSurgeryReportPDF(this._report, this.headerInfos, this.surgerySupportUser, this._report.isClinic);
    this.pdfSrc = this.pdf.output('datauristring');
    this._finalSurgeryReport = this._report;
    this.showEditorButton = false;
    this.show = true;

    this.saveOnDisk = true;
  }


  // -------------------------------------------------------------------------------------------------------------
  private async initClipboardHeader() {
    this.fullopTextClipboard += `OP-Datum:\t\t${DATETOSTRING(new Date(this.headerInfos.surgeryDate))} \n`
    this.fullopTextClipboard += `Uhrzeit Beginn:\t\t${DATE_TO_TIME_STRING(new Date(this.headerInfos.timeStart))} \n`
    this.fullopTextClipboard += `Uhrzeit Ende:\t\t${DATE_TO_TIME_STRING(new Date(this.headerInfos.timeEnd))} \n`
    this.fullopTextClipboard += `Dauer:\t\t\t${DURATION_BETWEEN_DATES_STRING(new Date(this.headerInfos.timeStart), new Date(this.headerInfos.timeEnd))} \n`
    this.fullopTextClipboard += `\n`

    if(!this.ui.isClinicUser) {
      const _itemsSR = await this.reloadSupport();
      this.surgerySupportUser = _itemsSR as SurgerySupportPersonI[];

      this.surgerySupportUser.forEach(_user => {
        let docString =  _user.title.length > 0 ? `${_user.title} ${_user.firstName} ${_user.lastName}` : `${_user.firstName} ${_user.lastName}`
        
        if(_user.role_title == "Instrumentierung"  || _user.role_title == "Weitere Assistenz") {
          this.fullopTextClipboard += `${_user.role_title}:\t${docString} \n`
        } else {
          this.fullopTextClipboard += `${_user.role_title}:\t\t${docString} \n`
        }
        
      })
    }
    
    this.fullopTextClipboard += `\n`
    if(this.headerInfos.lagerung.length) {
      this.fullopTextClipboard += `Lagerung:\t\t${this.headerInfos.lagerung} \n`
    }
    if(this.headerInfos.antibiose.length) {
      this.fullopTextClipboard += `Periop. Antibiose:\t${this.headerInfos.antibiose} \n`
    }
    if(this.headerInfos.blutsperre.length) {
      this.fullopTextClipboard += `Blutsperre:\t\t${this.headerInfos.blutsperre} \n\n`
    }
    if(this.headerInfos.lokalisation.length) {
      this.fullopTextClipboard += `Lokalisation:\t\t${this.headerInfos.lokalisation} \n`
    }

    this.fullopTextClipboard += `\n`
  }
  // -------------------------------------------------------------------------------------------------------------

  async generateSurgeryReport(){
    this.isPreview = true
    this.userAccount = this.ui.account;

    const _result = await this.tc.getAdditionalsureryInfos(this.tc.currentTemplateId);

    this.headerInfos = _result as SurgeryHeaderI
    var patBirtg = ''
   
    if ( !this.ui.isClinicUser ) {
      if(this.tc.activeProcess.patient_id){
        const patRes = await this.patientS.getSingle(this.tc.activeProcess.patient_id);
        this._patient = patRes as PatientFull
        patBirtg = ` Geb.Datum ${this.datePipe.transform(this._patient.birthDate)}`
        this.fullopTextClipboard = `Patient:\t\t\t${this._patient.firstName} ${this._patient.lastName},${patBirtg}\n`
      }
    }
    this.initClipboardHeader()
    
    if(this._template.address_id != null && this._template.address_id != -1){
      // const _addressIndex = this.ui.addresses.findIndex(item => item.id == this._template.address_id)
      // this.address = _addressIndex != -1 ? this.ui.addresses[_addressIndex] :  this.ui.addresses[0]
      const r = await this.tc.getFullInvoiceAddress(this._template.template_id) 
    
      if(r) {
        this.address = r
      }

    } else {
      this.address = this.ui.addresses[0]
    }

    const _res = await this.tc.getSurgeryReportPositions('processes',this.tc.currentTemplateId)
    this.positions = _res as SurgeryReportPositionI[]

    this.positions = _.orderBy(this.positions,[
      _position => _position.section_order_index,
      _position => _position.position_order_index,
    ],['asc','asc'])

    var _txt  = ''
    this.positions.forEach(_pos => {
      _txt += _pos.text
      _txt += ' '
    })
    this.initialSurgeryReportText = _txt;
    this.repC = new SurgeryReportC(this.datePipe, this.ui, this.tc);

    const data = await this.tc.getSurgeryReportPreview(this.tc.currentTemplateId)

    if(data != null){
      this._report = data as SurgeryReportI
      this._report.editorReport  = this._report.editorCopy != '' ?  this._report.editorCopy : this.initialSurgeryReportText;
      this._report.isClinic = this.ui.isClinicUser
    } else {
      this._report.editorReport = this.initialSurgeryReportText//this.repC.editorCopy({sections:this._template.sections})
      this._report.isClinic = this.ui.isClinicUser
    }
    var _diagnose = this.tc.getLatesDiagnose()
    let _diagnoseClipboard = _diagnose.replace(/\n/g, "\n\t\t\t")//.trim()
    if(_diagnoseClipboard.length) {
      this.fullopTextClipboard += `Diagnose(n):\t\t${_diagnoseClipboard}\n`
      // this.fullopTextClipboard += `\n`
    }
    var _tMethod = this.tc.getLatesTherapie()
    var _tMethodClipboard = _tMethod//.trim()
    _tMethodClipboard = _tMethodClipboard.replace(/\n/g, "\n\t\t\t")//.trim()
    if(_tMethodClipboard.length) {

      this.fullopTextClipboard += `Therapie(n):\t\t${_tMethodClipboard}\n`
      
      this.fullopTextClipboard += `\n\n`
    }
    // this.fullopTextClipboard += `\n`
    var _patName = ""
    if (this.ui.isClinicUser) {
      if (this._template.clinicProcessNumber) {
        _patName = this._template.clinicProcessNumber.toString()
      }
    } else {
      _patName = this._patient.lastName +  ' ' + this._patient.firstName + ''+  patBirtg
    }

    if(this.address != null){
      
      let _behandelnder_arzt = this.address.behandelnder_arzt //this.ui.account.title + ' ' + this.ui.account.firstName + ' ' + this.ui.account.lastName
      let _facharzt_bezeichnung =  this.address.facharzt_bezeichnung//this.ui.account.medical_specialty_designation

      this._report.abteilung = this.address.abteilung
      this._report.docName = this.ui.account.title + " " + this.ui.account.docName
      this._report.facharzt_bezeichnung = _facharzt_bezeichnung//this.ui.account.doc_description

      this._report.patient_name = _patName
      this._report.klinikName = this.address.klinikName
      this._report.behandelnder_arzt = _behandelnder_arzt//this.address.behandelnder_arzt
      this._report.arzt_role = this.address.arzt_role
      this._report.address_1 = this.address.streetName + ' ' + this.address.streetNumber
      this._report.address_2 = this.address.zip + ' ' + this.address.city
      this._report.diagnose = _diagnose
      this._report.therapiemethode = _tMethod

      let legalMain = this.ui.isClinicUser && this.address.clinicLegalMain ? this.address.clinicLegalMain : ""
      let clinicInvoiceProviderFirst = this.ui.isClinicUser && this.address.clinicInvoiceProviderFirst ? this.address.clinicInvoiceProviderFirst : ""
      let clinicInvoiceProviderSecond = this.ui.isClinicUser && this.address.clinicInvoiceProviderSecond ? this.address.clinicInvoiceProviderSecond : ""
      let clinicInvoiceProviderThird = this.ui.isClinicUser && this.address.clinicInvoiceProviderThird ? this.address.clinicInvoiceProviderThird : ""
      let clinicInvoiceProviderFourth = this.ui.isClinicUser && this.address.clinicInvoiceProviderFourth ? this.address.clinicInvoiceProviderFourth : ""

      this._report.legalMain = legalMain
      this._report.invoiceProviderFirst = clinicInvoiceProviderFirst
      this._report.invoiceProviderSecond = clinicInvoiceProviderSecond
      this._report.invoiceProviderThird = clinicInvoiceProviderThird
      this._report.invoiceProviderFourth = clinicInvoiceProviderFourth

    }

    this.fullopTextClipboard += `OP-Durchführung:\n`.toUpperCase()
    // this.fullopTextClipboard += `${this._report.editorReport}`
      
    this.pdf = SurgeryReportPDF.getSurgeryReportPDF(this._report, this.headerInfos, this.surgerySupportUser, this.ui.isClinicUser);
    //  console.log('this._report', this._report);

     this.pdfSrc = this.pdf.output('datauristring');//doc.output("blob");
     this.showEditorButton = true;
     this.show = true;
  }

  get editorReportClipBoardText(): string {
    var _outPut = this._report.editorReport//
    if (this.ui.isClinicUser) {
      if (this._template.clinicProcessNumber) {
        _outPut += `\n${this._template.clinicProcessNumber.toString()} `
      }
    }
    return _outPut
  }

  get editorReportClipBoardTextFull(): string {
    var _outPut = this.fullopTextClipboard
    _outPut += this.editorReportClipBoardText
    return _outPut
  }

  async reloadSupport(){
    const _support = await this.tc.getSurgerySupport(this.tc.currentTemplateId);
    return _support;
  }

  async resetEditorText(){

    var _diagnose = this.tc.getLatesDiagnose()
    var _tMethod = this.tc.getLatesTherapie()
    this._report.editorReport = this.initialSurgeryReportText
    this._report.lastModified = new Date().getTime();
    if(this.address != null){

      this._report.abteilung = this.address.abteilung
      this._report.docName = this.ui.account.docName
      this._report.facharzt_bezeichnung = this.ui.account.doc_description
      this._report.klinikName = this.address.klinikName
      this._report.behandelnder_arzt = this.address.behandelnder_arzt
      
      this._report.arzt_role = this.address.arzt_role
      this._report.address_1 = this.address.streetName
      this._report.address_2 = this.address.zip + ' ' + this.address.city
      this._report.diagnose = _diagnose
      this._report.therapiemethode = _tMethod
      
    }
    if(this.ui.isClinicUser) {
      
    } else {
      
      this._report.patient_name = this._patient.lastName +  ' ' + this._patient.firstName
    }
    // this.reportPreview.lastModified = new Date().getTime();
    this.pdf = SurgeryReportPDF.getSurgeryReportPDF(this._report,this.headerInfos, this.surgerySupportUser);
    this.pdfSrc = this.pdf.output('datauristring');
    this.savePreviewCopy();
  }



  openRichTextEditor(){
    this.showRichTextEditor = true;
  }

  savePreviewCopy(){
    this.addSurgeryReportPreview();
  }

  private async addSurgeryReportPreview(){
    const _r = await this.tc.updateSurgeryReportPreview(this.tc.currentTemplateId, this._report)

    this.cancelPreviewCopy();
   }

   cancelPreviewCopy(){
    var reg1 = RegExp('<p>','g')
    var reg2 = RegExp('</p>','g')
    var reg3 = RegExp('&nbsp;','g')
    var reg4 = RegExp('<br>','g')


    let mess = this._report.editorReport
    var cleanRepString = mess.replace(reg1, '')
    cleanRepString = cleanRepString.replace(reg2,'\n')
    cleanRepString = cleanRepString.replace(reg3,' ')
    cleanRepString = cleanRepString.replace(reg4,'\n')
    this._report.editorReport = cleanRepString;
    this.pdf= SurgeryReportPDF.getSurgeryReportPDF(this._report, this.headerInfos, this.surgerySupportUser);
    this.pdfSrc = this.pdf.output('datauristring');//doc.output("blob");
    this.show = true;
    this.showRichTextEditor = false;
  }

  calculateOPDuration(dateStart:Date, dateEnd:Date) :string{
    let surgeryDuration:string = ''
    let minutes:number = differenceInMinutes(dateEnd, dateStart)
    let _hoursOut = minutes/60;
    let _rHoursOut = Math.floor(_hoursOut);
    var _minutesOut = (_hoursOut - _rHoursOut) * 60;
    var _rMinutesOut = Math.round(_minutesOut);
    surgeryDuration = _rHoursOut.toString() + 'h '+ _rMinutesOut.toString() +'min'
    return surgeryDuration;
  }


  downloadPDF(){

    if(this.data.type === PdfPreviewI.Type.FINALINVOICE || this.data.type === PdfPreviewI.Type.SHOWINVOICE){
      if(this._finalInvoice != null){
        let _dateString:string = this._finalInvoice.invoiceDate.toISOString();
        let documentTitle:string = 'Rechnung.'+this._finalInvoice.templateName+'.'+_dateString +'.pdf';
        this.pdf.save(documentTitle)
      }

    } else if(this.data.type === PdfPreviewI.Type.FINALSURGERYREPORT){

      if(this._finalSurgeryReport != null){
        let _dateString:string = this.datePipe.transform(new Date(this._template.date), 'dd.MM.yyyy')
        // let _dateString:string = this._finalInvoice.invoiceDate.toISOString();
        let documentTitle:string = 'OP-Bericht.'+this._template.name+'.'+_dateString+'.pdf';
        this.pdf.save(documentTitle)
      } else {
        // console.log('_finalSurgeryReport')
      }

    } else {
      // console.log('_finalSurgeryReport ELSE')
    }
  }

  cancelFinalCreate(){
    this.isCompletionMode = false;
  }

  print(){
    console.log(this.data.type);
    // return
    let name = 'Rechnung'
    if (this.data.type == PdfPreviewI.Type.INVOICE) {
      name = 'Rechnung'
    } else if (this.data.type == PdfPreviewI.Type.SURGERYREPORT) {
      name = 'OP-Bericht'
    }
    let _dateString:string = this.datePipe.transform(new Date(this._template.date), 'dd.MM.yyyy')
    let documentTitle:string =  name+'.'+this._template.name+'.'+_dateString +'.pdf';
     this.pdf.save(documentTitle);
  }

  close(){
    this.dialogRef.close();
  }

  saveFinalElement(){
    this.saveSurgeryReport()
  }


  private async saveSurgeryReport(){
    var reg1 = RegExp('<p>','g')
    var reg2 = RegExp('</p>','g')
    var reg3 = RegExp('&nbsp;','g')
    var reg4 = RegExp('<br>','g')

    let mess = this._report.editorReport
    var cleanRepString = mess.replace(reg1, '')
    cleanRepString = cleanRepString.replace(reg2,'\n')
    cleanRepString = cleanRepString.replace(reg3,' ')
    cleanRepString = cleanRepString.replace(reg4,'\n')
    this._report.editorReport = cleanRepString;
    this.pdf= SurgeryReportPDF.getSurgeryReportPDF(this._report, this.headerInfos, this.surgerySupportUser, this.ui.isClinicUser);
    this.pdfSrc = this.pdf.output('datauristring');//doc.output("blob");
    const _r = await this.tc.finalizeSurgeryReportPreview(this.tc.currentTemplateId,this._report)

    this.tc.activeProcess.surgery_report_id = this.tc.activeProcess.template_id
    let _outPut:FinalSurgeryReportI = {
      documentTitle: 'OP-Bericht.'+this._template.name,
      pdf:this.pdf
    }
    _outPut.pdf.save(_outPut.documentTitle +'.pdf');

    this.dialogRef.close()
  }

  private async deletePreviewReport(){
    const data = await this.templateApi.deleteSurgeryReportPreview(this._template.documentId)
    console.log("delete - report preview ", data)
  }


}
