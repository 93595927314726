<div class="container-fluid  administration-wrapper   ">
 
  <div class="container-fluid p_t20">
    <div class="d-flex">
      <div class="p-2">
        <h1 class="display-1">Verwaltung</h1>
      </div>
      <div class="p-2 ml-auto">
        <mat-chip-list class="example-chip" #chipList multiple="true" *ngIf='!isLoading'>
          <mat-chip #chip="matChip" class="filter-chip" *ngFor="let filter of navChipList"
          [class.filterChipActiveBlue]="filter.is_active" 
          [class.filterChipUnactive]="!filter.is_active" 
          [selected]='filter.is_active'

            (click)='toggleFilter(filter)'>

            <mat-icon class="filter-chip-icon" *ngIf="filter.is_active">done</mat-icon>
            <mat-icon class="filter-chip-icon" *ngIf="!filter.is_active">add</mat-icon>

            {{filter.chipTitle}}
          </mat-chip>
       
        </mat-chip-list>
      </div>
    </div>

  </div>
  <div class="container-fluid pl0 pr0 p_t20" >
    <div class="d-flex " style="flex-wrap: wrap;">
      
      <div *ngFor="let item of activeChips">
        <app-settings-content-panel panelType="{{item.panelType}}" [visiblePanelCount]="activeChips.length"></app-settings-content-panel>
      </div>
      
    </div>
    
  </div>
</div>
  
  