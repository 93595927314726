<mat-toolbar style="height: 45px; border-radius: 6px; background-color: #ffffff!important;">
  <mat-label style="font-weight:500!important; font-size: 16px!important;">
    {{title}}</mat-label>
  <span class="toolbar-spacer"></span>
    <mat-icon style="color:#ABBBC7; cursor: pointer;   -webkit-user-select:none;" (click)='close()'>close</mat-icon>
</mat-toolbar>

<div class="container">

  <div class="row justify-content-between p_t40">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-label class='mat-caption'>Name</mat-label>
      <input class="formControl mat-body" [(ngModel)]="newAddress.klinikName">
    </div>


  </div>

  <div class="row p_t20">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-label class='mat-caption'>Straße und Hausnummer</mat-label>
      <input class="formControl mat-body" [(ngModel)]="newAddress.streetName">
    </div>
   <!--  <div class="col-lg-3 col-md-3 col-sm-12">
      <mat-label class='mat-caption'>Nr.</mat-label>
      <input class="formControl mat-body" [(ngModel)]="newAddress.streetNumber">
    </div> -->
  </div>

  <div class="row p_t20 p_b20">
    <div class="col-lg-3 col-md-3 col-sm-12">
      <mat-label class='mat-caption'>Plz</mat-label>
        <input class="formControl mat-body" [(ngModel)]="newAddress.zip">
    </div>

    <div class="col-lg-9 col-md-9 col-sm-12">
      <mat-label class='mat-caption'>Stadt</mat-label>
        <input class="formControl mat-body" [(ngModel)]="newAddress.city">
    </div>


  </div>

</div>

<mat-toolbar style="height: 64px; width: 100%; border-radius: 6px;" class="tlc justify-content-center">
  <div style="width:92px; text-align:center;" class="tlc justify-content-center" *ngIf='!isSaving'>
  <button mat-flat-button color='primary' (click)='save()' >Speichern</button>
  </div>
  <div style="width:50px; text-align:center;" class="tlc justify-content-center" *ngIf='isSaving'>
    <mat-spinner diameter='25' color='primary'></mat-spinner>
  </div>
</mat-toolbar>
