
<mat-card class="mat-card-no-shadow pr0 pt0 pb0 pl0" style="height:100%!important;">
  <div class="container-fluid p_b5 pt0 pr5" *ngIf="!ui.isClinicUser">

    <div class="d-flex h40">
      <div class="p-2 pb0">
        <span class="mat-caption m0">Patient/in</span>
      </div>
      <div class="p-2 ml-auto tle" *ngIf='editable && patientFlat'>
        <mat-icon (click)='editPatient()' matTooltip='Patient bearbeiten'
        style="font-size: 18px!important; color:rgba(0,0,0,0.5)!important; cursor: pointer;">edit</mat-icon>
      </div>
    </div>


  <div class="row " >
    <div class="col-12 pt0  p_t0">

      <div class="d-flex p-2  p_b0  p_t0" *ngIf='patientFlat'>
        <span class="patient-title p_b0 p_t0">{{patientFlat.firstName}} {{patientFlat.lastName}}</span>
        <span class="ml20 patient-copy">Geb.Datum: {{patientFlat.birthDate | date:"dd.MM.yyyy"}}</span>
      </div>

      <div class="d-flex justify-content-center align-items-center p_b0  p_t0" *ngIf='!patientFlat'>

        <div class="p-2 " style="padding-top: 0px!important;">
          <app-df-icon-button
          title="Patient auswählen"
          icon="person"
          color="primary"
          (clicked)="addPatient()"

          >
          </app-df-icon-button>
        </div>

      </div>

    </div>

  </div>

  </div>
</mat-card>
