import { Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { MaterialI } from 'src/app/core/models/materialI';
import { UiService } from 'src/app/services/ui.service';
import { MaterialDetailsComponent } from 'src/app/ui/overlays/material-details/material-details.component';

@Component({
  selector: 'app-settings-material',
  templateUrl: './settings-material.component.html',
  styleUrls: ['../settings-panel.scss']
})
export class SettingsMaterialComponent implements OnInit , OnDestroy {

  @ViewChild(MatSort, {static:true}) sort: MatSort;
  displayedColumns = ['bezeichnung','preis'];
  dataSource:any
  changeHeighSubscription:Subscription
  _currentHeight:number = 0
  materials:MaterialI[] = [];
  newItem:MaterialI = {
    bezeichnung:'',
    preis:'0.00'
  }
  constructor(
    private dialog:MatDialog,
    private ui:UiService
  ) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.loadData()
      this.changeHeighSubscription = this.ui.settingsPanelHeightChanged.subscribe(
        _newHeight =>{
          this._currentHeight = _newHeight
        }
      )
    },120)
  }
  async loadData(){
    const __dataMat = await this.ui.getAllFromApi()
    this.materials = __dataMat as MaterialI[]
    this.dataSource = new MatTableDataSource(this.materials);
    this.dataSource.sort = this.sort;

        
  }
  addMaterial(){
    this.dialog.open(MaterialDetailsComponent, {
      
      width: '580px',
      height: '240px',
      data:this.newItem,
      backdropClass:"backdrop-background"
      
    }).afterClosed().subscribe(
      _data => {
        this.newItem.bezeichnung=''
          
        this.newItem.preis='0.00'
        this.loadData()
      }
    );  
  }
  openDetails(row){
    this.dialog.open(MaterialDetailsComponent, {
      
      width: '580px',
      height: '240px',
      backdropClass:"backdrop-background",
      data:row
      
    }).afterClosed().subscribe(
      _data => {
        
        this.loadData()
      }
    );  
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  get getPanelHeight():string{
    return this._currentHeight + 'px'
  }
  ngOnDestroy(): void {
    if(this.changeHeighSubscription != null){
      this.changeHeighSubscription.unsubscribe()
    }
  }
}
