import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { PATIENT_SELECTION_VIEW_MODE } from 'src/app/core/models/enums';
import { HealthInsuranceM } from 'src/app/core/models/healthinsurance';
import { PatientFull } from 'src/app/core/models/patient';
import { PatientFlatI } from 'src/app/core/models/v3/patientFlat';
import { PatientDetailOverlayInputI } from 'src/app/core/models/viewI/patientSelectionInputI';
import { PatientService } from 'src/app/services/patient.service';
import { UiService } from 'src/app/services/ui.service';
import { PatientDetailsOverlayComponent } from 'src/app/ui/overlays/patient-details-overlay/patient-details-overlay.component';

@Component({
  selector: 'app-settings-patients',
  templateUrl: './settings-patients.component.html',
  // styleUrls: ['./settings-patients.component.scss']
  styleUrls: ['../settings-panel.scss']
})
export class SettingsPatientsComponent implements OnInit, OnDestroy {
  
  
  @ViewChild(MatSort, {static:true}) sort: MatSort;
  healthEnsurances:HealthInsuranceM[] = []
  patients:PatientFlatI[] = [];
  displayedColumns = ['lastName','firstName','Geburtsdatum','city'];
  dataSource:any
  changeHeighSubscription:Subscription
  _currentHeight:number = 0

  constructor(
    private dialog:MatDialog,
    private ui:UiService,
    private patientService:PatientService
  ) { }


  ngOnInit(): void {
    setTimeout(()=>{
      this.loadPatienten()
      this.changeHeighSubscription = this.ui.settingsPanelHeightChanged.subscribe(
        _newHeight =>{
          this._currentHeight = _newHeight
        }
      )
    },120)
  }

  async loadPatienten(){
    const _patientsAllFlat = await this.patientService.allFlat(this.ui.account.uid);
    this.patients = []
    this.patients = _patientsAllFlat['result'] as PatientFlatI[]
    this.dataSource = new MatTableDataSource(this.patients);
    this.dataSource.sort = this.sort;

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  select(row:PatientFlatI){
    // console.log(row);
    this.openPatientDetailOverlay(PATIENT_SELECTION_VIEW_MODE.MODIFY_MODE, row)
  }
  addPatient(){
    this.openPatientDetailOverlay(PATIENT_SELECTION_VIEW_MODE.CREATE_NEW_MODE)
  }
  openPatientDetailOverlay(mode: PATIENT_SELECTION_VIEW_MODE,pat?:PatientFlatI){
    const _data: PatientDetailOverlayInputI = {
      startMode: mode,
      openWithPatientId: pat != null && pat.patient_id != null ? pat.patient_id : null
    }
    
    
    const dialogRef = this.dialog.open(PatientDetailsOverlayComponent, {
      
      width:"70%",
      maxWidth:"1200px",
      height:"560px",
      backdropClass:"backdrop-background",
      data:_data
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != null && result == 'reload'){
        this.loadPatienten()
      }
      
    }); 
  }
  get getPanelHeight():string{
    return this._currentHeight + 'px'
  }
  ngOnDestroy(): void {
    if(this.changeHeighSubscription != null){
      this.changeHeighSubscription.unsubscribe()
    }
  }
}


