<div class="container-fluid" style="padding-top: 10px!important;padding-left: 30px!important; padding-right: 30px!important;">
	<div class="row justify-content-between ">
		<div class="col-8">
		<mat-form-field class="textfield100" appearance="outline">
			<input matInput placeholder="Rechnungen durchsuchen..." (keyup)="applyFilter($event.target.value)" >
			<mat-icon matSuffix>search</mat-icon>
		</mat-form-field>
		</div>
		<div class="col-auto">
		<!-- <button mat-mini-fab color='accent' class="mat-elevation-z0" (click)='addPatient()'><mat-icon>add</mat-icon></button> -->
		</div>
	</div>
	</div>
	
	<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0" style="width: 100%; ">

	<ng-container matColumnDef="invoiceDate">
		<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 24px!important; width: 160px;">Rechnungsdatum</th>
		<td mat-cell *matCellDef="let element" style="padding-left: 24px!important; width: 160px;">{{element.invoiceDate  | date:'dd.MM.yyyy'}}</td>
	</ng-container>

	<ng-container matColumnDef="invoiceNumber">
		<th mat-header-cell *matHeaderCellDef style="width: 130px;">Nummer</th>
		<td mat-cell *matCellDef="let element" style="width: 130px;"> {{element.invoiceYear}}{{element.invoiceNumber}}</td>
  </ng-container>
  
	<ng-container matColumnDef="templateName">
		<th mat-header-cell *matHeaderCellDef >Name</th>
		<td mat-cell *matCellDef="let element"> {{element.templateName}}</td>
  </ng-container>
  
	<ng-container matColumnDef="patient" stickyEnd>
		<th mat-header-cell *matHeaderCellDef>Patient</th>
		<td mat-cell *matCellDef="let element">{{element.patientFirstName}} {{element.patientLastName}}</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row class="element-row" *matRowDef="let row; columns: displayedColumns;" (click)='openInvoice(row)'></tr>
	</table>