<mat-toolbar style="height: 52px;">
{{title}}
<span class="toolbar-spacer"></span>
  <mat-icon  class='justification_arrow_icon' style="font-size: 20px!important;" (click)="close()">close</mat-icon>
</mat-toolbar>

<div class="d-flex flex-column">

  <div class="p-2 flex-fill">
    <!-- <div class="mat-caption"  [class.dateError]="dayControl.invalid">Tag</div> -->
  </div>
  <div class="p-2 flex-fill">
    <input class="formControl mat-body formControlDescription fs14-400" 
    (keyup)="textInput($event)"  [value]='textEntry'>
  </div>

  <!-- <div class="p-2">
    <button mat-flat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="primary" [disabled]="!saveEnabled" (click)="save()">Speichern</button>
    <mat-icon (click)='save()' 
    [class.justification_arrow_icon_green_disabled]="!saveEnabled"
    [class.justification_arrow_icon_green]="saveEnabled"
     style="font-size: 20px!important;">save</mat-icon>
  </div> -->
</div>

<mat-toolbar style="height: 52px;">
  
  <span class="toolbar-spacer"></span>
  <mat-icon (click)='save()' 
  [class.justification_arrow_icon_green_disabled]="!saveEnabled"
  [class.justification_arrow_icon_green]="saveEnabled"
   style="font-size: 20px!important;">save</mat-icon>

</mat-toolbar>