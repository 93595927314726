<mat-toolbar style="height: 52px;">
  <span *ngIf="dateTime">
    {{dateTime | date: 'dd. MMMM  yyyy'}}
  </span>
</mat-toolbar>

<div class="d-flex flex-column">
<div class="p-0">
  <div class="d-flex justify-content-center">
    <div class="p-2">
      <div class="d-flex flex-column">
        <div class="p-0">
          <div class="mat-caption"  [class.dateError]="hourControl.invalid">HH</div>
        </div>
        <div class="p-0" style="width: 50px;">
          <input class="formControl mat-body formControlDescription fs14-400" placeholder="T" #_hour [class.dateError]="hourControl.invalid"
          (keyup)="onHourInput($event)"  (focus)="onHourFocus()" [formControl]="hourControl" (change)="hourChanged($event)"
         [value]='hour' maxlength="2">
        </div>
      </div>
      
    </div>
    <div class="p-2" style="padding-top: 26px!important;">
      :
    </div>
    <div class="p-2">
      <div class="d-flex flex-column">
        <div class="p-0">
          <div class="mat-caption" [class.dateError]="minutesControl.invalid">mm</div>
        </div>
        <div class="p-0" style="width: 60px;">
          <input class="formControl mat-body formControlDescription fs14-400" placeholder="M" #_minute (change)="minutesChanged($event)"
          (keyup)="onMinuteInput($event)" (focus)="onMinuteFocus()" [formControl]="minutesControl" [class.dateError]="minutesControl.invalid"
         [value]='minute' maxlength="2" >
        </div>
      </div>
      
    </div>
<!--     <div class="p-2">
      <div class="d-flex flex-column">
        <div class="p-0">
          <div class="mat-caption">Jahr</div>
        </div>
        <div class="p-0" style="width: 75px;">
          <input class="formControl mat-body formControlDescription fs14-400" placeholder="Jahr" #_year
          (focus)="onYearFocus()" (keyup)="onYearInput($event)" (change)="yearChanged($event)" [formControl]="yearControl" [class.dateError]="yearControl.invalid"
         [value]='year' maxlength="4">
        </div>
      </div>
      
    </div> -->
  </div>
</div>
<div class="p-2">
  <div class="d-flex">
    <div class="p-0 mr2">
      <app-df-icon-button
      title= "Abbrechen"
      icon="cancel"
      (clicked)="close()"      
      >
      </app-df-icon-button>  

    </div>
    <div class="p-0 ml2">
      <app-df-icon-button
      title= "Speichern"
      icon="save"
      color="primary"
      [disabled]="isSaveDisabled"
      (clicked)="save()"      
      >
      </app-df-icon-button>  
    </div>
  </div>
  <!-- <button mat-flat-button (click)="close()">Abbrechen</button> -->
  
  <!-- <button mat-flat-button color="primary" (click)="save()">Speichern</button> -->
</div>
</div>
