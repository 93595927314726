import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { POSITION_TYPE } from 'src/app/core/models/enums';
import { FullPositionOverlayData, TEXT_FOCUS_ELEMENT } from 'src/app/core/models/final/textoverlay.model';
import { JustificationItemI, PositionFlatI } from 'src/app/core/models/sectionPositionM';
import { SurgeryReportItenV4I } from 'src/app/core/models/v3/SurgeryReportItemIV3';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { TemplatePositionOverlayComponent } from 'src/app/ui/components/template-position-overlay/template-position-overlay.component';
import * as _ from "lodash";
@Component({
  selector: 'template-op-position-item-tile',
  templateUrl: './template-op-position-item-tile.component.html',
  styleUrls: ['../positions.scss'],
})
export class TemplateOpPositionItemTileComponent implements OnInit {

  @Input() position:PositionFlatI
  @Output() onSavingElement = new EventEmitter<boolean>()
  @Output() reportItemsLoaded = new EventEmitter<void>()
 loaded = false
 activeIndex:number = 0

  _reportItemsByPos:SurgeryReportItenV4I[] = []
  constructor(private tc:TemplateControllerService, private dialog: MatDialog) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.load()
    },50)
  }

  get editable():boolean {
    return this.tc.isTemplateEditable
  }


  async load(){
    const _items = await this.tc.loadSurgReportItemsByPosId(this.tc.prefix,this.position.position_id)
    this._reportItemsByPos = _items;
    console.log(this._reportItemsByPos);
    
    const fIndex = this._reportItemsByPos.findIndex(item => item.is_active === true);
    console.log(`fIndex: ${fIndex}`);
    
    this.activeIndex = fIndex != null && fIndex !== -1 ? fIndex : 0;
    console.log(`activeIndex: ${this.activeIndex}`);
    

    if(this.position.justifications && this.position.justifications.length > 0) {
      const _lastIndented = _.maxBy(this.position.justifications, item => item.lastModified)
      
      

      this.position_justification_index = this.position.justifications.findIndex(item => item.id == _lastIndented.id)
    }

    setTimeout(()=>{
      this.loaded = true;
      this.reportItemsLoaded.emit()
    },50)
  }
  
  get reportItemsByPos(): SurgeryReportItenV4I[] {
    return this._reportItemsByPos
  }

  justifications(p:PositionFlatI) : JustificationItemI {
    if(p.justification_items != null  ){
      for(var j = 0; j < p.justification_items.length; j++){
         if(p.justification_items[j].progression_factor == p.progression_factor){

          p.justification_items[j].text_items = p.justification_items[j].text_items == null ? p.justification_items[j].text_items = [] : p.justification_items[j].text_items
          return p.justification_items[j]
         }
      }
    } else {
      return null;
    }
    return null;
  }

  itemNeedJustification(p:PositionFlatI):boolean {
    if(p.position_type != POSITION_TYPE.GOA){
      return false;
    }
    if(p.progression_factor != null && p.goa_paragraph != null){
      if(p.progression_factor > Number(p.goa_paragraph.progression_factor) || p.progression_factor > 2.3) {
        return true;
      }
    }
    return  false;
  }


  async activateSugeryRelevantTapped(){
    await this.tc.updateSinglePositionItem(this.tc.currentTemplateId, this.position.position_id, {surgery_relevant: this.position.surgery_relevant})
  }

  

  FOCUS_ELMENTS = TEXT_FOCUS_ELEMENT
  tapped(e: TEXT_FOCUS_ELEMENT) {
    this.openFullPusitionOverlay(this.position, e)
  }

  openFullPusitionOverlay(position: PositionFlatI, focuselement: TEXT_FOCUS_ELEMENT) {
    // console.log(position);
    // console.log(focuselement);
    // return
    
    if(!this.tc.isTemplateEditable) { return }
    // console.log(`OPEN FULL POSITION OVERLAY WITH FOCUS ${focuselement}`)
    var overlayData: FullPositionOverlayData = {
      position: position,
      textfocusElement: focuselement,
      reportItems: this._reportItemsByPos
    };

    this.dialog.open(TemplatePositionOverlayComponent, {
      // disableClose: true, 
      backdropClass:"backdrop-background",
      width: "80%",
      maxWidth: "1200px",
      height: '80%',
      maxHeight: '900px',
      data: overlayData,
      //disableClose: true
    })
    // .backdropClick()
    .afterClosed().subscribe(
      (_data: SurgeryReportItenV4I[]) => {

        if(position.justifications && position.justifications.length > 0) {
          const _lastIndented = _.maxBy(position.justifications, item => item.lastModified)
          
          

          this.position_justification_index = position.justifications.findIndex(item => item.id == _lastIndented.id)
        }

        console.log('AFTER CLOSED', _data);
        
        if (_data && _data.length > 0) {

          console.log('AFTER CLOSED');
          this._reportItemsByPos = _data;
          const fIndex = this._reportItemsByPos.findIndex(item => item.is_active === true);
          
          this.activeIndex = fIndex != null && fIndex !== -1 ? fIndex : 0;
          
    
          
        } else {
          this.load()
        }
      }
    )
  }

  position_justification_index = 0
}


