<div class="container p0" *ngIf='!itemNeedJustification'>
  <div class="row" >
    <div class="col-12">
      <p class="fs12-400" style="color:rgba(0, 0, 0, 0.5);" [class.enabled]='isEditable'>Keine Begründung erforderlich</p>
    </div>
  </div>
</div>



  <div class="d-flex pr20" *ngIf='!showJustificationButton(position) && itemNeedJustification'>

    <div class="p-0" >
      <div class="d-flex " style="width: 142px;">
        <div class="p-0">
          <mat-icon class='justification_arrow_icon' [class.enabled]='isEditable' *ngIf="tc.isTemplateEditable"
          (click)='prevJusitification(position)'>keyboard_arrow_left</mat-icon>
        </div>
    
        <div class="p-0 mt4 " style="font-size:14px; font-weight:300;" *ngIf="tc.isTemplateEditable">
          <span style="color: #1976D2; font-weight:500;"
          [class.enabled]='!position.activated'>{{currentIndex + 1}}</span>
          <span [class.enabled]='!position.activated'> von {{goaJustifications.length}}</span>
        </div>
    
        <div class="p-0">
          <mat-icon class='justification_arrow_icon' [class.enabled]='!position.activated' *ngIf="tc.isTemplateEditable"
          (click)='nextJusitification(position)'>keyboard_arrow_right</mat-icon>
        </div>
    
        <div class="p-0 mt4">
          <mat-icon class='justification_arrow_icon_mini' [class.enabled]='!position.activated' *ngIf="tc.isTemplateEditable"
          (click)='addItem()'>add</mat-icon>
        </div>
    
        <div class="p-0 mt4">
          <mat-icon class='justification_arrow_icon_mini' [class.enabled]='!position.activated' *ngIf="tc.isTemplateEditable"
          (click)='removeItem()'>delete</mat-icon>
        </div>
      </div>
    </div>
    <div class="p-0 " *ngIf='itemNeedJustification' (click)='openJustificationOverlay()' style="user-select: none; cursor: pointer; width: 100%;">
      
      <div class="justification-text-style" style="    border: 1px dotted #cccccc;">
        <span class="fs12-400 truncate-overflow p0 mt0 "
        style="width: 100%;  user-select: none; " [class.enabled]='position.activated'
        >{{getJustificationCopy}}</span>
      </div>
    
  </div>

  </div>
 
  <div class="d-flex" *ngIf='showJustificationButton(position)'>
    <div class="p-0" >
      <div class="col-auto p0 " style="height: 26px; margin-top: 3px!important;">
        <mat-icon  matTooltipClass='sectionGoaeTT' matTooltip='Neue Begründung hinzufügen' matTooltipPosition='above'
        class='justification_arrow_icon_mini_warn dib pl8' [class.enabled]='!position.activated'
        (click)='openJustificationOverlay(true)' >add</mat-icon>
        <div class="justification_copy_mini_warn dib ml3" (click)='openJustificationOverlay(true)'>Begründung erforderlich</div>
      </div>
    </div>
  </div>

