
<div class="content d-flex flex-column" [class.content_bg_process]="showDarkBg" >
    <app-breadcrump *ngIf='isSignedIn && ui.account != null'
    [class.navIn]='showNav'
    [class.navOut]='!showNav'></app-breadcrump>
    <router-outlet ></router-outlet>
  </div>



  <div class="_account d-flex align-items-center"  *ngIf='isSignedIn && ui.account != null'>
    <div class="p-2 _clickable"  [matMenuTriggerFor]="menu" style="padding-top:11px!important;">
      <div class="_help_icon">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 27.2222C21.3024 27.2222 27.2222 21.3024 27.2222 14C27.2222 6.69757 21.3024 0.777779 14 0.777779C6.69756 0.777779 0.777771 6.69757 0.777771 14C0.777771 21.3024 6.69756 27.2222 14 27.2222Z" stroke="white" class="svgUserIcon"/>
          <path d="M13.1715 17.9972V17.5369C13.1772 16.6392 13.2766 15.9091 13.4698 15.3466C13.663 14.7841 13.9471 14.3097 14.3221 13.9233C14.7028 13.5312 15.1744 13.1562 15.7369 12.7983C16.1573 12.5256 16.5096 12.2386 16.7937 11.9375C17.0835 11.6307 17.3022 11.2955 17.4499 10.9318C17.6033 10.5625 17.68 10.1619 17.68 9.73011C17.68 9.11648 17.5352 8.57954 17.2454 8.11932C16.9613 7.65909 16.5749 7.30398 16.0863 7.05398C15.5977 6.7983 15.055 6.67045 14.4585 6.67045C13.8903 6.67045 13.359 6.79545 12.8647 7.04545C12.3761 7.28977 11.9755 7.65341 11.663 8.13636C11.3562 8.61364 11.1886 9.20455 11.1602 9.90909H9.54936C9.57777 8.97159 9.81072 8.15909 10.2482 7.47159C10.6857 6.78409 11.271 6.25284 12.0039 5.87784C12.7369 5.49716 13.555 5.30682 14.4585 5.30682C15.4016 5.30682 16.2312 5.50284 16.9471 5.89489C17.663 6.28125 18.2227 6.8125 18.6261 7.48864C19.0295 8.15909 19.2312 8.92898 19.2312 9.79829C19.2312 10.3722 19.1403 10.8949 18.9585 11.3665C18.7766 11.8381 18.5039 12.2727 18.1403 12.6705C17.7823 13.0682 17.3335 13.4432 16.7937 13.7955C16.2823 14.1364 15.8761 14.4744 15.5749 14.8097C15.2738 15.1449 15.0579 15.5284 14.9272 15.9602C14.7965 16.3864 14.7255 16.9119 14.7141 17.5369V17.9972H13.1715ZM13.9897 23.1278C13.6545 23.1278 13.3647 23.0085 13.1204 22.7699C12.8817 22.5256 12.7624 22.2358 12.7624 21.9006C12.7624 21.5597 12.8817 21.2699 13.1204 21.0312C13.3647 20.7926 13.6545 20.6733 13.9897 20.6733C14.3249 20.6733 14.6119 20.7926 14.8505 21.0312C15.0948 21.2699 15.217 21.5597 15.217 21.9006C15.217 22.1222 15.1602 22.3267 15.0465 22.5142C14.9386 22.7017 14.7908 22.8523 14.6033 22.9659C14.4215 23.0739 14.217 23.1278 13.9897 23.1278Z" fill="white" class="svgUserIconFill"/>
          </svg>

      </div>
      <mat-menu #menu="matMenu" yPosition="above">
        <button mat-menu-item (click)="openHelp(2)">doxfox in 2 Schritten</button>
        <button mat-menu-item (click)="openHelp(1)">Navigation und Funktionen</button>
      </mat-menu>
    </div>
    <div class="p-2 _clickable" (click)='gotoAccount()'>
      <div class="d-flex">
        <div class="p-0 _account_copy">{{userName}}</div>
        <div class="p-0 _account_icon">
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 27.2222C21.3024 27.2222 27.2222 21.3024 27.2222 14C27.2222 6.69757 21.3024 0.777779 14 0.777779C6.69756 0.777779 0.777771 6.69757 0.777771 14C0.777771 21.3024 6.69756 27.2222 14 27.2222Z" stroke="white" class="svgUserIcon svgUserIconFill"/>
            <path d="M24.5341 21.7778C21.5972 19.5796 17.9506 18.2778 14 18.2778C9.88869 18.2778 6.10669 19.6877 3.11111 22.0504" stroke="white" class="svgUserIcon"/>
            <path d="M14 14.3889C16.1478 14.3889 17.8889 12.6478 17.8889 10.5C17.8889 8.35223 16.1478 6.61111 14 6.61111C11.8522 6.61111 10.1111 8.35223 10.1111 10.5C10.1111 12.6478 11.8522 14.3889 14 14.3889Z" stroke="white" class="svgUserIcon"/>
            </svg>


        </div>
      </div>
    </div>
  </div>



  <!-- [color]="color"
  [mode]="mode" -->
  <!-- [value]="value" -->
<app-progress-spinner class="example-margin"
[backdropEnabled]="true"
[positionGloballyCenter]="true"
 [displayProgressSpinner]="displayProgressSpinner">
</app-progress-spinner>

<div class="dev-banner"></div>


<div class="sessionTimeout" #sessionTimeOutDiv>
  <div class="d-flex h100 align-items-center justify-content-center">
    <div class="p-2" style="max-width: 70%; color: #fff; text-align: center; font-size: 64px; line-height: normal;">
      {{timeoutmessage}}
    </div>
  </div>
</div>
