import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/shared/auth.service';
import { Expo, Circ, Power1} from 'gsap/all'
import {TweenMax} from 'gsap';
import { ControllerService } from 'src/app/shared/controller.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SessionTimeoutOverlayComponent } from 'src/app/ui/overlays/session-timeout-overlay/session-timeout-overlay.component';
import { Subscription } from 'rxjs';
import { UiService } from 'src/app/services/ui.service';
import { LocaleService } from 'src/app/services/locale.service';
import { AddressI } from 'src/app/core/models/accountI';
import { AUTH_CALL } from 'src/app/core/models/auth/auth.enum';
import { CREATE_PASSWORD_RESPONSE, CREATE_PASSWORD_RESPONSE_STATUS } from 'src/app/core/models/api/auth.response.mode';
import { CryptService } from 'src/app/services/crypt.service';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { AutocompleteDirective } from 'src/app/directives/autocomplete.directive';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../auth.scss'],
   providers:[AutocompleteDirective]
})
export class LoginComponent implements OnInit , OnDestroy{
  @ViewChild('_authCard') _authCard:ElementRef;
  @ViewChild('_forgotPwContainer') _forgotPwContainer:ElementRef;
  _currentCenter = 0
  _overlayclosed = false;
  loginLoading = false;
  adressSaving = false;
  newPasswordCopy = 'Bitte legen Sie ein Neues Passwort an'
  email:string = ''
  firstPassword:string = ''
  secondPassword:string = ''
  docName:string = ''
  doc_description:string = ''
  enterNewPasswordMode:boolean = false;
  // saveDisabled = false
  charLengthOk = false
  specialCharOk = false
  upperCaseCharOK = false
  saveNewPasswordEnabled = false
  signUpEmitterSubscription:Subscription;

  address:AddressI = {
    id:-1,
    abteilung:"",
    arzt_role:"",
    bank_name:"",
    behandelnder_arzt:"",
    bic:"",
    city:"",
    facharzt_bezeichnung:"",
    iban:"",
    klinikName:"",
    kontoinhaber:"",
    streetName:"",
    streetNumber:"",
    ust_nr:"",
    zip:""
  }

  constructor(
    private activatedRoute:ActivatedRoute,
    private crypy:CryptService,
    private ui:UiService,
    public locales:LocaleService,
    public authService: AuthService,
    private router:Router,
    private dialog:MatDialog) { }

  ngOnDestroy(): void {
    if(this.signUpEmitterSubscription != null){
      this.signUpEmitterSubscription.unsubscribe()
    }
  }

  ngOnInit() {
    ControllerService.windowResize.subscribe(
      _newWindowSize => {
         if(this._currentCenter != _newWindowSize.height / 2){
          this.changeCenterY(_newWindowSize.height / 2);
         }
      }
    );

    this.activatedRoute.queryParams.subscribe(
      _queryParams => {
        console.log(_queryParams)
        if(_queryParams != null && _queryParams.signup != null){
          setTimeout(()=>{
            this.email = atob(_queryParams.signup)
          },1000)
        }
      }
    );

    this.activatedRoute.paramMap.subscribe(
      _params => {
        if(_params != null && _params.get('ll') != null && this._overlayclosed === false){
        //  this.openDialog();
        }
      }
    );

    if(this.signUpEmitterSubscription != null){
      this.signUpEmitterSubscription.unsubscribe()
    }

    this.signUpEmitterSubscription = this.ui.loginViewSignInEmitter.subscribe(
      _message => {
        if(_message == "signInComplete"){
          this.fadeOut();
        } else if(_message == 'ACCESS_ADDRESS_MISSING'){
          this.setAddressMissing()

        }
      }
    );
    setTimeout(()=>{
      // TweenMax.set(this._authCard.nativeElement, {transformStyle:"preserve-3d"});
    // TweenMax.set(".back", {rotationY:-180});
      // TweenMax.set([this._authCard.nativeElement], {backfaceVisibility:"hidden"});
      this.fadeIn();
    }, 2050)
  }
  openDialog(){
    this.dialog.open(SessionTimeoutOverlayComponent, {
      width:"450px",
      height:"250px",
      position: {top: "50px"}
    })
  }

  changeCenterY(newCenterY:number){
    console.log("changeCenterY")

    this._currentCenter =  newCenterY
    let newYPosition = newCenterY - 190
    TweenMax.to(this._authCard.nativeElement, 1, { 'margin-top':newYPosition, ease: Circ.easeOut, delay: 0.25});
    TweenMax.to(this._forgotPwContainer.nativeElement, 1, { 'margin-top': newYPosition + 320, ease: Circ.easeOut});
   
  }

  fadeIn(){
    console.log("fadeIn")
    this._currentCenter =  (ControllerService.currentScreenSize.height / 2)
    
    let newYPosition = (ControllerService.currentScreenSize.height / 2) - (this._authCard.nativeElement.clientHeight / 2)
  
    TweenMax.to(this._authCard.nativeElement, 0.45, { 'margin-top':newYPosition, ease: Expo.easeInOut});
    TweenMax.to(this._forgotPwContainer.nativeElement, 0.45, { 'margin-top':newYPosition + 320, ease: Expo.easeInOut, delay: 1.5});
    
    TweenMax.to(this._authCard.nativeElement, 0.35, { 'opacity':1, ease: Power1, delay: 0.25});
    TweenMax.to(this._forgotPwContainer.nativeElement, 0.35,{ 'opacity':1, ease: Power1, delay: 1.5});
  

  }

  fadeOut(){
    this._currentCenter =  (ControllerService.currentScreenSize.height / 2)
    let newYPositionStart = (ControllerService.currentScreenSize.height / 2) -190
    let newYPosition = ControllerService.currentScreenSize.height

    TweenMax.to(this._authCard.nativeElement, 1,
      {  'margin-top':newYPosition, ease: Expo.easeInOut});

    TweenMax.fromTo(this._authCard.nativeElement, 0.7, { 'opacity':1 },
    { 'opacity':0, ease: Power1});

    TweenMax.to(this._forgotPwContainer.nativeElement, 0.35,
    { 'opacity':0,'margin-top':newYPositionStart, ease: Power1});

  }

  setAddressMissing(){
    console.log("setAddressMissing")
    this._currentCenter =  (ControllerService.currentScreenSize.height / 2)
    // let newYPositionStart = (ControllerService.currentScreenSize.height / 2) +200
    let newYPosition = (ControllerService.currentScreenSize.height / 2) - 345

    TweenMax.to(this._authCard.nativeElement, 1,
    { 'width':1000, 'height':690, ease: Power1, delay: 0.5});

    TweenMax.to(this._authCard.nativeElement, 1,
    {  'margin-top':newYPosition, 'margin-left':-500, ease: Expo.easeInOut, delay:0.7 });

    TweenMax.to(this._forgotPwContainer.nativeElement, 1,
    { 'margin-top':newYPosition, ease: Expo.easeInOut, delay: 1.5});

    TweenMax.to(this._forgotPwContainer.nativeElement, 0.35,
    { 'opacity':0, ease: Power1, delay: 1});
  }
  // encryptAES = (text, key) => {
  //   return CryptoJS.AES.encrypt(text, key).toString();
  // };


  /**
   * Decrypt an encrypted message
   * @param encryptedBase64 encrypted data in base64 format
   * @param key The secret key
   * @return The decrypted content
   */
  // decryptAES = (encryptedBase64, key) => {
  //   const decrypted = CryptoJS.AES.decrypt(encryptedBase64, key);
  //   if (decrypted) {
  //     try {
  //       console.log(decrypted);
  //       const str = decrypted.toString(CryptoJS.enc.Utf8);
  //       if (str.length > 0) {
  //         return str;
  //       } else {
  //         return 'error 1';
  //       } 
  //     } catch (e) {
  //       return 'error 2';
  //     }
  //   }
  //   return 'error 3';
  // };
  async trySignIn(email: string, password: string) {
    let cPass = this.crypy.encryptAES(password)
    console.log(cPass)
    // let de = this.decryptAES(cPass, environment.EncryptKey)
    // console.log(de)
  
    let newYPosition = (ControllerService.currentScreenSize.height / 2) + 150 //- (this._authCard.nativeElement.clientHeight / 2)
    let newYPositionStart = (ControllerService.currentScreenSize.height / 2) -190
    TweenMax.to(this._forgotPwContainer.nativeElement, 0.35,
      { 'opacity': 0, 'margin-top': newYPositionStart, ease: Expo.easeInOut });
    
    this.loginLoading = true;

    
    setTimeout(() => {

      this.authService.SignIn(email, cPass).then((x) => {
        this.loginLoading = false;
  
        TweenMax.to(this._forgotPwContainer.nativeElement, 0.35, 
      { 'opacity': 1, 'margin-top': newYPosition, delay: 1, ease: Expo.easeInOut });
      })
    },1000)
  }

  async setNewPassword(){
    const result = await this.authService.POST(AUTH_CALL.POST_CREATE_PASSWORD,
      { "payload": {'password': this.firstPassword } })

    const response = result as CREATE_PASSWORD_RESPONSE  

    switch(response.status) {

      case CREATE_PASSWORD_RESPONSE_STATUS.ERROR: 
      this.authService.snackUnkownError()
      return

      case CREATE_PASSWORD_RESPONSE_STATUS.SUCCESS: 
        if(response.value){
          this.trySignIn(response.value, this.firstPassword)
        } else {
          this.authService.snackUnkownError()
        }
      return
    }

  }

  onEmailChanged(e) {
    // console.log(e);
    this.email = e.toLowerCase()
    
  }
  passwordChanged(e){
    this.upperCaseCharOK = this.hasUpperCase(e)
    this.specialCharOk = this.hastSpecialChar(e)
    this.charLengthOk = e.length > 7;
    this.checkValidation()
  }

  secondPasswordChanged(e){
    this.checkValidation()
  }
  private hastSpecialChar(str) {
    return (/[#,$,@,&,*,!,%]/.test(str));
  }
  private hasUpperCase(str) {
    return (/[A-Z]/.test(str));
  }
  checkValidation(){
    //this.emailFormControl.valid
     if(this.upperCaseCharOK &&
     this.specialCharOk &&
     this.charLengthOk && this.secondPassword === this.firstPassword) {
        this.saveNewPasswordEnabled = true;
      }
  }
  navigate(path:string){

    this.fadeOut()
    setTimeout(()=>{
      this.router.navigateByUrl(path)
    },650)
  }

  async saveAddress(){
    // this.address.behandelnder_arzt = this.docName
    // this.address.facharzt_bezeichnung = this.doc_description;
    this.adressSaving = true
    this.authService.saveAddress(this.address)
    // if(this.address.id == -1){
    //   const __data = await this.accountS.addNewAddress(this.address).then(
    //     (data)=>{
    //       // this.ui.loadAddresses()
    //       setTimeout(()=>{
    //         // this.addresses = this.ui.addresses;
    //         this.adressSaving = false;
    //        /*  this.addAdress() */
    //       },250)
    //     }, err =>{
    //       // this.isSaving = false;
    //     })

    // } else {
    //   const __data = await this.accountS.updateAddress(this.address).then(
    //     (data)=>{

    //       setTimeout(()=>{
    //         // this.addresses = this.ui.addresses;
    //         this.adressSaving = false;
    //         /* this.addAdress() */
    //       },250)
    //     }, err =>{
    //       // this.isSaving = false;
    //     })

    // }
  }

  get saveDisabled():boolean {
    if(this.address.city == null || this.address.city == ''
    || this.address.klinikName == null || this.address.klinikName == ''
    || this.address.streetName == null || this.address.streetName == ''
    || this.address.zip == null || this.address.zip == ''
    ) { return true}
    return false;
  }
}
