import { environment } from "src/environments/environment";

export enum AUTH_CALL {
  POST_SIGN_IN,
  GET_SIGN_IN,
  GET_UID,
  POST_VALIDATE_FB,
  GET_ACCESS,
  POST_LOST_PASSWORD,
  POST_CHANGE_PASSWORD,
  POST_CREATE_PASSWORD,
  POST_REFRESH,


}

export function AUTH_REQUEST(call: AUTH_CALL, params: string = '') : string {

  switch(call){

    case AUTH_CALL.POST_SIGN_IN:
      return `${environment.endpoints.apiBasePathV2}auth/sign-in`;

    case AUTH_CALL.GET_SIGN_IN:
      return `${environment.endpoints.apiBasePathV2}auth/sign-in`;

    case AUTH_CALL.GET_UID:
      return `${environment.endpoints.apiBasePathV2}auth/uid`;

    case AUTH_CALL.POST_VALIDATE_FB:
      return `${environment.endpoints.apiBasePathV2}auth/validate_fb`;

    case AUTH_CALL.GET_ACCESS:
      return `${environment.endpoints.apiBasePathV2}auth/access`;

    case AUTH_CALL.POST_LOST_PASSWORD:
      return `${environment.endpoints.apiBasePathV2}auth/lost-password`;

    case AUTH_CALL.POST_CHANGE_PASSWORD:
      return `${environment.endpoints.apiBasePathV2}auth/changePassword`;

    case AUTH_CALL.POST_CREATE_PASSWORD:
      return `${environment.endpoints.apiBasePathV2}auth/createPassword`;

    case AUTH_CALL.POST_REFRESH:
      return `${environment.endpoints.apiBasePathV2}auth/refresh`;



  }
}
