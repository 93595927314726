import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiUtilService {

  constructor(private http:HttpClient) { }


  async apiGetP(path:string){
    const _res = await this.http.get(path).toPromise()
    return _res
  }
  async apiPost(path:string, item:Object){
    const _res = await this.http.post(path,item).toPromise()
    return _res
  }
  async apiPostZipRep(path:string, item:Object){
    const _res = await this.http.post(path,item, {
      responseType: 'arraybuffer',
      // reportProgress: true,
    })
    console.log(_res);
    
    return _res.toPromise()
  }

  
}
