import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HORIZONTAL_ITEM_SELECTION_ACTION } from 'src/app/core/models/horizontalItemSelectionAction';

@Component({
  selector: 'horizontal-item-selection',
  templateUrl: './horizontal-item-selection.component.html',
  styleUrls: ['./horizontal-item-selection.component.scss']
})
export class HorizontalItemSelectionComponent implements OnInit {

  @Input() showRemove: boolean = true;
  @Input() showAdd: boolean = true;
  @Input() editable: boolean = true;
  @Input() itemCount: number;
  @Input() currentIndex: number = 0;
  activated = false

  @Output() action: EventEmitter<HORIZONTAL_ITEM_SELECTION_ACTION> = new EventEmitter<HORIZONTAL_ITEM_SELECTION_ACTION>()
  constructor() { }

  ngOnInit(): void {
  }

  prevElement() {
    this.action.emit(HORIZONTAL_ITEM_SELECTION_ACTION.PREV);
  }
  nextElement() {
    this.action.emit(HORIZONTAL_ITEM_SELECTION_ACTION.NEXT);
  }
  addElement() {
    this.action.emit(HORIZONTAL_ITEM_SELECTION_ACTION.ADD);
  }
  deleteElement() {
    this.action.emit(HORIZONTAL_ITEM_SELECTION_ACTION.REMOVE);
  }

}
