import { DatePipe } from "@angular/common";
import { CgcurrencyPipe } from "src/app/pipes/cgcurrency.pipe";
import { TemplateFlatI } from "../../api/TemplateFlatI";
import { InvoicePreviewPositionItemI } from "./invoicePositionI";
import  jsPDF  from "jspdf";
import autoTable from 'jspdf-autotable';
import { Fonts } from "../../../../ui/pdf-dependencies/pdfFonts";
import { DATETOSTRING } from "src/app/utils/helpers";
import { AddressI } from "../../accountI";
import { POSITION_TYPE } from "../../enums";
import addDays from "date-fns/addDays";
import { SectionMaterialI } from "../../materialI";
import { PatientFull } from "../../patient";
import { InvoiceModelI } from "./invoiceModel";
import { InvoiceI } from "../../invoiceI";

export class InvoicePreviewC {


  private _InvoiceI:InvoiceI
  get invoiceI():InvoiceI {
   return this._InvoiceI
  }
  constructor(
    private address:AddressI,
    private template:TemplateFlatI,
    private datePipe: DatePipe,
    private invoiceItems:InvoicePreviewPositionItemI[],
    private matItems:SectionMaterialI[],
    private patientFull:PatientFull,
    private currencyPipe:CgcurrencyPipe,
    private diagnose:string,
    private tMethod:string
    ) {}

    createPreview(invoiceNumber:number):jsPDF{
      let _items:InvoicePreviewPositionItemI[] = []
      _items = this.invoiceItems
      var _sum = 0;
      let _lastSectionId:number;
      let _templateDate:Date = new Date(this.template.date);
      _templateDate.setHours(0,0,0,0);

      let _currentDate:Date = _templateDate;
      let _lastDate:Date = _templateDate;
      let _last_treated_at_difference_days = 0
       var _date = _date = this.datePipe.transform(_currentDate, 'dd.MM.yyyy')

      for(var i = 0; i < _items.length; i ++){

        if(!_lastSectionId || _lastSectionId != _items[i].section_id) {
          _lastSectionId = _items[i].section_id
          _currentDate = _templateDate
        }

        if(_items[i].treated_at_difference_days > 0 && _items[i].treated_at_difference_days != _last_treated_at_difference_days){
          _last_treated_at_difference_days = _items[i].treated_at_difference_days
         _currentDate = addDays(_currentDate, _last_treated_at_difference_days)

        }

        if(_lastDate != _currentDate){
          _lastDate = _currentDate;
          _date = this.datePipe.transform(_currentDate, 'dd.MM.yyyy')
        }
        if(i > 0 && _items[i -1].date != _date){
          _items[i].date = _date;
        } else if(i==0){
          _items[i].date = _date;
        }

        if(_items[i].position_type == POSITION_TYPE.OWN){
          _items[i].progression_factor = 0
        }
        if(_items[i].position_type == POSITION_TYPE.GOA || _items[i].position_type == POSITION_TYPE.NONE){
          let _itemSum = (_items[i].progression_factor * _items[i].amount_euro) * _items[i].quantity
          _sum += _itemSum
          _items[i].summary = this.currencyPipe.transform(_itemSum)
        } else {
          _items[i].summary = "0.0"
        }



        _date = ''
      }


      let _tempSum = 0
      if(this.template.reduction != null && this.template.reduction != 0){
        let _reduction =  this.template.reduction;
         _tempSum = _sum * (_reduction / 100);
        _sum -= _tempSum;

      }
      var _i:InvoiceI = {
        documentId: '-1',
        IPROCESSd: '-1',
        uid: '-1',
        invoiceYear:2022,
        invoiceNumber:invoiceNumber,
        invoiceDate: new Date(_templateDate),
        templateName: this.template.name,
        address: this.address,
        treatment_region: this.template.treatment_region,
        treatment_type: this.template.treatment_type,

        diagnosis:this.diagnose,
        therapy: this.tMethod,
        date: '',
        notes: '',
        invoiceItems: _items,
        matSectionItems: this.matItems,

        patientFirstName: this.patientFull != null ? this.patientFull.firstName : '',
        patientLastName: this.patientFull != null ? this.patientFull.lastName : '',
        patientBirthday: this.patientFull != null && this.patientFull.birthDate != null ? this.datePipe.transform(this.patientFull.birthDate) : '',
        patientStreet: this.patientFull != null ? this.patientFull.street : '',
        patientZipCity: this.patientFull != null ? this.patientFull.zip + ' ' + this.patientFull.city : '',

        klinik_name: '',
        streetName: '',
        zip: '',
        city: '',


        summary:  this.currencyPipe.transform(_sum),
        // reductionSummary: '',
        bank_owner_name: '',
        reduction:this.template.reduction != null ? this.template.reduction : 0,
        reductionSummary:  this.currencyPipe.transform(_tempSum),//(Math.round((_tempSum + Num
        iban: '',
        bic: '',
      }
      this._InvoiceI = _i
      return this.getInvoicePDF(_i);
    }
     getInvoicePDF(invoiceI:InvoiceI):jsPDF {
      var lastCursor
      var lastY
      const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages()

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i)
          if(i == pageCount){

            if(invoiceI.reduction == 25){
              doc.setFont('Barlow-Light', 'normal')
              doc.setFontSize(10)
              .text("Minderung gem. § 6a, Abs. 1, GOÄ (25%): " + invoiceI.reductionSummary , 551, lastCursor.y + 20, {align:'right'});

            } else if(invoiceI.reduction == 15){
              doc.setFont('Barlow-Light', 'normal')
              doc.setFontSize(10)
              .text("Minderung gem. § 6a, Abs. 1, GOÄ (15%): " + invoiceI.reductionSummary , 551, lastCursor.y + 20, {align:'right'});

            }

            doc.setFont('Barlow-Light', 'normal')
            doc.setFontSize(14)
            .text('Gesamtbetrag: ' + invoiceI.summary, 551, lastCursor.y + 37, {align:'right'});

            let currentY = 781;
            doc.text("Bankverbindung " , 40, currentY + 24,{align:'left'})
            doc.setFontSize(8)

            var _kontoInhaber = "Kontoinhaber: "

            if(invoiceI.address != null){
              if(invoiceI.address.kontoinhaber != null && invoiceI.address.kontoinhaber != "" ){
                _kontoInhaber +=  invoiceI.address.kontoinhaber;
              }

              if(invoiceI.address.bank_name != null && invoiceI.address.bank_name != ""){
                _kontoInhaber += " | Bankinstitut: " + invoiceI.address.bank_name
              }
              doc.text(_kontoInhaber, 40, currentY +36,{align:'left'})


              var _ibanOutput = ""
              if(invoiceI.address.iban != null && invoiceI.address.iban != ""){
                _ibanOutput += "IBAN: " + invoiceI.address.iban
              }
              if(invoiceI.address.bic != null && invoiceI.address.bic != ""){
                _ibanOutput += " | BIC: " + invoiceI.address.bic;
              }


              if(invoiceI.address.ust_nr != null && invoiceI.address.ust_nr != ""){
                _ibanOutput += " | USt-IdNr.: " + invoiceI.address.ust_nr
              }
              doc.text(_ibanOutput, 40, currentY + 48,{align:'left'})
            }



          }

          doc.setFontSize(8)
          doc.text('Seite ' + String(i) + ' von ' + String(pageCount), 551, 820, {
            align: 'right'
          })
        }
      }

      var tableData = [];
      var lastDate =''
      for (var i = 0; i < invoiceI.invoiceItems.length; i++) {
        let _item = invoiceI.invoiceItems[i];

           let item = [_item.date, _item.goa_id, _item.quantity, _item.example, _item.progression_factor == 0 ? "" : _item.progression_factor, _item.summary]
           tableData.push(item);

      }

      const head = [['Behandlung', 'Ziffer', 'Menge', 'Bezeichnung','Faktor','Betrag']]

      const doc = new jsPDF('p','pt');

      let _curY = 54;
      doc.addFileToVFS('Barlow-Light.ttf', Fonts.light());
      doc.addFont('Barlow-Light.ttf', 'Barlow-Light', 'normal');

      doc.addFileToVFS('Barlow-Regular.ttf', Fonts.regular());
      doc.addFont('Barlow-Regular.ttf', 'Barlow-Regular', 'normal');

      doc.addImage('assets/doxfox.png', 40, 34, 32, 40)

      doc.setFont('Barlow-Light');
      doc.setFontSize(10)

      if(invoiceI.address != null && invoiceI.address.klinikName != null && invoiceI.address.klinikName != ""){
        doc.text(invoiceI.address.klinikName, 551, 44, { align: "right" })
      } else {
     /*    doc.text("XXXXXXXXXXXX", 551, 44, { align: "right" }) */
      }


      doc.setFontSize(8)


      if(invoiceI.address != null && invoiceI.address.abteilung != null && invoiceI.address.abteilung != ""){
        doc.text(invoiceI.address.abteilung, 551, _curY, { align: "right" });
        _curY += 10;
      }

      if(invoiceI.address != null && invoiceI.address.behandelnder_arzt != null && invoiceI.address.behandelnder_arzt != ""){
        // doc.text("beh. Arzt: " + invoiceI.address.behandelnder_arzt, 551, _curY, { align: "right" });
        doc.text(invoiceI.address.behandelnder_arzt, 551, _curY, { align: "right" });
        _curY += 10;
      }

      if(invoiceI.address != null && invoiceI.address.facharzt_bezeichnung != null && invoiceI.address.facharzt_bezeichnung != ""){
        doc.text(invoiceI.address.facharzt_bezeichnung, 551, _curY, { align: "right" })
        _curY += 10;
      }


      if(invoiceI.address != null && invoiceI.address.arzt_role != null && invoiceI.address.arzt_role != ""){
        doc.text(invoiceI.address.arzt_role, 551, _curY, { align: "right" })
        _curY += 10;
      }

      if(invoiceI.address != null && invoiceI.address.streetName != null && invoiceI.address.streetName != ""){
        doc.text(invoiceI.address.streetName, 551, _curY, { align: "right" })
      }
      _curY += 10;

      if(invoiceI.address != null && invoiceI.address.zip != null && invoiceI.address.zip != "" && invoiceI.address.city != null && invoiceI.address.city != ""){
        doc.text(invoiceI.address.zip + " " + invoiceI.address.city, 551, _curY, { align: "right" });
      }



      /* doc.text(invoiceI.streetName, 551, _curY, { align: "right" })
      _curY += 10;
      doc.text(invoiceI.zip + " " + invoiceI.city, 551, _curY, { align: "right" }); */

      doc.setFontSize(8);
      if(invoiceI.address != null && invoiceI.address.klinikName != null && invoiceI.address.klinikName != ""){
        doc.text(invoiceI.address.klinikName, 40, 135);
      } else {
        /* doc.text("XXXXXXXXXXXX", 40, 135); */
      }

      doc.setDrawColor(180,180,180);
      doc.setLineWidth(0.5);
      doc.line(40, 139, 270, 139);

      doc.rect(351,132,200, 80);
      doc.setFont('Barlow-Light')
      .setFontSize(10)
      .setDrawColor("#444444");
      var __y = 148;

      doc.text('Patient:', 361, __y)
      __y += 13;

      doc.setFont('Barlow-Regular')
      .text(invoiceI.patientFirstName + " " + invoiceI.patientLastName, 361, __y);
      __y += 13;

      doc.setFont('Barlow-Light');
      doc.text('Geburtsdatum:', 361, __y)
      .text(invoiceI.patientBirthday, 541, __y,{align:'right'});
      __y += 13;
      doc.text('Rechnungsdatum:', 361, __y)
      .text(DATETOSTRING(invoiceI.invoiceDate), 541, __y, {align:'right'});
      __y += 13;

      doc.text('Rechnungsnr.:', 361, __y)
      .text(invoiceI.invoiceYear.toString() + invoiceI.invoiceNumber.toString(), 541, __y, {align:'right'});
      __y += 13;

      /* doc.text('Patient:', 365, __y)
      .text(invoiceI.patientFirstName + " " + invoiceI.patientLastName, 475, __y);
      __y += 12;
      doc.text('Geburtsdatum:', 365, __y)
      .text(invoiceI.patientBirthday, 475, __y); */

      doc.text(invoiceI.patientFirstName + " " +  invoiceI.patientLastName, 40, 158)
        .text(invoiceI.patientStreet, 40, 171)
        .text(invoiceI.patientZipCity, 40, 184)


       doc
       .setFont('Barlow-Regular')
       .setFontSize(16)
       .setDrawColor("#444444")
       .text('Privatliquidation nach GOÄ', 40, 274);

       autoTable(doc, {
        margin: {top:10,right:35,left:35},
        theme: 'plain',
        styles:  { font: "Barlow-Light", fontStyle: 'normal',fillColor:[255,255,255] },
        // columnStyles: { 5: { halign: 'right' }, 2:{halign:'center' } , },
        startY: 290,

        body:  [
          ['Diagnose: '],
          [invoiceI.diagnosis],
          ['Therapie: '],
          [invoiceI.therapy],
        ],

        didDrawPage: (data) => {
          lastCursor = data.cursor

       },

        didDrawCell: (data) => {

         data.cell.styles.fillColor = [255,255,255],
         data.cell.styles.lineColor = [255,255,255]
        },
      });



      autoTable(doc, {
        head: head,
        styles:  { font: "Barlow-Light", fontStyle: 'normal'},
        columnStyles: { 5: { halign: 'right', cellWidth: 55 }, 2:{halign:'center' } , },
        startY: lastCursor.y,
        body: tableData,
        didDrawPage: (data) => {
          lastCursor = data.cursor
       },
        didDrawCell: (data) => {
        },
      })


      addFooters(doc)




      return doc;
    }
     getInvoicePDFFinal(invoiceI:InvoiceModelI):jsPDF {
      var lastCursor
      var lastY
      const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages()

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i)
          if(i == pageCount){

            if(invoiceI.reduction == 25){
              doc.setFont('Barlow-Light', 'normal')
              doc.setFontSize(10)
              .text("Minderung gem. § 6a, Abs. 1, GOÄ (25%): " + invoiceI.reductionSummary , 551, lastCursor.y + 35, {align:'right'});

            } else if(invoiceI.reduction == 15){
              doc.setFont('Barlow-Light', 'normal')
              doc.setFontSize(10)
              .text("Minderung gem. § 6a, Abs. 1, GOÄ (15%): " + invoiceI.reductionSummary , 551, lastCursor.y + 35, {align:'right'});

            }

            doc.setFont('Barlow-Light', 'normal')
            doc.setFontSize(14)
            .text('Gesamtbetrag: ' + invoiceI.summary, 551, lastCursor.y + 57, {align:'right'});

            let currentY = 781;
            doc.text("Bankverbindung " , 40, currentY + 24,{align:'left'})
            doc.setFontSize(8)

            var _kontoInhaber = "Kontoinhaber: "

            // if(invoiceI.address != null){
              if(invoiceI.kontoinhaber != null && invoiceI.kontoinhaber != "" ){
                _kontoInhaber +=  invoiceI.kontoinhaber;
              }

              if(invoiceI.bank_name != null && invoiceI.bank_name != ""){
                _kontoInhaber += " | Bankinstitut: " + invoiceI.bank_name
              }
              doc.text(_kontoInhaber, 40, currentY +36,{align:'left'})


              var _ibanOutput = ""
              if(invoiceI.iban != null && invoiceI.iban != ""){
                _ibanOutput += "IBAN: " + invoiceI.iban
              }
              if(invoiceI.bic != null && invoiceI.bic != ""){
                _ibanOutput += " | BIC: " + invoiceI.bic;
              }


              if(invoiceI.ust_nr != null && invoiceI.ust_nr != ""){
                _ibanOutput += " | USt-IdNr.: " + invoiceI.ust_nr
              }
              doc.text(_ibanOutput, 40, currentY + 48,{align:'left'})
            // }



          }

          doc.setFontSize(8)
          doc.text('Seite ' + String(i) + ' von ' + String(pageCount), 551, 820, {
            align: 'right'
          })
        }
      }

      var tableData = [];
      var lastDate =''
      for (var i = 0; i < invoiceI.invoiceItems.length; i++) {
        let _item = invoiceI.invoiceItems[i];

           let item = [_item.date, _item.goa_id, _item.quantity, _item.example, _item.progression_factor == 0 ? "" : _item.progression_factor, _item.summary]
           tableData.push(item);

      }

      const head = [['Behandlung', 'Ziffer', 'Menge', 'Bezeichnung','Faktor','Betrag']]

      const doc = new jsPDF('p','pt');

      let _curY = invoiceI.isClinic ? 34 : 44;
      doc.addFileToVFS('Barlow-Light.ttf', Fonts.light());
      doc.addFont('Barlow-Light.ttf', 'Barlow-Light', 'normal');

      doc.addFileToVFS('Barlow-Regular.ttf', Fonts.regular());
      doc.addFont('Barlow-Regular.ttf', 'Barlow-Regular', 'normal');

      doc.addImage('assets/doxfox.png', 40, 34, 32, 40)

      doc.setFont('Barlow-Light');
      doc.setFontSize(8)

      if (invoiceI.isClinic) {
        doc.setFont('Barlow-Light');
        if(invoiceI.legalMain != null && invoiceI.legalMain != ""){
          doc.text(invoiceI.legalMain, 551, _curY, { align: "right" })
          _curY += 10;
        }

      }
      doc.setFont('Barlow-Regular');
      if(invoiceI.klinikName != null && invoiceI.klinikName != ""){
        doc.text(invoiceI.klinikName, 551, _curY, { align: "right" })
        _curY += 10;
      }


      if (invoiceI.isClinic) {

      } else {
        doc.setFont('Barlow-Light');

        if(invoiceI.behandelnder_arzt != null && invoiceI.behandelnder_arzt != ""){
          // doc.text("beh. Arzt: " + invoiceI.behandelnder_arzt, 551, _curY, { align: "right" });
          doc.text(invoiceI.behandelnder_arzt, 551, _curY, { align: "right" });
          _curY += 10;
        }

        if(invoiceI.facharzt_bezeichnung != null && invoiceI.facharzt_bezeichnung != ""){
          doc.text(invoiceI.facharzt_bezeichnung, 551, _curY, { align: "right" })
          _curY += 10;
        }


        if(invoiceI.arzt_role != null && invoiceI.arzt_role != ""){
          doc.text(invoiceI.arzt_role, 551, _curY, { align: "right" })
          _curY += 10;
        }

        if(invoiceI.abteilung != null && invoiceI.abteilung != ""){
          doc.text(invoiceI.abteilung, 551, _curY, { align: "right" });
          _curY += 10;
        }
      }

      doc.setFont('Barlow-Regular');
      if(invoiceI.address_1 != null && invoiceI.address_1 != ""){
        doc.text(invoiceI.address_1, 551, _curY, { align: "right" })
      }
      _curY += 10;

      if(invoiceI.address_2 != null && invoiceI.address_2 != ""){
        doc.text(invoiceI.address_2, 551, _curY, { align: "right" });
        _curY += 10;
      }


      if (invoiceI.isClinic) {
        _curY += 12;
        doc.setFont('Barlow-Light');
        doc.setFontSize(8)

        if(invoiceI.invoiceProviderFirst != null && invoiceI.invoiceProviderFirst != ""){
          doc.text(invoiceI.invoiceProviderFirst, 551, _curY, { align: "right" });
          _curY += 10;
        }
        if(invoiceI.invoiceProviderSecond != null && invoiceI.invoiceProviderSecond != ""){
          doc.text(invoiceI.invoiceProviderSecond, 551, _curY, { align: "right" });
          _curY += 10;
        }
        if(invoiceI.invoiceProviderThird != null && invoiceI.invoiceProviderThird != ""){
          doc.text(invoiceI.invoiceProviderThird, 551, _curY, { align: "right" });
          _curY += 10;
        }
        if(invoiceI.invoiceProviderFourth != null && invoiceI.invoiceProviderFourth != ""){
          doc.text(invoiceI.invoiceProviderFourth, 551, _curY, { align: "right" });
          _curY += 10;
        }

      }

      // if(invoiceI.abteilung != null && invoiceI.abteilung != ""){
      //   doc.text(invoiceI.abteilung, 551, _curY, { align: "right" });
      //   _curY += 10;
      // }

      // if(invoiceI != null && invoiceI.behandelnder_arzt != null && invoiceI.behandelnder_arzt != ""){
      //   // doc.text("beh. Arzt: " + invoiceI.address.behandelnder_arzt, 551, _curY, { align: "right" });
      //   doc.text(invoiceI.behandelnder_arzt, 551, _curY, { align: "right" });
      //   _curY += 10;
      // }

      // if(invoiceI != null && invoiceI.facharzt_bezeichnung != null && invoiceI.facharzt_bezeichnung != ""){
      //   doc.text(invoiceI.facharzt_bezeichnung, 551, _curY, { align: "right" })
      //   _curY += 10;
      // }


      // if(invoiceI != null && invoiceI.arzt_role != null && invoiceI.arzt_role != ""){
      //   doc.text(invoiceI.arzt_role, 551, _curY, { align: "right" })
      //   _curY += 10;
      // }

      // if(invoiceI != null && invoiceI.address_1 != null && invoiceI.address_1 != ""){
      //   doc.text(invoiceI.address_1, 551, _curY, { align: "right" })
      // }
      // _curY += 10;

      // if(invoiceI != null && invoiceI.address_2 != ""){
      //   doc.text(invoiceI.address_2, 551, _curY, { align: "right" });
      // }



      /* doc.text(invoiceI.streetName, 551, _curY, { align: "right" })
      _curY += 10;
      doc.text(invoiceI.zip + " " + invoiceI.city, 551, _curY, { align: "right" }); */

      doc.setFontSize(8);
      if(invoiceI.klinikName != null && invoiceI.klinikName != ""){
        doc.text(invoiceI.klinikName, 40, 135);
      } else {
        /* doc.text("XXXXXXXXXXXX", 40, 135); */
      }

      doc.setDrawColor(180,180,180);
      doc.setLineWidth(0.5);
      doc.line(40, 139, 270, 139);

      doc.rect(351,132,200, 80);
      doc.setFont('Barlow-Light')
      .setFontSize(10)
      .setDrawColor("#444444");
      var __y = 148;

      if(!invoiceI.isClinic) {
        doc.text('Patient:', 361, __y)
        __y += 13;
        doc.setFont('Barlow-Regular')
        .text(invoiceI.patient_name, 361, __y);
        __y += 13;
        doc.setFont('Barlow-Light');
        doc.text('Geburtsdatum:', 361, __y)
        .text(invoiceI.patient_birthdate, 541, __y,{align:'right'});
        __y += 13;
      }

      doc.text('Rechnungsdatum:', 361, __y)
      .text(DATETOSTRING(new Date(invoiceI.invoice_date_time)), 541, __y, {align:'right'});
      __y += 13;

      // doc.text('Rechnungsnr.:', 361, __y)
      // .text(invoiceI.invoice_date_year.toString() + invoiceI.invoice_number.toString(), 541, __y, {align:'right'});
      // __y += 13;

      if(invoiceI.isClinic) {
        doc.text('Fallnummer:', 361, __y)
        .text(invoiceI.invoice_number.toString(), 541, __y, {align:'right'});
      __y += 13;
      } else {
        doc.text('Rechnungsnr.:', 361, __y)
        .text(invoiceI.invoice_date_year.toString() + invoiceI.invoice_number.toString(), 541, __y, {align:'right'});
        __y += 13;
      }

      doc.text(invoiceI.patient_name, 40, 158)
        .text(invoiceI.patient_address_1, 40, 171)
        .text(invoiceI.patient_address_2, 40, 184)


       doc
       .setFont('Barlow-Regular')
       .setFontSize(16)
       .setDrawColor("#444444")
       .text('Privatliquidation nach GOÄ', 40, 274);

       autoTable(doc, {
        margin: {top:10,right:35,left:35},
        theme: 'plain',
        styles:  { font: "Barlow-Light", fontStyle: 'normal',fillColor:[255,255,255] },
        // columnStyles: { 5: { halign: 'right' }, 2:{halign:'center' } , },
        startY: 290,

        body:  [
          ['Diagnose: '],
          [invoiceI.diagnose],
          ['Therapie: '],
          [invoiceI.therapiemethode],
        ],

        didDrawPage: (data) => {
          lastCursor = data.cursor

       },

        didDrawCell: (data) => {

         data.cell.styles.fillColor = [255,255,255],
         data.cell.styles.lineColor = [255,255,255]
        },
      });



      autoTable(doc, {
        head: head,
        styles:  { font: "Barlow-Light", fontStyle: 'normal'},
        columnStyles: { 5: { halign: 'right', cellWidth: 55 }, 2:{halign:'center' } , },
        startY: lastCursor.y,
        body: tableData,
        didDrawPage: (data) => {
          lastCursor = data.cursor
       },
        didDrawCell: (data) => {
        },
      })


      addFooters(doc)




      return doc;
    }
  }
