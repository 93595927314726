<div class="template-inner-wrapper" #splitScreenView >

  <div class="d-flex" style="width: 100%;" *ngIf="showAll">

    <div class="p-0 _splitScreen mr15" style="padding-right: 5px!important;">
      <mat-card class="p0 pl12 pr12 sections_background" >
      <div class="d-flex flex-column">
        <div class="p-0">
          <div class="d-flex" style="height: 56px; overflow: hidden;">
            <div class='p-0 d-flex align-items-center mr-auto' (click)="ui.toggleSplitScreenState(ui.splitScreenStates.CLOSED)">
              <div class="behandlungsschritte-icon pl0"></div>
              <div class="behandlungsschritte mat-title">Behandlungsabschnitte</div>
            </div>
          </div>
        </div>


        <div class="p-0" [ngStyle]='{"height": containerHeight + "px"}' style="overflow-y: scroll;">
          <div class="d-flex flex-column dxDragDrop-list"
            id='reveiverList'
            cdkDropList
            [cdkDropListData]='origin_sections'
            (cdkDropListDropped)="dropSectionObject($event)"
          >

          <div class="p-0 dxDragDrop-box _tr_cp " *ngFor="let oSection of destinationDropList;"
            cdkDrag
            [cdkDragData]='oSection'
            (cdkDragStarted)="onDestinationDragStarted($event)"
          >

          <div class="dxDragDrop-custom-placeholder" *cdkDragPlaceholder></div>

          <mat-expansion-panel style="width: 100%;" hideToggle="true" #panel
            [expanded]="currentDestinationExpansionPanelIndex === oSection.order_index"
            (opened)="openedDestinationPanelIndex(oSection.order_index)"
            (closed)="closedDestinationPanelIndex(oSection.order_index)"
          >
          <mat-expansion-panel-header (click)="panel.toggle()">
            <div class="container-fluid">
              <div class="d-flex align-items-center">

                <div class="p-2 pr16" >
                  <img src="/assets/drag_handle-24px.svg" height="24px">
                </div>

                <div class="p-2 pr16">
                  <div class="circleOpenClose" (click)="panel.toggle()" [class.circleOpenClose_active]='currentDestinationExpansionPanelIndex === oSection.order_index'>
                    <div class="horiz"></div>
                    <div class="vertl"></div>
                  </div>
                </div>

                <div class="p-2">{{oSection.section.name}}</div>

                <div class="p-2 ml-auto">
                  <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon style="color:#7C8B96!important">more_horiz</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" xPosition="before" >
                    <button mat-menu-item (click)="deleteSection(oSection.section);">
                      <span>Löschen</span>
                   </button>
                  </mat-menu>
                </div>

              </div>
            </div>
                </mat-expansion-panel-header>

                <div class="position-colum-header">
                  <div class="d-flex">
                    <div class="p-2" ></div>
                    <div class="p-2">Rechnung</div>
                    <div class="p-2" ></div>
                    <div class="p-2 ">Bericht</div>
                    <div class="p-2 flex-fill" >Maßnahme</div>
                    <div class="p-2 tlc" >Ziffer</div>
                    <div class="p-2" >Anzahl</div>
                    <div class="p-2 ml-auto tle" >Betrag</div>
                    <div class="p-2" ></div>
                  </div>
                </div>

                <div class="position-colum-list" cdkDropList [cdkDropListData]='oSection.section.position' id="{{ oSection.itemId }}" (cdkDropListDropped)="dropPosition($event)">
                  <app-position-copy-item
                  [isOrigin]="false"
                  [section]="oSection.section"></app-position-copy-item>
                </div>
               </mat-expansion-panel>

              </div>
            </div>
        </div>
      </div>
    </mat-card>
    </div>

    <!-- RIGHT -->
    <div class="p-0 _splitScreen" >
      <mat-card class="p0 m0 pl12 pr12" [class.sections_background]="_selectedTemplate != null">
        <div class="d-flex flex-column">

          <div class="p-0" *ngIf="_selectedTemplate" style="height: 56px!important;">
            <div class="d-flex h100 align-items-center" >
              <div class="p-2 userShortCutDoxFox ml20" >
              </div>
              <div class="p-2 " style="font-size: 16px;">{{_selectedTemplate.name}}</div>
            </div>
          </div>

          <div class="p-0" *ngIf="!_selectedTemplate" style="height: 56px!important;" >

            <div class="d-flex">
              <div class="p-2  flex-fill" >
                <mat-form-field class="textfield100 select-form-field" appearance="outline">
                  <input matInput placeholder="Passende Vorlage suchen." (keyup)="applyFilter($event.target.value)">
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="p-0 _tableHeader" *ngIf="!_selectedTemplate" >
            <div class="d-flex p0">

              <div class="p-2" style="width:62px">Ersteller</div>

              <div class="p-2">
                <div class="d-flex p0">
                  <div class="p-0 " style="cursor:pointer;" (click)="toggleTemplateNameSort()">Name</div>
                  <div class="p-0 mt2 ml5">
                    <mat-icon class="tableFilterIcon"  *ngIf="templateNameSort == 'nameDesc'"
                              [class.tableFilterIconActive]="templateNameSort == 'nameDesc'">north</mat-icon>
                    <mat-icon class="tableFilterIcon" *ngIf="templateNameSort == 'nameAsc'"
                              [class.tableFilterIconActive]="templateNameSort == 'nameAsc'">south</mat-icon>
                  </div>
                </div>
              </div>

              <div class="p-2 ml-auto">
                <div class="d-flex p0">
                  <div class="p-0 mt2 mr5">
                    <mat-icon class="tableFilterIcon" *ngIf="templateTimeSort == 'dateDesc'"
                              [class.tableFilterIconActive]="templateTimeSort == 'dateDesc'">south</mat-icon>

                    <mat-icon class="tableFilterIcon" *ngIf="templateTimeSort == 'dateAsc'"
                              [class.tableFilterIconActive]="templateTimeSort == 'dateAsc'">north</mat-icon>
                  </div>
                  <div class="p-0 " style="cursor:pointer;" (click)="toggleTemplateTimeSort()">zuletzt bearbeitet</div>
                </div>
              </div>




            </div>
          </div>

          <div class="p-0" [ngStyle]='{"height": containerHeight + "px"}' style="overflow-y: scroll; ">

            <div class="d-flex flex-column" *ngIf="!_selectedTemplate">

              <div class="p-2 _tr_cp" *ngFor="let t of filteredTemplates" (click)="selectTemplate(t)">
                <div class="d-flex align-items-center">
                  <div class="p-2 userShortCutDoxFox"></div>
                  <div class="p-2">{{t.name}}</div>
                  <div class="p-0 ml-auto clickable lastModifiedCopy" >
                    <div>{{t.lastModified | duration}}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex flex-column dxDragDrop-list" *ngIf="_selectedTemplate"
            cdkDropList
            (cdkDropListDropped)="dropSectionObject($event)"
            [cdkDropListConnectedTo]="['reveiverList']"
            [cdkDropListData]='sections'
            >

            <div class="p-0 dxDragDrop-box _tr_cp"  *ngFor="let section of sections; let i = index"



            cdkDrag
            [cdkDragData]='section'

            >
            <!-- <app-section-copy [section]="section"></app-section-copy> -->

            <div class="dxDragDrop-custom-placeholder" *cdkDragPlaceholder></div>

            <mat-expansion-panel style="width: 100%;" hideToggle="true"
              [expanded]="currenrRemoteExpansionPanelIndex === section.order_index"
              (opened)="openedRemotePanelIndex(section.order_index)"
              (closed)="closedRemotePanelIndex(section.order_index)"
            >
            <mat-expansion-panel-header #panel >
              <div class="container-fluid">
                <div class="d-flex align-items-center">
                  <div class="p-2 pr16">
                    <img src="/assets/drag_handle-24px.svg" height="24px"></div>
                    <div class="p-2 pr16">
                      <div class="circleOpenClose" [class.circleOpenClose_active]='currenrRemoteExpansionPanelIndex === section.order_index'>
                        <div class="horiz"></div>
                        <div class="vertl"></div>
                      </div>
                    </div>
                    <div class="p-2">{{section.name}}</div>
                  </div>
                </div>
              </mat-expansion-panel-header>
              <div class="position-colum-header">
                <div class="d-flex">
                  <div class="p-2" ></div>
                  <div class="p-2">Rechnung</div>
                  <div class="p-2" ></div>
                  <div class="p-2 ">Bericht</div>
                  <div class="p-2 flex-fill" >Maßnahme</div>
                  <div class="p-2 tlc" >Ziffer</div>
                  <div class="p-2" >Anzahl</div>
                  <div class="p-2 ml-auto tle" > </div>
                  <div class="p-2" ></div>
                </div>
              </div>


              <div class="position-colum-list"
              cdkDropList
              [cdkDropListConnectedTo]="destinationIds"

               >
              <app-position-copy-item
              [section]="section"
              [isOrigin]="true"
              (positionDropped)="dropPosition($event)"
              (selectedPosEmitter)="onPositionSelected($event)" ></app-position-copy-item>

            </div>
          </mat-expansion-panel>


              </div>

          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
</div>

<!-- <div class="template-inner-wrapper" >
  <div class="d-flex" style="width: 100%;">
    <div class="p-0 _splitScreen">
      <div class="d-flex">
        <div class='p-2 d-flex align-items-center mr-auto' >
          <div class="behandlungsschritte-icon pl0"></div>
          <div class="behandlungsschritte mat-title">Behandlungsabschnitte</div>
        </div>
      </div>
    </div>
    <div class="p-0 _splitScreen">
      <div class="d-flex flex-column">
        <div class="p-0">
          <div class="d-flex">
            <div class="p-2">
              <mat-icon>close</mat-icon>
            </div>
          </div>
        </div>
        <div class="p-0">
          <div class="d-flex flex-column">
            <div class="p-2 _tr_cp" *ngFor="let t of templates">
              <div class="d-flex align-items-center">
                <div class="p-2 userShortCutDoxFox"></div>
                <div class="p-2">{{t.name}}</div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
