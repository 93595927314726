<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <mat-icon (click)='close()' 
  matTooltip='Abbrechen' 
  class='justification_arrow_icon ml10' style="font-size: 20px!important;">close</mat-icon>
</mat-toolbar>

<div class="container-fluid" style="height: calc(100% - 88px);">

 <div *ngIf="currentAddress">
  <div class="row pl10 p_t15 pr10">
  
    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Name der Praxis/Klinik</mat-label>  
      <input class="formControl mat-body" 
      [class.formControlError]="currentAddress.klinikName == null || currentAddress.klinikName == '' " 
      [(ngModel)]="currentAddress.klinikName">
    </div>
  
    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Abteilung</mat-label>  
      <input class="formControl mat-body" 
      
      [(ngModel)]="currentAddress.abteilung">
    </div>
  
  </div>
  
  <div class="row pl10 p_t15 pr10">
    <div class="col-lg-5 col-md-5 col-sm-12">
      <mat-label class='mat-caption'>Behandelnde(r) Arzt/Ärztin</mat-label>  
      <input class="formControl mat-body formControlDisabled" disabled
  
      [(ngModel)]="ui.account.docName">
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <mat-label class='mat-caption'>Facharztbezeichnung</mat-label>  
      <input class="formControl mat-body formControlDisabled" 
     disabled
      [(ngModel)]="ui.account.doc_description">
    </div>
  
    <div class="col-lg-3 col-md-3 col-sm-6">
      <mat-label class='mat-caption'>Funktion</mat-label>  
      <input class="formControl mat-body" 
      
      [(ngModel)]="currentAddress.arzt_role">
    </div>
  
  </div>
  
  <div class="row pl10 p_t15 pr10">
  
    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Straße und Hausnummer</mat-label>  
      <input class="formControl mat-body" 
      [class.formControlError]="currentAddress.streetName == '' || currentAddress.streetName == null" 
      [(ngModel)]="currentAddress.streetName">
    </div>
  
    <div class="col-lg-2 col-md-2 col-sm-6">
      <mat-label class='mat-caption'>PLZ</mat-label>  
      <input class="formControl mat-body" 
      [class.formControlError]="currentAddress.zip == '' || currentAddress.zip == null" 
      [(ngModel)]="currentAddress.zip">
    </div>
  
    <div class="col-lg-4 col-md-4 col-sm-6">
      <mat-label class='mat-caption'>Stadt</mat-label>  
      <input class="formControl mat-body" 
      [class.formControlError]="currentAddress.city == '' || currentAddress.city == null" 
      [(ngModel)]="currentAddress.city">
    </div>
  
  </div>
  
  <div class="row pl10 p_t25">
    <div class="col-6">
      <h2>Bankverbindung</h2>
    </div>
  </div>
  
  <div class="row pl10 p_t15 pr10">
  
   <!--  [class.formControlError]="currentAddress.kontoinhaber == '' || currentAddress.kontoinhaber == null"  -->
    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Kontoinhaber/in</mat-label>  
      <input class="formControl mat-body" 
     
      [(ngModel)]="currentAddress.kontoinhaber">
    </div>
  
    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>USt-IdNr.</mat-label>  
      <input class="formControl mat-body"
      [(ngModel)]="currentAddress.ust_nr">
    </div>
  
  </div>
  
  <div class="row pl10 p_t15 pr10">
  
    <!-- [class.formControlError]="currentAddress.bank_name == '' || currentAddress.bank_name == null"  -->
    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Bankinstitut</mat-label>  
      <input class="formControl mat-body" 
     
      [(ngModel)]="currentAddress.bank_name">
    </div>
  
    <!-- [class.formControlError]="currentAddress.iban == '' || currentAddress.iban == null"  -->
    <div class="col-lg-3 col-md-3 col-sm-6">
      <mat-label class='mat-caption'>IBAN</mat-label>  
      <input class="formControl mat-body" 
    
      [(ngModel)]="currentAddress.iban">
    </div>
  
    <!-- [class.formControlError]="currentAddress.bic == '' || currentAddress.bic == null"  -->
    <div class="col-lg-3 col-md-3 col-sm-6">
      <mat-label class='mat-caption'>BIC</mat-label>  
      <input class="formControl mat-body" 
      
      [(ngModel)]="currentAddress.bic">
    </div>
  
  </div>
 </div>
</div>

<mat-toolbar class="overlay-toolbar">
<div class="d-flex w100">
  <div class="p-2 ml-auto">
    <app-df-icon-button
    title= "Einstellungen speichern"
    icon="save"
    color="primary"
    (clicked)="save()"
    [disabled]="saveDisabled" 
    >
    </app-df-icon-button>  
    <!-- <button mat-flat-button color='accent' [disabled]='saveDisabled' (click)='save()'>Einstellungen speichern</button> -->
  </div>
</div>
</mat-toolbar>