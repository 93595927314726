<mat-toolbar class="overlay-toolbar">
	<span *ngIf="goaItem">{{goaItem.goa_id}} - {{goaItem.short_description}}</span>
	<span class="toolbar-spacer"></span>
	<mat-icon *ngIf="isHistory" class="dxfx_icon_btn_medium" (click)='hideHistory()'>list</mat-icon>
  <mat-icon *ngIf="!isHistory" class="dxfx_icon_btn_medium" (click)='showHistory()'>history</mat-icon>
	<mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
</mat-toolbar>

<div class="container-fluid p_t20" style="padding-left: 30px!important; padding-right: 30px!important; height: calc(100% - 88px);"  *ngIf='goaItem && !isHistory && mode == "edit" '>

	<div class="row">
		<div class="col-6">
			<mat-label class='mat-caption'>Suchtext</mat-label>
			<input class="formControl mat-body" [(ngModel)]='goaItem.searchString' (ngModelChange)="modelChangeFn($event)">
			<!-- <textarea class="mat-body formControl" rows="2" 
			[(ngModel)]='goaItem.searchString' (ngModelChange)="modelChangeFn($event)"  [disabled]='isLoading'></textarea> -->
		</div>
	</div>
	<div class="row justify-content-between " >
		<div class="col-6">
			<mat-label class='mat-caption'>Kurztext</mat-label>
			<textarea class="mat-body formControl" rows="2" 
			[(ngModel)]='goaItem.short_description' (ngModelChange)="modelChangeFn($event)"  [disabled]='isLoading'></textarea>
		</div>

		<div class="col-3">
			<mat-label class='mat-caption'>Rechnungsfaktor</mat-label>
			<input class="formControl mat-body" [(ngModel)]='goaItem.progression_factor' (ngModelChange)="modelChangeFn($event)">
		</div>

		<div class="col-3">
			<mat-label class='mat-caption'>Betrag</mat-label>
			<input class="formControl mat-body" [(ngModel)]='goaItem.amount_euro' [disabled]='isLoading' (ngModelChange)="modelChangeFn($event)">
		</div>
		
		  
	</div>
	
	<div class="row justify-content-between p_t10">  
		<div class="col-12">
			<mat-label class='mat-caption'>Langtext</mat-label>
			<textarea class="mat-body formControl" rows="3" [(ngModel)]='goaItem.long_description' [disabled]='isLoading' (ngModelChange)="modelChangeFn($event)"></textarea>
		</div>
	</div>
	<!-- <div class="p-2">
		<mat-radio-group *ngIf="isAddRegelMode && !newRegelTypeSelected"
			aria-labelledby="example-radio-group-label"
			class="example-radio-group" (change)="regelTypeChanged($event)"
			[(ngModel)]="selectedRegelType">
			<mat-radio-button class="example-radio-button" *ngFor="let regelType of regelTypes" [value]="regelType">
				{{regelType}}
			</mat-radio-button>
		</mat-radio-group>
	</div> -->
	<div class="row justify-content-between p_t10">  
		<div class="col-12">

			<mat-toolbar class="overlay-toolbar pl10">

				<div class="d-flex w100 align-items-center">

					<!-- REGEL TYPE -->
					<div class="p-2 fs14-400" *ngIf='hasAusschluss'>{{regelCopy}}</div>

					<div class="p-2 flex-fill" *ngIf='isSelectGoaeIdMode'>
						<input class="formControl mat-body" #_goaTF placeholder="Neue GOÄ Maßnahme hinzufügen" (keyup)="applyFilter($event.target.value)" [matAutocomplete]="auto" style="max-width: 100%;">
						<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"  (optionSelected)='goaeSelectChanged($event)' [displayWith]="displayFnGOA" >
							<cdk-virtual-scroll-viewport itemSize="32" style="height: 250px;" >
								<mat-option style="height: 32px!important;" *cdkVirtualFor="let option of filtereGoaeOptions" [value]="option" class="mat-body">{{option.name}}</mat-option>  
							</cdk-virtual-scroll-viewport>
						</mat-autocomplete>
					</div>

					<div class="p-0" *ngIf="!hasAusschluss && isAddRegelMode && !newRegelTypeSelected">
						<mat-button-toggle-group (change)="regelTypeChanged($event)" [(ngModel)]="selectedRegelType">
							<mat-button-toggle  *ngFor="let regelType of regelTypes" [value]="regelType" >{{regelType.viewValue}}</mat-button-toggle>
						</mat-button-toggle-group>
					</div>
					<div class="p-0 ml-auto"></div>

					<div class="p-2" *ngIf='selectedGoae != null && isSelectGoaeIdMode'>
						<app-df-icon-button color="primary" title="Einfügen" icon="arrow_downward" (clicked)="addSelectedAusschl()"></app-df-icon-button>
					</div>
					<div class="p-2" *ngIf='hasAusschluss && !isSelectGoaeIdMode'>
						<app-df-icon-button color="primary" title="Ziffer hinzufügen" icon="add" (clicked)="addGoaeAussschluss()"></app-df-icon-button>
					</div>

					<div class="p-2" *ngIf='isSelectGoaeIdMode'>
						<app-df-icon-button  title="Abbrechen" icon="cancel" (clicked)="clearSelectedGoae()"></app-df-icon-button>
					</div>

					<div class="p-2" *ngIf='!hasAusschluss && !newRegelTypeSelected && !isAddRegelMode'>
						<app-df-icon-button color="primary" title="Regel hinzufügen" icon="add" (clicked)="addRegel()"></app-df-icon-button>
					</div>

					<div class="p-0" *ngIf="hasAddRegel">
						<app-df-icon-button color="primary" title="Regel löschen" icon="cancel" (clicked)="clearAddNewRegel()"></app-df-icon-button>
					</div>
					
					
				
					<!-- <div class="p-0 ml-auto">
						<app-df-icon-button *ngIf="!newRegelTypeSelected && !isAddRegelMode"
							color="primary"
							title="Regel hinzufügen"
							icon="add"
							(clicked)="addRegel()"
							>
						</app-df-icon-button>
					</div> -->

					<!-- <div class="p-0">
						<app-df-icon-button *ngIf="!newRegelTypeSelected && isAddRegelMode"
							color="primary"
							title="Abbrechen"
							icon="cancel"
							(clicked)="clearAddRegel()"
							>
						</app-df-icon-button>
					</div> -->
				</div>

			</mat-toolbar>


	</div>

	<div class="col-12">
		<mat-toolbar class="overlay-toolbar" *ngIf='errorMessage' color='warn'>
			<div class="container-fluid p0">
				<div class="d-flex align-items-center">
					<div class="p-2">
						{{errorMessage}}
					</div>
					<div class="p-2 ml-auto">
						<button mat-flat-button color='warn' class="mr10" (click)='deleteSelectedAusschluss()'>Entfernen</button>
						<button mat-flat-button color='basic' (click)='cancelDeleteAusschluss()'>Abbrechen</button>
					</div>
				</div>
			</div>
		</mat-toolbar>
	</div>

		
	<div class="col-12 p_t20" *ngIf="hasAusschluss">
		<mat-chip-list #chipList aria-label="Fruit selection" *ngIf="goaItem.ausschluss_liste && goaItem.ausschluss_liste.length > 0">
			<mat-chip *ngFor="let item of goaItem.ausschluss_liste" [selectable]="selectable" [disabled]='isLoading' [removable]="removable" (removed)="removeGOAE_Ausschluss(item)">
			{{item}}
			<mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
			</mat-chip>
		</mat-chip-list>
	</div>
		
	
	</div>
</div>


<mat-toolbar class="overlay-toolbar" *ngIf='goaItem != null && !isHistory'>
	<span class="addMessage" [class.addMessageIn]="addMessageIn" [class.addMessageOut]="!addMessageIn">{{addMessage}}</span>
	<span class="toolbar-spacer"></span>
	<app-df-icon-button *ngIf="!isLoading" title="Abbrechen" icon="cancel" (clicked)="close()"></app-df-icon-button>  
	<app-df-icon-button *ngIf="!isLoading" title="Speichern" color="primary" icon="save" [disabled]="!hasChanged" (clicked)="saveItem()"></app-df-icon-button>  
</mat-toolbar>

<div class="container-fluid p_t20" style="padding-left: 30px!important; padding-right: 30px!important;"  *ngIf='goaItem && isHistory'>
	<div class="changesContainer" style="height: 300px; overflow-y: scroll;">
		<div class="container-fluid p0" *ngIf='changes'>
			<mat-label class="mat-caption">Änderungen</mat-label>

						<div class="d-flex p0 changesItem"  *ngFor="let changesInfo of changes; let i = index;"  
						matTooltipPosition='left' matTooltipClass='sectionGoaeTT'  matTooltip='{{changesInfo.changesCopy}}'>
							<div class="p-2 flex-fill">
								{{getDateFromTimeStamp(changesInfo.lastModified) | date: 'dd.MM.yyyy HH:mm'}}
		
							</div>
							<div class="p-2 ml-auto"  >
								<mat-icon>info</mat-icon>
							</div>
							
						</div>
					
		</div>	
		</div>
</div>

<div class="container-fluid" style="padding-left: 30px!important; padding-right: 30px!important; height: calc(100% - 40px);"  *ngIf='!isHistory && mode == "add" '>
	<div class="d-flex flex-column h100 justify-content-center align-items-center " >
		<div class="p-2">
			<mat-label class='mat-caption'>Neue Goä Ziffer</mat-label>
			<input class="formControl mat-body" placeholder="Neue Ziffer" (keyup)="checkNewGoaeId($event)"  [disabled]='isLoading'>
		</div>
		<div class="p-2">
			<app-df-icon-button
			color="primary"
			title="Verwenden"
			icon="save"
			(clicked)="addNewOwnGoaId()"
			[disabled]="!canAddNewId"
			>
		</app-df-icon-button>  
		</div>
	</div>
</div>

