import { Injectable, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { apiRequest, API_CALL } from '../core/models/api/apiCalls';
import { TemplateFlatI } from '../core/models/api/TemplateFlatI';
import { template_item_text_tiles } from '../core/models/api/templateUtils';
import { BASE_TEMPLATE_TYPE, POSITION_TYPE, ProcessesSort } from '../core/models/enums';
import { GoaeConflictI, GoaeConflictType, PositionErrorI } from '../core/models/goaeAusschlussI';
import { IProcessState } from '../core/models/iProcessState';
import { PatientFull } from '../core/models/patient';
import { SectionM } from '../core/models/sectionM';
import { PositionFlatI, ProgressionFactorItem } from '../core/models/sectionPositionM';
import { TREATMENT_FILTER, TREATMENT_FILTER_LEVEL } from '../core/models/treatmentRegionM';
import { ApiUtilService } from './api-util.service';
import { TEMPLATES_CALL, TEMPLATE_API_PATH } from './enums/templateS.callEnum';
import {  SectionApiService } from './section-api.service';
import { TemplateApiService } from './template-api.service';
import { OverviewProcessI } from '../core/models/v4/processI';
import { IOProcessStateMessage, SocketService } from './socket.service';
import { T_POSITION_CALL } from './enums/positionS.call';
import { AreaOfExpertiseUserI } from '../core/models/expertiseArea';
import { SectionProgressionFactors, CustomProgressionFactorI } from '../core/models/customPositionProgressionFactor';
import { SectionMaterialI, MaterialI } from '../core/models/materialI';
import { SurgerySectionI } from '../core/models/v4/surgeryPositionI';
import { PositionApiService } from './position-api.service';
import { SurgeryReportItenV4I } from '../core/models/v3/SurgeryReportItemIV3';
import { SurgeryApiService } from './surgery-api.service';
import { SurgerySupportPersonI } from '../core/models/v4/surgery.personi';
import { AddressI, TemplatePoolFilterItemUser } from '../core/models/accountI';
import { SECTION_ENDPOINT } from './enums/sectionEndpoints.enum';

interface processResponse {
  status: string
  value?: TemplateFlatI
}

@Injectable({
  providedIn: 'root',
})
export class TemplateControllerService   {

  processReloaded:EventEmitter<void> = new EventEmitter<void>();
  goaJustigicatiionUpdate:EventEmitter<boolean> = new EventEmitter<boolean>();
  currentProcessLoaded:EventEmitter<boolean> = new EventEmitter<boolean>();
  currentTemplateLoaded:EventEmitter<boolean> = new EventEmitter<boolean>();
  errorTemplateLoading:EventEmitter<string> = new EventEmitter<string>();
  errorProcessLoading:EventEmitter<string> = new EventEmitter<string>();
  splitScreencontrollerPositionClipboard:EventEmitter<PositionFlatI | undefined> = new EventEmitter<PositionFlatI | undefined>()

  private _errorItems:PositionErrorI[] = [];
  private currentUid: string;
  private _overviewFilterString: string = ""
  private _currentTemplateId: number
  private _currentTemplate: TemplateFlatI
  private _progressionFactors:ProgressionFactorItem[];
  private _processList: OverviewProcessI[] = []
  private _filteredProcesses: OverviewProcessI[] = []
  private _tempalteTilesALl: template_item_text_tiles[] = []
  private _tempalteNotes: template_item_text_tiles[] = []
  private _tempalteMethods: template_item_text_tiles[] = []
  private _tempalteDiagnoses: template_item_text_tiles[] = []

  private  _templates: TemplateFlatI[] = [];
  private _filteredTemplates: TemplateFlatI[] = [];
  pSort:ProcessesSort = ProcessesSort.DATE_DESC
  isClinicUser:boolean = false
  private _baseTemplateType: BASE_TEMPLATE_TYPE = BASE_TEMPLATE_TYPE.NONE
  private _processStates:IProcessState[] = []

  constructor(
    public templateApi:TemplateApiService,
    private apiUtil:ApiUtilService,
    private sectApi:SectionApiService,
    //  sectionApi:SectionApiService,
    private posApi:PositionApiService,
    private ss: SocketService,
    private api:SurgeryApiService
    // surgeryAPI: SurgeryApiService,
    ) {
      // super(sectionApi, positionApi, surgeryAPI);
      console.log('>>> instance of TC <<<<<')
     }

  SIGN_OUT(){
    this._currentTemplate = null;
    this._tempalteNotes = [];
    this._tempalteMethods = [];
    this._tempalteDiagnoses = [];
    this.currentUid = null
    // this.clearSections()
    // this.clearPositions()
    // this.clearSurgery()

    this._currentSections = []
    this._sectionFactors = []
    this._matItems = []
    this._surgeryReportPosItems = []
  }
  
  //---------------------------------------------------------------------------------------
  get isTemplateEditable(): boolean {
    if (!this._currentTemplate) {
      return false
    }
    return this._currentTemplate.isEditable
  }

  //---------------------------------------------------------------------------------------
  // get showPrintButtonsIn(): boolean{
  //   return false
  // }
  //---------------------------------------------------------------------------------------
  // get canPrintInvoicePreview(): boolean {
  //   if(this._baseTemplateType === BASE_TEMPLATE_TYPE.PROCESS) {
  //     return true;
  //   }
  //   return false
  // }

  //---------------------------------------------------------------------------------------
  get templateHasInvoice(): boolean {
    if(this._baseTemplateType === BASE_TEMPLATE_TYPE.PROCESS && this._currentTemplate != null && this._currentTemplate.invoice_id != null && this._currentTemplate.invoice_id > 0 && this._currentTemplate.invoice_id != -1) {
      return true;
    }
    return false
  }

  //---------------------------------------------------------------------------------------
  get templateHasSurgeryReport(): boolean {
    if(this._baseTemplateType === BASE_TEMPLATE_TYPE.PROCESS && this._currentTemplate != null && this._currentTemplate.surgery_report_id != null && this._currentTemplate.surgery_report_id > 0) {
      return true;
    }
    return false
  }
  
  //---------------------------------------------------------------------------------------
  get prefix() : string {
      return this._baseTemplateType == BASE_TEMPLATE_TYPE.PROCESS ? 'processes' : 'templates'
  }

  //---------------------------------------------------------------------------------------
  async initProcessById(template_id:number) {
    const _message =  await this.templateApi.apiGet(TEMPLATES_CALL.PROCESS_DETAIL,template_id.toString())
    let response = _message as processResponse
    console.log(response);
        if(response.status == 'ACCESS DENIED'){
        this.errorTemplateLoading.emit('ACCESS DENIED')
        return
      }
    this._currentTemplateId = template_id;
    setTimeout(()=>{
      // this._currentProcess = _message as IPROCESS
      this._currentTemplate = response.value// as TemplateFlatI
      this._baseTemplateType = BASE_TEMPLATE_TYPE.PROCESS;
      },200)
      setTimeout(()=>{
        this.checkForConflicts();
        // this.currentTemplateLoaded.emit(true);
        this.currentProcessLoaded.emit(true);
        
      },800)
      setTimeout(()=>{
        this.getSections(template_id, this.prefix);
        this.initTemplatePositions(template_id, this.prefix);
      },400)
      
  }

  /**
   *
   * TEMPLATE INIT
   *
  **/


  async initTemplateById(template_id:number, asProcess:boolean = false)  {
    this._currentTemplateId = template_id;
    if(!asProcess){

      // const _message =  await this.templateApi.templateDetail(template_id)
      const _message =  await this.templateApi.apiGet(TEMPLATES_CALL.TEMPLATE_DETAIL,template_id.toString())
      
      if(_message == 'ACCESS DENIED'){
        this.errorTemplateLoading.emit('ACCESS DENIED')
        return
      }
      setTimeout(()=>{
        this._currentTemplate = _message as TemplateFlatI
        this._baseTemplateType = BASE_TEMPLATE_TYPE.TEMPLATE;

        this.check_template_item_text_tilesAll()
      //   console.log(this._currentTemplate.sections);
      //   console.log(this._currentTemplate.positions);
      this.setSections(this._currentTemplate.sections)
      this.setTemplatePositions(this._currentTemplate.positions)  
        },200)
        setTimeout(()=>{
          this.checkForConflicts();
          this.currentTemplateLoaded.emit(true);
        }, 800)
      
       //this.reInitTemplateSectionsAndPositions();


    } else {
      //  let _loaded:TemplateM
   
     }

  }

  reInitTemplateSectionsAndPositions(onlyPositions:boolean = false){
    setTimeout(()=>{
      if(onlyPositions){
        this.initTemplatePositions(this._currentTemplateId, this.prefix);
      } else {
        this.initTemplatePositions(this._currentTemplateId,this.prefix);
        this.getSections(this._currentTemplateId,this.prefix);
      }
    },400)
  }
  
  async processDateChanged(e){
     this._currentTemplate.date = e.getTime()
     if(this.baseTemplateType == BASE_TEMPLATE_TYPE.TEMPLATE){
      
      await this.updateTemplateItem({date: this._currentTemplate.date});
    } else if(this.baseTemplateType == BASE_TEMPLATE_TYPE.PROCESS){
      await this.updateProcessItem({date: this._currentTemplate.date});
    } 

  }


  //---------------------------------------------------------------------------------------
  async destroyTemplateById(templateId?:string)  {
    this._currentTemplate = null;
    this._tempalteNotes = [];
    this._tempalteMethods = [];
    this._tempalteDiagnoses = [];
    this._templatePositions = []
    this._matItems = []
    // this.clearSections()
    // this.clearPositions()
    // this.clearSurgery()
  }
  
  get tempalteTilesAll(): template_item_text_tiles[] {
    return this._tempalteTilesALl
  }
  ///---------------------------------------------------------------------------------------
  async check_template_item_text_tilesAll() {
    
    const _data = await this.templateApi.getTemplateTextTilesAll(this._currentTemplateId, this.prefix).then(
      _notesResponse => {
        if(_notesResponse && _notesResponse['answer']
          && _notesResponse['answer'] == 'finished' && _notesResponse['data']){
            this._tempalteTilesALl = _notesResponse['data'] as template_item_text_tiles[]
            return "finished";
        } else {
          return "error"
        }
      }
    )
    return _data;
  }
  async check_template_item_text_tiles(type:string) {
    
    const _data = await this.templateApi.getTemplateTextTileFor(type, this._currentTemplateId, this.prefix).then(
      _notesResponse => {
        if(_notesResponse && _notesResponse['answer']
          && _notesResponse['answer'] == 'finished' && _notesResponse['data']){
            this._addItemsFor(type, _notesResponse['data'])
            return "finished";
        } else {
          return "error"
        }
      }
    )
    return _data;
  }

//---------------------------------------------------------------------------------------
  private _addItemsFor(type:string, items:template_item_text_tiles[]){
    switch (type) {
      case "NOTE":
        this._tempalteNotes = items;
        return
      case "DIAGNOSE":
        this._tempalteDiagnoses = items;
        return
      case "THERAPY_METHOD":
        this._tempalteMethods = items;
        return
    }
  }

  //---------------------------------------------------------------------------------------
  getTemplate_item_text_tiles_by_type(type:string): template_item_text_tiles[]{
    if(!this._currentTemplate) return []

    switch (type) {
      case "NOTE":
        return  this._tempalteNotes.sort((a,b) => a.text_tile_id - b.text_tile_id)
      case "DIAGNOSE":
        return  this._tempalteDiagnoses.sort((a,b) => a.text_tile_id - b.text_tile_id)
      case "THERAPY_METHOD":
        return  this._tempalteMethods.sort((a,b) => a.text_tile_id - b.text_tile_id)
    }
    return []
  }

  //---------------------------------------------------------------------------------------
  async add_new_item_text_tile(type:string, description: string, text: string){
    const output = await this.templateApi.addTextItemTileFor(type, this._currentTemplateId, this.prefix, description, text).then(
      _data => {
        this.check_template_item_text_tiles(type);
      }
    )
    // this.templateModified(this._currentTemplateId);
    return output;
  }
  //---------------------------------------------------------------------------------------
  async update_item_text_tile(type:string, docId:string, item:template_item_text_tiles ){
    const output = await this.templateApi.updateTextItemTileFor(item, this.prefix).then(
      _data => {
        return this.check_template_item_text_tiles(type);
      }
    )
    // this.templateModified(this._currentTemplateId);
    return await output;
  }
  //---------------------------------------------------------------------------------------
  async delete_item_text_tile(type:string, docId:string, item:template_item_text_tiles ){
    const output = await this.templateApi.deleteTextItemTileFor(item, this.prefix).then(
      _data => {
        return this.check_template_item_text_tiles(type);
      }
    )
    // this.templateModified(this._currentTemplateId);
    return await output;
  }
//---------------------------------------------------------------------------------------
  private _isOneOfGoaeIdsActivated(goaeId:string[], positions:PositionFlatI[]):boolean {
    for (const _posM of positions){
      if(_posM.position_type == POSITION_TYPE.GOA && _posM.goa_paragraph != null && _posM.activated && goaeId.includes(_posM.goa_paragraph.goa_id)){
        return true;
      }
    }
    return false;
  }
  private _hasActicatePositionWithGoaeId(goaeId:string[], positions:PositionFlatI[]):string {
    for (const _posM of positions) {
      if(_posM.position_type == POSITION_TYPE.GOA && _posM.goa_paragraph != null && _posM.activated && goaeId.includes(_posM.goa_paragraph.goa_id)){
        return _posM.goa_paragraph.goa_id;
      }
    }
    return null;
  }

  async checkForConflicts() {
    this._errorItems = []
    const _sections:SectionM[] = this.currentSections

    for(var i = 0; i < _sections.length; i ++){
      const _positions:PositionFlatI[] = this.templatePositions.filter((item) => { return item.section_id == _sections[i].section_id})
      for (var j=0; j < _positions.length; j++) {
        let _p:PositionFlatI = _positions[j];
        if(_p.position_type == POSITION_TYPE.GOA && _p.goa_paragraph != null){
          if(_p.goa_paragraph.ausschluss_liste && _p.goa_paragraph.ausschluss_type
            && _p.goa_paragraph.ausschluss_type != 'none'){
              let conflict: GoaeConflictI = {
                ref_goa_id: _p.goa_paragraph.documentId,
                type: _p.goa_paragraph.ausschluss_type,
                items: _p.goa_paragraph.ausschluss_liste
              }
              let _errorText:string = ''
              switch (conflict.type) {
                case GoaeConflictType.only_allowed_with:
                  if(!this._isOneOfGoaeIdsActivated(conflict.items, _positions) && _p.activated && conflict.items.length > 0){
                    const _errorModel:PositionErrorI = {
                      section_id: _sections[i].section_id,
                      errorMessage: '',
                      position_id: _p.position_id,
                    }
                    _errorText = "Darf nur allein oder zusammen mit einer der folgenden Ziffern abgerechnet werden: " ;
                    for (const  textItem of conflict.items) {
                      _errorText += textItem + ", "
                    }

                    // _p.errorModel.errorMessage =  _errorText;
                    // _p.hasError = true;
                    _errorModel.errorMessage = _errorText;
                    this._errorItems.push(_errorModel)
                  }
                  break;

                  case GoaeConflictType.exclusion:
                    let _errorID = this._hasActicatePositionWithGoaeId(conflict.items, _positions)
                    if(!_p.activated){
                      break;
                    }

                    if(_errorID != null) {

                      _errorText =  "Kann nicht zusammen mit Ziffer " + _errorID  + " abgerechnet werden"

                      const _errorModel:PositionErrorI = {
                        section_id: _sections[i].section_id,
                        errorMessage: _errorText,
                        position_id: _p.position_id,
                      }
                      this._errorItems.push(_errorModel)
                    }
                  break;
              }

          }

        }
      }
    }

    // this._setReady();
  }


  // private _setReady(){
  //   this.currentTemplateLoaded.emit(true);
  // }

  get processStates():IProcessState[] {
    return this._processStates.sort((a,b)=> a.order_index - b.order_index)
  }


   async IPROCESSnvoiceAddressIdChanged(newId:number){
     this._currentTemplate.address_id = newId
     await this.updateProcessItem({address_id:newId})
    //  this.templateModified(this._currentTemplateId);
   }


  getLatesDiagnose():string {
   var out = ""

    let currentIndex = 0

    if(this._tempalteDiagnoses.length > 0) {
      let _lastModified = 0
      let _newIndex = 0

      for (var i = 0; i < this._tempalteDiagnoses.length; i ++) {
        if(this._tempalteDiagnoses[i].lastModified > _lastModified){
          _lastModified = this._tempalteDiagnoses[i].lastModified
          _newIndex = i
        }

      }
      currentIndex = _newIndex
      out = this._tempalteDiagnoses[currentIndex].text
    } else {
     currentIndex = 0;
     out = ""
    }
    return out

  }

  getLatesTherapie():string {
  var out = ""

    let currentIndex = 0

    if(this._tempalteMethods.length > 0) {
      let _lastModified = 0
      let _newIndex = 0

      for (var i = 0; i < this._tempalteMethods.length; i ++) {
        if(this._tempalteMethods[i].lastModified > _lastModified){
          _lastModified = this._tempalteMethods[i].lastModified
          _newIndex = i
        }

      }
      currentIndex = _newIndex
      out = this._tempalteMethods[currentIndex].text
    } else {
    currentIndex = 0;
    out = ""
    }
    return out

  }

  //---------------------------------------------------------------------------------------
  async renameTemplate(_newText:string, template_id:number) {
    await this.templateApi.apiPostObjectAndParams(TEMPLATES_CALL.RENAME_TEMPLATE, template_id.toString(), { newName: _newText} )
  }
  
  //---------------------------------------------------------------------------------------
  async moveTemplateToProcesses(template_id:number){
   const _res = await this.templateApi.apiGet(TEMPLATES_CALL.ADD_TO_PROCESS,template_id.toString())
   return _res;
  }
  
  //---------------------------------------------------------------------------------------
  async updatePosition(p:PositionFlatI, template_id:number) {
    await this.posApi.positionsPOST(this.prefix, T_POSITION_CALL.POSITION_UPDATE, {position:p}, "")
    this.itemsUpdated.emit();
  }
//---------------------------------------------------------------------------------------
  getProcessState(): IProcessState {
    if(this._currentTemplate == null) { return this._processStates[0]}

  //  const _state = this._currentTemplate.status
    const _index = this._processStates.findIndex((item)=> item.id === this._currentTemplate.status_id);
    return _index == -1  ? this._processStates[0] : this._processStates[_index];
  }

  //---------------------------------------------------------------------------------------
   async processStateChanged(state_id: number, process_id:number, dto: Object){
     const _res = await this.templateApi.changeProcessState('processes', state_id, process_id, dto);
    //  this.ss.changeProcessState()
    // this.templateModified(process_id);
   }
  //---------------------------------------------------------------------------------------
  async processStateHistory(process_id:number){
    const _res = await this.templateApi.processStateHistory(process_id, this.prefix);
    return _res
  }
  
  //---------------------------------------------------------------------------------------
  async loadPState(){
    const _states = await this.templateApi.loadPState(this.prefix)
    .then(_res => {
      return _res;
    })
    this._processStates = _states as IProcessState[]
  }

  //---------------------------------------------------------------------------------------
  async cloneTemplate(template_id:number, item:Object){
    const _r = await this.templateApi.apiPostObjectAndParams(TEMPLATES_CALL.CLONE_PUBLIC,
      template_id.toString(), item)
    return _r
  }
  async duplicateTemplate(template_id:number, item:Object){
    const _r = await this.templateApi.apiPostObjectAndParams(TEMPLATES_CALL.DUPLICATE,
      template_id.toString(), item)
    return _r
  }

  
  //---------------------------------------------------------------------------------------
  async updateTemplateItem(item:Object){
    // console.log(`updateTemplateItem ${Object.keys(item)[0]}`)
    if(this._currentTemplateId) {
      const _r = await this.templateApi.apiPostObjectAndParams(TEMPLATES_CALL.UPDATE_SINGLE_TEMPLATE_NODE,this._currentTemplateId.toString(), {item:item})
      this.itemsUpdated.emit();
    } else {
      console.log("ERROR no template id")
    }
  
    //this.templateModified(this._currentTemplateId);
  }
  //---------------------------------------------------------------------------------------
  async updateProcessItem(item:Object){
    const _r = await this.templateApi.apiPostObjectAndParams(TEMPLATES_CALL.UPDATE_SINGLE_PROCESS_NODE,this._currentTemplateId.toString(), {item:item})
  }
 //---------------------------------------------------------------------------------------
  initPogressionFactors(forceReload:boolean = false){
    if(forceReload){
      this.loadProgressionFactors();
      return
    }
    if(this._progressionFactors == null || this._progressionFactors.length == 0){
      this.loadProgressionFactors();
    }
  }

  //---------------------------------------------------------------------------------------
  private async loadProgressionFactors(){
    const _res = await this.templateApi.loadProgressionFactors();
    this._progressionFactors = _res as ProgressionFactorItem[]
  }

  //---------------------------------------------------------------------------------------
  async addGoaJustification(position_id:number, item:Object){
    var obj = item
    Object.assign(obj, {template_id: this._currentTemplate.template_id})    
    const _res = await this.posApi.addGoaJustification(this.prefix,position_id, obj);
    this.goaJustigicatiionUpdate.emit(true)
    return _res;
  }

  //---------------------------------------------------------------------------------------
  async updateGoaJustification(goa_justification_id:number, item: object){
    const _res = await this.posApi.updateGoaJustification(this.prefix,goa_justification_id, item);
    this.goaJustigicatiionUpdate.emit(true)
    return _res;
  }

  //---------------------------------------------------------------------------------------
  async deleteGOAJustificationById(id:number){
    const _res = await this.posApi.deleteGOAJustificationById(this.prefix,id);
    this.goaJustigicatiionUpdate.emit(true)
    return _res;
  }

  //---------------------------------------------------------------------------------------
  async getUserBillingCompanies() {
    const _r = await this.apiUtil.apiGetP(apiRequest(API_CALL.GET_BILLING_COMPANIES_BY_UID))
    return _r;
  }

  //---------------------------------------------------------------------------------------
  async getInvoicePreviewItems(processId:number) {
    const _r = await this.templateApi.getInvoicePreviewItems(processId);
    return _r;
  }

  async getFullInvoiceAddress(processId:number): Promise<AddressI> {
    const _r = await this.templateApi.getFullInvoiceAddress(processId);
    if(_r != null) {
      if(_r['status'] && _r['status'] == 'ERROR') {
        return null
      } else {
        return _r as AddressI
      }
    } else {
      return null
    }
    
  }
  //---------------------------------------------------------------------------------------
  async unlockInvoice(template_id:number){
    const _res = await this.templateApi.unlockInvoice(template_id)
    if(this._currentTemplate.template_id == template_id) {
      this._currentTemplate.invoice_id = null
    }
    return _res;
  }

  //---------------------------------------------------------------------------------------
  async toggleAreaOfExpertise(id:number){
    await this.apiUtil.apiGetP(apiRequest(API_CALL.TOGGLE_AREAS_OF_EXPERTISE_BY_ID, id.toString()))
  }
  async padnextFachAll(){
    const _path = apiRequest(API_CALL.GET_PAD_NEXT_FACH_ALL)
    const _data = await this.apiUtil.apiGetP(_path);
    return _data;
  }
  async padnextFachForUid(facharzt_pad_next_fachgebiete_id: number){
    const _path = apiRequest(API_CALL.POST_PAD_NEXT_FACH_FOR_UID)
    const _data = await this.apiUtil.apiPost(_path, {facharzt_pad_next_fachgebiete_id: facharzt_pad_next_fachgebiete_id});
    return _data;
  }

  async areasOfExpertise(){
    const _path = apiRequest(API_CALL.GET_AREAS_OF_EXPERTISE)
    const _data = await this.apiUtil.apiGetP(_path);
    return _data;
  }

  async user_reatment_filters_all(){
    const _path = apiRequest(API_CALL.GET_TREATMENT_FILTERS_FOR_USER);
    const _data = await this.apiUtil.apiGetP(_path);
    return _data;
  }
  // async treatment_regions2(){
  //   const _path = apiRequest(API_CALL.GET_TREATMENT_REGIONS);
  //   const _data = await this.apiUtil.apiGetP(_path);
  //   return _data;
  // }
  async treatment_types_for_user(){
    const _path = apiRequest(API_CALL.GET_TREATMENT_TYPE_FOR_USER);
    const _data = await this.apiUtil.apiGetP(_path);
    return _data;
  }

  async user_reatment_filters_active(){
    const _path = apiRequest(API_CALL.GET_ACTIVE_TREATMENT_FILTER_IDS);
    const _data = await this.apiUtil.apiGetP(_path);
    return _data;
  }

  async update_user_reatment_filters_active(allFilter: TREATMENT_FILTER[]){
    const allFilterIds = _.map(allFilter.filter(item => item.selected ),'id')
    // console.log('allFilterIds', allFilterIds);
    const _path = apiRequest(API_CALL.POST_ACTIVE_TREATMENT_FILTER_IDS);
    const _data = await this.apiUtil.apiPost(_path, {payload: allFilterIds.toString()})
    return _data;
  }



  /**
   *
   * TEMP GET SINGLE PATIENT
   *
   */
   async getSinglePatientFull(patId:any) {
    const _result:any = await this.apiUtil.apiGetP(apiRequest(API_CALL.GET_SINGLE_PATIENT_FULL, patId.toString()))
    const _resPat = _result['result'] != null && _result['result'][0] != null ? _result['result'][0] : null
    const _patientFull = _resPat as PatientFull
    // const _path = environment.endpoints.apiBasePathV2 + 'patients/single/'+patId
    // const _result=  await this.http.get(_path).toPromise()
    // console.log('getSingle > result', _result)
    return _patientFull//_result['result'] != null && _result['result'][0] != null ? _result['result'][0] : null
  }

  /**
   *
   * PROCESS ACCESS
   *
   */

  async processesFiltered(call:TEMPLATES_CALL, payload:Object){
    const _path = TEMPLATE_API_PATH(call)
    const _res = await this.templateApi.apiPostObject(call, payload)
    if (_res['status'] && _res['status'] == "ERROR" && _res['type']){
      // ControllerService.sessionTimeoutEmitter.emit(_res['type'])
      return;
    }
    this._processList = _res as OverviewProcessI[]
  
    this.processReloaded.emit()  
    
    return _res
  }
  // async processSingle(call:TEMPLATES_CALL){
  //   const _path = TEMPLATE_API_PATH(call)
  //   const _res = await this.templateApi.apiGet(call)
 
   
    
  //   return _res
  // }


  get processes():OverviewProcessI[] {
    if(this._overviewFilterString.trim().length > 2) {
        return _.filter(this._filteredProcesses,
    item => item.firstName?.toLowerCase().includes(this._overviewFilterString.toLowerCase())
    || item.lastName?.toLowerCase().includes(this._overviewFilterString.toLowerCase())
    || item.name.toLowerCase().includes(this._overviewFilterString.toLowerCase())
    || item.clinicProcessNumber?.toString().includes(this._overviewFilterString.toLowerCase())
    )
    } else {
      return this._filteredProcesses
    }
  }


  async processStateChangedEmitted(changed:IOProcessStateMessage, processFilter:IProcessState[], isAccountant:boolean) {
    const index = this._filteredProcesses.findIndex(item => item.template_id == changed.template_id)
    console.log('index ', index);
    if(index != -1) {
        
      this._filteredProcesses.splice(index, 1)
     }
    const _res = await this.templateApi.apiGet(TEMPLATES_CALL.PROCESSES_OVERVIEW_SINGLE, changed.template_id.toString())
    console.log('_res ', _res)
    const _process = _res as OverviewProcessI
    if(_process) {
      if(this.currentUid != null && this.currentUid != '' && this.currentUid === _process.assignee_uid && _process.status_id == 3) {

      } else {
        if (isAccountant) {
          if(_process.status_id == 1) {

          } else if(_process.status_id == 2) {
            console.log('_process ', _process);
            this._processList.push(_process)
            this.sortProcesses(this.pSort, this.isClinicUser)
          } else if((_process.status_id == 3 && this.currentUid === _process.assignee_uid) || _process.status_id == 4) {
            console.log('_process ', _process);
            this._processList.push(_process)
            this.sortProcesses(this.pSort, this.isClinicUser)
          }
        } else {
          console.log('_process ', _process);
          this._processList.push(_process)
          this.sortProcesses(this.pSort, this.isClinicUser)
        }
        

      }
    }
    
  }


  async loadNewProcess(template_id:number, processFilter:IProcessState[],isAccountant:boolean) {
    const _res = await this.templateApi.apiGet(TEMPLATES_CALL.PROCESSES_OVERVIEW_SINGLE, template_id.toString())
      // const result = await this.processSingle(TEMPLATES_CALL.PROCESSES_OVERVIEW_SINGLE, )
      const _process = _res as OverviewProcessI
      console.log('load new process ', template_id);
      
      // this._filteredProcesses[index].status_id = changed.state_id
      if (isAccountant && _process.status_id == 1) {
     
      } else {
        this._filteredProcesses.push(_process)
      this.sortProcesses(this.pSort, this.isClinicUser)
      }
      
  }

  
  //----------------------------------------------------------------------------------------
  sortProcesses(pSort:ProcessesSort, isClinicUser:boolean){
  this.pSort = pSort
  this.isClinicUser = isClinicUser
  // console.log('this.filteredProcesses', this._filteredProcesses);

  if(!this._processList || this._processList.length == 0) {
    return
  }
  switch(pSort){
    case ProcessesSort.DATE_ASC:
      this._filteredProcesses = this._processList.sort((a, b) => a.date - b.date)
      // _.orderBy(this.processes, [
      //   template => template.date
      // ], ['asc']);
      break;
    case ProcessesSort.DATE_DESC:
      this._filteredProcesses = this._processList.sort((a, b) => b.date - a.date)
      // this.filteredProcesses = _.orderBy(this.processes, [
      // ], ['desc']);
      // template => template.date
        break;
    case ProcessesSort.PATIENT_ASC:
      if ( !isClinicUser) {
        this._filteredProcesses = _.orderBy(this._processList, [
          template => template.lastName?.toLowerCase(),
          template => template.firstName?.toLowerCase()
        ], ['asc']);
      } else {
        
                this._filteredProcesses = _.orderBy(this._processList, [
        template => template.clinicProcessNumber
      ], ['asc']);
        //   'clinicProcessNumber'
        // ], ['asc']);
      }

    break;
    case ProcessesSort.PATIENT_DESC:
      if ( !isClinicUser) {
      this._filteredProcesses = _.orderBy(this._processList, [
        template => template.lastName?.toLowerCase(),
        template => template.firstName?.toLowerCase()
      ], ['desc']);
    } else {
      this._filteredProcesses = _.orderBy(this._processList, [
        template => template.clinicProcessNumber
      ], ['desc']);
    }
      break;
    case ProcessesSort.SUMMARY_ASC:
      this._filteredProcesses = _.orderBy(this._processList, [
        template => template.summary
      ], ['asc']);
      break;
    case ProcessesSort.SUMMARY_DESC:
      this._filteredProcesses = _.orderBy(this._processList, [
        template => template.summary
      ], ['desc']);
      break;

  }
  }

  get errorItems():PositionErrorI[] {
    return this._errorItems;
  }

  get progressionFactors():ProgressionFactorItem[] {
    return this._progressionFactors;
  }

  get baseTemplateType(): BASE_TEMPLATE_TYPE {
    return this._baseTemplateType;
  }

  get activeProcess(): TemplateFlatI{
    return this._currentTemplate
  }
  get activeTemplate(): TemplateFlatI{
    return this._currentTemplate
  }

  get currentTemplateId():number {
    return this._currentTemplateId
  }

  get activeTemplateDate() : Date {
    return this._currentTemplate != null
    && this._currentTemplate.date != null ? new Date(this._currentTemplate.date) : new Date(Date.now())
  }

  get currenUid(): string {
    return this.currentUid
  }

  set currenUid(val:string) {
    this.currentUid = val;
  }

  get currentTemplateType():string {
    if(this._currentTemplate != null){
      return this._currentTemplate.type;
    } else {
      return ''
    }
  }

  get canRenameTemplate(): boolean {
    if(this.baseTemplateType == BASE_TEMPLATE_TYPE.TEMPLATE){
      if(this.currentUid != null && this.currentUid != '' && (this.currentUid === this.activeTemplate.owner || this.currentUid === this.activeTemplate.creator)){
        return true
      } else {
        return false;
      }
    } else if(this.baseTemplateType == BASE_TEMPLATE_TYPE.PROCESS) {
      return this._currentTemplate.isEditable

    } else {
      return false;
    }
  }

  get templateNameTooltip():string {
    return this.canRenameTemplate ? 'Name bearbeiten' : this.cantEditTooltipText
  }

  get cantEditTooltipText():string {
    if(this.baseTemplateType == BASE_TEMPLATE_TYPE.TEMPLATE){
      if(this.currentUid != this.activeTemplate.owner){
        return "Die Vorlage ist Öffentlich. Nur der Ersteller kann diese Vorlage bearbeiten."
      } else {
        return '';
      }
    } else if(this.baseTemplateType == BASE_TEMPLATE_TYPE.PROCESS){
      if(this.templateHasInvoice && this.templateHasSurgeryReport) {
        return "Bearbeiten nicht möglich. Rechnung und OP-Bericht sind abgeschlossen."
      } else if(this.templateHasInvoice && !this.templateHasSurgeryReport){
        return "Bearbeiten nicht möglich. Rechnung ist abgeschlossen."
      } else if(!this.templateHasInvoice && this.templateHasSurgeryReport){
        return "Bearbeiten nicht möglich. OP-Bericht ist abgeschlossen."
      }


    } else {
      return '';
    }
  }


async deleteSinglePosition(position_id:number, template_id:number) {
  // console.log(`deleteSinglePosition > position_id: ${position_id} > template_id: ${template_id}`)
  // return
  const s = await this.posApi.positionsGET(this.prefix, T_POSITION_CALL.POSITION_DELETE, position_id.toString());
  this.itemsUpdated.emit();
  const _index = this._templatePositions.findIndex(item => item.position_id == position_id)
  if(_index != -1){
    this._templatePositions.splice(_index, 1)
  }
  this.initTemplatePositions(template_id, this.prefix);
  return s
}


async updateSinglePositionItem(template_id:number, position_id:number, item: Object) {
  const keys = Object.keys(item)
  const _index = this._templatePositions.findIndex(pItem => pItem.position_id == position_id)
  if(_index != -1) {
    this._templatePositions[_index][keys[0]] = item[keys[0]]
  }
  // const s = await this.posApi.updateSinglePositionItem(template_id, position_id, item);
  await this.posApi.positionsPOST(this.prefix, T_POSITION_CALL.POSITION_UPDATE_SINGLE_ITEM, {item: item}, `${template_id}/${position_id}`)
  this.itemsUpdated.emit();
}

  async updateActiveStatesForPositions(template_id:number, positions:PositionFlatI[]) {
    for(var i = 0; i < positions.length; i ++){
      const index = this._templatePositions.findIndex(item => item.position_id == positions[i].position_id)
      if(index != -1){
        this._templatePositions[index].activated = positions[i].activated
      }
      // await this.posApi.updateSinglePositionItem(template_id, positions[i].position_id, {activated: positions[i].activated});
      await this.posApi.positionsPOST(this.prefix, T_POSITION_CALL.POSITION_UPDATE_SINGLE_ITEM, {item: {activated: positions[i].activated}}, `${template_id}/${positions[i].position_id}`)
    }
    this.itemsUpdated.emit();
  }


async deleteTemplate(template_id:number) {
  const index = this._filteredTemplates.findIndex(item => item.template_id == template_id)
  if(index != -1) {
    this._filteredTemplates.splice(index, 1);
    const index2 = this._templates.findIndex(item => item.template_id == template_id)
    if(index2 != -1) {
      this._templates.splice(index2, 1);
    }
  }
  await this.templateApi.deleteTemplate(template_id).then(x => {
    // this.onReloadTemplates.emit()
  })
}

get overviewTemplates(): TemplateFlatI[] {  
  if(this._overviewFilterString.trim().length > 2) {
    return this._filteredTemplates.filter(item => item.name.toLowerCase().includes(this._overviewFilterString.toLowerCase()));
  } else {
    return this._filteredTemplates
  }
}

get overviewEmpty() : boolean {
  return this._templates.length == 0
}

async loadTemplates(filter:TemplatePoolFilterItemUser[], allFilter:TREATMENT_FILTER[], treatmentFilterGroups: string[], fgb:AreaOfExpertiseUserI){
  console.log('\n============\nload templates\n============\n')
  console.log(filter);
  console.log(allFilter)
  console.log(treatmentFilterGroups)
  console.log(fgb)
  this._templates = []
  const data = await this.templateApi.apiGet(TEMPLATES_CALL.TEMPLATE_OVERVIEW_ALL);
  this._templates = data as TemplateFlatI[]
  console.log(`\n============\nLOADED count: ${this._templates.length}\n============\n`)
  //  = await this.templateApi.apiGet(TEMPLATES_CALL.TEMPLATE_OVERVIEW_ALL).then(
  //   _data => {
  //     // console.log("templates loaded")
  //     // console.log(_data)
  //     console.log('\n============\nload templates\n============\n', _data.length)
  //     return _data as TemplateFlatI[]
  //   }
  // )
  console.log(filter);
  this.joinTemplateOverview(filter, allFilter, treatmentFilterGroups, fgb)
  // this._filteredTemplates = this._templates
}

async oveviewOut(){
  this._filteredTemplates = []  
}

overviewTemplatesFilterWith(filter: string) {
  this._overviewFilterString = filter
}

joinTemplateOverview(filter:TemplatePoolFilterItemUser[], allFilter:TREATMENT_FILTER[], treatmentFilterGroups: string[], fgb:AreaOfExpertiseUserI){
  console.log("joinTemplateOverview");
  console.log(`\n============\njoinTemplateOverview\n============\n`)
  console.log(filter)
  console.log(fgb)
  var _tmp = [];
/*   let _filteredExpertises: TemplateFlatI[] = []; */
  let _filtersRegions: TemplateFlatI[] = [];
  var _filtered: TemplateFlatI[] = [];

  const _templateFilters = filter

  var _tmp = [];

  // let _privacyDOXFOX = [];
  // let _privacySCS = [];

  for(const filter of _templateFilters) {
    const _filterdTmp = this._templates.filter(item => item.pool_id == filter.template_pool_id && filter.filters.includes(item.privacy))
    _tmp.push(_filterdTmp)
  }

    // for(const filter of _templateFilters){

    //   if(filter.endsWith('_DOXFOX')){
    //     _privacyDOXFOX.push(filter.replace('_DOXFOX',''))
    //   } else if(filter.endsWith('_SCS')){
    //     _privacySCS.push(filter.replace('_SCS',''))
    //   }
    // }
  
    // console.log(_privacyDOXFOX);
    
  //  this.filteredTemplates = this.templates
    // let _scsFilter = Object.entries({
    //   'type': ['SCS'],
    //   'privacy':_privacySCS
    // })
    // let _dfxFilter = Object.entries({
    //   'type': ['DOXFOX'],
    //   'privacy':_privacyDOXFOX
    // })

    // var df = this._templates.filter(o => _dfxFilter.every(([k, v]) => v.includes(o[k])));
    // var scs = this._templates.filter(o => _scsFilter.every(([k, v]) => v.includes(o[k])));
    // _tmp.push(df)
    // _tmp.push(scs)

    _tmp = _.flatten(_tmp);

    // this.filteredTemplates = _tmp
    // console.log(_tmp)
    // console.log(_tmp)
    _filtersRegions = _tmp

    var _completeActiveFilterIds : number[] = []

    const getFilterByGroup = (group:string) => {
      if(group == 'Filter 1'){
        return allFilter.filter(item => item.filter_level == TREATMENT_FILTER_LEVEL.FIRST)
      }
      if(group == 'Filter 2'){
        return allFilter.filter(item => item.filter_level == TREATMENT_FILTER_LEVEL.SECOND)
      }
      if(group == 'Filter 3'){
        return allFilter.filter(item => item.filter_level == TREATMENT_FILTER_LEVEL.THIRD)
      }
        return []
    }

    for(let group of treatmentFilterGroups){
      // console.log(_.map(this.getFilterByGroup(group).filter(item => item.selected && item.fachgebiet_id == this.ui.fachgebiet.fachgebiet_id),'selected'));

      // const __enabledRegions = this.getFilterByGroup(group).filter(item => item.selected && item.fachgebiet_id == this.ui.fachgebiet.fachgebiet_id)
      const __enabledRegions = _.map(getFilterByGroup(group).filter(item => item.selected && item.fachgebiet_id == fgb.fachgebiet_id),'id')


      _completeActiveFilterIds = _.concat(_completeActiveFilterIds, __enabledRegions)
      // console.log(' group __enabledRegions ',group + ' : ' + __enabledRegions.length + " : " + __enabledRegions)
      if(group == 'Filter 1'){
        _filtersRegions = _filtersRegions.filter((_data) => {

            return __enabledRegions.includes(_data.treatmentfilter_first) || _data.treatmentfilter_first == -1;
        });
      }
      else if(group == 'Filter 2'){
        _filtersRegions = _filtersRegions.filter((_data) => {
          return __enabledRegions.includes(_data.treatmentfilter_second) || _data.treatmentfilter_second == -1;
        });
      }
      else if(group == 'Filter 3'){

        _filtersRegions = _filtersRegions.filter((_data) => {
          return __enabledRegions.includes(_data.treatmentfilter_third) || _data.treatmentfilter_third == -1;
        });
      }
    }  

    for(const k of _filtersRegions) {
      _filtered.push(k);
    }
  this._filteredTemplates = _filtered 
  console.log(`\n============\nfiltered templates count: ${this._filteredTemplates.length}\n============\n`)
  
  }
  


//// SECTOPM

  private  _matItems:SectionMaterialI[] = []
  sectionChanged:EventEmitter<void> = new EventEmitter<void>();

  private _sectionFactors:SectionProgressionFactors[] = []

  get allSectionMaterialItems():SectionMaterialI[] {
    return this._matItems;
  }

  private _currentSections:SectionM[] = []

  get currentSections(): SectionM[] {
    return this._currentSections
  }

  get sectionFactors(): SectionProgressionFactors[]{
    return this._sectionFactors
  }

  
  getCustomProgressionFactors(section_id): CustomProgressionFactorI[] {
    const items = this._sectionFactors.find(item => item.section_id === section_id)
     if (items == null){
      return []
     } {
       return items.factors
     }
  }


  async getSections(template_id:number, prefix: string) {
    const s = await this.sectApi.getSectionByTemplateId(prefix, template_id);
    this._currentSections = []
    this._currentSections = s
    this.sectionChanged.emit();
  }

   async setSections(sections:SectionM[]) {
    this._currentSections = []
    this._currentSections = sections
    this.sectionChanged.emit();
  }

  async loadSectionsForCopy(template_id:number,prefix: string) {
    const s = await this.sectApi.getSectionByTemplateId(prefix, template_id);
    // const s = await this.sectApi.getSectionByTemplateId(template_id);
    return s;
  }

  async addNewSection(template_id:number, prefix: string){
    let s = {
      template_id: template_id,
      order_index: this._currentSections.length,
      name: 'Bezeichnung des Abschnittes',
      date: 1,
      treated_at_difference_days: 0,
      isReference: 0,
    }
    const r = await this.sectApi.createSection(prefix,s);
    await this.getSections(template_id, prefix);
  }


  async updateAllSections(prefix: string){
    for (var i = 0; i < this._currentSections.length; i++){
      const section_id = this._currentSections[i].section_id;
      var section = this._currentSections[i];
      await this.updateSingleSectionItem(prefix, section_id, {treated_at_difference_days: section.treated_at_difference_days});
    }
  }
  
  async updateSectionIndex(startIndex: number, prefix: string){
    for (var i = startIndex; i < this._currentSections.length; i++){
      const section_id = this._currentSections[i].section_id;
      var section = this._currentSections[i];
      section.order_index = i;
      await this.updateSingleSectionItem(prefix, section_id, {order_index: i});
    }
  }


  async updateSingleSectionItem(prefix: string, section_id:number, item: Object) {
    await this.sectApi.sectionsPOST(prefix,SECTION_ENDPOINT.SECTION_UPDATE_SINGLE_ITEM, {item: item},section_id.toString())
  }

  async moveSectionToTemplate(prefix: string,section_id:number, newTemplateId:number){
    const _addedSectionResponse = await this.sectApi.moveSectionToTemplate(prefix,section_id, newTemplateId).then(_data => {return _data})
  }

  async duplicateSection(section_id:number, template_id:number, prefix: string){
     await this.sectApi.sectionsGET(prefix, SECTION_ENDPOINT.SECTION_DUPLICATE, `${section_id.toString()}/${template_id.toString()}`).then(_data => {return _data})
     this.getSections(template_id, prefix)
  }

  async deleteSection(section_id:number, template_id:number, prefix: string){
    const _sections = await this.sectApi.sectionsGET(prefix, SECTION_ENDPOINT.SECTION_DELETE, `${section_id.toString()}/${template_id.toString()}`).then(_data => {return _data})
    this._currentSections = _sections as SectionM []
    this.sectionChanged.emit();
  }

  
  // async getCustomProgressionFactorBySectionId(prefix: string,section_id:number){
  //   const _res = await this.sectApi.getCustomProgressionFactorBySectionId(prefix,section_id);
  //   const index = this._sectionFactors.findIndex(item => item.section_id == section_id);
  //   if(index != -1){
  //     this._sectionFactors.splice(index, 1);
  //   }
  //   this._sectionFactors.push({section_id: section_id, factors: _res as CustomProgressionFactorI[] })
  //   return _res;
  // }

  /**
   * GET MATERIAL ITEMS THAT IN SECTION INCLUDET
  */
  async getSectionMaterialItems(prefix: string, section_id:number, forceReload:boolean = false){
    console.log('%c getSectionMaterialItems', 'color:red')
    if(forceReload){
      const _r = await this.sectApi.sectionsGET(prefix, SECTION_ENDPOINT.SECTION_MAT_ITEMS, section_id.toString()) as any[]

      this._matItems = _.remove(this._matItems, function(e) {
        return e.section_id != section_id;
      })
      this._matItems = _.concat(this._matItems, _r)

      } else {
        return 
      }

    this.itemsUpdated.emit();
  }

    /**
     * ADD MATERIAL ITEM TO SECTION
     */
    async addSectionMaterialItem(prefix: string,section_id:number, item:MaterialI){
      const _r = await this.sectApi.addSectionMaterialItem(prefix, section_id, item) as SectionMaterialI[]
      this._matItems = _.remove(this._matItems, function(e) {
        return e.section_id != section_id;
      })
      this._matItems = _.concat(this._matItems, _r)
      this.itemsUpdated.emit();
    }


    /**
     * UPDATE ITEM IN SECTION
     */
    async updateSectionMaterialItem(prefix: string,item:SectionMaterialI) {
      const _r = await this.sectApi.updateSectionMaterialItem(prefix,item);
      this.itemsUpdated.emit();
    }

    /**
     * REMOVE FROM SECTION
     */
    async deleteSectionMaterialItem(prefix: string,matItemId:number) {
      await this.sectApi.deleteSectionMaterialItem(prefix,matItemId);
      this.itemsUpdated.emit();
    }

    positionFactorChanged:EventEmitter<number> = new EventEmitter<number>();

    async postAndGetId(prefix: string, call:T_POSITION_CALL, payload:Object) {
      return await this.posApi.postAndGetId(prefix, call, payload)
    }
  
    // clearPositions(){
    //   this._templatePositions = []
    // }
    protected _templatePositions:PositionFlatI[] = []
  
  
  
    get templatePositions(): PositionFlatI[] {
      return this._templatePositions
    }
  
    async initTemplatePositions(template_id:number, prefix: string) {
      const s = await this.posApi.positionsGET(prefix, T_POSITION_CALL.POSITIONS_BY_T_ID, template_id.toString());
      
      this._templatePositions = []
      this._templatePositions = s as PositionFlatI []
      this.positionsEmitter.emit(this._templatePositions)
  
      // console.log("TEMPLATE POSITIONS")
      // console.log(this._templatePositions)
    }
  
      async setTemplatePositions(positions:PositionFlatI[]) {
      
      this._templatePositions = []
      this._templatePositions = positions
      this.positionsEmitter.emit(this._templatePositions)
  
      // console.log("TEMPLATE POSITIONS")
      // console.log(this._templatePositions)
    }
  
  
    async reloadPositionsBySectionId(section_id: number, prefix: string) {
      const s = await this.posApi.positionsGET(prefix, T_POSITION_CALL.POSITIONS_BY_S_ID, section_id.toString());
      this._templatePositions = _.remove(this._templatePositions, function(n) { return n.section_id != section_id})
      const _t = s as PositionFlatI []
      this._templatePositions = _.concat(this._templatePositions, _t)
      this.positionsEmitter.emit(this._templatePositions)
      this.sectionPositionChanged.emit(section_id)
    }
  
    async loadPositionsBySectionId(prefix: string, section_id:number) {
      const s = await this.posApi.positionsGET(prefix, T_POSITION_CALL.POSITIONS_BY_S_ID, section_id.toString());
      return s;
    }
  
    positionForSectionId(section_id:number):PositionFlatI[]{
      return this._templatePositions.filter((p) => p.section_id == section_id);
    }
    positionForId(position_id:number):PositionFlatI{
      return this._templatePositions.find((p) => p.position_id == position_id);
    }
    async addNewPosition(prefix: string, p:PositionFlatI, template_id:number) {
      let b = {position: p}
      // console.log(b);
      const s = await this.posApi.positionsPOST(prefix, T_POSITION_CALL.POSITION_CREATE, b)
      return Number(s)
    }
   
  
  
    async _checkForConflicts2() {
      // console.log("_checkForConflicts START")
      let _ids:string[] = []
      this._templatePositions.forEach(_pos => {
        if(_pos.activated && _pos.position_type === POSITION_TYPE.GOA
          && _pos.goa_paragraph != null && _pos.goa_paragraph.goa_id){
            if (!_ids.includes(_pos.goa_paragraph.goa_id) ){
              _ids.push(_pos.goa_paragraph.goa_id)
            }
          }
      })
      if(_ids.length == null){
        return
      }
    }
  
  
    /**
     *
     *
     * CUSTOM PROGRESSION FACTORS
     *
     *
     */
  
  
    async getCustomProgressionFactor(prefix: string, position_id:number){
      const _res = await this.posApi.getCustomProgressionFactor(prefix, position_id);
      return _res;
    }
    async addNewCustomProgressionFactor(prefix: string, section_id:number, position_id:number, factor:number){
      const _res = await this.posApi.addNewCustomProgressionFactor(prefix, section_id, position_id, factor);
      return _res;
    }
  
    async updateCustomProgressionFactor(prefix: string, factorId:number, factor:number){
      const _res = await this.posApi.updateCustomProgressionFactor(prefix, factorId, factor);
      return _res;
    }
    async deleteCustomProgressionFactor(prefix: string, factorId:number) {
      const _res = await this.posApi.deleteCustomProgressionFactor(prefix, factorId);
      return _res;
    }
  
  
    /**
     *
     *
     * CUSTOM PROGRESSION FACTORS
     *
     *
     */
     async getJustificationById(prefix: string,position_id:number, progressionFactorId:number, isPublic:number){
      const _res = await this.posApi.getJustificationById(prefix, position_id, progressionFactorId, isPublic);
      return _res;
    }
    /**
     *
     *
     * CREAT OR BLOCK
     *
     *
     */
     async createOrGroup(section_id:number, template_id:number, startIndex:number, prefix: string){
      const _res = await this.posApi.createOrGroup(prefix, section_id, template_id, startIndex);
      this.itemsUpdated.emit();
      this.initTemplatePositions(template_id, prefix);
      return _res;
    }
  
  
    /**
     *
     *
     * SURGERY POSITION ITEMS
     *
     *
     */
  
    async getSurgeryReportPositionItemsByTemplateId(template_id:number, prefix: string){
      var surgerSectionsAll: SurgerySectionI[] = []
      // var surgerPositionsAll: SurgeryPositionI[] = []
      const _items = await  this.posApi.positionsGET(prefix, T_POSITION_CALL.REPORT_POS_ITEMS, template_id.toString()) as any[]
      _items.forEach(_item => {
  
        if(surgerSectionsAll.findIndex(item => item.section_id == _item.section_id) == -1){
          const _positions = _items.filter((item) => item.section_id == _item.section_id)
          surgerSectionsAll.push({
            section_id: _item.section_id,
            name: _item.name,
            order_index: _item.order_index,
  
            treated_at_difference_days: _item.treated_at_difference_days,
            positions:_positions
          })
        } else {
  
        }
      })
      console.log(surgerSectionsAll)
      return surgerSectionsAll;
    }


    private _surgeryReportPosItems:SurgeryReportItenV4I[] = []

  public footerEmitter:EventEmitter<void> = new EventEmitter<void>();
  public positionsEmitter:EventEmitter<PositionFlatI[]> = new EventEmitter<PositionFlatI[]>();
  public itemsUpdated:EventEmitter<void> = new EventEmitter<void>();
  public positionGoaError:EventEmitter<PositionErrorI[]> = new EventEmitter<PositionErrorI[]>();
  public sectionPositionChanged:EventEmitter<number> = new EventEmitter<number>();
  async loadSurgReportItemsByPosId(prefix:string, posId:number){
    const _result = await this.api.getReportItemsByPosId(prefix,posId)
    return _result
  }


  async addSurgReportPosItem(prefix:string, template_id:number, position_id:number){
    const _newRepItem:SurgeryReportItenV4I = {
      surgery_report_item_id: -1,
      template_id: template_id,
      position_id: position_id,
      is_active: true,
      text: "",
      description: "",
    }
   const _data =  await this.api.postSurgeryRepors(prefix,_newRepItem)
   return _data
  }

  async addSurgReportPosItemFull(prefix:string, template_id:number, position_id:number, text: string, description: string){
    const _newRepItem:SurgeryReportItenV4I = {
      surgery_report_item_id: -1,
      template_id: template_id,
      position_id: position_id,
      is_active: true,
      text: text,
      description: description,
    }
   const _data =  await this.api.postSurgeryRepors(prefix,_newRepItem)
   return _data as number;
  }

  
  async removeSurgeryReportItem(prefix:string, surgery_report_item_id:number,templateDocId:number ){
    const _result = await this.api.removeSurgeryReportItem(prefix,surgery_report_item_id, templateDocId)
  }

  async updateActiveSurgeryReport(prefix:string, position_id, surgery_report_item_id ){
    await this.api.updateActiveSurgeryReport(prefix,position_id,surgery_report_item_id)
  }


  async updateSingleReport(prefix:string, report:SurgeryReportItenV4I,templateDocId:number ){
     await this.api.updateSingleReport(prefix,report,templateDocId)
  }

   /**
   *
   * ADDIIONAL SURGERY INFOS
   *
   */

  async postAdditionalsureryInfos(infos:any, process_id:number){
    await this.api.postAdditionalsureryInfos(infos, process_id)
  }
  async getAdditionalsureryInfos(template_id:number){
    const _resp = await this.api.getAdditionalsureryInfos(template_id)  .then(_res => {
      return _res;
    })
    return _resp;
  }
  async getSurgerySupport(template_id:number){
    const _resp = await this.api.getSurgerySupport(template_id)  .then(_res => {
      return _res;
    })
    return _resp;
  }
  async addSurgerySupport(template_id:number, supportItem:SurgerySupportPersonI){
    const _resp = await this.api.addSurgerySupport(template_id, supportItem)  .then(_res => {
      return _res;
    })
    return _resp;
  }
  async changeSurgerySupport(template_id:number, id:number, employee_id:number){
    const _resp = await this.api.changeSurgerySupport(template_id, id, employee_id)  .then(_res => {
      return _res;
    })
    return _resp;
  }
  async removeSupportItem(supportItemId:number){
    const _resp = await this.api.removeSurgerySupportById(supportItemId).then(_res => {
      return _res;
    })
    return _resp;
  }

  /**
   *
   * Surgery Report Preview
   *
   */

  async unlockSurgeryReportById(process_id:number){
    const _resp = await this.api.unlockSurgeryReportById(process_id)
    return _resp;
  }
  async getSurgeryReportById(process_id:number){
    const _resp = await this.api.getSurgeryReportById(process_id).then(_res => {
      return _res;
    })
    return _resp;
  }
  async getSurgeryReportPreview(process_id:number){
    const _resp = await this.api.getSurgeryReportPreview(process_id).then(_res => {
      return _res;
    })
    return _resp;
  }
  async updateSurgeryReportPreview(process_id:number, item:Object){
    const _resp = await this.api.updateSurgeryReportPreview(process_id, item).then(_res => {
      return _res;
    })
    return _resp;
  }
  async finalizeSurgeryReportPreview(process_id:number, item:Object){
    const _resp = await this.api.finalizeSurgeryReportPreview(process_id, item).then(_res => {
      return _res;
    })
    return _resp;
  }


  /**
   *
   * Surgery Report POSITIONS
   *
   */

  async getSurgeryReportPositions(prefix:string, process_id:number){
    const _resp = await this.api.getSurgeryReportPositions(prefix, process_id).then(_res => {
      return _res;
    })
    return _resp;
  }
}
