
<mat-toolbar class="overlay-toolbar" >
  <mat-label style="font-weight:500!important; font-size: 16px!important;">
    Mitarbeiter hinzufügen</mat-label>
  <span class="toolbar-spacer"></span>
    <mat-icon style="color:#ABBBC7; cursor: pointer;   -webkit-user-select:none;" (click)='close()'>close</mat-icon>
</mat-toolbar>

<div class="container" style="height: 272px;">
  
  <div class="row justify-content-between p_t40">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Title</mat-label>
      <input class="formControl mat-body" [(ngModel)]="employee.title">
    </div>
    
    
  </div>
  
  <div class="row p_t20">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Vorname</mat-label>
      <input class="formControl mat-body" [(ngModel)]="employee.firstName">
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Nachname</mat-label>
      <input class="formControl mat-body" [(ngModel)]="employee.lastName">
    </div>
  </div>
  
  <div class="row p_t20 p_b20">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Telefon</mat-label>
        <input class="formControl mat-body" [(ngModel)]="employee.phone">
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Email</mat-label>
        <input class="formControl mat-body" [(ngModel)]="employee.email">
    </div>

    
  </div>
  <!-- <div class="row"  *ngIf='employee.documentId != null'>
    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-checkbox [(ngModel)]="employee.disabled">Archivieren</mat-checkbox>
    </div>
  </div> -->
      
</div> 

<mat-toolbar class="overlay-toolbar" >
  <span class="toolbar-spacer"></span>
  <!-- style="width:92px; text-align:right;" class="tlc justify-content-end" -->
  <div class="d-flex">
    <div class="p-0">
      <app-df-icon-button
      title="Löschen"
      icon="delete"
      (clicked)="delete()"
      
      >
      </app-df-icon-button>  
    </div>
    <div class="p-0">
      <app-df-icon-button
      title="Speichern"
      icon="save"
      (clicked)="save()"
      
      >
      </app-df-icon-button>  
    </div>
  </div>
  <!-- <div  *ngIf='!isSaving'>
  
  
  </div> -->
  <!-- <div style="width:50px;"  *ngIf='isSaving'>
    <mat-spinner diameter='25' color='primary'></mat-spinner>
  </div> -->
</mat-toolbar>
  