import { Injectable, EventEmitter, TemplateRef, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { ScreenSize } from '../core/models/screenSize';
import { SectionM } from '../core/models/sectionM';
import { OverlayConfig} from '@angular/cdk/overlay';
import { DxFxBreadCrumb } from '../core/models/breadCrumpIntemI';
import { MatDialog } from '@angular/material/dialog';
import { GoaeConflictI } from '../core/models/goaeAusschlussI';
import { ISnackData } from '../core/models/viewI/customsnack.model';

@Injectable({
  providedIn: 'root'
})
export class ControllerService {



  private _currentPage:string = '';

  isPreloadOverlay:boolean = false
  static _roles = []
  static _templateConflicts:GoaeConflictI[] = []
  static _isGoaeUpdated:boolean = false;
  public  DIALOG

  constructor(private router:Router, public  dialog:MatDialog) {
    this.DIALOG = this.dialog
   }


   static isDebug = true;


   static goaeUpdated(){
     this._isGoaeUpdated = true;
     this._templateConflicts = [];
   }
   static get isGoaeUpdated() :boolean{
     return this._isGoaeUpdated;
   }

  // static isMenuOpen:EventEmitter<boolean> = new EventEmitter<boolean>();
  static hasNavigateTo:EventEmitter<string> = new EventEmitter<string>();
  static currentPage:EventEmitter<string> = new EventEmitter<string>();
  static snack:EventEmitter<string> = new EventEmitter<string>();
  static customSnack:EventEmitter<ISnackData> = new EventEmitter<ISnackData>();
  static windowResize:EventEmitter<ScreenSize> = new EventEmitter<ScreenSize>();
  static windowScrollEmitter:EventEmitter<number> = new EventEmitter<number>();
  //  static alertMessageEmitter:EventEmitter<AlertI> = new EventEmitter<AlertI>();

  //  static alertResultMessageEmitter:EventEmitter<AlertActionI> = new EventEmitter<AlertActionI>();

  // static deleteSectionEmitter:EventEmitter<SectionM> = new EventEmitter<SectionM>();
  static duplicateSectionEmitter:EventEmitter<SectionM> = new EventEmitter<SectionM>();

  static currentSize:ScreenSize;
  static currentScrollY:number;
  static _router:Router;

  static _ui:string

  static saveTemplate:EventEmitter<string> = new EventEmitter<string>();
  static isWritingTemplate:EventEmitter<boolean> = new EventEmitter<boolean>();

  static preloadOverlay:EventEmitter<boolean> = new EventEmitter<boolean>();

  static breadCrumpEmitter:EventEmitter<DxFxBreadCrumb> = new EventEmitter<DxFxBreadCrumb>();

  static showIsPublicTemplateAlertEmitter:EventEmitter<string> = new EventEmitter<string>();

  static sessionTimeoutEmitter:EventEmitter<string> = new EventEmitter<string>();




  // static showAlert(alertI:AlertI) {

  //   this.alertMessageEmitter.emit(alertI)
  //   // .open(AlertOverlayComponent, {
  //   //   disableClose:true,
  //   //   data:alert,
  //   //   backdropClass:"backdrop-background"
  //   // })

  //   // .afterClosed().subscribe((result:AlertResultI) => {
  //   //   if(result != null){
  //   //     return result
  //   //   }

  //   // });
  // }


  static get userId():string {
    return this._ui
  }
  static setUserId(u:string) {
     this._ui = u;
  }
  static setCurrentPage(path:string){
    setTimeout(()=>{
      this.currentPage.emit(path);
    },550)
  }
  static get currentScreenSize ()  {
    if(this.currentSize == null){
      this.currentSize = {width:window.innerWidth, height:window.innerHeight};
    }
    return this.currentSize
  }

  static navigate(path:string){
    this.hasNavigateTo.emit(path);

  }

  static setBreadCrumbPath(items:DxFxBreadCrumb){
    this.breadCrumpEmitter.emit(items)
  }

  static setScrollY(offsetY: number) {
    this.currentScrollY = offsetY;
    this.windowScrollEmitter.emit(offsetY);
  }
  static changeScreenAspect(newSize: ScreenSize) {
    this.currentSize = newSize;
    this.windowResize.emit(newSize);
  }

  static setRoles(r) {
    this._roles = r;
  }
  static getRoles() {
    return this._roles;
  }

  static setConflict(r:GoaeConflictI) {
    if(this._templateConflicts == null){
      this._templateConflicts = []
    }
    if(this._templateConflicts.find((item) => item.ref_goa_id == r.ref_goa_id) == null){
      this._templateConflicts.push(r);
    }

  }

  static isGoaIdInConflicts(goaeId:string):boolean {
    return this._templateConflicts.find((item)=>item.ref_goa_id == goaeId) == null ? false : true;
  }

  static getConflictsAll() {
    return this._templateConflicts
  }
  static getConflicts(goaeIds:string[]):GoaeConflictI[] {//: GoaeConflictUI_I[]{
    let conflictsForSection:GoaeConflictI[] = []
    for(var i= 0; i < goaeIds.length; i ++){
      // console.log("------")
      let c:GoaeConflictI =  this._templateConflicts.find((item)=>item.ref_goa_id == goaeIds[i]);
      if(c != null){
        conflictsForSection.push(c)
        // console.log('C  != null')
        // console.log(c)
      } else {
        // console.log('C  == null')
      }
    }
    // let conflictsForSection:GoaeConflictI[] = this._templateConflicts.filter((item) =>  item.ref_goa_id in goaeIds);

  /*   if(p.errorModel.regel != null && p.errorModel.regel.type != null && p.errorModel.regel.ausschluss_liste != null){
      if(p.errorModel.regel.type == 'only_allowed_with'){
        var _errorText = ''
        if(!this._onlyAllowedWith(p.errorModel.regel.ausschluss_liste) && p.activated){
          _errorText = "Darf nur allein oder zusammen mit einer der folgenden Ziffern abgerechnet werden: " ;
            for (const  textItem of   p.errorModel.regel.ausschluss_liste) {
              _errorText += textItem + ", "
            }
            p.errorModel.errorMessage =  _errorText;
            p.hasError = true;
        } else {
            p.errorModel.errorMessage =  '';
            p.hasError = false;
        }

      }  else if(p.errorModel.regel.type == 'exclusion'){
        for (var j=0; j< goaeIds.length; j++) {
          if(this.section.positions[j].activated && !this.section.positions[j].indented) {
            let _errorID = p.errorModel.regel.ausschluss_liste.find((goaeId)=>goaeId == this.section.positions[j].goa_paragraph.goa_id);
            if(_errorID) {
              _errorText = "Kann nicht zusammen mit Ziffer " + goaeIds[j]  + " abgerechnet werden"
              p.errorModel.errorMessage =  _errorText;
              p.hasError = true;
            }
            else {
              p.errorModel.errorMessage =  '';
              p.hasError = false;
          }
          }
        }
      }
    } */
    return conflictsForSection;
  }


  // static showIsDoxFoxTemplateAlert(template:TemplateM){
    // this.alertMessageEmitter.emit(
    //   {
    //     title:"Achtung",
    //     bodyMessage:"Diese Vorlage ist eine DoxFox Vorlage. Bitte erst *Zu meinen Vorlagen hinzufügen*  auswählen",
    //     actions: [
    //       {
    //         title:'Template zu *Meinen Vorlagen kopieren*', color: AlertActionI.BUTTON_COLOR_TYPE.ACCENT, actionType:AlertActionI.BUTTON_ACTION_TYPE.RESUME
    //       },
    //       {
    //         title:'Abbrechen', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType:AlertActionI.BUTTON_ACTION_TYPE.CANCEL
    //       }
    //     ]
    //   }
    // )
  // }
}

export interface AppOverlayConfig extends OverlayConfig { }
