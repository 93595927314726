import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormControl} from '@angular/forms';
// import { MatDatepicker } from '@angular/material/datepicker';

import { PatientService } from 'src/app/services/patient.service';
import { AccountService } from 'src/app/services/account.service';
import { AccountI, AddressI } from 'src/app/core/models/accountI';
import { FinalInvoiceRecieptI } from 'src/app/core/models/invoiceI';
import { DatePipe } from '@angular/common';
import { PatientFull } from 'src/app/core/models/patient';
import jsPDF from 'jspdf';
import * as _ from "lodash";
import { POSITION_TYPE, ProcessCompletionOverlayData, ProcessCompletionType } from 'src/app/core/models/enums';
import { employeeI } from 'src/app/core/models/employeeI';
import { CgcurrencyPipe } from 'src/app/pipes/cgcurrency.pipe';
import { GoaServicesService } from 'src/app/services/goa-services.service';
import { UiService } from 'src/app/services/ui.service';
import {TemplateApiService} from '../../../services/template-api.service';
import { TemplateFlatI } from 'src/app/core/models/api/TemplateFlatI';

import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { InvoicePreviewPositionItemI } from 'src/app/core/models/v4/invoice/invoicePositionI';

import { InvoicePreviewC } from 'src/app/core/models/v4/invoice/invoicePreviewI';
import { InvoiceCreator } from 'src/app/utils/invoiceCreator';

@Component({
  selector: 'app-process-completion',
  templateUrl: './process-completion.component.html',
  styleUrls: ['./process-completion.component.scss'],
  providers:[DatePipe]
})
export class ProcessCompletionComponent implements OnInit {
  // @ViewChild('picker') datePicker: MatDatepicker<Date>;
  pdf:jsPDF
  invoiceNumber:number;
  userpath:string;
  k:string;
  pdfSrc;
  datumsTitle:string ='Rechnungsdatum'
  isGenerating:boolean = false;
  finalCreateDisabled:boolean = true;
  cancelDisabledDisabled:boolean = false;
  isContentAvailable:boolean = false;
  patientError:boolean = false;
  missingJustificationsError:boolean = false;
  missingSurgeryDocError:boolean = false;
  missingReportCopiesError:boolean = false;
  isInvoice:boolean = true;
  readyBtnTxt:string = 'Fertigstellen'
  selectedAddress:AddressI
  addresses:AddressI[] = []
  // nextNumber:number
  private _year:number;
  private _userAccount:AccountI;
  private _hasDateChanged = false;
  // private _template:TemplateFlatI;
  private _patient:PatientFull;
  private employees:employeeI[] = []

  constructor(public dialogRef: MatDialogRef<ProcessCompletionComponent>,


    private ui:UiService,
    private patientS:PatientService,
    private accountS:AccountService,
    private datePipe: DatePipe,
    private currencyPipe:CgcurrencyPipe,
    private goaeS:GoaServicesService,
    public tc:TemplateControllerService,
    private templateApi:TemplateApiService,
    @Inject(MAT_DIALOG_DATA) public data: ProcessCompletionOverlayData) {
      // this._template = data.template;
     }

   

    invoiceDate = new Date()
   
    get _template():TemplateFlatI {
      return this.tc.activeTemplate
    }

    ngOnInit(): void {
      this._year = new Date().getFullYear()
      setTimeout(()=>{
        this.getUserAccount()
    },1000);


  }

  addressChanged(event){
    this.selectedAddress = event.value
    console.log(event)
    this._template.address_id = event.value.id
  }

  private initPage(){

    switch(this.data.type){
      case ProcessCompletionType.INVOICE:
      // console.log("return this.saveInvoice();")
      // this.saveInvoice();
      this.checkPreview();
      // return

      this.addresses = this.ui.addresses

       var address:AddressI

       if(this._template.address_id != null ){
         const index = this.ui.addresses.findIndex(item => item.id == this._template.address_id);
         if(index != -1){
           address = this.ui.addresses[index]
         } else {
           address = this.ui.addresses[0]
         }
       } else {
         address = this.ui.addresses[0]
       }

       this.selectedAddress = address

       if (!this.ui.isClinicUser) {
         if(this._patient == null){
           this.patientError = true;
         }
       }

       /*  if(this.hasMissingJustification() === true){
          this.missingJustificationsError = true;
        } */
        this.isContentAvailable = true
        //this.missingJustificationsError === false && this.patientError === false  ? true : false;

        break;
        // return this._initForInvoice()
      case ProcessCompletionType.SURGERYREPORT:

        if(this._patient == null){
          this.patientError = true;
        }
        if(this._template.additionalSurgeryInfos.surgery_doc_uid == null || this._template.additionalSurgeryInfos.surgery_doc_uid == ""){
          this.missingSurgeryDocError = true;
        }
       /*  if(this.hasMissingReportCopies() === true){
          this.missingReportCopiesError = true;
        } */
        if(this.missingSurgeryDocError === true
          || this.patientError === true
          || this.missingReportCopiesError === true){
            this.readyBtnTxt = 'Trotzdem abschliessen'
          }
          this.isContentAvailable = true;
          this.finalCreateDisabled = false;
        break;

      case ProcessCompletionType.BEFUND:
        if(this.missingSurgeryDocError === true
          || this.patientError === true
          || this.missingReportCopiesError === true){
          this.readyBtnTxt = 'Trotzdem abschliessen'
        }
        this.isContentAvailable = true;
        this.finalCreateDisabled = false;
        break;
        // return  this._initForSuRep();
    }

    if(this.isContentAvailable === true){
      switch(this.data.type){
        // case ProcessCompletionType.INVOICE:
        //   // this.isInvoice = true;
        //   // this.datumsTitle = 'Rechnungsdatum'
        //   // if(this.patientError || this.missingJustificationsError){
        //   //   this.readyBtnTxt = 'Trotzdem abschliessen'
        //   // }
        //   // this.getInvoices()
        //   return;
        case ProcessCompletionType.SURGERYREPORT:
        this.isInvoice = false;
        this.datumsTitle = 'OP Bericht Datum'
         this.finalCreateDisabled = false;
         return
      }
    }
  }

  private hasMissingReportCopies() : boolean {
     return false;
  }

  private hasMissingJustification() : boolean {
    // for (var i = 0; i < this._template.sections.length; i++) {
    //   let section = this._template.sections[i];
    //    for(var j = 0; j < section.positions.length; j++) {
    //       let pos = section.positions[j];

    //       if(pos != null && pos.progression_factor != null  && pos.activated && !pos.isOwnBillingItem && pos.goa_paragraph != null){

    //         if(pos.progression_factor > Number(pos.goa_paragraph.progression_factor) || pos.progression_factor > 2.3) {
    //         // if(pos.goa_paragraph.progression_factor < pos.progression_factor || pos.goa_paragraph.progression_factor > pos.progression_factor){
    //           if(pos.justification_items != null){
    //             let jufc:JustificationItemI = pos.justification_items.find((item) => item.progression_factor == pos.progression_factor)
    //             if(jufc == null) {
    //               return true;
    //             } else {
    //               let textItem = jufc.text_items.find((item) => item.selected === true);
    //                 if(textItem == null || textItem.text === ''){
    //                   return true
    //                 }
    //             }
    //           } else {
    //             return true
    //           }
    //         }
    //       }
    //    }
    // }

    return false;
  }

  close(){
    this.dialogRef.close();
  }
  save(){
    this.cancelDisabledDisabled = true;
    this.isGenerating = true;
    this.finalCreateDisabled = true;
    setTimeout(()=>{
      switch(this.data.type){
        case ProcessCompletionType.INVOICE:
          return this.saveInvoice();
        case ProcessCompletionType.SURGERYREPORT:
          return this.saveSurgeryReport();
      }
    },250);
  }



  private async getInvoices(){
    // let _invoices:InvoiceI[] = await this.templateS.getInvoicesByYear(this._userAccount.uid, this.date.value.getFullYear())
    // .then(
    //   _data => {
    //     return _data.docs.map(
    //       action => {
    //         const model = action.data() as InvoiceI;
    //         return model;
    //       }
    //     )
    //   }
    // )
    // let nextNumber:number = 10001;
    // if(_invoices != null && _invoices.length > 0){
    //   _invoices = _.sortBy(_invoices,'invoiceNumber');
    //   nextNumber = _invoices[_invoices.length -1 ].invoiceNumber += 1
    // }
    // this.invoiceNumber =  nextNumber;
    // this.finalCreateDisabled = false;
  }



  private async saveSurgeryReport(){
    // let _date:Date = new Date(this.date.value);
    // let _dateString:string = _date.toISOString();
    // var address:AddressI
    // if(this._template.invoiceAddressId != null ){
    //   const index = this.ui.addresses.findIndex(item => item.id == this._template.invoiceAddressId);
    //   if(index != -1){
    //     address = this.ui.addresses[index]
    //   } else {
    //     address = this.ui.addresses[0]
    //   }
    // } else {
    //   address = this.ui.addresses[0]
    // }
    // await this.accountS.employeesArray().then(
    //   (data) => {
    //     this.employees = data as employeeI[];
    //   }
    // )
    // let _report:SurgeryReportI = new SurgeryReportC(this.datePipe, this.templateS)
    // .createReport(this._template, this._userAccount, this._patient, this.employees, address);

    // let docId = await this.templateS.addFinalSurgeryReport(this._userAccount.uid, _report).then(
    //   (x)=>{ return x; }
    // )
    // this.cancelDisabledDisabled = false;
    // this._template.hasSurgeryReport = true;
    // this._template.surgeryReportDocId = docId;
    // for (var i = 0; i < this._template.sections.length; i++) {
    //   let section = this._template.sections[i];
    //   this._template.sections[i].templateHasSurgeryReport = true
    //   for(var j = 0; j < section.positions.length; j ++) {
    //     this._template.sections[i].positions[j].
    //     templateHasSurgeryReport = true
    //   }
    // }
    // await this.templateS.updateProcess(this._template);
    // let _outPut:FinalSurgeryReportI = {
    //   documentTitle: 'OP-Bericht.'+this._template.name+'.'+_dateString,
    //   pdf:this.pdf
    // }
    // this.dialogRef.close(_outPut)
  }


  private async saveInvoice(){

    var address:AddressI
    if(this._template.address_id != null && this._template.address_id != -1){
      // const _addressIndex = this.ui.addresses.findIndex(item => item.id == this._template.address_id)
      const r = await this.tc.getFullInvoiceAddress(this._template.template_id) 
      if(r) {
        address = r
      } else {
        address = this.ui.addresses[0]
      }
      // if(_addressIndex != -1){
      //   address = this.ui.addresses[_addressIndex]
      // } else {
      //   address = this.ui.addresses[0]
      // }
    } else {
      address = this.ui.addresses[0]
    }

    // const _invoice_number_next = await this.accountS.getLastInvoiceNumber()
    const _procssInvoices = await this.accountS.invoicesByProcessId(this._template.template_id);
    // console.log('_invoice_number_next ', _invoice_number_next)
    // console.log('_procssInvoices ', _procssInvoices)
    // let _invoiceNumber;
    // if(_procssInvoices.length > 0){
    //   _invoiceNumber = _procssInvoices[0].invoice_number;
    // } else {
    //   _invoiceNumber =  _invoice_number_next + 1
    // }


    let _invoiceNumber;

    if(_procssInvoices.length > 0){
      _invoiceNumber = _procssInvoices[0].invoice_number;
    } else {
      if (this.ui.isClinicUser) {
        if ( this._template.clinicProcessNumber != null){
          _invoiceNumber = this._template.clinicProcessNumber
        }
      } else {
        const _invoice_number_next = await this.accountS.getLastInvoiceNumber()
        _invoiceNumber =  _invoice_number_next + 1
      }

    }

    let _invoiceCreator = new InvoiceCreator(this.tc,this.ui, this.tc.activeTemplate,  address, this.datePipe, this.currencyPipe, this.invoiceDate)


    const _invoiceItem = await _invoiceCreator.createInvoiceItem(_invoiceNumber, this.ui.isClinicUser)


    const _res =  await this.accountS.createInvoice(_invoiceItem)
    this.pdf = _invoiceCreator.getInvoicePDF(_invoiceItem, this.ui.isClinicUser)
    this.tc.activeTemplate.invoice_id = _res;

     let _outPut:FinalInvoiceRecieptI = {
      documentTitle: 'Rechnung.'+this._template.name+'.'+_invoiceNumber,
      pdf:this.pdf
    }

    this.dialogRef.close(_outPut)

  }

  private async checkPreview(){

    let _matItems = this.tc.allSectionMaterialItems

    let _items:InvoicePreviewPositionItemI[] = []
    _items = await this.tc.getInvoicePreviewItems(this._template.template_id) as InvoicePreviewPositionItemI[]
    _items = _.orderBy(_items, [item => item.order_index, item => item.position_order_index])

    for(var i = 0; i < _matItems.length; i ++){
      const _tadd = _items.find(item => item.section_id == _matItems[i].section_id)
      _items.push({
        section_id: _matItems[i].section_id,
        position_id: 99999999,
        amount_euro: _matItems[i].preis,
        order_index: 99 + i,
        treated_at_difference_days: _tadd != null && _tadd.treated_at_difference_days != null ? _tadd.treated_at_difference_days : 0,
        position_order_index:99 + i,
        example: _matItems[i].bezeichnung,
        // invoiceCopy: _matItems[i].bezeichnung,
        progression_factor: 1,
        quantity: _matItems[i].quantity,
        position_type: POSITION_TYPE.NONE
      })
    }

    var _patientFull:PatientFull;
    if(this._template.patient_id != null && this._template.patient_id != -1){

      const _resPat = await this.patientS.getSingle(this._template.patient_id)
      _patientFull = _resPat
    }

    var __address:AddressI

    if(this._template.address_id != null && this._template.address_id != -1){
      const _addressIndex = this.ui.addresses.findIndex(item => item.id == this._template.address_id)
      if(_addressIndex != -1){
        __address = this.ui.addresses[_addressIndex]
      } else {
        __address = this.ui.addresses[0]
      }
    } else {
      __address = this.ui.addresses[0]
    }
    var _diagnose = this.tc.getLatesDiagnose()
    var _tMethod = this.tc.getLatesTherapie()




    const _procssInvoices = await this.accountS.invoicesByProcessId(this._template.template_id);

    // console.log('_invoice_number_next ', _invoice_number_next)
    // console.log('_procssInvoices ', _procssInvoices)

    let _invoiceNumber;

    if(_procssInvoices.length > 0){
      _invoiceNumber = _procssInvoices[0].invoice_number;
    } else {
      if (this.ui.isClinicUser) {
        if ( this._template.clinicProcessNumber != null){
          _invoiceNumber = this._template.clinicProcessNumber
        }
      } else {
        const _invoice_number_next = await this.accountS.getLastInvoiceNumber()
        _invoiceNumber =  _invoice_number_next + 1
      }

    }
    this.invoiceNumber = _invoiceNumber;

    console.log('invoiceNumber ', this.invoiceNumber)
    let _ip:InvoicePreviewC = new InvoicePreviewC(__address, this._template, this.datePipe, _items, _matItems, _patientFull, this.currencyPipe, _diagnose, _tMethod);
    // _ip.invoiceI.invoiceNumber = this.invoiceNumber
    this.pdf = _ip.createPreview(_invoiceNumber);

    this.finalCreateDisabled = false;

  }



  get getInvoiceNumber() : string {
    if(!this.invoiceNumber) {
      return ''
    } else {
      return this.invoiceDate.getFullYear().toString() + this.invoiceNumber.toString();
    }
  }


  private getUserAccount(){
     this._userAccount = this.ui.account;
    if(this.userpath == null || this.userpath == ''){
      this.userpath = this._userAccount.uid;
      this.initPatient();
    } else {
     this.initPatient();
    }
  }


  private async initPatient(){
    if(this._template.patient_id == null || this._template.patient_id == -1){
      return this.initPage()
     } else {
       await this.patientS.getSingle(this._template.patient_id).then(
         (p) => {
           console.log(p)
           this._patient = p as PatientFull
         }
       ).then(
         (x) => {
           this.initPage()
         }
       )
     }

  }


  dateChanged(e){
    this.invoiceDate = e
    this._hasDateChanged = true;
    // this._template.date = this.currentDate.getTime()
    // this.tc.activeTemplate.

    if(this._year != this.invoiceDate.getFullYear()){
      this.finalCreateDisabled = true;
      // if(this.data.type === ProcessCompletionType.INVOICE){
      //   this.getInvoices();
      // }

      this._year =  this.invoiceDate.getFullYear();
    } else {
      this.finalCreateDisabled = false;
    }
  }
}

