

<div class="container-fluid p0" style="position: relative; overflow: hidden; height: 100%;">
  <div style="height: 56px;position: fixed; width: 820px; z-index: 900; ">
    <mat-toolbar style="border-top-left-radius: 6px; border-top-right-radius: 6px;">
      <div class="container-fluid p0">
        <div class="d-flex align-items-center justify-content-end">

          <div class="p-0 mr10" *ngIf='_report != null'>
            <!-- <div class="d-flex table_cell_button " (click)="clipboardTest()"> -->
            <div class="d-flex table_cell_button" [cdkCopyToClipboard]="editorReportClipBoardTextFull" >
              <div class="p-0 align-text-end ml-auto">
                <mat-icon class="table_cell_button_icon">content_copy</mat-icon>
              </div>
              <div class="p-0 table_cell_button_copy">Kopieren</div>
            </div>
          </div>
          <div class="p-0 mr10" *ngIf='_report != null'>
            <div class="d-flex table_cell_button" [cdkCopyToClipboard]="editorReportClipBoardText" >
              <div class="p-0 align-text-end ml-auto">
                <mat-icon class="table_cell_button_icon">subject</mat-icon>
              </div>
              <div class="p-0 table_cell_button_copy">OP-Durchführung kopieren</div>
            </div>
          </div>

          <div class="p-0 mr10" *ngIf='showPreviewResetButton && !showRichTextEditor && !isCompletionMode'>
            <div class="d-flex table_cell_button_warn " (click)='resetEditorText()'
            matTooltipClass='errorTT' matTooltip='Auf OP-Bericht Textbausteine zurücksetzen'>
              <div class="p-0 align-text-end ml-auto">
                <mat-icon class="table_cell_button_icon">delete</mat-icon>
              </div>
              <div class="p-0 table_cell_button_copy">zurücksetzen</div>
            </div>
          </div>

          <div class="p-0 mr10" *ngIf='showEditorButton && !showRichTextEditor && !isCompletionMode'>
            <div class="d-flex table_cell_button " (click)='openRichTextEditor()'>
              <div class="p-0 align-text-end ml-auto">
                <mat-icon class="table_cell_button_icon">edit</mat-icon>
              </div>
              <div class="p-0 table_cell_button_copy">Editieren</div>
            </div>
          </div>

          <div class="p-0 mr10" *ngIf='pdf != null'>
            <!-- <button mat-icon-button (click)='downloadPDF()' *ngIf='saveOnDisk'><mat-icon>save</mat-icon></button> -->
            <div class="d-flex table_cell_button " (click)='downloadPDF();' *ngIf='saveOnDisk'>
              <div class="p-0 align-text-end ml-auto">
                <mat-icon class="table_cell_button_icon">print</mat-icon>
              </div>
              <div class="p-0 table_cell_button_copy">Drucken</div>
            </div>
            <div class="d-flex table_cell_button " (click)='print();' *ngIf='!saveOnDisk'>
              <div class="p-0 align-text-end ml-auto">
                <mat-icon class="table_cell_button_icon">print</mat-icon>
              </div>
              <div class="p-0 table_cell_button_copy">Drucken</div>
            </div>
          </div>

          <div class="p-0 mr10" *ngIf='showFertigStellen'>
            <div class="d-flex table_cell_button " (click)='createFinalReport()'>
              <div class="p-0 align-text-end ml-auto">
                <mat-icon class="table_cell_button_icon">lock</mat-icon>
              </div>
              <div class="p-0 table_cell_button_copy">Fertigstellen</div>
            </div>
          </div>

          <div class="p-0 mr10" *ngIf='!isInvoice && !data.template.hasSurgeryReport && isCompletionMode'>
            <div class="d-flex table_cell_button " (click)='saveFinalElement()'>
              <div class="p-0 align-text-end ml-auto">
                <mat-icon class="table_cell_button_icon">save</mat-icon>
              </div>
              <div class="p-0 table_cell_button_copy">{{readyBtnTxt}}</div>
            </div>
          </div>


          <div class="p-0">
            <mat-icon (click)='close()' class='dxfx_icon_btn_medium ml10' >close</mat-icon>
            <!-- <button mat-mini-fab class="mat-elevation-z0 p0" color='basic'
            style="padding-top:0px!important; line-height: 14px!important; width: 32px; height: 32px;" (click)='close()'><mat-icon>close</mat-icon></button> -->
          </div>
        </div>
      </div>
    </mat-toolbar>
  </div>


  <div class="" style="position: absolute; left: 0; right: 0; top: 56px; height: 100%; background-color: rgba(255, 255,255, 0.35); backdrop-filter: blur(5px);z-index: 999;" *ngIf='showErrorOverlay()'>

    <div class="d-flex flex-column" >
      <div class="p-2 flex-fill p0">
        <div class="d-flex borderLight align-items-center" *ngIf='patientError'>
          <div class="p-2 flex-fill">
            <div class="alert alert-danger" role="alert">Kein Patient ausgewählt</div>
          </div>
          <!-- <div class="p-2 ml-auto">
            <mat-icon color='warn'>cancel</mat-icon>
          </div> -->
        </div>
        <div class="d-flex borderLight align-items-center" *ngIf='missingJustificationsError'>
          <div class="p-2 flex-fill">
            <div class="alert alert-danger" role="alert">Fehlende Begründungen für Steigerung</div>
          </div>
          <!-- <div class="p-2 ">
            Fehlende Begründungen für Steigerung
          </div>
          <div class="p-2 ml-auto">
            <mat-icon color='warn'>cancel</mat-icon>
          </div> -->
        </div>
        <div class="d-flex borderLight align-items-center" *ngIf='missingReportCopiesError'>
          <div class="p-2 flex-fill">
            <div class="alert alert-danger" role="alert">Fehlende Op-Bericht Texte</div>
          </div>
          <!-- <div class="p-2 ">
            Fehlende Op-Bericht Texte
          </div>
          <div class="p-2 ml-auto">
            <mat-icon color='warn'>cancel</mat-icon>
          </div> -->
        </div>
        <div class="d-flex borderLight align-items-center" *ngIf='missingSurgeryDocError'>
          <div class="p-2 flex-fill">
            <div class="alert alert-danger" role="alert">Operateur:in nicht ausgewählt</div>
          </div>
          <!-- <div class="p-2 ">
            Operierende(r) Ärztin/Arzt nicht ausgewählt
          </div>
          <div class="p-2 ml-auto">
            <mat-icon color='warn'>cancel</mat-icon>
          </div> -->
        </div>
      </div>
    </div>

  </div>


  <div class="container-fluid p0 " style="height: 100%; position: relative; padding-bottom:56px!important;" >

    <div class="container-fluid p0 m_b40" style="height: 100%!important;margin-top: 56px; " *ngIf='showRichTextEditor'>
     <!--  <div class="p-2">
        <surgery-report-header [surgeryReport]='_report' [userAccount]='userAccount'></surgery-report-header>
      </div> -->
      <!-- <div class="p-2 m_b40 flex-fill"  > -->
        <!-- [height]='90'
          style="height:100%!important;"
        -->
        <ejs-richtexteditor

          #templateRTE  [enableResize]="false"
          id="name"
          #name='ngModel' name="name" [(ngModel)]="_report.editorReport"
          [toolbarSettings]='tools'
          [editorMode]='mode'
          >
        </ejs-richtexteditor>
      <!-- </div> -->
    </div>
    <div class="container-fluid p0 m_b40" style="height: 100%!important;margin-top: 56px;  overflow-x: hidden; " *ngIf='!showRichTextEditor'>
    <div class="row" style="height: 100%; " >
      <div class="col-12" style="min-height: 100%;" *ngIf='show'>
        <pdf-viewer [src]="pdfSrc"
                [render-text]="true"
                [autoresize]="true"
                [show-all]="true"
                style="display: block;"
    ></pdf-viewer>
      </div>
      </div>
    </div>
  </div>

  <div style="position: absolute; left: 0; right: 0; bottom: 0; height: 44px; backdrop-filter: blur(5px); background-color: rgba(255, 255,255, 0.35); z-index: 999;"
  *ngIf='showRichTextEditor && !isCompletionMode'>
<!--   *ngIf='showRichTextEditor || isCompletionMode'> -->

    <div class="d-flex justify-content-end" style="margin-top: 5px;" >
      <div class="p-0 mr10">
        <div class="d-flex table_cell_button " (click)='cancelPreviewCopy()'>
          <div class="p-0 align-text-end ml-auto">
            <mat-icon class="table_cell_button_icon">delete</mat-icon>
          </div>
          <div class="p-0 table_cell_button_copy">Abbrechen</div>
        </div>
      </div>
      <div class="p-0 mr10">
        <div class="d-flex table_cell_button " (click)='savePreviewCopy()'>
          <div class="p-0 align-text-end ml-auto">
            <mat-icon class="table_cell_button_icon">save</mat-icon>
          </div>
          <div class="p-0 table_cell_button_copy">Speichern</div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--     <div class="d-flex justify-content-end" style="margin-top: 5px;" *ngIf='isCompletionMode'>
      <div class="p-0 mr10">
        <div class="d-flex table_cell_button " (click)='cancelFinalCreate()'>
          <div class="p-0 align-text-end ml-auto">
            <mat-icon class="table_cell_button_icon">delete</mat-icon>
          </div>
          <div class="p-0 table_cell_button_copy">Abbrechen</div>
        </div>
      </div>
      <div class="p-0 mr10" *ngIf="!isGenerating">
        <div class="d-flex table_cell_button " (click)='saveFinalElement()'>
          <div class="p-0 align-text-end ml-auto">
            <mat-icon class="table_cell_button_icon">save</mat-icon>
          </div>
          <div class="p-0 table_cell_button_copy">{{readyBtnTxt}}</div>
        </div>
      </div>
      <div class="p-0 mr10" *ngIf="isGenerating">
        <mat-spinner diameter='25' color='primary'></mat-spinner>
      </div>
    </div> -->


<!-- </div> -->

<!-- </div>
</div> -->
 <!-- <div class="row p0">
      <mat-toolbar>
        <div class="container-fluid">
          <div class="d-flex align-items-center">
            <div class="p-2 mr-auto al">
              <span class="pl30">Vorschau</span>
            </div>
            <div class="p-2" *ngIf='pdf != null'>
              <button mat-icon-button (click)='downloadPDF()' *ngIf='saveOnDisk'><mat-icon>save</mat-icon></button>
              <button mat-icon-button (click)='print()' *ngIf='!saveOnDisk'><mat-icon>print</mat-icon></button>
            </div>
            <div class="p-2">
              <button mat-icon-button (click)='close()'><mat-icon>close</mat-icon></button>
            </div>
          </div>
        </div>
      </mat-toolbar>
    </div> -->
