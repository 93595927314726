import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PositionFlatI } from 'src/app/core/models/sectionPositionM';
import { TemplateControllerService } from 'src/app/services/template-controller.service';

@Component({
  selector: 'app-add-custom-quantity',
  templateUrl: './add-custom-quantity.component.html',
  styleUrls: ['./add-custom-quantity.component.scss']
})
export class AddCustomQuantityComponent implements OnInit {
  modelControl:FormControl;
  @ViewChild('_txtInput', {static:false}) _txtInput:ElementRef;
  title = 'Anzahl'
  count = 0
  quantity:number
  customFactor:number = 0.0
  position:PositionFlatI
  constructor(
    private tc: TemplateControllerService,
    public dialogRef: MatDialogRef<AddCustomQuantityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PositionFlatI) {
      this.position = data;
    } 

  ngOnInit(): void {
    this.quantity = this.position.quantity
    setTimeout(()=> {
      this._txtInput.nativeElement.focus();
    },150)
    setTimeout(()=> {
      this._txtInput.nativeElement.select()
    },160)
    this.modelControl = new FormControl(this.quantity, 
        [Validators.required, Validators.maxLength(4), 
          Validators.pattern(/^-?(0|[1-9]\d*)?$/)])
          // Validators.pattern(/^[0-9,]*\.?[0-9.]*$/)])
  }

  async save(){
    if(this.canSave){
      this.quantity = parseInt(this.modelControl.value);
      this.dialogRef.close(this.quantity)
    }
    
  }
  onKeyUp(e){
    setTimeout(()=>{
      this.count = this.modelControl.value.length
    },5)
  }
  close(){
    this.dialogRef.close()
  }
  get canSave():boolean {
    return this.modelControl.valid && this.position && this.position.position_id != -1
  }

}
