import { ViewChild,Component, OnInit, ElementRef, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ControllerService } from 'src/app/shared/controller.service';
import { SectionM } from 'src/app/core/models/sectionM';
import { PatientSelectionComponent } from 'src/app/ui/overlays/patient-selection/patient-selection.component';
import { PatientService } from 'src/app/services/patient.service';
import { AccountI, AddressI } from 'src/app/core/models/accountI';
import { DatePipe } from '@angular/common';
import { employeeI } from 'src/app/core/models/employeeI';
import { AddComponentDirective } from 'src/app/directives/add-component.directive';
import { TemplateReportViewComponent } from 'src/app/ui/widgets/template-modules/template-report-view/template-report-view.component';
declare var TweenLite: any;
import {Sine} from 'gsap/all'
import { InvoiceViewProcess, NachbehandlungView, ProcessHistoryView, ReportView } from 'src/app/utils/helpers';
import { AlertOverlayComponent } from 'src/app/ui/overlays/alert-overlay/alert-overlay.component';
import { AlertActionI } from 'src/app/core/models/alertM';
import { Subscription } from 'rxjs';
import { UiService } from 'src/app/services/ui.service';
import { TextEditI } from 'src/app/core/models/textOverlayEditI';
import { TexteditOverlayComponent } from 'src/app/ui/overlays/textedit-overlay/textedit-overlay.component';
import { ProcessStatesOverlayComponent } from 'src/app/ui/overlays/process-states-overlay/process-states-overlay.component';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { TemplateNachbehandlungComponent } from 'src/app/ui/widgets/template-modules/template-nachbehandlung/template-nachbehandlung.component';
import { PatientFlatI } from 'src/app/core/models/v3/patientFlat';
import { PatientSelectionInputI } from 'src/app/core/models/viewI/patientSelectionInputI';
import { BASE_TEMPLATE_TYPE, PATIENT_SELECTION_VIEW_MODE } from 'src/app/core/models/enums';
import { DxFxBreadCrumb } from 'src/app/core/models/breadCrumpIntemI';
import { IProcessState } from 'src/app/core/models/iProcessState';
import { ProcessHostorieComponent } from 'src/app/ui/widgets/template-modules/process-hostorie/process-hostorie.component';
import { IOProcessStateMessage, SocketService } from 'src/app/services/socket.service';
import { ProcessBehandlungsschritteComponent } from 'src/app/ui/widgets/process-behandlungsschritte/process-behandlungsschritte.component';
import { IProcessStatesOverlayData, IProcessStatesOverlayDataViewMode } from 'src/app/core/models/viewI/openProcessOverlayData';


@Component({
  selector: 'app-process-details',
  templateUrl: './process-details.component.html',
  styleUrls: ['../templates-details.scss'],
  providers:[DatePipe]
})
export class ProcessDetailsComponent implements OnInit, OnDestroy {

  @ViewChild(AddComponentDirective, {static: false}) adHost: AddComponentDirective;
  @ViewChild('_bgContainerFuck', {static: false}) _bgContainerFuck:ElementRef;
  @ViewChild('_contentHolder', {static: false}) _contentHolder:ElementRef;
  saveTimeout: any;
  viewChecked:boolean = false;
  ioSubscription:Subscription
  surgeryInfosInit:boolean = false;
  documentId:string = ''
  template_id:number = -1
  isLoading:boolean = true;
  isSectionsView:boolean = true;
  isSurgeryView:boolean = false;
  historyActive:boolean = false;
  patientFlat:PatientFlatI
  userpath:string
  showOpAsTimeline:boolean = true;
  accessDeniedHidden = true;
  data$
  selectedTabIndex = 0
  itemsEditable = false
  userAccount:AccountI
  employees:employeeI[] = []
  roles = []
  winWasOpen = false;
  greedBackground:boolean= false
  nachbehandlungActive:boolean= false
  saveSubscription:Subscription;
  deleteSubscription:Subscription;
  duplicateSubscription:Subscription;
  processloadedSubscription:Subscription;
  templateLoadError:Subscription;
  addresses:AddressI[] = []
  currentAddressTitle
  initialLOaded = false



  constructor(
    private dialog:MatDialog,
    private patientService:PatientService,
    private route: ActivatedRoute,
    public ui:UiService,
    private resolver: ComponentFactoryResolver,
    private router: Router,
    public tc:TemplateControllerService,
    private ss: SocketService
  ) {}

  ngOnInit(): void {

    this.route.params.subscribe(
      params=> {
        this.template_id = params['id'];
        this.tc.initProcessById(this.template_id)
      }
    );

    this.ioSubscription = this.ss.socketProcessStateEmitter.subscribe(
      (_message: IOProcessStateMessage) => {
        console.log("_message: IOProcessStateMessage",_message);    
        if(_message.template_id == this.template_id) {
          const index = this.tc.processStates.findIndex(item => item.id == _message.state_id)
          if(index != -1) {
            this.tc.activeTemplate.status_id = this.tc.processStates[index].id
            this.tc.activeTemplate.status_color = this.tc.processStates[index].color
            this.tc.activeTemplate.status_view_value = this.tc.processStates[index].view_value
          }
          if (_message.uid != this.ui.account.uid) {
            // this.tc.initProcessById(this.template_id)
            this.router.navigate(['vorgaenge'])
          } else {
             this.tc.initProcessById(this.template_id)
          }
             
        }
      }
    );

    this.processloadedSubscription =  this.tc.currentProcessLoaded.subscribe(_d=>{
      if(_d && !this.initialLOaded) {
        this._initView()
      }
    });

    this.templateLoadError = this.tc.errorTemplateLoading.subscribe(
      _error => {
        if(_error == 'LOAD_ERROR_PAGE') {
          console.log("\n\n\nLOAD_ERROR_PAGE\n\n\n")
          ControllerService.preloadOverlay.emit(false);
          this._cancelSubscription()
          this.router.navigate(['error/', 'vorgaenge'])
        } else  if(_error == 'ACCESS DENIED') {
          console.log("\n\n\ACCESS DENIED\n\n\n")
          ControllerService.preloadOverlay.emit(false);
          this._cancelSubscription()
          this.accessDeniedHidden = false;
          // this.router.navigate(['error/', 'vorgaenge'])
        } else {
          // this.accessDeniedHidden = false   
        }
      }
    );
  }

  private async _initView() {
    console.log('\n\n\nINIT PROCESS DETAILS \n\n\n')
    this.initialLOaded = true

    setTimeout(()=>{
      ControllerService.preloadOverlay.emit(true);
        ControllerService.setCurrentPage('vorgaenge');
        let _bc:DxFxBreadCrumb = {
          bcItems: [
            {title:'Meine Leistungen',path:'vorgaenge'},
            {title:'Detail',path:''}
          ]
        }
        ControllerService.setBreadCrumbPath(_bc);
    },100);

    setTimeout(()=>{
      this.ui.reloadEmployees()
      if(this.tc.activeTemplate && this.tc.activeTemplate.address_id && this.ui.addresses && this.ui.addresses.length > 0){
        const __add = this.ui.addresses.find((item) => item.id === this.tc.activeTemplate.address_id)
        if(__add){
          this.currentAddressTitle = __add
        }
      }
    },500);

    setTimeout(()=>{
  
      ControllerService.preloadOverlay.emit(false);
      setTimeout(()=>{
        this.isLoading = false

        if(this.tc.activeTemplate && this.tc.activeTemplate.patient_id != null && this.tc.activeTemplate.patient_id != -1 
          && !this.ui.isClinicUser){
          this.loadPatient(this.tc.activeTemplate.patient_id)
        } 
        this.itemsEditable = true;
        // this.initSurgeryInfos();
        this.surgeryInfosInit = true
        this.historyActive = false
        this.injectInvoiceView();
        // this.injectHistorie();
        // this.injectReportView()
      },50)
    },600);
  }

  async loadPatient(patId:any) {
    console.log('load patienent', 'color:red')
    console.log('%c_ERROR_ __indented', 'color:red')
    //     console.log(`%c                DRAGGED  INDEX : ${draggedPosition.position_order_index}        ID: ${draggedPosition.position_id}`, 'color:#4EA8B0')
    // console.log(`%c                REPLACED INDEX : ${replaced_position.position_order_index}        ID: ${replaced_position.position_id}`,'color:#FE714C')
    if(patId != null && patId != ''){
      const result = await this.patientService.singlePatientFlat(patId)
      if(result){
        this.patientFlat = result as PatientFlatI
      }
    }
  }


  get templateDateEditable():boolean {
    if(this.tc.isTemplateEditable){
      if(this.tc.activeProcess.invoice_id == -1 || this.tc.activeProcess.invoice_id == null ){
        if(this.tc.activeProcess.surgery_report_id == -1 || this.tc.activeProcess.surgery_report_id == null ){
          return true
        }
      }
    }
    return false
  }

  _deleteSection(pSection: SectionM){
    this.dialog.open(AlertOverlayComponent, {
      width: '350px',
      backdropClass: 'backdrop-background',
      data: {
        title: 'Vorlage löschen',
        bodyMessage: 'Sind Sie sicher, dass Sie diesen Behandlungsschritt löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
        actions: [
          {
            title: 'Löschen', color: AlertActionI.BUTTON_COLOR_TYPE.WARN, actionType: AlertActionI.BUTTON_ACTION_TYPE.RESUME
          },
          {
            title: 'Abbrechen', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType: AlertActionI.BUTTON_ACTION_TYPE.CANCEL
          }
        ]
      }
    }).afterClosed().subscribe((result) => {
      if (result != null){
        if (result === AlertActionI.BUTTON_ACTION_TYPE.RESUME){
          const index = this.tc.currentSections.indexOf(pSection);
          this.tc.currentSections.splice(index, 1);
          // this.save()
        }
      }
    });
  }
  




  addressChanged(event){
    var _newAdd:AddressI = event.value
    this.currentAddressTitle = _newAdd
    this.tc.IPROCESSnvoiceAddressIdChanged(_newAdd.id);
  }



  dateChanged(e){
    this.tc.processDateChanged(e);
  }

  changeStateTapped(){
    const _overlayData: IProcessStatesOverlayData = {

      viewMode: IProcessStatesOverlayDataViewMode.HISTORY,
      status_id: this.tc.activeTemplate.status_id,
      currentProcess: this.tc.activeTemplate

      // template_id: template.template_id,
      // status_id: this.tc.activeTemplate.status_id,
      // status_color:this.tc.activeTemplate.status_color,
      // status_list_value: this.tc.activeTemplate.status_list_value,
      // status_view_value: this.tc.activeTemplate.status_view_value,
      // isEditable: this.tc.activeTemplate.isEditable
    }    

    // const _overlayData: OverviewProcessI = {

    //   template_id: this.template_id,
    //   status_id: this.tc.activeTemplate.status_id,
    //   status_color:this.tc.activeTemplate.status_color,
    //   status_list_value: this.tc.activeTemplate.status_list_value,
    //   status_view_value: this.tc.activeTemplate.status_view_value,
    //   isEditable: this.tc.activeTemplate.isEditable
    // }
    this.dialog.open(ProcessStatesOverlayComponent, {
      width: '80%',
      maxWidth: '1400px',
     height: '400px',
      backdropClass:"backdrop-background",
      data:_overlayData
    }).afterClosed().subscribe(
      _newState => {
        if(_newState == "OVERVIEW"){
          this.router.navigate(['vorgaenge'])
          // this.tc.activeTemplate.status_id = _newState.id
          // this.tc.activeTemplate.status_color = _newState.color
          // this.tc.activeTemplate.status_view_value = _newState.view_value
         // this.changeProcessState(template.documentId, _newState.title)

        }
      }
   )
  }
 ////

  toggleChanged(item){
    if(item == 'surgery'){
      this.isSurgeryView = !this.isSurgeryView;
      this.isSectionsView = !this.isSectionsView;
    } else {
      this.isSurgeryView = !this.isSurgeryView;
      this.isSectionsView = !this.isSectionsView;
    }
  }

  renameTemplate(){
    if(!this.tc.canRenameTemplate) { return }
    const d: TextEditI = {singleline: true, text: this.tc.activeTemplate.name, title: 'Namen ändern'};
    this.dialog.open(TexteditOverlayComponent, {
      width: '600px',
      data: d,
      backdropClass: 'backdrop-background',
    }).afterClosed().subscribe(
      _newText => {
        if (_newText != null){
          if(this.tc.baseTemplateType == BASE_TEMPLATE_TYPE.TEMPLATE) {
            this.tc.updateTemplateItem({name:_newText});
            this.tc.activeTemplate.name = _newText;
          } else {
            this.tc.updateProcessItem({name:_newText});
            this.tc.activeTemplate.name = _newText;
          }
          // this.save();
        }
      }
    );
  }

  diagnoseChanged(e){
    ControllerService.saveTemplate.emit(this.tc.activeTemplate.documentId)
  }

  async onEditPatient(e) {
    if(e == 'editPatient'){
      let _overlayData:PatientSelectionInputI = {
        startMode:PATIENT_SELECTION_VIEW_MODE.LIST_PATIENTS_MODE,
        openWithPatientId:null,
        uid: this.tc.activeProcess.creator
      }
      if(this.patientFlat != null && this.patientFlat.patient_id != null){
        this.loadPatient(this.tc.activeTemplate.patient_id)
        _overlayData.startMode = PATIENT_SELECTION_VIEW_MODE.MODIFY_MODE
        _overlayData.openWithPatientId = this.patientFlat.patient_id
      }

      this.dialog.open(PatientSelectionComponent,{
        disableClose:true,
        width:"70%",
        maxWidth:"1200px",
        height:"560px",
        backdropClass:"backdrop-background",
        data: _overlayData
      }).afterClosed().
      subscribe(
        (_patientResult: PatientFlatI)=> {
          if(_patientResult != null && _patientResult.patient_id != null){
            if(this.tc.baseTemplateType == BASE_TEMPLATE_TYPE.PROCESS) {

              this.tc.updateProcessItem({patient_id:_patientResult.patient_id});
              this.tc.activeTemplate.patient_id = _patientResult.patient_id
            } else {

              this.tc.updateTemplateItem({patient_id:_patientResult.patient_id});
              this.tc.activeTemplate.patient_id = _patientResult.patient_id
            }
           this.loadPatient(_patientResult.patient_id)
          }
       }
        
      )
    }
     else if(e== 'addPatient'){
      const _overlayData:PatientSelectionInputI = {
        startMode:PATIENT_SELECTION_VIEW_MODE.LIST_PATIENTS_MODE,
        uid: this.tc.activeProcess.creator
      }
      this.dialog.open(PatientSelectionComponent,{
        disableClose:true,
        width:"70%",
        maxWidth:"1200px",
        height:"560px",
        backdropClass:"backdrop-background",
        data:_overlayData
      }).afterClosed().
      subscribe(
        (_patientResult: PatientFlatI)=> {
           if(_patientResult != null){
            if(this.tc.baseTemplateType == BASE_TEMPLATE_TYPE.PROCESS) {

              this.tc.updateProcessItem({patient_id:_patientResult.patient_id});
              this.tc.activeTemplate.patient_id = _patientResult.patient_id
            } else {

              this.tc.updateTemplateItem({patient_id:_patientResult.patient_id});
              this.tc.activeTemplate.patient_id = _patientResult.patient_id
            }
            this.loadPatient(_patientResult.patient_id)
           }
        }
      )
     }

  }

  openPatientSelections(){
    const _overlayData:PatientSelectionInputI = {
      startMode:PATIENT_SELECTION_VIEW_MODE.LIST_PATIENTS_MODE,
      uid: this.tc.activeProcess.creator
    }
    this.dialog.open(PatientSelectionComponent,{
      disableClose:true,
      width:"70%",
      maxWidth:"1200px",
      height:"560px",
      backdropClass:"backdrop-background",
      data:_overlayData
    }).afterClosed().
    subscribe(
      (_patientResult: PatientFlatI)=> {
         if(_patientResult != null){
          this.tc.activeTemplate.patient_id = _patientResult.patient_id
          this.loadPatient(_patientResult.patient_id)

         }
      }
    );
  }

  showReportView(){
    TweenLite.to(this._contentHolder.nativeElement, 0.5,
    { 'opacity': 1, 'y': 0, delay:0.15, ease: Sine.easeOut});
  }

  showNachbehandlungView(){
    TweenLite.to(this._contentHolder.nativeElement, 0.5,
    { 'opacity': 1, 'y': 0, delay:0.15, ease: Sine.easeOut});
  }

  showInvoiceView(){
    TweenLite.to(this._contentHolder.nativeElement, 0.5,
    { 'opacity': 1,'y': 0, delay:0.15,  ease: Sine.easeInOut});
  }

  showHistorieView(){
    TweenLite.to(this._contentHolder.nativeElement, 0.5,
    { 'opacity': 1,'y': 0, delay:0.15,  ease: Sine.easeInOut});
  }

  hideContentHolderView(destination:string){
    TweenLite.to(this._contentHolder.nativeElement, 0.45, { 'opacity': 0, 'y': 10, onComplete:()=>{
      if(destination == 'INVOICE'){
        this.injectInvoiceView();
      } else  if(destination == 'REPORT'){
        this.injectReportView();
      } else  if(destination == 'NACHBEHANDLUNG'){
       this.injectNachbehandlungView()
      } else {
        this.injectHistorie()
      }

    }});
  }

  injectReportView(){
    // const __editable:boolean = this.itemsEditable === true && this.tc.activeTemplate.hasSurgeryReport === true ? false : true;
    const item = new ReportView(TemplateReportViewComponent, this.employees, this.tc.activeTemplate,  this.tc.isTemplateEditable);
    const containerRef = this.adHost.viewChildContainerRef;
    containerRef.clear();
    const compFact = this.resolver.resolveComponentFactory(item.component);
    const compRef = containerRef.createComponent(compFact);
    (compRef.instance).employees = item.employees;
    (compRef.instance).template = item.template;
    (compRef.instance).editable = !item.editable;
    (compRef.instance).showSurgeryInfos = true;
    this.showReportView()
  }

  injectNachbehandlungView(){
    const __editable:boolean = this.itemsEditable === true && !this.tc.activeTemplate.hasSurgeryReport === false ? true : false;
    const item = new NachbehandlungView(TemplateNachbehandlungComponent);
    const containerRef = this.adHost.viewChildContainerRef;
    containerRef.clear();
    const compFact = this.resolver.resolveComponentFactory(item.component);
    const compRef = containerRef.createComponent(compFact);
    this.showNachbehandlungView()
  }

  injectInvoiceView(){
    const item = new InvoiceViewProcess(ProcessBehandlungsschritteComponent, this.tc.activeTemplate, this.itemsEditable);
    const compFact = this.resolver.resolveComponentFactory(item.component);
    const containerRef = this.adHost.viewChildContainerRef;
    containerRef.clear();
    const compRef = containerRef.createComponent(compFact);
    (compRef.instance).template = item.template;
    (compRef.instance).editable = !item.editable;
    this.showInvoiceView();
  }

  injectHistorie(){
    const item = new ProcessHistoryView(ProcessHostorieComponent);
    const compFact = this.resolver.resolveComponentFactory(item.component);
    const containerRef = this.adHost.viewChildContainerRef;
    containerRef.clear();
    const compRef = containerRef.createComponent(compFact);
    this.showHistorieView();
  }


  
  // getDateFromSeconds(section:SectionM):Date {
  //   let d:Date  = new Date(section.date)
  //   return d
  // }

  bgContainerHeight(){
    let h = this._bgContainerFuck.nativeElement.offsetHeight;
    let h2 = this._bgContainerFuck.nativeElement.clientHeight;

   /*  console.log(h)
    console.log(h2) */
  }

  tabbarTap(path:string){
    if(path == 'REPORT'){
    /*   if(this.greedBackground){
        return;
      } */
      this.greedBackground = true;
      this.nachbehandlungActive = false;
      this.historyActive = false
      this.hideContentHolderView('REPORT');

    } else if(path == 'INVOICE'){
      if(!this.greedBackground && !this.nachbehandlungActive){
        return;
      }
      this.greedBackground = false;
      this.nachbehandlungActive = false;
      this.historyActive = false
      this.hideContentHolderView('INVOICE')
      // this.selectedTabIndex = 0
    } else if(path == 'NACHBEHANDLUNG'){
      if(this.nachbehandlungActive){
        return;
      }
      this.nachbehandlungActive = true;
      this.greedBackground = false;
      this.historyActive = false
      this.hideContentHolderView('NACHBEHANDLUNG')
      // this.selectedTabIndex = 0
    } else if(path == 'HISTORY'){
      if(this.historyActive){
        return;
      }
      this.historyActive = true
      this.nachbehandlungActive = false;
      this.greedBackground = true;
      this.hideContentHolderView('HISTORY')
      // this.selectedTabIndex = 0
    }
  }

  // get processStates() :IProcessState[] {
  //   return this.ui.processStates
  // }

  // get isTemplateEditable():boolean{

  //   if(this.tc.baseTemplateType == BASE_TEMPLATE_TYPE.PROCESS){
  //     if(this.tc.activeProcess.invoice_id != null && this.tc.activeProcess.invoice_id != -1) {
  //       return false
  //     }
  //     if(this.tc.activeProcess.surgery_report_id != null && this.tc.activeProcess.surgery_report_id != -1) {
  //       return false
  //     }
  //     return true
  //   } else {
  //     return this.tc.isTemplateEditable
  //   }

  // }

  ngOnDestroy(): void {
    this._cancelSubscription();
  
  }

  private _cancelSubscription(){

    this.tc.destroyTemplateById(this.documentId)
    // if(this.processloadedSubscription){
    //   this.processloadedSubscription.unsubscribe();
    // }
    if(this.saveSubscription){
      this.saveSubscription.unsubscribe();
    }
    if(this.deleteSubscription){
      this.deleteSubscription.unsubscribe();
    }
    if(this.duplicateSubscription){
      this.duplicateSubscription.unsubscribe();
    }

    if(this.processloadedSubscription){
      this.processloadedSubscription.unsubscribe();
    }
  
    if(this.templateLoadError){
      this.templateLoadError.unsubscribe();
    }
    if(this.ioSubscription != null){
      this.ioSubscription.unsubscribe();
    }
   
  }

}
