import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SurgeryReportI } from '../core/models/surgeryReportI';
import { SurgerySupportPersonI } from '../core/models/v4/surgery.personi';

@Injectable({
  providedIn: 'root'
})
export class SurgeryApiService {

  private _endpoint:string = environment.endpoints.apiBasePathV2
  constructor(private http:HttpClient) { }


  async getReportItemsByPosId(prefix:string, docId:number){
    const _path =  `${this._endpoint}${prefix}/surgeryItems/by-position-id/${docId}`
    const tempatesArray = await this.http.get<any[]>(_path).toPromise()
    .then(
      _data => {
        // console.log(_data)
        return _data;
      }
    )
    return tempatesArray;
  }
  async updateActiveSurgeryReport(prefix:string,position_id:number, active_surgery_report_item_id:number){
    const _path =  `${this._endpoint}${prefix}/surgeryItems/updateActiveSurgeryReport/${position_id}/${active_surgery_report_item_id}`
    const _result = await this.http.get(_path).toPromise().then(
      _data => {
        return _data;
      }
    )
    return _result;
  }
  async postSurgeryRepors(prefix:string,repItem:Object){
    const _path =  `${this._endpoint}${prefix}/surgeryItems/add-new/`
    const _result = await this.http.post(_path, {reportItem: repItem}).toPromise();
    return _result;
  }
  async updateSingleReport(prefix:string,repItem:Object, reportItemId:number){
    //${templateDocId}
    const _path =  `${this._endpoint}${prefix}/surgeryItems/update-single/${reportItemId}`
    const _result = await this.http.post(_path, {reportItem:repItem}).toPromise().then(
      _data => {
        return _data;
      }
    )
    return _result;
  }


  async removeSurgeryReportItem(prefix:string,surgery_report_item_id,templateDocId ){
    const _path =  `${this._endpoint}${prefix}/surgeryItems/remove-report-item/${surgery_report_item_id}/${templateDocId}`
    const _result = await this.http.get(_path).toPromise().then(
      _data => {
        return _data;
      }
    )
    return _result;
  }



  /**
   *
   * ADDIIONAL SURGERY INFOS
   *
   */

   async postAdditionalsureryInfos(additionalInfos:any, process_id:number){
    // const process_id = 8;
    const _payload = {
      additionalInfos: additionalInfos
    }
    const _post = await this.http.post(`${this._endpoint}templates/modules/surgery-header/${process_id}`,_payload).toPromise()
    .then(_res => {
      return _res;
    })
    return _post

  }

  async getAdditionalsureryInfos(template_id:number){
    const _r = await this.http.get(`${this._endpoint}templates/modules/surgery-header/${template_id}`).toPromise().then(
      _data => {
        return _data;
      }
    )
    return _r;
  }
  async getSurgerySupport(template_id:number){
    const _r = await this.http.get(`${this._endpoint}templates/modules/surgery-header/surgery-support/${template_id}`).toPromise().then(
      _data => {
        return _data;
      }
    )
    return _r;
  }

  async addSurgerySupport(template_id:number, supportItem:SurgerySupportPersonI){
    const _r = await this.http.put(`${this._endpoint}templates/modules/surgery-header/surgery-support/${template_id}`,{
      supportItem
    }).toPromise().then(
      _data => {
        return _data;
      }
    )
    return _r;
  }
  async changeSurgerySupport(template_id:number, id:number, employee_id:number){
    const _r = await this.http.post(`${this._endpoint}templates/modules/surgery-header/surgery-support-change/${template_id}`,{
      supportItem:{
        id:id, employee_id:employee_id
      }
    }).toPromise().then(
      _data => {
        return _data;
      }
    )
    return _r;
  }

  async removeSurgerySupportById(id:number){
    await this.http.delete(`${this._endpoint}templates/modules/surgery-header/surgery-support/${id}`).toPromise();
  }



  /**
   *
   * Surgery Report Preview
   *
   */

  async unlockSurgeryReportById(process_id:number){
    const _r = await this.http.get(`${this._endpoint}processes/surgeryItems/surgery-report-unlock/${process_id}`).toPromise()
    return _r;
  }
  async getSurgeryReportById(process_id:number){
    const _r = await this.http.get<SurgeryReportI>(`${this._endpoint}processes/surgeryItems/surgery-report/${process_id}`).toPromise()
    return _r;
  }
  async getSurgeryReportPreview(process_id:number){
    const _r = await this.http.get<SurgeryReportI>(`${this._endpoint}templates/surgeryItems/surgery-report/${process_id}`).toPromise().then(
      _data => {
        return _data;
      }
    )
    return _r;
  }
  async updateSurgeryReportPreview(process_id:number, item:Object){
    const _r = await this.http.post<string>(`${this._endpoint}templates/surgeryItems/surgery-report-update/${process_id}`,{
      report: item
    }).toPromise()
      return _r;
    }
  async finalizeSurgeryReportPreview(process_id:number, item:Object){
    const _r = await this.http.post<string>(`${this._endpoint}processes/surgeryItems/surgery-report-finalized/${process_id}`,{
      report: item
    }).toPromise()
      return _r;
    }

    /**
     *
     * Surgery Report POSITIONS
     *
     */

    async getSurgeryReportPositions(prefix:string, process_id:number){
      const _r = await this.http.get<any[]>(`${this._endpoint}${prefix}/surgeryItems/surgery-report-positions/${process_id}`).toPromise().then(
        _data => {
          return _data;
        }
      )
      return _r;
    }


}
