import { Component, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AUTH_CALL } from 'src/app/core/models/auth/auth.enum';
import { AutocompleteDirective } from 'src/app/directives/autocomplete.directive';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  providers:[AutocompleteDirective]
})
export class ChangePasswordComponent implements OnInit {

  charLengthOk = false
  specialCharOk = false
  upperCaseCharOK = false
  saveNewPasswordEnabled = false

  firstPassword:string = ''
  secondPassword:string = ''
  constructor(

    private authService:AuthService,
    private dialogRef:MatDialogRef<ChangePasswordComponent>) { }

  ngOnInit(): void {
    this.firstPassword = ''
    this.secondPassword = ''


  }
  async setNewPassword(){
     const result = await this.authService.POST(AUTH_CALL.POST_CHANGE_PASSWORD,
      {payload: {password: this.firstPassword}})
     this.dialogRef.close();
     if(result['ready'] == 'yes'){
       this.trySignIn(result['email'] , this.firstPassword)
     }
   }


   passwordChanged(e){
     this.firstPassword = e.target.value
     this.upperCaseCharOK = this.hasUpperCase(e.target.value)
     this.specialCharOk = this.hastSpecialChar(e.target.value)
     this.charLengthOk = e.target.value.length > 7;
     this.checkValidation()
   }
   secondPasswordChanged(e){
    this.secondPassword = e.target.value
     this.checkValidation()
   }
   private hastSpecialChar(str) {
     return (/[#,$,@,&,*,!,%]/.test(str));
   }
   private hasUpperCase(str) {
     return (/[A-Z]/.test(str));
   }
   checkValidation(){
     //this.emailFormControl.valid
      if(this.upperCaseCharOK &&
      this.specialCharOk &&
      this.charLengthOk && this.secondPassword === this.firstPassword) {
         this.saveNewPasswordEnabled = true;
       }
   }
   async trySignIn(email:string, password:string){
    const result = await this.authService.SignIn(email, password)
   }

   save(){

   }
   close(){
    this.dialogRef.close();
   }
}
