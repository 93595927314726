<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <!-- <button mat-icon-button (click)='close()'><mat-icon>close</mat-icon></button> -->
  <mat-icon (click)='close()' class='justification_arrow_icon ml10' style="font-size: 20px!important;">close</mat-icon>
</mat-toolbar>
<div class="container " style="padding:32px!important; height: calc(100% - 88px);">
  
  <div class="row" *ngIf='materialItem !== null'>
    
    <div class="col-9 verySmallGutter">
      <mat-label>Bezeichnung</mat-label>
      <textarea class="mat-body formControl" rows="3" maxlength="200"
      [(ngModel)]="materialItem.bezeichnung"></textarea>
    </div>

    <div class="col-3 verySmallGutter">
      <mat-label >Preis</mat-label>
      <input class="formControl mat-body " style="text-align: right!important;"
      (onfocus)="onPreisFocus()"
      #_preisTF [(ngModel)]="materialItem.preis">
    </div>

  </div>
</div>
<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <app-df-icon-button
  title="Speichern"
  icon="save"
  (clicked)="save()"
  >
  </app-df-icon-button>  
</mat-toolbar>