import { Component, OnInit, ViewChild, Input, EventEmitter, Output, ElementRef } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SectionM, SectionMDateChanged } from 'src/app/core/models/sectionM';
import { MatDialog } from '@angular/material/dialog';
import { TexteditOverlayComponent } from 'src/app/ui/overlays/textedit-overlay/textedit-overlay.component';
import { TextEditI } from 'src/app/core/models/textOverlayEditI';
import { SECTION_HEADER_SUMMERY } from 'src/app/utils/helpers';
import { addDays } from 'date-fns';
import { DatePipe } from '@angular/common';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { CustomProgressionFactorI } from 'src/app/core/models/customPositionProgressionFactor';
import { BASE_TEMPLATE_TYPE } from 'src/app/core/models/enums';

declare var TweenMax: any;
import * as _ from "lodash"
@Component({
  selector: 'template-section-item',
  templateUrl: './template-section-item.component.html',
  // styleUrls: ['./template-section-item.component.scss']
  styleUrls: ['../sections.scss'],
  providers:[DatePipe]
})
export class TemplateSectionItemComponent implements OnInit {

  @ViewChild(MatExpansionPanel, {static: true}) panel: MatExpansionPanel;
  @ViewChild('_panel') _panel:HTMLElement;
  @ViewChild('_exp_panel', {static: true}) _exp_panel:ElementRef;
  @Input() section: SectionM;
  @Output() sectionDateChanged:EventEmitter<SectionMDateChanged> = new EventEmitter<SectionMDateChanged>();


  resizeTimeout: any;
  isLoading = true;

  constructor(
    public dialog:MatDialog,
    public tc:TemplateControllerService,
    
    private datePipe: DatePipe) { }

  isItemOpen:boolean = false;
  isDateEditMode:boolean = false;
  isTitleEditMode:boolean = false;
  title = "Präoperative Behandlung";
  panelOpenState : boolean = false;
  summe = ""
  _customProgressFactors:CustomProgressionFactorI[] = []

  ngOnInit(): void {
    
    this.summeryChanged();   
    this.tc.getSectionMaterialItems(this.tc.prefix, this.section.section_id, true)
 
  }
 

  onAfterCollapse(){
    console.log('onAfterCollapse')
    this.isItemOpen = this.panel.expanded
    this.isLoading = true;
  }

  onAferExpanded(){
    console.log('onAferExpanded')
    this.isItemOpen = this.panel.expanded
    this.checkCustoms();
  }

  async checkCustoms(){
    // const _res = await this.tc.getCustomProgressionFactorBySectionId(this.tc.prefix, this.section.section_id)
    // if(_res){
    //   this._customProgressFactors = _res as CustomProgressionFactorI[]
    //   console.log(this._customProgressFactors);
      
    //   this.isLoading = false;
      
    // }
    this.isLoading = false;
  }
  deletePositonAtIndexCallback(pPositionIndex:number){
    console.log('delete detected ', pPositionIndex)
  }
  openPanelCallback(e){

  }
  // get customProgressFactors():CustomProgressionFactorI[] {
  //   return this._customProgressFactors
  // }
  
  toggleOpenClose(e:HTMLElement){
    if(this.panel.expanded){
      this.panel.close();
    } else {
      this.panel.open();
      setTimeout(()=>{
        e.scrollIntoView({behavior:"smooth"});
      },650)
    }
  }

  editTitle(){
    if (!this.tc.canRenameTemplate) { return }
    let d:TextEditI = {'singleline':false, 'text':this.section.name, "title":'Bezeichnung des Abschnittes ändern', largeFont: true}
    this.dialog.open(TexteditOverlayComponent,{
      width: "70%",
      maxWidth:"750px",
      data:d,
      backdropClass:"backdrop-background",

    }).afterClosed().subscribe(
      _newText => {
        if(_newText != null){
          this.section.name = _newText
          let _section_id:number = this.section.section_id
          this.tc.updateSingleSectionItem(this.tc.prefix, _section_id, {name: _newText})
        }
      }
    )
  }
  summeryChanged(){
    return SECTION_HEADER_SUMMERY(
      this.tc.positionForSectionId(this.section.section_id),
      this.tc.allSectionMaterialItems.filter((item) => {return item.section_id == this.section.section_id}),
      false
      ) as number;

  }


  getDateFromSeconds(section:SectionM):Date {
    /* let d:Date  = new Date(section.date.seconds) */
    let d:Date  = new Date(this.tc.activeTemplate.date)
    if(section.treated_at_difference_days != null ){
      // && section.treated_at_difference_days > 0
      d = addDays(d, section.treated_at_difference_days)
    }
    return d
  }

  getSectionDiffDaysText(section:SectionM):string {
    var output = '   '
    /* var output = 'Behandlungsdatum ' */
    if(this.tc.baseTemplateType == BASE_TEMPLATE_TYPE.PROCESS){
      output = this.datePipe.transform(this.getDateFromSeconds(section), 'dd.MM.yyyy')
    } else {
      if(section.treated_at_difference_days > 0 || section.treated_at_difference_days < 0){
        output += section.treated_at_difference_days < 0 ? ' ' : ' + '
        output += section.treated_at_difference_days.toString()
      }


      if(section.treated_at_difference_days == 1 || section.treated_at_difference_days == -1){
        output += ' Tag '
      } else if(section.treated_at_difference_days > 1 || section.treated_at_difference_days < -1){
        output += ' Tage'
      }
    }
    return output;
  }

  sectionDayAdd(){
    this.section.treated_at_difference_days == null ? this.section.treated_at_difference_days = 1 : this.section.treated_at_difference_days +=1;
    this.sectionDateChanged.emit({section: this.section});
  }

  sectionDaySub(){
    this.section.treated_at_difference_days == null ? this.section.treated_at_difference_days = 0 : this.section.treated_at_difference_days -=1;
    // || this.section.treated_at_difference_days == 0 ?
    this.sectionDateChanged.emit({section: this.section});
    /* this.sectionDateChanged.emit({sectionIndex:this.section.index, treated_at_difference_days:this.section.treated_at_difference_days}); */
  }
  dateEditTapped(){
    this.isDateEditMode = !this.isDateEditMode;
  }
  titleEditTapped(){
    this.isTitleEditMode = !this.isTitleEditMode;
  }
  async duplicateSection(){
    await this.tc.duplicateSection(this.section.section_id, this.tc.currentTemplateId, this.tc.prefix);
    this.tc.reInitTemplateSectionsAndPositions(true)
  }
  async deleteSection(){
    await this.tc.deleteSection(this.section.section_id, this.tc.currentTemplateId, this.tc.prefix);
    setTimeout(()=> {
      this.tc.initTemplatePositions(this.tc.currentTemplateId, this.tc.prefix)
    },150)
  }


}
