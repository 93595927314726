import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
@Pipe({
  name: 'cgcurrency'
})
export class CgcurrencyPipe implements PipeTransform {

  transform(
    value:number,
    currencyCode: string = 'EUR',
    display:
        | 'code'
        | 'symbol'
        | 'symbol-narrow'
        | string
        | boolean = 'symbol',
    digitsInfo: string = '.2-2',
    locale: string = 'de',
): string | null {
  return value > 0 ? 
    formatCurrency(
      value,
      locale,
      getCurrencySymbol(currencyCode, 'wide'),
      currencyCode,
      digitsInfo,
    ) : '';
}

}
