
<div class="container p_t40" *ngIf='patient'>
  
  <div class="row">

      <div class="col-lg-2 col-md-2 col-sm-12">
        <patient-salutation-selection 
        [patient]="patient"
        (onChanged)="textChanged('e')"
        ></patient-salutation-selection>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-12">
        <patient-gender-selection
        [patient]="patient"
        (onChanged)="textChanged('e')"
        ></patient-gender-selection>
      </div>


    <div class="col-lg-4 col-md-6 col-sm-12">
      <mat-label class='mat-caption' >Vorname*</mat-label>
      <input class="formControl mat-body" [(ngModel)]="patient.firstName" required 
      [class.formControlErrorAddress]="firstNameError"
       (keyup)='textChanged($event)'> 
    </div>

    <div class="col-lg-4 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Nachname*</mat-label>
        <input class="formControl mat-body" [(ngModel)]="patient.lastName" 
        [class.formControlErrorAddress]="lastNameError"
         (keyup)='textChanged($event)'>
    </div>
  </div> 

  <div class="row p_t20">
    <div class="col-lg-6 col-md-8 col-sm-12">
      <mat-label class='mat-caption'>Name Rechnungsempfänger <i>(falls abweichend)</i></mat-label>
        <input class="formControl mat-body" [(ngModel)]="patient.invoiceName" (keyup)='textChanged($event)'>
    </div>
  </div>
  
  <hr>

  <div class="row ">
    <div class="col-lg-5 col-md-5 col-sm-12">
      <mat-label class='mat-caption'>Straße*</mat-label>
        <input class="formControl mat-body" [(ngModel)]="patient.street" 
        [class.formControlErrorAddress]="streetError" (keyup)='textChanged($event)'>
    </div>

    <div class="col-lg-1 col-md-1 col-sm-12">
      <mat-label class='mat-caption'>Hausnummer</mat-label>
        <input class="formControl mat-body" [(ngModel)]="patient.streetNumber"
        [class.formControlErrorAddress]="streetError"
          (keyup)='textChanged($event)'>
    </div>

    <div class="col-lg-2 col-md-2 col-sm-6">
      <mat-label class='mat-caption'>Postleitzahl*</mat-label>
        <input class="formControl mat-body" [(ngModel)]="patient.zip"
        [class.formControlErrorAddress]="zipError"
          (keyup)='textChanged($event)'>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <mat-label class='mat-caption'>Ort*</mat-label>
        <input class="formControl mat-body" [(ngModel)]="patient.city"
        [class.formControlErrorAddress]="cityError" (keyup)='textChanged($event)'>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-12 p_t20">
      <mat-label class='mat-caption'>Adresszusatz</mat-label>
        <input class="formControl mat-body" [(ngModel)]="patient.addon" (keyup)='textChanged($event)'>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-12 p_t20">
      <mat-label class='mat-caption'>Telefon</mat-label>
        <input class="formControl mat-body" [(ngModel)]="patient.phone" (keyup)='textChanged($event)'>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-12 p_t20">
      <mat-label class='mat-caption'>Email</mat-label>
        <input class="formControl mat-body" [(ngModel)]="patient.email" (keyup)='textChanged($event)'>
    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col-lg-4 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Geburtsdatum</mat-label>
        <div class="item-text-style" 
        [class.formControlErrorAddress]="bithDateError"
        (click)='onBirthDateEdit()' style="height: 34px;">
          {{patient.birthDate | date: 'dd.MM. yyyy'}}
        </div>
    </div>

    <div class="col-lg-8 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Krankenversicherung</mat-label>
      <input type="text" class="formControl mat-body " #_goaTF
               placeholder="Krankenversicherung hinzufügen"
               [(ngModel)]="insurance"     
               (keyup)="applyFilter($event.target.value)"
               [matAutocomplete]="auto" style="max-width: 100%;"
        >
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)='secletedChanged($event)' [displayWith]="displayFn">
          <cdk-virtual-scroll-viewport itemSize="48" style="height: 142px;">
            <mat-option *cdkVirtualFor="let option of filteredOptions" [value]="option" class="mat-body"  >
              {{option.title}}
            </mat-option>  
          </cdk-virtual-scroll-viewport>
          
        </mat-autocomplete>
    </div>
  
 </div>

</div>
