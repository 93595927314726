import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {  ITextitemtilesOverlayData, TEXT_ITEM_FOCUS_ELEMENT, template_item_text_tiles, template_item_text_tiles_local } from 'src/app/core/models/api/templateUtils';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import * as _ from "lodash";
import { MatDialog } from '@angular/material/dialog';
import { HORIZONTAL_ITEM_SELECTION_ACTION } from 'src/app/core/models/horizontalItemSelectionAction';
import { TextitemtilesOverlayComponent } from '../../components/textitemtiles-overlay/textitemtiles-overlay.component';

@Component({
  selector: 'app-template-note-method-diagnose',
  templateUrl: './template-note-method-diagnose.component.html',
  styleUrls: ['./template-note-method-diagnose.component.scss']
})
export class TemplateNoteMethodDiagnoseComponent implements OnInit, OnDestroy {

  @Input() type:string;
  @Input() templateDocId:string;
  @Input() title:string = ''

  private items:template_item_text_tiles[] = [];

  currentIndex = 0
  
  isLoading = true;
  
  _checkTimeout: any;
  FOCUS_ITEMS = TEXT_ITEM_FOCUS_ELEMENT
  
  constructor(
    public tc: TemplateControllerService,
    private dialog: MatDialog
    ) { }
    
    ngOnInit(): void {
      setTimeout(()=>{
        this._initItems()
      },1000)
      
    }

    private  async _initItems(){
    this.isLoading = true;


    
/* 
      this.items = this.tc.tempalteTilesAll.filter(item => item.type ==  this.type)
      this.currentIndex = 0

      if(this.items.length == 0) {
        setTimeout(()=>{
          this.isLoading = false;
          this.currentIndex = 0;
        },10)
        return;
      }

      let _lastModified = 0
      let _newIndex = 0
      
      for (var i = 0; i < this.items.length; i ++) {
        if(this.items[i].lastModified > _lastModified){
          _lastModified = this.items[i].lastModified
          _newIndex = i
        }
      
      }
      setTimeout(()=>{
        this.isLoading = false;
        this.currentIndex = _newIndex;
      },10) */

    const _response = await this.tc.check_template_item_text_tiles(this.type);
    
    if(_response == "finished") {
      
      this.items = []

      
      this.items = this.tc.getTemplate_item_text_tiles_by_type(this.type)
      
      this.currentIndex = 0

      if(this.items.length == 0) {
        setTimeout(()=>{
          this.isLoading = false;
          this.currentIndex = 0;
        },10)
        return;
      }

      let _lastModified = 0
      let _newIndex = 0
      
      for (var i = 0; i < this.items.length; i ++) {
        if(this.items[i].lastModified > _lastModified){
          _lastModified = this.items[i].lastModified
          _newIndex = i
        }
      
      }
      setTimeout(()=>{
        this.isLoading = false;
        this.currentIndex = _newIndex;
      },10)
    } else {
      setTimeout(()=>{
        this.isLoading = false;
        this.currentIndex = 0;
      },10)
    }
   
    
  }

  get _tiles():template_item_text_tiles[] {
    /* if(this.tc.getTemplate_item_text_tiles_by_type(this.type) */
    return this.items//this._Initialtiles
  }
  get itemCount():number {
    if(this.isLoading) return 0;
    const __l = this._tiles.length
    return __l != null ?  __l : 0
  }
  get current_tile(): template_item_text_tiles {
    if(this.currentIndex >= this.itemCount) {return null}
    return this._tiles[this.currentIndex];
  }

  async save(item:template_item_text_tiles, reload:boolean = true){
    if(!this.tc.isTemplateEditable){
      return
    }
    const __save = await this.tc.update_item_text_tile(this.type, this.templateDocId, item)
    if(reload){
      this._initItems()
    }
  }

  get getTitle(){
    if(this.isLoading) return;
    if(this.itemCount == 0 || this.itemCount < this.currentIndex){
      return ""
    } else {
      return this.current_tile != null ? this.current_tile.description : ""
    }
  }

  get getCopy(){
    if(this.isLoading) return;
    if(this.itemCount == 0 || this.itemCount < this.currentIndex){
      return ""
    } else {
      return this.current_tile != null ? this.current_tile.text : ""
    }
  }

  async createNewItem(){
    if(!this.tc.isTemplateEditable){
      return
    }
    const description = `Variante ${this.items.length + 1}`;
    await this.tc.add_new_item_text_tile(this.type, description, "");
    this._initItems()
      setTimeout(()=>{
          
          this.openEditWin(TEXT_ITEM_FOCUS_ELEMENT.TEXT_FOCUS)
        },100)
  }

  ngOnDestroy(): void {
  /*   console.log("ngOnDestroy ", this.type) */
  }


  async onSelectionAction(e: HORIZONTAL_ITEM_SELECTION_ACTION) {
    console.log(e);
    switch (e) {
      case HORIZONTAL_ITEM_SELECTION_ACTION.ADD:
         this.openEditWin(TEXT_ITEM_FOCUS_ELEMENT.ADD)
        return;
      case HORIZONTAL_ITEM_SELECTION_ACTION.REMOVE:
         this.openEditWin(TEXT_ITEM_FOCUS_ELEMENT.REMOVE)
        return;
      case HORIZONTAL_ITEM_SELECTION_ACTION.NEXT:
         this.openEditWin(TEXT_ITEM_FOCUS_ELEMENT.NEXT)
        return;
      case HORIZONTAL_ITEM_SELECTION_ACTION.PREV:
         this.openEditWin(TEXT_ITEM_FOCUS_ELEMENT.PREV)
        return;
    }
  }

  openEditWin(focus: TEXT_ITEM_FOCUS_ELEMENT) {
    if(!this.tc.isTemplateEditable){
      return
    }

    let olData: ITextitemtilesOverlayData = {
      type: this.type,
      items: this.items,
      focus: focus
    }
    this.dialog.open(TextitemtilesOverlayComponent,{
      width:'75%',
      minHeight:'400px',
      maxWidth:"1200px",
      backdropClass:"backdrop-background",
      data: olData,
      disableClose: false,

    }).afterClosed().subscribe((items: template_item_text_tiles[]) => { 
      if (items) { 
        this.items = items;
        let _lastModified = 0
        let _newIndex = 0
        
        for (var i = 0; i < this.items.length; i ++) {
          if(this.items[i].lastModified > _lastModified){
            _lastModified = this.items[i].lastModified
            _newIndex = i
          }

        }
        setTimeout(()=>{
          this.isLoading = false;
          this.currentIndex = _newIndex;
        },10)
      } else {
        this._initItems()
      }
    })

  }
}
