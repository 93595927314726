<mat-accordion>
  <mat-expansion-panel expanded='true' (afterExpand)="afterCollapse($event)">
    <mat-expansion-panel-header style="cursor: pointer!important;">
      <mat-panel-title>Allgemeine Informationen</mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container-fluid" style="background: linear-gradient(0deg, transparent ,rgba(0,0,0,0.04));">
      <div class="row justify-content-between p_t20">

        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12">


          <div class="row d-flex">
            <div class="col-4">
              <div class="d-flex flex-column supportItem">
                <div class="p-0">
                  <mat-label class='mat-caption'>OP-Datum</mat-label>
                </div>
                <div class="p-0 supportUserItem" [class.itemDisabled]="!editable"  (click)='openSurgeryDateStartSelection()'>
                  <div class="d-flex">
                    <div class="p-0" #_surgeryDateStart>
                      {{surgeryDate | cgDate}}
                      <!-- {{_surgeryDateStartOutPut}} -->
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>


          <div class="row d-flex p_t20">

              <div class="col-4" style="width: 155px!important;">
                <mat-label class='mat-caption'>Uhrzeit Beginn</mat-label>
                <input  type="time" class="formControl mat-body" placeholder="00:00" #_startTime  [class.itemDisabled]="!editable" (change)="surgeryStartTimeChanged($event)">
              </div>
              <div class="col-4" style="width: 155px;">
                <mat-label class='mat-caption'>Uhrzeit Ende</mat-label>
                <input type="time" class="formControl mat-body" placeholder="00:00" #_endTime  [class.itemDisabled]="!editable" (change)="surgeryEndTimeChanged($event)">
              </div>
              <div class="col-4">
                <mat-label class='mat-caption'>Dauer</mat-label>
                <p class="mat-body p_t5">{{surgeryDuration}}</p>
              </div>
            </div>



          <div class="row" *ngIf="headerInfos">
            <div class="col-6">
              <div class="row m_t15">
                <div class="col-12 d-2">
                  <mat-label class='mat-caption'>Lokalisation</mat-label>
                  <mat-form-field appearance="outline" class="textfield100 select-form-field"  >
                    <mat-select [(value)]="headerInfos.lokalisation" (selectionChange)='save()' [disabled]="!editable" >
                      <mat-option *ngFor="let _lla of lokalisierungItems" [value]="_lla.text">
                        {{_lla.text}}
                      </mat-option>

                      <mat-option (click)="addOpZeug(lla_item_types.LOKALISIERUNG)"
                      *ngIf="lokalisierungItems.length == 0 && !ui.isAccountant">Neues Element</mat-option>
                      <div *ngIf="lokalisierungItems.length > 0 && !ui.isAccountant"
                        class="d-flex align-items-center add-button-select" (click)="addOpZeug(lla_item_types.LOKALISIERUNG)">
                        <div class="p-2  btn-copy pl12 p_t5 p_b5">Neues Element</div>
                        <div class="p_2 align-text-end ml-auto">
                          <mat-icon class="nav-icon-mini">add</mat-icon>
                        </div>
                      </div>

                    </mat-select>
                  </mat-form-field>

                </div>
                <div class="col-12 d-2 p_t20">
                  <mat-label class='mat-caption'>Lagerung</mat-label>
                  <mat-form-field appearance="outline" class="textfield100 select-form-field" >
                    <mat-select [(value)]="headerInfos.lagerung" (selectionChange)='save()' [disabled]="!editable">
                      <mat-option *ngFor="let _lla of lagerungItems" [value]="_lla.text">
                        {{_lla.text}}
                      </mat-option>
                      <mat-option *ngIf="lagerungItems.length == 0 && !ui.isAccountant"
                        (click)="addOpZeug(lla_item_types.LAGERUNG)">Neues Element</mat-option>

                      <div mat-option *ngIf="lagerungItems.length > 0 && !ui.isAccountant"
                      class="d-flex align-items-center add-button-select" (click)="addOpZeug(lla_item_types.LAGERUNG)">
                        <div class="p-2  btn-copy pl12 p_t5 p_b5">Neues Element</div>
                        <div class="p_2 align-text-end ml-auto">
                          <mat-icon class="nav-icon-mini">add</mat-icon>
                        </div>
                      </div>
                    </mat-select>
                  </mat-form-field>

                </div>
                <div class="col-12 d-2 p_t20">
                  <mat-label class='mat-caption'>Blutsperre in Minuten</mat-label>
                  <input class="formControl mat-body" placeholder="Blutsperre in Minuten"
                   [(ngModel)]="headerInfos.blutsperre"  (change)='save()' [disabled]="!editable">
                </div>
              </div>

            </div>

            <div class="col-6">

              <div class="row d-flex">


                <div class="col-12 d-2 p_t20" >
                  <mat-label class='mat-caption'>Periop. Antibiose</mat-label>

                  <mat-form-field appearance="outline" class="textfield100 select-form-field" >
                    <mat-select [(value)]="headerInfos.antibiose" (selectionChange)='save()' [disabled]="!editable" >
                      <mat-option *ngFor="let _lla of antibioseItems" [value]="_lla.text">
                        {{_lla.text}}
                      </mat-option>
                      <mat-option *ngIf="antibioseItems.length == 0 && !ui.isAccountant"
                      (click)="addOpZeug(lla_item_types.ANTIBIOSE)">Neues Element</mat-option>
                      <div mat-option *ngIf="antibioseItems.length > 0 && !ui.isAccountant"
                      class="d-flex align-items-center add-button-select" (click)="addOpZeug(lla_item_types.ANTIBIOSE)">
                        <div class="p-2  btn-copy pl12 p_t5 p_b5">Neues Element</div>
                        <div class="p_2 align-text-end ml-auto">
                          <mat-icon class="nav-icon-mini">add</mat-icon>
                        </div>
                      </div>
                    </mat-select>
                  </mat-form-field>

                </div>
              </div>
            </div>
          </div>


        </div>


        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12" *ngIf="!ui.isClinicUser">

          <div class="d-flex flex-column">

            <div class="p-0 align-self-end" *ngIf="editable">

              <app-df-icon-button
              title="OP Team hinzufügen"
              icon="add"
              color="primary"
              (clicked)="addSurgeryDudes()"
              >
              </app-df-icon-button>

            </div>
            <div class="p-2 align-self-end" style="width: 80%; padding-right: 0px!important;">

              <div class="d-flex flex-column supportItem" *ngFor="let supportDude of surgerySupportUser">

                <surgery-header-support-item
                [employees]="employees"
                (reloadSupportUsers)="reloadSupport()"
                (deleteSupportEvent)="deleteSupport($event)"
                [editable]="editable" [supportUser]="supportDude"></surgery-header-support-item>

              </div>

            </div>

            <div class="p-0">

            </div>
          </div>
        </div>


      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
