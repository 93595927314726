import { AccountI } from './accountI';
import { TemplateFlatI } from './api/TemplateFlatI';
import { InvoiceI } from './invoiceI';
import { Patient } from './patient';


export interface PdfPreviewI{
  template:TemplateFlatI;
  patient?:Patient;
  invoice?:InvoiceI;
  userAccount?:AccountI;
  type:PdfPreviewI.Type;
  invoice_id?:number
}
export namespace PdfPreviewI {
  export type Type = 'INVOICE' | 'SURGERYREPORT' | 'SCSREPORT' | 'FINALINVOICE' | 'SHOWINVOICE' | 'FINALSURGERYREPORT' ;
  export const Type = {
    INVOICE: 'INVOICE' as Type,
    SURGERYREPORT: 'SURGERYREPORT' as Type,
    SCSREPORT: 'SCSREPORT' as Type,
    FINALINVOICE: 'FINALINVOICE' as Type,
    SHOWINVOICE: 'SHOWINVOICE' as Type,
    FINALSURGERYREPORT: 'FINALSURGERYREPORT' as Type,
  }
}