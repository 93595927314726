
import { Component,ViewChild, ElementRef, EventEmitter, ComponentFactoryResolver, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TextEditI } from 'src/app/core/models/textOverlayEditI';
import { TexteditOverlayComponent } from 'src/app/ui/overlays/textedit-overlay/textedit-overlay.component';
import { ControllerService } from 'src/app/shared/controller.service';
import { AddComponentDirective } from 'src/app/directives/add-component.directive';
declare var TweenMax: any;
import {TweenLite} from 'gsap';
import { Sine, Expo} from 'gsap/all';
import { InvoiceView, NachbehandlungView, ReportView } from 'src/app/utils/helpers';
import { TemplateReportViewComponent } from 'src/app/ui/widgets/template-modules/template-report-view/template-report-view.component';
import { TemplateBehandlungsschritteViewComponent } from 'src/app/ui/widgets/template-modules/template-behandlungsschritte-view/template-behandlungsschritte-view.component';
import { DxFxBreadCrumb } from 'src/app/core/models/breadCrumpIntemI';
import { PositionColumStyles } from 'src/app/core/models/positionColSt';
import { Subscription } from 'rxjs';
import { TemplateApiService } from 'src/app/services/template-api.service';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { TemplateNachbehandlungComponent } from 'src/app/ui/widgets/template-modules/template-nachbehandlung/template-nachbehandlung.component';
import { TemplateFlatI } from 'src/app/core/models/api/TemplateFlatI';
import { UiService } from 'src/app/services/ui.service';
import { SPLIT_SCREEN_CHANGED, SPLIT_SCREEN_VIEW_STATE, TemplateInfoOverlayActionE, TemplateInfoOverlayActionResult, TemplateInfoOverlayComponentDataI } from 'src/app/core/models/enums';
import { TemplateInfoOverlayComponent } from 'src/app/ui/overlays/template-info-overlay/template-info-overlay.component';
import { NewTemplateNameComponent } from 'src/app/ui/overlays/new-template-name/new-template-name.component';
import { AccessDirective } from 'src/app/directives/access.directive';
import { PRIVACY } from 'src/app/core/models/api/templatePrivacy';
import { IRenameTemplateOverlayCloseData, IRenameTemplateOverlayData } from 'src/app/core/models/renameTemplateOverlayData.model';

@Component({
  selector: 'app-template-detail',
  templateUrl: './template-detail.component.html',
  styleUrls: ['../templates-details.scss'],
  providers:[AccessDirective]
})
export class TemplateDetailComponent implements OnInit, OnDestroy {
  @ViewChild('_footer') _footer: ElementRef;
  @ViewChild(AddComponentDirective, {static: false}) adHost: AddComponentDirective;
  @ViewChild('_contentHolder', {static: false}) _contentHolder: ElementRef;
  @ViewChild('_templateItems', {static: false}) _templateItems: ElementRef;
  @ViewChild('_templateNavStuff', {static: false}) _templateNavStuff: ElementRef;
  greedBackground = false;
  nachbehandlungActive = false;
  accessDeniedHidden = true;
  documentId = '';
  template_id = -1
  isLoading = true;
  isSaving = false;
  fadeOut = true;
  output = new EventEmitter<string>();
  deleteSectionSubscription: any;
  createdBy = '';
  saveTimeout: any;
  // _currentWindowSize:ScreenSize
  columStyles: PositionColumStyles;
  saveSubscription:Subscription;
  deleteSubscription:Subscription;
  duplicateSubscription:Subscription;
  templateLoadError:Subscription;
  _currentTemplateLoadedSubscription:Subscription;
  resizeSubscr:Subscription;
  _templateSplitScreenToggleSubscription:Subscription;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    public ui:UiService,
    private tempalteApi:TemplateApiService,
    private resolver: ComponentFactoryResolver,
    public template:TemplateControllerService
    ) { }

  ngOnDestroy(): void {
    this._cancelSubscription();
    this.template.destroyTemplateById(this.documentId)
    if(this.ui.splitScreenState != SPLIT_SCREEN_VIEW_STATE.CLOSED){
      this.ui.toggleSplitScreenState(SPLIT_SCREEN_VIEW_STATE.CLOSED)
    }

  }

  ngOnInit(){
    this.route.params.subscribe(
      _p => {
        if(_p.id != null && _p.id != this.template_id){
          this.template_id = _p.id;
          this.template.initTemplateById(this.template_id)
        }
      }
    );
    this._startSubscription();

    setTimeout(() => {
      ControllerService.setCurrentPage('templates');
      const _bc: DxFxBreadCrumb = {
          bcItems: [
            {title:'Vorlagen',path:'templates'},
            {title:'Detail',path:''},
          ]
        };
      ControllerService.setBreadCrumbPath(_bc);
    },1000);
  }

  private _cancelSubscription(){
    if(this._currentTemplateLoadedSubscription){
      this._currentTemplateLoadedSubscription.unsubscribe();
    }
    if(this.saveSubscription){
      this.saveSubscription.unsubscribe();
    }
    if(this.deleteSubscription){
      this.deleteSubscription.unsubscribe();
    }
    if(this.duplicateSubscription){
      this.duplicateSubscription.unsubscribe();
    }
    if(this.templateLoadError){
      this.templateLoadError.unsubscribe();
    }
    if(this.resizeSubscr){
      this.resizeSubscr.unsubscribe();
    }
    if(this._templateSplitScreenToggleSubscription){
      this._templateSplitScreenToggleSubscription.unsubscribe();
    }
  }

  private _startSubscription(){
    this._cancelSubscription();

    this.templateLoadError = this.template.errorTemplateLoading.subscribe(
      _error => {
        this.accessDeniedHidden = false
      }
    )

    this._currentTemplateLoadedSubscription = this.template.currentTemplateLoaded.subscribe(
      _isLoaded => {
        if(_isLoaded && this.template.activeTemplate){
          var timeout = setTimeout(()=>{
            this.isLoading = false;
            this.injectInviewView();
            ControllerService.preloadOverlay.emit(false);
            this.fadeOut = false;
            this.ui.fachgebiet = this.ui.fachgebiete.find(item => item.fachgebiet_id == this.template.activeTemplate.fachgebiet_id)
            ControllerService.preloadOverlay.emit(false);
          },500)
        }
      }
    );

    this._templateSplitScreenToggleSubscription = this.ui.templateSplitScreenViewChanged.subscribe(
      _state => {
        // this.showSplitScreen = _showSplitScreen
        this.toggleSplitScreen(_state)
      }
    )
  }


  toggleSplitScreen(values:SPLIT_SCREEN_CHANGED){
    if(values.oldValue != values.newValue){
      if(values.newValue == SPLIT_SCREEN_VIEW_STATE.CLOSED ){
        this._templateItems.nativeElement.style.visibility = 'unset'
        this._templateNavStuff.nativeElement.style.visibility = 'unset'
        TweenLite.to(this._templateItems.nativeElement, 0.75,
          { opacity: 1, y: 0, height:  'auto', delay: 0.2,  ease: Expo.easeOut});
          TweenLite.to(this._templateNavStuff.nativeElement, 0.75,
          { opacity: 1, y: 0, height:'auto',delay: 0.2,  ease: Expo.easeOut, onComplete(){

          }});
      } else {
        const that = this
        TweenLite.to(this._templateItems.nativeElement, 0.75,
        { opacity: 0, y: -100 , height:  '0px',delay: 0.2,  ease: Expo.easeOut});
        TweenLite.to(this._templateNavStuff.nativeElement, 0.75,
        { opacity: 0 , y: -80, height:  0,delay: 0.2,  ease: Expo.easeOut, onComplete(){
          setTimeout(()=>{
            that.hide()
           },10)
        }});
      }
    }
  }

  hide(){
    //console.log(this._templateItems.nativeElement)
    this._templateItems.nativeElement.style.visibility = 'hidden'
    this._templateNavStuff.nativeElement.style.visibility = 'hidden'
  }
  getTemplate = async () => {
    this.template.initTemplateById(this.template_id)

  }

  asyncOP(str:string){
    ControllerService.preloadOverlay.emit(true);
    setTimeout(()=>{
     if(str === 'publishT'){
       this.publishTemplate()
     } else if(str === 'unpublishT'){
      this.unPublishTemplate()
    }
    },300)
  }

  private overlayOut(reloadTemplate:boolean){
    setTimeout(()=>{
      if(reloadTemplate === true){
        this.getTemplate();
      }
      ControllerService.preloadOverlay.emit(false);
     },300)
  }

  private async publishTemplate(){
    await this.tempalteApi.publishTemplate(this.template.currentTemplateId);
    this.overlayOut(true)
  }

  private async unPublishTemplate(){
    await this.tempalteApi.unpublishTemplate(this.template.currentTemplateId);
    this.overlayOut(true)
  }

  async renameTemplate() {
    if(!this.template.canRenameTemplate){
      return
    }
    const d: TextEditI = {singleline: true, text: this.template.activeTemplate.name, title: 'Vorlage umbenennen', largeFont:true};
    this.dialog.open(TexteditOverlayComponent, {
      width: '600px',
      // height:"400px",
      data: d,
      backdropClass: 'backdrop-background',

    }).afterClosed().subscribe(
      _newText => {
        if (_newText != null){
          this.template.activeTemplate.name = _newText;
          this.template.renameTemplate(_newText, this.template.currentTemplateId)
        }
      }
    );
  }

  tabbarTap(path:string){
    if(path == 'REPORT'){
      if(this.greedBackground){
        return;
      }
      this.greedBackground = true;
      this.nachbehandlungActive = false;
      this.hideContentHolderView('REPORT');
    } else if(path == 'INVOICE'){
      if(!this.greedBackground && !this.nachbehandlungActive){
        return;
      }
      this.greedBackground = false;
      this.nachbehandlungActive = false;
      this.hideContentHolderView('INVOICE')
    } else if(path == 'NACHBEHANDLUNG'){
      if(this.nachbehandlungActive){
        return;
      }
      this.nachbehandlungActive = true;
      this.greedBackground = false;
      this.hideContentHolderView('NACHBEHANDLUNG')
    }
  }

  showReportView(){
    TweenLite.fromTo(this._contentHolder.nativeElement, 0.95, { opacity: 0},
    { opacity: 1,  ease: Expo.easeOut});
  }

  showNachbehandlungView(){
    TweenLite.to(this._contentHolder.nativeElement, 0.5,
    { 'opacity': 1, 'y': 0, delay:0.15, ease: Sine.easeOut});
  }


  injectNachbehandlungView(){
    const item = new NachbehandlungView(TemplateNachbehandlungComponent);
    const containerRef = this.adHost.viewChildContainerRef;
    containerRef.clear();
    const compFact = this.resolver.resolveComponentFactory(item.component);
    const compRef = containerRef.createComponent(compFact);
    this.showNachbehandlungView()
  }

  injectReportView(){
    const item = new ReportView(TemplateReportViewComponent, [], this.template.activeTemplate, this.template.isTemplateEditable);
    const containerRef = this.adHost.viewChildContainerRef;
    containerRef.clear();
    const compFact = this.resolver.resolveComponentFactory(item.component);
    const compRef = containerRef.createComponent(compFact);
    // (compRef.instance).employees = item.employees;
    (compRef.instance).template = item.template;
    (compRef.instance).editable = !item.editable;
    (compRef.instance).showSurgeryInfos = false;
    this.showReportView();
  }

  hideContentHolderView(destination:string){
    TweenLite.to(this._contentHolder.nativeElement, 0.45, { 'opacity': 0, 'y': 10, onComplete:()=>{
      if(destination == 'INVOICE'){
        this.injectInviewView();
      } else  if(destination == 'REPORT'){
        this.injectReportView();
      } else  if(destination == 'NACHBEHANDLUNG'){
      this.injectNachbehandlungView()
      }

    }});
  }
  showInvoiceView(){
    TweenMax.fromTo(this._contentHolder.nativeElement, 0.95, { opacity: 0},
    { opacity: 1,  ease: Expo.easeOut});
  }
  injectInviewView(){
    const item = new InvoiceView(TemplateBehandlungsschritteViewComponent, this.template.activeTemplate, this.template.isTemplateEditable);
    const compFact = this.resolver.resolveComponentFactory(item.component);
    const containerRef = this.adHost.viewChildContainerRef;
    containerRef.clear();
    const compRef = containerRef.createComponent(compFact);
    (compRef.instance).template = item.template;
    (compRef.instance).editable = item.editable;
    this.showInvoiceView();
  }

  addToProcess(){
    if(this.template.activeTemplate.privacy === PRIVACY.USER_OWN) {
      this.checkCopyTemplateToProcesses(this.template.activeTemplate)
    }
  }

  private checkCopyTemplateToProcesses(template: TemplateFlatI){
    const _overlayData:TemplateInfoOverlayComponentDataI = {
      overlayAction: TemplateInfoOverlayActionE.USE_AS_PROCESS,
      template_id: template.template_id,
    }

    this.dialog.open(TemplateInfoOverlayComponent,{
      width:"450px",
      height:"450px",
      disableClose:true,
      backdropClass:"backdrop-background",
      data:_overlayData
    }).afterClosed().subscribe(
      (result:TemplateInfoOverlayActionResult) => {
      if(result != null){
        this.router.navigate([result.path, result.template_id]);
      }
    });
  }


  startCopyTemplate() {
    let olData: IRenameTemplateOverlayData = {
      templateName: this.template.activeTemplate.name,
      poolSelectionRequired: false
    }
    let newName:string = this.template.activeTemplate.name;
    this.dialog.open(NewTemplateNameComponent,{
      width:"850px",
      height:"250px",
      disableClose:true,
      backdropClass:"backdrop-background",
      data: olData
    }).afterClosed().subscribe(
      (result:IRenameTemplateOverlayCloseData) => {
        if(result){
          newName = result.newName
        }
      const _dto = {
          actionType: "CLONE_PUBLIC",
          template_id: this.template.activeTemplate.template_id,
          template_pool_id: null,
          newTemplateName: newName,
          owner_uid: this.ui.account.uid,
          creator_uid: this.ui.account.uid,
          privacy: "USER_OWN"
        }
        this.cloneAsUser(this.template.activeTemplate, _dto, newName)
    });
  }

  private async cloneAsUser(template: TemplateFlatI, dto:Object, newName: string){

    const _overlayData:TemplateInfoOverlayComponentDataI = {
      overlayAction: TemplateInfoOverlayActionE.CLONE_PUBLIC,
      template_id: template.template_id,
      dto: dto,
      templateName: newName
    }

      this.dialog.open(TemplateInfoOverlayComponent,{
        width:"450px",
        height:"450px",
        disableClose:true,
        backdropClass:"backdrop-background",
        data:_overlayData
      }).afterClosed().subscribe(
        (result:TemplateInfoOverlayActionResult) => {
        if(result != null){
          this.router.navigate([result.path, result.template_id]);
        }
      })

  }

}
