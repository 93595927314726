<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <mat-icon  class='justification_arrow_icon' style="font-size: 20px!important;" (click)="close()">close</mat-icon>
</mat-toolbar>
<div class="container" style="height: calc(100% - 88px)">

<div class="d-flex" >
  
  <div class="p-2 w50p">
    <mat-selection-list  [multiple]="false">
      <mat-list-option *ngFor="let expertise of _areaOfExpertiseUser"  [value]="expertise">
        {{expertise.name}} {{expertise.id}} {{expertise.fachgebiet_id}}
      </mat-list-option>
    </mat-selection-list>
    

  </div>

  <div class="p-2 w50p">
  
    <mat-selection-list  [multiple]="true" (selectionChange)="optionChanged($event)" #_options>
      <mat-list-option *ngFor="let expertise of _areaOfExpertisAll"  [value]="expertise" 
      
      [selected]="userHasExpertise(expertise)">
        {{expertise.name}}
      </mat-list-option>
    </mat-selection-list>
  </div>
</div>

</div>
<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <app-df-icon-button
  title="Speichern"
  icon="save"
  (clicked)="save()"
  
  >
  </app-df-icon-button>  


</mat-toolbar>