import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ControllerService } from 'src/app/shared/controller.service';
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';
import { DxFxBreadCrumb } from 'src/app/core/models/breadCrumpIntemI';
import { Subscription } from 'rxjs';
import { MatExpansionPanel } from '@angular/material/expansion';
import { TemplateFlatI } from 'src/app/core/models/api/TemplateFlatI';
import { TemplateApiService } from 'src/app/services/template-api.service';
import * as _ from "lodash";
import { UiService } from 'src/app/services/ui.service';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { TreatmentRegions, TREATMENT_FILTER, TREATMENT_FILTER_LEVEL } from 'src/app/core/models/treatmentRegionM';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { TemplatePoolOverlayComponent } from 'src/app/ui/components/template-pool-overlay/template-pool-overlay.component';
import { IRenameTemplateOverlayCloseData, IRenameTemplateOverlayData, ITemplatePoolOverlayData } from 'src/app/core/models/renameTemplateOverlayData.model';
import { NewTemplateNameComponent } from 'src/app/ui/overlays/new-template-name/new-template-name.component';
import { DATETOSTRING } from 'src/app/utils/helpers';

interface ActiveFilter {
  unpublishedSCS?: boolean;
  publishedSCS?: boolean;
  unpublishedDF?: boolean;
  publishedDF?: boolean;
  ownDF?: boolean;
  ownSCS?: boolean;

}
interface TemplateCreateBtn {
  template_pool_id: number;
  title: string
  privacy: string
}
@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss', '../templates-details.scss'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':enter', [
          style({ opacity: 0  }),
          stagger(35, [
            animate('0.15s ease-out', style({ opacity: 1, marginTop: 0 }))
          ])
        ], { optional: true })
      ])
    ])
  ]
})
export class TemplatesComponent implements OnInit, OnDestroy {

  @ViewChild('_filterPanel', {static: false}) filterPanel: MatExpansionPanel;
  fadeOut = true;
  templateCreateButtons: TemplateCreateBtn[] = []
  templateSort = 'sortPerName'

  isCreatingNewTemplate = false;

  isLoading = true;

  tableIsEmpty = false;

  logAnimation(_event) {
    // console.log(_event)
  }
 
  private _templateSortChangedSubscription:Subscription;

  filterChangedSubscription:Subscription

  enabledRegions = [];

  userShortCut: string;

  isInitialLoading = true;

  ActiveFilters: ActiveFilter;


  // templateSortChanged(e) {
  //   console.log(e);
    
  //   this.templateSort = e.value;
  //   this.joinTemplates();
  // }

  constructor(
    // private dialog: MatDialog,
    public dialog: NgDialogAnimationService,
    public tc:TemplateControllerService,
    private router: Router,
    private templateApi:TemplateApiService,
    public ui:UiService
    ) { }


    ngOnInit(): void {
      setTimeout(() => {
        ControllerService.preloadOverlay.emit(true);
      },150);
    console.log('TemplatesComponent ngOnInit')
      setTimeout(() => {
        this.loadTemplates()

      },150);

      setTimeout(() => {
        ControllerService.setCurrentPage('templates');
        const breadCrump: DxFxBreadCrumb = {
            bcItems: [
              {title: 'Vorlagen', path: ''},
            ]
          };
        ControllerService.setBreadCrumbPath(breadCrump);
      }, 1000);
  }


  // initRoles(){
  //   setTimeout(() => {
  //     this.loadTemplates()
  //   },150);
  // }

  async loadTemplates(){
    this.tc.loadTemplates(this.ui.templatePoolFiltersActive, this.ui.allTreatmentFilter, this.ui.treatmentFilterGroups,this.ui.fachgebiet)
    // this.startSubscription();
    this.initTemplates()
  }
  private startSubscription(){

    if(this.filterChangedSubscription != null ){
      this.filterChangedSubscription.unsubscribe()
    }

    this.filterChangedSubscription = this.ui.filterchangedEmitter.subscribe(
      _filters => {
        console.log("this.ui.filterchangedEmitter");
        console.log(_filters);

        this.joinTemplates();
      }
    )
  }

  private initTemplates() {
    this.startSubscription();
    setTimeout(() => {
      this.initView()
    },550)
  }

  private initView() {
    ControllerService.preloadOverlay.emit(false);
    this.isLoading = false;
    this.fadeOut = false;
    setTimeout(() => {
      this.joinTemplates();
    },150);
    setTimeout(() => {
      this.filterPanel.open();
    },350);
  }



  private joinTemplates(){
    this.createTemplateCreateButtons()
    this.tc.joinTemplateOverview(this.ui.templatePoolFiltersActive, this.ui.allTreatmentFilter, this.ui.treatmentFilterGroups,this.ui.fachgebiet)
  }



  get overviewTemplates(): TemplateFlatI[] {
    
    switch(this.ui.templateSort){
      case "DATE_DESC_NAME_ASC":
        return  _.orderBy(this.tc.overviewTemplates, [
          template => template.lastModified,
          template => template.name.toLowerCase(),
        ], ['desc', 'asc']);
        break;
      case "DATE_ASC_NAME_DESC":
        return  _.orderBy(this.tc.overviewTemplates, [
          template => template.lastModified,
          template => template.name.toLowerCase(),
        ], ['asc', 'desc']);
        break;
      case "DATE_ASC_NAME_ASC":
        return  _.orderBy(this.tc.overviewTemplates, [
          template => template.lastModified,
          template => template.name.toLowerCase(),
        ], ['asc', 'asc']);
        break;
      case "DATE_DESC_NAME_DESC":
        return  _.orderBy(this.tc.overviewTemplates, [
          template => template.lastModified,
          template => template.name.toLowerCase(),
        ], ['desc', 'desc']);
        break;
      case "DATE_DESC":
        return  _.orderBy(this.tc.overviewTemplates, [
          template => template.lastModified,
        ], ['desc']);
        break;
      case "DATE_ASC":
        return  _.orderBy(this.tc.overviewTemplates, [
          template => template.lastModified
        ], ['asc']);
        break;
      case "NAME_DESC":
        return  _.orderBy(this.tc.overviewTemplates, [
          template => template.name.toLowerCase()
        ], ['desc']);
        break;
      case "NAME_ASC":
        return  _.orderBy(this.tc.overviewTemplates, [
          template => template.name.toLowerCase()
        ], ['asc']);
        break;
    }
  }

  applyFilter(filterValue: string) {
    this.tc.overviewTemplatesFilterWith(_.trim(filterValue)); 
  }
  


  createTemplatePool() {
    const data: ITemplatePoolOverlayData = {
      isEditMode: false
    }
    this.dialog.open(TemplatePoolOverlayComponent, {
      width: '70%', 
      maxWidth: "1200px",
      height: '450px',
      backdropClass: "backdrop-background",
      data: data
    }).afterClosed().subscribe((x:any) => {
      this.ui.reloadAccount()
      this.ui.loadTemplateFilter()
      this.createTemplateCreateButtons()
    })
  }

  
  async createNewTemplateForPool(createBtn: TemplateCreateBtn) {
    console.log(createBtn);
    
    let olData: IRenameTemplateOverlayData = {
      templateName: 'Neue Vorlage vom ' + DATETOSTRING(new Date(), false, true),
      poolSelectionRequired: false,
    }
    let newName:string = olData.templateName
    this.dialog.open(NewTemplateNameComponent,{
      width:"850px",
      height:"250px",
      disableClose:true,
      backdropClass:"backdrop-background",
      data: olData
    }).afterClosed().subscribe(
      (result:IRenameTemplateOverlayCloseData) => {
        if(result){
          newName = result.newName
          this.createTemplateWith(newName, createBtn.template_pool_id, createBtn.privacy)
        }

      

    });

   
  }
  private async createTemplateWith(name: string, pool: number, privacy: string) {
    const fachgebiet = this.ui.allTreatmentFilterByCurrentFach
    if(fachgebiet == null || fachgebiet[0] == null) { return }
    this.isCreatingNewTemplate = true;
    const _dto = {
      name: name,
      privacy: privacy,
      type: 'DOXFOX',
      fachgebiet_id: fachgebiet[0].fachgebiet_id,
      treatmentfilter_first: fachgebiet[0].id,
      template_pool_id: pool,
    }
    const __newTemplate = await this.templateApi.createNewTemplate(_dto)
    if(__newTemplate != null){
      setTimeout(()=>{
        this.isCreatingNewTemplate = false;
        this.router.navigate(['template-detail', __newTemplate]);
      },200)
    }
  }

  

   async expertiseChanged(e){
     this.ui.fachgebiet = e.value
     await this.tc.toggleAreaOfExpertise(e.value.fachgebiet_id)
    this.joinTemplates();
  }
  get treatmentRegions():TreatmentRegions[]{
    if(this.ui.fachgebiet) {
      return this.ui.treatmentRegions2.filter(item => item.fachgebiet_id == this.ui.fachgebiet.fachgebiet_id)
    } else {
      return []
    }
  }
  
  toggleRegion(item){
    item.selected = !item.selected;
    this.joinTemplates();
  }

  regionAllTapped(){
    for(let _expertise of this.ui.fachgebiete){
      _expertise.is_active = true;
    }
    for(let _region of this.ui.treatmentRegions2){
      _region.selected = true;
    }

    this.joinTemplates();
  }

  regionNothingTapped(){
    for(let _region of this.ui.treatmentRegions2){
      _region.selected = false;
    }
    this.joinTemplates();
  }

  typeAllTapped(){
    for (let i = 0; i < this.ui.treatmentTypes.length; i++) {
      this.ui.treatmentTypes[i].selected = true;
    }
    this.joinTemplates();
  }
  typeNothingTapped(){
    for (let i = 0; i < this.ui.treatmentTypes.length; i++) {
      this.ui.treatmentTypes[i].selected = false;
    }
    this.joinTemplates();
  }

  changeSelectedRegion($event, item): void {
    item.selected = $event.selected;
  }
  changeAreaOfExpertise(event, item): void {
    item.is_active = event.selected;

  }

  onOpenTemplateDetails(template_id: number) {
    ControllerService.preloadOverlay.emit(true);
    this.tc.oveviewOut()
    // setTimeout(() => {
    //   this.fadeOut = true;
    // }, 10);
    setTimeout(() => {
      this.tc.overviewTemplatesFilterWith('')
      this.router.navigate(['template-detail', template_id]);
      
     /*  this.dialog.open(TemplateDetailOverlayComponentComponent,{
        data: {template_id: template_id,  baseType: BASE_TEMPLATE_TYPE.TEMPLATE},
        width: "100vw",
        maxWidth: "100vw",
        height: "90vh",
        animation: { to:"top", incomingOptions: {
              keyframeAnimationOptions: { duration: 350, easing: 'cubic-bezier(0.27, 0.32, 0.14, 0.86)' }
            },
            outgoingOptions: {
              keyframes: [
                // { to: "bottom" },
                // { opacity: 0 },
                // { transform: "rotate(0)" },
                // { transform: "rotate(360deg)" }
              ],
              keyframeAnimationOptions: { duration: 250, easing: 'cubic-bezier(0.94, 0.01, 0.07, 0.97)' }
            }},
        //incomingOptions: { keyframeAnimationOptions: {easing: 'ease-out'}}},
        position: { bottom: "0px" },
        direction: 'rtl',
        role: 'alertdialog',
        // incomingOptions: {}
        
        // exitAnimationDuration: 3
        
  
      }) */
      // return;
    }, 250);
    
  }

  getFilterByGroup(group:string):TREATMENT_FILTER[] {
    if(group == 'Filter 1'){
      return this.ui.allTreatmentFilterByCurrentFach.filter(item => item.filter_level == TREATMENT_FILTER_LEVEL.FIRST)
    }
    if(group == 'Filter 2'){
      return this.ui.allTreatmentFilterByCurrentFach.filter(item => item.filter_level == TREATMENT_FILTER_LEVEL.SECOND)
    }
    if(group == 'Filter 3'){
      return this.ui.allTreatmentFilterByCurrentFach.filter(item => item.filter_level == TREATMENT_FILTER_LEVEL.THIRD)
    }
     return []
  }
  get filterLevel1():TREATMENT_FILTER[] {
    return this.ui.allTreatmentFilterByCurrentFach.filter(item => item.filter_level == TREATMENT_FILTER_LEVEL.FIRST)
  }
  get filterLevel2():TREATMENT_FILTER[] {
    return this.ui.allTreatmentFilterByCurrentFach.filter(item => item.filter_level == TREATMENT_FILTER_LEVEL.SECOND)
  }
  get filterLevel3():TREATMENT_FILTER[] {
    return this.ui.allTreatmentFilterByCurrentFach.filter(item => item.filter_level == TREATMENT_FILTER_LEVEL.THIRD)
  }


  toggleSelectAll(group:string, selectAll:boolean){
    for(const item of this.getFilterByGroup(group)){
      item.selected = selectAll
    }
    console.log("toggleSelectAll");
    this.joinTemplates();
  }


  showSubMenu(template: TemplateFlatI): boolean {
    return template.isEditable
  }
  
  createTemplateCreateButtons()  {
    var btns: TemplateCreateBtn[] = []
    if(this.ui.account && this.ui.account.templatePools) {
      if(this.ui.hasTemplateAdminRole) {
        this.ui.account.templatePools.forEach(item => {
          if(item.canEdit === true) {
            btns.push({
              template_pool_id: item.template_pool_id,
              title: `Öffentliche ${item.name} Vorlage erstellen`,
              privacy: 'UNPUBLISHED_PUBLIC'
            })
          }
        })
      } else if(!this.ui.hasTemplateAdminRole && this.ui.isDocRole) {
        this.ui.account.templatePools.forEach(item => {
          if(item.canEdit === false) {
            btns.push({
              template_pool_id: item.template_pool_id,
              title: `Eigene ${item.name} Vorlage erstellen`,
              privacy: 'USER_OWN'
            })
          }
        })
      }
      
    }
    
    this.templateCreateButtons = btns
  }
  
  editPools() { 
    const data: ITemplatePoolOverlayData = {
      isEditMode: true
    }
    this.dialog.open(TemplatePoolOverlayComponent, {
      width: '70%', 
      maxWidth: "1200px",
      height: '450px',
      backdropClass: "backdrop-background",
      data: data
    }).afterClosed().subscribe((x:any) => {
      this.ui.reloadAccount()
      this.ui.loadTemplateFilter()
      this.createTemplateCreateButtons()
    })
  }

  async ngOnDestroy() {

    if(this.filterChangedSubscription != null ){
      this.filterChangedSubscription.unsubscribe()
    }
    if(this._templateSortChangedSubscription != null){
      this._templateSortChangedSubscription.unsubscribe();
    }
  
    this.tc.update_user_reatment_filters_active(this.ui.allTreatmentFilter)
    this.isLoading = false;
    this.fadeOut = false;
  }
}
