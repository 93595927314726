import { Regel } from './goaeM';

export interface GoaeAusschlussI {
  GOA_ID?:string;
  documentId?:string;
  goa_id?:string;
  errorMessage?:string;
  U?:string;
  regel?:     Regel;
}

export interface GoaeConflictUI_I{
  goa_id?:string;
  errorMessage?:string;
}
export interface GoaeConflictI{
  ref_goa_id:string;
  items?:string[];
  type:GoaeConflictType;
}

export enum GoaeConflictType {
  only_allowed_with = 'only_allowed_with',
  exclusion = 'exclusion',
  none = 'none'
}

export interface PositionErrorI {
  section_id:number
  position_id:number
  GOA_ID?:string;
  documentId?:string;
  goa_id?:string;
  errorMessage?:string;
}