import { TemplateFlatI } from "./api/TemplateFlatI";


export interface ProcessCompletionOverlayData {
  template:TemplateFlatI;
  type:ProcessCompletionType;
}
export enum ProcessCompletionType {
  SURGERYREPORT, 
  INVOICE,
  BEFUND

}
export enum ProcessesSort {
  PATIENT_ASC = 'PATIENT_ASC',
  PATIENT_DESC = 'PATIENT_DESC',
  DATE_DESC = 'DATE_DESC',
  DATE_ASC = 'DATE_ASC',
  SUMMARY_DESC = 'SUMMARY_DESC',
  SUMMARY_ASC = 'SUMMARY_ASC'
}

export enum API_POST_MODE {
  UPDATE = 'UPDATE', 
  ADD_NEW = 'ADD_NEW', 
}
export enum PATIENT_SELECTION_VIEW_MODE {
  LIST_PATIENTS_MODE = 'LIST_PATIENTS_MODE', 
  MODIFY_MODE = 'MODIFY_MODE', 
  CREATE_NEW_MODE = 'CREATE_NEW_MODE',
}


export interface TemplateInfoOverlayComponentDataI {
  overlayAction:TemplateInfoOverlayActionE;
  template_id:number;
  templateName?:string
  dto?: Object
}
export interface TemplateInfoOverlayActionResult {
  template_id:number;
  path:string
}
;
export enum TemplateInfoOverlayActionE{
  CLONE_PUBLIC = 'CLONE_PUBLIC', 
  USE_AS_PROCESS = 'USE_AS_PROCESS', 
  DUPLICATE_TEMPLATE = 'DUPLICATE_TEMPLATE', 
  CLONE_TO_OWN_POOL = "CLONE_TO_OWN_POOL"
}

export enum TEMPLATE_TYPES{
  TEMPLATE_PROCESS = "TEMPLATE_PROCESS",
  TEMPLATE = "TEMPLATE",
}
export enum BASE_MODULE_TYPE{
  DOXFOX,
  SCS,
}

export enum BASE_TEMPLATE_TYPE{
  TEMPLATE = 'TEMPLATE',
  PROCESS = 'PROCESS',
  NONE = 'NONE'
}

export enum POSITION_TYPE {
  GOA = 'GOA',
  OWN = 'OWN',
  NONE = 'NONE' 
}

export interface SPLIT_SCREEN_CHANGED{
  oldValue:SPLIT_SCREEN_VIEW_STATE
  newValue:SPLIT_SCREEN_VIEW_STATE
}

export enum SPLIT_SCREEN_VIEW_STATE{
  CLOSED ='CLOSED',
  TEMPLATE_LIST ='TEMPLATE_LIST',
  TEMPLATE_SELECTED ='TEMPLATE_SELECTED',
  
}

export enum DRAG_DROP_MODE {
  BOTH_OR_MODE = 'BOTH_OR_MODE',
  DRAGGED_OR_MODE_REPLACE_INDENTED = 'DRAGGED_OR_MODE_REPLACE_INDENTED',
  DRAGGED_OR_MODE_REPLACE_OUT_OR_OR_BLOCK = 'DRAGGED_OR_MODE_REPLACE_OUT_OR_OR_BLOCK',
  DRAGGED_INDENTED_REPLACED_OR_MODE = 'DRAGGED_INDENTED_REPLACED_OR_MODE', 
  DRAGGED_INDENTED_REPLACED_OUT_OF_BLOCK = 'DRAGGED_INDENTED_REPLACED_OUT_OF_BLOCK', 
  BOTH_INDENTED = 'BOTH_INDENTED', 
  DRAGGED_OUT_OF_MODE_REPLACED_OR_MODE = 'DRAGGED_OUT_OF_MODE_REPLACED_OR_MODE',
  DRAGGED_OUT_OF_MOODE_REPLACED_INDENTED = 'DRAGGED_OUT_OF_MOODE_REPLACED_INDENTED',
  BOTH_OUT_OF_MODE = 'BOTH_OUT_OF_MODE'
}

export enum SIGN_IN_MODE {
  UPDATE_PASSWORD_REQIERED = 'UPDATE_PASSWORD_REQIERED',
  LOADING = 'LOADING',
  SIGN_IN = 'SIGN_IN',
  READY = 'READY',
  ADDRESS_MISSING = 'ADDRESS_MISSING',
}

export enum ROW_BUTTON_TYPE {
  EDIT,
  CREATE_PROCESS,
  CLONE,
  DUPLICATE,
  NONE
}
export enum ROW_BUTTON_ACTION_TYPE {
  CREATE_PROCESS = "CREATE_PROCESS",
  OPEN_DETAILS = "OPEN_DETAILS",
  DUPLICATE = "DUPLICATE",
  CLONE = "CLONE",
  CLONE_TO_OWN_POOL = "CLONE_TO_OWN_POOL",
}