import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs/internal/Subscription';
import { GOA_SMALL } from 'src/app/core/models/goaeM';

import { UiService } from 'src/app/services/ui.service';
import * as _ from "lodash";
import { GoaeDetailOverlayComponent } from 'src/app/administration/goae-detail-overlay/goae-detail-overlay.component';
import { GoaDetailOverlayParams } from 'src/app/core/models/goaParagraphM';
import { GoaServicesService } from 'src/app/services/goa-services.service';
@Component({
  selector: 'app-settings-goa',
  templateUrl: './settings-goa.component.html',
  styleUrls: ['./settings-goa.component.scss']
})
export class SettingsGoaComponent implements OnInit, OnDestroy {
  // @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  // paginatorPageSize = 0
  // paginatorLength = 100;
  
  changeHeighSubscription:Subscription
  _currentHeight:number = 0
  goae_list_small:GOA_SMALL[] = [];
  displayedColumns: string[] = ['name'];
  dataSource:any
  
  constructor(
    private dialog:MatDialog,
    private ui:UiService,
    private goaS:GoaServicesService
  ) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.loadData()
      this.changeHeighSubscription = this.ui.settingsPanelHeightChanged.subscribe(
        _newHeight =>{
          this._currentHeight = _newHeight
        }
      )
    },120)
  }


  async loadData(){
    let list:GOA_SMALL[]=[];
    list = await this.goaS.goaeListSmall(true).then(
      _data => {
        return _data as GOA_SMALL[]
      }
    )
    
    this.goae_list_small = _.sortBy(list,['V'])
    this.dataSource = new MatTableDataSource<GOA_SMALL>(this.goae_list_small);
    // this.dataSource.paginator = this.paginator;
  }


  get getPanelHeight():string{
    return this._currentHeight + 'px'
  }
  ngOnDestroy(): void {
    if(this.changeHeighSubscription != null){
      this.changeHeighSubscription.unsubscribe()
    }
  }
  // applyFilter(filterValue: string) {
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  // }
  applyFilter(filterValue:string) {
    this.dataSource = this.goae_list_small.filter((item) =>  item.searchString != null && item.searchString.toLowerCase().includes(filterValue.trim().toLowerCase()))
  //   let numerics= ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
  //  if(numerics.includes(filterValue[0])){
  //    this.dataSource = this.goae_list_small.filter((item) =>  item.name != null && item.name.startsWith(filterValue.trim()))
  //  } else {
  //    this.dataSource = this.goae_list_small.filter((item) =>  item.long != null && item.long.toLowerCase().startsWith(filterValue.trim().toLowerCase()))
  //  }
// this.filtereGoaeOptions = this.fullGoae.filter((item) =>  item.long != null && item.long.startsWith(filterValue.trim()))
  //this.filtereGoaeOptions = this.fullGoae.filter((item) => item.long != null && item.long.startsWith(filterValue.trim().toLowerCase()) || item.goa_id.startsWith(filterValue))
 } 
  openDetails(row){
    console.log(row);
    
    const _data:GoaDetailOverlayParams = {
      listSmall: this.goae_list_small,
      selectedId: row.documentId,
      mode: 'edit'
    }
    this.dialog.open(GoaeDetailOverlayComponent, {
      width:"85%",
      height: "560px",
      backdropClass:"backdrop-background",
      data: _data
    }) .afterClosed().subscribe(  
      _item => {
        this.loadData()
      }
    )
  }
  add(){
    const _data:GoaDetailOverlayParams = {
      listSmall: this.goae_list_small,
      selectedId: "",
      mode: 'add'
    }
    this.dialog.open(GoaeDetailOverlayComponent, {
      width:"85%",
      height: "560px",
      backdropClass:"backdrop-background",
      data: _data
    }).afterClosed().subscribe(
      _item => {
        this.loadData()
      }
    )
  }
}
