<div class="container-fluid">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0" style="width: 100%; ">

    <ng-container matColumnDef="rechnungNr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header ><span class="pl30">Nummer</span></th>
      <td mat-cell *matCellDef="let element" class="pl40"><span class="pl30">{{element.invoice_number}}</span></td>
    </ng-container>
    <ng-container matColumnDef="dateCreated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header ><span class="pl30">Erstellt</span></th>
      <td mat-cell *matCellDef="let element" class="pl40"><span class="pl30">{{element.date_created  | date: 'dd.MM.yyyy HH:mm'}}</span></td>
    </ng-container>
    
    <!-- <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Vorname</th>
      <td mat-cell *matCellDef="let element"> {{element.firstName}}</td>
    </ng-container>
  
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nachname</th>
      <td mat-cell *matCellDef="let element"> {{element.lastName}}</td>
    </ng-container>
  
    
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Tel </th>
      <td mat-cell *matCellDef="let element">{{element.phone}}</td>
    </ng-container> -->
    
    <ng-container matColumnDef="sum">
      <th mat-header-cell *matHeaderCellDef >Betrag</th>
      <td mat-cell *matCellDef="let element">{{element.summary}}</td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="element-row" *matRowDef="let row; columns: displayedColumns;" (click)='openDetails(row)' ></tr>
  
  </table>
  <!-- <div class="d-flex flex-column">
    <div class="p-2"  *ngFor="let invoice of invoiceList">
      <div class="d-flex">
        <div class="p-2">{{invoice.date_created | date: 'dd.MM.yyyy HH:mm'}}</div>
        <div class="p-2">{{invoice.summary}}</div>
      </div>
    </div>
  </div> -->
</div>
