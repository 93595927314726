import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  private _locales:Object 
  constructor() {
    this._locales = LOCALES
   }

   get locales() {
     return this._locales;
   }

}

const LOCALES = {
  AUTH:{
    BTN_LOGIN: "Einloggen",
    BTN_FORGOT_PASSWORD: "Passwort vergessen?"
  },
  DASHBOAD:{
    HL: "Herzlich Willkommen",
    PUBLISHED_TEMPLATES_HL: "Vorlagen",
    UNPUBLISHED_TEMPLATES_HL: "Unveröffentlichte Vorlagen",
    PROCESSES_HL: "Meine Leistungen",
    PUBLISHED_TEMPLATES_EMPTY: "Sie haben noch keine Vorlagen verwendet.",
    UNPUBLISHED_TEMPLATES_EMPTY: "Sie haben derzeit keine unveröffentlichten Vorlagen.",
  },
  SELECT_NEW_POSITION:{
    OVERLAY_HL:{
      OWN_ITEM: "Textbaustein hinzufügen",
      GOAE: "GOÄ Ziffer hinzufügen",
      MATERIAL: "Material hinzufügen"
    },
    SEARCH_INPUT_PLACEHOLDER:{
      OWN_ITEM: "Textbaustein suchen",
      GOAE: "Neue GOÄ Maßnahme hinzufügen",
      MATERIAL: "Material suchen..."
    }
  },

  T_BH_BUTTONS: {
    BTN_SHARE_WITH_ACCOUNTING: "An Abrechnung übergeben",
    BTN_SHARE_WITH_DOC: "Zür Prüfung"
  },

  PROCESS_READ_ONLY_ALERT: {
    BODY_MESSAGE: "Dieser Vorgang ist in Bearbeitung von: <strong>XXX</strong> und kann nicht bearbeitet werden.",
    OPEN_BTN: "Trotzdem anzeigen",
    CANCEL_BTN: "Abbrechen",
  },
  PROCESS_ACCESS_DENIED_ALERT: {
    BODY_MESSAGE: "Sie haben keine Berechtigung für diesen Vorgang.<br> In Bearbeitung von: <strong>XXX</strong>",
  },
  PROCESS_DONE_READ_ONLY_MESSAGE: {
    BODY_MESSAGE: "Dieser Vorgang ist abgeschlossen und kann nicht mehr bearbeitet werden.",
    OPEN_BTN: "Anzeigen",
    CANCEL_BTN: "Abbrechen",
  },

  SURGERY_REPORT: {
    DELETE_ALERT: {
      TITLE: 'OP-Berichtstext löschen', 
      BODY: 'Sind Sie sicher, dass Sie diesen Berichtstext löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
      ACTION_RESUME: 'Löschen',
      ACTION_CANCEL: 'Abbrechen'
    }
  },
  JUSTIFICATION: {
    DELETE_ALERT: {
      TITLE: 'Begründungstext löschen?', 
      BODY: 'Sind Sie sicher, dass Sie diesen Begründungstext löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
      ACTION_RESUME: 'Löschen',
      ACTION_CANCEL: 'Abbrechen'
    }
  },


  SUBSCRIPTION_PLAN_INFO: {
      TITLE: 'Vielen Dank für Ihr Interesse', 
      BODY: 'Wir  werden uns in Kürze mit Ihnen in Verbindung setzen um die Details für Ihre Lizenz zu besprechen.',
      
      ACTION_CANCEL: 'OK'
    
  }
}
