<div class="container p_t40 p_b40">
  <div class="row"  style="height: 106px;">
    <div class="col-12">
      <p style="text-align:center; font-size:16px; font-weight:400;">Ihre Sitzung ist abgelaufen.
      <br>Bitte melden Sie sich erneut an.</p>
    </div>
  </div>
  Sie haben sich auf einem anderen Gerät in Ihrem doxfox-Konto angemeldet, daher wird Ihre Sitzung auf diesem Gerät beendet.
</div>
<!-- <mat-toolbar style="height: 64px; width: 100%; border-radius: 6px;" class="tlc justify-content-center">
  <div style="width:auto; text-align:center;"  class="tlc justify-content-center" >
    <button mat-flat-button color="primary" (click)="close()">schließen</button>
  </div>
</mat-toolbar> -->