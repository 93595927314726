<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <mat-icon style="color:#ABBBC7; cursor: pointer;   -webkit-user-select:none;" (click)='close()'>close</mat-icon>
</mat-toolbar>
<div class="container-fluid ">
  <div [ngSwitch]="type">

    <div *ngSwitchCase="'GOA'">
      <div class="container">
        <div class="row">
          <div class="col-10 offset-1">
            <div class="d-flex pl0">
              <div class="p-2 mr0 pr0 pl0">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.5 7.5H1.5V9H10.5V7.5ZM10.5 4.5H1.5V6H10.5V4.5ZM1.5 12H7.5V10.5H1.5V12ZM16.125 8.625L17.25 9.75L12.0075 15L8.625 11.625L9.75 10.5L12.0075 12.75L16.125 8.625Z" fill="#1B242B"/>
                </svg>
              </div>
              <div class="p-2 ml0 pl8">{{locale.locales['SELECT_NEW_POSITION']['OVERLAY_HL']['GOAE']}}</div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div *ngSwitchCase="'OWN'" >
      <div class="container">
        <div class="row">
          <div class="col-10 offset-1">
            <div class="d-flex pl0">
              <div class="p-2 mr0 pr0 pl0">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.5 7.5H1.5V9H10.5V7.5ZM10.5 4.5H1.5V6H10.5V4.5ZM1.5 12H7.5V10.5H1.5V12ZM16.125 8.625L17.25 9.75L12.0075 15L8.625 11.625L9.75 10.5L12.0075 12.75L16.125 8.625Z" fill="#1B242B"/>
                </svg>
              </div>
              <div class="p-2 ml0 pl8">{{locale.locales['SELECT_NEW_POSITION']['OVERLAY_HL']['OWN_ITEM']}}</div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div *ngSwitchCase="'MATERIAL'" >
      <div class="container">
        <div class="row">
          <div class="col-10 offset-1">
            <div class="d-flex pl0">
              <div class="p-2 mr0 pr0 pl0">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.86476 6.01027L10.8548 4.02027C11.0498 3.82527 11.0498 3.51027 10.8548 3.31527L8.68476 1.14527C8.48976 0.950273 8.17476 0.950273 7.97976 1.14527L5.98976 3.13527L3.99976 1.14527C3.89976 1.05027 3.77476 1.00027 3.64476 1.00027C3.51976 1.00027 3.38976 1.05027 3.29477 1.14527L1.12477 3.31527C0.929766 3.51027 0.929766 3.82527 1.12477 4.02027L3.11477 6.01027L1.12477 8.00027C0.929766 8.19527 0.929766 8.51027 1.12477 8.70527L3.29477 10.8753C3.48976 11.0703 3.80476 11.0703 3.99976 10.8753L5.98976 8.88527L7.97976 10.8753C8.07976 10.9753 8.20476 11.0203 8.33476 11.0203C8.46476 11.0203 8.58976 10.9703 8.68976 10.8753L10.8598 8.70527C11.0548 8.51027 11.0548 8.19527 10.8598 8.00027L8.86476 6.01027V6.01027ZM5.99976 4.50027C6.27476 4.50027 6.49976 4.72527 6.49976 5.00027C6.49976 5.27527 6.27476 5.50027 5.99976 5.50027C5.72476 5.50027 5.49976 5.27527 5.49976 5.00027C5.49976 4.72527 5.72476 4.50027 5.99976 4.50027ZM3.64476 5.48027L1.82977 3.67027L3.64476 1.85527L5.45476 3.66527L3.64476 5.48027ZM4.99976 6.50027C4.72476 6.50027 4.49976 6.27527 4.49976 6.00027C4.49976 5.72527 4.72476 5.50027 4.99976 5.50027C5.27476 5.50027 5.49976 5.72527 5.49976 6.00027C5.49976 6.27527 5.27476 6.50027 4.99976 6.50027ZM5.99976 7.50027C5.72476 7.50027 5.49976 7.27527 5.49976 7.00027C5.49976 6.72527 5.72476 6.50027 5.99976 6.50027C6.27476 6.50027 6.49976 6.72527 6.49976 7.00027C6.49976 7.27527 6.27476 7.50027 5.99976 7.50027ZM6.99976 5.50027C7.27476 5.50027 7.49976 5.72527 7.49976 6.00027C7.49976 6.27527 7.27476 6.50027 6.99976 6.50027C6.72476 6.50027 6.49976 6.27527 6.49976 6.00027C6.49976 5.72527 6.72476 5.50027 6.99976 5.50027ZM8.32976 10.1703L6.51476 8.36027L8.32976 6.54527L10.1398 8.35527L8.32976 10.1703Z" fill="#1B242B"/>
                </svg>
              </div>
              <div class="p-2 ml0 pl8">{{locale.locales['SELECT_NEW_POSITION']['OVERLAY_HL']['MATERIAL']}}</div>
            </div>
          </div>
        </div>
      </div>

    </div>


  </div>

</div>

<div [ngSwitch]="type">
  <!-- GOAE -->
  <div *ngSwitchCase="'GOA'">
    <div class="container-fluid p_b20">
      <div class="row">
        <div class="col-10 offset-1">
          <input  class="formControl  mat-body" #_goaTF
            placeholder="{{locale.locales['SELECT_NEW_POSITION']['SEARCH_INPUT_PLACEHOLDER']['GOAE']}}" (keyup)="applyFilter($event.target.value)"
            [matAutocomplete]="auto" style="max-width: 100%;" 
          >
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
          (optionSelected)='goaeSelectChanged($event)' [displayWith]="displayFnGOA" (opened)="openGOA()" >
            <cdk-virtual-scroll-viewport itemSize="32" [ngStyle]="{'height': height}">
              <mat-option  *cdkVirtualFor="let option of filtereGoaeOptions"
              [value]="option" class="mat-body"  >
                {{option.name}}
              </mat-option>
            </cdk-virtual-scroll-viewport>
          </mat-autocomplete>
      </div>

      </div>
       <div class="row" style="height: 40px;">
        <div class="col-auto offset-1"><div style="width: 120px;" *ngIf='selectedGoae != null'>
            <div class="d-flex align-items-center add-button pr0" (click)='clearGoaSearch()'>
              <div class="p_2 align-text-end pl12 pr0 mr0">
                <mat-icon class="nav-icon-mini pr0 mr0">search</mat-icon>
              </div>
              <div class="p-2 btn-copy align-text-start  p_t5 p_b5 pl0 ml0 pr16">neue Suche</div>
            </div>
          </div>
          </div>
        </div>
    </div>
  </div>

  <!-- OWN -->
  <div *ngSwitchCase="'OWN'" >
    <div class="container-fluid p_b20" *ngIf="!isCreateOwn">
      <div class="row">
        <div class="col-10 offset-1">
          <div class="d-flex">
            <div class="p-0 flex-fill">
              <input type="text" class="formControl  mat-body" #_billingTF
                placeholder="{{locale.locales['SELECT_NEW_POSITION']['SEARCH_INPUT_PLACEHOLDER']['OWN_ITEM']}}" (keyup)="applyFilterBilling($event.target.value)"
                [matAutocomplete]="autoBilling" style="max-width: 100%;" >
          <!-- [formControl]="control" -->
          <!-- style="height: 48px;"  -->
              <mat-autocomplete autoActiveFirstOption #autoBilling="matAutocomplete" (opened)="open()" (closed)="closeed()"
              (optionSelected)='billingSelectChanged($event)' [displayWith]="displayFnBilling" >
              <!-- style="height: 148px;" -->
                <cdk-virtual-scroll-viewport itemSize="32" 
                [ngStyle]="{'height': height}">
                  <mat-option *cdkVirtualFor="let option of filteredBillingOptions" [value]="option" class="mat-body"  >
                    {{option.short_description}}
                  </mat-option>
                </cdk-virtual-scroll-viewport>
              </mat-autocomplete>
            </div>
            <div class="p-0 p_t5 pl5">
              <mat-icon (click)='addNewOwn()' class='dxfx_icon_btn_medium' matTooltipClass='sectionGoaeTT' matTooltip='Textbaustein anlegen' matTooltipPosition='above'>add</mat-icon>
            </div>
          </div>
          
      </div>
 
      </div>
       <div class="row" style="height: 40px;">
        <div class="col-auto offset-1"><div style="width: 120px;" *ngIf='selectedBillingItem != null'>
            <div class="d-flex align-items-center add-button pr0" (click)='clearBillingSearch()'>
              <div class="p_2 align-text-end pl12 pr0 mr0">
                <mat-icon class="nav-icon-mini pr0 mr0">search</mat-icon>
              </div>
              <div class="p-2 btn-copy align-text-start  p_t5 p_b5 pl0 ml0 pr16">neue Suche</div>
            </div>
          </div>
          </div>
        </div>
    </div>

    <div class="container-fluid p_b20" *ngIf="isCreateOwn">
      <div class="row">
        <div class="col-10 offset-1">
          <div class="d-flex flex-column">  
            <div class="p-2">
              <mat-label class='mat-caption'>Suchtext </mat-label>
              <input class="mat-body formControl" 
              [(ngModel)]='newBillingItem.short_description'  />
            </div>  
          
            <div class="p-2">
              <mat-label class='mat-caption'>Langtext</mat-label>
              <textarea class="mat-body formControl" rows="6" 
              [(ngModel)]='newBillingItem.long_description' ></textarea>
            </div>  
            
          </div>
        </div>
      </div>
    
    </div>

  </div>
  <!-- MATERIAL -->
  <div *ngSwitchCase="'MATERIAL'" >

    <div class="container-fluid p_b20" *ngIf="!isCreateMat">
      <div class="row">
        <div class="col-10 offset-1">
          <div class="d-flex">
            <div class="p-0 flex-fill">
              <input type="text" class="formControl  mat-body" #_materialTF
              placeholder="{{locale.locales['SELECT_NEW_POSITION']['SEARCH_INPUT_PLACEHOLDER']['MATERIAL']}}" (keyup)="applyFilterMaterial($event.target.value)"
              [matAutocomplete]="autoMaterial" style="max-width: 100%;"
            >
            <mat-autocomplete autoActiveFirstOption #autoMaterial="matAutocomplete" (opened)="openMat()"
            (optionSelected)='materialSelectChanged($event)' [displayWith]="displayFnMaterial">
              <cdk-virtual-scroll-viewport itemSize="32"  [ngStyle]="{'height': height}">
                <mat-option *cdkVirtualFor="let option of filtereMaterialOptions" [value]="option" class="mat-body"  >
                  {{option.bezeichnung}}
                </mat-option>
              </cdk-virtual-scroll-viewport>
            </mat-autocomplete>
            </div>
            <div class="p-0 p_t5 pl5">
              <mat-icon (click)='addNewMat()' class='dxfx_icon_btn_medium'
        matTooltipClass='sectionGoaeTT' matTooltip='Material anlegen' matTooltipPosition='above'>add</mat-icon>
            </div>
          </div>
      
      </div>

      </div>
       <div class="row" style="height: 40px;">
        <div class="col-auto offset-1"><div style="width: 120px;" *ngIf='selectedMaterialItem != null'>
            <div class="d-flex align-items-center add-button pr0" (click)='clearMaterialSearch()'>
              <div class="p_2 align-text-end pl12 pr0 mr0">
                <mat-icon class="nav-icon-mini pr0 mr0">search</mat-icon>
              </div>
              <div class="p-2 btn-copy align-text-start  p_t5 p_b5 pl0 ml0 pr16">neue Suche</div>
            </div>
          </div>
          </div>
        </div>
    </div>

    <div class="container-fluid p_b20" *ngIf="isCreateMat">
 
      <div class="d-flex">

        <div class="p-2 w100">
          <mat-label>Bezeichnung</mat-label>
          <textarea class="mat-body formControl" rows="3" maxlength="200"
          [(ngModel)]="materialItem.bezeichnung"></textarea>
        </div>

        <div class="p-2 flex-shrink-1">
          <mat-label >Preis</mat-label>
          <input class="formControl mat-body " style="text-align: right!important; width: 100px;"
          (onfocus)="onPreisFocus()"
          #_preisTF [(ngModel)]="materialItem.preis">
        </div>

      </div>
      <!-- <div class="row" *ngIf='materialItem !== null'>
    
        <div class="col-9 verySmallGutter">
          <mat-label>Bezeichnung</mat-label>
          <textarea class="mat-body formControl" rows="3" maxlength="200"
          [(ngModel)]="materialItem.bezeichnung"></textarea>
        </div>
    
        <div class="col-3 verySmallGutter">
          <mat-label >Preis</mat-label>
          <input class="formControl mat-body " style="text-align: right!important;"
          (onfocus)="onPreisFocus()"
          #_preisTF [(ngModel)]="materialItem.preis">
        </div>
    
      </div> -->

    </div>

  </div>
</div>



<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <app-df-icon-button title="Übernehmen" icon="check" [disabled]="selectedGoae == null
  && selectedBillingItem== null && selectedMaterialItem == null" color="primary" (clicked)="save()" *ngIf="!isCreateOwn && !isCreateMat">
  </app-df-icon-button>

  <app-df-icon-button title="Abbrechen" icon="close"  (clicked)="clearEditMode()" *ngIf="isCreateOwn || isCreateMat">
  </app-df-icon-button>
  <app-df-icon-button title="Speichern" icon="save" [disabled]="!canSaveOwn" color="primary" (clicked)="saveNewItem()" *ngIf="isCreateOwn">
  </app-df-icon-button>
  <app-df-icon-button title="Speichern" icon="save" [disabled]="!canSaveMat" color="primary" (clicked)="saveNewMat()" *ngIf="isCreateMat">
  </app-df-icon-button>
  <!-- <div style="width:92px; text-align:center;" class="tlc justify-content-center" >
  <button mat-flat-button color='primary' (click)='save()' [disabled]='selectedGoae == null
  && selectedBillingItem== null && selectedMaterialItem == null'>Übernehmen</button>
  </div> -->
</mat-toolbar>
