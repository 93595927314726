import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { AuthService } from '../shared/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { GOA_SMALL, Gnr } from '../core/models/goaeM';
import { GoaeAusschlussI, GoaeConflictI, GoaeConflictType } from '../core/models/goaeAusschlussI';
import { differenceInMinutes } from "date-fns";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GoaParagraph } from '../core/models/goaParagraphM';
@Injectable({
  providedIn: 'root'
})
export class GoaServicesService {
  
  private _conflictItems:GoaeConflictI[] = []
  private _apiBasePathV2 = environment.endpoints.apiBasePathV2
  userPath:string ='';
  private _goaListSmall:GOA_SMALL[]
  constructor(private fs: AngularFirestore, private authS:AuthService, private http:HttpClient
     ) {

  }

  async goaeListSmall(forceReload:boolean = false)  {
    if(forceReload){
      return await this.getSmallAll()
    } else {

      return this._goaListSmall != null ? this._goaListSmall : await this.getSmallAll()
    }
  }

  getGoaAusschluss(goaDocId:string): Observable<GoaeAusschlussI> {
    return this.fs.collection('goae-ausschluss-3').doc(goaDocId)
    .snapshotChanges()
    .pipe(
      map(
        a => {
          return a.payload.data() as Gnr;
        }
      )
    )
   }

   async updateGoaAusschlussItem(goaeAusschluss:GoaeAusschlussI){
     return await this.fs.collection('goae-ausschluss-3').doc(goaeAusschluss.documentId).update(goaeAusschluss);
   }

   async getfullItem(documtentId:string) {
    const _data = await this.http.get(this._apiBasePathV2 +"goae/full/" + documtentId).toPromise().then(
      data => {return data}
    )
    console.log(_data)
    return _data
  }
  
  async goaeShotCopy(documtentId:string) {
    let message = ''
    let output = await this.fs.collection('goae-full-3').
    doc(documtentId).get().toPromise().then(
      (item)=>{
        message = item.data()['allgemein']['legende']['kurztext']
      }
    )
    return message
  }

  async addOwnNewGoaId(newId:string){
    const _data = await this.http.post(`${this._apiBasePathV2}goae/admin-add-new/${'GOA_'+newId}`,{
      payload: {
        goa_id: newId
      }
    }).toPromise()
    
  }

/*   async updateFullItem(gnrItem:Gnr){
    this.goaVersionUpdate()
    // this.fs.collection('misc').doc('goae_small_update').update({time:new Date(Date.now())})
    return this.fs.collection('goae-full-3').
    doc(gnrItem.documentId).update(gnrItem);
  } */

  goaVersionUpdate() {
    let docRef = this.fs.collection('misc').doc('goae_small_update');
    docRef.get().toPromise().then(
      _d => {
        if(_d.exists){
          let _lastUpdate:Date = new Date(_d.data()['time'].seconds);
          let _now:Date = new Date(Date.now());
          if(differenceInMinutes(_now, _lastUpdate) > 1){
             console.log(differenceInMinutes(_now, _lastUpdate))
            let v:number = _d.data()['version'];
            v = v +1
            docRef.update({time:new Date(Date.now()), version: v})
          }
        }
      }
    )
  }
  //1600627506000
  async getGOAFull(documentId:string){
  const _data = await this.http.get(`${this._apiBasePathV2}goae/admin-full/${documentId}`).toPromise()
  return _data
  //  this.fs.collection('goae-full-3').doc(documentId)
  //   .snapshotChanges()
  //   .pipe(
  //     map(
  //       a => {
  //         return a.payload.data() as Gnr;
  //       }
  //     )
  //   )
  }
  async getGOAFullChanges(documentId:string){
    const _data = await this.http.get<any[]>(`${this._apiBasePathV2}goae/changes/${documentId}`).toPromise()
    return _data
  }




  
   goaeUpdateTime():Observable<any> {
    return this.fs.collection('misc').doc('goae_small_update').valueChanges()
  }

  // : Observable<GOA_SMALL[]>
  private async getSmallAll() {
    const _data = await this.http.get(this._apiBasePathV2 +"goae/small").toPromise().then(
      data => {
        
        return data
      }
    )
    this._goaListSmall = _data as GOA_SMALL[]
    return _data
    /* return this.fs.collection('goae-small', ref => ref.where('U', "<", '5')) */
/*     .get().toPromise() */
   
  }
  getSingleSmall(id:string): Observable<GOA_SMALL> {
      return this.fs.collection('goae-small').doc(id)
      .snapshotChanges()
      .pipe(
        map(
          a => {
            return a.payload.data() as GOA_SMALL;
          }
        )
      )
  }

  async postChanges(goae_full_id:string, changes:string) {
    const _data = await this.http.post(`${this._apiBasePathV2}goae/changes/${goae_full_id}`,{
      changesCopy: changes
    }).toPromise()
  }
  async updateSingleFull(item:GoaParagraph) {
    const _data = await this.http.post(`${this._apiBasePathV2}goae/admin-full/${item.goae_full_id}`,{
      payload: item
    }).toPromise()
    // return _data
    // return this.fs.collection('goae-small').doc(item.documentId).
    // update(item)  
  }

  //  getSingle(id:string) {
  //   let root  = this.db.database.ref();
  //   return  this.db.list('goae-single-2', ref => ref.orderByChild('documentId').equalTo(id)).snapshotChanges().pipe(
  //     map(action => {
  //       return action.map(a => {
  //         const model = a.payload.val() as GOA_SMALL;
  //         model.dbKey = a.key
  //         return model;
  //       });
  //     })
  //   )
  // }


  // async getSmallGoaeList() {
  //   let root  = this.db.database.ref();
  //    return (await root.child('goae-single-2').orderByChild("U").endAt("4"))
  // }

  async goaeExclusions(goaeIds:string[]){
  //   let _docIds = [];

  //   for(const goaeId of goaeIds){
  //     if(!this.isGoaIdInConflicts(goaeId)){
  //       const _docId = await this.getGoaeDocumentIdByGoaeId(goaeId)
  //       if(_docId != null && !_docIds.includes(_docId)){
  //         _docIds.push(_docId);
  //       }
  //     }
      
  //   }
  //  /*  console.log('_docIds ', _docIds) */

  //   const _path = this._apiBasePath + "goa-conflicts/";
  //   const updateData = {
  //     data:{
  //       goaeIds:_docIds
  //     }
  //   }
  //   if(_docIds.length == 0){
  //     return null
  //   }
  //   const conflictItems = await this.http.post(_path, updateData).toPromise().then(
  //     _data => {
  //       return _data as []
  //     }
  //   )
  //     for (const cfitm of conflictItems) {
  //       /* console.log(cfitm) */
  //       let t:GoaeConflictType
  //       if(cfitm['regel'] != null && cfitm['regel']['type'] != null){
  //         t = cfitm['regel']['type'] == 'only_allowed_with' ?  GoaeConflictType.only_allowed_with : GoaeConflictType.exclusion
  //       } else {
  //         t = null
  //       }
  //         const model:GoaeConflictI = {
            
  //             ref_goa_id: cfitm['goa_id'],
  //             type: t,//data.data()['regel'] != null && data.data()['regel']['type'] != null ? data.data()['regel']['type'] == 'only_allowed_with' ? : GoaeConflictType.only_allowed_with : GoaeConflictType.exclusion  : GoaeConflictType.exclusion
  //             items: cfitm['regel'] != null && cfitm['regel']['ausschluss_liste'] != null ? cfitm['regel']['ausschluss_liste'] : []
            
  //         }

  //       this.setConflict(model)
  //     }
  //   return conflictItems;  

  }

  async goaeExtensionForGoaeDocId(documentId:string){
  //   const _data = await this.http.get(this._apiBasePath +"extension/" + documentId).toPromise().then(
  //     data => {return data}
  //   )
  //   console.log(_data)
  //   let t:GoaeConflictType
  //   if(_data['regel'] != null && _data['regel']['type'] != null){
  //     t = _data['regel']['type'] == 'only_allowed_with' ?  GoaeConflictType.only_allowed_with : GoaeConflictType.exclusion
  //   } else {
  //     t = null
  //   }
  //     const model:GoaeConflictI = {
        
  //         ref_goa_id: _data['goa_id'],
  //         type: t,//data.data()['regel'] != null && data.data()['regel']['type'] != null ? data.data()['regel']['type'] == 'only_allowed_with' ? : GoaeConflictType.only_allowed_with : GoaeConflictType.exclusion  : GoaeConflictType.exclusion
  //         items: _data['regel'] != null && _data['regel']['ausschluss_liste'] != null ? _data['regel']['ausschluss_liste'] : []
        
  //     }
  //     return model;
  // /*   return await this.fs.collection('goae-ausschluss-3').doc(documentId)
  //      .get().toPromise().then((data)=>{
  //        let t:GoaeConflictType
  //        if(data.data()['regel'] != null && data.data()['regel']['type'] != null){
  //          t = data.data()['regel']['type'] == 'only_allowed_with' ?  GoaeConflictType.only_allowed_with : GoaeConflictType.exclusion
  //        } else {
  //          t = null
  //        }
  //          const model:GoaeConflictI = {
             
  //              ref_goa_id: data.data()['goa_id'],
  //              type: t,//data.data()['regel'] != null && data.data()['regel']['type'] != null ? data.data()['regel']['type'] == 'only_allowed_with' ? : GoaeConflictType.only_allowed_with : GoaeConflictType.exclusion  : GoaeConflictType.exclusion
  //              items: data.data()['regel'] != null && data.data()['regel']['ausschluss_liste'] != null ? data.data()['regel']['ausschluss_liste'] : []
             
  //          }
  //          return model;
  //      }) */
       
 }

 //// conflicts 
  setConflict(r:GoaeConflictI) {
    if(this._conflictItems == null){
      this._conflictItems = []
    }
    if(this._conflictItems.find((item) => item.ref_goa_id == r.ref_goa_id) == null){
      this._conflictItems.push(r);
    }
    
  }

  isGoaIdInConflicts(goaeId:string):boolean {
    return this._conflictItems.find((item)=>item.ref_goa_id == goaeId) == null ? false : true;
  }

  getConflictsAll() {
    return this._conflictItems
  }
  getConflicts(goaeIds:string[]):GoaeConflictI[] {//: GoaeConflictUI_I[]{
    let conflictsForSection:GoaeConflictI[] = []
    for(var i= 0; i < goaeIds.length; i ++){
      // console.log("------")
      let c:GoaeConflictI =  this._conflictItems.find((item)=>item.ref_goa_id == goaeIds[i]);
      if(c != null){
        conflictsForSection.push(c)
        // console.log('C  != null')
        // console.log(c)
      } else {
        // console.log('C  == null')
      }
    }

    return conflictsForSection;
  }
 
  async getGoaeDocumentIdByGoaeId(goaeId:string)  {
    const _list = await this.goaeListSmall() as GOA_SMALL[]
   /*  if(this.goaeListSmall == null || this.goaeListSmall.length == 0){
        return null;
    } */
    let goaitem:GOA_SMALL = _list.find((item)=>item.goa_id == goaeId)
    return goaitem != null && goaitem.documentId != null  && goaitem.documentId != '' ? goaitem.documentId : null;
  }


}
