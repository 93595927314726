import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SectionM } from 'src/app/core/models/sectionM';
import { MatExpansionPanel } from '@angular/material/expansion';


@Component({
  selector: 'template-scs-report-section-item',
  templateUrl: './template-scs-report-section-item.component.html',
  styleUrls: ['../sections.scss'],
})
export class TemplateScsReportSectionItemComponent implements OnInit {

  @Input() section: SectionM;
  @Input() templateDocumentId: string;
  @Input() isInProcesses: boolean;

  @Input() isPublicTemplate:boolean
  @ViewChild(MatExpansionPanel, {static: true}) panel: MatExpansionPanel;
  isItemOpen:boolean = false;
  summe = ""
  constructor() { }

  ngOnInit(): void {

  }

  toggleOpenClose(){
    if(this.isItemOpen){
      this.panel.close();
    } else {
      this.panel.open();
    }
    this.isItemOpen = !this.isItemOpen;
  }
  deletePositonAtIndexCallback(e){

  }
  summeryChanged(){
    // console.log('changed')
    // var _sum = 0;
    // for(var i = 0; i < this.section.positions.length; i ++){
    //   var __position:PositionM = this.section.positions[i];
    //   if(__position.activated){
    //     _sum += ((__position.progression_factor * __position.goa_paragraph.amount_euro) * __position.quantity)
    //   }

    // }
    // this.summe = _sum.toFixed(2).toString();
    // return _sum.toFixed(2).toString();
  }
  editTitle(){}
  deleteSection(){}

}
