import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-session-timeout-overlay',
  templateUrl: './session-timeout-overlay.component.html',
  styleUrls: ['./session-timeout-overlay.component.scss']
})
export class SessionTimeoutOverlayComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SessionTimeoutOverlayComponent>,
    private authS:AuthService) { }

  ngOnInit(): void {
    
    setTimeout(()=>{
      // this.authS.SignOut()
    },3000)
    setTimeout(()=>{
      // this.close();
    },5000)
  }
  close(){
    this.dialogRef.close();
  }

}
