<div class="container template-inner-wrapper pvy" >
	<div class="row p_t40 pl0 pr0">
	  <div class="col-12 tlc" style="padding: 0px!important;">
		<h1>Datenschutzerklärung</h1>
	  </div>
  </div>
  <div class="row p_t40 justify-content-center">
    <div class="col-8">
      Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:
    </div>
    <div class="col-8">
      Doxfox GmbH <br>
      Lenbachplatz 2a <br>
      80333 München<br>
      Deutschland<br>
    </div>
  </div>
  <div class="row p_t40 justify-content-center">
    <div class="col-8">
      <h1>Ihre Betroffenenrechte</h1>
    </div>
  </div>
  <div class="row p_t20 justify-content-center">
    <div class="col-8 lb">
      Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:
    </div>
    <div class="col-8">
      <ul > 
        <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
        <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
        <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
        <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),</li>
        <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
        <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>
      </ul>
    </div>
    <div class="col-8 lb">
      Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.
    </div>
    <div class="col-8 lb">
      Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige Behörde.
    </div>
    <div class="col-8">
      Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter:
    </div>
    <div class="col-8">
      <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.</a>
    </div>
  </div>
  <div class="row p_t40 justify-content-center">
    <div class="col-8 lb">
      <h1>Kontaktformular</h1>
    </div>
    <div class="col-8 lb">
      <h2>Art und Zweck der Verarbeitung:</h2>
    </div>
    <div class="col-8 lb">
      Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional.
    </div>
    
    <div class="col-8 lb">
      <h2>Rechtsgrundlage:</h2>
    </div>
    <div class="col-8 lb">
      Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).
    </div>

    <div class="col-8 lb">
      Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.
    </div>

    <div class="col-8 lb">
      Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das Kontaktformular eingegebenen Daten zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b DSGVO).
    </div>
    <div class="col-8 lb">
      <h2>Empfänger:</h2>
    </div>
    <div class="col-8 lb">
      Empfänger der Daten sind ggf. Auftragsverarbeiter.
    </div>

    <div class="col-8 lb">
      <h2>Speicherdauer:</h2>
    </div>
    <div class="col-8 lb">
      Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.
    </div>

    <div class="col-8 lb">
      Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach Ablauf dieser Fristen.
    </div>
    <div class="col-8 lb">
      <h2>Bereitstellung vorgeschrieben oder erforderlich:</h2>
    </div>
    <div class="col-8 lb">
      Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.
    </div>
    <div class="col-8 lb p_t15">
      <h1>SSL-Verschlüsselung</h1>
    </div>
    <div class="col-8 lb">
      Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
    </div>
    <div class="col-8 lb p_t15">
      <h1>Änderung unserer Datenschutzbestimmungen</h1>
    </div>
    <div class="col-8 lb">
      Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.
    </div>
    <div class="col-8 lb p_t15">
      <h1>Fragen an den Datenschutzbeauftragten</h1>
    </div>
    <div class="col-8 lb">
      Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:
    </div>
    <div class="col-8 lb">
      Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für <a href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/" target="_blank">externe Datenschutzbeauftragte</a> (Version #2019-04-10).
    </div>
  </div>
</div>
