<div class="footer" #_footer  >
  <div class="container-fluid">
    <!-- flex-row justify-content-between -->
    <div class="d-flex ">
      
      <div class="p-2 d-flex align-items-center saving-area" *ngIf='!isSaving' style="width: 140px;">
          <div class="saving-icon"><mat-icon style='color:#383F46;'>check</mat-icon></div>
          <div class="summary-copy">Gespeichert</div>  
      </div>

      <div class="p-2 d-flex align-items-center saving-area" *ngIf='isSaving' style="width: 140px;">
        <div class='template-loader'><mat-spinner  diameter='25'></mat-spinner></div>
      </div>

      <div class="p-2 d-flex align-items-center pl40" >
        <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.0859 0.091148C18.7481 -0.0760699 18.4103 0.007539 18.157 0.258366L10.8942 7.44873L8.7829 5.86017L1.35122 0.174757C1.09786 0.007539 0.760059 -0.0760699 0.506706 0.091148C0.168902 0.258366 0 0.509193 0 0.843628V14.7227C0 14.9735 0.084451 15.2244 0.337804 15.3916L9.20515 22.8328C9.37405 23 9.54296 23 9.71186 23C9.88076 23 10.1341 22.9164 10.2186 22.8328L19.2548 15.3916C19.4237 15.2244 19.5926 14.9735 19.5926 14.7227V0.760019C19.5926 0.509193 19.3393 0.174757 19.0859 0.091148ZM17.9036 2.85024V12.7161L12.2454 8.45204L17.9036 2.85024ZM1.68902 2.51581L17.6502 14.5555L12.8365 18.4851H6.67163L1.68902 14.3047V2.51581Z" fill="#26A69A"
          [class.errorIcon]='fs.errorCount > 0' 
            [class.noErrorIcon]='fs.errorCount == 0'
          />
          </svg>
        <!-- <img src="/assets/invoiceCheckLogo.svg" alt=""> -->
        <div class="pl10 p_t8">
          <p style="font-weight: 600; line-height:0; font-size:10px;" 
            [class.errorColor]='fs.errorCount > 0' 
            [class.noErrorColor]='fs.errorCount == 0'
            >doxfox Rechnungscheck</p>
          <p style="font-weight: 300; line-height:0; font-size:14px;" *ngIf='fs.errorCount == 0'>Keine Probleme gefunden</p>
          <p style="font-weight: 300; line-height:0; font-size:14px;" *ngIf='fs.errorCount == 1'>{{fs.errorCount}} Problem gefunden</p>
          <p style="font-weight: 300; line-height:0; font-size:14px;" *ngIf='fs.errorCount > 1'>{{fs.errorCount}} Probleme gefunden</p>
        </div>
      </div>
        
      <div class="p-2 d-flex align-items-center ml-auto">
        <span *ngIf='hasReduction()'>{{reductionCopy}}</span>
          <div  class="summary-copy" 
          counto [step]="10" [countTo]="countTo" [countFrom]="countFrom" [duration]="1" 
          (countoChange)="intermediate = $event" (countoEnd)="onCountoEnd()">
            <!-- <span *ngIf='!hasReduction()'>Summe {{intermediate | cgcurrency}} </span> -->
            <span style="width: 140px;">Summe {{intermediate | cgcurrency}}</span>
          </div>
      </div>
    </div>
  </div>
</div>


<!-- <div class="summary-icon"> </div> -->
        <!-- <div class="summary-copy" *ngIf='_summaryWriter != null'>Summe  {{_summaryWriter }}</div> -->
        <!-- <div counto [step]="30" [countTo]="10" [countFrom]="0" [duration]="4" (countoChange)="counto = $event" (countoEnd)="onCountoEnd()">{{counto | currency:'EUR':'symbol':'1.2-2'}}</div> -->
        <!-- | currency:'EUR':'symbol':'1.2-2' -->

        