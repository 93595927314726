import { Injectable, NgZone, EventEmitter } from '@angular/core';
import {Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { ControllerService } from 'src/app/shared/controller.service';
import { UserI } from '../core/models/userI';
import { AccountI, AddressI } from '../core/models/accountI';
import { AuthStateE } from '../core/models/authI';
import { TokenstorageService } from '../services/tokenstorage.service';
import { UiService } from '../services/ui.service';
import { NavigationService } from '../services/navigation.service';
import { SIGN_IN_MODE } from '../core/models/enums';
import { addHours, addSeconds } from 'date-fns';
import { ACCOUNT_CALL } from '../services/enums/accountS.callEnum';
import { ERROR_TYPE, IAuthResponse, REPONSE_MESSAGE, STATUS_RESPONSE } from '../core/models/api/auth.response.mode';
import { HttpClient } from '@angular/common/http';
import { AUTH_CALL, AUTH_REQUEST } from '../core/models/auth/auth.enum';
import { SocketService } from '../services/socket.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userData?: UserI;
  checkCount = 0
  private _signMode: SIGN_IN_MODE = SIGN_IN_MODE.LOADING
  private _uid: string = null;
  private _roles: string[] | undefined;
  private _emailIsVeryfied = true
  private adressSaving = false

  credentialData:any
  loggedInEventEmitter: EventEmitter<AuthStateE> = new EventEmitter<AuthStateE>();

  constructor(
    public router: Router,
    public ngZone: NgZone,
    private ui:UiService,
    private accountS: AccountService,
    private tokenStorage:TokenstorageService,
    private _nav:NavigationService,
    private http:HttpClient,
    private ss: SocketService
  ) {
    this._checkTokenAndAccess()
    this.checkCount= 0
  }


  /**
   * --------- 1 ---------------------------------
   */
  private async _checkTokenAndAccess() {
    this.getIP()
    const _uid = this.tokenStorage.getUID();
    const _token = this.tokenStorage.getToken();

    if(_token) {
      this.ss.initSocket(_token.token)
    }
    if (_token && _uid) {
      // console.log(_token);
      this.ss.uidEmit(_uid)

      this._checkUid(_uid);
      return;

    } else if (_token && !_uid) {

      const _r: any = await this.GET(AUTH_CALL.GET_UID)
      console.log(`\n\n\n AUTH_CALL.GET_UID \n\n\n`);
      
      this.tokenStorage.saveUID(_r.u)
      setTimeout(()=>{ this._checkTokenAndAccess()},100)

    } else {

      if ( this.checkCount < 3) {
        //console.log('checkCount', this.checkCount);

        setTimeout(()=>{
          this._checkTokenAndAccess()
          this.checkCount ++
        },100)
      } else {
        this.checkCount = 0
        this.SignOut(true);
        return;
      }
    }

  }


  /**
   *  2 --------------------------------------------
   *  COMPARE LOCAL UID WITH API RESPONSE
   */
   private async _checkUid(uid:string) {
    const _r: any = await this.GET(AUTH_CALL.GET_UID)
    console.log(`\n\n\n AUTH_CALL.GET_UID \n\n\n`);
    // console.log("_R", _r)
    if ( _r.u === uid) {
      this._checkMainAccess();
    } else {
      this.SignOut()
      return
    }
  }


  /**
   * --------- 3 ---------------------------------
  */
   private async _checkMainAccess() {
    
    const _authResponse = await this.GET(AUTH_CALL.GET_ACCESS)
    console.log(`\n\n\n AUTH_CALL.GET_ACCESS \n\n\n`);
    console.log(_authResponse);
    if(_authResponse == null) { this.SignOut(); return; }

    const signInResponse: IAuthResponse = _authResponse as IAuthResponse

    if(signInResponse.status) {

      // console.log("signInResponse.status", signInResponse.status);
      
      switch(signInResponse.status) {
        //CHECK_ACCOUNTING
        case STATUS_RESPONSE.BLOCKED_BY_ADMIN:
          this.snackUnkownError("IHR ACCOUNT WURDE GESPERRT")
        this.SignOut()
        return

        case STATUS_RESPONSE.ERROR:
          this.SignOut()
        return

        case STATUS_RESPONSE.CHECK_ACCOUNTING:
          this._signMode = SIGN_IN_MODE.READY
          this._startSubscribeUser('accounting-confirm')
         return;
        case STATUS_RESPONSE.ACCOUNT_EXPIRED:
          // this.SignOut()
         return;

        case STATUS_RESPONSE.UPDATE_PASSWORD_REQUIRED:
          this._signMode = SIGN_IN_MODE.UPDATE_PASSWORD_REQIERED;
         return;

        case STATUS_RESPONSE.ACCESS_ADDRESS_MISSING:
          // alert("ACTION HIER FEHLT WAS")
          // this._signMode = SIGN_IN_MODE.READY
          this._signMode = SIGN_IN_MODE.ADDRESS_MISSING
          this.ui.loginViewSignInEmitter.emit('ACCESS_ADDRESS_MISSING');
          // this._startSubscribeUser()
        return;

        case STATUS_RESPONSE.ACCESS:
          this._signMode = SIGN_IN_MODE.READY
          this._startSubscribeUser()
        return;
      }

    }

  }
  
  get emailVeryfied(){
    return this._emailIsVeryfied
  }
  get sign_in_mode(): SIGN_IN_MODE {
    return this._signMode
  }

  private _checkToken(){
    // console.log("_checkToken")
    // this.afAuth.idTokenResult.subscribe(
    //   _result =>{

    //     if(_result != null && _result.token != null){
    //       const _d = new Date(_result.expirationTime)
    //       console.log(_d)
    //       // console.log("differenceInMinutes(new Date(_d), new Date(Date.now()))")
    //       this.tokenStorage.saveToken({token:_result.token, expire: _d.getTime()})
    //     //  this.ui.loadAccunt();
    //     }
    //   }
    // )
  }

  hasAccess = (p) =>  {
    return ControllerService.getRoles().includes(p) ? true : false;
  }

  get isLoggedIn(): boolean {
    // const user: UserI = JSON.parse(localStorage.getItem('user'));
    // this._isLoggedIn =  (user !== null) ? true : false;
    // return this._isLoggedIn;
    const _token = this.tokenStorage.getToken();
    return _token != null//this._isLoggedIn;
  }


  getUser = () => {
    const u = JSON.parse(localStorage.getItem('user'));
    // if (u == null) return undefined;
    // if (u === null || u.status !== 'LIVE') return undefined;
    if (u === null){
      return undefined;
    }
    return u;
  }


  get uid(): string {
    if(this._uid == null){
      const user: UserI = JSON.parse(localStorage.getItem('user'));
      if (user != null && user.uid != null && user.uid !== ''){
        this._uid = user.uid;
      }
    }
    return this._uid;
  }

  get roles(): string[] {
    return this._roles;
  }
  private async _SignOut(){

  }
  async setNewPassword(email:string, newPass){
    const _r = await this.POST(AUTH_CALL.POST_CREATE_PASSWORD,
      { "payload": {'password': newPass } })
    if(_r){
      this.SignIn(email, newPass)
    }

  }


  /***
   *
   * VALIDATE FB UISER STUFF -> MIGRATUION VON FB AUTH ZU API AUTH
   */
  private async _fixAuthWithFB(email: string, password: string, withRequiredPWUpdate: boolean){
    // const _fbAuth = await this.fbAuth.signInWithEmailAndPassword(email, password)
    // if (_fbAuth.user && _fbAuth.user.uid) {
    //   const __token = _fbAuth.user['ya']



    //   const _resp2 = await this.POST(AUTH_CALL.POST_VALIDATE_FB,{
    //       email: email, password: password, auth_token:__token})
    //   const validateResponse:IValiateFBResponse = _resp2 as IValiateFBResponse;

    //   if (validateResponse.status && validateResponse.status == STATUS_RESPONSE.SUCCESS) {
    //     if(validateResponse.token && validateResponse.uid ) {
    //       this.tokenStorage.saveToken({
    //         token: validateResponse.token, expire: addHours(new Date(), 8).getTime()
    //         // 

    //         // token: validateResponse.token, expire: addHours(new Date(), 8).getTime()
    //       });
    //       this.tokenStorage.saveUID(validateResponse.uid);

    //       if (withRequiredPWUpdate) {
    //         this._signMode = SIGN_IN_MODE.UPDATE_PASSWORD_REQIERED;
    //         console.log('withRequiredPWUpdate!!!');

    //       } else {
    //         this._checkTokenAndAccess()
    //         console.log('_checkTokenAndAccess!!!');
    //       }

    //     } else {
    //       return this.snackUnkownError();
    //     }
    //   } else {
    //     return this.snackUnkownError();
    //   }

    // } else {
    //   return this.snackUnkownError();
    // }
  }




   snackUnkownError(custom:string = "") {
    if ( custom != "") {
      ControllerService.snack.emit(custom);
    } else {
      ControllerService.snack.emit('Ein Fehler ist aufgetreten. Versuchen Sie es zu einem anderen Zeitpunkt erneut.');
    }

    this._uid = null;
    this._roles = [];
    this.loggedInEventEmitter.emit(AuthStateE.LOGGED_OUT);
  }


  async getIP() {

//const _res = await this.http.get<any>('https://geolocation-db.com/json/').toPromise()
// const _res = await this.http.get<any>('https://jsonip.com').toPromise()
// console.log('_RES >>>>>', _res);

// .pipe(
//   catchError(err => {
//     return throwError(err);
//   }),
//   tap(response => {
//     console.log(response.IPv4);
//   })
// )
  }

  /**
   * SIGN IN
   */
  async SignIn(email, password) {
    this.getIP()
    const signInResponse:IAuthResponse = await this.POST(AUTH_CALL.POST_SIGN_IN, {
      email: email,
      password: password
    })

    var _UpdatePwRequired = false;

    if (signInResponse.message && signInResponse.message == REPONSE_MESSAGE.UPDATE_PASSWORD_REQUIRED) {
      _UpdatePwRequired = true;
    }

    if(signInResponse.status) {

      switch(signInResponse.status) {

        case STATUS_RESPONSE.ERROR:

        if (signInResponse.type && signInResponse.type === ERROR_TYPE.BLOCKED) {
          // this.snackUnkownError(signInResponse.message)
          ControllerService.customSnack.emit({
            panelClass: "customSnack",
            duration: 5000,
            message: signInResponse.message
          })
          this.SignOut()
          return

        } else if (signInResponse.type && signInResponse.type === ERROR_TYPE.WRONG_PW) {
     
          ControllerService.customSnack.emit({
            panelClass: "customSnack",
            duration: 5000,
            message: 'Ihr Passwort ist falsch.'
          })
          return

        } else if (signInResponse.type && signInResponse.type === ERROR_TYPE.NO_PW_IN_DB) {

          this._fixAuthWithFB(email, password, _UpdatePwRequired);
          return

        } else if (signInResponse.type && signInResponse.type === ERROR_TYPE.USER_NOT_FOUND) {     
          
          ControllerService.customSnack.emit({
            panelClass: "customSnack",
            duration: 5000,
            message: 'Dieser Benutzer wurde nicht gefunden.'
          })
          return

        } else {
          this.snackUnkownError();
          return;
        }


        // case STATUS_RESPONSE.UPDATE_PASSWORD_REQUIRED:
        //   this._signMode = SIGN_IN_MODE.UPDATE_PASSWORD_REQIERED;
        //   return;

        // case STATUS_RESPONSE.ACCESS_ADDRESS_MISSING:
        //   // alert("ACTION HIER FEHLT WAS")
        //   this._signMode = SIGN_IN_MODE.ADDRESS_MISSING
        //   this.ui.loginViewSignInEmitter.emit('ACCESS_ADDRESS_MISSING');
        //   // this._startSubscribeUser()
        // return;

        case STATUS_RESPONSE.SUCCESS:

          if(signInResponse.token && signInResponse.refresh && signInResponse.uid ) {
            // const _d = addSeconds(new Date(), 30)
            const _d = addHours(new Date(), 9)
            
            var expired = _d.getTime()
            
            if(signInResponse.expiresIn) {
              
              expired = signInResponse.expiresIn
            }

            this.tokenStorage.saveToken({
              refresh: signInResponse.refresh,
              token: signInResponse.token, expire: expired
            });
            this.tokenStorage.saveUID(signInResponse.uid);
            this._checkTokenAndAccess()
          } else {
            this.snackUnkownError();
            return;
          }

        return;
      }

    }

  }

  async refreshToken() {
    const _refrT = this.tokenStorage.getToken().refresh
    if ( !_refrT ) {
      return
    }
    const repo: any = await this.POST(AUTH_CALL.POST_REFRESH, {refresh: this.tokenStorage.getToken().refresh})
    // console.log('refresh', repo);
    if ( repo && repo.token) {
      const _d = addSeconds(new Date(), 30)
      this.tokenStorage.saveToken({
        refresh: _refrT,
        token: repo.token,
        expire: _d.getTime()
      })
    } else {
      this.SignOut()
    }

  }
  private _startSubscribeUser(forRoute: string = ''){

    setTimeout(()=>{

      ControllerService.setUserId(this._uid)
      // this._emailIsVeryfied = user.emailVerified
      // this._isLoggedIn = true;
      this._signMode = SIGN_IN_MODE.READY
      this.loggedInEventEmitter.emit(AuthStateE.LOGGED_IN);
      this.ui.loadAccunt()
      // this.router.navigate(['dashboard']);
      if (forRoute != "") {
        this.ngZone.run(() => {
          this.router.navigate([forRoute]);
          
  
        });
      } else {
        this.ngZone.run(() => {
          if(this._nav.initRoute){
            this.router.navigate([this._nav.initRoute]);
            
          } else {
            this.router.navigate(['dashboard']);
            
          }
  
        });
      }
      
    },50)
  
  }


  RecoverPassword(email: string){
   // return this.afAuth.sendPasswordResetEmail(email);
  }

  async SignUp(account: AccountI, password: string) {
    // return this.afAuth.createUserWithEmailAndPassword(account.email, password)
    //   .then(result => {
    //     account.uid = result.user.uid
    //     const ref = this.afs.collection('docs').doc(result.user.uid).set(account);
    //     // this.ngZone.run(() => {
    //     //   this.router.navigate(['dashboard'])
    //     // });
    //   })
    //   .catch(error => {
    //     window.alert(error.message);
    //   });
  }


  clearLS(){
    localStorage.setItem('user', null);
    localStorage.removeItem('cryptCode');
    localStorage.removeItem('goae-small');
  }
  
  SignOut(gotoMain: boolean = true) {
    
    this._nav.clearRoute()
    this.ui.SIGN_OUT()
     this.tokenStorage.signOut();
     this.loggedInEventEmitter.emit(AuthStateE.LOGGED_OUT);

     setTimeout(()=>{
      this._signMode = SIGN_IN_MODE.SIGN_IN
    },250)
    // return this.afAuth.signOut().then(() => {
    //   this._signMode = SIGN_IN_MODE.SIGN_IN
    //   localStorage.removeItem('user');
    //  /*  localStorage.removeItem('cryptCode');
    //   localStorage.removeItem('goae-small'); */

    //   this.loggedInEventEmitter.emit(AuthStateE.LOGGED_OUT);

      if (gotoMain){
        this.router.navigate(['/']);
        this.router.navigate(['auth/sign-in'])
      } else {
      }
    // });
  }

  async saveAddress(address:AddressI){
    // address.behandelnder_arzt = this.docName
    //this.address.facharzt_bezeichnung = this.doc_description;
    this.adressSaving = true
    if(address.id == -1){
      // const __data = await this.accountS.addNewAddress(address)
      const __data = await this.accountS.apiPostObject(ACCOUNT_CALL.CREATE_NEW_ADDRESS, {payload: address} )
      .then(
        (data)=>{

          setTimeout(()=>{
            this._checkTokenAndAccess()
            // this.adressSaving = false;

          },250)
        }, err =>{
          // this.isSaving = false;
          this._checkTokenAndAccess()
        })

    } else {
      //const __data = await this.accountS.updateAddress(address)
      const __data = await this.accountS.apiPostObjectAndParams(ACCOUNT_CALL.UPDATE_ADDRESS_BY_ID,address.id.toString() ,{payload: address} )
      .then(
        (data)=>{

          setTimeout(()=>{
            this._checkTokenAndAccess()
            // this.adressSaving = false;

          },250)
        }, err =>{
          this._checkTokenAndAccess()
          // this.isSaving = false;
        })

    }
  }





  async GET(call:AUTH_CALL){
    const _path = AUTH_REQUEST(call)
    const _res = await this.http.get(_path).toPromise()
    if (_res['status'] && _res['status'] == "ERROR" && _res['type']){
      ControllerService.sessionTimeoutEmitter.emit(_res['type'])
     return
    }
    return _res
  }

  async POST(call:AUTH_CALL, payload:Object){
    const _path = AUTH_REQUEST(call)
    const _res = await this.http.post(_path, payload).toPromise()
    // if (_res['status'] && _res['status'] == "ERROR" && _res['type']){
    //   switch(_res['type']) {
    //     case "WRONG_PW":
    //       return;
    //       case "NO_PW_IN_DB":
    //         return _res;
    //         default:
    //         return _res;
    //   }
    //   return;
    // }
    return _res
  }

}
