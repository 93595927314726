<div class="container-fluid pl0 pr0" *ngIf='matItems != null && matItems.length > 0'>
  <div class="position-colum-header">
    <div class="d-flex">
      <div class="p-2 column-title pl40">Bezeichnung Produkt</div>
      <div class="p-2 column-title ml-auto w80 tle">Anzahl</div>
      <div class="p-2 column-title w80 tle">Betrag</div>
      <div class="p-2 column-title  tle pr30"  style="width: 72px;">Aktion</div>
    </div>
  </div>
  <div class="d-flex align-items-center" *ngFor ='let materialItem of matItems; let i = index;'>
    
    <div class="p-2 cell pl40">{{materialItem.bezeichnung}}</div>
    <div class="p-2 cell ml-auto w80 tle">
      <!-- (change)='save()' -->
      <select class="formControl select select_box fs14-400" (change)='matQuantityChanged($event, materialItem)'
      [(ngModel)]='materialItem.quantity'> 
        <option value="1">1</option> 
        <option value="2">2</option> 
        <option value="3">3</option> 
        <option value="4">4</option> 
        <option value="5">5</option> 
        <option value="6">6</option> 
        <option value="7">7</option> 
        <option value="8">8</option> 
        <option value="9">9</option> 
        <option value="10">10</option> 
      </select>
      <!-- {{materialItem.quantity}}
      (change)='save()'   -->
    </div>
    <div class="p-2 cell w80 tle">{{getMaterialSummery(materialItem)}}</div>
    <div class="p-2 cell tle pr30" style="width: 72px;">
      <button mat-icon-button [matMenuTriggerFor]="materialMenu" [disabled]='!tc.isTemplateEditable'>
        <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #materialMenu="matMenu" xPosition="before" >
        <button mat-menu-item (click)="deleteMaterialItem(materialItem)"><span>Löschen</span></button>
      </mat-menu>
    </div>
    
  </div>
</div>