export const environment = {
    production: true,
    firebase : {
      apiKey: "AIzaSyApJx9K6CSIclKz-PdkwxfQBymn7kpoPXY",
      authDomain: "dxfx-dev.firebaseapp.com",
      databaseURL: "https://dxfx-dev-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "dxfx-dev",
      storageBucket: "dxfx-dev.appspot.com",
      messagingSenderId: "632669652214",
      appId: "1:632669652214:web:4853191731fa8ecde098d7"
    },
    endpoints:{
      apiBasePath:"",
      apiBasePathV2: "https://apidev.doxfox.app/",
      //apiBasePathV2: "https://dxfx-express-api-rebuild-bd6vktduma-ez.a.run.app/",
      // apiBasePathV2: "https://dxfx-express-api-rebuild-bd6vktduma-ey.a.run.app/",
    },
    cryptCode: '',
    EncryptKey:  "sa9jdas9d$#;asd8",
    EncryptIV:   "sa9jdas9d$#;asd8",
    // SOCKET_ENDPOINT: 'https://dxfx-express-api-rebuild-bd6vktduma-ez.a.run.app'
    SOCKET_ENDPOINT: 'https://apidev.doxfox.app'
    // SOCKET_ENDPOINT: 'https://dxfx-express-api-rebuild-bd6vktduma-ey.a.run.app'
  };
  