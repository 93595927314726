import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-toolbar',
  templateUrl: './template-toolbar.component.html',
  styleUrls: ['./template-toolbar.component.scss']
})
export class TemplateToolbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
