import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { DxFxBreadCrumb } from 'src/app/core/models/breadCrumpIntemI';
import { InvoiceI } from 'src/app/core/models/invoiceI';
import { AuthService } from 'src/app/shared/auth.service';
import { ControllerService } from 'src/app/shared/controller.service';
import * as _ from "lodash";
import { PdfPreviewI } from 'src/app/core/models/pdfPreviewI';
import { PdfPreviewComponent } from 'src/app/ui/overlays/pdf-preview/pdf-preview.component';
import { TemplateFlatI } from 'src/app/core/models/api/TemplateFlatI';
@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
  @ViewChild(MatSort, {static:true}) sort: MatSort;
  userPath:string;
  invoices:InvoiceI[] = [];
  displayedColumns = ['invoiceDate','invoiceNumber','templateName','patient'];
  /* 'invoiceYear', */
  dataSource: any; 
  winWasOpen = false;
  k:string =''

  constructor(private dialog:MatDialog,
    private authS:AuthService, 
    private router:Router) {
    if(this.authS.getUser() != null){
      this.userPath = this.authS.getUser()['uid']; 
    }
  }

  ngOnInit(): void {
    setTimeout(()=>{
     this.bcPath()
     this.getInvoices()
    },1000)
   }
 
   bcPath(){
     let _bc:DxFxBreadCrumb = {
       bcItems: [
         {title:'Verwaltung',path:'verwaltung'},
         {title:'Rechnungen',path:''},   
       ]
     }
     ControllerService.setBreadCrumbPath(_bc);
   }

   async getInvoices(){
   
   }
   
   openInvoice(e){
    
     let inv:InvoiceI = e;
     inv.hasSurgeryReport = true;
    let _date:PdfPreviewI = {
      invoice:inv,
      template:inv as unknown as TemplateFlatI,
      type:PdfPreviewI.Type.SHOWINVOICE
    }
    this.dialog.open(PdfPreviewComponent,{
      disableClose:true,
      width:'820px',
      height:"92%",   
      backdropClass:"backdrop-background",
      data: _date
    })
  }
   applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
