import { Component,  ElementRef,  OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './shared/auth.service';
import { ControllerService } from './shared/controller.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthStateE } from './core/models/authI';
import { UiService } from './services/ui.service';
import { SIGN_IN_MODE } from './core/models/enums';
import { MatDialog } from '@angular/material/dialog';
import { SessionTimeoutOverlayComponent } from './ui/overlays/session-timeout-overlay/session-timeout-overlay.component';
import { TokenstorageService } from './services/tokenstorage.service';
import { differenceInMinutes, isAfter } from 'date-fns';
import { Subscription } from 'rxjs';
import { LastActivityService } from './services/last-activity.service';
import { ISnackData } from './core/models/viewI/customsnack.model';
import { InactivityWarnOverlayComponent } from './ui/overlays/inactivity-warn-overlay/inactivity-warn-overlay.component';
import { SocketService } from './services/socket.service';
import { Expo} from 'gsap/all'
import {TweenMax} from 'gsap';
import { AccountingInfoPageComponent } from './pages/accounting-info-page/accounting-info-page.component';
import { ISocketOpenInviteMessage } from './services/socket.service';
import { DxFxBreadCrumb } from './core/models/breadCrumpIntemI';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

  host: {
    '(window:resize)': 'onResize($event)',
    '(window:scroll)': 'onScroll($event)',
  }
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('sessionTimeOutDiv') sessionTimeOutDiv: ElementRef;
  public lastActiveDate: Date;
  isSignedIn = false;
  showNav = false;
  
  resizeTimeout: any;
  scrollTimeout: any;
  displayProgressSpinner = false;
  sessionTimeoutVisible = false;  
  accountSubscr: Subscription;
  routerSubscr: Subscription;
  socketSubscription: Subscription;
  socketSubscriptionExpired: Subscription;
  timeoutmessage = 'Sie haben sich auf einem anderen Gerät in Ihrem doxfox-Konto angemeldet, daher wird Ihre Sitzung auf diesem Gerät beendet.'
  socketInvite: Subscription;
  private validateTokenTimoutInterval:any;
  private sessionTimoutInterval:any;
  //  color = 'primary';
  //  mode = 'indeterminate';
  //  value = 25;
   showDarkBg = false
  constructor(
    private lastActiveService: LastActivityService,
    private router: Router,
    private ss: SocketService,
    public authS: AuthService,
    private dialog:MatDialog,
    private snackBar: MatSnackBar,
    private tStorage:TokenstorageService,
    public ui: UiService ){
    
    }
   
  ngOnInit(): void {
    if(this.routerSubscr) {
      this.routerSubscr.unsubscribe()
    }

    ControllerService.breadCrumpEmitter.subscribe( (p:DxFxBreadCrumb) => {
      // this.currentPage = p
      if(p.bcItems && p.bcItems.length == 2) {
        if(p.bcItems[0].path == "vorgaenge" && p.bcItems[1].title == "Detail") {
          this.showDarkBg = true
        } else {
          this.showDarkBg = false
        } 
      } else {
        this.showDarkBg = false
      }
      
    }
      )
    
    this.lastActiveService.lastActive$.subscribe(
      _d => {
        if ( this.lastActiveDate != _d ) {
          this.lastActiveDate = _d
          if (this.ui.isInactivityOverlay) {
            this.ui.activityDetected.emit(true)
          }
        }
      }
    )
    this.checkActivity()
    // this.subscribeUserAccount();

    this.authS.loggedInEventEmitter.subscribe(
      loginData => {
        switch(loginData){
          case AuthStateE.LOGGED_IN:
            this.initTokenCheck();
            this.isSignedIn = this.authS.sign_in_mode == SIGN_IN_MODE.UPDATE_PASSWORD_REQIERED ? false : true;
          this.toggleNavVisibility(true);
            break;
          case AuthStateE.LOGGED_OUT:
            this.isSignedIn = false;
            this.toggleNavVisibility(false);
            break;
        }
      }
    );
    this.ui.loginViewSignInEmitter.subscribe(
      _message => {
        console.log('_message ', _message)
        if(_message == "SESSION_TIMEOUT"){
          //this._openSessionTimeout();
        }
      }
    );
    ControllerService.snack.subscribe(
      (message) => {
        if (message){
          this.snack(message, 'Achtung');
        }
      }
    );
    ControllerService.customSnack.subscribe(
      (message) => {
        if (message){
          this.snackCustom(message);
        }
      }
    );
    // ControllerService.alertMessageEmitter.subscribe(
    //   _alert => {
    //     this.openAlert(_alert);
    //   }
    // );
    // ControllerService.isMenuOpen.subscribe(
    //   menuOpen => {
    //     this.toggleNav();
    //   }
    // );

    ControllerService.preloadOverlay.subscribe(
      show => {
        if (show === true){
          this.displayProgressSpinner = show;
        } else {
          setTimeout(() => {
            this.displayProgressSpinner = false;
          }, 250);
        }

      }
    );

    ControllerService.sessionTimeoutEmitter.subscribe(
      _message => {
        console.log("sessionTimeoutEmitter ", _message)
        if(_message == "TOKEN_EXPIRED"){
           this._openSessionTimeout();
        }
      }
    )

    
    this.socketInvite = this.ss.inviteEmitter.subscribe(
      _auth => {
        if(_auth) {
          this.initAccountInvite(_auth)
        }
      }
    )
    this.socketSubscription = this.ss.socketAuthStateEmitter.subscribe(
      _auth => {
        if(this.ui.account) {
          if(_auth == this.ui.account.uid) {
            this.timeoutmessage = 'Sie haben sich auf einem anderen Gerät in Ihrem doxfox-Konto angemeldet, daher wird Ihre Sitzung auf diesem Gerät beendet.'
            this.initSessionTimeoutWin();
          }
        }
      }
    )
    this.socketSubscriptionExpired = this.ss.socketAuthDemoExpiredEmitter.subscribe(
      _auth => {
        if(this.ui.account) {
          if(_auth == this.ui.account.uid) {
            this.timeoutmessage = 'Ihr Demozeitraum ist abgelaufen'
            this.initSessionTimeoutWin();
          }
        }
      }
    )
  }

  private async checkActivity(){
    console.log(` APP COMP >> checkActivity`);
    if(this.validateTokenTimoutInterval) return;

    this.validateTokenTimoutInterval = setInterval(()=>{

      let now = new Date()

        //  let diff = differenceInSeconds(now, this.lastActiveDate)
         let diff = differenceInMinutes(now, this.lastActiveDate)


        if ( diff > 10 && this.isSignedIn) {
          //this._openSessionTimeout();
          this._openInactivity();
        }

    },5000)
  }


  private checkInviteState(url:string) {
    // if(this.accountingCheckTimoutInterval){
    //   clearTimeout(this.accountingCheckTimoutInterval)
    // }
    // this.accountingCheckTimoutInterval = setTimeout((() => {
    //   // console.log(this.router.routerState.snapshot.url)
    //   // console.log(url, this.ui.accountingState)
    //   if(url != 'accounting-confirm' && this.ui.accountingState != ACCOUNTING_STATE.CONFIRMED) {
    //     // this.checkInviteState()
    //     this.router.navigate(['accounting-confirm'])
    //   }
    // }).bind(this), 500);
    
  }

  private _openInactivity() {
    if (this.isSignedIn == false ) { return }
    clearInterval(this.validateTokenTimoutInterval)
    if (this.ui.isInactivityOverlay) { return }
    this.dialog.open(InactivityWarnOverlayComponent, {
      width:"450px",
      backdropClass: "backdrop-timeout"
      // height:"450px",
      // position: {top: "50px"}
    }).afterClosed().subscribe(
      _data => {
        this.ui.isInactivityOverlay = false
        clearInterval(this.validateTokenTimoutInterval)
        this.validateTokenTimoutInterval = null
        setTimeout(() => {

          this.checkActivity()
        },3000)
      }
    )
  }

  private _openSessionTimeout(){
    this.isSignedIn = true
    if(this.validateTokenTimoutInterval) {
      clearInterval(this.validateTokenTimoutInterval)
    };
    setTimeout(()=>{
      this.dialog.open(SessionTimeoutOverlayComponent, {
        width:"450px",
        height:"250px",
        position: {top: "50px"}
      })

    },100)
  }

  get userName():string {
    // return `${this.ui.accountingState}`
    let _out = ''
    if(!this.ui.account) {
      return ""
    }
    if (!this.isSignedIn){ return "" }
    if(this.ui.account.title != null && this.ui.account.title != ''){
      _out += this.ui.account.title
    }
    if(this.ui.account.firstName != null && this.ui.account.firstName != ''){
      _out += " " + this.ui.account.firstName
    }
    if(this.ui.account.lastName != null && this.ui.account.lastName != ''){
      _out += " " + this.ui.account.lastName
    }
    return _out
  }


  private initTokenCheck(){
    if(this.sessionTimoutInterval) return;
    this.sessionTimoutInterval = setInterval(()=>{
      this.checkToken();
    },5000)
  }

  private checkToken(){
    const _t = this.tStorage.getToken();
    if(_t){
      // console.log('_t ', _t);
      const _currentDate:Date = new Date(Date.now());
      const _tokenDate:Date = new Date(_t.expire);
      
      const _tokenDateTest = _tokenDate //subMinutes(_tokenDate,30);
      // console.log(differenceInMinutes(_tokenDateTest, _currentDate))
      // console.log(_tokenDate)
      if(isAfter(_currentDate, _tokenDateTest)){
        //console.log('SESSION TIMEOUT');
        //this.authS.refreshToken()
       // this._openSessionTimeout();
      }
      //console.log(`Current ${DATETOSTRING(_currentDate, true)} expired in ${DATETOSTRING(_tokenDate, true)} TESTDATE: ${DATETOSTRING(_tokenDateTest,true)}`)
    }
  }

  // private subscribeUserAccount() {

  //    this.accountSubscr = this.ui.accountSubject().subscribe(
  //     _data =>{
  //       this.account = _data
  //       console.log('this.account', this.account);

  //       // this.toggleNavVisibility(true);
  //     }
  //   )

  // }

  toggleNavVisibility(show:boolean){
    // if(show){
    //   this.subscribeUserAccount()
    // } else {
    //   this.accountSubscr.unsubscribe()
    // }

    setTimeout(()=>{
       this.showNav = this.showNav != show ? show : this.showNav;
      //  if(this.socketSubscription) {
      //   this.socketSubscription.unsubscribe()
      // }
      
    },500)
  }

  onScroll(event) {
    if(this.scrollTimeout){
      clearTimeout(this.scrollTimeout)
    }
    this.scrollTimeout = setTimeout((() => {
      ControllerService.setScrollY(window.scrollY)
    }).bind(this), 25);
  }

  onResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
      this.resizeTimeout = setTimeout((() => {
      ControllerService.changeScreenAspect({width:window.innerWidth, height:window.innerHeight})
    }).bind(this), 500);
  }

  snack(message:string, title:string) {
    this.snackBar.open(message, null, {
      duration: 3500,
    });
  }
  snackCustom(item:ISnackData) {
    this.snackBar.open(item.message, null, {
      duration: item.duration,
      panelClass: item.panelClass
    });
  }

  gotoAccount(){
    
   this.router.navigateByUrl('einstellungen');
  }

  adminNav(path:string){
    this.router.navigateByUrl(path);
  }

  // toggleNav(){
  //   if(this.opened){
  //     TweenMax.fromTo(this._nav.nativeElement, 0.45, { 'width':'260px' }, { 'width':'65px',  ease: Expo.easeOut});
  //     this.opened = false;
  //   } else {
  //     TweenMax.fromTo(this._nav.nativeElement, 0.45, { 'width': '65px', }, { 'width':'260px',  ease: Expo.easeOut});
  //     this.opened = true;
  //   }
  // }
  sendMsg(index:number) {
    if(index == 1) {
      this._openSessionTimeout()
      // this.ss.uidEmit(this.ui.account.uid)
    } else if(index == 2) {
      this.initSessionTimeoutWin()
      
    }
  }
  openHelp(index:number){

    if(index === 1){
      if (this.ui.isClinicUser) {
        window.open('/assets/doxfox-Klinik_NavigationuFunktionen.pdf', '_blank')
      } else {
        window.open('/assets/doxfox_Navigation-Funktionen_v1.03.pdf', '_blank')
      }
    } else {
      if (this.ui.isClinicUser) {
        window.open('/assets/doxfox-Klinik_Anleitung2Steps.pdf', '_blank')
      } else {
        window.open('/assets/doxfox_in2Schritten.pdf', '_blank')
      }
    }

  }


  ngOnDestroy(): void {
    if(this.sessionTimoutInterval){
      clearInterval(this.sessionTimoutInterval)
    }
    if(this.validateTokenTimoutInterval){
      clearInterval(this.validateTokenTimoutInterval)
    }
    if(this.accountSubscr) {
      this.accountSubscr.unsubscribe()
    }
    if(this.routerSubscr) {
      this.routerSubscr.unsubscribe()
    }
    if(this.socketSubscription) {
      this.socketSubscription.unsubscribe()
    }
    if(this.socketSubscriptionExpired) {
      this.socketSubscriptionExpired.unsubscribe()
    }
    if(this.socketInvite) {
      this.socketInvite.unsubscribe()
    }
    // this.socketService.disconnect()
  }

  private initAccountInvite(data: ISocketOpenInviteMessage) {
    this.dialog.open(AccountingInfoPageComponent, {
      width:"450px",
      height:"450px",
      data: data,
      backdropClass: "backdrop-timeout"
    })
    // if(!this.sessionTimeoutVisible) {
    //   this.sessionTimeoutVisible = true
    //   TweenMax.to(this.sessionTimeOutDiv.nativeElement, 0.85, { 'opacity':1, 'blurX':0, ease: Expo.easeInOut, delay: 0.25});
    //   setTimeout(()=>{
    //     this.authS.SignOut()
    //   },2600)
    //   setTimeout(() => {
    //     this.deInitSessionTimeoutWin()
    //   },8000)
    // }
  }

  private initSessionTimeoutWin() {
    if(!this.sessionTimeoutVisible) {
      this.sessionTimeoutVisible = true
      TweenMax.to(this.sessionTimeOutDiv.nativeElement, 0.85, { 'opacity':1, 'blurX':0, ease: Expo.easeInOut, delay: 0.25});
      setTimeout(()=>{
        this.authS.SignOut()
      },2600)
      setTimeout(() => {
        this.deInitSessionTimeoutWin()
      },8000)
    }
  }
  private deInitSessionTimeoutWin() {
    if(this.sessionTimeoutVisible) {
      this.sessionTimeoutVisible = false
      TweenMax.to(this.sessionTimeOutDiv.nativeElement, 1, { 'opacity':0, ease: Expo.easeOut, delay: 0.25});
    }
  }

  
}

