
<div class="container-fluid m_t5" *ngIf="address" style="background-color: rgba(0,0,0,0.05)">

  <div class="d-flex pl10 p_t10">
    <div class="p-2 flex-fill">
      <h2>Praxis/Klinik</h2>
    </div>
    
      <div class="p-2  ml-auto" *ngIf="!address.isMainAddress && !ui.isClinicUser && showSetBaseAddress" >
        <app-df-icon-button *ngIf="address.id != -1"
        title= "Als Standard Addresse festlegen"
        icon="star"
        color="accent"
        (clicked)="setAsBaseAddress()"
        >
        </app-df-icon-button>
    
    </div>

    <div class="p-2  ml-auto" *ngIf="address.isMainAddress && !ui.isClinicUser" >
      <mat-icon *ngIf="address.id != -1"
      style="color: #FFB800;"
      >star</mat-icon>
      
  </div>
  </div>

  <div class="row pl10 p_t15 pr10">

    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Name der Praxis/Klinik</mat-label>
      <input class="formControl mat-body" #_klinikName
      [class.formControlError]="address.klinikName == '' || address.klinikName == null"
      [(ngModel)]="address.klinikName" [disabled]="ui.isClinicUser">
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Abteilung</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.abteilung" [disabled]="ui.isClinicUser" [class.formControlDisabled]="ui.isClinicUser">
    </div>
  </div>

  <div class="row pl10 p_t15 pr10">
    <div class="col-lg-5 col-md-5 col-sm-12">
      <mat-label class='mat-caption'>Behandelnde(r) Arzt/Ärztin</mat-label>
      <input class="formControl mat-body formControlDisabled" disabled [(ngModel)]="account.docName">
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <mat-label class='mat-caption'>Facharztbezeichnung</mat-label>
      <!-- <input class="formControl mat-body formControlDisabled" disabled [(ngModel)]="account.doc_description"> -->
      <input class="formControl mat-body formControlDisabled" disabled [(ngModel)]="account.medical_specialty_designation">
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6" *ngIf="showFunktion">
      <mat-label class='mat-caption'>Funktion</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.arzt_role">
    </div>
  </div>

  <div class="row pl10 p_t15 pr10">

    <div class="col-lg-4 col-md-4 col-sm-9">
      <mat-label class='mat-caption'>Straße </mat-label>
      <input class="formControl mat-body" [disabled]="ui.isClinicUser"
      [class.formControlError]="address.streetName == '' || address.streetName == null"  [(ngModel)]="address.streetName">
    </div>
    <div class="col-lg-2 col-md-2 col-sm-9">
      <mat-label class='mat-caption'>Hausnummer</mat-label>
      <input class="formControl mat-body" [class.formControlError]="address.streetNumber == '' || address.streetNumber == null"  [(ngModel)]="address.streetNumber" [disabled]="ui.isClinicUser">
    </div>

    <div class="col-lg-2 col-md-2 col-sm-6">
      <mat-label class='mat-caption'>PLZ</mat-label>
      <input class="formControl mat-body" [class.formControlError]="address.zip == '' || address.zip == null"  [(ngModel)]="address.zip" [disabled]="ui.isClinicUser">
    </div>

    <div class="col-lg-4 col-md-4 col-sm-6">
      <mat-label class='mat-caption'>Stadt</mat-label>
      <input class="formControl mat-body" [class.formControlError]="address.city == '' || address.city == null"  [(ngModel)]="address.city" [disabled]="ui.isClinicUser">
    </div>

  </div>

  <div class="row pl10 p_t25">
    <div class="col-6">
      <h2>Bankverbindung</h2>
    </div>
  </div>

  <div class="row pl10 p_t15 pr10">

    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Kontoinhaber/in</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.kontoinhaber" [disabled]="ui.isClinicUser">
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>USt-IdNr.</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.ust_nr" [disabled]="ui.isClinicUser">
    </div>

  </div>

  <div class="row pl10 p_t15 pr10 p_b20">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Bankinstitut</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.bank_name" [disabled]="ui.isClinicUser">
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6">
      <mat-label class='mat-caption'>IBAN</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.iban" [disabled]="ui.isClinicUser">
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6">
      <mat-label class='mat-caption'>BIC</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.bic" [disabled]="ui.isClinicUser">
    </div>

  </div>

  <div class="row pl10 p_t15 pr10" *ngIf="!ui.isClinicUser">

    <div class="col-12" >
      <div class="d-flex" *ngIf='!isSaving'>
        <!-- <div class="p-2 pl0"  >
          <app-df-icon-button
          title= "Weitere Praxis/Belegklinikadresse anlegen"
          icon="add"
          (clicked)="addAdress()"
          >
          </app-df-icon-button>

        </div> -->
        <div class="p-2 ml-auto">
          <app-df-icon-button
          title= "Einstellungen speichern"
          icon="save"
          color="primary"
          (clicked)="save()"
          [disabled]="saveDisabled"
          >
          </app-df-icon-button>
        </div>
      </div>

      <div class="d-flex justify-content-center" *ngIf='isSaving'>
        <div class="p-2">
          <mat-spinner diameter='25' color='primary'></mat-spinner>
        </div>
      </div>
    </div>


  </div>

</div>