<mat-toolbar style="position: static!important; height: 64px; width: 100%; border-radius: 6px;">
  <p>Fertigstellen</p>
	<span class="toolbar-spacer"></span>
</mat-toolbar>

<div class="container-fluid" style="height: 322px;">

  <div class="d-flex flex-column" >
    <div class="p-2 flex-fill p0">
      <div class="d-flex borderLight align-items-center" *ngIf='patientError'>
        <div class="p-2">
          Kein Patient ausgewählt
        </div>
        <div class="p-2 ml-auto">
          <mat-icon color='warn'>cancel</mat-icon>
        </div>
      </div>
      <div class="d-flex borderLight align-items-center" *ngIf='missingJustificationsError'>
        <div class="p-2 ">
          Fehlende Begründungen für Steigerung
        </div>
        <div class="p-2 ml-auto">
          <mat-icon color='warn'>cancel</mat-icon>
        </div>
      </div>
      <div class="d-flex borderLight align-items-center" *ngIf='missingReportCopiesError'>
        <div class="p-2 ">
          Fehlende Op-Bericht Texte
        </div>
        <div class="p-2 ml-auto">
          <mat-icon color='warn'>cancel</mat-icon>
        </div>
      </div>
      <div class="d-flex borderLight align-items-center" *ngIf='missingSurgeryDocError'>
        <div class="p-2 ">
          Operateur:in nicht ausgewählt
        </div>
        <div class="p-2 ml-auto">
          <mat-icon color='warn'>cancel</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf='isContentAvailable'>
    <div class="d-flex p-0 flex-fill">

          <div class="container">

            <div class="row justify-content-between pl16 pr16 " style="padding-top: 13px!important;">
              <span class="mat-caption">Praxis/Klinik</span>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="textfield100 select-form-field">
                  <mat-select (selectionChange)='addressChanged($event)' [(value)]="selectedAddress">
                    <mat-option *ngFor="let address of addresses" [value]="address">
                      {{address.klinikName}} {{address.streetName}} {{address.zip}} {{address.city}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

    </div>
    <div class="d-flex">
    <div class="p-2" style="width: 230px;">

        <process-date-item [title]="datumsTitle" [inputDate]='invoiceDate' (changedEmitter)="dateChanged($event)"></process-date-item>

    </div>
    <div class="p-2 pl20" *ngIf='isInvoice === true'>
      <div class="d-flex flex-column">
        <div class="p-2">Rechnungsnummer</div>
        <div class="p-2 flex-fill p_t10">
          {{getInvoiceNumber}}
        </div>
      </div>
    </div>
  </div>
  </div>

  <div class="d-flex justify-content-center p_t20" style="height: 36px;">
    <div class="p-2" *ngIf="!isGenerating">
      <button mat-flat-button color='primary' [disabled]='finalCreateDisabled' (click)='save()'>{{readyBtnTxt}}</button>
    </div>
    <div class="p-2" *ngIf="isGenerating">
      <mat-spinner diameter='25' color='primary'></mat-spinner>
    </div>
  </div>

</div>
<mat-toolbar style="height: 64px; width: 100%; border-radius: 6px;">
  <span class="toolbar-spacer"></span>
  <button mat-flat-button (click)='close()' >Abbrechen</button>
</mat-toolbar>
