<div class="container-fluid pl0 pr0" *ngIf='!TABLEVIEW' >
  <div class="position-colum-header">
    <div class="d-flex">
      <div class="p-2 w60" ></div>
      <div class="p-2 w58">Rechnung</div>
      <div class="p-2 w90">OP-Bericht</div>
      <div class="p-2">Maßnahme</div>
      <div class="p-2 w90 ml-auto tlc">GOÄ Text / Ziffer</div>
      <div class="p-2 w60 tlc">Anzahl</div>
      <div class="p-2 w90 tlc">Steigerung</div>
      <div class="p-2" style="width: 362px;">Begründungen für Steigerung</div>
      <div class="p-2 tle w90">Betrag</div>
      <div class="p-2 w72" >
      </div>
    </div>
  </div>

  <div cdkDropList class="groupList example-list" (cdkDropListDropped)="drop($event)">

    <div class="loadingContainer" *ngIf="showPreloader">
      <div class="d-flex h100 justify-content-center align-items-center" >
        <div class="p-0">
          <div class="loader-box">
            <div class="loader">......</div>
          </div>
        </div>
      </div>
    </div>


    <div class="d-flex flex-column example-box " *ngFor="let group of positionGroups" [class.newOrGroupStart]="group.isOrGroup"
    cdkDrag [cdkDragData]='group'
    [cdkDragDisabled]="isOrEditing || itemDisabled"
    [class.splitBG]='group.isOrGroup'
    >

     <div class="example-custom-placeholder-new" *cdkDragPlaceholder></div>

      <div class="p-0"  *ngIf="group.positions.length > 1">
        <div class="d-flex">
          <div class="p-0 p_t10 p_b6" style="margin-left: 28px;">
            <div class="cell-drag-item pl0  " cdkDragHandle >
              <img src="/assets/positionTable/drag_handle-24px.svg" class='cell-drag-item-img' alt="" >
            </div>
          </div>
        </div>

      </div>

      <div class="p-0">
        <!-- POSITIONS -->
        <div *ngFor ='let position of group.positions; let i = index;' >

        <div class="_trenner_EDITING_MODULE_BAR" [class.barOut]="(isOrEditing && position.orType == OR_TYPES.NONE) || (!isOrEditing && (position.orType != OR_TYPES.OR || position == group.positions[0])   )" >
          <div class="d-flex align-items-center " >
            <div class="p-0 ml-auto"></div>
            <div class="p-0 _toolbar_OR_TRENNER" *ngIf='isOrEditing && position.orType == OR_TYPES.OR && position !== group.positions[0]' ></div>
            <div class="splitBG-Trenner" *ngIf='!isOrEditing && position.orType == OR_TYPES.OR && position !== group.positions[0]' >
              <div class="positionOrModeTrenner" ></div>
            </div>

            <div class="p-0" *ngIf="isOrEditing && position !== group.positions[0]">
              <mat-radio-group aria-label="Select an option"  [(ngModel)]="position.orType" color="primary"
              [disabled]="group.positions.length <= 2"
              (ngModelChange)="positionOrModeChanged($event,position)">
                <mat-radio-button [value]="OR_TYPES.OR" >Oder-Trenner</mat-radio-button>
                <mat-radio-button [value]="OR_TYPES.INDENTED">Gruppieren</mat-radio-button>
              </mat-radio-group>
            </div>

            <div class="p-0 ml15" *ngIf="isOrEditing &&  position !== group.positions[0] && position !== group.positions[1]" >
              <div class="d-flex align-items-center add-button pr0" (click)='endORSection(group,position)'>
                <div class="p-2 btn-copy align-text-start p_t5 p_b5 pl0 ml0 pl12">Oder-Sektion beenden</div>
                <div class="p_2 align-text-end  pr0 mr0 pr16">
                  <mat-icon class="nav-icon-mini pr0 mr0">keyboard_return</mat-icon>
                </div>
              </div>
            </div>

          </div>
        </div>


        <div class="_trenner innterContainer"
          [class.positionIsNewGroup]="position.starts_new_group"
          [class.nonSplitBG]='position.orType == OR_TYPES.NONE'
          [class.splitBGActive]='position.orType != OR_TYPES.NONE && position.activated'
        >

          <div class="d-flex colum-drag-box-item " >
            <!-- DRAG OR ERROR ICON-->
            <div class="p-2 p0 pr0"
            [class.error-border-tbl]='hasGoaError(position)'
            [class._active-border]='position.activated'
            [class._unactive-border]='!position.activated'
            [class.errorIconPadding]='hasGoaError(position)'
            [class.nonErrorIconPadding]='!hasGoaError(position)'
            ></div>

            <div class="p-0 w60">
              <div *ngIf='hasGoaError(position)' matTooltipClass='errorTT' class="errorIconCell pl0 pt0"
                matTooltip='{{getErrorTooltip(position)}}' matTooltipPosition='right'>
                <svg width="44" height="46" viewBox="0 0 44 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H44L24 20.5L0 46V0Z" fill="url(#paint0_linear)"/>
                  <path d="M13.432 15.44C13.3687 15.44 13.3117 15.421 13.261 15.383C13.223 15.3323 13.204 15.2753 13.204 15.212L12.957 6.928C12.957 6.86467 12.976 6.814 13.014 6.776C13.0647 6.72533 13.1217 6.7 13.185 6.7H15.408C15.4713 6.7 15.522 6.72533 15.56 6.776C15.6107 6.814 15.636 6.86467 15.636 6.928L15.351 15.212C15.351 15.2753 15.3257 15.3323 15.275 15.383C15.237 15.421 15.1863 15.44 15.123 15.44H13.432ZM14.173 19.981C13.7423 19.981 13.3813 19.8417 13.09 19.563C12.8113 19.2717 12.672 18.9107 12.672 18.48C12.672 18.0367 12.8113 17.6757 13.09 17.397C13.3687 17.1183 13.7297 16.979 14.173 16.979C14.6163 16.979 14.971 17.1183 15.237 17.397C15.5157 17.6757 15.655 18.0367 15.655 18.48C15.655 18.9107 15.5157 19.2717 15.237 19.563C14.9583 19.8417 14.6037 19.981 14.173 19.981Z" fill="white"/>
                  <defs>
                  <linearGradient id="paint0_linear" x1="21.5" y1="12" x2="-5.5" y2="23.5" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FF4548"/>
                  <stop offset="1" stop-color="#D33C3E"/>
                  </linearGradient>
                  </defs>
                </svg>
              </div>
              <div class="cell-drag-item pl0 p_t8 " cdkDragHandle *ngIf='group.positions.length == 1'>
                <img src="/assets/positionTable/drag_handle-24px.svg" class='cell-drag-item-img' alt="" >
              </div>
            </div>


            <!-- CHECKBOX -->
            <div class="p-2 w44">
              <mat-checkbox
                *ngIf='position.position_type == POSITION_TYPES.GOA'
                color='{{getCheckboxColor(position)}}'
                class="checkbox" [(ngModel)]='position.activated'
                [disabled]='itemDisabled || isOrEditing'
                [class.itemDisabledButBlue]='itemDisabled || isPublicTemplate'
                (change)='activateToggleTapped(group, position)'>
              </mat-checkbox>
            </div>

            <!-- OP RELEVANT -->
            <div class="p-2 w90 surgeryRelevantCol">
              <div class="d-flex justify-content-center surgeryRelevantFlexBox" >
                <div class="p-0">
                  <mat-checkbox color='accent' class="checkbox"
                    [(ngModel)]='position.surgery_relevant' (change)='activateSugeryRelevantTapped(position,i)'
                    [disabled]='itemDisabled || isOrEditing' >
                  </mat-checkbox>
                </div>
                <div class="p-0 iconHolder">
                  <mat-icon class="dxfx_icon_btn_24"
                    matTooltipClass='sectionGoaeTT'
                    matTooltip='OP-Ansicht'
                    matTooltipPosition='above'
                    *ngIf="tc.isTemplateEditable"
                  (click)="openFullPusitionOverlay(position, FOCUS_ELMENTS.SURGERY_ITEM_COPY)">
                  <!-- (click)="showSurgerySingleView(position)"> -->
                  wysiwyg</mat-icon>
                </div>
              </div>
            </div>

            <!-- Massnahme -->
            <div class="p-0" style="width: calc(100% / 3);">
              <div class="d-flex">
                <div class="p-2 flex-fill">
                  <div class="truncate-overflow-header-item-tile item-text-style" [class.itemDisabled]="isOrEditing"
                  [class.truncate-overflow-header-item-tile-dragging]="isDragging"
                  (click)="openFullPusitionOverlay(position, FOCUS_ELMENTS.EXAMPLE_COPY)">
                    {{position.example}}
                  </div>
                </div>
              </div>
            </div>

            <!-- GOAE TEXT -->
            <div class="p-2 w90 ml-auto">
              <div class="d-flex bd-highlight p0"  *ngIf='position.position_type == "GOA" ' >
                <div class="p-0 flex-fill goaInfoButton"
                  matTooltipClass='sectionGoaeTT'
                  matTooltip='{{getGoaText(position)}}'
                  matTooltipPosition='above'
                  [matTooltipDisabled]='isOrModeStarted' [class.enabled]='position.templateHasInvoice || !position.activated || isOrModeStarted'
                  *ngIf='position.goa_paragraph != null'>
                  {{position.goa_paragraph.goa_id}}
                </div>
              </div>
            </div>

            <!-- QUANTITY -->
            <div class="p-2 w60">
              <div *ngIf='position.position_type == POSITION_TYPES.GOA'>
                <div class="p-2 factorMenu"  style="width: 52px; height:28px;" (click)="openFullPusitionOverlay(position, FOCUS_ELMENTS.QUANTITY)">
                  <div class="d-flex h100 align-items-center factor_menu_row">
                    <div class="p-0" style="width: 30px; padding-left: 6px!important;">{{position.quantity}}</div>
                    <div class="p-0 factor_menu_icon"><mat-icon>expand_more</mat-icon></div>
                  </div>
                </div>
               
              </div>
            </div>

            <!-- PROGRESS FACTOR -->
            <div class="p-2 w90">
                <div *ngIf="showProgressionFactor(position)" style="display: flex; justify-content:center;">
                  <div class="p-2 factorMenu"  style="width: 64px; height:28px;" (click)="openFullPusitionOverlay(position, FOCUS_ELMENTS.PROGRESS_FACTOR)">
                    <div class="d-flex h100 align-items-center factor_menu_row">
                      <div class="p-0" style="width: 30px; padding-left: 6px!important;">{{position.progression_factor}}</div>
                      <div class="p-0 factor_menu_icon"><mat-icon>expand_more</mat-icon></div>
                    </div>
                  </div>

              </div>
            </div>

            <!--JUSTIFICATIONS-->
            <div class="p-2 " style="width: 362px;">
              <!-- [progression_factors]="getPositionProgressionFactors(position.position_id)" -->
              <app-position-justifications *ngIf="position.position_type == 'GOA' && positionJustifications"
              (onTapped)="openFullPusitionOverlay(position, FOCUS_ELMENTS.JUSTIFICATION_COPY)"
              [currentIndex]="getCurrentJustificationIndex(position)"
              [position]="position"></app-position-justifications>
            </div>


            <div class="p-2 w90" (click)="getSummery1(position)">
              <div class="tle p_t5" [class.enabled]='!position.activated || isOrModeStarted'  *ngIf="position.position_type != 'NONE'">
                {{getSummery(position) | cgcurrency}}
              </div>
            </div>

            <!-- MENU -->
            <div class="p-2 w72">
              <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]='isPublicTemplate || itemDisabled'>
                <mat-icon [class.enabled]='!position.activated || isOrModeStarted'>more_horiz</mat-icon>
              </button>

              <mat-menu #menu="matMenu" xPosition="before" >
                <button mat-menu-item (click)="startOrSection(group, position)" *ngIf='position.orType == OR_TYPES.NONE' ><span>Oder-Sektion starten</span></button>
                <button mat-menu-item (click)="resetOrSectionALert(group, position)" *ngIf='position.orType == OR_TYPES.INDENTED || position.orType == OR_TYPES.OR' ><span>Oder-Sektion auflösen</span></button>
                
                <button mat-menu-item *ngIf="position.orType == 'NONE' && position.starts_new_group"
                (click)="removeStartsNewGroup(position)"><span>Trenner entfernen</span></button>
                <button mat-menu-item *ngIf="position.orType == 'NONE' && !position.starts_new_group"
                (click)="addStartsNewGroup(position)"><span>Trenner einfügen</span></button>
                <!-- <button mat-menu-item (click)="showSurgerySingleView(position)"><span>OP-Ansicht</span></button> -->
                <button mat-menu-item (click)="openFullPusitionOverlay(position, FOCUS_ELMENTS.SURGERY_ITEM_COPY)"><span>OP-Ansicht</span></button>
                <button mat-menu-item [disabled]="isOrModeStarted || position.orType != OR_TYPES.NONE" (click)="deletePosition(group, position)"><span>Löschen</span></button>
              </mat-menu>
            </div>

          </div>


          </div> <!-- _trenner ENDE -->

          <div class="_trenner_EDITING_MODULE_BAR" *ngIf="isOrEditing && position === group.positions[group.positions.length-1]">
            <div class="d-flex align-items-center" style="height: 32px;">
              <div class="p-0 ml-auto"></div>
              <div class="p-0" >
                <div class="d-flex align-items-center add-button pr0" (click)='endORSection(group,position, true)'>
                  <div class="p-2 btn-copy align-text-start p_t5 p_b5 pl0 ml0 pl12">Oder-Sektion beenden</div>
                  <div class="p_2 align-text-end  pr0 mr0 pr16">
                    <mat-icon class="nav-icon-mini pr0 mr0">keyboard_return</mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>


        <!-- POSITIONS ENDE -->
      </div>
      <!-- ende p-0 groupList -->
    </div>

  </div> <!-- ENDE groupList -->





<div class="container-fluid" *ngIf='buttonBarVisible'>
  <div class="d-flex" style="border-top: 1px solid #AABBC7;">

    <div class="p-2 p_t10">
      <div class="col-auto p0 pr0 mr0 ">
        <div class="d-flex align-items-center add-button pr0" (click)='addCustomPositionFor("goae")'>
          <div class="p_2 align-text-end pl12 pr0 mr0">
            <mat-icon class="nav-icon-mini pr0 mr0">add</mat-icon>
          </div>
          <div class="p-2 btn-copy align-text-start  p_t5 p_b5 pl0 ml0 pr16">GOÄ Ziffer</div>
        </div>
      </div>
    </div>



    <div class="p-2 p_t10">
      <div class="col-auto p0 pr0 mr0 ">
        <div class="d-flex align-items-center add-button pr0" (click)='addCustomPositionFor("own")'>
          <div class="p_2 align-text-end pl12 pr0 mr0">
            <mat-icon class="nav-icon-mini pr0 mr0">add</mat-icon>
          </div>
          <div class="p-2 btn-copy align-text-start  p_t5 p_b5 pl0 ml0 pr16">Eigener Textbaustein</div>
        </div>
      </div>
    </div>

    <div class="p-2 p_t10">
      <div class="col-auto p0 pr0 mr0 ">
        <div class="d-flex align-items-center add-button pr0" (click)='addCustomPositionFor("material")'>
          <div class="p_2 align-text-end pl12 pr0 mr0">
            <mat-icon class="nav-icon-mini pr0 mr0">add</mat-icon>
          </div>
          <div class="p-2 btn-copy align-text-start  p_t5 p_b5 pl0 ml0 pr16">Material</div>
        </div>
      </div>
    </div>


  </div>

</div>
<app-section-material-item *ngIf="!isLoading"
  (actions)="actions($event)"
 [section_id]="section.section_id"></app-section-material-item>

