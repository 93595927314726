import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { differenceInHours, differenceInMinutes, isBefore, addDays } from 'date-fns';
import { lla_item_type, template_lla_item, template_lla_items_by_type } from 'src/app/core/models/api/templateUtils';
import * as _ from "lodash";
import { employeeI } from 'src/app/core/models/employeeI';
import { SurgeryHeaderI } from 'src/app/core/models/sectionM';
import { SurgerSupportSelectI } from 'src/app/core/models/surgerSupportSelectI';
import { SurgerySupportPersonI } from 'src/app/core/models/v4/surgery.personi';
import { AccountService } from 'src/app/services/account.service';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { UiService } from 'src/app/services/ui.service';
import { AddSinglelineTextItemComponent } from '../../overlays/add-singleline-text-item/add-singleline-text-item.component';
import { SimpleDateSelectionComponent } from '../../overlays/simple-date-selection/simple-date-selection.component';
import { SimpleTimeSelectionComponent } from '../../overlays/simple-time-selection/simple-time-selection.component';
import { SurgerySupportSelectionComponent } from '../../overlays/surgery-support-selection/surgery-support-selection.component';
import { DATE_TO_TIME_STRING } from 'src/app/utils/helpers';

@Component({
  selector: 'app-surgery-header',
  templateUrl: './surgery-header.component.html',
  styleUrls: ['./surgery-header.component.scss']
})
export class SurgeryHeaderComponent implements OnInit {

  @ViewChild('_surgeryDateStart') _surgeryDateStart:ElementRef;
  @ViewChild('_startTime') _startTime:ElementRef;
  @ViewChild('_endTime') _endTime:ElementRef;
  surgeryDate:number = new Date().getTime()
  surgeryDuration:string = ''
  surgeyStartDate:Date
  surgeyEndDate:Date


  _surgeryDateStartOutPut

  headerInfos:SurgeryHeaderI
  surgerySupportUser:SurgerySupportPersonI[] = []
  // employees:employeeI[]
  lla_item_types = lla_item_type
  lla_items_by_type:template_lla_items_by_type[] = []

  startTime = ''
  endTime = ''
  constructor(
    private datePipe: DatePipe,
    private aS:AccountService,
    public ui:UiService,
    public tc:TemplateControllerService,
    private dialog:MatDialog
  ) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.getInfos()
      // this.load()
      this.reloadSupport()
      this.ui.getOpItems()

    },200)
  }
  get editable():boolean {
    return this.tc.activeProcess.isEditable// .surgery_report_id == null || this.tc.activeProcess.surgery_report_id == -1;
  }
  private initTime(){


    if(this.headerInfos != null){


      this.surgeryDate = this.headerInfos.surgeryDate != null ? this.headerInfos.surgeryDate : new Date().getTime()
      this.surgeyStartDate = new Date(this.headerInfos.timeStart);
      this.surgeyEndDate = new Date(this.headerInfos.timeEnd);
      let minutes:number = differenceInMinutes(this.surgeyEndDate, this.surgeyStartDate)
      // this.surgeryDuration = this.surgeyStartDate.getHours().toString() +':' +this.surgeyStartDate.getMinutes().toString()
      this.surgeryDuration =  minutes.toString()
      // let _startHours:string = this.surgeyStartDate.getHours() < 10 ? "0" +this.surgeyStartDate.getHours().toString() : this.surgeyStartDate.getHours().toString();
      // let _startMinutes:string = this.surgeyStartDate.getMinutes() < 10 ? "0" +this.surgeyStartDate.getMinutes().toString() : this.surgeyStartDate.getMinutes().toString();
      // let _startValueString = _startHours +':' +_startMinutes
      
      
      
      
      this._startTime.nativeElement.value = DATE_TO_TIME_STRING(this.surgeyStartDate)

      // let _endHours:string = this.surgeyEndDate.getHours() < 10 ? "0" +this.surgeyEndDate.getHours().toString() : this.surgeyEndDate.getHours().toString();
      // let _endMinutes:string = this.surgeyEndDate.getMinutes() < 10 ? "0" +this.surgeyEndDate.getMinutes().toString() : this.surgeyEndDate.getMinutes().toString();
      // this._startTime.nativeElement.value = _startHours +':' +_startMinutes
      this._endTime.nativeElement.value = DATE_TO_TIME_STRING(this.surgeyEndDate) // _endHours +':' +_endMinutes

      this._surgeryDateStartOutPut = this.datePipe.transform(this.surgeyStartDate != null ? this.surgeyStartDate : new Date(), 'dd.MM.yyyy')

      this.calculateOPDuration()
    }
  }

  get employees():employeeI[] {
    let _employees:employeeI[] = []
    _employees =  _.orderBy(this.aS.employees, [
        employees => employees.lastName,
        employees => employees.firstName,
      ], ['asc', 'asc']);


      return _employees;
  }
  async load(){

  }
  async reloadSupport(){
    console.log('reloadSupport');
    
    if ( this.ui.isClinicUser) { return }
    // this.load()
    const _support = await this.tc.getSurgerySupport(this.tc.currentTemplateId);
    this.surgerySupportUser = _support as  SurgerySupportPersonI[]
    console.log(this.surgerySupportUser);
    
    const _opArstIndex = this.surgerySupportUser.findIndex(item => item.role_id == 1)
    const _assistenzIndex = this.surgerySupportUser.findIndex(item => item.role_id == 2)
    const _instrumentiertungIndex = this.surgerySupportUser.findIndex(item => item.role_id == 3)
    const _ansaestaesieIndex = this.surgerySupportUser.findIndex(item => item.role_id == 5)

    if(_opArstIndex == -1){
      this.surgerySupportUser = _.concat([{employee_id:-1, id:-1, firstName:'', lastName:'', role_id:1, role_title:'Operateur:in'}], this.surgerySupportUser)
      // this.surgerySupportUser = _.concat([{employee_id:-1, id:-1, firstName:'', lastName:'', role_id:1, role_title:'Operierende(r) Ärztin/Arzt'}], this.surgerySupportUser)
    }
    if(_assistenzIndex == -1){
      this.surgerySupportUser = _.concat([{employee_id:-1, id:-1, firstName:'', lastName:'', role_id:2, role_title:'Assistenz'}], this.surgerySupportUser)
    }
    if(_instrumentiertungIndex == -1){
      this.surgerySupportUser = _.concat([{employee_id:-1, id:-1, firstName:'', lastName:'', role_id:3, role_title:'Instrumentierung'}], this.surgerySupportUser)
    }
    if(_ansaestaesieIndex == -1){
      this.surgerySupportUser = _.concat([{employee_id:-1, id:-1, firstName:'', lastName:'', role_id:5, role_title:'Anästhesie'}], this.surgerySupportUser)
    }

    this.surgerySupportUser.sort((a, b) => a.role_id - b.role_id)
    //  console.log('this.surgerySupportUser ', this.surgerySupportUser)
  }
  async getInfos() {
    const _result = await this.tc.getAdditionalsureryInfos(this.tc.currentTemplateId);
    this.headerInfos = _result as SurgeryHeaderI

    this.initTime()
  }
  afterCollapse(e){

  }
  async deleteSupport(item:SurgerySupportPersonI){
    if(item.id == -1){return}
    console.log(item)
    await this.tc.removeSupportItem(item.id);
    this.reloadSupport();
  }

  addSurgeryDudes(){
    const d: SurgerSupportSelectI = {
      employees: this.employees,
      supportRoles: this.aS.surgerySupportRoles
    };
    this.dialog.open(SurgerySupportSelectionComponent, {
      width: '600px',
      data: d,
      backdropClass: 'backdrop-background',

    }).afterClosed().subscribe(
      _data => {
        if(_data && _data === 'reload'){
          this.reloadSupport()
        }
      }
    );
  }
  // addSurgeryDateEvent(type: string, event: MatDatepickerInputEvent<Date>) {
  //   // this.events.push(`${type}: ${event.value}`);

  //   /* this.changedEmitter.emit(event.value); */
  // }
  openSurgeryTimeSelection(value:{endTime:boolean}){
    const _isEndTime = value.endTime
    this.dialog.open(SimpleTimeSelectionComponent,{
      data: _isEndTime ? new Date(this.headerInfos.timeEnd) : new Date(this.headerInfos.timeStart),
      disableClose: true
    }).afterClosed().subscribe(
      (_newDate:Date) => {
        if(_newDate != null){
          //this.changedEmitter.emit(_newDate);
          if(_isEndTime){
            this.headerInfos.timeEnd = _newDate.getTime()
          } else {
            this.headerInfos.timeStart = _newDate.getTime()
          }
          this.initTime()
          this.save()
        }
      }
    )
  }
  openSurgeryDateStartSelection(){
    if(!this.editable){
      return
    }
    this.dialog.open(SimpleDateSelectionComponent,{
      data: new Date(this.surgeryDate),
      disableClose: true
    }).afterClosed().subscribe(
      (_newDate:Date) => {
        if(_newDate != null){
          // console.log(_newDate)
          //this.changedEmitter.emit(_newDate);
          this.headerInfos.surgeryDate = _newDate.getTime()
          this.initTime()
          this.save()
          // this.initTime()
        }
      }
    )
  }

  surgeryStartTimeChanged(e){
    if (!e.target.value) {
      this._startTime.nativeElement.value = DATE_TO_TIME_STRING(this.surgeyStartDate)
      this.headerInfos.timeStart = this.surgeyStartDate.getTime()
      return
      
      // return
    }
    let _hours:number = this._startTime.nativeElement.value.split(':')[0];
    let _minutes:number = this._startTime.nativeElement.value.split(':')[1];
    let start:Date = new Date(this.headerInfos.timeStart)
    start.setHours(_hours);
    start.setMinutes(_minutes);
    this.headerInfos.timeStart = start.getTime()

    var end:Date = new Date(this.headerInfos.timeEnd)

    const diffInHours = differenceInHours(end, start)
    /* console.log(' diffInHours ', diffInHours) */

    if(isBefore(end, start )){
      const diffInHours = differenceInHours(end, start)
      /* console.log(' diffInHours ', diffInHours) */
      end = new Date(addDays(end, 1))
      this.headerInfos.timeEnd =end.getTime()
    }

    this.save()
    this.calculateOPDuration()
  }
  surgeryEndTimeChanged(e){
     if (!e.target.value) {
      this._endTime.nativeElement.value = DATE_TO_TIME_STRING(this.surgeyEndDate)
      this.headerInfos.timeEnd = this.surgeyEndDate.getTime()
      return
    }
    let _hours:number = this._endTime.nativeElement.value.split(':')[0];
    let _minutes:number = this._endTime.nativeElement.value.split(':')[1];
    var end:Date = new Date(this.headerInfos.timeStart)
    var start:Date = new Date(this.headerInfos.timeStart)

    end.setHours(_hours);
    end.setMinutes(_minutes);
    if(isBefore(end, start )){
      end =  new Date(addDays(end, 1))
    }
    this.headerInfos.timeEnd = end.getTime()
    this.save()
    this.calculateOPDuration()
  }
  calculateOPDuration(){

    this.surgeyStartDate = new Date(this.headerInfos.timeStart)
    this.surgeyEndDate = new Date(this.headerInfos.timeEnd)
    let minutes:number = differenceInMinutes(this.surgeyEndDate, this.surgeyStartDate)
    let _hoursOut = minutes/60;
    let _rHoursOut = Math.floor(_hoursOut);
    var _minutesOut = (_hoursOut - _rHoursOut) * 60;
    var _rMinutesOut = Math.round(_minutesOut);
    this.surgeryDuration = _rHoursOut.toString() + 'h '+ _rMinutesOut.toString() +'min'

  }
  addOpZeug(allType:lla_item_type){
    this.dialog.open(AddSinglelineTextItemComponent, {
      data: ['', allType],
      width: "500px"
    }).afterClosed().subscribe(
      _text => {
        if(_text != null){
          this.addLLA(allType,  _text)
        }
      }
    )
  }

  addLLA(type:lla_item_type, text:string){
    switch (type) {
      case lla_item_type.ANTIBIOSE:
         this.ui.addLLaFor('ANTIBIOSE', text).then(
          x => {
            this.headerInfos.antibiose = text;
            this.ui.getOpItems()
          }
        )
        break;
      case lla_item_type.LOKALISIERUNG:
         this.ui.addLLaFor('LOKALISIERUNG', text).then(
          x => {
            this.headerInfos.lokalisation = text;
            this.ui.getOpItems()
          }
        )
        break;
      case lla_item_type.LAGERUNG:
        this.ui.addLLaFor('LAGERUNG', text).then(
          x => {
            this.headerInfos.lagerung = text;
            this.ui.getOpItems()
          }
        )
        break;
    }

  }
  async save(){
    console.log(this.headerInfos);
    
    await this.tc.postAdditionalsureryInfos(this.headerInfos, this.tc.currentTemplateId)
    // this.reloadSupport()
  }
  get antibioseItems(): template_lla_item[] {
    let _lla_items_by_typeF:template_lla_items_by_type
    _lla_items_by_typeF = this.ui.lla_items_by_type.find((item) => item.type == "ANTIBIOSE")
    return _lla_items_by_typeF != null ? _.orderBy(_lla_items_by_typeF.items, [item => item.text.toLowerCase()]) : []
  }
  get lagerungItems(): template_lla_item[] {
    let _lla_items_by_typeF:template_lla_items_by_type
    _lla_items_by_typeF = this.ui.lla_items_by_type.find((item) => item.type == "LAGERUNG")
    return _lla_items_by_typeF != null ? _.orderBy(_lla_items_by_typeF.items, [item => item.text.toLowerCase()]) : []
  }
  get lokalisierungItems(): template_lla_item[] {
    let _lla_items_by_typeF:template_lla_items_by_type
    _lla_items_by_typeF = this.ui.lla_items_by_type.find((item) => item.type == "LOKALISIERUNG")
    return _lla_items_by_typeF != null ? _.orderBy(_lla_items_by_typeF.items, [item => item.text.toLowerCase()]): []
  }


}



