<mat-toolbar class="overlay-toolbar">
  OP Team
  <span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_mini" (click)='close()'>close</mat-icon>
</mat-toolbar>
<div class="container">

  <div class="d-flex justify-content-between flex-column " >  

    <div class="p-2">
      <mat-label class='mat-caption'>Funktion</mat-label>
      <!-- <mat-label class='mat-caption'>{{selectedRole.role_title}}</mat-label> -->
      <mat-form-field appearance="outline" class="textfield100 select-form-field" >
        <mat-select [(value)]="selectedRole" (selectionChange)='supportRoleChanged($event)'>
          <mat-option></mat-option>
          <mat-option *ngFor="let role of supportRoles" [value]="role">
            {{role.role_title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="p-2">
      <mat-label class='mat-caption'>Name</mat-label>
      <mat-form-field appearance="outline" class="textfield100 select-form-field" >
        <mat-select [(value)]="selectedEmployee" (selectionChange)='employeeChanged($event)'>
          <mat-option></mat-option>
          <mat-option *ngFor="let employee of employees" [value]="employee">
            {{employee.title}} {{employee.firstName}} {{employee.lastName}}
          </mat-option>
          <div mat-option class="d-flex align-items-center add-button-select" (click)="addEmployee()">
            <div class="p-2  btn-copy pl12 p_t5 p_b5">Neue Person</div>
            <div class="p_2 align-text-end ml-auto">
              <mat-icon class="nav-icon-mini">add</mat-icon>
            </div>
          </div>
        </mat-select>
      </mat-form-field>
    </div>

  </div>
</div> 

<mat-toolbar class="overlay-toolbar">
  <div class="d-flex w100">
    <div class="p-2 ml-auto">
      <app-df-icon-button
      title= "Speichern"
      icon="save"
      color="primary"
      [disabled]="isSaveDisabled"
      (clicked)="save()"      
      >
      </app-df-icon-button>  
    </div>
  </div>

</mat-toolbar>
  