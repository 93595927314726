import { Component, ComponentFactoryResolver, ElementRef, OnInit, ViewChild, ViewContainerRef,Type } from '@angular/core';
import { DxFxBreadCrumb } from 'src/app/core/models/breadCrumpIntemI';
import { ControllerService } from 'src/app/shared/controller.service';
import * as _ from 'lodash'
import { Router } from '@angular/router';
import { UiService } from 'src/app/services/ui.service';
import { AccountService } from 'src/app/services/account.service';
import { SettingsPanelType } from 'src/app/shared/settingsContentPanelType';
import { ACCOUNT_CALL } from 'src/app/services/enums/accountS.callEnum';

export interface NAV_CHIP_ITEM {
  panelType:SettingsPanelType;
  is_active:boolean;
  panelValue:string;
  chipTitle:string;
  index:number
}
@Component({
  selector: 'app-administration-overview',
  templateUrl: './administration-overview.component.html',
  styleUrls: ['../administration.scss']
})
export class AdministrationOverviewComponent implements OnInit {



  @ViewChild('_childContainer', {static: true}) _childContainer:ViewContainerRef;
  @ViewChild('_contentHolder', {static: false}) _contentHolder:ElementRef;
  currentTabBarIndex = 0
  isLoading=true

  navChipList:NAV_CHIP_ITEM[] = []

  // navItems:string[] = ['patientenverwaltung', 'materialliste', 'mitarbeiter','textbausteine','rechnungen', 'benutzerkonten', 'goae-editor']

  contentCards:string[] = []

  constructor(
    private accountS:AccountService,
    private ui:UiService,
    public resolver: ComponentFactoryResolver,
    private router:Router) { }

  ngOnInit(): void {
    setTimeout(()=>{
      let _bc:DxFxBreadCrumb = {
        bcItems: [
          {title:'Verwaltung',path:'verwaltung'},
        ]
      }
      ControllerService.setBreadCrumbPath(_bc);
      ControllerService.setCurrentPage('verwaltung')
      this.getRoles()

    },1000)

  }


  get activeChips():NAV_CHIP_ITEM[] {
    return this.navChipList && this.navChipList.length > 0 ? this.navChipList.filter(item => item.is_active).sort((a,b) => a.index -b.index) : []
  }

  private _toggleUserFilter(setActive:boolean){
    for(const _chip of this.navChipList){
      if(_chip.panelType == SettingsPanelType.USERS){
        _chip.is_active = setActive
      } else {
        if(setActive){
          _chip.is_active = false;
        }
      }
    }
  }
  private _isUserActivce():boolean {
    const navChipLis:NAV_CHIP_ITEM = this.navChipList.find(item => item.panelType == SettingsPanelType.USERS)
    if(navChipLis){
      return navChipLis.is_active
    }
    return false;
  }
  toggleFilter(filter:NAV_CHIP_ITEM){
    if(filter.panelType == SettingsPanelType.USERS){
      this._toggleUserFilter(!filter.is_active)
    } else {
      if(!filter.is_active){
        if(this._isUserActivce()){
          this._toggleUserFilter(false)
        }
        filter.is_active = true;
      } else {
        filter.is_active = false;
      }
    }
    // filter.is_active = !filter.is_active;
    setTimeout(()=>{
      ControllerService.windowResize.emit(ControllerService.currentScreenSize);
    },50)
    // console.log(filter)
  }
  private async getRoles(){


    this.navChipList = [

    ]

    const roles:any = await this.accountS.apiGet(ACCOUNT_CALL.GET_ROLES)


    if(this.ui.isClinicUser){

      // const _index = this.navChipList.findIndex(item => item.panelType == SettingsPanelType.PATIENT)
      // if(_index != -1){
      //   this.navChipList.splice(_index, 1)
      // }
      this.navChipList = [
      { panelType: SettingsPanelType.OWN_BILLIG, is_active:true, panelValue: 'OWN_BILLIG', chipTitle: 'Eigene Textbausteine', index:4  },
      { panelType: SettingsPanelType.OP_ITEM, is_active:true, panelValue: 'OP_ITEM', chipTitle: 'Perioperative Angaben', index:5  },
      { panelType: SettingsPanelType.MATERIAL, is_active:true, panelValue: 'MATERIAL', chipTitle: 'Materialliste', index:6  },
      ]
    } else {
      this.navChipList = [
        { panelType: SettingsPanelType.PATIENT, is_active:true, panelValue: 'PATIENT', chipTitle: 'Patienten', index:2 },
      { panelType: SettingsPanelType.EMPLOYEE, is_active:true, panelValue: 'EMPLOYEE' , chipTitle: 'Mitarbeiter', index:3 },
      { panelType: SettingsPanelType.OWN_BILLIG, is_active:true, panelValue: 'OWN_BILLIG', chipTitle: 'Eigene Textbausteine', index:4  },
      { panelType: SettingsPanelType.OP_ITEM, is_active:true, panelValue: 'OP_ITEM', chipTitle: 'Perioperative Angaben', index:5  },
      { panelType: SettingsPanelType.MATERIAL, is_active:true, panelValue: 'MATERIAL', chipTitle: 'Materialliste', index:6  },
      ]
    }

    // if(roles.includes('manager')){
    //   this.navChipList = _.concat([{
    //     panelType: SettingsPanelType.USERS, is_active: false, panelValue: "USERS", chipTitle: 'Userverwaltung', index:1
    //   }], this.navChipList)
    // }
    if(roles.includes('goa_admin')){
      this.navChipList.push({
        panelType: SettingsPanelType.GOAE, is_active: false, panelValue: "GOAE", chipTitle: 'GOÄ Ziffer Editor', index:7
      })
    }
    if(roles.includes('super_admin')){
      this.navChipList.push({
        panelType: SettingsPanelType.EXPERTISES, is_active: false, panelValue: "EXPERTISES", chipTitle: 'Fachbereiche', index:8
      })
    }
    // if(roles.includes('goa_admin')){
    //   this.contentCards = [
    //      'PATIENT', 'EMPLOYEE', 'OWN_BILLIG', 'MATERIAL', 'OP_ITEM', 'GOAE', 'EXPERTISES'
    //   ]
    // } else {
    //   this.contentCards = [
    //      'PATIENT', 'EMPLOYEE', 'OWN_BILLIG', 'MATERIAL', 'OP_ITEM'
    //   ]
    // }

    // if(roles.includes('manager')){
    //   this.contentCards = _.concat(['USERS'], this.contentCards)
    // }


    this.isLoading = false
  }
  // fadeViewOut(index:number){
  //   TweenMax.fromTo(this._contentHolder.nativeElement, 0.35, { 'opacity': 1},
  //   { 'opacity': 0,  ease: Power4.easeOut, onComplete:()=>{

  //     //this.injectViewForIndex(index)
  //     this.navto(index)

  //   }});
  // }
  // navto(index){
  //   setTimeout(()=>{
  //     this.router.navigate(['verwaltung',this.navItems[index]])
  //   },50)
  // }
  // fadeViewIn(){
  //   TweenMax.fromTo(this._contentHolder.nativeElement, 0.35, { 'opacity': 0},
  //   { 'opacity': 1,  ease: Power4.easeOut});
  // }

  injectViewForIndex(index:number){

  }

}
