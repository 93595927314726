<div style="position: relative; height: auto;">
  <div class="p-2 factorMenu" [matMenuTriggerFor]="progressionmenu" style="width: 64px; height:28px;" *ngIf="!itemDisabled" #menuTrigger="matMenuTrigger">
    <div class="d-flex h100 align-items-center factor_menu_row">
      <div class="p-0" style="width: 30px; padding-left: 6px!important;">{{progression_factor}}</div>
      <div class="p-0 factor_menu_icon"><mat-icon>expand_more</mat-icon></div>
    </div>
  </div>
  <div class="p-2 factorMenu" style="width: 64px; height:28px;" *ngIf="itemDisabled">
    <div class="d-flex h100 align-items-center factor_menu_row">
      <div class="p-0" style="width: 30px; padding-left: 6px!important;">{{progression_factor}}</div>
      <div class="p-0 factor_menu_icon"><mat-icon>expand_more</mat-icon></div>
    </div>
  </div>
  <mat-menu #progressionmenu="matMenu">
    <div *ngFor='let factor of factors' class="factorMenuOption" (click)="select(factor)" >{{factor.view_value}}</div>
    <div class="factorMenuOptionAdd" (click)="add()"><mat-icon>add</mat-icon></div>
  </mat-menu>
</div>
