import { Component, OnInit, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { PatientFlatI } from 'src/app/core/models/v3/patientFlat';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { UiService } from 'src/app/services/ui.service';
@Component({
  selector: 'template-patient-item',
  templateUrl: './template-patient-item.component.html',
  styleUrls: ['./template-patient-item.component.scss', '../../../../pages/templates-details.scss']
})
export class TemplatePatientItemComponent implements OnInit {

  @Input() patientFlat:PatientFlatI

  @Output() onEditPatient = new EventEmitter<string>();

  constructor(
    private tc: TemplateControllerService, public ui:UiService
    ) { }

    get editable():boolean {
      if(this.tc.templateHasInvoice || this.tc.templateHasSurgeryReport){
        return false
      }
      return true;
    }
  ngOnInit(): void {

  }
  addPatient(){
    this.onEditPatient.emit('addPatient')
  }
  editPatient(){
    this.onEditPatient.emit('editPatient')

  }


}
