<ng-template #progressSpinnerRef>
<!-- 	<mat-progress-spinner [color]="color" [diameter]="diameter" [mode]="mode" [strokeWidth]="strokeWidth" [value]="value">
	</mat-progress-spinner> -->
<!-- 	<div class="dfloader"></div> -->

  <div class="cgSpinner-box" >

      
    <div class="cgSpinner">
      <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M56 31C56 44.8071 44.8071 56 31 56C17.1929 56 6 44.8071 6 31" stroke="url(#paint0_linear_101_235)" stroke-width="8"/>
        <path d="M6 31C6.00001 17.1929 17.1929 6 31 6C44.8071 6 56 17.1929 56 31" stroke="url(#paint1_linear_101_235)" stroke-width="8"/>
        <defs>
        <linearGradient id="paint0_linear_101_235" x1="57" y1="34" x2="4" y2="33" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0"/>
        <stop offset="0.791667" stop-color="#1976D2"/>
        </linearGradient>
        <linearGradient id="paint1_linear_101_235" x1="5" y1="30" x2="58" y2="30" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0"/>
        <stop offset="0.9375" stop-color="#1976D2"/>
        </linearGradient>
        </defs>
        </svg>
    </div>   

    <!-- <div class="loader">......</div> -->
    <!-- <div class="loader">
      <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M56 31C56 44.8071 44.8071 56 31 56C17.1929 56 6 44.8071 6 31" stroke="url(#paint0_linear_101_235)" stroke-width="8"/>
        <path d="M6 31C6.00001 17.1929 17.1929 6 31 6C44.8071 6 56 17.1929 56 31" stroke="url(#paint1_linear_101_235)" stroke-width="8"/>
        <defs>
        <linearGradient id="paint0_linear_101_235" x1="57" y1="34" x2="4" y2="33" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0"/>
        <stop offset="0.791667" stop-color="#1976D2"/>
        </linearGradient>
        <linearGradient id="paint1_linear_101_235" x1="5" y1="30" x2="58" y2="30" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0"/>
        <stop offset="0.9375" stop-color="#1976D2"/>
        </linearGradient>
        </defs>
        </svg>
        
    </div> -->
  </div>
</ng-template>

