<div class="container p_t40 p_b40">
  <div class="d-flex flex-column align-items-center">
    <!-- style="height: 106px;" -->
    <!-- <div class="col-12">
      <p style="text-align:center; font-size:16px; font-weight:400;">Ihre Sitzung ist abgelaufen.
      <br>Bitte melden Sie sich erneut an.</p>
    </div> -->
    <!-- <div class="col-12">
      <div class="progress"></div>
    </div> -->
    <!-- <div class="p-0" style="border: 1px solid red;"> -->
      <div class="p-0 circle-wrap">
        <div class="circle">
          <div class="mask full">
            <div class="fill"  [ngStyle]="{'transform': 'rotate(' + degrees +'deg)'}"></div>
          </div>
          <div class="mask half">
            <div class="fill"></div>
          </div>
          <div class="inside-circle"> {{progress}}</div>
        </div>
      </div>
      <div class="p-2">
        <p style="text-align:center; font-size:16px; font-weight:400;">Sie werden in {{progress}} Sekunden automatisch abgemeldet.</p>
      </div>
      <!-- <mat-progress-spinner
        [mode]="mode"
        [value]="progress">
      </mat-progress-spinner> -->
    <!-- </div> -->

  </div>

</div>
<!-- <mat-toolbar style="height: 64px; width: 100%; border-radius: 6px;" class="tlc justify-content-center">
  <div style="width:auto; text-align:center;"  class="tlc justify-content-center" >
    <button mat-flat-button color="primary" (click)="close()">Ich bin noch da</button>
  </div>
</mat-toolbar> -->
