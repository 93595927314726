import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import {FormControl} from '@angular/forms';
import { GOA_SMALL, Gnr } from 'src/app/core/models/goaeM';


import { SectionM } from 'src/app/core/models/sectionM';
import { MatDialog } from '@angular/material/dialog';

import { AlertService } from 'src/app/services/alert.service';


@Component({
  selector: 'template-scs-report-position-item',
  templateUrl: './template-scs-report-position-item.component.html',
  // styleUrls: ['../positions.scss']
})
export class TemplateScsReportPositionItemComponent implements OnInit {
  @Input() templateDocumentId: string;
  @Input() isInProcesses:boolean
  @Input() isPublicTemplate:boolean
  @Input() section:SectionM;

  @ViewChild('_goaTF') _goaTF:ElementRef;

  @Output() deletePositonAtIndex  = new EventEmitter();


  myControl = new FormControl();

  filteredOptions;//: GOA_SMALL[] = [];
  goae_list_small:GOA_SMALL[] = [];
  summery:number = 0;
  resizeTimeout: any;
  splitTimeOut:any = null
  deletePositonSubscription:any
  // indentedItems:Array<boolean> = [];
  constructor(private dialog:MatDialog, private alertService:AlertService) { }

  errorMessage = 'null';

  ngOnInit() {
    setTimeout(()=>{
      this.init();
    },600)
  }

  init(){

    // for (var i = 0; i < this.section.positions.length; i++) {
    //   if(this.section.positions[i].befunde ==  null){
    //     this.section.positions[i].befunde = [];
    //     this.section.positions[i].befunde.push(
    //       {
    //         selected:true,
    //         text:""
    //       }
    //     );
    //   }
    // }
  }
  displayFn(item: GOA_SMALL): string {
    return item && item.name ? item.name : '';
  }

}
