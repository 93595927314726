<mat-toolbar class="overlay-toolbar" >
  <div *ngIf='currentViewMode !== viewModes.LIST_PATIENTS_MODE'>{{title}}</div>
  <input *ngIf='currentViewMode === viewModes.LIST_PATIENTS_MODE'
    class="formControl mat-body" placeholder="Patient suchen.." (keyup)="applyFilter($event.target.value)" >  

  <span class="toolbar-spacer"></span>
  
  <div class="p-0 " *ngIf='currentViewMode !== viewModes.LIST_PATIENTS_MODE'>
    <div class="d-flex align-items-center add-button pr0" (click)='showListView()'>
      <div class="p_0 align-text-end pl12 pr0 mr0">
        <mat-icon class="nav-icon-mini pr0 mr0" style="font-size:20px!important; margin: -3px;">list</mat-icon>
      </div>
      <div class="p-0 btn-copy align-text-start  pl0 ml0 pr16" style="margin-top:-2px;">Alle anzeigen</div>
    </div>
  </div>
  <mat-icon (click)='addPatient()' *ngIf='currentViewMode === viewModes.LIST_PATIENTS_MODE'
    matTooltip='Patient anlegen' 
    class='justification_arrow_icon_blue ml10' >add</mat-icon>
  <mat-icon (click)='close()' 
    matTooltip='Abbrechen' 
    class='justification_arrow_icon ml10' style="font-size: 20px!important;">close</mat-icon>
</mat-toolbar>



<div class="container" style="padding: 0px!important; margin: 0px!important; height: 472px;
  overflow-y: scroll; overflow-x: hidden;" *ngIf='isLoading == false && currentViewMode === viewModes.LIST_PATIENTS_MODE'>
    
   <div class="row" style="padding: 0px!important; margin: 0px!important;" >

    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0" style="width: 100%; ">
 
      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 24px!important;">Nachname </th>
        <td mat-cell *matCellDef="let element" style="padding-left: 24px!important;">{{element.lastName}}</td>
      </ng-container>
    
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Vorname </th>
        <td mat-cell *matCellDef="let element">{{element.firstName}}</td> 
      </ng-container>

      <ng-container matColumnDef="Geburtsdatum">
        <th mat-header-cell *matHeaderCellDef > Geburtsdatum </th>
        <td mat-cell *matCellDef="let element"> {{element.birthDate | date: 'dd.MM.yyyy'}}</td>
      </ng-container>
      
      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef > Ort </th>
        <td mat-cell *matCellDef="let element"> {{element.city}}</td>
      </ng-container>
      
      <ng-container matColumnDef="zip">
        <th mat-header-cell *matHeaderCellDef > Plz </th>
        <td mat-cell *matCellDef="let element"> {{element.zip}}</td>
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="element-row" *matRowDef="let row; columns: displayedColumns;" (click)='select(row)'></tr>
    </table>

  </div>

</div>

<div class="container" style="padding: 0px!important; margin: 0px!important; height: 472px;
  overflow-y: scroll; overflow-x: hidden;" *ngIf='isLoading == false &&  currentViewMode !== viewModes.LIST_PATIENTS_MODE'>
  
  <app-patient-modify 
    (onExistingUserModified)="onExistingUserModified($event)"
    [viewMode]="patientDetailViewMode"
    [healthEnsurances]="healthEnsurances"
    [patient]="newPatient"
    ></app-patient-modify>
  
</div>

<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>

  <app-df-icon-button *ngIf='currentViewMode !== viewModes.LIST_PATIENTS_MODE && hasExistingUserModified === true'
  title="Speichern & Übernehmen"
  icon="person_add"
  (clicked)="savePatient(true)"
  [disabled]="!canSave"
  >
  </app-df-icon-button>  
  <app-df-icon-button *ngIf='currentViewMode !== viewModes.LIST_PATIENTS_MODE && hasExistingUserModified === false'
  title="Übernehmen"
  icon="person_add"
  (clicked)="insertPat()"
  [disabled]="!canSave"
  >
  </app-df-icon-button>  


</mat-toolbar>
