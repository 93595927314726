import { PositionFlatI } from "../sectionPositionM";
import { SurgeryReportItenV4I } from "../v3/SurgeryReportItemIV3";


export interface FullPositionOverlayData {
  position: PositionFlatI
  textfocusElement?: TEXT_FOCUS_ELEMENT
  reportItems?:SurgeryReportItenV4I[]
}

export enum TEXT_FOCUS_ELEMENT {
  NONE = 'NONE',
  EXAMPLE_COPY = 'EXAMPLE_COPY',
  JUSTIFICATION_COPY = 'JUSTIFICATION_COPY',
  SURGERY_ITEM_DESCRIPTION = 'SURGERY_ITEM_DESCRIPTION',
  SURGERY_ITEM_COPY = 'SURGERY_ITEM_COPY',
  QUANTITY = 'QUANTITY',
  PROGRESS_FACTOR = 'PROGRESS_FACTOR',
  
  
  ADD_REPORT = 'ADD_REPORT',
  REMOVE_REPORT = 'REMOVE_REPORT',
  PREV_REPORT = 'PREV_REPORT',
  NEXT_REPORT = 'NEXT_REPORT',

  ADD_JUSTIFICATION = 'ADD_JUSTIFICATION',
  REMOVE_JUSTIFICATION = 'REMOVE_JUSTIFICATION',
  PREV_JUSTIFICATION = 'PREV_JUSTIFICATION',
  NEXT_JUSTIFICATION = 'NEXT_JUSTIFICATION',
  
  
}