import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MaterialI } from '../core/models/materialI';
import { BilligItemI } from '../core/models/billing_itemI';

@Injectable({
  providedIn: 'root'
})
export class UiUtilServiceService {

  private resizeTimeout: any;

  constructor(public http: HttpClient) { 

    

  }
  async updateMaterial(item:MaterialI){
    if(item.id == null || item.id == -1){
      const _items =  await this.http.put(`${environment.endpoints.apiBasePathV2}templates/modules/material/add`,{
        item:item
      }).toPromise()
      return  _items
    } else {
      const _items =  await this.http.post(`${environment.endpoints.apiBasePathV2}templates/modules/material/update/${item.id}`,{
        item:item
      }).toPromise()
      return  _items
    }
  }

  async getAllFromApi() {
    const _items =  await this.http.get(`${environment.endpoints.apiBasePathV2}templates/modules/material`).toPromise()
    return  _items
  }

  async postBillingItem(item:BilligItemI){
    if(item.billing_item_id == -1){
      console.log('POST add' , item)
      const _result = this.http.put(`${environment.endpoints.apiBasePathV2}templates/modules/billing-items/add`,{
        item: item
      }).toPromise()
      return _result
    } else {
      const _result = this.http.post(`${environment.endpoints.apiBasePathV2}templates/modules/billing-items/update/${item.billing_item_id}`,{
        item: item
      }).toPromise()
    }
    await this.getAllBilligItemsFromApi()
  }

  // async getBillingItemsByManaedIds(ids:string[]){
  //   const _items = await this.http.get(`${environment.endpoints.apiBasePathV2}templates/modules/billing-items`).toPromise()
  //   return _items;
  // }

  async getAllBilligItemsFromApi() {
    const _path = environment.endpoints.apiBasePathV2 + 'templates/modules/billing-items'
    const _items =  await this.http.get(_path).toPromise()
    // .then(
    //   _data => {
    //     return _data//_data['data'];
    //   }
    // )
    return  _items
  }

  async getLLAsForUser(){
    const _path = environment.endpoints.apiBasePathV2 + 'templates/modules/lla/lla-all'
    const _items =  await this.http.get(_path).toPromise()
    .then(
      _data => {
        return _data['data'];
      }
    )
    return  _items
  }
  async addLLaFor(type:string, title:string) {
    const _path = environment.endpoints.apiBasePathV2 + 'templates/modules/lla/lla-add/' +type
    return await this.http.put(_path, {
        text: title,
    }).toPromise();
  }
  async updateLLaFor(id:string, pText:string) {
    const _path = environment.endpoints.apiBasePathV2 + 'templates/modules/lla/lla-update/' +id
    return await this.http.post(_path, {
        text: pText,
    }).toPromise();
  }
  async deleteLLaFor(id:string) {
    const _path = environment.endpoints.apiBasePathV2 + 'templates/modules/lla/lla-remove/' +id
    return await this.http.delete(_path).toPromise();
  }


  startInterVal() {
    
    
  }
  protected stopInterval() {
    
    
  }
}
