import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CryptService {
  private key = environment.EncryptKey;
  private iv = CryptoJS.enc.Utf8.parse(environment.EncryptIV);
  constructor() { }

  encryptAES(text)  {
    return CryptoJS.AES.encrypt(text, this.key).toString();
  };


  /**
   * Decrypt an encrypted message
   * @param encryptedBase64 encrypted data in base64 format
   * @param key The secret key
   * @return The decrypted content
   */
  decryptAES(encryptedBase64)  {
    const decrypted = CryptoJS.AES.decrypt(encryptedBase64, this.key);
    if (decrypted) {
      try {
        console.log(decrypted);
        const str = decrypted.toString(CryptoJS.enc.Utf8);
        if (str.length > 0) {
          return str;
        } else {
          return 'error 1';
        } 
      } catch (e) {
        return 'error 2';
      }
    }
    return 'error 3';
  };

//   encryptUsingAES256(text): any {
//     var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), this.key, {
//         // keySize: 128 / 8,
//         iv: this.iv,
//         // mode: CryptoJS.mode.CBS,
//         padding: CryptoJS.pad.Pkcs7
//     });
//     return encrypted.toString();
// }
// // encryptUsingAES256(text): any {
// //   var encrypted = CryptoJS.AES.encrypt(text, this.key);
// //   return encrypted.toString();
// // }
//   decryptUsingAES256(decString) {
//       var decrypted = CryptoJS.AES.decrypt(decString, this.key, {
//           keySize: 128 / 8,
//           iv: this.iv,
//           mode: CryptoJS.mode.CBC,
//           padding: CryptoJS.pad.Pkcs7
//       });
//       return decrypted.toString(CryptoJS.enc.Utf8);
//   }
}
