<mat-toolbar class="overlay-toolbar  align-items-center">
  <div class="p-2">{{title}}</div>
  <span class="toolbar-spacer"></span>
  <mat-icon *ngIf="viewMode == VIEWMODES.HISTORY" class="dxfx_icon_btn_medium" (click)='hideHistory()'>list</mat-icon>
  <mat-icon *ngIf="viewMode == VIEWMODES.STATES_VIEW" class="dxfx_icon_btn_medium" (click)='showHistory()'>history</mat-icon> 
  <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
</mat-toolbar>

<div class="container-fluid p0" style="height: calc(100% - 88px); overflow: hidden;"  *ngIf="viewMode == VIEWMODES.STATES_VIEW">

  <div class="d-flex flex-column" style="height: 100%; overflow-y:scroll;">
    <div class="p-2 ml10">
      Bewahren Sie den Überblick über den Stand <br>ihrer Rechnungs- und Berichtsleistungen.
    </div>
    <div class="p-0">
      <!-- *ngFor="let status of tc.processStates; let index = index" [class.stateSelectItemBoxSelected]='selectedIndex == index' -->
      <div class="d-flex stateSelectItemBox"
      *ngFor="let status of availableStates; let index = index" [class.stateSelectItemBoxSelected]='selectedItem == status'
      (click)="itemSelect(status)">
        <div class="p-2"> <div class='stateDot' [ngStyle]='{"background-color":status.color}'></div> </div>
        <div class="p-2 _w130 _head">{{status.view_value}}</div>
        <div class="p-2 _descr">{{status.description}}</div>
      </div>
    </div>
  </div>
</div>


<div class="container-fluid p0" *ngIf="viewMode == VIEWMODES.HISTORY" style="height: calc(100% - 46px); overflow: hidden;" >

  <div class="d-flex flex-column" style="height: 100%; overflow-y:scroll;">

    <div class="p-0">
      <div class="d-flex _tableHeader align-items-center" >
        <div class="p-0-"><mat-icon style="margin-left: 15px; margin-top: 4px;" class="dxfx_icon_btn_mini_disabled">history</mat-icon></div>
        <div class="p-0 _w130" style="margin-left: 22px; ">status</div>
        <div class="p-0 _w110" style="padding-right:33px!important; ">geändert</div>
        <div class="p-0 _w110" style="padding-right:33px!important; ">von</div>
        <div class="p-0 " style="padding-right:33px!important; ">Notiz</div>
      </div>
    </div>

    <div class="p-0">
      <div class="d-flex stateHistoryItemBox align-items-center" *ngFor="let hist of history;">
        <div class="p-2"> <div class='stateDot' [ngStyle]='{"background-color":hist.color}'></div> </div>
        <div class="p-2 _w130 _head">{{hist.view_value}}</div>
        <div class="p-2  _w110" style="padding-right:33px!important;">{{hist.dateTime | cgDate}}</div>
        <div class="p-2  " style="padding-right:33px!important;">{{hist.firstName}} {{hist.lastName}}</div>
        <div class="p-2  _descr" style="padding-right:33px!important;">{{hist.message}}</div>
        <!-- <div class="p-2 _descr ml-auto" style="padding-right:33px!important;">{{hist.dateTime | duration}}</div> -->
      </div>
    </div>

  </div>

</div>

<mat-toolbar class="overlay-toolbar tlc justify-content-center" *ngIf="viewMode == VIEWMODES.STATES_VIEW">
  <div style="width:130px; text-align:center;" class="tlc justify-content-center" >
    <app-df-icon-button *ngIf="selectedItem" title="Speichern" color="primary" icon="save"  (clicked)="save()"></app-df-icon-button>  
  <!-- <button mat-flat-button color='primary' (click)='save()' [disabled]='selectedIndex == -1' >Speichern</button> -->
  </div>
</mat-toolbar>
