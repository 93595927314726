<!-- 
<div class="loader-box"  *ngIf='isLoading'>
  <div class="loader">......</div>
</div> -->

<div class="container " [class.CONTENTOUT]='fadeOut' [class.CONTENTIN]='!fadeOut'>
  <div class="row p_t40">
    <div class="col-12" style="padding: 0px!important;">
      <h1 class="pl0">Vorlagen</h1>
    </div>
  </div>
</div>


<div class="container pl0 pr0 template-inner-wrapper  "  *ngIf='!isLoading'>
  <div class="row" [class.CONTENTOUT]='fadeOut' [class.CONTENTIN]='!fadeOut' >

    <div class="col-12">
      <mat-accordion>
        <mat-expansion-panel class='filterPanel' expanded='false' #_filterPanel>
          <mat-expansion-panel-header style="height: 72px!important;">
            <div class="row" style="padding: 0px!important;">
              <div class="col">
                <button mat-icon-button>
                  <mat-icon color='primary'>tune</mat-icon>
                </button>
                <span style="font-size: 14px; font-weight: 600; color:#017bdf">Filter</span>
              </div>
            </div>
          </mat-expansion-panel-header>
          <div class="container-fluid p_t10">

            <div class="d-flex">
              
              <div class="p-2 flex-fill _filterContainer ml10 m_b10 mr10" >
                <p class="filter-title">Kategorie </p>
                <mat-chip-list class="example-chip" #chipList multiple="true" *ngIf='!isLoading'>
                  <mat-chip #chip="matChip" class="filter-chip" *ngFor="let filter of ui.templatePoolFilters"
                    [ngStyle]='{"background-color": ui.isTemplatePoolFilterActive(filter) ? filter.color : "rgba(0,0,0,0)"}'
                    [class.filterChipUnactive]="!ui.isTemplatePoolFilterActive(filter)"
                    [selected]='ui.isTemplatePoolFilterActive(filter)'
                    (click)='ui.toogleTemplatePoolFilter(filter)'>
                    <mat-icon class="filter-chip-icon" *ngIf="ui.isTemplatePoolFilterActive(filter)">done</mat-icon>
                    <mat-icon class="filter-chip-icon" *ngIf="!ui.isTemplatePoolFilterActive(filter)">add</mat-icon>
                    {{filter.name}}
                  </mat-chip>
                </mat-chip-list>
              </div>

                  <div class="p-2  ml-auto m_b10 ">
                      <mat-icon *ngIf="ui.hasOwnTemplatePools" class="dxfx_icon_btn_mini" (click)='editPools()'>edit</mat-icon>
                    <!-- <button mat-menu-item (click)='createTemplatePool()'>Vorlagenpool erstellen</button> -->
                  </div>
              <div class="p-2 _filterContainer  m_b10 w15p" *ngIf="templateCreateButtons.length > 0 && ui.hasTemplateAdminRole">
                <div class="d-flex align-items-center add-button" (click)='createTemplatePool()'>
                  <div class="p-2  btn-copy pl12 p_t5 p_b5">Vorlagenpool erstellen</div>
                  <div class="p_2 align-text-end ml-auto">
                    <mat-icon class="nav-icon-mini">add</mat-icon>
                  </div>
                </div>
                <!-- <button mat-menu-item (click)='createTemplatePool()'>Vorlagenpool erstellen</button> -->
              </div>
            
            </div>
            <div class="d-flex">
              <div class="p-2 flex-fill _filterContainer mr10 ml10 m_b10" >
                <p class="filter-title">Fachbereich</p>
                <mat-form-field appearance="outline" class="textfield100 select-form-field _filterContainerFB">
                  <mat-select (selectionChange)='expertiseChanged($event)' [(value)]="ui.fachgebiet" >
                    <mat-option *ngFor="let expertise of ui.fachgebiete" [value]="expertise">
                      {{expertise.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="d-flex ">
              
              <div class="p-2 _filterContainer " *ngFor='let group of ui.treatmentFilterGroups; let i = index' [class.filterContainerFirst]="i==0" [class.filterContainers]="i > 0">
                <div class="row">
                  <div class="col-auto">
                    <p class="filter-title">{{group}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <p class="filter-title">
                      <span class='all-filter' (click)='toggleSelectAll(group, true)'>alle</span>
                      <span class='all-filter' (click)='toggleSelectAll(group, false)'>keine</span>
                    </p>
                  </div>
                </div>
                <mat-chip-list class="example-chip" #chipList multiple="true">
                  <div *ngFor="let treatmentfilter of getFilterByGroup(group)">
            
                    <mat-chip #chip="matChip"  class="filter-chip"
                      [class.filterChipActiveBlue]="treatmentfilter.selected" [class.filterChipUnactive]="!treatmentfilter.selected"
                      [selected]='treatmentfilter.selected' (click)='toggleRegion(treatmentfilter)'
                      (selectionChange)="changeSelectedRegion($event, treatmentfilter)">
                      <mat-icon class="filter-chip-icon" *ngIf="treatmentfilter.selected">check</mat-icon>
                      <mat-icon class="filter-chip-icon" *ngIf="!treatmentfilter.selected">add</mat-icon>
                      {{treatmentfilter.filter}}
                    </mat-chip>
                  </div>

                </mat-chip-list>
              </div>
              
           
            </div>

            

            <div class="d-flex m_t19 align-items-center " *ngIf='!isLoading && !isCreatingNewTemplate'>
              <div class="p-2  flex-fill" >
                <mat-form-field class="textfield100 select-form-field" appearance="outline">
                  <input matInput placeholder="Passende Vorlage suchen" (keyup)="applyFilter($event.target.value)">
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
              </div>

              <div class="p-2 ml-auto" style="width: 280px;" *ngIf="templateCreateButtons.length == 0 && ui.hasTemplateAdminRole">
                <div class="d-flex align-items-center add-button" (click)='createTemplatePool()'>
                  <div class="p-2  btn-copy pl12 p_t5 p_b5">Vorlagenpool erstellen</div>
                  <div class="p_2 align-text-end ml-auto">
                    <mat-icon class="nav-icon-mini">add</mat-icon>
                  </div>
                </div>
              </div>

              <div class="p-2 ml-auto" style="width: 280px;" *ngIf="templateCreateButtons.length == 1">
                <div class="d-flex align-items-center add-button" (click)='createNewTemplateForPool(templateCreateButtons[0])'>
                  <div class="p-2  btn-copy pl12 p_t5 p_b5">{{templateCreateButtons[0].title}}</div>
                  <div class="p_2 align-text-end ml-auto">
                    <mat-icon class="nav-icon-mini">add</mat-icon>
                  </div>
                </div>
              </div>

              <div class="p-2 ml-auto" style="width: 280px;" *ngIf="templateCreateButtons.length > 1">
                <div class="d-flex align-items-center add-button" [matMenuTriggerFor]="menu"  >
                  <div class="p-2  btn-copy pl12 p_t5 p_b5">Neue Vorlage</div>
                  <div class="p_2 align-text-end ml-auto">
                    <mat-icon class="nav-icon-mini">add</mat-icon>
                  </div>
                </div>
                <mat-menu #menu="matMenu" >
                  

                    <button *ngFor="let tcbutton of templateCreateButtons;" mat-menu-item class="fs12-400" style="width: 263px!important;" (click)='createNewTemplateForPool(tcbutton)'>{{tcbutton.title}}</button>
               
                </mat-menu>
              </div>
            </div>

          </div>

        </mat-expansion-panel>

      </mat-accordion>
    </div>

  </div>
</div>
<!-- p_t40 -->
<!-- -->
<div class="container pl0 pr0 template-inner-wrapper  p_b60"  *ngIf='!isLoading'>
  <mat-card class="p0 m0"  [class.CONTENTOUT]='fadeOut' [class.CONTENTIN]='!fadeOut' >

    <div class="container p0"  *ngIf='tableIsEmpty'>
      <div class="row p0 m0 justify-content-center" style="min-height: 360px;">
        <div class="col-auto p0 d-flex align-items-end"  *ngIf='tableIsEmpty && !isLoading'>
          <svg viewBox="0 0 64 64" width="64" height="64">
            <g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2" fill="#DCECF2" stroke="#DCECF2">
              <line data-cap="butt" data-color="color-2" fill="none" stroke-miterlimit="10" x1="60" y1="60" x2="48"
                y2="48" stroke-linecap="butt"></line>
              <circle fill="none" stroke="#DCECF2" stroke-miterlimit="10" cx="26" cy="26" r="24"></circle>
              <path data-color="color-2" fill="none" stroke-miterlimit="10" d="M10.5,22 C12,16.4,16.4,12,22,10.5">
              </path>
            </g>
          </svg>
        </div>
        <div class="col-12 tlc p_t20 align-items-end" style="height: 65px;">
          <p style="font-weight: 400; font-size: 18px;" *ngIf='tableIsEmpty && !isLoading'>Keine Ergebnisse gefunden</p>
          <div class="loader" *ngIf='!tableIsEmpty && isLoading'>......</div>
        </div>
      </div>
    </div>
    <div class="container "  *ngIf='!tableIsEmpty && !isLoading'>

      <div class="row " *ngIf='!tableIsEmpty'>
        <div class="container-fluid _tableHeader p0">

        <!--TABLE HEADER-->
         <div class="d-flex p0">

           <div class="p-2 ml15" style="width:62px">Ersteller</div>

           <div class="p-2">
             <div class="d-flex p0">
               <div class="p-0 ml10" style="cursor:pointer;" (click)="ui.toggleTemplateNameSort()">Name</div>
               <div class="p-0 mt2 ml5">
                 <mat-icon class="tableFilterIcon"  *ngIf="ui.templateNameSort == 'nameDesc'"
                           [class.tableFilterIconActive]="ui.templateNameSort == 'nameDesc'">north</mat-icon>
                 <mat-icon class="tableFilterIcon" *ngIf="ui.templateNameSort == 'nameAsc'"
                           [class.tableFilterIconActive]="ui.templateNameSort == 'nameAsc'">south</mat-icon>
               </div>
             </div>
           </div>

           <div class="p-2 ml-auto">
             <div class="d-flex p0">
               <div class="p-0 mt2 mr5">
                 <mat-icon class="tableFilterIcon" *ngIf="ui.templateTimeSort == 'dateDesc'"
                           [class.tableFilterIconActive]="ui.templateTimeSort == 'dateDesc'">south</mat-icon>
                 <mat-icon class="tableFilterIcon" *ngIf="ui.templateTimeSort == 'dateAsc'"
                           [class.tableFilterIconActive]="ui.templateTimeSort == 'dateAsc'">north</mat-icon>
               </div>
               <div class="p-0 mr15" style="cursor:pointer;" (click)="ui.toggleTemplateTimeSort()">zuletzt bearbeitet</div>
             </div>
           </div>

           <div class="p-2" style="width: 155px; text-align:center;">ACTION</div>
           <div class="p-2 mr15"></div>

         </div>
        </div>
        <div class="container-fluid p0">
          <div [@listAnimation]="overviewTemplates.length"
              (@listAnimation.start)="logAnimation($event)"
              (@listAnimation.done)="logAnimation($event)">
          <div class="d-flex flex-column  p0 align-items-center" *ngFor="let element of overviewTemplates;" >
            <div class="p-0 flex-fill" style="width:100%; height:72px; position:relative;">
              <template-row 
              [template]="element" 
              (showDetails)="onOpenTemplateDetails($event)"
              (onReloadTemplates)="loadTemplates()"></template-row>
            </div>

          </div>
          </div>
        </div>

      </div>
    </div>
  </mat-card>

</div>
