<div class="container-fluid p0" style="position: relative; overflow: hidden; height: 100%;">

  <div style="height: 56px;position: fixed; width: 820px; z-index: 900; ">
  
    <mat-toolbar style="border-top-left-radius: 6px; border-top-right-radius: 6px;">
    
        <span class="toolbar-spacer"></span>
        <mat-icon  *ngIf="showPrintButton" class='justification_arrow_icon' style="font-size: 20px!important;" (click)="print()">print</mat-icon>
        <mat-icon  class='justification_arrow_icon' style="font-size: 20px!important;" (click)="close()">close</mat-icon>
    </mat-toolbar>

  </div>

  <div class="container-fluid p0 " style="height: 100%; position: relative; padding-bottom:56px!important;" >
    <div class="container-fluid p0 m_b40" style="height: 100%!important;margin-top: 56px;  overflow-x: hidden; ">
      <div class="row" style="height: 100%; " >
        <div class="col-12" style="min-height: 100%;" *ngIf='show'>
  
          <pdf-viewer 
          [src]="pdfSrc" 
          [render-text]="true"
          [autoresize]="true"
          [show-all]="true"
          style="display: block;"
          ></pdf-viewer>
  
  </div>
  </div>
  </div>
  </div>



</div>
