<div class="container">

  <div class="d-flex p_t25" *ngIf="!ui.isClinicUser">

    <div class="p-0 " *ngIf="ui.subscriptionPlanId == 1">
      <div class="d-flex fs14-400">
        <div class="p-2 ">
          <p class="fs-md18">Basic <span  class="activeLicenceMarker">aktiv</span> </p>
          <p>Einzeluser</p>
        </div>
      </div>
    </div>
    <div class="p-0 " *ngIf="ui.subscriptionPlanId == 2">
      <div class="d-flex">
        <div class="p-2  accountingList fs14-400">
          <p class="fs-md18">Premium <span  class="activeLicenceMarker">aktiv</span> </p>
          <p>Team, inklusive Abrechnungsperson</p> 
        </div>
      </div>
    </div>
    <div class="p-0 " *ngIf="ui.subscriptionPlanId == 3">
      <div class="d-flex">
        <div class="p-2  accountingList fs14-400">
          <p class="fs-md18">Premium Plus  <span  class="activeLicenceMarker">aktiv</span> </p>
          <p>Team, inklusive Abrechnungsperson</p> 
        </div> 
      </div>
    </div>

    <div class="p-2 ml-auto">
      <button mat-flat-button (click)="openFullplanOverlay()">Alle ansehen</button>
    </div>
  </div>
  
  <div *ngIf="loading">
    <mat-spinner diameter='25' color='primary'></mat-spinner>
  </div>

	<div *ngIf="!loading && errorMessage">
		<div class="d-flex align-items-center justify-content-center" style="height: 150px;">
			<div class="p-2">
				<h2 style="color:#D32F2F">{{errorMessage}}</h2>
			</div>
		</div>
	</div>

  <div *ngIf="!loading && !errorMessage">
    
    <div class="d-flex ">
      <div class="p-0" style="margin-top: 8px; margin-left: 10px;"  *ngIf="ui.isClinicUser">
        <mat-radio-group aria-label="Select an option" style="color: #333!important; font-size: 14px!important; font-weight: 400!important;" color="primary" [(ngModel)]="accountingListView" (change)="onViewChaged($event)">
          <mat-radio-button value="type">Fachbereiche</mat-radio-button>
          <mat-radio-button value="persons">Abrechnungsperson</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-0 ml-auto pt0 ">
        <div class="d-flex ">
          
          <div class="p-0"  *ngIf="showCreateACcountingButton">
            <app-df-icon-button 
              title="Abrechnung anlegen"
              icon="credit_card"
              color="primary"
              (clicked)="createNewAccounting()"
              >
              </app-df-icon-button>
          </div>

          <div class="p-0 "  >
            <div class="d-flex">
              <div class="p-0" *ngIf="isViewLoaded">
                <app-df-icon-button
                  title="Abrechnungsperson hinzufügen"
                  icon="group_add"
                  color="primary"
                  (clicked)="inviteUser('ACCOUNTANT')"
                  >
                  </app-df-icon-button>
              </div>
              <div class="p-0" *ngIf="isViewLoaded && !ui.isClinicUser && ui.subscriptionPlanId == 3">
                <app-df-icon-button
                  title="Kollege hinzufügen"
                  icon="personal_injury"
                  color="primary"
                  (clicked)="inviteUser('USER')"
                  >
                  </app-df-icon-button>
              </div>
            </div>
          </div>

        </div>    
      </div>
    </div>
    
    <div class="d-flex flex-column" >
      
      <div class="p-0"  *ngIf="ui.isClinicUser === true " >
        <div class="d-flex">
          <div class="p-2 flex-fill" *ngIf="accountingListView == 'type' ">
            <table mat-table [dataSource]="sectorataSource"  multiTemplateDataRows matSort class="mat-elevation-z0" style="width: 100%; ">

              <ng-container matColumnDef="fachbereich">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fachbereich</th>
                <td mat-cell *matCellDef="let element"> {{element.description}}</td>
              </ng-container>
              <ng-container matColumnDef="countDocs" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 90px;">Ärzte</th>
                <td mat-cell *matCellDef="let element" style="text-align: center" 
                [matTooltip]="getDocsToolTip(element)"
                matTooltipClass="multiline-tooltip"
                matTooltipPosition="above">{{element.docs.length}}</td>
              </ng-container>
            
              <ng-container matColumnDef="countAccountant">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 0px; ">Abrechner</th>
                <td mat-cell *matCellDef="let element" style="text-align: center"
                [matTooltip]="getAccountantsToolTip(element)"
                matTooltipClass="multiline-tooltip"
                matTooltipPosition="above">{{element.accountants.length}}</td>
              </ng-container>
          
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width: 45px; "></th>
                <td mat-cell *matCellDef="let element">
                  <!-- <mat-icon class="dxfx_icon_btn_mini"(click)="addAccountantForClinic(element)" >add </mat-icon> -->
                </td>
              </ng-container> 
              
              <tr mat-header-row *matHeaderRowDef="fachbereichDisplayedColumns"></tr>
              <tr mat-row  *matRowDef="let row; columns: fachbereichDisplayedColumns;"  ></tr>
            
            </table>
          </div>
          <div class="p-2 flex-fill" *ngIf="accountingListView == 'persons' ">
            <table mat-table [dataSource]="clinicDataSource" matSort class="mat-elevation-z0" style="width: 100%; ">

              
              <ng-container matColumnDef="userName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let element"> {{element.firstName}} {{element.lastName}}</td>
              </ng-container>
            
              <!-- <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nachname</th>
                <td mat-cell *matCellDef="let element"> {{element.lastName}}</td>
              </ng-container> -->
            
              
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email </th>
                <td mat-cell *matCellDef="let element">{{element.email}}</td>
              </ng-container>
              
              <!-- <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef >Role</th>
                <td mat-cell *matCellDef="let element">{{element.role}}</td>
              </ng-container> -->
              <ng-container matColumnDef="accounting_state">
                <th mat-header-cell *matHeaderCellDef >Status</th>
                <td mat-cell *matCellDef="let element">{{element.accounting_state}}</td>
              </ng-container>

              <ng-container matColumnDef="fachbereich">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fachbreiche</th>
                <td mat-cell *matCellDef="let element"> {{element.description}}</td>
              </ng-container>
              <ng-container matColumnDef="countDocs" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 90px;">Ärzte</th>
                <td mat-cell *matCellDef="let element" style="text-align: center" 
                [matTooltip]="getDocsToolTipUserFull(element)"
                matTooltipClass="multiline-tooltip"
                matTooltipPosition="above">{{element.docs.length}}</td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width: 45px; "></th>
                <td mat-cell *matCellDef="let element">
                  <!-- <mat-icon class="dxfx_icon_btn_mini"(click)="addAccountantForClinic(element)" >add </mat-icon> -->
                </td>
              </ng-container> 
              <tr mat-header-row *matHeaderRowDef="clinicDisplayedColumns"></tr>
              <tr mat-row class="element-row" *matRowDef="let row; columns: clinicDisplayedColumns;" (click)='openDetails(row)' ></tr>
            
            </table>
          </div>
          
          <!-- <div class="p-2 flex-fill" *ngIf="clinicDataSource">

            <table mat-table [dataSource]="clinicDataSource" matSort class="mat-elevation-z0" style="width: 100%; ">

              <ng-container matColumnDef="fachbereich">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>In Fachbereich</th>
                <td mat-cell *matCellDef="let element"> {{getFachbereichTitle(element)}}</td>
              </ng-container>
              <ng-container matColumnDef="userName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let element"> {{element.firstName}} {{element.lastName}}</td>
              </ng-container>
            
              <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nachname</th>
                <td mat-cell *matCellDef="let element"> {{element.lastName}}</td>
              </ng-container>
            
              
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email </th>
                <td mat-cell *matCellDef="let element">{{element.email}}</td>
              </ng-container>
              
              <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef >Role</th>
                <td mat-cell *matCellDef="let element">{{element.role}}</td>
              </ng-container>
              <ng-container matColumnDef="accounting_state">
                <th mat-header-cell *matHeaderCellDef >Status</th>
                <td mat-cell *matCellDef="let element">{{element.accounting_state}}</td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width: 45px; "></th>
                <td mat-cell *matCellDef="let element">
                  <mat-icon class="dxfx_icon_btn_mini"(click)="addAccountantForClinic(element)" >add </mat-icon>
                </td>
              </ng-container> 
              <tr mat-header-row *matHeaderRowDef="clinicDisplayedColumns"></tr>
              <tr mat-row class="element-row" *matRowDef="let row; columns: clinicDisplayedColumns;" (click)='openDetails(row)' ></tr>
            
            </table>
          
          </div> -->
         </div> 
      </div>

      <div class="p-0 " *ngIf="ui.isClinicUser === false  && ui.subscriptionPlanId > 1">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0" style="width: 100%; ">

          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vorname</th>
            <td mat-cell *matCellDef="let element"> {{element.firstName}}</td>
          </ng-container>
        
          <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nachname</th>
            <td mat-cell *matCellDef="let element"> {{element.lastName}}</td>
          </ng-container>
        
          
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email </th>
            <td mat-cell *matCellDef="let element">{{element.email}}</td>
          </ng-container>
          
          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef >Role</th>
            <td mat-cell *matCellDef="let element">{{element.role}}</td>
          </ng-container>
          <ng-container matColumnDef="accounting_state">
            <th mat-header-cell *matHeaderCellDef >Status</th>
            <td mat-cell *matCellDef="let element">{{element.accounting_state}}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row class="element-row" *matRowDef="let row; columns: displayedColumns;" (click)='openDetails(row)' ></tr>
        
        </table>
      </div>
    </div>
    
  </div>    
</div>