import  jsPDF  from "jspdf";
import autoTable from 'jspdf-autotable';
import { Fonts } from "../ui/pdf-dependencies/pdfFonts";;
import { SurgeryReportI } from '../core/models/surgeryReportI';
import { SurgeryHeaderI } from "../core/models/sectionM";
import { DATETOSTRING, DATE_TO_TIME_STRING, DURATION_BETWEEN_DATES_STRING } from "./helpers";
import { SurgerySupportPersonI } from "../core/models/v4/surgery.personi";

export class SurgeryReportPDF {

  static getSurgeryReportPDF(report:SurgeryReportI, header:SurgeryHeaderI,
    support:SurgerySupportPersonI[], isClinicUser: boolean = false):jsPDF {

    var lastCursor
    var lastY
    const addFooters = doc => {
      const pageCount = doc.internal.getNumberOfPages()

      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        if(i == pageCount){

          let currentY = 820;

          doc.setFont('Barlow-Regular')
          doc.setFontSize(10)
          doc.text(report.docName , 40, lastCursor.y +55, {align:'left'})

          if(report.facharzt_bezeichnung != null && report.facharzt_bezeichnung != ''){
            doc.setFont('Barlow-Light', 'normal')
            doc.setFontSize(8)
            doc.text(report.facharzt_bezeichnung, 40, lastCursor.y + 67 ,{align:'left'})
          }

        }
        doc.setFont('Barlow-Light', 'normal')
        doc.setFontSize(8)
        doc.text('Seite ' + String(i) + ' von ' + String(pageCount), 551, 820, {align: 'right'})
      }
    }

    var tableData = [];
    var tableDataOP = [];

    let item = [report.editorReport]
    tableData.push(item);

    // const head2 = [['Title', 'Name']]

    const doc = new jsPDF('p','pt');

    doc.addFileToVFS('Barlow-Light.ttf', Fonts.light());
    doc.addFont('Barlow-Light.ttf', 'Barlow-Light', 'normal');

    doc.addFileToVFS('Barlow-Regular.ttf', Fonts.regular());
    doc.addFont('Barlow-Regular.ttf', 'Barlow-Regular', 'normal');


    let _curY = report.isClinic ? 34 : 44;
    doc.setFont('Barlow-Light');
    doc.setFontSize(8)

    if (report.isClinic) {
      doc.setFont('Barlow-Light');
      // doc.setFontSize(report.isClinic ? 8 : 10)
      if(report.legalMain != null && report.legalMain != ""){
        doc.text(report.legalMain, 551, _curY, { align: "right" })
        _curY += 10;
      }

    }
    doc.setFont('Barlow-Regular');
    // doc.setFontSize(10)
    if(report.klinikName != null && report.klinikName != ""){
      doc.text(report.klinikName, 551, _curY, { align: "right" })
      _curY += 10;
    }

    doc.setFont('Barlow-Light');
    doc.setFontSize(8)


    if (report.isClinic) {

    } else {
      doc.setFont('Barlow-Light');

      // doc.text(report.docName , 551, _curY, {align:'right'})
      // _curY += 10;
      if(report.behandelnder_arzt != null && report.behandelnder_arzt != ""){
        // doc.text("beh. Arzt: " + invoiceI.address.behandelnder_arzt, 551, _curY, { align: "right" });
        doc.text(report.behandelnder_arzt, 551, _curY, { align: "right" });
        _curY += 10;
      }

      if(report.facharzt_bezeichnung != null && report.facharzt_bezeichnung != ''){
        doc.text(report.facharzt_bezeichnung, 551, _curY ,{align:'right'})
        _curY += 10;
      }

      
      if(report.arzt_role != null && report.arzt_role != ""){
        doc.text(report.arzt_role, 551, _curY, { align: "right" })
        _curY += 10;
      }
      
      if(report.abteilung != null && report.abteilung != ""){
        doc.text(report.abteilung, 551, _curY, { align: "right" });
        _curY += 10;
      }

    }

    doc.setFont('Barlow-Regular');
    if(report.address_1 != null && report.address_1 != ""){
      doc.text(report.address_1, 551, _curY, { align: "right" })
    }
    _curY += 10;

    if(report.address_2 != null && report.address_2 != "" ){
      doc.text(report.address_2, 551, _curY, { align: "right" });
    }

    if (report.isClinic) {
      _curY += 12;


      doc.setFont('Barlow-Light');
      doc.setFontSize(8)

      if(report.invoiceProviderFirst != null && report.invoiceProviderFirst != ""){
        doc.text(report.invoiceProviderFirst, 551, _curY, { align: "right" });
        _curY += 10;
      }
      if(report.invoiceProviderSecond != null && report.invoiceProviderSecond != ""){
        doc.text(report.invoiceProviderSecond, 551, _curY, { align: "right" });
        _curY += 10;
      }
      if(report.invoiceProviderThird != null && report.invoiceProviderThird != ""){
        doc.text(report.invoiceProviderThird, 551, _curY, { align: "right" });
        _curY += 10;
      }
      if(report.invoiceProviderFourth != null && report.invoiceProviderFourth != ""){
        doc.text(report.invoiceProviderFourth, 551, _curY, { align: "right" });
        _curY += 10;
      }

    }
    doc.setFont('Barlow-Light');
    var _patTitle = isClinicUser ? "Fallnummer: " : "Patient:"
    var __y = 135;
     doc
     .setFont('Barlow-Regular')
     .setFontSize(16)
     .setDrawColor("#444444")
     .text("OP-Bericht", 40, 135);
     __y += 30;

    doc
      .setFontSize(10)
      .text(_patTitle, 40, __y)
      .setFont('Barlow-Light')
      .text(report.patient_name, 200, __y);
     __y += 18;

    doc
    .setFont('Barlow-Regular').text('OP-Datum:', 40, __y)
    .setFont('Barlow-Light')
    .text(DATETOSTRING(new Date(header.surgeryDate)) , 200, __y);
    __y += 18;


    doc.setFont('Barlow-Regular').text('Uhrzeit Beginn:', 40, __y)
    .setFont('Barlow-Light')
    .text(DATE_TO_TIME_STRING(new Date(header.timeStart)) , 200, __y);
    __y += 18;

    doc.setFont('Barlow-Regular').text('Uhrzeit Ende:', 40, __y)
    .setFont('Barlow-Light')
    .text(DATE_TO_TIME_STRING(new Date(header.timeEnd)) , 200, __y);
    __y += 18;

    doc.setFont('Barlow-Regular').text('Dauer:', 40, __y)
    .setFont('Barlow-Light')
    .text(DURATION_BETWEEN_DATES_STRING(new Date(header.timeStart), new Date(header.timeEnd)) , 200, __y);
    __y += 18;

    doc.setDrawColor(180,180,180)
    .setLineWidth(0.5)
    .line(40, __y, 550, __y);

    __y += 18;

     for(var i = 0; i < support.length; i++) {
      doc
      .setFont('Barlow-Regular')
      .setFontSize(10)
      .text(support[i].role_title + ":", 40, __y)
      .setFont('Barlow-Light')
      .text(support[i].title + ' ' + support[i].firstName + ' ' + support[i].lastName, 200, __y);
      __y += 16;
    }
     __y -= 4;
    doc.setDrawColor(180,180,180)
    .setLineWidth(0.5)
    .line(40, __y, 550, __y);
    __y += 16;

    if(header){

      // if(header.lokalisation != null && header.lokalisation != ""){
      //  doc
      //  .setFont('Barlow-Regular').setFontSize(10)
      //  .text("Lokalisation:", 40, __y).setFont('Barlow-Light')
      //  .text(header.lokalisation, 200, __y);
      //  __y += 16;
      // }

      if(header.lagerung != null && header.lagerung != ""){
       doc
       .setFont('Barlow-Regular').setFontSize(10)
       .text("Lagerung:", 40, __y).setFont('Barlow-Light')
       .text(header.lagerung, 200, __y);
       __y += 16;
      }

      if(header.blutsperre != null && header.blutsperre != ""){
       doc
       .setFont('Barlow-Regular').setFontSize(10)
       .text("Blutsperre in Minuten:", 40, __y).setFont('Barlow-Light')
       .text(header.blutsperre, 200, __y);
       __y += 16;
      }

      if(header.antibiose != null && header.antibiose != ""){
       doc
       .setFont('Barlow-Regular').setFontSize(10)
       .text("Periop. Antibiose:", 40, __y).setFont('Barlow-Light')
       .text(header.antibiose, 200, __y);
       __y += 16;
      }
      if(header.lokalisation != null && header.lokalisation != ""){
        doc
        .setFont('Barlow-Regular').setFontSize(10)
        .text("Lokalisation:", 40, __y).setFont('Barlow-Light')
        .text(header.lokalisation, 200, __y);
        __y += 16;
       }
    }

     __y -= 14;


     autoTable(doc, {
      margin: {top:0,right:35,left:35},
      theme: 'plain',
      // styles:  { font: "Barlow-Light", fontStyle: 'normal',fillColor:[255,255,255] },
      columnStyles: { 0: { font:'Barlow-Regular' , fontStyle: 'normal', cellWidth: 160 }, 1: { font:'Barlow-Light', fontStyle: 'normal' } },
      // columnStyles: { 5: { halign: 'right' }, 2:{halign:'center' } , },
      startY: __y ,

      body:  [
        ['Diagnose: ', report.diagnose],
        ['Therapiemethode: ', report.therapiemethode],
      ],

      didDrawPage: (data) => {
        lastCursor = data.cursor
        //console.log('data ' ,data.cursor)
     },


    });


    let _newY = lastCursor.y
    // _newY +=4;
    doc.setDrawColor(180,180,180);
    doc.setLineWidth(0.5);
    doc.line(40, _newY, 550, _newY);
    _newY +=16;
    doc
      .setFont('Barlow-Regular')
      .setFontSize(10)
      .text("OP-DURCHFǓHRUNG:", 40, _newY);
      _newY +=4;


      autoTable(doc, {
      margin: {top:15,right:35,left:35},
      theme: 'plain',
      styles:  { font: "Barlow-Light", fontStyle: 'normal',fillColor:[255,255,255] },
      startY: _newY,
      body: tableData,

      didDrawPage: (data) => {
         lastCursor = data.cursor
     },

      didDrawCell: (data) => {
       data.cell.styles.fillColor = [255,255,255],
       data.cell.styles.lineColor = [255,255,255]
      },
    });


    addFooters(doc)

    return doc;
  }


}
