<!-- (click)='selectGoae()' -->
  <div class="col-auto p_t10 p0 pr0 mr0 ">
    <div class="d-flex align-items-center add-button pr0" >
      <div class="p_2 align-text-end pl12 pr0 mr0">
        <mat-icon class="nav-icon-mini pr0 mr0">add</mat-icon>
      </div>
      <div class="p-2 btn-copy align-text-start  p_t5 p_b5 pl0 ml0 pr16">GOÄ Ziffer</div>
    </div>
  </div>

