<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>

</mat-toolbar>

<div class="container" style="padding:32px!important; height: calc(100% - 88px);">

  <div *ngIf="!isLoading && !showOpenMessage">

    <div class="row justify-content-center p_t20">
      <div class="col-10 tlc">
        <p style="font-weight: 600;">Vorlage übernehmen?</p>
      </div>
      <div class="col-10 tlc">
        Die doxfox Vorlage wird in “Meine Leistungen” kopiert und kann dort als eigene Vorlage verwendet werden.
      </div>
    </div>
    <div class="row justify-content-center p_t30">
      <div class="col-auto">
        <img src="assets/templateToProcessCopyExample.png" alt="">
      </div>
    </div>
    <div class="row justify-content-center p_t30 p_b15">
      <div class="col-auto">
        <mat-checkbox (change)='checkboxChanged($event)' [(ngModel)]="cpaChecker" ><span style="color: #ABBBC7;">Diese Meldung künftig nicht mehr anzeigen</span></mat-checkbox>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column align-items-center justify-content-center h100" *ngIf="isLoading || showOpenMessage">
    <div class="p-2 pb0 p_t0"  *ngIf="!isLoading && showOpenMessage">
      <p style="text-align:center; font-size:14px; font-weight:300; color: #1B242B; line-height: 20px;" [innerHtml]='bodyMessage'></p>
    </div>
    <div class="p-2 pb0 p_t0" *ngIf="isLoading && !showOpenMessage">
      <p style="text-align:center; font-size:32px; font-weight:400; color: #1B242B; line-height: 40px;" [innerHtml]='loadingMessage'></p>
    </div>
    <div class="p-2" *ngIf="isLoading &&!showOpenMessage">
      <div class="loader-box" >
        <div class="loader">......</div>
      </div>
    </div>
  </div>
  
    
  
</div>
<!-- <div class="loadingAnimation" #loaderBar></div> -->
<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  
  <app-df-icon-button  *ngIf="!isLoading && !showOpenMessage" title="Abbrechen" icon="close" (clicked)="close()"></app-df-icon-button>  
  
  <app-df-icon-button  *ngIf="!isLoading && !showOpenMessage" class="ml5" title="Fortfahren" icon="check"color="primary" (clicked)="runProcess()"></app-df-icon-button>  

  <app-df-icon-button  *ngIf="!isLoading && showOpenMessage" title="Jetzt nicht" icon="close" (clicked)="close()"></app-df-icon-button>  
  
  <app-df-icon-button  *ngIf="!isLoading && showOpenMessage && _resultTemplateId && _resultPath" class="ml5" title="Öffnen" icon="check"color="primary" (clicked)="openTemplate()"></app-df-icon-button>  
</mat-toolbar>