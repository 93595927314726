import { Component, OnInit } from '@angular/core';
// import { PositionM } from 'src/app/core/models/sectionPositionM';

@Component({
  selector: 'goae-inline-button',
  templateUrl: './add-goae-inline-button.component.html',
  styleUrls: ['./add-goae-inline-button.component.scss']
})
export class AddGoaeInlineButtonComponent implements OnInit {
  // @Input() position:PositionM
  constructor() { }

  ngOnInit(): void {
  }
  selectGoae(){

  }
}
