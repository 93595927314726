<mat-toolbar class="overlay-toolbar">
  {{textEdit.title}}
  <span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_mini" (click)='close()'>close</mat-icon>
</mat-toolbar>
<div class="container-fluid" style="height: calc(100% - 88px);">

  <div class="d-flex justify-content-between flex-column h100" >
    <div class="p-2 " *ngIf='textEdit.singleline' >
      <input class="formControl mat-body formControlDescription fs14-400"
      #singleLine [class.largeFont]="showLargeFontSize" [(ngModel)]="textModel"
      (keydown)="onKeyDown2($event)"
      (keydown.enter)="save()">
    </div>
    <div class="p-0 p_t5 p_b5 flex-fill" *ngIf='!textEdit.singleline' >
      <textarea style="width:100%; height: 100%; background-color:rgba(0,0,0,0);   resize: vertical !important;"

      maxlength="300" [(ngModel)]="textModel" rows="2" class="mat-body formControl fs14-400" [class.largeFont]="showLargeFontSize"  #multiLine
      (blur)="onBlur()"

      >
      </textarea>

    </div>
  </div>
</div>

<mat-toolbar class="overlay-toolbar">
  <div class="d-flex w100">
    <div class="p-2 ml-auto">
      <app-df-icon-button
      title= "Speichern"
      icon="save"
      color="primary"
      (clicked)="save()"
      >
      </app-df-icon-button>
    </div>
  </div>

</mat-toolbar>
