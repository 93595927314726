import { environment } from "src/environments/environment";

export enum API_CALL {
  GET_AREAS_OF_EXPERTISE = 'GET_AREAS_OF_EXPERTISE',
  GET_AREAS_OF_EXPERTISE_ADMIN = 'GET_AREAS_OF_EXPERTISE_ADMIN',
  GET_AREAS_OF_EXPERTISE_BY_UID = 'GET_AREAS_OF_EXPERTISE_BY_UID',
  GET_PAD_NEXT_FACH_ALL = 'GET_PAD_NEXT_FACH_ALL',
  POST_PAD_NEXT_FACH_FOR_UID = 'POST_PAD_NEXT_FACH_FOR_UID',
  POST_AREAS_OF_EXPERTISE = 'POST_AREAS_OF_EXPERTISE',
  POST_AREAS_OF_EXPERTISE_UPDATE = 'POST_AREAS_OF_EXPERTISE_UPDATE',
  GET_TREATMENT_REGIONS = 'GET_TREATMENT_REGIONS',
  GET_TREATMENT_REGIONS_BY_EXPERTISE = 'GET_TREATMENT_REGIONS_BY_EXPERTISE',
  POST_TREATMENT_FILTER = 'POST_TREATMENT_FILTER',
  DELETE_AREAS_OF_EXPERTISE_BY_UID = 'DELETE_AREAS_OF_EXPERTISE_BY_UID',
  POST_AREAS_OF_EXPERTISE_BY_UID = 'POST_AREAS_OF_EXPERTISE_BY_UID',
  TOGGLE_AREAS_OF_EXPERTISE_BY_ID = 'TOGGLE_AREAS_OF_EXPERTISE_BY_ID',
  TOGGLE_PRIMARY_AREA_OF_EXPERTISE_BY_ID = 'TOGGLE_PRIMARY_AREA_OF_EXPERTISE_BY_ID',
  GET_TREATMENT_TYPE_FOR_USER = 'GET_TREATMENT_TYPE_FOR_USER',
  GET_TREATMENT_FILTERS_FOR_USER = 'GET_TREATMENT_FILTERS_FOR_USER',
  SET_BASE_ADDRESS = 'SET_BASE_ADDRESS',
  GET_BILLING_COMPANIES_BY_UID = 'GET_BILLING_COMPANIES_BY_UID',
  GET_SINGLE_PATIENT_FULL = 'GET_SINGLE_PATIENT_FULL',
  XML_TEST = 'XML_TEST',
  PAD_EXPORT = 'PAD_EXPORT',
  

  GET_ACTIVE_TREATMENT_FILTER_IDS = 'GET_ACTIVE_TREATMENT_FILTER_IDS',
  POST_ACTIVE_TREATMENT_FILTER_IDS = 'POST_ACTIVE_TREATMENT_FILTER_IDS',

  POST_PROCESS_ACCESS_ALL = "POST_PROCESS_ACCESS_ALL",
  
  
  TEMPLATE_POOL_CREATE = "TEMPLATE_POOL_CREATE",
  UPDATE_TEMPLATE_POOL = "UPDATE_TEMPLATE_POOL",
  GET_CLINIC_SECTORS = "GET_CLINIC_SECTORS",

  
}



export function apiRequest(call:API_CALL, params:string = '') {
  switch(call){
    case API_CALL.GET_PAD_NEXT_FACH_ALL:
      return `${environment.endpoints.apiBasePathV2}ui/pad-next-fach`;
    case API_CALL.POST_PAD_NEXT_FACH_FOR_UID:
      return `${environment.endpoints.apiBasePathV2}ui/pad-next-fach`;

    case API_CALL.GET_AREAS_OF_EXPERTISE:
      return `${environment.endpoints.apiBasePathV2}ui/expertise-areas`;

    case API_CALL.GET_AREAS_OF_EXPERTISE_ADMIN:
      return `${environment.endpoints.apiBasePathV2}ui/expertise-areas-admin`;

    case API_CALL.GET_AREAS_OF_EXPERTISE_BY_UID:
      return `${environment.endpoints.apiBasePathV2}ui/expertise-areas-uid/${params}`;

    case API_CALL.GET_TREATMENT_REGIONS:
      return `${environment.endpoints.apiBasePathV2}ui/treatment_regions`;

    case API_CALL.GET_TREATMENT_REGIONS_BY_EXPERTISE:
      return `${environment.endpoints.apiBasePathV2}ui/treatment_regions-by-expertise/${params}`;

    case API_CALL.POST_AREAS_OF_EXPERTISE:
      return `${environment.endpoints.apiBasePathV2}ui/expertise-area-add`;

    case API_CALL.POST_AREAS_OF_EXPERTISE_UPDATE:
      return `${environment.endpoints.apiBasePathV2}ui/expertise-area-edit/${params}`;

    case API_CALL.GET_TREATMENT_FILTERS_FOR_USER:
      return `${environment.endpoints.apiBasePathV2}ui/treatment-filter-all-user/`;
    case API_CALL.POST_TREATMENT_FILTER:
      return `${environment.endpoints.apiBasePathV2}ui/treatment_filter-add/${params}`;

    case API_CALL.DELETE_AREAS_OF_EXPERTISE_BY_UID:
      return `${environment.endpoints.apiBasePathV2}ui/remove-user-expertise-area-id/${params}`;

    case API_CALL.POST_AREAS_OF_EXPERTISE_BY_UID:
      return `${environment.endpoints.apiBasePathV2}ui/add-user-expertise-area-uid/${params}`;

    case API_CALL.TOGGLE_AREAS_OF_EXPERTISE_BY_ID:
      return `${environment.endpoints.apiBasePathV2}ui/activate-user-expertise-area-id/${params}`;
    case API_CALL.TOGGLE_PRIMARY_AREA_OF_EXPERTISE_BY_ID:
      return `${environment.endpoints.apiBasePathV2}ui/toggle-user-expertise-area-uid-primary/${params}`;

    case API_CALL.GET_TREATMENT_TYPE_FOR_USER:
      return `${environment.endpoints.apiBasePathV2}ui/treatment_types`;

    case API_CALL.SET_BASE_ADDRESS:
      return `${environment.endpoints.apiBasePathV2}accounts/address-toggle-base/${params}`;

    case API_CALL.GET_BILLING_COMPANIES_BY_UID:
      return `${environment.endpoints.apiBasePathV2}invoice/billing-companies`;

    case API_CALL.GET_SINGLE_PATIENT_FULL:
      return `${environment.endpoints.apiBasePathV2}patients/single/${params}`;


      

    case API_CALL.XML_TEST:
      return `${environment.endpoints.apiBasePathV2}invoice/xml-test/`;
    case API_CALL.PAD_EXPORT:
      return `${environment.endpoints.apiBasePathV2}invoice/pad-export/`;


      case API_CALL.GET_ACTIVE_TREATMENT_FILTER_IDS:
        return `${environment.endpoints.apiBasePathV2}ui/treatment-filter-active/`;

        case API_CALL.POST_ACTIVE_TREATMENT_FILTER_IDS:
          return `${environment.endpoints.apiBasePathV2}ui/treatment-filter-active/`;
       
       
          case API_CALL.POST_PROCESS_ACCESS_ALL:
          return `${environment.endpoints.apiBasePathV2}processes/access/`;


    case API_CALL.TEMPLATE_POOL_CREATE: return `${environment.endpoints.apiBasePathV2}templates/pool/create`;
    case API_CALL.UPDATE_TEMPLATE_POOL: return `${environment.endpoints.apiBasePathV2}templates/pool/update/${params}`;

    case API_CALL.GET_CLINIC_SECTORS:
      return `${environment.endpoints.apiBasePathV2}accounts/clinic/clinic-sectors/`;

  }
}
