import { EventEmitter, Injectable } from '@angular/core';
import { Socket, io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SocketService {


  inviteEmitter: EventEmitter<ISocketOpenInviteMessage> = new EventEmitter<ISocketOpenInviteMessage>();
  socketMessageEmitter: EventEmitter<string> = new EventEmitter();
  socketProcessStateEmitter: EventEmitter<IOProcessStateMessage> = new EventEmitter();
  socketAuthStateEmitter: EventEmitter<string> = new EventEmitter();
  socketAuthDemoExpiredEmitter: EventEmitter<string> = new EventEmitter();
  socketProcessAddedEmitter: EventEmitter<number> = new EventEmitter();

  private socket: Socket;

  constructor() { 
    console.log("SocketService construct");
  }

  initSocket(token: any) {
    console.log("\n\n\n>>>>initSocket > token")
    this.socket = io(environment.SOCKET_ENDPOINT, {
      query: {token},
    
    });
    this.socket.on('connect', () => {
      console.log("soccet connected",   this.socket.id);
    });
    this.socket.on('disconnect', () => {
      console.log("soccet disconnect");
    });

    this.socket.on('process_added', msg => {
      // this.socketProcessStateEmitter.emit(msg)
      this.socketProcessAddedEmitter.emit(msg)
    })
    this.socket.on('process_state', msg => {
      this.socketProcessStateEmitter.emit(msg)
    })
    
    this.socket.on(`auth_signin`, msg => {
      console.log("\nSocketService  auth signin\n", msg)
      this.socketAuthStateEmitter.emit(msg)
   })
    this.socket.on(`demo_expired`, msg => {
      console.log("\nSocketService  auth demo_expired\n", msg)
      this.socketAuthDemoExpiredEmitter.emit(msg)
   })
    this.socket.on(`ACCOUNTING_INVITE`, msg => {
      console.log("\ACCOUNTING_INVITE ", msg)
      this.inviteEmitter.emit(msg as ISocketOpenInviteMessage)
   })
    // this.socket.of().on('auth signin', msg => {
    //   console.log("auth signin", msg)
    //   this.socketAuthStateEmitter.emit(msg)
    // })
    this.socket.on('_template_id: process state', msg => {
      console.log("process-state", msg)
    })
    // this.socket.on('templates_load', msg => {
    //   console.log("templates_load", msg)
    // })
  }

  changeProcessState(uid:string) {
    if(!this.socket) {return}
    this.socket.emit('auth_signin', uid, uid)
  }
  uidEmit(uid:string) {
    if(!this.socket) {return}
    // console.log("\n\n\n>>>>uidEmit > uid")
    this.socket.emit('create', uid)
  }
  signOut() {
    if(!this.socket) {return}
    // console.log("\n\n\n>>>>uidEmit > uid")
    // this.socket.emit('sign_out', uid)
    this.socket.disconnect()
  }
  subscribeAccountingChannel(uid:string) {
    if(!this.socket) {return}
    // console.log("\n\n\n>>>>uidEmit > uid")
    this.socket.emit('create', uid)
  }
}

export interface IOProcessStateMessage {
  uid: string
  template_id: number
  state_id: number
}
export interface IOAuthStateMessage {
  uid: string
  ip2?: string
  ipAddress?: string
}
export interface ISocketOpenInviteMessage {
  accounting_user_pool_id: number
  firstName?: string
  lastName?: string
  email?: string
}