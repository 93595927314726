<mat-toolbar class="overlay-toolbar">
  Vorlage teilen?
  <span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_mini" (click)='close()'>close</mat-icon>
</mat-toolbar>

<div class="container" style="height: calc(100% - 88px); width: 450px; overflow: hidden;">

  <div class="d-flex align-items-center justify-content-center" style="height: 100%;" *ngIf="viewState != VIEWSTATES.NONE">
    <div class="p-2">
      <mat-spinner diameter='25' color='primary' *ngIf="viewState == VIEWSTATES.LOADING"></mat-spinner>

      <div *ngIf="viewState == VIEWSTATES.ERROR" class="alert alert-danger" role="alert">
        {{error}}
      </div>

      <!-- <p *ngIf="viewState == VIEWSTATES.ERROR">{{error}}</p> -->
      <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" *ngIf="viewState == VIEWSTATES.SUCCESS">
        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
      </svg>
    </div>
  </div>

  <div class="d-flex flex-column" style="height: 100%;" *ngIf="viewState == VIEWSTATES.NONE">
      <div class="p-2">
        <mat-label class='mat-caption'>Email</mat-label>
        <input class="formControl mat-body" [(ngModel)]="email" type="email" id="inviteEmail" #_inviteEmailTF>
      </div>

      <div class="p-2 flex-fill">
        <mat-label class='mat-caption'>Nachricht</mat-label>
        <textarea class="mat-body formControl" rows='4'
        [(ngModel)]="message" ></textarea>
      </div>
  
  </div>
  </div>
  
<mat-toolbar class="overlay-toolbar">
  <div class="d-flex w100 justify-content-center" *ngIf="viewState == VIEWSTATES.NONE">
    <div class="p-2 ">
      <app-df-icon-button title= "Abbrechen" icon="close" (clicked)="close()"></app-df-icon-button>  
    </div>
    <div class="p-2 ">
      <app-df-icon-button title= "Vorlage teilen" icon="save" color="primary" [disabled]='email == "" ' (clicked)="save()"></app-df-icon-button>  
    </div>
  </div>

  <div class="d-flex w100 justify-content-center" *ngIf="viewState == VIEWSTATES.ERROR">
    <div class="p-2 ">
      <app-df-icon-button title="Erneut versuchen" icon="refresh" (clicked)="inviteWithOtherEmail()"></app-df-icon-button>
    </div>
    <div class="p-2 ">
      <app-df-icon-button title="Abbrechen" icon="close" (clicked)="close()"></app-df-icon-button>
    </div>
  </div>
</mat-toolbar>

