import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-process-clinic-number',
  // templateUrl: './process-clinic-number.component.html',
  // styleUrls: ['./process-clinic-number.component.scss']
  template: `
  <mat-card class="mat-card-no-shadow pr0 pt0 pb0 pl0" style="height:100%!important;">
    <div class="container-fluid p_b5 pt0 pr5">

      <div class="d-flex h40">
        <div class="p-2 pb0">
          <span class="mat-caption m0">Fallnummer</span>
        </div>
      </div>


      <div class="row " >
        <div class="col-12 pt0  p_t0">

          <div class="d-flex p-2  p_b0  p_t0">
            <span class="patient-title p_b0 p_t0">{{fallnummer}}</span>
          </div>

        </div>

      </div>

    </div>
  </mat-card>
  `
})
export class ProcessClinicNumberComponent {

  constructor() { }

  @Input() fallnummer?: string



}
