<div class="p-0">
  <div class="d-flex flex-column">
    <div class="p-0" style="height: 50px;">
      <div class="d-flex">
        <div class="p-2 flex-fill">
          <input class="formControl mat-body" placeholder="Meine Textbausteine durchsuchen..." (keyup)="applyFilter($event.target.value)" >  
        </div>
        <div class="p-2 ml-auto">
          <div class="d-flex align-items-center add-button pr0" (click)='add()'>
            <div class="p_2 align-text-end pl12 pr0 mr0">
              <mat-icon class="nav-icon-mini pr0 mr0">add</mat-icon>
            </div>
            <div class="p-2 btn-copy align-text-start  p_t5 p_b5 pl0 ml0 pr16">Textbaustein anlegen</div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-0 flex-fill" style="overflow-y: scroll; " [ngStyle]='{"height":getPanelHeight}'>

      <div style="padding: 0px!important; margin: 0px!important;" >
        <div class="row" style="padding: 0px!important; margin: 0px!important;" >
      
          <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0" style="width: 100%; ">

            <ng-container matColumnDef="short_description">
              <th mat-header-cell *matHeaderCellDef mat-sort-header ><span class="pl30">Beschreibung</span></th>
              <td mat-cell *matCellDef="let element" class="pl40"><span class="pl30">{{element.short_description}}</span></td>
            </ng-container>
            
            
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row class="element-row" *matRowDef="let row; columns: displayedColumns;" (click)='openDetails(row)' ></tr>
          
          </table>
      
       </div>
      
      </div>
    </div>
  </div>
</div>