import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { Geschlecht, PatientFull } from "src/app/core/models/patient";

@Component({
  selector: 'patient-gender-selection',
  template: ` <mat-label class='mat-caption'>Geschlecht</mat-label>
  <mat-form-field  appearance="outline"  
  class="textfield100 select-form-field " style="margin-top: -5px!important"
  [class.errorSelect]="!patient.geschlecht "
  >
    <mat-select [(value)]="patient.geschlecht" (selectionChange)='textChanged()'>
      <mat-option *ngFor="let g of genders" [value]="g">{{g}}</mat-option>
    </mat-select>
  </mat-form-field>
  `,
})
export class PatientGenderSelectComponent implements OnInit {
  genders = [Geschlecht.m, Geschlecht.w, Geschlecht.u]
  @Input() patient!: PatientFull;
  @Output() onChanged = new EventEmitter<void>();
  ngOnInit(): void {

  }
  textChanged() {
    this.onChanged.emit()
  }
}