import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PdfPreviewI } from 'src/app/core/models/pdfPreviewI';
import { InvoiceModelI } from 'src/app/core/models/v4/invoice/invoiceModel';
import { AccountService } from 'src/app/services/account.service';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { PdfPreviewComponent } from 'src/app/ui/overlays/pdf-preview/pdf-preview.component';

@Component({
  selector: 'app-process-hostorie',
  templateUrl: './process-hostorie.component.html',
  styleUrls: ['./process-hostorie.component.scss']
})
export class ProcessHostorieComponent implements OnInit {

  invoiceList:InvoiceModelI[] = []
  dataSource:any
  displayedColumns = ['rechnungNr','dateCreated','sum'];
  constructor(public tc:TemplateControllerService, private aS:AccountService, private dialog:MatDialog) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.load()
    },1000)
  }

  private async load(){
    const  _r = await this.aS.invoicesByProcessId(this.tc.currentTemplateId)
    this.invoiceList = _r
    this.dataSource = new MatTableDataSource(this.invoiceList);
  }
  openDetails(e){
    const _data: PdfPreviewI = {
       template: this.tc.activeTemplate,
      invoice_id:e.id,
      type: PdfPreviewI.Type.SHOWINVOICE
    };
    this.dialog.open(PdfPreviewComponent, {
      disableClose: true,
      width: '820px',
      height: '92%',
      backdropClass: 'backdrop-background',
      data: _data
    }).afterClosed().subscribe(
      _print => {
        /* if(_print != null){
          _print.save("rechnung-"+ this.template.name +".pdf");
        } */
      }
    );
  }

}
