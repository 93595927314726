import { Component, Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import {  SurgeryReportItenV4I } from 'src/app/core/models/v3/SurgeryReportItemIV3';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import * as _ from 'lodash';
import { ApiUtilService } from 'src/app/services/api-util.service';
import { LocaleService } from 'src/app/services/locale.service';
import { TEXT_FOCUS_ELEMENT } from 'src/app/core/models/final/textoverlay.model';

@Component({
  selector: 'app-position-surgery-reports',
  templateUrl: './position-surgery-reports.component.html',
  styleUrls: ['./position-surgery-reports.component.scss']
})

export class PositionSurgeryReportsComponent implements OnInit {
  @ViewChild('_textArea',{static: false}) _textArea: ElementRef;
  @ViewChild('_titleTextField',{static: false}) _titleTextField: ElementRef;
  @Input() reportItems: SurgeryReportItenV4I[];

  @Input() editable: boolean;
  @Output() tapped: EventEmitter<TEXT_FOCUS_ELEMENT> = new EventEmitter<TEXT_FOCUS_ELEMENT>();
 
  @Input() position_id: number;
  isTitleEditMode = false
  @Input() activeIndex:number = 0
  isLoading = false;
  isSaving = false;
  selectedReportItem

  hasReportCopyAreaChanged = false;
  hasReportDescriptionInputChanged = false;
  FOCUS_ELMENTS = TEXT_FOCUS_ELEMENT
  constructor(
    public locales: LocaleService,
    private apiUtil: ApiUtilService,
    public tc: TemplateControllerService) {
     }

  ngOnInit(): void {

  
  }


  openOverlay(e:TEXT_FOCUS_ELEMENT) {
    this.tapped.emit(e)
  }

  setTitleEditMode() {
    this.tapped.emit()
  }


  get getReportionCopyDescription():string {
    if(this.reportItems[this.activeIndex] != null && this.reportItems[this.activeIndex].description != null){
      return this.reportItems[this.activeIndex].description
    }
    return ''
  }
  set getReportionCopyDescription(value:string) {
    if(this.reportItems[this.activeIndex] != null && this.reportItems[this.activeIndex].description != null){
     this.reportItems[this.activeIndex].description = value
    }
  }
  get getReportionCopy():string {
    if(this.reportItems[this.activeIndex] != null && this.reportItems[this.activeIndex].text != null){
      return this.reportItems[this.activeIndex].text
    }
    return ''
  }
  set getReportionCopy(val) {
    if(this.reportItems[this.activeIndex] != null && this.reportItems[this.activeIndex].text != null){
      this.reportItems[this.activeIndex].text = val
    }
  }

}

@Directive({
  selector: 'textarea[resize]'
})
export class ResizableTextAreaDirective {
  @Output() resize = new EventEmitter();

  width: number;
  height: number;

  mouseMoveListener: Function;

  @HostListener('mousedown', ['$event.target'])
  onMouseDown(el) {
    this.width = el.offsetWidth;
    this.height = el.offsetHeight;
    this.mouseMoveListener = this.renderer.listen('document', 'mousemove', () => {
      if (this.width !== el.offsetWidth || this.height !== el.offsetHeight) {
        this.resize.emit({ width: el.offsetWidth, height: el.offsetHeight });
      }
    });
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.ngOnDestroy();
  }

  constructor(private renderer: Renderer2) {}

  ngOnDestroy() {
    if (this.mouseMoveListener) {
      this.mouseMoveListener();
    }
  }
}
