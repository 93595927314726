import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocaleService } from 'src/app/services/locale.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  timerStartet = false
  constructor(
    private activatedRoute:ActivatedRoute,
    
    private ui:UiService,
    public locales:LocaleService,
    
    private router:Router
    ) { }
  

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(
      _params => {
        if(_params != null && _params.get('ll') != null){
          this.routeTo(_params.get('ll'))
        }
      }
    );
  }


  private routeTo(path: string) {
    if(this.timerStartet) {
      return
    }



    this.timerStartet = true
    console.log(path)

    setTimeout(() => {
      this.router.navigateByUrl(path)
    },5000)
  }
  
}
