import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { PatientFull } from "src/app/core/models/patient";

@Component({
  selector: 'patient-salutation-selection',
  template: `
      <mat-label class='mat-caption'>Anrede</mat-label>
        <mat-form-field appearance="outline" 
        class="textfield100 select-form-field" style="margin-top: -5px!important; " 
   
        [class.errorSelect]="!patient.anrede || patient.anrede == '' "
        >
          <mat-select [(value)]="patient.anrede" (selectionChange)='textChanged()'>
            <mat-option value="Ohne Anrede">Ohne Anrede</mat-option>
            <mat-option value="Frau">Frau</mat-option>
            <mat-option value="Herr">Herr</mat-option>
            <mat-option value="Herr / Frau">Herr / Frau</mat-option>
          </mat-select>
          
        </mat-form-field>
  `,
})
export class PatientSalutationSelectComponent implements OnInit {
  @Input() patient!: PatientFull;
  @Output() onChanged = new EventEmitter<void>();
  ngOnInit(): void {

  }
  textChanged() {
    this.onChanged.emit()
  }
}