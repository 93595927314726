<mat-card class="mat-card-no-shadow p0" style="height:100%!important;">
  <div class="container " >
    <div class="d-flex flex-column p_t10 p_b10">
      <div class="p-0">
        <span class="mat-caption">{{title}}</span>
      </div>
      <div class="p-0">
        <mat-form-field appearance="outline" class="textfield100 select-form-field mt0 mb0" >
          <mat-select [value]="currentFilter" (selectionChange)='treatmentFilterSelect($event)' [disabled]='!tc.isTemplateEditable'>
            <mat-option *ngFor="let treatmentFilter of treatmentFilters" [value]="treatmentFilter">
              {{treatmentFilter.filter}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-card>
