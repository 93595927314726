<div  #_panel>
  <!-- expanded="false" -->
  <!-- class="out"
  #_panelItem -->
  <div  #_exp_panel class="filterPanel">

  <mat-expansion-panel style="width: 100%;" hideToggle="true" 
 
  (afterCollapse)="onAfterCollapse()"
  
  (afterExpand)="onAferExpanded()">  
    <mat-expansion-panel-header #panel   (click)="toggleOpenClose(_panel)" >
    
    <div class="container-fluid " >
      <div class="row d-flex align-items-center flex-nowrap">
        <div class="p-2 pr16">
          <img src="/assets/drag_handle-24px.svg" height="24px"></div>
          <div class="p-2 pr16">
          <div class="circleOpenClose" (click)='toggleOpenClose(_panel)' 
              [class.circleOpenClose_active]='isItemOpen'>
            <div class="horiz"></div>
            <div class="vertl"></div>
          </div>
        </div>
        
        <div class="p-2 flex-fill">
            <!-- <div class="mat-body big" >{{section.order_index}} {{section.name}}</div>  -->
            <div class="mat-body big" [innerText]="section.name" (dblclick)="editTitle()"></div> 
        </div> 
      
        <div class="p-2 ml-auto " style="min-width: 215px;" >

          <div class="d-flex align-items-center justify-content-end" style="height: 100%; padding-top: 2px;">
            <div class="p-0">
              
              <div class="d-flex flex-column">
                  <div class="p-0">
                    <span style="color: #1B242B; font-size: 10px;" *ngIf="tc.isTemplateEditable || section?.treated_at_difference_days !=0">Behandlungsdatum</span>
              
                  </div>
                  <div class="p-0" style="color: #1B242B; font-size: 14px; ">
                    <div class="d-flex"  style="min-width: 110px;">
                      <div class="p-0">
                        <span>{{getSectionDiffDaysText(section)}}</span>
                      </div>
                      <div class="p-0 ml-auto"  *ngIf="tc.isTemplateEditable && !tc.templateHasInvoice">
                        <mat-icon class="dxfx_icon_btn_mini" (click)="sectionDayAdd()">add</mat-icon>
                      </div>
                      <div class="p-0"  *ngIf="tc.isTemplateEditable  && !tc.templateHasInvoice">
                        <mat-icon class="dxfx_icon_btn_mini" (click)="sectionDaySub()">remove</mat-icon>
                      </div>
                      
                    </div>
                  </div>
              </div>
            </div>
          </div>

        </div> 

        <div class="p-2 ml-auto">
          <div class="summery mat-body">{{summeryChanged()  | cgcurrency}}</div>
        </div>
        
        <div class="p-2">
          <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="tc.canRenameTemplate && tc.isTemplateEditable">
            <mat-icon style="color:#7C8B96!important">more_horiz</mat-icon>
          </button>

          <mat-menu #menu="matMenu" xPosition="before" >
            <button mat-menu-item (click)='editTitle()'>
              <span>Umbenennen</span>
            </button>
            <button mat-menu-item (click)="duplicateSection();">
              <span>Duplizieren</span>
            </button>
            <button mat-menu-item (click)="deleteSection();">
              <span>Löschen</span>
           </button>
          </mat-menu>
        </div>
      </div>
    </div>

  </mat-expansion-panel-header>

  
  <!-- [customProgressFactors]='customProgressFactors' -->
  <template-position-item *ngIf="!isLoading"

  [section]='section' 
  (deletePositonAtIndex)='deletePositonAtIndexCallback($event)'
  ></template-position-item>

  
</mat-expansion-panel> 
  </div>
</div>
