
import { DatePipe } from '@angular/common';
import { differenceInMinutes } from 'date-fns';
import jsPDF from 'jspdf';
import { UiService } from 'src/app/services/ui.service';
import { TemplateControllerService } from 'src/app/services/template-controller.service';

export interface SurgeryReportI {

  process_id?:number;
  owner?:string;
  editorCopy?:string;
  lastModified?: number;
  report_mode?: string;
  docName?: string;
  doc_description?: string;
  editorReport?: string;
  patient_name?: string;
  klinikName?: string;
  abteilung?: string;
  behandelnder_arzt?: string;
  facharzt_bezeichnung?: string;
  arzt_role?: string;
  address_1?: string;
  address_2?: string;
  diagnose?: string;
  therapiemethode?: string;

  legalMain:string
  invoiceProviderFirst:string
  invoiceProviderSecond:string
  invoiceProviderThird:string
  invoiceProviderFourth:string

  isClinic: boolean

}
export interface SurgeryReportItemI {
  example?:string;
  report?:string;
}
export interface SurgeryReportDocs {
  title?:string;
  name?:string;
}
export interface FinalSurgeryReportI{
  documentTitle:string;
  pdf:jsPDF;
}

export class SurgeryReportC {

  constructor(private datePipe: DatePipe, private ui:UiService, private tc:TemplateControllerService){

  }
  // createReport(template:TemplateFlatI,
  //   headerInfos:SurgeryHeaderI,
  //   account:AccountI,
  //   surgeryReportPos:SurgeryReportPositionI[],
  //   patient:PatientFull, employees:employeeI[], pAddress:AddressI,
  //   surgerySupportUser:SurgerySupportPersonI[]):SurgeryReportI {
  //   let _opDuration = ''
  //   let _opDateStart = ''
  //   if(headerInfos.timeStart != null && headerInfos.timeEnd != null){
  //     _opDuration = this.calculateOPDuration(new Date(headerInfos.timeStart),new Date(headerInfos.timeEnd))
  //     _opDateStart = this.datePipe.transform(new Date(headerInfos.timeStart), 'dd.MM.yyyy HH:mm')
  //   }


  //   const _docs:SurgeryReportDocs[] = [];
  //   const _reportItems:SurgeryReportItemI[] = [];

  //   for(var i = 0; i < surgerySupportUser.length; i ++){
  //     _docs.push({
  //       title: surgerySupportUser[i].role_title,
  //       name: surgerySupportUser[i].firstName + ' ' +surgerySupportUser[i].lastName
  //     })
  //   }

  //   let testMessage = '';
  //   surgeryReportPos.forEach( _pos => {

  //   })


  //   const _report: SurgeryReportI = {
  //       klinikName: pAddress.klinikName,
  //       abteilung: pAddress.abteilung,
  //       facharzt_bezeichnung: pAddress.facharzt_bezeichnung,
  //       behandelnder_arzt: pAddress.behandelnder_arzt,
  //       address_1: pAddress.streetName,
  //       address_2: pAddress.zip + ' '+ pAddress.city,
  //       diagnose: this.tc.getLatesDiagnose(),
  //       therapiemethode: this.tc.getLatesTherapie(),
  //       patient_name: patient == null || patient.lastName ==  null ? '' : patient.lastName + ' ' + patient.firstName,
  //       editorCopy: ''

  //     };

  //     return _report;
  //   }


    private calculateOPDuration(dateStart: Date, dateEnd: Date): string{
      let surgeryDuration = '';
      const minutes: number = differenceInMinutes(dateEnd, dateStart);
      const _hoursOut = minutes / 60;
      const _rHoursOut = Math.floor(_hoursOut);
      const _minutesOut = (_hoursOut - _rHoursOut) * 60;
      const _rMinutesOut = Math.round(_minutesOut);
      surgeryDuration = _rHoursOut.toString() + 'h ' + _rMinutesOut.toString() + 'min';

      return surgeryDuration;

    }
    public editorCopy(preview:SurgeryReportI): string {
      let testMessage = '';

      // for (let i = 0; i < preview.sections.length; i++) {

      //   const section = preview.sections[i];

      //   for (let j = 0; j < section.positions.length; j ++) {

      //     const pos = section.positions[j];
      //     if (pos.surgery_relevant){
      //     //if (pos.activated && pos.surgery_relevant){

      //       let begruendung = '';

      //       if (pos.surgeryItems != null){
      //         const surgItem: SurgeryProtocollItem = pos.surgeryItems.find((item) => item.selected);
      //         let begruendungSteigerung = '';
      //         if (pos.goa_paragraph != null){
      //           if (pos.goa_paragraph.progression_factor !== pos.progression_factor && pos.justification_items != null){
      //             const jufc: JustificationItemI = pos.justification_items.find((item) => item.progression_factor === pos.progression_factor);
      //             if (jufc != null) {
      //               let textItem = jufc.text_items.find((item) => item.selected === true);
      //               if(textItem != null){
      //                 textItem.text = textItem.text.endsWith(' ') ? textItem.text.substring(0,textItem.text.length -1) : textItem.text;
      //                 begruendungSteigerung = textItem != null ?  ' ' +  textItem.text : '';
      //               }

      //             }
      //           }
      //         } else {
      //         }

      //         if (surgItem != null) {
      //           const __text =  surgItem.text.endsWith(' ') ? surgItem.text.substring(0,surgItem.text.length -1) : surgItem.text;
      //           begruendung = __text
      //         } else {
      //           let __text = pos.surgeryItems[0].text;
      //           __text = __text.endsWith(' ') ? __text.substring(0,__text.length -1) : __text;
      //           begruendung = __text
      //         }
      //         testMessage += begruendung;
      //         testMessage += '';
      //         if (begruendungSteigerung !== ''){
      //           testMessage += begruendungSteigerung += ' ';
      //         } else {
      //           testMessage += ' ';
      //         }
      //         // if (surgItem != null) {
      //         //   const __text =  surgItem.text.endsWith(' ') ? surgItem.text.substring(0,surgItem.text.length -1) : surgItem.text;
      //         // begruendung = __text
      //         // } else {
      //         //   let __text = pos.surgeryItems[0].text;
      //         //   __text = __text.endsWith(' ') ? __text.substring(0,__text.length -1) : __text;
      //         //   begruendung = __text
      //         // }
      //         // testMessage += begruendung;
      //         // testMessage += '';
      //         // if (begruendungSteigerung !== ''){
      //         //   testMessage += `${begruendungSteigerung} `;
      //         // }


      //       }
      //      }

      //    }
      //  }
       return testMessage;
    }
}
