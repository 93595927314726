import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
// import { lastValueFrom } from 'rxjs';
import { TemplateFlatI } from 'src/app/core/models/api/TemplateFlatI';
@Injectable({
  providedIn: 'root'
})
export class TemplateDetailService {
  private basePath: string = ""

  constructor(private http: HttpClient) { 
    this.basePath = environment.endpoints.apiBasePathV2
  }

  loadTemplatesTest() {
    //return lastValueFrom(this.http.get<TemplateFlatI[]>(this.basePath + 'templates/overview-own'))
  }
}
