import { Component,OnInit } from '@angular/core';
import { BASE_TEMPLATE_TYPE } from 'src/app/core/models/enums';

import { TemplateControllerService } from 'src/app/services/template-controller.service';


interface Reduction {
  value: number;
  viewValue: string;
}
@Component({
  selector: 'template-header-minderung',
  templateUrl: './template-header-minderung.component.html',
  styleUrls: ['../templateHeader.scss']
})
export class TemplateHeaderMinderungComponent implements OnInit {
  
  reduction:number = 0
  selectedReduction
  reductions: Reduction[] = [
    {value: 0, viewValue: 'keine Minderung'},
    {value: 15, viewValue: 'Belegarzt (-15%)'},
    {value: 25, viewValue: 'Chefarzt (-25%)'},
    
  ]
  constructor(
    public tc:TemplateControllerService,
 
  ) { }
  ngOnInit(): void {
    
    setTimeout(()=>{
      if(this.tc.activeTemplate.reduction != null){
        if(this.tc.activeTemplate.reduction == 25){
          this.reduction = 25;
          this.selectedReduction = this.reductions[2].value
        } else if(this.tc.activeTemplate.reduction == 15){
          this.reduction = 15;
          this.selectedReduction = this.reductions[1].value
        } else if(this.tc.activeTemplate.reduction == 0){
          this.reduction = 0;
          this.selectedReduction = this.reductions[0].value
        }
      }
    },1000)
  }
  async changed(e){
    this.tc.activeTemplate.reduction = e.value;
    if(this.tc.baseTemplateType == BASE_TEMPLATE_TYPE.TEMPLATE){
      await this.tc.updateTemplateItem({reduction: e.value})
    } else if(this.tc.baseTemplateType == BASE_TEMPLATE_TYPE.PROCESS){
      await this.tc.updateProcessItem({reduction: e.value})
      this.tc.itemsUpdated.emit();
    } 
  }

  // get isDisabled():boolean {
  //   if(this.tc.baseTemplateType === BASE_TEMPLATE_TYPE.TEMPLATE){
  //     return !this.tc.isTemplateEditable
  //   } else {
  //     return this.tc.activeProcess.invoice_id > 0 || this.tc.activeProcess.surgery_report_id > 0
  //   }
    
  // }

}
