<mat-toolbar class="overlay-toolbar">
    <span class="toolbar-spacer"></span>
    <mat-icon class="dxfx_icon_btn_mini" (click)='close()'>close</mat-icon>
  </mat-toolbar>
<div class="container" style="height: calc(100% - 88px);">

    <div class="d-flex flex-column align-items-center justify-content-center h100" >
        <!-- <div class="p-2">
            <h1>{{accountingPool.description}}</h1> 

             *ngIf="accountingPool && admin"
        </div> -->
        <div class="p-2">
            <div [innerHTML]="inviteMessge"></div>
        </div>
<!-- 
        <div class="p-2">

            <div class="d-flex align-items-center justify-content-center">
                <div class="p-2">
                    <app-df-icon-button
                    title="Ablehnen"
                    icon="delete"
                    
                    (clicked)="cancelInvite()"
                    >
                    </app-df-icon-button>
                </div>
                <div class="p-2">
                    <app-df-icon-button
                    title="Annehmen"
                    icon="check"
                    color="accent"
                    (clicked)="confirm()"
                    >
                    </app-df-icon-button>
                </div>
            </div>
        </div> -->
    </div>
</div>

<mat-toolbar class="overlay-toolbar">
    <div class="d-flex w100 justify-content-center" >
      <div class="p-2 ">
        <app-df-icon-button title= "Abbrechen" icon="delete" (clicked)="cancelInvite()"></app-df-icon-button>  
      </div>
      <div class="p-2 ">
        <app-df-icon-button title= "Einladung annehmen" icon="check" color="primary" (clicked)="confirm()"></app-df-icon-button>  
      </div>
    </div>
  
  </mat-toolbar>