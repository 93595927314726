export interface IAuthResponse {
  status?:STATUS_RESPONSE;
  type?: ERROR_TYPE
  message?: REPONSE_MESSAGE
  token?: string
  refresh?: string
  uid?: string
  expiresIn?:number
}

export enum STATUS_RESPONSE {
  ERROR = "ERROR",
  UPDATE_PASSWORD_REQUIRED = "UPDATE_PASSWORD_REQUIRED",
  BLOCKED_BY_ADMIN = "BLOCKED_BY_ADMIN",
  ACCESS_ADDRESS_MISSING = "ACCESS_ADDRESS_MISSING",
  ACCESS = "ACCESS",
  SUCCESS = "SUCCESS",
  CHECK_ACCOUNTING = "CHECK_ACCOUNTING",
  ACCOUNT_EXPIRED = "ACCOUNT_EXPIRED",
}

export enum ERROR_TYPE {
  NO_PW_IN_DB = "NO_PW_IN_DB",
  WRONG_PW = "WRONG_PW",
  BLOCKED = "BLOCKED",
  USER_NOT_FOUND = "USER_NOT_FOUND",
}


export enum REPONSE_MESSAGE {
  UPDATE_PASSWORD_REQUIRED = "UPDATE_PASSWORD_REQUIRED",
  MISSING_PAYLOAD = "MISSING_PAYLOAD",
  RETISTRATION_FAILED = "RETISTRATION_FAILED"

}
export interface IValiateFBResponse {
  status?:STATUS_RESPONSE;
  uid?: string
  token?: string
 
}

export interface IAccountingInviteResponse {
  status:STATUS_RESPONSE;
  code:ACCOUNTING_INVITE_RESPONSE_CODE
  
}
export enum ACCOUNTING_INVITE_RESPONSE_CODE {
  
  MISSING_PAYLOAD = "MISSING_PAYLOAD",
  ACCOUNTANT_INVITED = "ACCOUNTANT_INVITED",
  USER_INVITED = "USER_INVITED",
  USER_ACCOUNT_NOT_REGISTERED = "USER_ACCOUNT_NOT_REGISTERED",
  UNKNOWN = "UNKNOWN",
  USER_ALREADY_HAS_ACCOUNTANT = "USER_ALREADY_HAS_ACCOUNTANT",
  USER_IS_REGISTERED_IN_ANOTHER_IN_ANOTHER_TEAM = "USER_IS_REGISTERED_IN_ANOTHER_IN_ANOTHER_TEAM",
  USER_HAS_ALSO_ACCOUNTING = "USER_HAS_ALSO_ACCOUNTING",
}

export interface CREATE_PASSWORD_RESPONSE {
  status:CREATE_PASSWORD_RESPONSE_STATUS
  value?: string
}

export enum CREATE_PASSWORD_RESPONSE_STATUS {
  ERROR = "ERROR",
  SUCCESS  = "SUCCESS"
}


export interface CREATE_ACCOUNTING_SECTION_POOL_RESPONSE {
  status:CREATE_PASSWORD_RESPONSE_STATUS
  value?: string
}
export enum API_RESPONSE_STATE {
  ERROR = "ERROR",
  SUCCESS  = "SUCCESS"
}
export interface API_RESPONSE {
  status: API_RESPONSE_STATE
  message?: string
  code?: string
}

export enum VIEWSTATE {
  NONE,
  LOADING, 
  ERROR, 
  SUCCESS, 
  COMPLETE
}