import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { template_item_text_tiles } from '../core/models/api/templateUtils';
import { SurgeryReportI } from '../core/models/surgeryReportI';
import { NachbehandlungItemI } from '../core/models/treatment_typeM';
import { ControllerService } from '../shared/controller.service';
import { TEMPLATES_CALL, TEMPLATE_API_PATH } from './enums/templateS.callEnum';

@Injectable({
  providedIn: 'root'
})
export class TemplateApiService {
  private _apiBasePath:string = environment.endpoints.apiBasePath;
  private _apiBasePathV2:string = environment.endpoints.apiBasePathV2
  constructor(private http:HttpClient) {

  }

  /**
   *
   * MINIMIZE
   *
   *
   *
   */
   async apiGet(call:TEMPLATES_CALL, params:string = ''){
    const _path = TEMPLATE_API_PATH(call) + params
    const _res = await this.http.get(_path).toPromise()

    if (_res && _res['status'] && _res['status'] == "ERROR" && _res['type']){
      ControllerService.sessionTimeoutEmitter.emit(_res['type'])
      return;
    }
    return _res
  }

  async apiPostObject(call:TEMPLATES_CALL, payload:Object){
    const _path = TEMPLATE_API_PATH(call)
    const _res = await this.http.post(_path,payload).toPromise()
    if (_res['status'] && _res['status'] == "ERROR" && _res['type']){
      ControllerService.sessionTimeoutEmitter.emit(_res['type'])
      return;
    }
    return _res
  }

  async apiPostObjectAndParams(call:TEMPLATES_CALL, params:string, payload:Object){
    const _path = TEMPLATE_API_PATH(call) + params
    
    const _res = await this.http.post(_path,payload).toPromise()
    if (_res['status'] && _res['status'] == "ERROR" && _res['type']){
      ControllerService.sessionTimeoutEmitter.emit(_res['type'])
      return;
    }
    return _res
  }

  async apiPutObject(call:TEMPLATES_CALL, payload:Object){
    const _path = TEMPLATE_API_PATH(call)
    const _res = await this.http.put(_path,payload).toPromise()
    if (_res['status'] && _res['status'] == "ERROR" && _res['type']){
      ControllerService.sessionTimeoutEmitter.emit(_res['type'])
      return;
    }
    return _res
  }

  /**
   *
   * reduction changed
   *
   *
   *
   */
  


  async templateDetail(docId:number | string){
    const _path = this._apiBasePathV2 + `templates/single/${docId}`
    const r = await this.http.get(_path).toPromise()
    return r;
  }


  async createNewTemplate(dto:Object){
    const _path = this._apiBasePathV2 + 'templates/create/'
    const data = {
      dto: dto
    }
    const message = await this.http.post(_path, data).toPromise().then(
      _data => {
        return _data;
      }
    )
    return message;
  }

  async deleteTemplate(templateId:number){
    await this.http.delete(TEMPLATE_API_PATH(TEMPLATES_CALL.DELETE_TEMPLATE, templateId.toString())).toPromise()
  }

  async publishTemplate(templateId:number){
    const message = await this.http.get(`${this._apiBasePathV2}templates/publish/${templateId}`).toPromise().then(
      _data => {
        return _data;
      }
    )
    return message;
  }
  async unpublishTemplate(templateId:number){
    // const _path = this._apiBasePath + 'templatesV1/unpublishTemplate/' + templateId
    const message = await this.http.get(`${this._apiBasePathV2}templates/unpublish/${templateId}`).toPromise().then(
      _data => {
        return _data;
      }
    )
    return message;
  }






  async shareTemplate(pPayload:Object){
    const data = { payload: pPayload };
    const message = await this.http.post(`${this._apiBasePathV2}templates/sharing`, data).toPromise();//.
    //   then(
    //   _data => {
    //     return _data;
    //   }
    // )
    return message;
  }



  async deleteProcess(process_id:number){
    const message = await this.http.get<number>(`${this._apiBasePathV2}processes/delete/${process_id}`).toPromise()
    return message;
  }



  /// surgery report previews
  async getSurgeryReportPreview(docId:string){
    const _path = this._apiBasePath + 'processesV1/surgery-report/' + docId

    return await this.http.get(_path).toPromise()
    .then(
      _data => {
        return _data;
      }
    )
   /*  return report;   */
  }
  async deleteSurgeryReportPreview(docId:string){
    const _path = this._apiBasePath + 'processesV1/delete-surgery-report/' + docId

    return await this.http.get(_path).toPromise()
    .then(
      _data => {
        return _data;
      }
    )
   /*  return report;   */
  }
  async addSurgeryReportPreview(rep:SurgeryReportI){
    const _path = this._apiBasePath + 'processesV1/add-surgery-report/'
    let data = {
      payload:rep
    }
    return await this.http.post(_path, data).toPromise()
    .then(
      _data => {
        return _data;
      }
    )
   /*  return report;   */
  }


  /**
   *
   * FINAL SURGERY REPORTS
   *
  **/

  async addFinalSurgeryReport(IPROCESSd:string, report:SurgeryReportI) {
    const _path = this._apiBasePath + 'processesV1/add-surgery-report/';
    const postData = {
      payload:{
        IPROCESSd: IPROCESSd,
        report: report
      }
    };
    return await this.http.post(_path, postData).toPromise()
    .then(
      _data => {
        return _data;
      }
    )
  }

  async deleteFinalSurgeryReport(docId:string){
    const _path = this._apiBasePath + 'processesV1/delete-surgery-report/' + docId

    return await this.http.get(_path).toPromise()
    .then(
      _data => {
        return _data;
      }
    )
   /*  return report;   */
  }




  //// UTILS
  /// get

  async getTemplateTextTilesAll(template_id:number, prefix: string){
    const r = await this.http.get(`${this._apiBasePathV2}${prefix}/modules/item-tiles/for-template/${template_id}`).toPromise()
    return r
    // .then(
    //   _data => {
    //     return _data;
    //   }
    // )
    // return []
  }
  async getTemplateTextTileFor(pType:string, template_id:number, prefix: string){
    const r = await this.http.get(`${this._apiBasePathV2}${prefix}/modules/item-tiles/${pType}/${template_id}`).toPromise()
    return r
    // .then(
    //   _data => {
    //     return _data;
    //   }
    // )
    // return []
  }

  async addTextItemTileFor(type:string, template_id:number, prefix: string, description: string, text: string) {
    return await this.http.put(`${this._apiBasePathV2}${prefix}/modules/item-tiles/${type}/${template_id}`,
    { text: text, description: description}).toPromise();
  }
  async updateTextItemTileFor(item:template_item_text_tiles, prefix: string) {
    return await this.http.post(`${this._apiBasePathV2}${prefix}/modules/item-tiles/${item.text_tile_id}`,
    { text: item.text, description: item.description,}).toPromise();
  }
  async deleteTextItemTileFor(item:template_item_text_tiles, prefix: string) {
    await this.http.delete(`${this._apiBasePathV2}${prefix}/modules/item-tiles/${item.text_tile_id}`).toPromise();
  }


  async loadPState(prefix: string){
    const _states = await this.http.get(`${environment.endpoints.apiBasePathV2}${prefix}/modules/states/`).toPromise()
    .then(_res => {
      return _res;
    })
    return _states
  }
  async changeProcessState(prefix: string, newStateId:number, process_id:number, dto: Object = {}){
    const _states = await this.http.post(`${environment.endpoints.apiBasePathV2}${prefix}/modules/states/${process_id}/${newStateId}`,{dto: dto}).toPromise()
    .then(_res => {
      return _res;
    })
    return _states
  }
  async processStateHistory(process_id:number, prefix: string){
    const _states = await this.http.get(`${environment.endpoints.apiBasePathV2}${prefix}/modules/states/history/${process_id}`).toPromise()
    .then(_res => {
      return _res;
    })
    return _states
  }




/**
 *
 * PROGRESSION FACTORS
 *
 */

 async loadProgressionFactors(){
  const _results = await this.http.get(`${environment.endpoints.apiBasePathV2}ui/progression-factors`).toPromise()
  .then(_res => {
    return _res;
  })
  return _results
}




/**
 *
 * INVOICE ITEMS
 *
 */


  async getInvoicePreviewItems(processId:number) {
    const _r = await this.http.get(`${environment.endpoints.apiBasePathV2}invoice/preview/${processId}`).toPromise()
    .then(_res => {
      return _res;
    })
    return _r
  }
  async getFullInvoiceAddress(processId:number) {
    const _r = await this.http.get(`${environment.endpoints.apiBasePathV2}invoice/full-address-for-process/${processId}`).toPromise()
    .then(_res => {
      return _res;
    })
    return _r
  }

  async unlockInvoice(template_id:number){
    const _res = await this.http.get(`${this._apiBasePathV2}invoice/unlock-invoice/${template_id}`).toPromise()
    return _res;
  }



  // NACHBEHANDLUNGEN
  async getTemplateNachbehandlungFor(template_id:number, prefix: string){
    const _r = await this.http.get<any[]>(`${this._apiBasePathV2}${prefix}/modules/nachbehandlung/by-templateId/${template_id}`).toPromise()
    return _r;
  }

  async updateNachbehandlungFor(item:NachbehandlungItemI, prefix: string) {
    const _path = `${this._apiBasePathV2}${prefix}/modules/nachbehandlung/update/${item.template_nachbehandlung_id}`
    return await this.http.post(_path, {
        text: item.text,
        description:item.description
    }).toPromise();
  }

  async addNachbehandlungFor(template_id:number, text:string, description:string, prefix: string) {
    const _path = `${this._apiBasePathV2}${prefix}/modules/nachbehandlung/add/`
    return await this.http.put(_path, {
      template_id:template_id,
        text: text,
        description:description
    }).toPromise();
  }

  async deleteTemplateNachbehandlungFor(docId:number, prefix: string){
    return await this.http.get(`${this._apiBasePathV2}${prefix}/modules/nachbehandlung/delete/${docId}`).toPromise()
  }

}
