import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { POSITION_TYPE } from 'src/app/core/models/enums';
import { SectionM } from 'src/app/core/models/sectionM';
import { OR_TYPE, PositionFlatI } from 'src/app/core/models/sectionPositionM';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { ControllerService } from 'src/app/shared/controller.service';
import * as _ from 'lodash';
import { T_POSITION_CALL } from 'src/app/services/enums/positionS.call';
@Component({
  selector: 'app-position-copy-item',
  templateUrl: './position-copy-item.component.html',
  styleUrls: ['./position-copy-item.component.scss']
})
export class PositionCopyItemComponent implements OnInit {

  @Input() section:SectionM
  @Input() isOrigin: boolean
  @Output() positionDropped = new EventEmitter<CdkDragDrop<string[]>>()
  // @Output() selectedPosEmitter = new EventEmitter<PositionM>()
  OR_TYPES = OR_TYPE
  positions:PositionFlatI[] = []
  isPositionInClipBoard?:PositionFlatI = null
  constructor(private tc: TemplateControllerService) { }

  ngOnInit(): void {
    console.log(this.section)
    this.loadPositions()
    this.tc.splitScreencontrollerPositionClipboard.subscribe(
      copyPosition => {
        if ( copyPosition != undefined) {
          console.log('copyPosition IS READY')
          if( this.isOrigin == false) {
            this.isPositionInClipBoard = copyPosition
            console.log('this.isPositionInClipBoard', this.isPositionInClipBoard);

          }
        } else {
          console.log('copyPosition UNDEFINED');
          this.isPositionInClipBoard = null
        }


      }
    )
  }
  async loadPositions(){
    const _ps = await this.tc.loadPositionsBySectionId(this.tc.prefix, this.section.section_id) as PositionFlatI[]
    console.log(_ps)
    this.positions = _ps.sort((a,b) => a.position_order_index - b.position_order_index)
  }
  showActiveSplitBG(s:SectionM, p:PositionFlatI):boolean{
    if(p.orType != OR_TYPE.NONE){

      if(p.orType == OR_TYPE.OR && p.activated){
        return true;
      }
      // if(p.orType == OR_TYPE.INDENTED){
      //   let orGroupItem:PositionOrGroupItemI = this.orGroupItemFromIndentedPosition(s,p);
      //   if(orGroupItem == null) return false;
      //   let _p = s.positions.find((pos) => pos.position_order_index == orGroupItem.index)
      //   if(_p != null){
      //     return _p.activated;
      //   }

      // }
    }
    return false;
  }

  // orGroupItemFromIndentedPosition(s:SectionM,p:PositionFlatI):PositionOrGroupItemI {
  //   // for(const group of s.orGroups) {
  //   //   for (const positionOrGroupItem of group.items) {
  //   //     if(positionOrGroupItem.indentedItems.includes(p.position_order_index)){
  //   //       return positionOrGroupItem;
  //   //     }
  //   //   }
  //   // }
  //   return null;
  // }
  getSummery(position:PositionFlatI) : number {
    if(position.position_type == POSITION_TYPE.GOA || position.goa_paragraph == null){
      return  0.0
    }
    return (position.progression_factor * position.goa_paragraph.amount_euro) * position.quantity
  }
  onPosDragStarted(e){

    // this.selectedPosEmitter.emit(e.source.data)
  }
  dropPosition(event){
    console.log(event)
    // this.positionDropped.emit(event)
   }


   copyItem(p:PositionFlatI) {
    this.tc.splitScreencontrollerPositionClipboard.emit(p)

    ControllerService.customSnack.emit({
      panelClass: "highlightSnack",
      duration: 5000,
      message: `${p.example} kopiert`
    })
   }

   pasteFromClipBoard(p:PositionFlatI) {
    var clonedpositions = _.cloneDeep(this.positions)
    var positions = clonedpositions.sort((a,b) => a.position_order_index - b.position_order_index)
    .filter(item => item.position_order_index > p.position_order_index)

    console.log(positions)

    var positionFromClipBoard = this.isPositionInClipBoard != null ? _.cloneDeep(this.isPositionInClipBoard) : null;
    if (positionFromClipBoard) {
      console.log('POSITION FROM CLIPBOARD > ', positionFromClipBoard)
      console.log('PASTE AFTER POSITION > ',p);
      if (p.orType == this.OR_TYPES.NONE) {
        positionFromClipBoard.orType = this.OR_TYPES.NONE
      } else if (p.orType == this.OR_TYPES.OR) {
        positionFromClipBoard.orType = this.OR_TYPES.INDENTED
      } else if (p.orType == this.OR_TYPES.INDENTED) {
        positionFromClipBoard.orType = this.OR_TYPES.INDENTED
      }

      let insertPositionOrderIndex = p.position_order_index + 1;
      positionFromClipBoard.template_id = this.section.template_id
      positionFromClipBoard.section_id = this.section.section_id
      positionFromClipBoard.position_order_index = insertPositionOrderIndex
      positionFromClipBoard.hasOrModeTrenner = false;
      positionFromClipBoard.activated = p.activated
      positionFromClipBoard.starts_new_group = false;
      positionFromClipBoard.startNewOrCollection = false;

      console.log("POSITION ORDER INDEX FOR POS FROM CLIPBOARD : ", insertPositionOrderIndex)

      var updatePositionOrderIndex = insertPositionOrderIndex + 1
      // console.log("POSITION ORDER INDEX FOR UPDATE BOTTOM POSITIONS : ", updatePositionOrderIndex)
      for(var i = 0; i < positions.length; i ++) {
        let orderIndex = updatePositionOrderIndex + i
        console.log(` curent:  ${positions[i].position_order_index} new: ${orderIndex}`)
        positions[i].position_order_index = orderIndex
        // console.log(` curent:  ${ positions[i].position_id} index: ${_orderIndex}`)
      }
      this.updatePositions(positions)

     setTimeout(()=> {
      this.transferClonedPosition(positionFromClipBoard)
     },150)

    }
   }
   pasteFromClipBoardEmpty() {
    
    var positionFromClipBoard = this.isPositionInClipBoard != null ? _.cloneDeep(this.isPositionInClipBoard) : null;
    if (positionFromClipBoard) {

      positionFromClipBoard.orType = this.OR_TYPES.NONE

      let insertPositionOrderIndex = 0
      positionFromClipBoard.template_id = this.section.template_id
      positionFromClipBoard.section_id = this.section.section_id
      positionFromClipBoard.position_order_index = insertPositionOrderIndex
      positionFromClipBoard.hasOrModeTrenner = false;
      // positionFromClipBoard.activated = p.activated
      positionFromClipBoard.starts_new_group = false;
      positionFromClipBoard.startNewOrCollection = false

     setTimeout(()=> {
      this.transferClonedPosition(positionFromClipBoard)
     },150)

    }
   }

   private async transferClonedPosition(p:PositionFlatI) {

    const _dto = {
      dto: {
        target_position_id: p.position_id,
        position: p
      }
    }
      const _result = await this.tc.postAndGetId(this.tc.prefix, T_POSITION_CALL.CLONE_POSITION, _dto)
      if (_result) {
        console.log('RESULT ', _result);
        let _position_id = parseInt(_result)
        console.log('RESULT ', _position_id);
        p.position_id = _position_id
        var newP = _.cloneDeep(p)
        newP.position_id = _position_id
        this.positions.push(newP)
        this.positions.sort((a,b) => a.position_order_index - b.position_order_index)
        this.tc.splitScreencontrollerPositionClipboard.emit(null)
      } else {
        ControllerService.customSnack.emit({
          panelClass: "customSnack",
          duration: 5000,
          message: "Ein Fehler ist aufgetreten bitte laden Sie die Seite neu!"
        })
      }


   }

   private async updatePositions(positions: PositionFlatI[]) {
    let _template_id = this.tc.currentTemplateId
    for(var i = 0; i < positions.length; i ++ ) {

      let _position_id = positions[i].position_id
      let _orderIndex = positions[i].position_order_index
      let _oeIndex = this.positions.findIndex(item => item.position_id == _position_id)
      if(_oeIndex != -1) {
        this.positions[_oeIndex].position_order_index = _orderIndex
      }
      await this.tc.updateSinglePositionItem(_template_id, _position_id, {position_order_index: _orderIndex})
    }
   }


}
