import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateFlatI } from 'src/app/core/models/api/TemplateFlatI';
import { IProcessChangeOverlayData, IProcessChangeOverlayDataViewMode } from 'src/app/core/models/viewI/openProcessOverlayData';
import { TEMPLATES_CALL } from 'src/app/services/enums/templateS.callEnum';
import { LocaleService } from 'src/app/services/locale.service';
import { TemplateApiService } from 'src/app/services/template-api.service';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-process-stage-change-overlay',
  templateUrl: './process-stage-change-overlay.component.html',
  styleUrls: ['./process-stage-change-overlay.component.scss']
})
export class ProcessStageChangeOverlayComponent implements OnInit {
  
  title = ""
  infoMessage = ""
  userMessage: string = ""
  textDisabled = false
  isSaving = false
  olData: IProcessChangeOverlayData
  viewMode: IProcessChangeOverlayDataViewMode = IProcessChangeOverlayDataViewMode.LOADING
  VIEWMODES = IProcessChangeOverlayDataViewMode

  get readyBtnTxt(): string {
    if(this.olData && this.olData.errors.length > 0) {
      return 'Trotzdem abschliessen'
    } else {
      return "Speichern"
    }
  }

  constructor(
    public tc:TemplateControllerService,
    public ui:UiService,
    private templateAPi: TemplateApiService,
    public ls: LocaleService,public dialogRef: MatDialogRef<ProcessStageChangeOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IProcessChangeOverlayData) {
      this.title = data.windowTitle
      this.infoMessage = data.windowBodyMessage
      this.olData = data
      this.viewMode = data.viewMode
    } 

  ngOnInit(): void {
  }

  async onTextAreaKeyDown(event){

  }

  onTextAreaChanged(){
    // this.hasReportCopyAreaChanged = true;
  }
  
  async onTextAreaBlur(event) {

  }

  resizeEvent(e){
    // console.log('TEXT AREA RESIZE ', e);
  }
  
  get canSave(): boolean {
    let m = this.userMessage.trim()
   return true//m.length > 5 ? true : false
  }

  async save() {
    
    if(this.olData) {

      this.isSaving = true
    
      if(this.olData.viewMode == IProcessChangeOverlayDataViewMode.UNLOCK_INVOICE) {
        await this.tc.unlockInvoice(this.tc.currentTemplateId)
      }
  
      const dto = {
        message: this.userMessage,
        accounting_pool_id: this.ui.accountingPool == null ? null : this.ui.accountingPool.accounting_pool_id,
        stateId: this.olData.newStatus_id,
        assigned_role: this.olData.role,
        assignee_uid: this.olData.new_assignee_uid,
        template_id: this.olData.template_id,      
      }
      
      await this.templateAPi.apiPostObject(TEMPLATES_CALL.PROCESS_STATE_CHANGED,{dto:dto})
      // await this.tc.processStateChanged(this.changeToStatusId, this.template_id, dto);
  console.log(dto);
  
      setTimeout(()=> {
        this.isSaving = false
        this.dialogRef.close(this.olData.closeAction)
      }, 500)

    } else {
      this.dialogRef.close()
    }


  }

  async resetShareAccounting() {
    this.save()// = true
  }

  close() {
    this.dialogRef.close()
  }
}
