
<div style="position: relative; height: 100%;">
  <mat-toolbar style="position: static!important; height: 52px; width: 100%; border-radius: 6px;">
    <div class="d-flex align-items-center" style="height: 100%; width: 100%;">

        <div class="p-2 pr0 flex-fill">
          <input class="formControl mat-body formControlDescription fs14-400" 
          placeholder="Kurze Beschreibung (max 80 Zeichen)" [disabled]='!editable'
          (keydown)="onEditTextInputKeyDown($event)"
          (blur)="onEditTextInputBlur($event)"
        [(ngModel)]='description' maxlength="80">
        </div>
        <div class="p-0 p_t2 ml10">
          <mat-icon (click)='close()' class='justification_arrow_icon' style="font-size: 20px!important;">close</mat-icon>
        </div>
      
    </div>
  </mat-toolbar>

  <div class="container" style="height: calc(100% - 94px); width: 100%; margin-top: 15px; margin-bottom: 15px;">
    <div class="d-flex" style="height: 100%; width: 100%;">
      <div class="p-2 flex-fill">
        <textarea style="width:100%; height: 100%; background-color:rgba(0,0,0,0); resize: vertical !important;"  #editTextArea
        rows="4" cols='2' class="mat-body formControl fs14-400" placeholder="Ihr Text..."
        [(ngModel)]='text' [disabled]='!editable' 
        (blur)="onEditTextAreaBlur($event)"
        (keydown)="onEditTextAreaKeyDown($event)"
        >
        </textarea>
      </div>
    </div>
  </div>


  <div class="overlayPreloader" *ngIf="showSpinner">
    <div class="d-flex h100 justify-content-center align-items-center">
      <div class="p-2">
        <mat-spinner diameter="25"></mat-spinner>
      </div>
    </div>
  </div>
</div>

