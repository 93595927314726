export enum SettingsPanelType {
  PATIENT = 'PATIENT',
  EMPLOYEE = 'EMPLOYEE',
  OWN_BILLIG = 'OWN_BILLIG',
  MATERIAL = 'MATERIAL',
  OP_ITEM = 'OP_ITEM',
  INVOICE = 'INVOICE',
  USERS = 'USERS',
  GOAE = 'GOAE',
  EXPERTISES = 'EXPERTISES'
}