import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from 'src/app/services/account.service';
import { ACCOUNT_CALL } from 'src/app/services/enums/accountS.callEnum';
import { ISocketOpenInviteMessage } from 'src/app/services/socket.service';

@Component({
  selector: 'app-accounting-info-page',
  templateUrl: './accounting-info-page.component.html',
  styleUrls: ['./accounting-info-page.component.scss']
})
export class AccountingInfoPageComponent implements OnInit {

  // accountingPool:IAccountingPool = null

  // admin: IAccountingPoolUser = null
  
  inviteData: ISocketOpenInviteMessage
  constructor(
    private as: AccountService,
    private dialogRef: MatDialogRef<AccountingInfoPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISocketOpenInviteMessage, 
    
    ) { 
      this.inviteData = data
    }


  

  ngOnInit(): void {
    
  }

  

  get inviteMessge(): string {

    if(this.inviteData) {
      // zu ${this.accountingPool.description}
      return `Sie wurden von <em>${this.inviteData.firstName} ${this.inviteData.lastName}</em> zu einem Abrechnungsteam eingeladen. <br>`
      + `Möchten Sie die Einladung annehmen?`
    }

    return ""
  }
  async confirm(){
    // if(this.accountingPool) {
      const _r = await this.as.apiGet(ACCOUNT_CALL.ACCEPT_ACCOUNTING_INVITE, this.inviteData.accounting_user_pool_id.toString())
      this.dialogRef.close()
    //   setTimeout(()=>{
    //     // this.ui.accountantState()
        
    //   },150)
    //   setTimeout(()=>{
    //     if(this._nav.initRoute){
    //       this.router.navigate([this._nav.initRoute]);
          
    //     } else {
    //       this.router.navigate(['dashboard']);
          
    //     }

    //   },500)
    // }
    
  }

  cancelInvite(){
    this.dialogRef.close()
  }
  close() {
    this.dialogRef.close()
  }
}
