import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { template_lla_item } from 'src/app/core/models/api/templateUtils';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-perioperative-angaben',
  templateUrl: './perioperative-angaben.component.html',
  styleUrls: ['./perioperative-angaben.component.scss']
})
export class PerioperativeAngabenComponent implements OnInit {

  llaItem:template_lla_item
  constructor(
    private ui:UiService,
    private dialogRef:MatDialogRef<PerioperativeAngabenComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: template_lla_item )  { 
      this.llaItem = data
    }

  ngOnInit(): void {

  }

  close(){
    this.dialogRef.close()
  }
  async save(){
    if(this.llaItem.text == ''){
      return
    }
    if(this.llaItem.lla_text_id == -1){
      await this.ui.addLLaFor(this.llaItem.type, this.llaItem.text);
      this.dialogRef.close('reload')
    } else {
      await this.ui.updateLLaFor(this.llaItem.lla_text_id.toString(), this.llaItem.text);
      this.dialogRef.close('reload')
    }
  }
  async deleteItem(){
    this.dialogRef.close('reload')
    await this.ui.deleteLLaFor(this.llaItem.lla_text_id.toString());
  }

}
