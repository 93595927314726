

import { IProcessState } from "../iProcessState"
import { OverviewProcessI } from "../v4/processI"


export interface IOpenProcessOverlayData {
    title: string
    body: string
    status?: IProcessState
    cancelBtn?:string
    resumeBtn?:string
    process:OverviewProcessI
}

export interface IProcessChangeOverlayData {
    windowTitle: string
    windowBodyMessage: string

    viewMode: IProcessChangeOverlayDataViewMode

    closeAction: IProcessChangeOverlayCloseAction


    
    old_assignee_uid: string
    new_assignee_uid: string
    template_id: number

    // template: TemplateFlatI

    status?: IProcessState

    oldStatus_id: number
    newStatus_id: number

    role: string

    // hasError: boolean
    errors: string[]
    // unlockInvoice: boolean
}


export enum IProcessChangeOverlayDataViewMode {
    LOADING = "LOADING",
    STATEUPDATE = "STATEUPDATE",
    UNLOCK_INVOICE = "UNLOCK_INVOICE",
    SHARE_TO_ACCOUNTING = "SHARE_TO_ACCOUNTING",
    RESET_SHARE_TO_ACCOUNTING = "RESET_SHARE_TO_ACCOUNTING",
    BACK_TO_DOC = "BACK_TO_DOC",
    
}
export enum IProcessChangeOverlayCloseAction {
    BACK_TO_OVERVIEW = "BACK_TO_OVERVIEW",
    RELOAD = "RELOAD",
    NONE = "NONE",
    OPEN_TEMPLATE = "OPEN_TEMPLATE",
   
}


export interface IProcessStatesOverlayData {
    // windowTitle: string
    // windowBodyMessage: string

    viewMode: IProcessStatesOverlayDataViewMode

    // closeAction: IProcessChangeOverlayCloseAction
    status_id: number
    // template_id: number
    currentProcess:OverviewProcessI
}
export enum IProcessStatesOverlayDataViewMode {
    HISTORY = "HISTORY",
    STATES_VIEW = "STATES_VIEW",
   
}