<div style="position: relative; height: 100%;" >
  <mat-toolbar class="overlay-toolbar">
    <span class="toolbar-spacer"></span>
    <button mat-flat-button (click)='closeOverlay()'>Fertig</button>
  </mat-toolbar>


  <div class="container" style="height: calc(100% - 88px); overflow-y: scroll;">

    <!-- BESCHREIBUNG 1. BOX -->
    <div class="d-flex bb">
      <div class="p-2 w120">OP-Bericht</div>
      <div class="p-2 flex-fill pl20">Maßnahme</div>
      <div class="p-2 w120" style="text-align: center;">Ziffer</div>
    </div>
    <!-- ENDE BESCHREIBUNG 1. BOX -->

    <!-- ERSTER CONTAINER --> 
    <!-- OP CHECKBOX, Maßnamhmen Text und GOA Ziffer mit Tooltip -->
    <div class="d-flex">
      <div class="p-2 w120">
        <mat-checkbox color='accent' class=" mr0"
        [(ngModel)]='position.surgery_relevant' (change)='surgeryRelevantCB_changed()'
        [disabled]='!editable' style="text-align: right;">
      </mat-checkbox>
      </div>
      <div class="p-2 flex-fill" style="padding-left:20px">
        <!-- maxlength="2000" -->
        <textarea style="width:100%; height: 100%; background-color:rgba(0,0,0,0); resize: none !important;"  
          rows="5" cols='4' class="mat-body formControl fs14-400" #exampleTextArea
          (blur)="onTextFieldBlur($event)"
          (keyup)="onTextFieldKeyUp($event)"
          (keydown.esc)="onTextEscape($event)"
          [value]='exampleCopy' >
        </textarea>
      </div>
      <div class="p-2 w120" style="text-align: center;">
        <div class="d-flex bd-highlight p0"  *ngIf='position != null && position.position_type == "GOA" ' >
          <div class="p-0 flex-fill goaInfoButton"
            matTooltipClass='sectionGoaeTT'
            matTooltip='{{goaText}}'
            matTooltipPosition='above'
            *ngIf='position != null && position.goa_paragraph != null'>
            {{position.goa_paragraph.goa_id}}
          </div>
        </div>
      </div>
    </div>
    <!-- ENDE ERSTER CONTAINER -->

    <!-- BESCHREIBUNG 2. BOX -->
    <div class="d-flex bb">
      <div class="p-2">Bericht</div>
    </div>
    <!-- ENDE BESCHREIBUNG 2. BOX -->

    <!-- SURGERY REPORTS CONTAINER -->
    <div class="d-flex align-items-center p_r20 " *ngIf='reportItems && isLoaded'>
  
      <horizontal-item-selection
      [editable]="editable"
      [itemCount]="reportItems.length"
      [currentIndex]="activeIndex"
      (action)="onReportSelectionAction($event)"
    ></horizontal-item-selection>
   
    
      <div class="p-2 pr20 flex-fill" *ngIf="reportItems.length > 1" >
        <div class="d-flex">
          <div class="p-0 flex-fill">
            <mat-form-field appearance="outline" class="textfield100 select-form-field" *ngIf="!isTitleEditMode">
              <mat-select (selectionChange)='changed($event)' [(value)]="selectedReportItem" [disabled]="!tc.isTemplateEditable">
                <mat-option *ngFor="let reportItem of reportItems" [value]="reportItem">
                  {{reportItem.description}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <input class="formControl mat-body formControlDescription fs14-400" placeholder="Beschreibung" [disabled]='!editable' maxlength="250"
              style="background-color:rgba(0,0,0,0); " 
              #_titleTextField
              (keydown.enter)="onTextFieldTitleEnter($event)"
              (keydown.esc)="onTextFieldTitleEscape($event)"
              (change)="reportDescriptionInputChanged()"
              (blur)="reportDescriptionInputBlur($event)"
              [value]='getReportionCopyDescription'
              *ngIf="isTitleEditMode"
              >
          </div>
          <div class="p-0 p_t8 pl8" *ngIf="resizableTextarea">
            <mat-icon  *ngIf="!isTitleEditMode" (click)='setTitleEditMode()' class='justification_arrow_icon_mini' [class.justification_arrow_icon_green_disabled]="reportItems.length == 0">edit</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <!-- ENDE SURGERY REPORTS CONTAINER -->
    
    <!-- SURGERY TEXT CONTAINER -->
    <div class="d-flex p_t0 p_b5" *ngIf='reportItems && isLoaded'>
      <textarea style="width:100%; height: 100%; background-color:rgba(0,0,0,0);  resize: vertical !important;"
        maxlength="15000"
        rows="10" cols='2' class="mat-body formControl fs14-400"
        #_textArea
        (change)="reportCopyAreaChanged()"
        (resize)="resizeEvent($event)"
        (keydown.esc)="onReportCopyAreaEscape($event)"
        (blur)="reportCopyAreaBlur($event)"
        [value]="getReportionCopy" [disabled]='!editable'  >
      </textarea>
    </div>
    <!-- ENDE SURGERY TEXT CONTAINER -->

    <!-- BESCHREIBUNG 3. BOX -->
    <div class="d-flex bb">
      <div class="p-2 w120">Anzahl</div>
      <div class="p-2 w120">Steigerung</div>
      <div class="p-2 flex-fill pl20">Begründungen für Steigerung</div>
    </div>
    <!-- ENDE BESCHREIBUNG 3. BOX -->

    <!-- CONTENT 3. BOX -->
    <div class="d-flex">
      <div class="p-2 w120">
        <div *ngIf='position && position.position_type == POSITION_TYPES.GOA'>
          <position-quatity [position]="position" 
          [itemDisabled]="!editable" 
          [hasFocus]="hasQuantityFocus"
          (quantityChanged)="quantityChanged($event);"></position-quatity>
        </div>
      </div>
      <div class="p-2 w120">
        <div *ngIf="position && position.position_type == POSITION_TYPES.GOA">
          <!-- [progression_factors]="getPositionProgressionFactors(position.position_id)" -->
          <!-- (progressionFactorChanged)="progressionFactorChanged($event)" -->
          <position-progression-factor
            [position]="position" [itemDisabled]="!editable" [hasFocus]="hasProgressFactorFocus"
            [showProgressionFactor]="position && position.position_type == POSITION_TYPES.GOA"
          >
        </position-progression-factor>
      </div>
      </div>
      <div class="p-2 flex-fill pl20">
        <div class="d-flex">

          <div class="p-2" *ngIf='!itemNeedJustification'>
            <p class="fs12-400" style="color:rgba(0, 0, 0, 0.5);" >Keine Begründung erforderlich</p>
          </div>
          <div class="p-0 flex-fill" *ngIf='itemNeedJustification'>

            <div class="d-flex" *ngIf='!showJustificationButton'>
              <div class="p-0 flex-fill p_t5" >
                <horizontal-item-selection
                  [editable]="editable"
                  [itemCount]="goaJustifications.length"
                  [currentIndex]="currentJustificationIndex"
                  (action)="onJustificationSelectionAction($event)"
                ></horizontal-item-selection>
             
              </div>
              <div class="p-0 flex-fill" *ngIf='itemNeedJustification' style="width: 100%;">
                <div class="d-flex flex-column">
                  <div class="p-0" *ngIf="goaJustifications.length > 1">
                    <div class="d-flex">
                      <div class="p-0 flex-fill">
                        <mat-form-field appearance="outline" class="textfield100 select-form-field" *ngIf="!isJustificationTitleEditMode">
                          <mat-select (selectionChange)='justificationChanged($event)' [(value)]="selectedJustification" [disabled]="!tc.isTemplateEditable">
                            <mat-option *ngFor="let goaJustification of goaJustifications" [value]="goaJustification">
                              {{goaJustification.description}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <input class="formControl mat-body formControlDescription fs14-400" placeholder="Beschreibung" [disabled]='!editable' maxlength="250"
                          style="background-color:rgba(0,0,0,0); " 
                          #_justificationTitleTextField
                          (keydown.enter)="onJustificationTitleEnter($event)"
                          (keydown.esc)="onJustificationTitleEscape($event)"
                          (change)="justificationDescriptionInputChanged()"
                          (blur)="justificationDescriptionInputBlur($event)"
                          [value]='justificationDescription'
                          *ngIf="isJustificationTitleEditMode">
                      </div>
                      <div class="p-0 p_t8 pl8" *ngIf="resizableTextarea">
                        <mat-icon  *ngIf="!isJustificationTitleEditMode" (click)='setJustificationTitleEditMode()' class='justification_arrow_icon_mini' >edit</mat-icon>
                        <!-- [class.justification_arrow_icon_green_disabled]="reportItems.length == 0" -->
                      </div>
                    </div>
                  </div>
                  <!-- JUSTIFICATION TEXT AREA -->
                  <div class="p-0 p_t8">
                    <textarea style="width:100%; height: 100%; background-color:rgba(0,0,0,0);  resize: vertical !important;"
                      maxlength="300"
                      rows="5" cols='2' class="mat-body formControl fs14-400"
                      #_justificationTextArea
                      (change)="justificationCopyAreaChanged()"
                      (resize)="justificationResizeEvent($event)"
                      (keydown.esc)="onJustificationTextAreaEscape($event)"
                      (blur)="justificationTextAreaBlur($event)"
                      [value]="getJustificationCopy" [disabled]='!editable'  >
                    </textarea>
                  </div>
                  <!-- ENDE JUSTIFICATION TEXT AREA -->
                </div>

            </div>
          </div>
            
            <!-- ADD JUSTIFICATION BUTTON -->
            <div class="d-flex" *ngIf='showJustificationButton'>
              <div class="p-0" >
                <div class="col-auto p0 " style="height: 26px; margin-top: 3px!important;">
                  <mat-icon  matTooltipClass='sectionGoaeTT' matTooltip='Neue Begründung hinzufügen' matTooltipPosition='above'
                  class='justification_arrow_icon_mini_warn dib pl8' [class.enabled]='!position.activated'
                  (click)='openJustificationOverlay()' >add</mat-icon>
                  <div class="justification_copy_mini_warn dib ml3" (click)='openJustificationOverlay()'>Begründung erforderlich</div>
                </div>
              </div>
            </div>
            <!-- ENDE ADD JUSTIFICATION BUTTON -->
          </div>
        </div>
      </div>
    </div>
    <!-- ENDE CONTENT 3. BOX -->

  </div>

  <mat-toolbar class="overlay-toolbar"></mat-toolbar>

  <div class="loadOverlay" *ngIf="isSaving && isLoaded">
    <div class="d-flex h100 align-items-center justify-content-center">
      <div class="p-0"><mat-spinner diameter="25"></mat-spinner></div>
    </div>
  </div>

  <div class="loadOverlay" *ngIf="!isLoaded">
    <div class="d-flex h100 align-items-center justify-content-center">
      <div class="p-0"><mat-spinner diameter="25"></mat-spinner></div>
    </div>
  </div>

</div>