<mat-toolbar class="overlay-toolbar">
  <div class="d-flex align-items-center" style="height: 100%; width: 100%;">
   <!-- {{_currentFactor}} -->
    <div class="p-2  pr0 pl0">
      <mat-icon class='justification_arrow_icon' (click)='prevItem()'>keyboard_arrow_left</mat-icon>
    </div>


    <div class="p-2  pr0 pl0" style="font-size:14px; font-weight:300;">
      <span style="color: #1976D2; font-weight:500;">{{selectedIndex+1}}</span>
      <span> von {{goaJustificationsCount}}</span>
    </div>
    
    <div class="p-2  pr0 pl0">
      <mat-icon class='justification_arrow_icon' (click)='nextItem()'>keyboard_arrow_right</mat-icon>
    </div>

    <div class="p-2  pr0 pl10">
      <mat-icon (click)='addItem()' class='justification_arrow_icon' style="font-size: 20px!important;"
      matTooltipClass='sectionGoaeTT' matTooltip='Position hinzufügen' matTooltipPosition='above'>add</mat-icon>
    </div>
  <!--   <div class="p-2  pr0 pl0" *ngIf="justification.text_items.length > 1">
      <mat-icon (click)='removeItem()' class='justification_arrow_icon' style="font-size: 20px!important;"
      matTooltipClass='sectionGoaeTT' matTooltip='Position entfernen' matTooltipPosition='above'>remove</mat-icon>
    </div> -->
    
  </div>
  
  <span class="toolbar-spacer"></span>
  <mat-icon (click)='close()' class='justification_arrow_icon' style="font-size: 20px!important;">close</mat-icon>
</mat-toolbar>
<div class="container">
  <div class="d-flex flex-column" style="height: 100%; width: 100%;">
    <div class="p-0" *ngIf="newFactor">
      
      
      <div class="d-flex">
        <div class="p-2" style="margin-top: 10px;">
          <p class='mat-caption'>Neuer Faktor</p>
        </div>
        <div class="p-2" style="width: 80px;">
          <input class="formControl mat-body formControlDescription fs14-400" [disabled]='!editable' placeholder="10.0"
            [value]='newFactorValue' maxlength="5" (change)='newFactor_changed($event)'>
        </div>
      </div>
      
    </div>
    <div class="p-0 flex-fill">
      <textarea style="width:100%; height: 100%; background-color:rgba(0,0,0,0);  resize: vertical !important;"  
      rows="4" cols='2' class="mat-body formControl fs14-400" (change)='justificatio_changed($event)'
      [value]='copy' [disabled]='!editable'  >
      </textarea>
    </div>
  </div>
</div>

<mat-toolbar class="overlay-toolbar">
  <div class="d-flex w100 justify-content-center">
    <div class="p-2 ">
      <app-df-icon-button
      title= "Speichern"
      icon="save"
    
      color="primary"
      (clicked)="save()"      
      >
      </app-df-icon-button>  
    </div>
  </div>

</mat-toolbar>