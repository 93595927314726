import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isValid, lastDayOfMonth } from 'date-fns';


@Component({
  selector: 'app-simple-time-selection',
  templateUrl: './simple-time-selection.component.html',
  styleUrls: ['./simple-time-selection.component.scss']
})
export class SimpleTimeSelectionComponent implements OnInit {

  @ViewChild('_hour') _hour:ElementRef;
  @ViewChild('_minute') _minute:ElementRef;
  // @ViewChild('_year') _year:ElementRef;

  hour:number =0
  minute:number = 0

  dateTime = new Date();
  

  _hourValue = 1;
  _minuteValue = 1;
  
 
  hourControl: FormControl
  minutesControl: FormControl
  
  
  constructor(private dialogRef:MatDialogRef<SimpleTimeSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Date) {
      this.dateTime = new Date(data);
      this.hour = this.dateTime.getHours()
      this.minute = this.dateTime.getMinutes()    
      this._hourValue = this.hour
      this._minuteValue = this.minute
   }

  ngOnInit(): void {
    this.hourControl = new FormControl(this.dateTime.getHours(), [Validators.required, Validators.max(24), Validators.min(0)])
    this.minutesControl = new FormControl(this.dateTime.getMinutes(),[Validators.required, Validators.max(59), Validators.min(0)])
    // this.yearControl = new FormControl(this.dateTime.getFullYear(), [Validators.required, Validators.max(2030), Validators.min(1900)])
  }
  
  onHourInput(e){
    if(parseInt(e.target.value) != NaN && this.hourControl.valid){
      let _value = parseInt(e.target.value)
      this._hourValue = _value;
      // setTimeout(()=>{
      //   this.validateDay()
      // },100)
    }
   
  }

  onMinuteInput(e){
    
    if(parseInt(e.target.value) != NaN){
      let _value = parseInt(e.target.value)
      this._minuteValue = _value;
    }
    // this.validateDay()
  }

  // onYearInput(e){
    
  //   if(parseInt(e.target.value) != NaN){
  //     let _value = parseInt(e.target.value)
  //     this._yearValue = _value;
  //   }
  //   this.validateDay()
  // }



  validate(){
    // if(this.hourControl.valid){
    //   var _date = new Date();
    // // _date.setFullYear(this._yearValue, this._monthValue,  this._dateValue)
   
    //   if(isValid(_date) && this.hourControl.valid && this.minutesControl.valid){
    //     this.dateTime = _date;
    //   } else {
    //     this.dateTime = null
    //   }
    // } else {
    //   this.dateTime = null
    // }
      
   
  }
  validateDay(){
  //   var _date = new Date();
    
  //   let _lastDayOfMonth = lastDayOfMonth(_date).getDate();
  //   this.dayControl = new FormControl(this._dateValue, [Validators.required, Validators.max(_lastDayOfMonth), Validators.min(1)])
  //   if(isValid(_date)){
  //     //this.dateTime = _date;
  //   }
  //  setTimeout(()=>{
  //   if(this.dayControl.valid){
  //     this.validate();
  //   }
  //  },100)
  }

  hourChanged(e){
    var _date = new Date();
    _date.setHours(this._hourValue, this._minuteValue, 0,0)

    if(isValid(_date)){
      this.dateTime = _date;
    }
    this.validate()
  }

  minutesChanged(e){
    var _date = new Date();
    _date.setHours(this._hourValue, this._minuteValue, 0,0)

    if(isValid(_date)){
      this.dateTime = _date;
    }
    this.validate()
  }
  
  onHourFocus(){
    setTimeout(()=>{
      this._hour.nativeElement.select();
    },80)
    
  }
  onMinuteFocus(){
    this._minute.nativeElement.select();
  }
  
  close(){
    this.dialogRef.close()
  }
  save(){
    if(this.hourControl.valid && this.minutesControl.valid ){
      this.validate();
    }
    setTimeout(()=>{
      // console.log(this.dateTime.toISOString())
     /*  this.dateTime.setHours(0,0,0,0);*/
      this.dialogRef.close(this.dateTime); 
    },100)
    
  }
  get isSaveDisabled():boolean {
    return this.hourControl.invalid
  }
}
