import { Component, Input, OnInit } from '@angular/core';
import { TreatmentRegions, TREATMENT_FILTER, TREATMENT_FILTER_LEVEL } from 'src/app/core/models/treatmentRegionM';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { UiService } from 'src/app/services/ui.service';
import * as _ from "lodash";
@Component({
  selector: 'behandlungsregion-comp',
  templateUrl: './behandlungsregion-comp.component.html',
  styleUrls: ['../templateHeader.scss']
})
export class BehandlungsregionCompComponent implements OnInit {
  // selectedRegion
  @Input() title: string
  @Input() filter_level: TREATMENT_FILTER_LEVEL
  @Input() filters: TREATMENT_FILTER[]

  constructor(
    public ui:UiService,
    public tc:TemplateControllerService,
    ) { }

  ngOnInit(): void {

  }
  get selectedRegion():number{
    if(this.tc.activeTemplate && this.tc.activeTemplate.treatment_region_id) {
      return this.tc.activeTemplate.treatment_region_id 
      // this.ui.treatmentRegions.find( item => item.key === this.tc.activeTemplate.treatment_region).key
    } else {
      return 0
    }
  }
  
  get currentFilter(){
    switch(this.filter_level){
      case TREATMENT_FILTER_LEVEL.FIRST:
        
        return this.filters.find(item => item.id == this.tc.activeTemplate.treatmentfilter_first)
        case TREATMENT_FILTER_LEVEL.SECOND:
        
        return this.filters.find(item => item.id == this.tc.activeTemplate.treatmentfilter_second)
        case TREATMENT_FILTER_LEVEL.THIRD:
        
          return this.filters.find(item => item.id == this.tc.activeTemplate.treatmentfilter_third)
    }
    // return this.filters.find(item => this.tc.activeTemplate.)
  }
  get treatmentFilters(){
    return _.orderBy(this.filters, [
      filterItem => filterItem.filter.toLowerCase()
    ], ['asc']);
  }
  get treatmentRegions():TreatmentRegions[]{
    // const _activeExpertises = _.map(this.ui.fachgebiete.filter(item => item.is_active),'id')
    // return this.ui.treatmentRegions2.filter(item => _activeExpertises.includes(item.fachgebiet_id))
    if(this.ui.fachgebiet) { 
      return this.ui.treatmentRegions2.filter(item => item.fachgebiet_id == this.ui.fachgebiet.fachgebiet_id)
    } else {
      return []
    }
  }
  treatmentFilterSelect(treatmentFilter){
    console.log(treatmentFilter.value)
    switch(treatmentFilter.value.filter_level){
      case 'FIRST':
        this.tc.updateTemplateItem({treatmentfilter_first: treatmentFilter.value.id})
        this.tc.activeTemplate.treatmentfilter_first = treatmentFilter.value.id
        return
      case 'SECOND':
        this.tc.updateTemplateItem({treatmentfilter_second: treatmentFilter.value.id})
        this.tc.activeTemplate.treatmentfilter_second = treatmentFilter.value.id
        return
      case 'THIRD':
        this.tc.updateTemplateItem({treatmentfilter_third: treatmentFilter.value.id})
        this.tc.activeTemplate.treatmentfilter_third = treatmentFilter.value.id
        return
    }
    // const _index = this.ui.treatmentRegions2.findIndex(item => item.id==region.value)
    // if(_index != -1){
    //   console.log(this.ui.treatmentRegions2[_index])
      
    //   this.ui.fachgebiet = this.ui.fachgebiete.find(item => item.fachgebiet_id == this.ui.treatmentRegions2[_index].fachgebiet_id)
    //   this.tc.updateTemplateItem({fachgebiet_id: this.ui.treatmentRegions2[_index].fachgebiet_id})
    //   // if(this.tc.activeTemplate.fachgebiet_id != this.ui.treatmentRegions2[_index].fachgebiet_id){
    //   // }
    // } else {
    //   console.log('%cERRPR INDEX', 'color:red')
    // }
    // // console.log(region)
    // // const _newValue = this.ui.treatmentRegions2.find( item => item.key === region.value)
    // if(region && region.value){
    //   this.tc.activeTemplate.treatment_region_id = region.value
    //   this.tc.updateTemplateItem({treatment_region_id: region.value})
    // }
  }
  selectRegion(region){
    // // console.log(region)
    // // console.log(this.ui.treatmentRegions2.findIndex(item => item.id==region.value))
    // const _index = this.ui.treatmentRegions2.findIndex(item => item.id==region.value)
    // if(_index != -1){
    //   console.log(this.ui.treatmentRegions2[_index])
      
    //   this.ui.fachgebiet = this.ui.fachgebiete.find(item => item.fachgebiet_id == this.ui.treatmentRegions2[_index].fachgebiet_id)
    //   this.tc.updateTemplateItem({fachgebiet_id: this.ui.treatmentRegions2[_index].fachgebiet_id})
    //   // if(this.tc.activeTemplate.fachgebiet_id != this.ui.treatmentRegions2[_index].fachgebiet_id){
    //   // }
    // } else {
    //   console.log('%cERRPR INDEX', 'color:red')
    // }
    // // console.log(region)
    // // const _newValue = this.ui.treatmentRegions2.find( item => item.key === region.value)
    // if(region && region.value){
    //   this.tc.activeTemplate.treatment_region_id = region.value
    //   this.tc.updateTemplateItem({treatment_region_id: region.value})
    // }
  }

}
