import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MaterialI, SectionMaterialI } from '../core/models/materialI';
import { SectionM } from '../core/models/sectionM';
import { SECTION_API_PATH, SECTION_ENDPOINT } from './enums/sectionEndpoints.enum';


@Injectable({
  providedIn: 'root'
})
export class SectionApiService {

  private _endpoint:string = environment.endpoints.apiBasePathV2
  constructor(private http:HttpClient) { }




   async getSectionByTemplateId(prefix: string, docId:number){ 
    var sections:SectionM[] = []
    var arrItems:any[] = []
    arrItems = await this.sectionsGET(prefix, SECTION_ENDPOINT.SECTIONS_BY_T_ID, docId.toString()) as any[]
    arrItems.forEach(_item =>{
      
      // var og:OrGroupCollection[] = [] 

      sections.push({
        //orSection: null,//_item.orGroups != null && _item.orGroups != 'null' && _item.orGroups != 'NULL' ? new OrSection(_item.orGroups, _item.section_id) : new OrSection(null, _item.section_id),
        section_id: _item.section_id,
        //  orGroups: og,
        template_id: _item.template_id,
        order_index: _item.order_index,
        treated_at_difference_days: _item.treated_at_difference_days,
        name: _item.name,
        date: _item.date,

      })
    })
    return sections;  
  }


  async createSection(prefix: string, section: SectionM) {
    const _path = `${this._endpoint}${prefix}/sections/create/`
    return await this.http.post(_path, {section:section}).toPromise()
    .then(
      _data => {
        
        return _data
      }
    )
  }


  async moveSectionToTemplate(prefix: string, section_id:number, template_id:number) {
    const _path = `${this._endpoint}templates/sections/move-to-template/${section_id}/${template_id}`
    const _added = await this.http.get(_path).toPromise().then(
      _data => {return _data}
    )
    return _added;
  }
  

    /**
   * 
   * 
   * CUSTOM PROGRESSION FACTORS
   * 
   * 
   */


  // async getCustomProgressionFactorBySectionId(prefix: string, section_id:number){ 
  //   const _res = await this.http.get(`${this._endpoint}${prefix}/sections/custom-progression-factor-by-section/${section_id}`).toPromise().then(
  //     _data => { return _data; }
  //   )
  //   return _res;  
  // }


   /**
     * GET MATERIAL ITEMS THAT IN SECTION INCLUDET
     */
    async getSectionMaterialItems(prefix: string, section_id:number){
      const _res = await this.http.get<any[]>(
        `${this._endpoint}${prefix}/modules/material/by-section/${section_id}`).toPromise();
      return _res;  
    }

    /**
     * ADD MATERIAL ITEM TO SECTION
     */
    async addSectionMaterialItem(prefix: string,section_id:number, item:MaterialI){
      return await this.sectionsPOST(prefix, SECTION_ENDPOINT.SECTION_MAT_ITEM_ADD, {payload: item }, section_id.toString())
      // const _res = await this.http.post(`${this._endpoint}${prefix}/modules/material/add-to-section/${section_id}`,{payload:item}).toPromise();
        
      // return _res;
    }


  /**
   * UPDATE ITEM IN SECTION
   */
  async updateSectionMaterialItem(prefix: string, item:SectionMaterialI) {
    return await this.sectionsPOST(prefix, SECTION_ENDPOINT.SECTION_MAT_ITEM_UPDATE, {payload: item }, item.id.toString())
  }


  /**
   * REMOVE FROM SECTION
   */
  async deleteSectionMaterialItem(prefix: string, matItemId:number) {
    await this.sectionsGET(prefix, SECTION_ENDPOINT.SECTION_MAT_ITEM_DELETE, matItemId.toString());
  }



  async sectionsGET(prefix: string, call:SECTION_ENDPOINT, params:string = '') {
    const _path = SECTION_API_PATH(prefix, call, params)
    const _res = await this.http.get(_path).toPromise()
    return _res
  }
  async sectionsPOST(prefix: string, call:SECTION_ENDPOINT, item:Object, params:string = ''){
    const _path = SECTION_API_PATH(prefix, call, params)
    const _r =  await this.http.post(_path, item).toPromise()
    return _r
  }

}




