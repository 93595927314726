import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { differenceInHours } from 'date-fns';
import { AccountService } from 'src/app/services/account.service';
import { ACCOUNT_CALL } from 'src/app/services/enums/accountS.callEnum';
import { LocaleService } from 'src/app/services/locale.service';
import { UiService, session_item } from 'src/app/services/ui.service';

@Component({
  selector: 'app-subscription-plan-overlay',
  templateUrl: './subscription-plan-overlay.component.html',
  styleUrls: ['./subscription-plan-overlay.component.scss']
})
export class SubscriptionPlanOverlayComponent implements OnInit {

  showSubscriptionChangeButtons = false
  showSubscriptionRequestMessage = false
  
  constructor(
    public locales: LocaleService,
    public ui: UiService,
    private as: AccountService, 
    private http: HttpClient,
    private dialogRef: MatDialogRef<SubscriptionPlanOverlayComponent>,
    public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.hasActiveSubscriptionChangeRequest()
  }

  async onSubscriptionRequest(planTxt: string) {
    const dto = {
      planTxt
    }
    await this.as.apiPostObject(ACCOUNT_CALL.SUBSCRIPTION_REQUEST, {dto: planTxt})
    this.setActiveSubscriptionChangeRequest(planTxt)

    this.showSubscriptionRequestMessage = true;

    setTimeout(()=>{
      this.hasActiveSubscriptionChangeRequest()
      this.showSubscriptionRequestMessage = false
    },5000)
    // this.dialog.open(AlertOverlayComponent, {
    //   width: '400px',
    //   data: {
    //     title: this.locales.locales['SUBSCRIPTION_PLAN_INFO'].TITLE,
    //     bodyMessage: this.locales.locales['SUBSCRIPTION_PLAN_INFO'].BODY,
    //       actions: [
            
    //         {title: this.locales.locales['SUBSCRIPTION_PLAN_INFO'].ACTION_CANCEL, color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType: AlertActionI.BUTTON_ACTION_TYPE.CANCEL}
    //       ]
    //     },
    //   backdropClass: 'backdrop-background'
    // })
    // this.hasActiveSubscriptionChangeRequest()
  }

  private hasActiveSubscriptionChangeRequest()  {
    const info = JSON.parse(window.sessionStorage.getItem("sub-req"))
    // console.log(info);
    if(info) {
      const item = info as session_item
      const diff = differenceInHours(new Date(), new Date(item.dateTime))
      if(diff < 20) {

        this.showSubscriptionChangeButtons = false
      } else if(diff > 20) {
        this.showSubscriptionChangeButtons = true
        window.sessionStorage.removeItem("sub-req");
      } 
      
      
    } else {
      this.showSubscriptionChangeButtons = true
    }
  }
   private setActiveSubscriptionChangeRequest(subReq: string)  {
    window.sessionStorage.setItem("sub-req", JSON.stringify({dateTime: new Date().getTime(), info: subReq}));
  }

  close() {
    this.dialogRef.close()
  }
}
