import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IProcessState } from 'src/app/core/models/iProcessState';
import { OverviewProcessI } from 'src/app/core/models/v4/processI';
import { IProcessStatesOverlayData, IProcessStatesOverlayDataViewMode } from 'src/app/core/models/viewI/openProcessOverlayData';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { UiService } from 'src/app/services/ui.service';

interface StateHistory {
  color:string;
  view_value:string;
  dateTime:number
  message?:string;
  
  lastName?:string;
  firstName?:string;
}

@Component({
  selector: 'app-process-states-overlay',
  templateUrl: './process-states-overlay.component.html',
  styleUrls: ['./process-states-overlay.component.scss']
})
export class ProcessStatesOverlayComponent implements OnInit {
  
  VIEWMODES = IProcessStatesOverlayDataViewMode
  currentState: number = 0
  selectedIndex = -1
  selectedItem:IProcessState
  // isHistory = true
  viewMode: IProcessStatesOverlayDataViewMode = IProcessStatesOverlayDataViewMode.HISTORY
  history:StateHistory[] = []
  private currentProcess:OverviewProcessI
  constructor(
    public dialogRef: MatDialogRef<ProcessStatesOverlayComponent>, 
    public tc:TemplateControllerService,
    private ui: UiService,
    
    @Inject(MAT_DIALOG_DATA) public data: IProcessStatesOverlayData){
      // this.isHistory = data.//data.status_id < 5
      this.currentProcess = data.currentProcess;
      this.viewMode = data.viewMode,
      this.currentState = data.status_id;
      // this.selectedIndex = this.tc.processStates.findIndex((item)=>item.id == this.data.status_id)

  }
  ngOnInit(): void {
    if(this.currentState < 5) { 
      
      this.loadHistory()
    }
  }
  get title():string {
    return this.viewMode == IProcessStatesOverlayDataViewMode.HISTORY ? 'Verlauf' : 'Status aktualisieren';
  }
  showHistory(){
    this.viewMode = IProcessStatesOverlayDataViewMode.HISTORY
    this.loadHistory()
  }
  hideHistory(){
    if(this.ui.useAccounting === false){
      this.viewMode = IProcessStatesOverlayDataViewMode.STATES_VIEW
    } else {

      if(this.currentState < 5) { return }
      this.viewMode = IProcessStatesOverlayDataViewMode.STATES_VIEW
    }
  }
  itemSelect(index:IProcessState){
    console.log(index);
    this.selectedItem = index
    // this.selectedIndex = index
  }

  async loadHistory(){
    const _res = await this.tc.processStateHistory(this.currentProcess.template_id).then(
      _data => {
        return _data
      }
    )
    this.history = _res as StateHistory[]
    console.log(_res)
  }

  private saveModeEnabled(state: IProcessState): boolean {
    if(this.ui.isAccountant && state.id ==1) {
      return true
    } else {
      if(!this.ui.processFilterInclude(state)){
        this.ui.toggleProcessFilter(state, true)
        return true
      } else {
        return true
      }
    }
  }

  async save(){
    const processSate = this.selectedItem != null ? this.selectedItem : null
    if(!processSate) { return }
    
    // if(this.ui.isAccountant && processSate.id ==1) {

    // } else {
    //   if(!this.ui.processFilterInclude(processSate)){
    //     this.ui.toggleProcessFilter(processSate, false)
    //   }
    // }
    if(this.saveModeEnabled(processSate)) {
      setTimeout(() => {
        const dto = {
          message: `Geändert in ${processSate.view_value}`,
          assigned_role: "ACCOUNTANT",
          assignee_uid: this.ui.uid
        }
        this.tc.processStateChanged(processSate.id, this.currentProcess.template_id, dto);
        this.dialogRef.close('OVERVIEW')
      }, 200)
    } else {
      this.dialogRef.close('OVERVIEW')
    }
    

    
  }
  close(){
    this.dialogRef.close();
  }
  get availableStates() :IProcessState[]{
    let geht:number[] = [10,6,8,9,5]
    return this.tc.processStates.filter(item => geht.includes(item.id)).sort((a,b) => a.order_index - b.order_index)
  }
}
