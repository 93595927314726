<div class="container-fluid">

  <div class="container-fluid p_t20" *ngIf="template.type === 'DOXFOX'">
      <div class="row pl20 pr20" *ngIf="showSurgeryInfos">
        <div class="col-12" *ngIf='surgeryInfosInit'>
          <app-surgery-header>
          </app-surgery-header>
        </div>
      </div>

      <div>
        <div class="row p_b10 " *ngFor="let section of surgerSectionsAll; let i = index;" >

          <div class='col-12 p_t10' >
            <div class="container-fluid p_b60" >

              <div class="row" style="height: 100%;">

                <div class="col-md-auto tlc pl30">
                  <div class="timeline-circle">{{i+1}}</div>
                  <div class="timeline-line"></div>
                </div>

                <div class="col pr20">
                  <div class="d-flex" >
                    <div class="p-2 mr-auto">
                      <p style="color:#1B242B; font-weight:400; font-size:12px; margin-bottom: 0px;">{{getDateFromSeconds(section) | date:'dd.MM.yyyy'}}</p>
                      <p style="color:#1B242B; font-weight:500; font-size:14px; padding-top: 5px;">{{section.name}}</p>
                    </div>

                    <div class="p-2 pr0" *ngIf="showPrintButtons && showButtonType == 'FINALIZED_BUTTON' && i == 0">
                      <mat-icon class="pl0 ml0 pr0 unlockButton" (click)='unlockReport()'>lock_open</mat-icon>
                    </div>

                    <div class="p-2 tle pr0" *ngIf="showPrintButtons && showButtonType == 'FINALIZED_BUTTON' && i == 0">
                      <button mat-flat-button color="primary" class="m_b15" (click)="showSurgeryReport()">
                        <mat-icon style="font-size: 14px;  margin-right: 4px;" class="pl0 ml0"  inline=true>picture_as_pdf</mat-icon>
                        <span  inline=true>OP-Bericht ansehen</span>
                      </button>
                    </div>

                    <div class="p-2" *ngIf="showPrintButtons && showButtonType == 'PREVIEW_BUTTON' && i == 0" >
                      <div matTooltipPosition="above" [matTooltipDisabled]="surgeryReportCreateBtnEnabled"  matTooltipClass="tt_cantCreate" matTooltip="{{createSurgeryReportButtonTooltipText}}">
                        <button mat-flat-button color="primary" (click)="surgeryPdfPreview()" [disabled]="!surgeryReportCreateBtnEnabled" >
                          <mat-icon style="font-size: 14px;  margin-right: 4px;" class="pl0 ml0"  inline=true>picture_as_pdf</mat-icon>
                          <span  inline=true>OP-Bericht erstellen</span>
                        </button>
                      </div>
                    </div>
                  </div>


                  <div class="d-flex">
                    <!-- [showAllSurgeryRelevantItems]='showAllSurgeryRelevantItems' -->
                    <mat-card style="width: 100%;" class="p0 m0 mat-card-no-shadow">
                      <template-timeline-op-section-item
                      [editable]='editable'
                      [section]='section' [templateDocumentId]='template.documentId'
                      style="width: 100%;" ></template-timeline-op-section-item>
                    </mat-card>
                  </div>

                  <div class="d-flex" >
                    <div class="p-2 mr-auto">
                    </div>

                    <div class="p-2 pr0" *ngIf="showPrintButtons && showButtonType == 'FINALIZED_BUTTON' && i == surgerSectionsAll.length -1">
                      <mat-icon class="pl0 ml0 pr0 unlockButton" (click)='unlockReport()'>lock_open</mat-icon>
                    </div>

                    <div class="p-2 tle pr0" *ngIf="showPrintButtons && showButtonType == 'FINALIZED_BUTTON' && i == surgerSectionsAll.length -1">
                      <button mat-flat-button color="primary" class="m_b15" (click)="showSurgeryReport()">
                        <mat-icon style="font-size: 14px;  margin-right: 4px;" class="pl0 ml0"  inline=true>picture_as_pdf</mat-icon>
                        <span  inline=true>OP-Bericht ansehen</span>
                      </button>
                    </div>

                    <div class="p-2 tle pr0" *ngIf="showPrintButtons && showButtonType == 'PREVIEW_BUTTON' && i == surgerSectionsAll.length -1">
                      <div matTooltipPosition="above" [matTooltipDisabled]="surgeryReportCreateBtnEnabled"  matTooltipClass="tt_cantCreate" matTooltip="{{createSurgeryReportButtonTooltipText}}">
                        <button mat-flat-button color="primary" (click)="surgeryPdfPreview()" [disabled]="!surgeryReportCreateBtnEnabled" >
                          <mat-icon style="font-size: 14px;  margin-right: 4px;" class="pl0 ml0"  inline=true>picture_as_pdf</mat-icon>
                          <span  inline=true>OP-Bericht erstellen</span>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
          </div>
      </div>
  </div>

  <div *ngIf="template.type === 'SCS'"  class="container-fluid"  >
      <div class="row p_t10 p_b40">
        <div class='col-12' >
          <div class="d-flex">
            <div class="p-2 mr-auto">
              <p style="color:#98A8B3; font-weight:600; font-size:18px; padding-top: 12px;">BEFUND</p>
            </div>

            <div class="p-2" *ngIf="showPrintButtons">
              <button mat-button color="basic" (click)="scsPdfPreview()">
                <mat-icon style="font-size: 16px;  margin-right: 4px; color: #7C8B96;" class="pl0 ml0" inline=true>picture_as_pdf</mat-icon>
                <span style="color: #7C8B96;" inline=true>Vorschau Befundungsbericht</span>
              </button>
            </div>
            <div class="p-2" *ngIf="showPrintButtons" matTooltip="under construction">
              <!--TODO FINAL SURGERY REPORT WRONG-->
              <button mat-flat-button color="primary" (click)="finalReport()" disabled>

                <mat-icon style="font-size: 14px;  margin-right: 4px;" class="pl0 ml0"  inline=true>lock</mat-icon>
                <span  inline=true>Fertigstellen</span>
              </button>
            </div>
          </div>
          <div class="d-flex ">
            <mat-card style="width: 100%;" class="p0 m0">
              <template-scs-report-view
                      [isPublicTemplate]='editable'
                      [befund_id]='template.befund_id'
                      [templateDocumentId]='template.documentId'
              ></template-scs-report-view>
            </mat-card>
          </div>

        </div>
      </div>

  </div>

</div>