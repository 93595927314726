import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControllerService } from 'src/app/shared/controller.service';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { DxFxBreadCrumb } from 'src/app/core/models/breadCrumpIntemI';
import * as _ from "lodash";
import { TemplateApiService } from 'src/app/services/template-api.service';
import { TemplateFlatI } from 'src/app/core/models/api/TemplateFlatI';
import { MatDialog } from '@angular/material/dialog';
import { AlertOverlayComponent } from 'src/app/ui/overlays/alert-overlay/alert-overlay.component';
import { AlertActionI } from 'src/app/core/models/alertM';
import { UiService } from 'src/app/services/ui.service';
import { Subscription } from 'rxjs';
import { ProcessesSort } from 'src/app/core/models/enums';
import { ProcessStatesOverlayComponent } from 'src/app/ui/overlays/process-states-overlay/process-states-overlay.component';
// import { ProcessFilterType } from 'src/app/core/models/accountI';
import { MatChipList } from '@angular/material/chips';
import { IPROCESS, OverviewProcessI } from 'src/app/core/models/v4/processI';
import { IProcessState } from 'src/app/core/models/iProcessState';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { TEMPLATES_CALL } from 'src/app/services/enums/templateS.callEnum';
import { OpenProcessComponentOverlayComponent } from 'src/app/ui/overlays/open-process-component-overlay/open-process-component-overlay.component';
import {  IOpenProcessOverlayData, IProcessChangeOverlayCloseAction, IProcessChangeOverlayData, IProcessChangeOverlayDataViewMode, IProcessStatesOverlayData, IProcessStatesOverlayDataViewMode } from 'src/app/core/models/viewI/openProcessOverlayData';
import { ProcessStageChangeOverlayComponent } from 'src/app/ui/overlays/process-stage-change-overlay/process-stage-change-overlay.component';
import { IOProcessStateMessage, SocketService } from 'src/app/services/socket.service';
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';
import { LocaleService } from 'src/app/services/locale.service';

@Component({
  selector: 'app-processes-overview',
  templateUrl: './processes-overview.component.html',
  styleUrls: ['./processes-overview.component.scss'],
  animations: [
    trigger('processListAnimation', [
      transition('* => *', [ // each time the binding value changes
        // query(':leave', [
        //   stagger(1, [
        //     animate('0.05s ease-in', style({ opacity: 0 }))
        //   ])
        // ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(65, [
            animate('0.25s ease-out', style({ opacity: 1, marginTop: 0 }))
          ])
        ], { optional: true })
      ])
    ])
  ]
})
export class ProcessesOverviewComponent implements OnInit, OnDestroy{

  @ViewChild(MatSort, {static:true}) sort: MatSort;
  @ViewChild('chipList') chipList: MatChipList;
  processesDataSource:any
  displayedColumns: string[] = ['type','status','patientDisplayName','name','hasInvoice', 'hasSurgeryReport', 'date', 'summary', 'edit'];
  isLoading = true;
  userPath:string
  processSortSubscription:Subscription
  ioSubscription:Subscription
  ioSubscription2:Subscription
  filterSubscription:Subscription
  // processFilters = ProcessFilterType
  activeFilters:IProcessState[] = []

  constructor(
    private dialog:MatDialog,
    public ui: UiService,
    private templateAPI:TemplateApiService,
    private router:Router,
    public tc:TemplateControllerService,
    private ss: SocketService,
    public locale:LocaleService
    ) { }

  ngOnDestroy(): void {
    if(this.processSortSubscription != null){
      this.processSortSubscription.unsubscribe();
    }
    if(this.filterSubscription != null){
      this.filterSubscription.unsubscribe();
    }
    if(this.ioSubscription != null){
      this.ioSubscription.unsubscribe();
    }
    if(this.ioSubscription2 != null){
      this.ioSubscription2.unsubscribe();
    }
    this.tc.overviewTemplatesFilterWith('')
  }


  ngOnInit(): void {
    setTimeout(()=>{ 
      ControllerService.preloadOverlay.emit(true);
    },100)
    
   this.activeFilters = this.ui.processFilter
  //  this.tc.loadPState()
   this.ui.loadProcessFilters()
   if (this.ui.accountingAvailable) {
    this.ui.loadAccounting()
   }
  
    setTimeout(()=>{
      ControllerService.setCurrentPage('vorgaenge');
      // ControllerService.preloadOverlay.emit(true);
        let _bc:DxFxBreadCrumb = {
          bcItems: [
            {title:'Meine Leistungen',path:''},
          ]
        }
        ControllerService.setBreadCrumbPath(_bc);
        this._initProcesses();

        // console.log(this.tc.processStates)
    },800);
    this.ioSubscription = this.ss.socketProcessStateEmitter.subscribe(
      (_message: IOProcessStateMessage) => {
        console.log("_message: IOProcessStateMessage",_message);
        this.tc.processStateChangedEmitted(_message, this.ui.processFilter, this.ui.isAccountant)
      }

    )
    this.ioSubscription2 = this.ss.socketProcessAddedEmitter.subscribe(
      (_message: number) => {
        console.log("_message: socketProcessAddedEmitter",_message);
        this.tc.loadNewProcess(_message, this.ui.processFilter, this.ui.isAccountant)
      }

    )
  }


  getStatusUser(process:IPROCESS) : string {
    if(this.ui.useAccounting) {

      if(this.ui.isAccountant) {
        if(process.assignee_uid == this.ui.uid) {
          // return "für mich"
          return ''
        }
        if(!process.assignee_uid || process.assignee_uid == "") {
          return `von: ${process.creatorFirstName ?? ''} ${process.creatorLastName ?? ""}`  
        } else {
          return `von: ${process.assigneeFirstName ?? ''} ${process.assigneeLastName ?? ""}`  
        }
      } else {
       
        if(process.assignee_uid == this.ui.uid) {
          // return "für mich"
          return ''
        } else {
          return `${process.assigneeFirstName ?? ''} ${process.assigneeLastName ?? ""}`  
        }
        
      }
    } else {
      return ''
    }

    // let index = this.ui.processStatesAccount.findIndex(item => item.template_id == process.template_id)
    // if(index != -1) {
    //   let item = this.ui.processStatesAccount[index]
    //   return `${item.firstName} ${item.lastName}`
    // } else {
    //   return ''
    // }
  }
 
  getStatus(process:OverviewProcessI) : IProcessState {
    let p:IProcessState = {
      id: process.status_id,
      color: process.status_color,
      description: process.state_description,
      list_value: process.status_list_value,
      view_value: process.status_view_value,
      order_index: 0
    }
    return p
    let index = this.ui.processStatesAccount.findIndex(item => item.template_id == process.template_id)
    if(index != -1) {
      let item = this.ui.processStatesAccount[index]
      let p:IProcessState = {
        id: item.status_id,
        color: item.color,
        description: item.message,
        list_value: item.status_list_value,
        view_value: item.status_view_value,
        order_index: 0
      }
      return  p
    } else {
      let p:IProcessState = {
        id: process.status_id,
        color: process.status_color,
        description: '',
        list_value: process.status_list_value,
        view_value: process.status_view_value,
        order_index: 0
      }
      return p
    }
  }


  //----------------------------------------------------------------------------------------
  get tableIsEmpty(): boolean {
    return this.tc.processes == null || this.tc.processes.length == 0
  }
  
  //----------------------------------------------------------------------------------------
  private async _initProcesses(){
    if(this.processSortSubscription != null){
      this.processSortSubscription.unsubscribe();
     }

     if(this.filterSubscription != null){
      this.filterSubscription.unsubscribe();
     }

     this.filterSubscription = this.ui.processFilterchangedEmitter.subscribe(
      _filters => {
        this.activeFilters = _filters
        // console.log("filterSubscription changed")
        this._initProcesses();
     });

     this.processSortSubscription = this.ui.processesSortChanged.subscribe(
       _sort => {
        // console.log("processSortSubscription changed")
          this.sortProcesses(_sort)
       }
     )
     //const processes = 
     await this.tc.processesFiltered(TEMPLATES_CALL.PROCESSES_OVERVIEW,{filter: this.ui.activeProcessFilterIds})
    //  await this.templateAPI.apiPostObject(TEMPLATES_CALL.PROCESSES_OVERVIEW,{filter: this.ui.activeProcessFilterIds})
    //  .then(
    //    _data => {return _data as OverviewProcessI[]}
    // );

      // console.log(this.processes)
    //  if(processes.length > 0){
    //    this.processes = processes;

    //   this.sortProcesses(this.ui.processesOverviewSort)

    // } else if(processes.length == 0){
    //   // this.tableIsEmpty= true;
    // }

    setTimeout(()=>{
      this.sortProcesses(this.ui.processesOverviewSort)
      ControllerService.preloadOverlay.emit(false);
      this.isLoading = false;
    },50);

  }

  
  //----------------------------------------------------------------------------------------
  openProcess(e, process:OverviewProcessI) {
    e.stopPropagation();
    e.preventDefault();

   console.log(process)
    // return
   
    let isOwner = process.creator == this.ui.account.uid
    let useAccounting = this.ui.accountingInUse; //this.ui.isAccountant// || this.ui.account?.hasAccountant
    if (useAccounting) {
      
      if (process.status_id == 1) {

        // der arst selber kann die vorlage öffnen
        if(isOwner) {
          this.routeToProcess(process.template_id)
        } else {
          // ... nur der arzt selber kann ...
          ControllerService.customSnack.emit({
            panelClass: "customSnack",
            duration: 5000,
            message: "Nur der Inhaber kann diese Leistung bearbeiten"
          })
        }

      } else {

        // der status ist nicht entwurf

        if(process.status_id == 2) {
        
         // open dialog status auf 1 sezten
          if(this.ui.isAccountant) {
            this.routeToProcess(process.template_id)
          } else {
            this.resetToEntwurf(process)
            // open overlay//// 
            /// arzt muss den Status auf 1 == entwurd zurürcksetztn
          }

        } else if (process.status_id == 3) {
          if(process.assignee_uid == this.ui.account.uid) {
            this.routeToProcess(process.template_id)
          } else {
            if (isOwner) {
              this.openErrorMessage(process, 'readonly')
              return
            } else {
               this.openErrorMessage(process, 'accessdenied')
              return
            }
          
          }
        } else if (process.status_id == 4) {
          if(process.assignee_uid == this.ui.account.uid) {
            this.routeToProcess(process.template_id)
          } else {
            if (isOwner) {
              this.openErrorMessage(process, 'readonly')
              return
            } else {
               this.openErrorMessage(process, 'accessdenied')
              return
            }
          
          }
        } else if (process.status_id >= 5) {
          if(process.assignee_uid == this.ui.account.uid) {
            this.routeToProcess(process.template_id)
          } else {
            if (isOwner) {
              this.openErrorMessage(process, 'readonly')
              return
            } else {
              if(this.ui.isAccountant) {
                this.routeToProcess(process.template_id)
              }
            }
          }
        }
        else {

        }
       
      }

   

     } else {
      // console.log("OPEN PROCESS NO ACCOUNTANT AND NOT ACCOUNTING")
        this.routeToProcess(process.template_id)
     } 
    
    // 1. welche Rolle hab ich - arzt, accountent
    // 2. welchen status hat der Prozess und wer ist der Eigentümer (uid letzter status)
    // 3. und nu?????

  

   
// return
    // e.stopPropagation();
    // e.preventDefault();
    // this.router.navigate(['vorgaenge/detail', process.template_id],)
  }

  private openErrorMessage(process:OverviewProcessI, type: string) {
    if (type == 'readonly') {
      var bodyMessage = this.locale.locales['PROCESS_READ_ONLY_ALERT']['BODY_MESSAGE']
      bodyMessage = _.replace(bodyMessage, 'XXX', `${process.assigneeFirstName} ${process.assigneeLastName}`)
      const data: IOpenProcessOverlayData = {
        title: "",
          body: bodyMessage,
        resumeBtn: this.locale.locales['PROCESS_READ_ONLY_ALERT']['OPEN_BTN'],
        cancelBtn: this.locale.locales['PROCESS_READ_ONLY_ALERT']['CANCEL_BTN'],
        process: process
      }
      this.cantOpenProcess(data)
    } else if (type == 'accessdenied') {
        var bodyMessage = this.locale.locales['PROCESS_ACCESS_DENIED_ALERT']['BODY_MESSAGE']
        bodyMessage = _.replace(bodyMessage, 'XXX', `${process.assigneeFirstName} ${process.assigneeLastName}`)
        const data: IOpenProcessOverlayData = {
          title: "",
            body: bodyMessage,
          // resumeBtn: this.locale.locales['PROCESS_READ_ONLY_ALERT']['OPEN_BTN'],
          // cancelBtn: this.locale.locales['PROCESS_READ_ONLY_ALERT']['CANCEL_BTN'],
          process: process
        }
        this.cantOpenProcess(data)
        return
    }
  }
  logAnimation(_event) {
    // console.log(_event)
  }
  async resetToEntwurf(process: OverviewProcessI) {
    // console.log('\n reset to entwurf')
    // return
    let data: IProcessChangeOverlayData = {
      windowTitle: "Leistung erneut öffnen?",
      windowBodyMessage: `Falls Sie diese bereits übergebene Leistung erneut bearbeiten möchten, wird der Status wieder auf <strong>Entwurf</strong> gesetzt. `,
      viewMode: IProcessChangeOverlayDataViewMode.RESET_SHARE_TO_ACCOUNTING,
      closeAction: IProcessChangeOverlayCloseAction.OPEN_TEMPLATE,
      // old_assignee_uid: '',
      // new_assignee_uid: '',
      old_assignee_uid: process.assignee_uid,
      new_assignee_uid: process.assignee_uid,
      template_id: process.template_id,
      oldStatus_id: 2,
      newStatus_id: 1,
      role: 'DOC',
      errors: []
    }
    this.dialog.open(ProcessStageChangeOverlayComponent,{
      disableClose: true, 
      width: "80%",
      maxWidth: "900px",
      height: "250px",
      data: data,
      backdropClass:"backdrop-background"
    }).afterClosed().subscribe(
      _message => {
        if(_message && _message == "OPEN_TEMPLATE"){
          this.router.navigate(['vorgaenge/detail', process.template_id]);
        }
      }
    )
  }
  private cantOpenProcess(data:IOpenProcessOverlayData) {
    console.log('cantOpenProcess')
    this.dialog.open(OpenProcessComponentOverlayComponent,{
      // disableClose: true, 
      width: "80%",
      maxWidth: "700px",
      height: "250px",
      data:  data,
      backdropClass:"backdrop-background"
    }).afterClosed().subscribe(
      _message => {
        if(_message && _message == 'SHOW_READ_ONLY') {
          this.routeToProcess(data.process.template_id)
        }
      }
    )
  }
  private routeToProcess(pid: number) {
    this.router.navigate(['vorgaenge/detail', pid])
  }
  //----------------------------------------------------------------------------------------
  showSubMenu(process:OverviewProcessI):boolean {
    if(this.ui.accountingInUse){
      let isOwner = process.creator == this.ui.account.uid
      if(process.status_id == 1 && isOwner){
        return true;
      } else {
        return false
      }
    } else {
      return true;
    }
  }
  //----------------------------------------------------------------------------------------
  deleteProcess(process:OverviewProcessI){

    const dialogReg = this.dialog.open(AlertOverlayComponent, {
      width:"400px",
      disableClose:true,
      data:  {
        title:"Vorlage löschen",
        bodyMessage:"Sind Sie sicher, dass Sie diesen Vorgang löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
        actions: [
          {
            title:'Fortfahren', color: AlertActionI.BUTTON_COLOR_TYPE.WARN, actionType:AlertActionI.BUTTON_ACTION_TYPE.RESUME
          },
          {
            title:'Abbrechen', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType:AlertActionI.BUTTON_ACTION_TYPE.CANCEL
          }
        ]
      },
      backdropClass:"backdrop-background"
    }).afterClosed().subscribe((result) => {

      if(result != null){
        if(result === AlertActionI.BUTTON_ACTION_TYPE.RESUME){
          this.finalDeleteProcess(process)
        } else {
        }
      }
    });
  }

  private async finalDeleteProcess(process:TemplateFlatI){
    const _index = this.tc.processes.findIndex(item => item.template_id == process.template_id);
    if(_index != -1){
      this.tc.processes.splice(_index, 1)
    }
    await this.templateAPI.deleteProcess(process.template_id)
    this._initProcesses();
  }

  //----------------------------------------------------------------------------------------
  changeStat(e, template:OverviewProcessI){
    
    e.stopPropagation();
    e.preventDefault();
  
    const _overlayData: IProcessStatesOverlayData = {

      viewMode: IProcessStatesOverlayDataViewMode.HISTORY,
      status_id: template.status_id,
      currentProcess: template
    }

    this.dialog.open(ProcessStatesOverlayComponent, {
      width: '740px',
      height: '530px',
      backdropClass:"backdrop-background",
      data: _overlayData
    }).afterClosed().subscribe(
      //  (_newState:IProcessState) => {
      //    if(_newState != null){

      //      template.status_id = _newState.id
      //      template.status_color = _newState.color
      //      template.status_view_value = _newState.view_value
      //     // this.changeProcessState(template.documentId, _newState.title)

      //    }
      //  }
    )
  }

  getDisplayName(t:OverviewProcessI):string {
    if(this.ui.isClinicUser) { return t.clinicProcessNumber != null ?
      t.clinicProcessNumber.toString() : ""}
    var _out = ''

    // if(t.patient) {
    //   if(t.patient.lastName) {
    //     _out += t.patient.lastName
    //     _out += " "
    //   }
  
    //   if(t.patient.firstName) {
    //     _out += t.patient.firstName
  
    //   }
    // }

    if(t.lastName) {
      _out += t.lastName
      _out += " "
    }

    if(t.firstName) {
      _out += t.firstName

    }
    return _out
  }

  subOpen(e){
    e.stopPropagation();
    e.preventDefault();
  }

//----------------------------------------------------------------------------------------
private sortProcesses(pSort:ProcessesSort){
  this.tc.sortProcesses(pSort, this.ui.isClinicUser)
}

//----------------------------------------------------------------------------------------
applyFilter(filterValue: string) {
  this.tc.overviewTemplatesFilterWith(_.trim(filterValue))
  // if(filterValue.length == 0) {
  //   this._initProcesses();
  //   return
  // }
  // this.tc.processes = _.filter(this.tc.processes,
  //   item => item.firstName?.toLowerCase().includes(filterValue.toLowerCase())
  //   || item.lastName?.toLowerCase().includes(filterValue.toLowerCase())
  //   || item.name.toLowerCase().includes(filterValue.toLowerCase())
  //   || item.clinicProcessNumber?.toString().includes(filterValue.toLowerCase())
  //   )
}



}

  // getStateColor(status:string):string {
  //   var out = '#BFCCD5'
  //   switch(status){
  //     case "Entwurf":
  //       return '#BFCCD5';
  //     case "Versandbereit":
  //       return '#1976D2';
  //     case "Versendet":
  //       return '#FFD466';
  //     case "Teilzahlung":
  //       return "#A9F0BF"
  //     case "Bezahlt":
  //       return '#3EC667';
  //     case "Gemahnt":
  //       return '#FA4E12';
  //     default:
  //       return out
  //   }
  //   return out
  // }