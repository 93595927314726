import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { GOA_SMALL } from 'src/app/core/models/goaeM';
import { PositionsHelper, ProgressionFactorItem, PositionFlatI, PositionDragStartConf, OR_TYPE, OR_MARGIN_TYPE, PositionFlatIGroup, OR_EDITIN_MODULE, ORPosFlatCollections, ORPosFlat } from 'src/app/core/models/sectionPositionM';
import {AlertActionI } from 'src/app/core/models/alertM';
import { SectionM } from 'src/app/core/models/sectionM';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { AlertOverlayComponent } from 'src/app/ui/overlays/alert-overlay/alert-overlay.component';
import { AlertService } from 'src/app/services/alert.service';
import { AddCustomPositionSelectI } from 'src/app/core/models/addCustomPositionSelectI';
import { SelectPositionCustomItemComponent } from 'src/app/ui/overlays/select-position-custom-item/select-position-custom-item.component';
import * as _ from "lodash";
import { BilligItemI } from 'src/app/core/models/billing_itemI';
import { MaterialI } from 'src/app/core/models/materialI';
import { TemplateControllerService } from 'src/app/services/template-controller.service';
import { BASE_TEMPLATE_TYPE, POSITION_TYPE } from 'src/app/core/models/enums';
import { Subscription } from 'rxjs';
import { FullPositionOverlayData, TEXT_FOCUS_ELEMENT } from 'src/app/core/models/final/textoverlay.model';
import { TemplatePositionOverlayComponent } from 'src/app/ui/components/template-position-overlay/template-position-overlay.component';
import { UiService } from 'src/app/services/ui.service';

enum PositionDragInfo {
  INDENTED = "INDENTED",
  OR_MODE = "OR_MODE",
  NONE = "NONE"
}

declare var TweenMax: any;
@Component({
  selector: 'template-position-item',
  templateUrl: './template-position-item.component.html',
  styleUrls: ['../positions.scss', './template-position-item.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplatePositionItemComponent implements OnInit, OnDestroy {

  // @ViewChild('_tableContainer') _tableContainer:ElementRef;
  // @ViewChild('_goaTF') _goaTF:ElementRef;

  @Input() templateDocumentId: string;
  @Input() section:SectionM;
  @Output() deletePositonAtIndex = new EventEmitter();

  POSITION_TYPES = POSITION_TYPE

  private _isDragging = false;
  TABLEVIEW = false;
  showPreloader = true
  filteredOptions;//: GOA_SMALL[] = [];
  goae_list_small:GOA_SMALL[] = [];
  summery:number = 0;
  resizeTimeout: any;
  splitTimeOut:any = null
  deletePositonSubscription:any
  progression_factor

  isOrModeStarted:boolean = false;
  orModeEditInfoMessage:string =''
  
  orModeMessageTimeout:any
  errorMessageTimeout:any
  positionSubscription:Subscription

  isLoading = true;
  positionJustifications = true; // TODO check if or why needed
  progression_factors:ProgressionFactorItem[] = []


  get isPublicTemplate(): boolean {
    return !this.tc.isTemplateEditable
  }

  positionDragBase:PositionDragStartConf[] = []

  constructor(
    private ui: UiService,
    private dialog:MatDialog,
    public tc: TemplateControllerService,
    private alertService:AlertService) { }

    ngOnInit() {
      this.section = this.section;
  
      if(this.section.materialItems == null){this.section.materialItems = []} else {
        for (var i = 0; i < this.section.materialItems.length; i++) {
          let mat = this.section.materialItems[i];
          if(mat.quantity == null){
            mat.quantity = 1
          }
        }
      }
  
     // this.positions = this.tc.positionForSectionId(this.section.section_id)
      this.progression_factors = this.tc.progressionFactors
      setTimeout(()=>{
        this.initGroups()
        this.isLoading = false;
        this.positionSubscription = this.tc.sectionPositionChanged.subscribe(
          _incomingsectionId => {
            if(_incomingsectionId == this.section.section_id){
              this.initGroups()
              // console.log("_incomingsectionId ", _incomingsectionId)
            }
          }
        )
      },100)
    }

    ngOnDestroy(): void {
    this.orEditMod = null
    if(this.positionSubscription != null) {
      this.positionSubscription.unsubscribe()
      }
    }

    errorMessage = 'null';


   private _positionGroups: PositionFlatIGroup[] = []

   private orEditMod: OR_EDITIN_MODULE = {
    isEditingStartet: false,
    orGroup: null,
    startIndex: 999,
    startPosId: 999,

    lastIndex: 999,
    lastPosId: 999,
  }

  OR_TYPES = OR_TYPE
  OR_MARGIN_TYPES = OR_MARGIN_TYPE

  private _localPositions:PositionFlatI[] = []

  get isOrEditing() : boolean {
    return this.orEditMod.isEditingStartet
  }

  getCurrentJustificationIndex(p: PositionFlatI): number {
    var _latestMod
    var currentIndex = 0
    const pp = this.tc.positionForId(p.position_id)
    // var _goaJustifications = p.justifications ? p.justifications : []
    var _goaJustifications = pp.justifications ? pp.justifications : []
    for(var i = 0; i < _goaJustifications.length; i++) {
      const jstf = _goaJustifications[i]
      if(!_latestMod){
        _latestMod = jstf.lastModified
      }
      if(jstf.lastModified > _latestMod){
        _latestMod = jstf.lastModified
        currentIndex = i
      }
    }
    return currentIndex
  }


  private initGroups(){
    console.log('initGroups ---------');
    
    this._positionGroups = []
    var _usedPosIds = []
    let _ourGroupIndex = 0
    this._localPositions =  _.cloneDeep(this.tc.positionForSectionId(this.section.section_id)).sort((a,b)=> a.position_order_index - b.position_order_index)
    //this.section.positions
    let _positions = this._localPositions
    // console.log('_positions', _positions);

    for(var i = 0; i < _positions.length; i ++) {
      const _p = _positions[i]
      if(!_usedPosIds.includes(_positions[i].position_id)) {
        // if(_p.indented == false && _p.or_mode == false) {
          if(_p.orType == OR_TYPE.NONE) {
            // console.log('\n------>>>>>> _p.orType == OR_TYPE.NONE');
          this._positionGroups.push({
            id: _ourGroupIndex,
            order_index: _ourGroupIndex,
            positions: [_positions[i]],
            isOrGroup: false,
            items:[]
          })
          _usedPosIds.push(_positions[i].position_id)
          _ourGroupIndex ++
          // console.log('\n _p.orType == OR_TYPE.NONE <<<<<<------ENDE \n');
        } else {
   
          if (_p.startNewOrCollection) {

            // console.log('\n------>>>>>> startNewOrCollection');
            const _startIndex = _p.position_order_index;
            
            const _lastItemInCollection = _positions.find(item => item.position_order_index > _startIndex &&  (item.startNewOrCollection == true || item.orType == OR_TYPE.NONE))
            const _lastCollectionItemIndex = _lastItemInCollection ? _lastItemInCollection.position_order_index : 99999

            var _ogCollection: ORPosFlatCollections[] = []
            let __postns = _positions.filter(item => item.position_order_index >= _p.position_order_index && item.position_order_index < _lastCollectionItemIndex)

            var _lastAdded = __postns[0].position_order_index
            // console.log('__postns: ', __postns);
            for ( var l = 0; l < __postns.length; l ++) {

              // console.log(`CURRENT POS ${__postns[l].position_order_index} | ${__postns[l].position_id}`)
              var orItems:ORPosFlat[] = []

              // console.log('_lastAdded: ', _lastAdded);
              const _lastItemInOr = __postns.find(item => item.position_order_index > _lastAdded && (item.orType == OR_TYPE.OR || item.orType == OR_TYPE.NONE))
              let _lastItemIndexOr = _lastItemInOr ? _lastItemInOr.position_order_index : -1
              // console.log('_lastItemIndexOr: ', _lastItemIndexOr)

              let __indentedPositions:PositionFlatI[] = []
              if(_lastItemIndexOr == -1){
                __indentedPositions = _positions.filter(item => item.orType == OR_TYPE.INDENTED && item.position_order_index > _lastAdded)
                // console.log("ÏNDENTED ", __indentedPositions)
                const _lastIndented = _.maxBy(__indentedPositions, item => item.position_order_index)
                // console.log('_lastIndented ', _lastIndented);

                _lastItemIndexOr = _lastIndented != null ? _lastIndented.position_order_index + 1 : 99
                // console.log('_lastItemIndexOr: ', _lastItemIndexOr);
              }
              //.filter(pos => pos.position_order_index == _lastAdded)
              const _positionsTemp = _lastItemIndexOr == -1 ? [__postns[l]] :
              __postns.filter(pos => pos.position_order_index >= _lastAdded && pos.position_order_index < _lastItemIndexOr)
              // console.log('_positionsTemp: ', _positionsTemp);

              _positionsTemp.forEach(posTemp => {
                _usedPosIds.push(posTemp.position_id)
                orItems.push({
                  order_index: posTemp.position_order_index,
                  position_id: posTemp.position_id,
                })
                _ogCollection.push({
                  items: orItems
                })
              } )
              // console.log('orItems ', orItems);
              _lastAdded = _lastItemIndexOr//_.maxBy(_positionsTemp, function(o) { return o.position_order_index}).position_order_index
            }
            this._positionGroups.push({
              id: _ourGroupIndex,
              order_index: _ourGroupIndex,
              positions: __postns.sort((a,b)=> a.position_order_index - b.position_order_index),
              isOrGroup: true,
              items: _ogCollection
            })
            _ourGroupIndex ++
            // console.log("__postns ", __postns)
            // console.log('\n<<<<<------ ');
          }
        }
      }
    }

    // this.positionGroupsInitial = _.cloneDeep(this._positionGroups)
    setTimeout(()=>{
      this.showPreloader = false
    },500)
  }

  get positions(): PositionFlatI [] {
    return this.tc.positionForSectionId(this.section.section_id).sort((a,b)=> a.position_order_index - b.position_order_index)
       //this.section.positions//
  }

  get positionGroups(): PositionFlatIGroup[] {
    if(this.orEditMod.isEditingStartet === true){
      return this._positionGroups
    }
    return this._positionGroups
  }

  quantityChanged(p:PositionFlatI, val:number){
    this.tc.updateSinglePositionItem(this.tc.currentTemplateId, p.position_id, {quantity: val})
    p.quantity = val;
    // this.save(p);
  }

  async activateSugeryRelevantTapped(p:PositionFlatI, index:number){
    console.log('', p);
    await this.tc.updateSinglePositionItem(this.tc.currentTemplateId, p.position_id, {surgery_relevant: p.surgery_relevant})
    //this.save(p);
  }

  hasGoaError(pos:PositionFlatI):boolean {
    return this.tc.errorItems.findIndex((item) => item.position_id == pos.position_id) != -1
  }

  getErrorTooltip(p:PositionFlatI):string {
    if(this.hasGoaError(p)){
      const index = this.tc.errorItems.findIndex((item) => item.position_id == p.position_id)
      if(index != -1){
        return this.tc.errorItems[index].errorMessage
      } else {
        return "ERROR"
      }
    } else {
      return "ERROR"
    }
  }


  /***
   *
   *      ACTIVATE BUTTON TAPPED
   *
   */
  async activateToggleTapped(group:PositionFlatIGroup,p:PositionFlatI) {

    let _isSelected:boolean = p.activated;

    if(p.orType == OR_TYPE.NONE){
      this.saveActiveState(p)
      return
    }
    console.log('group ', group);

    if(_isSelected) {
      let ids = []
      group.items.forEach(item => {
        console.log('group > item', item);
        if(item.items.findIndex(itemElement => itemElement.position_id == p.position_id) == -1){
          item.items.forEach(id => {
            ids.push(id.position_id)
          })
        }
      })
      var _postn = group.positions.filter(item => ids.includes(item.position_id))

      for(const _p of _postn){
        _p.activated = false
        // await this.save(p);
      }
      _postn.push(p)
      await this.saveActiveStates(_postn)
      // console.log('' , _postn);
      // const _items = group.items.filter(item => item.items.i)
    } else {
      this.saveActiveState(p)
    }

  }

  getGoaText(p:PositionFlatI):string{
    if(p.position_type == POSITION_TYPE.OWN){
      return p.own_long
    } else if(p.goa_paragraph == null){
      return ''
    } else {
      return p.goa_paragraph.long_description
    }
  }

  getCheckboxColor(p:PositionFlatI):string {
    return this.hasGoaError(p) ? 'warn' : 'primary'
  }

  actions(action:string){

  }

  addCustomPositionFor(type:string, p:PositionFlatI = null){
    let _type:AddCustomPositionSelectI.CustomPositonType
    switch(type){
      case 'goae':
        _type = AddCustomPositionSelectI.CustomPositonType.GOA;
      break;
      case 'own':
        _type = AddCustomPositionSelectI.CustomPositonType.OWN; 
      break;
      case 'material':
        _type = AddCustomPositionSelectI.CustomPositonType.MATERIAL;
      break;
    }

    let  _modalDate:AddCustomPositionSelectI = {
      type: _type
    }

    this.dialog.open(SelectPositionCustomItemComponent,{
      width:"600px",
      // height:"400px",
      data:_modalDate,
      backdropClass: "backdrop-background",
    }).afterClosed().subscribe(
      _newItem => {
        console.log('\nHIER!!!!');
        console.log(_newItem);
        
        
        if(_newItem != null) {
          switch(type){
            case 'goae':
              if(p!= null) {
                p.position_type = POSITION_TYPE.GOA;
                p.goa_paragraph = _newItem.goa_paragraph
                p.quantity = 1
                p.example = _newItem.goa_paragraph.short_description
                let f = this.progression_factors.find((item)=>item.factor == p.progression_factor);
                if(f == null){
                  p.progression_factor = 2.3;
                }
                console.log("HIER")
                // this._startGOA_CHECK();
                this.saveNewGoaePosition(p)
              } else if(p==null){
                console.log("HIER p ==== null")
                
                this.addNewGoaePosition(_newItem)
              }
            break;
            case 'own':
              if(p!= null) {
                // console.log("HIER")
                p.position_type = POSITION_TYPE.OWN;
                // p.ownBillingItem = _newItem
                 p.example = _newItem.short_description;
              } else if(p==null){
                // console.log("HIER p ==== null")
                // _newItem = BilligItemI
                this.addNewOwnPosition(_newItem)
              }
            break;
            case 'material':
              this.addMaterialItem(_newItem)
            break;
          }
        }
      }
    )
  }

  private async addNewGoaePosition(p:PositionFlatI){

    let _lastIndex = this.positions.length > 0 ? this.positions.length  : 0;
    let _id = this.getNewIdForPosition();
    if(_lastIndex > 0){
      const _prevIndex = _lastIndex -1
      let prevP:PositionFlatI = this.positions[_prevIndex];
    }
    p.position_order_index = _id;
  
    p.example = p.goa_paragraph.short_description;
    p.section_id = this.section.section_id;
    p.template_id = this.section.template_id;

    await this.tc.addNewPosition(this.tc.prefix, p, this.tc.currentTemplateId)
    setTimeout(()=>{
      this.tc.reloadPositionsBySectionId(p.section_id, this.tc.prefix)
    },150)
    
  }

  private async saveNewGoaePosition(p:PositionFlatI){
    await this.tc.updatePosition(p, this.tc.currentTemplateId);

  }

  private async addNewOwnPosition(billigitem:BilligItemI) {
    let p:PositionFlatI = PositionsHelper.cleanPositionFlat(0,0);

    let _lastIndex = this.positions.length > 0 ? this.positions.length  : 0;
    let _id = this.getNewIdForPosition();
      // if(_lastIndex > 0){
      //   const _prevIndex = _lastIndex -1
      //   let prevP:PositionFlatI = this.positions[_prevIndex];
      //   if(prevP != null && this._isIndexInOrGroup(prevP.position_id)){
      //     p.starts_new_group = true
      //   }
      // }
      p.position_order_index =this.getNewIdForPosition()
      p.section_id = this.section.section_id;
      p.template_id = this.section.template_id;
      // p.position_id = _id;
      p.position_type = POSITION_TYPE.OWN
      // p.ownBillingItem = billigitem
      p.example = billigitem.short_description;
      p.own_billing_item_id = billigitem.billing_item_id
      await this.tc.addNewPosition(this.tc.prefix,p, this.tc.currentTemplateId)
      this.tc.checkForConflicts()

      setTimeout(()=>{
        this.tc.reloadPositionsBySectionId(p.section_id, this.tc.prefix)
      },150)
  }

  private async addMaterialItem(matItem:MaterialI){
    // this.isLoading = true;
    if(this.section.materialItems == null){
      this.section.materialItems = [];
    }

    if(matItem.quantity == null){
      matItem.quantity = 1
    }
    await this.tc.addSectionMaterialItem(this.tc.prefix,this.section.section_id, matItem)
    // await this.tc.getSectionMaterialItems(this.tc.prefix,this.section.section_id, true)
  }



  async deletePosition(group: PositionFlatIGroup, pPosition:PositionFlatI){
    const dialogReg = this.dialog.open(AlertOverlayComponent, {
      width:"400px",
      disableClose:true,
      data: {
        title:"Position löschen",
        bodyMessage:"Sind Sie sicher, dass Sie diese Position löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
          actions: [
            {title:'Löschen', color: AlertActionI.BUTTON_COLOR_TYPE.WARN, actionType:AlertActionI.BUTTON_ACTION_TYPE.RESUME},
            {title:'Abbrechen', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType:AlertActionI.BUTTON_ACTION_TYPE.CANCEL}
          ]
        },
      backdropClass:"backdrop-background"
    }).afterClosed().subscribe((result) => {

      if(result != null){
        if(result === AlertActionI.BUTTON_ACTION_TYPE.RESUME){
          this._finalDeletePosition(group, pPosition)
          this.deletePositonSubscription = null
        } else {
          this.deletePositonSubscription = null
        }
      }
    });
  }

  async _finalDeletePosition(group: PositionFlatIGroup, pPosition:PositionFlatI){
    this.showPreloader = true;
    // console.log(pPosition)
    // console.log(group)
    this._localPositions.sort((a,b) => a.position_order_index - b.position_order_index)

    const _start = pPosition.position_order_index > 0 ? pPosition.position_order_index - 1 : 0

    const index = this._localPositions.findIndex(item => item.pos_group_id == pPosition.pos_group_id)

    if(index != -1){
      this._localPositions.splice(index, 1)
    }

    await this.tc.deleteSinglePosition(pPosition.position_id, this.tc.currentTemplateId)

    for(var i = _start; i < this._localPositions.length; i ++ ) {
      this._localPositions[i].position_order_index = i
      await this.tc.updateSinglePositionItem(this.tc.currentTemplateId, this._localPositions[i].position_id, {position_order_index: i})
    }

    this.tc.reloadPositionsBySectionId(this.section.section_id, this.tc.prefix)
    return
  }

  async removeStartsNewGroup(p:PositionFlatI){
    p.starts_new_group = false
    await this.tc.updateSinglePositionItem(this.tc.currentTemplateId, p.position_id, {starts_new_group: false})
  }

  async addStartsNewGroup(p:PositionFlatI){
    p.starts_new_group = true
    await this.tc.updateSinglePositionItem(this.tc.currentTemplateId, p.position_id, {starts_new_group: true})
  }


  drop(event: CdkDragDrop<PositionFlatIGroup,PositionFlatIGroup>) {
    this._isDragging = false
    const oldIndex:number = event.previousIndex
    const newIndex:number = event.currentIndex

    if(oldIndex == newIndex){
      return
    }

    let dragedPosData:PositionFlatIGroup = event.item.data;
    if(dragedPosData == null){
      return;
    }

    this.showPreloader = true
    dragedPosData.positions.sort((a,b) => a.position_order_index - b.position_order_index)

    let _startFixingFromIndex = oldIndex < newIndex ? oldIndex : newIndex
    console.log('FIX ORDER FROM ',_startFixingFromIndex);

    const groups = this._positionGroups.filter(item => item.id != oldIndex)


    setTimeout(()=>{
      for(var i = _startFixingFromIndex ; i < this._positionGroups.length; i ++){
        this._positionGroups[i].order_index = i
      }
    },100)

    setTimeout(()=>{
      let _positions: PositionFlatI[] = []
      let _index = 0
      this._positionGroups.sort((a,b) => a.order_index - b.order_index)
      for(var i = 0 ; i < this._positionGroups.length; i ++){
        for ( var k = 0; k < this._positionGroups[i].positions.length; k ++) {
          this._positionGroups[i].positions[k].position_order_index = _index
          _positions.push(this._positionGroups[i].positions[k])
          _index ++
        }
      }
      this.updatePositionList(_positions)
    },150)


     moveItemInArray(this._positionGroups, event.previousIndex, event.currentIndex);
    // console.log('', this.positions);

    // this.tc.updatePositionBySectionId(this.section, {position_id: dragedPosData.position_id, position:dragedPosData, fromIndex:oldIndex, toIndex:newIndex},)
  }

  get isDragging():boolean {
    return this._isDragging
  }

  getNewIdForPosition():number{
    if(this.positions.length == 0){
      return 0;
    }
    let _tmpPos:PositionFlatI[] = [];
    _tmpPos = this.positions.map(x=>x);
    _tmpPos.sort((a, b) =>{
      return a.position_order_index - b.position_order_index;
    })
    let _latestId:number = _tmpPos[_tmpPos.length -1].position_order_index;
    const newId = _latestId += 1;
    return newId
  }

  // showAddGoaeInlineButtonForP(p:PositionFlatI):boolean{
  //   return p.position_type == POSITION_TYPE.NONE;
  // }

  // showAddOwnBillingItemInlineButtonForP(p:PositionFlatI):boolean{
  //   return p.position_type == POSITION_TYPE.NONE;
  // }

  showProgressionFactor(p:PositionFlatI):boolean {
    if(p.position_type == POSITION_TYPE.OWN){
      return false
    }
    return true;
  }

  getSummery(position:PositionFlatI): number {
    if(position.position_type == POSITION_TYPE.OWN){
      return 0.00
    }
    return (position.progression_factor * position.goa_paragraph.amount_euro) * position.quantity;
  }

  getSummery1(position:PositionFlatI) : number {
    console.log(position.justifications);
    const mmm = _.maxBy(position.justifications,  item => item.lastModified)
    console.log(mmm);
    
    
    if(position.position_type == POSITION_TYPE.OWN){
      return 0.00
    }
    
    return (position.progression_factor * position.goa_paragraph.amount_euro) * position.quantity
  }
  get itemDisabled():boolean {
    return !this.tc.isTemplateEditable || this.tc.templateHasInvoice
  }

  /**
   *
   * SAVEING
   *
   */

  private async saveActiveStates(pos:PositionFlatI[]){
     await this.tc.updateActiveStatesForPositions(this.tc.currentTemplateId, pos)
     setTimeout(()=> {
      this.tc.checkForConflicts()
    },200)
  }

  private async saveActiveState(p:PositionFlatI){
    await this.tc.updateActiveStatesForPositions(this.tc.currentTemplateId, [p])
     setTimeout(()=> {
      this.tc.checkForConflicts()
    },200)
    // await this.tc.updateSinglePositionItem(this.tc.currentTemplateId, p.position_id, {activated: p.activated})
    // setTimeout(()=> {
    //   this.tc.checkForConflicts()
    // },1000)

  }

  async save(p:PositionFlatI){
    /* for(var i = 0; i < this.positions.length; i++) {
      this.positions[i].errorModel = null;
        this.positions[i].hasError =false;
    } */

    await this.tc.updatePosition(p, this.tc.currentTemplateId);
    this.tc.checkForConflicts()

    if(this.section.templateHasInvoice){
      return
    }
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    if(this.isPublicTemplate){
      // ControllerService.showIsPublicTemplateAlertEmitter.emit(this.templateDocumentId)
      this.alertService.templateItemPositonWantEditTemplate(this.templateDocumentId)
      // this.alertService.clearWantEditPublicTemplate();
    } else {
      this.resizeTimeout = setTimeout((() => {
        //ControllerService.saveTemplate.emit(this.templateDocumentId)
      }).bind(this), 500);
    }
  }

  get buttonBarVisible():boolean{
    if (this.isOrEditing) return false
    if(this.tc.baseTemplateType == BASE_TEMPLATE_TYPE.TEMPLATE){
      return this.tc.isTemplateEditable
    } else {
      return !this.tc.templateHasInvoice && !this.tc.templateHasSurgeryReport && !this.ui.isAccountant
    }
  }

  /**
   *
   *
   * NEW OR STUFF
   *
   */

  resetOrSectionALert(group: PositionFlatIGroup, p: PositionFlatI) {
  if(this.isPublicTemplate){
    this.alertService.templateItemPositonWantEditTemplate(this.templateDocumentId)
    return
  }
  const dialogReg = this.dialog.open(AlertOverlayComponent, {
    width:"400px",
    disableClose:true,
    data: {
      title:"Oder-Sektion auflösen",
      bodyMessage: "Sie sind sicher, dass Sie die Oder-Sektion auflösen wollen? Diese Aktion kann nicht rückgängig gemacht werden.<br> <br>Einzelne Positionen können dann in der Reihenfolge geändert, gelöscht oder neue Positionen hinzugefügt werden.",
        actions: [
          {title:'Auflösen', color: AlertActionI.BUTTON_COLOR_TYPE.WARN, actionType:AlertActionI.BUTTON_ACTION_TYPE.RESUME},
          {title:'Abbrechen', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType:AlertActionI.BUTTON_ACTION_TYPE.CANCEL}
        ]
      },
    backdropClass:"backdrop-background"
  }).afterClosed().subscribe((result) => {

    if(result != null){

      if(result === AlertActionI.BUTTON_ACTION_TYPE.RESUME){
        this.resetOrSection(group,p)

      } else {

      }
    }
  });
  }


  private async resetOrSection(group: PositionFlatIGroup,p:PositionFlatI) {

    this.showPreloader = true
    let _positions: PositionFlatI[] = []
    _positions = _.cloneDeep(group.positions)
    _positions.forEach(_p => {
      _p.startNewOrCollection = false
      _p.orType = OR_TYPE.NONE
      // _p.has = false
      // _p.startNewOrCollection = false
    })
    setTimeout(()=>{
      this.updatePositionList(_positions)
    },100)


  }

  async startOrSection(group: PositionFlatIGroup, p:PositionFlatI) {
    this.showPreloader = true;
    //console.log('START OR SECTION EDITIN \n\n\n');
    // console.log('', group);
    // console.log('', this._positionGroups);
    let _positionsTemp = _.cloneDeep(this._localPositions).sort((a,b) => a.position_order_index - b.position_order_index)
    let _positions: PositionFlatI[] = []
    var _hasOrCollectionFound = false;
    for(var i = 0 ; i < _positionsTemp.length; i ++) {
      let _p = _positionsTemp[i];

      if (!_hasOrCollectionFound && _p.position_order_index >= p.position_order_index && _p.startNewOrCollection === true) {
        _hasOrCollectionFound = true
      } else {}

      if (!_hasOrCollectionFound && _p.position_order_index >= p.position_order_index && _p.orType == OR_TYPE.NONE && !_p.startNewOrCollection ){
        _positions.push(_p)
      }

    }

    if(_positions.length < 2){
      this.showAlert();
      return
    }

    let _psGroup = _.cloneDeep(group)
    _psGroup.positions = _positions
    this._positionGroups = [_psGroup]

    _psGroup.isOrGroup = true


    for(var i = 0; i < _psGroup.positions.length; i ++ ){
      if(i == 0) {
        _psGroup.positions[i].orType = OR_TYPE.OR
        _psGroup.positions[i].startNewOrCollection = true

        _psGroup.positions[i].orMarginType = OR_MARGIN_TYPE.NEW_BLOCK

        _psGroup.positions[i].activated = true

      }
      // else if(_positionTemp[i].position_id === lastPosId) {
      //   _positionTemp[i].orType = OR_TYPE.NONE
      //   _positionTemp[i].orMarginType = OR_MARGIN_TYPE.NONE

      // }
      else {
        _psGroup.positions[i].orType = OR_TYPE.OR
        _psGroup.positions[i].orMarginType = OR_MARGIN_TYPE.NONE

        _psGroup.positions[i].activated = false
        _psGroup.positions[i].startNewOrCollection = false
      }
    }
    // this.orEditMod = {

    //   // OR_GROUP_THIRD
    //   orGroup: {
    //     startIndex: 0,///startIndex,
    //     positions: [],//_OR_POSITION_THIRD
    //   },

    //   isEditingStartet: true

    // }

    setTimeout(() => {
      this.orEditMod.isEditingStartet = true;
      this.showPreloader = false;
    },120)
    // const _groups = this._positionGroups.sort((a,b) => a.order_index - b.order_index)
    // const _groups = this._positionGroups.filter(item => item.isOrGroup == false).sort((a,b) => a.order_index - b.order_index)
    // console.log(`_groups: ${_groups} count: ${_groups.length}`);
    console.log('-----------------------------\n');

    // const sliceIndex = _groups.
    // findIndex(item => item.order_index >= group.order_index && (item.isOrGroup === true || item.order_index === _groups[_groups.length -1].order_index))
    // if(sliceIndex == -1) {
    //   return
    // }



    // console.log('sliceIndex', sliceIndex);
    // let posIds: number[] = []
    // _groups.forEach(pGroup => {
    //   if(pGroup.order_index >= group.order_index && pGroup.order_index <= sliceIndex){
    //     pGroup.positions.forEach(pPos => {
    //       posIds.push(pPos.position_id)
    //     })

    //   }
    // })
    // console.log('posIds ', posIds);

    // group.positions = this.positions.filter(item => posIds.includes(item.position_id)).sort((a,b)=>a.position_order_index-b.position_order_index)

    // console.log('sliceIndex', sliceIndex);
    // console.log('group', group);



    // const posCount = this.positions.filter(item => item.position_order_index >= p.position_order_index).length
    // this.OR_POSITIONS.splice(sliceIndex, posCount)

    // console.log('> ', this.OR_POSITIONS);
    // const _psGroup = _.cloneDeep(group)
    // this._positionGroups = [_psGroup]
    return

    // TODO ERROR ?




    // _psGroup.isOrGroup = true
    // // if(this.orEditMod) {

    //   if(this.orEditMod.isEditingStartet === true) {
    //     // TODO ERROR ?
    //     return
    //   } else {

    //     this.createNewCleanEditMod(_psGroup, p)

    //     setTimeout(() => {
    //       this.orEditMod.isEditingStartet = true;
    //     },120)

    //   }
      // craeate now clean or collection
  }

  positionOrModeChanged(e, p:PositionFlatI){
    console.log('> ', e);
    console.log('> ', p);

    // if(p.orType == OR_TYPE.INDENTED) {
    //   p.or_mode = false
    //   p.indented = true
    // } else {
    //   p.or_mode = true
    //   p.activated = false
    //   p.indented = false

    // }

  }

  async endORSection(group: PositionFlatIGroup, p:PositionFlatI, isLastItem: boolean = false){
    this.showPreloader = true

    group.positions.sort((a,b) => a.position_order_index - b.position_order_index)

    const sliceIndex = group.positions.findIndex(item => item.position_id == p.position_id)
    if(sliceIndex == -1) { return }

    let posCount = group.positions.filter(item => isLastItem ? (item.position_order_index > p.position_order_index) : item.position_order_index >= p.position_order_index).length

    group.positions.forEach(_pos => {
      if(_pos.position_order_index > p.position_order_index){
        _pos.orType = OR_TYPE.NONE
        _pos.startNewOrCollection = false
      }
    })
    group.positions.splice(sliceIndex, posCount)

    setTimeout(()=>{
      let orGroupStartPositions = group.positions.filter(pos => pos.orType === OR_TYPE.OR ).sort((a,b) => a.position_order_index - b.position_order_index)
      let indentedPositions = group.positions.filter(pos => pos.orType === OR_TYPE.INDENTED ).sort((a,b) => a.position_order_index - b.position_order_index)

      for ( var i = 0; i < orGroupStartPositions.length; i ++ ){

        if(i == 0) {
          orGroupStartPositions[i].startNewOrCollection = true
        } else {
          orGroupStartPositions[i].startNewOrCollection = false
        }
        let _lastIndex = orGroupStartPositions[i+1] != null ? orGroupStartPositions[i+1].position_order_index : group.positions[group.positions.length -1].position_order_index
          let indented = indentedPositions.filter(indentedPos => indentedPos.position_order_index >= orGroupStartPositions[i].position_order_index &&  indentedPos.position_order_index <= _lastIndex)

          indented.forEach(indentedItem => {
            indentedItem.hasOrModeTrenner = false

          })

          if(i == 0){
              orGroupStartPositions[i].hasOrModeTrenner = false
          } else {
            orGroupStartPositions[i].hasOrModeTrenner = true
          }
      }

    },100)


    setTimeout(()=>{
      this.updatePositionList(group.positions)
    },250)

  }

  private async updatePositionList(positionList:PositionFlatI[]) {

    // await this.tc.updateSectionOrGroupsFromController(this.section.section_id, groups)

    let _count = 1
    for( var j = 0; j < positionList.length; j ++){
       this.tc.updatePosition(positionList[j], this.tc.activeTemplate.template_id).then(x => {
        if(_count == positionList.length){

          setTimeout(()=>{
            this.startReloading()
          },250)
        } else {
          _count ++
        }

      })

    }


  }
  
  private async startReloading(){
    this.orEditMod.isEditingStartet = false
    this.tc.reloadPositionsBySectionId(this.section.section_id, this.tc.prefix)
  }

  showAlert() {
    const dialogReg = this.dialog.open(AlertOverlayComponent, {
      width:"400px",
      disableClose:true,
      data: {
        title:"",
        bodyMessage: "Bitte fügen Sie erst weitere Positionen hinzu",
          actions: [
            {title:'OK', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType:AlertActionI.BUTTON_ACTION_TYPE.RESUME},
            // {title:'Abbrechen', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType:AlertActionI.BUTTON_ACTION_TYPE.CANCEL}
          ]
        },
      backdropClass:"backdrop-background"
    }).afterClosed().subscribe((result) => {

      this.orEditMod.isEditingStartet = false
      setTimeout(()=>{
        this.initGroups()
      },250)

    });
  }

  // TEXT EDIT OVERLAY

  FOCUS_ELMENTS = TEXT_FOCUS_ELEMENT

  openFullPusitionOverlay(position: PositionFlatI, focuselement: TEXT_FOCUS_ELEMENT) {
    // console.log(position.justifications);
    // console.log(_.maxBy(position.justifications, item => item.lastModified));
    // console.log(_.maxBy(this.tc.positionForId(position.position_id).justifications, item => item.lastModified));

    if(!this.tc.isTemplateEditable) { return }
    var overlayData: FullPositionOverlayData = {
      position: this.tc.positionForId(position.position_id),
      // position: position,
      textfocusElement: focuselement,
    };
    // console.log(overlayData);
    // return;
    this.dialog.open(TemplatePositionOverlayComponent, {
      // disableClose: true, 
      backdropClass:"backdrop-background",
      width: "80%",
      maxWidth: "1200px",
      height: '80%',
      maxHeight: '900px',
      data: overlayData
    })
    .afterClosed().subscribe(x => {
      // console.log(_.maxBy(position.justifications, item => item.lastModified));
      // console.log(_.maxBy(this.tc.positionForId(position.position_id).justifications, item => item.lastModified));
      this.initGroups()
    })
  }

}






  // customFactorsByPos(position_id:number):CustomProgressionFactorI[] {
  //   return this.customProgressFactors.filter(item => item.position_id == position_id);
  // }

  // getPositionProgressionFactors(position_id:number):PositionProgressionFactors[] {
  //   var _all:PositionProgressionFactors [] = []
  //   this.progression_factors.forEach(_item => {
  //     _all.push({
  //       id: _item.id,
  //       isPublic: true,
  //       factor: _item.factor,
  //       view_value: _item.view_value
  //     })
  //   })
  //   this.customFactorsByPos(position_id).forEach(_customItem => {
  //     _all.push({
  //       isPublic: false,
  //       factor: _customItem.factor,
  //       id: _customItem.id,
  //       view_value: _customItem.view_value
  //     })
  //   })
  //   return _all
  // }

  // progressionFactorChanged(changeItem:PositionProgressionFactorChanged){
  //   this.tc.updateSinglePositionItem(this.tc.currentTemplateId, changeItem.position.position_id, {progression_factor: changeItem.factor})
  //   setTimeout(()=>{
  //     this.checkProgressFacts()
  //   },100)
  // }
