<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <mat-icon  class='justification_arrow_icon' style="font-size: 20px!important;" (click)="close()">close</mat-icon>
</mat-toolbar>

<div class="container-fluid" style="height: calc(100% - 88px)">
  

<!-- PATIENT -->
<div class="container-fluid" *ngIf="patientEdit">
  <app-patient-modify 
  (onExistingUserModified)="onExistingUserModified($event)"
  [viewMode]="currentViewMode"
  [healthEnsurances]="healthEnsurances"
  [patient]="patientFull"
  ></app-patient-modify>
</div>

<div class="container" *ngIf="patientError">
  <div class="alert alert-danger d-flex" role="alert" *ngIf="patientFull">
    <div class="p-2 fs-md18 ">
      Patienten angaben fehlerhaft!
    </div>
    <div class="p-2 ml-auto">
      <mat-icon  class='dxfx_icon_btn_mini_warn'  (click)="editPatient()">edit</mat-icon>
    </div>
  </div>
  <div class="alert alert-danger d-flex" role="alert" *ngIf="!patientFull">
    <div class="p-2 fs-md18 ">
      Patient fehlt!
    </div>
    <div class="p-2 ml-auto">
      <mat-icon  class='dxfx_icon_btn_mini_warn'  (click)="close()">close</mat-icon>
    </div>
  </div>
</div>



<div class="container-fluid" *ngIf="!patientEdit && !patientError">
  <div class="row">
    <div class="col-12">
      <!-- ADDRESS showAddress && -->
      <div class="container" *ngIf="address">
        <div class="row pl10 p_t15 pr10">
  
          <div class="col-lg-6 col-md-6 col-sm-12">
            <mat-label class='mat-caption'>Name der Praxis/Klinik</mat-label>
            <input class="formControl mat-body" #_klinikName
            [class.formControlError]="address.klinikName == '' || address.klinikName == null"
            [(ngModel)]="address.klinikName" [disabled]="ui.isClinicUser">
          </div>
  
        
        </div>
  
  
  
        <div class="row pl10 p_t15 pr10">
          <div class="col-lg-5 col-md-5 col-sm-12">
            <mat-label class='mat-caption'>Behandelnde(r) Arzt/Ärztin</mat-label>
            <input class="formControl mat-body formControlDisabled" disabled [(ngModel)]="account.docName">
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6">
            <mat-label class='mat-caption'>Facharztbezeichnung</mat-label>
            <input class="formControl mat-body formControlDisabled" disabled [(ngModel)]="account.medical_specialty_designation">
          </div>
  
        </div>
  
        <div class="row pl10 p_t15 pr10" >
          <!-- *ngIf="!padNextFachBereichSelected" -->
  
          <div class="col-lg-3 col-md-4 col-sm-12">
            <mat-label class='mat-caption'>Fachgebiet</mat-label>
            <mat-form-field appearance="outline" class="textfield100 select-form-field" style="margin-top: -5px!important">
              <mat-select [(value)]="padNextFachBereichSelected" (selectionChange)='fachbereichCHanged($event)'>
                <mat-option *ngFor="let g of padNextFachFull" [value]="g">{{g.title}}</mat-option>      
              </mat-select>
            </mat-form-field>
          </div>
    
          <div class="col-lg-4 col-md-5 col-sm-12" *ngIf="padNextFachBereichSelected">
            <mat-label class='mat-caption'>Facharztbezeichnung</mat-label>
            <mat-form-field appearance="outline" class="textfield100 select-form-field" style="margin-top: -5px!important">
              <mat-select [(value)]="padNextFachArztBereichSelected" (selectionChange)='fachArztCHanged($event)' >
                <mat-option *ngFor="let g of padNextItemsSelected" [value]="g">{{g.title}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
    
          
        </div>
        <div class="row pl10 p_t15 pr10">
  
          <div class="col-lg-4 col-md-4 col-sm-9">
            <mat-label class='mat-caption'>Straße </mat-label>
            <input class="formControl mat-body" [disabled]="ui.isClinicUser"
            [class.formControlError]="address.streetName == '' || address.streetName == null"  [(ngModel)]="address.streetName">
          </div>
          <div class="col-lg-2 col-md-2 col-sm-9">
            <mat-label class='mat-caption'>Hausnummer</mat-label>
            <input class="formControl mat-body" [class.formControlError]="address.streetNumber == '' || address.streetNumber == null"  [(ngModel)]="address.streetNumber" [disabled]="ui.isClinicUser">
          </div>
  
          <div class="col-lg-2 col-md-2 col-sm-6">
            <mat-label class='mat-caption'>PLZ</mat-label>
            <input class="formControl mat-body" [class.formControlError]="address.zip == '' || address.zip == null"  [(ngModel)]="address.zip" [disabled]="ui.isClinicUser">
          </div>
  
          <div class="col-lg-4 col-md-4 col-sm-6">
            <mat-label class='mat-caption'>Stadt</mat-label>
            <input class="formControl mat-body" [class.formControlError]="address.city == '' || address.city == null"  [(ngModel)]="address.city" [disabled]="ui.isClinicUser">
          </div>
  
        </div>
  
        <div class="row pl10 p_t25">
          <div class="col-6">
            <h2>Bankverbindung</h2>
          </div>
        </div>
  
        <div class="row pl10 p_t15 pr10">
  
          <!-- <div class="col-lg-6 col-md-6 col-sm-12">
            <mat-label class='mat-caption'>Kontoinhaber/in</mat-label>
            <input class="formControl mat-body" [(ngModel)]="address.kontoinhaber" [disabled]="ui.isClinicUser">
          </div> -->
  
          <div class="col-lg-6 col-md-6 col-sm-12">
            <mat-label class='mat-caption'>USt-IdNr.</mat-label>
            <input class="formControl mat-body" [(ngModel)]="address.ust_nr" >
            <!-- [class.formControlError]="address.ust_nr == '' || address.ust_nr == null" [disabled]="ui.isClinicUser" -->
          </div>
  
        </div>
  
        <!-- <div class="row pl10 p_t15 pr10 p_b20">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <mat-label class='mat-caption'>Bankinstitut</mat-label>
            <input class="formControl mat-body" [(ngModel)]="address.bank_name" [disabled]="ui.isClinicUser">
          </div>
  
          <div class="col-lg-3 col-md-3 col-sm-6">
            <mat-label class='mat-caption'>IBAN</mat-label>
            <input class="formControl mat-body" [(ngModel)]="address.iban" [disabled]="ui.isClinicUser">
          </div>
  
          <div class="col-lg-3 col-md-3 col-sm-6">
            <mat-label class='mat-caption'>BIC</mat-label>
            <input class="formControl mat-body" [(ngModel)]="address.bic" [disabled]="ui.isClinicUser">
          </div>
  
        </div> -->
      </div>
    </div>
  
    <div class="col-6">
      
    </div>
  
  </div>
</div>

</div>

<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  
  <!-- <app-df-icon-button *ngIf='currentViewMode !== viewModes.LIST_PATIENTS_MODE && hasExistingUserModified === true' -->
  <app-df-icon-button *ngIf='patientEdit && hasExistingUserModified === true'
  title="Speichern & Übernehmen"
  icon="person_add"
  (clicked)="savePatient(true)"
  [disabled]="!canSave"
  >
  </app-df-icon-button>  

  <app-df-icon-button *ngIf='!patientEdit && !patientError'
  title="Download PadNext"
  icon="picture_as_pdf"
  (clicked)="JIJI()"
  [disabled]="!canDownload"
  >
</app-df-icon-button>  

</mat-toolbar>