<div class="dynToolbarHolder">
  <div class="dynToolbarIcon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.4901 18.5466L18.0013 13.0581C16.9186 11.9754 15.3015 11.7645 13.9984 12.4066L9.00184 7.41021V4.49956L3.00218 0L0.00234358 2.99971L4.50209 8.99912H7.41287L12.4095 13.9955C11.772 15.2985 11.9782 16.9155 13.061 17.9982L18.5497 23.4868C19.2341 24.1711 20.3403 24.1711 21.0199 23.4868L23.4901 21.0167C24.1697 20.3324 24.1697 19.2262 23.4901 18.5466V18.5466ZM15.5499 10.5458C16.8764 10.5458 18.1232 11.0614 19.0607 11.9988L19.97 12.9081C20.7106 12.5847 21.4137 12.1348 22.023 11.5254C23.762 9.78654 24.3526 7.33991 23.7995 5.11825C23.6963 4.69642 23.1667 4.55112 22.8573 4.86046L19.37 8.34762L16.1874 7.81799L15.6577 4.63549L19.145 1.14833C19.4544 0.838981 19.3044 0.309345 18.8779 0.201543C16.6561 -0.346841 14.2094 0.243726 12.4751 1.97793C11.1392 3.31374 10.5111 5.07607 10.5439 6.83371L14.3922 10.6818C14.7718 10.5927 15.1656 10.5458 15.5499 10.5458ZM10.6799 14.3892L8.02221 11.7317L0.878857 18.8794C-0.292952 20.0512 -0.292952 21.9494 0.878857 23.1212C2.05067 24.2929 3.949 24.2929 5.12081 23.1212L10.9142 17.328C10.558 16.3953 10.4502 15.3782 10.6799 14.3892V14.3892ZM3.00218 22.1228C2.38346 22.1228 1.87724 21.6166 1.87724 20.998C1.87724 20.3746 2.37877 19.8731 3.00218 19.8731C3.62558 19.8731 4.12711 20.3746 4.12711 20.998C4.12711 21.6166 3.62558 22.1228 3.00218 22.1228Z" fill="url(#paint0_linear_6_17)"/>
      <defs>
      <linearGradient id="paint0_linear_6_17" x1="12" y1="0" x2="12" y2="24" gradientUnits="userSpaceOnUse">
      <stop stop-color="#1976D2"/>
      <stop offset="1" stop-color="#004BA0"/>
      </linearGradient>
      </defs>
      </svg>      
  </div>
  <div class="dynToolbarButtonHolder">

    <div class="p-0 dynToolbarButton" >
      <div class="p-2 btn-copy pl12 p_t5 p_b5">Zu meinen Vorlagen</div>
      <div class="p_2 align-text-end ml-auto">
        <mat-icon class="nav-icon-mini" >cloud_download</mat-icon>
      </div>
    </div>

    <div class="p-0 dynToolbarButton" >
      <div class="p-2 btn-copy pl12 p_t5 p_b5">Verwenden</div>
      <div class="p_2 align-text-end ml-auto">
        <mat-icon class="nav-icon-mini" >edit</mat-icon>
      </div>
    </div>

    <div class="p-0 dynToolbarButton" >
      <div class="p-2 btn-copy pl12 p_t5 p_b5">Veröffentlichen</div>
      <div class="p_2 align-text-end ml-auto">
        <mat-icon class="nav-icon-mini" >public</mat-icon>
      </div>
    </div>


  </div>
</div>
