import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PATIENT_SELECTION_VIEW_MODE } from 'src/app/core/models/enums';
import { HealthInsuranceM } from 'src/app/core/models/healthinsurance';
import { Geschlecht, PatientFull } from 'src/app/core/models/patient';
import { SimpleDateSelectionComponent } from 'src/app/ui/overlays/simple-date-selection/simple-date-selection.component';

@Component({
  selector: 'app-patient-modify',
  templateUrl: './patient-modify.component.html',
  styleUrls: ['./patient-modify.component.scss']
})
export class PatientModifyComponent implements OnInit {

  filteredOptions;
  
  @Input() viewMode:PATIENT_SELECTION_VIEW_MODE
  patientError = true
  @Input() patient:PatientFull
  @Input() healthEnsurances:HealthInsuranceM[]
  genders = [Geschlecht.m, Geschlecht.w, Geschlecht.u]
  @Output() onExistingUserModified = new EventEmitter<string>();
  // @Output() error = new EventEmitter<string>();
  insurance:HealthInsuranceM
  hasTextChanged = false;
  hasErrors = false
  constructor(private dialog:MatDialog) { 
    
  }

  ngOnInit(): void {
    this.hasTextChanged = false;
    setTimeout(()=>{
      this.hasErrors = this.anyErrors
      this.onExistingUserModified.emit(this.anyErrors === true ? 'ERRORS' : 'NO_ERRORS')
      
    },100)
    this.filteredOptions = new MatTableDataSource<HealthInsuranceM>(this.healthEnsurances);
    if(this.patient != null && this.patient.health_insurance_id != null && this.patient.title != null){
      this.insurance = {
        health_insurance_id: this.patient.health_insurance_id, 
        title: this.patient.title
      }
    }
    // this.myControl.setValue({name: 'Shelley'});
  }


  private emitEvent(){
    if(!this.hasTextChanged){
      this.hasTextChanged = true
      this.onExistingUserModified.emit('patient_changed')
    }
    if(this.hasErrors != this.anyErrors){
      this.hasErrors = this.anyErrors
      this.onExistingUserModified.emit(this.anyErrors === true ? 'ERRORS' : 'NO_ERRORS')
    }
  }
  secletedChanged(event){
    this.patient.health_insurance_id = event.option.value.health_insurance_id
    this.emitEvent()
    
    
  }
  applyFilter(filterValue: string) {
    this.filteredOptions.filter = filterValue.trim().toLowerCase();
  } 
  
  displayFn(item: HealthInsuranceM): string {
    return item && item.title ? item.title : '';
  }
  textChanged(e){
    this.emitEvent()
  }
  
  onBirthDateEdit(){
    this.emitEvent()
    let _birthDate = this.patient != null  && this.patient.birthDate != null ? new Date(this.patient.birthDate) : null;
    this.dialog.open(SimpleDateSelectionComponent,{
      data: _birthDate,
      disableClose: true
    }).afterClosed().subscribe(
      (_newDate:Date) => {
        if(_newDate != null){
          // console.log(_newDate);
          this.patient.birthDate = _newDate.getTime();
          // console.log(this.patient);
        }
      }
    )
  }

  get anyErrors() : boolean {
    return this.firstNameError || this.lastNameError || this.zipError || this.streetError || this.cityError
  }
  get firstNameError():boolean {
    return this.patient == null || this.patient.firstName == null || this.patient.firstName.length < 3
  }
  get lastNameError():boolean {
    return this.patient == null || this.patient.lastName == null || this.patient.lastName.length < 3
  }
  get streetError():boolean {
    return this.patient == null || this.patient.street == null || this.patient.street.length < 4 || this.patient.streetNumber == null || this.patient.streetNumber == ""
  }
  get zipError():boolean {
    return this.patient == null || this.patient.zip == null || this.patient.zip.length < 4
  }
  get cityError():boolean {
    return this.patient == null || this.patient.city == null || this.patient.city.length < 4
  }
  get bithDateError():boolean {
    return this.patient == null || this.patient.birthDate == null
  }
}
